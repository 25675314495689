import { renderTwoFactorRequestModal } from '../multi_factor_auth/functions'

UC.partials.SessionsForm = Backbone.View.extend({

  el: '#formLogon',

  events: {
    'submit': 'logonSubmit',
    'click #btnLinkedInLogin': 'logonLinkedIn',
    'click #btnGoogleLogin': 'logonGoogle'
  },

  elements: {
    formLogon: null,
    chkRemember: null,
    btnLinkedInLogin: null,
    btnGoogleLogin: null,
    txtLogonEmail: null,
    txtLogonPassword: null,
    panelLoginForm: null,
    panelSuspended: null,
    panelPending: null,
    btnLogin: null,
    recaptchaTags: null
  },

  initialize: function(options) {
    options = options || {};

    this.components = {};

    UC.utils.setSelectors(this.elements);

    // set return url
    this.next_url = this.$('#next_url').val();
    if (UC.constants.returnUrl) {
      this.next_url = UC.constants.returnUrl;
    }

    this.authentication = new UC.components.Authentication({
      next_url: this.next_url
    });

    this.components.logonLoader = this.elements.btnLogin.buttonLoader();

    this.elements.txtLogonEmail.focus();

    // allow one to override the default callbacks
    this.logonCallback = options.logonCallback;
    this.oauthCallback = options.oauthCallback;
    this.modal = options.modal;
  },

  logonSubmit: function(e) {
    e.preventDefault();

    var fields = [
      {
        element: this.elements.txtLogonEmail,
        errorMessage: 'Please provide a valid email address',
        type: 'email'
      },{
        element: this.elements.txtLogonPassword,
        errorMessage: 'Please provide a valid password'
      }
    ];

    if (UC.utils.validateFields(fields)) {
      this.components.logonLoader.showLoader();

      var data = {
        email: this.elements.txtLogonEmail.val(),
        password: this.elements.txtLogonPassword.val(),
        remember: this.elements.chkRemember.is(':checked'),
        modal: this.modal
      };

      var recaptchaData = this.$('textarea.g-recaptcha-response').val();
      if (recaptchaData) {
        data['g-recaptcha-response'] = recaptchaData
      }

      this.authentication.logon(data, function(err, result) {
        this.onLogon(err, result);
      }.bind(this));
    }
  },

  // default logon callback for homepage
  onLogon: function(err, result) {
    this.hideLogonLoaders();

    if (typeof this.logonCallback === 'function') {
      this.logonCallback(err ? err.message : null, result);
    } else {
      if (err) {
        this.elements.recaptchaTags.html(err.recaptcha)
        this.handleLogonError(err.message);
      } else {
        window.location = result.next_url;
      }
    }
  },

  logonLinkedIn: function (e) {
    e.preventDefault();

    this.authentication.logonLinkedIn(
        {
          callback: this.onOauthLogon.bind(this),
        }
    );
  },

  logonGoogle: function(e) {
    e.preventDefault();
    e.stopPropagation();

    this.authentication.logonGoogle(
        {
          context: 'signin',
          backdrop: UC.constants.pageName === 'logon',
          callback: this.onOauthLogon.bind(this),
        }
    );
  },

  onOauthLogon: function(data) {
    if (data && data.two_factor_request_modal && this.modal) {
      renderTwoFactorRequestModal(data.two_factor_request_modal, function () {
        this.onOauthLogon({status: data.status})
      }.bind(this))
      return;
    }

    const googleLogon = data && data.provider === 'google_oauth2';
    const logonSkipped = googleLogon && (data.status === 'signin_skipped' || data.status === 'signup_skipped');

    if (typeof this.oauthCallback === 'function' && !logonSkipped) {
      this.oauthCallback(data);
    } else {
      switch (data.status) {
        case 'authenticated':
          window.location = data.next_url;
          break;
        case 'unregistered':
          UC.utils.showNotification(data.message, UC.constants.results.failure);
          break;
        case 'signup_not_displayed':
          UC.utils.showNotification(data.googleErrorMessage, UC.constants.results.warning);
          break;
        case 'signin_not_displayed':
          UC.utils.showNotification(data.googleErrorMessage, UC.constants.results.warning);
          break;
        default:
          if (!logonSkipped) {
            this.authentication.handleLogonError(data.status);
          }
          break;
      }
    }
  },

  // logon form specific callback with pending/suspended panels
  handleLogonError: function(err) {
    switch (err) {
      case 'suspended':
        UC.utils.hidePanels([this.elements.panelLoginForm, this.elements.panelPending]);
        UC.utils.showPanel(this.elements.panelSuspended);
        break;
      case 'pending':
        UC.utils.hidePanels([this.elements.panelLoginForm, this.elements.panelSuspended]);
        UC.utils.showPanel(this.elements.panelPending);
        break;
      default:
        this.hideLogonLoaders();
        UC.utils.showNotification(err, UC.constants.results.failure);
        break;
    }
  },

  hideLogonLoaders: function() {
    this.components.logonLoader.hideLoader();
  }
});
