UC.views.InvoicesNew = Backbone.View.extend({
  events: {
    'change #selectClients' : 'fetchAttorneyClient',
    'change #selectJob' : 'onChangeSelectedJob'
  },

  initialize: function() {
    var attorney_client_id = UC.utils.getParameterByName('attorney_client_id');

    this.$selectJob = this.$el.find('#selectJob');
    this.$selectClients = this.$el.find('#selectClients');
    this.$formDetails = this.$el.find('#invoice-form-details');

    if (UC.constants.attorney_client) {
      attorney_client_id = UC.constants.attorney_client.id;
    }

    if (attorney_client_id) {
      UC.utils.setSelectedElement(this.$selectClients, attorney_client_id);
      this.fetchAttorneyClient();
    } else if (this.$selectClients.val() !== '') {
      // clear out the selected client field, as the user probably hit back on a
      // new for job invoice page
      this.$selectClients.val('');
    }

    this.$el.find('#txtInvoiceDate').datepicker(UC.constants.datepickerOptions);
    this.$selectClients.select2();

    // If we include parameter to specify this is a direct invoice, it must be triggered after the client has been
    // selected as the select job dropdown is not populated until a client has been selected and the related events
    // have fired. Also, we need to check that the 'direct' option is present since we don't render this option if the
    // client doesn't have any available options to choose. Ludicrous.
    if (UC.utils.getParameterByName('direct_invoice') === 'true' && this.$selectJob.find('option[value="direct"]').length > 0) {
      UC.utils.setSelectedElement(this.$selectJob, 'direct');
      this.$selectJob.trigger('change');
    }

    if (!UC.constants.hasPayout) {
      this.$el.find('#modalNoBankAccount').modal({ keyboard: false, backdrop: 'static' });
    } else if (UC.constants.unverifiedAccount) {
      this.$el.find('#modalUnverifiedAccount').modal({ keyboard: false, backdrop: 'static' });
    }
  },

  fetchAttorneyClient: function(e) {
    var attorney_client_id = this.$selectClients.val();

    if (attorney_client_id) {

      var path = '/clients/{0}.json'.format(attorney_client_id);

      UC.net.get(path, function(attorney_client) {
        this.onFetchAttorneyClient(attorney_client);
      }.bind(this));
    }
  },

  onFetchAttorneyClient: function(attorney_client) {
    var $panelSelectJob = this.$el.find('#panelSelectJob');
    var clientId = attorney_client.id;

    this.attorney_client = attorney_client;

    var $timesheetLink = $('#timesheet-link');

    if ($timesheetLink.length > 0) {
      $timesheetLink.attr('href', '/clients/{0}?current_ts_entries=true'.format(clientId));
    }

    if (clientId && clientId != '##') {
      var name_options = attorney_client.options_for_bill_to_name;
      var name_options_html = name_options.map(function (name) {
        return '<option value="{0}">{0}</option>'.format(name);
      });

      this.$el.find('#selectBillToName').html(name_options_html);
    }

    if (attorney_client.wip_jobs.length > 0 && UC.constants.invoice_type !== 'direct') {
      var options = '<option value="">Select a related job</option>';
      var jobs = attorney_client.wip_jobs;

      for (var i = 0, len = jobs.length; i < len; i++) {
        options += '<option value="{0}">{1}</option>'.format(jobs[i]._id, jobs[i].JobTitle);
      }
      options += '<optgroup label="-----------"><option value="direct">Not related to an active UpCounsel job</option></optgroup>';

      UC.utils.showPanel($panelSelectJob);

      this.$selectJob.html(options).select2();
    } else {
      this.$selectJob.html('');
      UC.utils.hidePanel($panelSelectJob);

      if (clientId && clientId !== '##') {
        this.showFormDetails();

        this.pushNewJobForUSC();
      }
    }
  },

  onChangeSelectedJob: function () {
    var selectedJob = UC.utils.findSelectedElement(this.$selectJob).val();

    if (this.form_details) {
      this.form_details.confirmUnload.resetChanged();
    }

    if (selectedJob !== UC.constants.emptySelectValue && selectedJob !== 'direct') {
      location.href = '/invoices/new/{0}'.format(selectedJob);
    } else {
      this.noJobSelected();
    }
  },

  noJobSelected: function () {
    var wipJobsCount = this.attorney_client.wip_jobs.length;
    var openPrivateJobsCount = this.attorney_client.open_private_jobs.length;

    if (openPrivateJobsCount > 0) {
      this.showPrivateJobModal(this.attorney_client, openPrivateJobsCount);
    } else if (wipJobsCount > 0) {
      this.showWipJobModal(this.attorney_client, wipJobsCount);
    } else {
      this.pushNewJobForUSC();
    }
  },

  showPrivateJobModal: function (attorney_client, count) {
    var clientId = attorney_client.id;

    UC.net.get('open_private_jobs/{0}'.format(clientId), null, function(html) {
      var $existingModal = this.$el.find('#privateJobsModal');

      if ($existingModal.length > 0) $existingModal.remove();

      $('body').append($(html));
      $modal = this.$el.find('#privateJobsModal');
      $modal.modal({backdrop: 'static'});

      $modal.find('.fancy-check').each(function() {
        $(this).fancyCheck();
      });

      $modal.find('#btnNoRelatedJobs').click(this.noRelatedJobsClick.bind(this));
      $modal.find('#btnYesRelatedJobs').click(this.yesRelatedJobsClick.bind(this));
      UC.utils.recordKissEvent('show_open_jobs_reminder', { jobCount: count, attorneyClientId: clientId, attorneyId: UC.user.id });
    }.bind(this));
  },

  noRelatedJobsClick: function (e) {
    var clientId = this.$selectClients.find('option:selected').val();

    $modal = $('#privateJobsModal');
    $modal.modal('hide');

    this.showFormDetails();
    UC.utils.recordKissEvent('click_no_open_jobs_reminder', { attorneyClientId: clientId, attorneyId: UC.user.id });

    this.pushNewJobForUSC();
  },

  yesRelatedJobsClick: function (e) {
    var clientId = this.$selectClients.find('option:selected').val();
    var jobIds = [];
    var $modal = this.$el.find('#privateJobsModal');
    var $chks = $modal.find(".fancy-check");

    if ($chks.length > 0) {
      $chks.each(function(){
        if ($(this).hasClass('checked')) jobIds.push($(this).attr('value'));
      });
    } else {
      jobIds = JSON.parse($modal.find("input[name='RelatedJobIds[]']").val());
    }

    for (var i = 0; i < jobIds.length; i++) {
      this.$formDetails.append("<input type='hidden' name='RelatedJobIds[]' value='{0}'>".format(jobIds[i]));
    }

    $modal.modal('hide');
    this.showFormDetails();
    UC.utils.recordKissEvent('click_yes_open_jobs_reminder', {jobIds: jobIds, attorneyClientId: clientId, attorneyId: UC.user.id});
  },

  showWipJobModal: function (attorney_client, count) {
    var clientJobs = '{0} job{1} in progress'.format(count, count > 1 ? 's' : '');
    var clientName = attorney_client.FirstName;
    var clientId = attorney_client.id;
    var text = 'You have <strong>{0}</strong> with {1}. As a reminder, our new invoicing feature allows you to submit more than two invoices for a job without closing it. If you would like to create an invoice for an existing job, please select it from the “Related Job” dropdown menu.'.format(clientJobs, clientName);

    UC.utils.recordKissEvent('show_wip_jobs_reminder_modal', {jobCount: count, attorneyClientId: clientId, attorneyId: UC.user.id});
    UC.utils.showConfirmModal('Are You Sure?', text, this.pushNewJobForUSC.bind(this), { yesText: 'OK', noText: ''} );

    this.showFormDetails();
  },

  pushNewJobForUSC: function () {
    if (this.attorney_client.transaction_type === "USC") {
      this.showOpenNewJobModal(this.attorney_client);
    }
  },

  showOpenNewJobModal: function (attorney_client) {
    var clientId = attorney_client.id;
    var bestPracticesPath = '/educationcenter/Invoicing';
    var text = 'To protect both parties, all work agreed to by an attorney and client must be documented in a project rather than standalone invoices. We\'ve made it easier to create new projects for existing clients, eliminating the need for the client to hire the attorney. <a href={0}>Read more about invoicing best practices here</a>.';

    UC.utils.showConfirmModal('Do You Want to Start a New Project?', text.format(bestPracticesPath), function (createNewJob) {
      if (createNewJob) {
        UC.utils.recordKissEvent('new_invoice_new_job_modal_create_job', {attorneyClientId: clientId, attorneyId: UC.user.id});
        location.href = '/job/add_project?clientId={0}'.format(clientId);
      } else {
        UC.utils.recordKissEvent('new_invoice_new_job_modal_create_invoice', {attorneyClientId: clientId, attorneyId: UC.user.id});
        this.$el.find('#repeat-usc-client-message').removeClass('hidden');
      }
    }.bind(this), {yesText: 'New Project', noText: 'New Invoice'});
  },

  showFormDetails: function () {

    if (!this.form_details) {
      this.form_details = new UC.partials.InvoicesFormDetails({
        el: this.el,
        showTimesheetEntryImport: true
      });
    }

    this.form_details.render(this.attorney_client);

    this.$formDetails.removeClass('hidden');
  },
});
