!function() {

  UC.views.DashboardMetrics = Backbone.View.extend({

    events: {
      'shown.bs.tab a[data-toggle="tab"]' : 'metricsTabClicked',
      'click .carousel-control' : 'carouselControlClicked'
    },

    initialize: function() {
      this.renderInitialMetrics();
    },

    renderInitialMetrics: function() {
      var $tab = $('.metrics-row > .tab-pane.active.in'),
          tabId = $tab.attr('id');

      this.trackMetricsTabShown(tabId);
      this.renderMetricsTab($tab);
    },

    metricsTabClicked: function(e) {
      var that = this;

      $('.tab-pane.active.in').each(function(){
        var $this = $(this);
        that.trackMetricsTabShown($this.attr('id'));
        if ($this.hasClass('main')) {
          $('.metrics-row').attr('data-active', $this.attr('id'));
          that.renderMetricsTab($this);
        }
      });
    },

    carouselControlClicked: function(e) {
      var that = this;

      // hack to render graph after carousel has completed transition (.6s)
      setTimeout(function() {
        $('.tab-pane.active.in').each(function(){
          var $this = $(this);
          if ($this.hasClass('main')) {
            that.renderMetricsTab($this);
          }
        });
      }.bind(this), 700);
    },

    trackMetricsTabShown: function(tabId) {
      UC.utils.recordKissEvent('metrics_tab_shown', {
        'tabId': tabId
      });
    },

    renderMetricsTab: function($tab) {
      var id = $tab.attr('id');
      switch(id) {
        case 'repeat-clients':
          this.createMonthlyRepeatGraph();
          this.createActivePercentileGraph();
          this.createRehireGraph();
          this.createRehirePercentileGraph();
          break;
        case 'transactions':
          this.createMonthlyRevenueGraph();
          break;
      };
    },

    defaultChartOptions: function() {
      return {
        chart: {
          type: 'column'
        },
        exporting: { enabled: false },
        title: {
          text: ''
        },
        yAxis: {
          tickAmount: 3,
          lineWidth: 1,
          lineColor: '#E5E5E5',
          minorGridLineWidth: 0,
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          title : {
            text : ' '
          },
          labels: {
            style: {
              color: '#E5E5E5',
              fontSize: '11px',
              fontWeight: 'bold'
            }
          }
        },
        xAxis: {
          lineWidth: 0,
          lineColor: 'transparent',
          tickWidth: 0,
          labels: {
            style: {
              color: '#A4ADB8',
              fontWeight: 'bold',
              fontSize: '11px',
            }
          }
        },
        credits: {
          enabled: false
        },
        series: [{
          showInLegend: false,
          name: '',
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, '#A6EFFC'],
              [1, '#009DDC'],
            ]
          },
          pointWidth: 40,
        }],
        tooltip: {
          backgroundColor: 'black',
          borderColor: 'black',
          borderRadius: 3,
          borderWidth: 3,
          style: {
            color: 'white',
            fontWeight: 'bold',
            padding: '5px'
          }
        },
        accessibility: {
          enabled: false
        }
      };
    },

    createMonthlyRevenueGraph: function () {
      var $cht = $('#monthlyRevenueGraph'),
          options = $.extend({}, this.defaultChartOptions()),
          categories = [],
          data = [];

      if ($cht.hasClass('rendered') || !$cht.is(":visible")) {
        return;
      }

      for (var i = 0 ; i < UC.constants.metrics.revenue_by_months.length; i++) {
        var value = UC.constants.metrics.revenue_by_months[i].Value || 0;
        data.push(value);
        categories.push(UC.constants.metrics.revenue_by_months[i].Label);
      }

      options.xAxis.categories = categories;
      options.series[0].data = data;

      options.yAxis.labels = {
        formatter: function () {
          return '$' + this.axis.defaultLabelFormatter.call(this);
        },        
        style: {
          color: '#E5E5E5',
          fontSize: '11px',
          fontWeight: 'bold'
        }
      };

      options.tooltip.formatter = function() {
        return UC.utils.formatCurrency(this.y, '$', 0);
      };

      options.backgroundColor = '#EDF4FF';

      $cht.highcharts(options);
      $cht.addClass('rendered');
    },

    createMonthlyRepeatGraph: function () {
      var $cht = $('#monthlyRepeatGraph'),
          options = $.extend({}, this.defaultChartOptions()),
          categories = [],
          data = [];

      if ($cht.hasClass('rendered') || !$cht.is(":visible")) {
        return;
      }

      for (var i = 0 ; i < UC.constants.metrics.repeat_clients_by_months.length; i++) {
        var value = UC.constants.metrics.repeat_clients_by_months[i].Value || 0;
        data.push(value);
        categories.push(UC.constants.metrics.repeat_clients_by_months[i].Label);
      }

      options.xAxis.categories = categories;
      options.series[0].data = data;
      options.tooltip.pointFormat = "{point.y} repeat clients";
      options.tooltip.formatter = function() {
        return this.y + " repeat clients";
      };

      $cht.highcharts(options);
      $cht.addClass('rendered');
    },

    createActivePercentileGraph: function () {
      var $cht = $('#activePercentile'),
          options = $.extend({}, this.defaultChartOptions()),
          data = [
            { y: UC.constants.metrics.RepeatClientsByDays180Percentile || 0 }
          ],
          invData = [
            { y: 100 - UC.constants.metrics.RepeatClientsByDays180Percentile || 0 }
          ],
          gradientStops = this.getGradientStopsForPercentile(UC.constants.metrics.RepeatClientsByDays180Percentile || 0);

      if ($cht.hasClass('rendered') || !$cht.is(":visible")) {
        return;
      }

      options.xAxis.labels.enabled = false;
      options.yAxis.labels.enabled = false;
      options.yAxis.lineWidth = 0;
      options.yAxis.lineColor = 'transparent';
      options.yAxis.min = 0;
      options.yAxis.max = 100;
      options.tooltip.pointFormat = "{point.y} repeat clients";

      options.plotOptions = {
        column: {
          stacking: 'percent'
        }
      };

      options.tooltip.formatter = function() {
        return false;
      };

      options.tooltip.positioner = function(boxWidth, boxHeight, point) {         
        return {x:point.plotX + 20,y:point.plotY};         
      };

      options.series[0].pointWidth = 22;
      options.series.push(JSON.parse(JSON.stringify(options.series[0])));

      options.series[1].color = {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: gradientStops
      };

      options.series[1].data = data;
      options.series[0].data = invData;
      options.series[0].color = '#EDF4FF';

      options.series[0].states = {
        hover: {
          enabled: false
        }
      };

      function renderer(chart) {
        var point = chart.series[1].data[0],
        text = chart.renderer.text(
            UC.constants.metrics.RepeatClientsByDays180Percentile || 0 + "%",
            point.plotX + chart.plotLeft - 50,
            point.plotY + chart.plotTop + 3
            ).attr({
          zIndex: 5
        }).css({
              color: gradientStops[1][1],
              fontWeight: "bold",
            }).add(),
        box = text.getBBox(),
        margin = 3,
        path = [
          'M',
          box.x - margin, box.y - margin,
          'L',
          box.x - margin, box.y + box.height + margin,
          box.x + box.width + margin, box.y + box.height + margin,
          box.x + box.width + margin*2.5, box.y + (box.height/2),
          box.x + box.width + margin, box.y - margin,
        ];

        chart.renderer.path(path)
          .attr({
            fill: '#EDF4FF',
            zIndex: 4,
          })
        .add();
      }
      $cht.highcharts(options, renderer);
      $cht.addClass('rendered');
    },

    createRehirePercentileGraph: function () {
      var $cht = $('#rehirePercentile'),
          options = $.extend({}, this.defaultChartOptions()),
          data = [
            { y: UC.constants.metrics.RehirePercentile }
          ],
          invData = [
            { y: (100 - UC.constants.metrics.RehirePercentile) }
          ],
          gradientStops = this.getGradientStopsForPercentile(UC.constants.metrics.RehirePercentile);

      if ($cht.hasClass('rendered') || !$cht.is(":visible")) {
        return;
      }

      options.xAxis.labels.enabled = false;
      options.yAxis.labels.enabled = false;
      options.yAxis.lineWidth = 0;
      options.yAxis.lineColor = 'transparent';
      options.yAxis.min = 0;
      options.yAxis.max = 100;

      options.tooltip.pointFormat = "{point.y} repeat clients";
      options.tooltip.formatter = function() {
        return false;
      };

      options.plotOptions = {
        column: {
          stacking: 'percent'
        }
      };

      options.series[0].pointWidth = 22;
      options.series.push(JSON.parse(JSON.stringify(options.series[0])));

      options.series[1].color = {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: gradientStops
      };

      options.series[1].data = data;
      options.series[0].data = invData;
      options.series[0].color = '#EDF4FF';

      options.series[0].states = {
        hover: {
          enabled: false
        }
      };

      function renderer(chart) {
        var point = chart.series[1].data[0],
        text = chart.renderer.text(
            UC.constants.metrics.RehirePercentile + "%",
            point.plotX + chart.plotLeft - 50,
            point.plotY + chart.plotTop + 3
            ).attr({
          zIndex: 5
        }).css({
              color: gradientStops[1][1],
              fontWeight: "bold",
            }).add(),
        box = text.getBBox(),
        margin = 3,
        path = [
          'M',
          box.x - margin, box.y - margin,
          'L',
          box.x - margin, box.y + box.height + margin,
          box.x + box.width + margin, box.y + box.height + margin,
          box.x + box.width + margin*2.5, box.y + (box.height/2),
          box.x + box.width + margin, box.y - margin,
        ];

        chart.renderer.path(path)
          .attr({
            fill: '#EDF4FF',
            zIndex: 4,
          })
        .add();
      }

      $cht.highcharts(options, renderer);
      $cht.addClass('rendered');
    },

    createRehireGraph: function() {
      var options = {
        colors: [
        {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#A6EFFC'],
            [1, '#009DDC'],
          ]
        },
        "#EDF4FF"
        ],
        chart: {
          type: 'pie'
        },
        title: {
          text: '',
        },
        xAxis: {
          title: {
            text: '',
          }
        },
        yAxis: {
          title: {
            text: '',
          }
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%']
          }
        },
        credits: {
          enabled: false
        },
        series: [{
          data: [["Rehire",UC.constants.metrics.RehirePercent],["Placeholder",(100-UC.constants.metrics.RehirePercent)]],
          size: '170%',
          innerSize: '70%',
          showInLegend: false,
          dataLabels: {
            enabled: false
          },
          states: {
            hover: {
              enabled: false
            }
          }
        }],
        tooltip: {
          formatter: function() {
            return false;
          }
        }
      };

      var $cht = $('#rehireGraph');

      if ($cht.hasClass('rendered') || !$cht.is(":visible")) {
        return;
      }

      function renderer(chart) {
        var textX = chart.plotLeft + (chart.plotWidth  * 0.5);
        var textY = chart.plotTop  + (chart.plotHeight * 0.5);

        var span = '<span id="rehireInfoText" style="position:absolute; text-align:center;">';
        span += UC.constants.metrics.RehirePercent + '%</span>';

        $("#rehireAddText").append(span);
        span = $('#rehireInfoText');
        span.css('left', textX + (span.width() * -0.5));
        span.css('top', textY + (span.height() * -0.5));
      }

      $cht.highcharts(options, renderer);
      $cht.addClass('rendered');
    },

    getGradientStopsForPercentile:  function(percentile) {
      var bottomStop,
          topStop;

      if (percentile < 33) {
        bottomStop = '#FD435F';
        topStop = '#F3C3CA';
      } else if (percentile < 66) {
        bottomStop = '#CBAE21';
        topStop = '#F9D31B';
      } else {
        bottomStop = '#00C967';
        topStop = '#00E6A0';
      }

      return [
        [0, topStop],
        [1, bottomStop],
      ];
    }

  });

}();
