var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li>\n        You don't have any calls scheduled. Review your\n        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"proposalsPageLink") || (depth0 != null ? lookupProperty(depth0,"proposalsPageLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"proposalsPageLink","hash":{},"data":data,"loc":{"start":{"line":9,"column":17},"end":{"line":9,"column":38}}}) : helper)))
    + "\">proposals</a>\n        to schedule calls with attorneys. Review your\n        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"messagesPageLink") || (depth0 != null ? lookupProperty(depth0,"messagesPageLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messagesPageLink","hash":{},"data":data,"loc":{"start":{"line":11,"column":17},"end":{"line":11,"column":37}}}) : helper)))
    + "\">messages</a>\n        to reschedule a canceled call\n      </li>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"scheduledCalls") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":15,"column":6},"end":{"line":62,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"isScheduled") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams),"inverse":container.program(7, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":16,"column":8},"end":{"line":61,"column":15}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class=\"i-flex flex-align-center\">\n            <div class=\"marg-r-auto\">\n              <div class=\"fs-14\">\n                <b>Call Scheduled</b> with "
    + alias2(alias1(((stack1 = ((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"Attorney") : stack1)) != null ? lookupProperty(stack1,"full_name") : stack1), depth0))
    + " on\n                "
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"adjusted_date_time") : stack1), depth0))
    + "\n              </div>\n\n              <div class=\"text-grey marg-t-5 fs-12\">\n                Scheduled\n                "
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"time_ago") : stack1), depth0))
    + "\n              </div>\n            </div>\n\n            <div class=\"text-right\">\n              <button\n                class=\"js-call-cancel btn btn-default btn-sm marg-5\"\n                data-id=\""
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"_id") : stack1), depth0))
    + "\"\n              >\n                Cancel Call\n              </button>\n\n              <button\n                class=\"js-call-reschedule btn btn-default btn-sm marg-5\"\n                data-id=\""
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"_id") : stack1), depth0))
    + "\"\n                data-job-id=\""
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"JobId") : stack1), depth0))
    + "\"\n                data-bid-id=\""
    + alias2(alias1(((stack1 = ((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"Bid") : stack1)) != null ? lookupProperty(stack1,"_id") : stack1), depth0))
    + "\"\n                data-attorney-id=\""
    + alias2(alias1(((stack1 = ((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"Attorney") : stack1)) != null ? lookupProperty(stack1,"_id") : stack1), depth0))
    + "\"\n                data-user-id=\""
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"UserId") : stack1), depth0))
    + "\"\n                data-instant-available=\""
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"instantAvailable") : stack1), depth0))
    + "\"\n              >\n                Reschedule\n              </button>\n            </div>\n          </li>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"isPending") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":51,"column":8},"end":{"line":61,"column":8}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class=\"i-flex flex-align-center\">\n            <div class=\"fs-14\">\n              You have requested a call with "
    + alias2(alias1(((stack1 = ((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"Attorney") : stack1)) != null ? lookupProperty(stack1,"full_name") : stack1), depth0))
    + ".\n              They are reviewing your call request,\n              and you may send them a\n              <a href="
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"messagesPageLink") : stack1), depth0))
    + ">message</a>\n              to discuss your project.\n            </div>\n          </li>\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel marg-b-30\">\n  <ul class=\"jo-list\">\n    <li>\n      <h4>Your Calls</h4>\n    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmptyState") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.program(3, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":6,"column":4},"end":{"line":63,"column":11}}})) != null ? stack1 : "")
    + "  </ul>\n</div>\n\n";
},"useData":true,"useBlockParams":true});