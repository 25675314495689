import TextLengthCounter from '../../../components/text_length_counter'

UC.views.JobsEdit = Backbone.View.extend({
  events: {
    'change #job_Description' : 'handleDescriptionChange',
    'click #btnEditSubmit' : 'handleEditClick'
  },

  initialize: function() {
    this.dataChanged = false;
    this.editSubmitBtnLoader = this.$('#btnEditSubmit').buttonLoader();
    this.$('#job_LegalSubjectId').select2();

    this.descriptionLengthCounter = new TextLengthCounter({
      el: this.$el,
      errorContainer: '.form-group',
      textarea: 'textarea.js-description-textarea',
      counter: '.js-description-length'
    })
  },

  handleEditClick: function(e) {
    e.preventDefault();

    if (UC.constants.reopen && !this.dataChanged){
      UC.utils.showValidationErrors(
          [{element: this.$el.find('#job_Description'), text: 'Please update the description'}],
          {className: 'form-group'}
      );
    } else { this.updateJob(); }
  },

  handleDescriptionChange: function(e) {
    if(UC.constants.reopen) { this.dataChanged = true }
  },

  updateJob: function() {
    var errors = [];
    var data = this.$('#edit_job_' + UC.job.id).serializeJSON();

    UC.utils.clearValidationErrors('form-group');

    if (!data.job.JobTitle) {
        errors.push({ element: this.$('#job_JobTitle'), text: 'Job title is required'});
    }

    if (!this.descriptionLengthCounter.hasValidTextLength()) {
      errors.push(this.descriptionLengthCounter.getError());
    }

    if (!data.job.JobTitle) {
        errors.push({ element: this.$('#job_LegalSubjectId'), text: 'Legal subject is required'});
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, {className: 'form-group'});
    } else {
      UC.utils.hideComponent(this.$('#btnCancelEditJob'));
      this.editSubmitBtnLoader.showLoader();
      if (UC.constants.reopen) data.reopen = true;

      UC.net.post('/job/update/' + UC.constants.jobId, data, function (response) {
        var defaultPath = '/jobs/' + UC.constants.jobId + '/proposals';
        location.href = UC.utils.getParameterByName("back") || response.nextUrl || defaultPath;
      }).fail(function(res) {
        UC.utils.showComponent(this.$('#btnCancelEditJob'));
        this.editSubmitBtnLoader.hideLoader();
        UC.closeTheLoop.handleFailure(res, false);
      }.bind(this));
    }
  }
});
