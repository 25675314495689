export function loadFileStackScript(callback) {
    $.getScript('https://static.filestackapi.com/filestack-js/3.32.3/filestack.min.js', function () {
        if (callback) {
            callback();
        }
    });
}

export function pickFileAndStore({fileStackApiKey, pickerOptions, storeOptions}) {
    if (!window.filestack) {
        console.log('filestack was not initialized yet, loading...')
        loadFileStackScript(function () {
            pickFileAndStore({fileStackApiKey, pickerOptions, storeOptions});
        })
        return;
    }

    const client = filestack.init(fileStackApiKey || UC.constants.fileStackApiKey);

    const options = pickerOptions || {};
    options.storeTo = storeOptions || {};
    if (!options.fromSources) {
        options.fromSources = [
            'local_file_system',
            'facebook',
            'box',
            'dropbox',
            'googledrive',
            'onedrive',
            'gmail',
            'instagram'
        ];
    }
    if (!options.maxFiles) {
        options.maxFiles = 1;
    }
    if (!options.maxSize) {
        options.maxSize = 20971520; // 20 Mb
    }

    client.picker(options).open();
}
