!function() {
  UC.modals.EditPracticeAreas = Backbone.View.extend({

    el: '#mdlEditPracticeAreas',

    events: {
      'click #btnSavePracticeAreas': 'savePracticeAreas',
      'click #btnCancelPracticeAreas': 'cancelPracticeAreas',
      'click #aAllLegalSubjects': 'clickAllLegalSubjects',
      'click #aNoneLegalSubjects': 'clickNoLegalSubjects'
    },

    initialize: function(opts) {
      if (opts){
        this.initPracticeAreaPopovers = opts.initPracticeAreaPopovers;
        this.savePracticeAreasCallback = opts.savePracticeAreas;
        this.cancelPracticeAreasCallback = opts.cancelPracticeAreas;
      }
      this.superFancyCatLegalSubjects = $("[name='LegalSubjectIds[]']").superFancyCat();
    },

    openModal: function() {
      this.$el.modal({ backdrop: 'static' });
    },

    cancelPracticeAreas: function(){
      this.$el.modal('hide');
      if (this.cancelPracticeAreasCallback) {
        this.cancelPracticeAreasCallback(this.superFancyCatLegalSubjects);
      }
    },

    clickAllLegalSubjects: function(e) {
      e.preventDefault();
      this.superFancyCatLegalSubjects.toggleAll();
    },

    clickNoLegalSubjects: function(e) {
      e.preventDefault();
      this.superFancyCatLegalSubjects.toggleNone();
    },

    savePracticeAreas: function() {
      if (this.savePracticeAreasCallback) {
        this.savePracticeAreasCallback();
      } else {
        var data = {LegalSubjectIds: $("[name='LegalSubjectIds[]']").val()};
        UC.net.post(UC.constants.update_practice_areas_path, data, this.onSavePracticeAreas.bind(this))
      }
    },

    onSavePracticeAreas: function(response) {
      this.$el.modal('hide');

      if (UC.action === 'index'){
        UC.utils.checklistSuccessCallback('btnEditPracticeAreas');

        if (response.currentLegalGroups && response.currentLegalGroups.length > 0) {
          new UC.modals.AttorneyTopLegalGroups({
            allLegalGroups: response.allLegalGroups,
            attorneyLegalGroups: response.currentLegalGroups,
            attorneyTopLegalGroups: response.topLegalGroups,
            afterUpdateCallback: function() {
              $('#top-legal-groups-modal').modal('hide');
            }
          });
        }
      } else {
        $('#practiceAreasContent').html(response.html);
        this.initPracticeAreaPopovers();

        if (response.currentLegalGroups && response.currentLegalGroups.length > 0) {
          new UC.modals.AttorneyTopLegalGroups({
            allLegalGroups: response.allLegalGroups,
            attorneyLegalGroups: response.currentLegalGroups,
            attorneyTopLegalGroups: response.topLegalGroups,
            afterUpdateCallback: function(res) {
              $('#practiceAreasContent').html(res.html);
              $('#top-legal-groups-modal').modal('hide');
              this.initPracticeAreaPopovers();
            }.bind(this)
          });
        }
      }
    }
  });
}();
