import { ClientJS } from 'clientjs'; // previous lib name is client_browser
import { default as Cookies } from 'js-cookie';
import './components/popdown.jquery'

/*
 * C like string format
 */
String.prototype.format = function() {
  var pattern = /\{\d+\}/g;
  var args = arguments;
  var s = (this || '').toString();
  return s.replace(pattern, function (capture) {
    return args[capture.match(/\d+/)];
  });
};

/*
 * Turns "this".splice(2, '-') into "th-is""
 */
String.prototype.splice = function(idx, delim) {
  if (idx > this.length) {
    return this;
  } else {
    return this.slice(0, idx) + delim + this.slice(idx, this.length);
  }
};

/*
 * Turns "This Thing" into "this_thing"
 */
String.prototype.toSnakeCase = function() {
  return this.split(" ")
    .map(function(k) {
      return k.toLowerCase();
    })
    .join("_");
};

/*
 * Turns "this_thing" into "ThisThing"
 */
String.prototype.classify = function() {
  return this.replace(/(?:^|[-_])(\w)?/g, function(_, c) {
    return c ? c.toUpperCase() : "";
  });
};

/*
 * Turns "my sentance" into "My sentance"
 */
String.prototype.capitalize = function(all) {
  if (all === true) {
    return this.split(" ")
      .map(function(i) {
        return i[0].toUpperCase() + i.substring(1);
      })
      .join(" ");
  } else {
    return this.replace(this[0], this[0].toUpperCase());
  }
};

String.prototype.isBlank = function() {
  return this.length === 0 || !this.trim();
};

// Function to check if viewport is mobile width, returns boolean
$.fn.isMobileWidth = function() {
  return this.width() < UC.constants.minSmallScreen;
};

$.fn.isScrollable = function() {
  return this.get(0).scrollHeight > this.outerHeight();
};

UC.browser = new ClientJS();
UC.locale = "en";
UC.translations = { en: {}, es: {} };
UC.translation_namespaces = { en: [], es: [] };

UC.page = UC.page || {};
UC.models = UC.models || {};
UC.collections = UC.collections || {};
UC.views = UC.views || {};
UC.partials = UC.partials || {};
UC.modals = UC.modals || {};
UC.components = UC.components || {};
UC.constants = UC.constants || {};
UC.constants.emptySelectValue = "##";
UC.constants.emptySelectText = "--";
UC.constants.emptySelectItem = '<option value="##">--</option>';
UC.constants.results = {
  success: "success",
  failure: "failure",
  warning: "warning",
  info: "info"
};

UC.constants.minSmallScreen = 768;
UC.constants.minMediumScreen = 992;

UC.constants.momentDateFormats = ["M/D/YYYY", "MM/DD/YYYY"];

UC.constants.datepickerOptions = {
  dateFormat: "mm/dd/yy",
  changeMonth: true,
  changeYear: true
};

UC.views.resolveHandlebarsTemplate = function(templateName) {
  return require('./templates/' + templateName + '.hbs');
}

UC.utils = {};

UC.utils.generateSlug = function (string) {
  return(
    string
      .toLowerCase()
      .replace(/^\s+|\s+$|\./g, "")
      .replace(/\s/g, "-")
      .replace(/(?![a-z0-9-_])/g, "")
    );
};

UC.utils.setLocale = function(locale) {
  var current_locale = UC.locale,
    namespaces = UC.translation_namespaces[current_locale];
  UC.locale = locale;
  namespaces.forEach(function(namespace) {
    UC.utils.fetchTranslations({
      resource: namespace,
      locale: locale
    });
  });
};

UC.utils.toggleMobile = function() {
  $("html").hasClass("isMobile")
    ? $("html").removeClass("isMobile")
    : $("html").addClass("isMobile");
};

UC.utils.fetchTranslations = function(options, callback) {
  var options = options || {},
    resource = options.resource || "en",
    locale = options.locale || UC.locale,
    version = options.version || "v1",
    data = {
      resource: resource,
      locale: locale
    };
  UC.net
    .get("/api/" + version + "/translations", data, function(translations) {
      UC.translation_namespaces[locale] =
        UC.translation_namespaces[locale] || [];
      UC.translation_namespaces[locale].push(resource);
      UC.utils.add_translations(translations, locale);
      if (callback) {
        callback.call();
      }
    })
    .fail(function() {});
};

UC.utils.add_translations = function(translations, locale, prefix) {
  var locale = locale || UC.utils.locale;
  UC.translations[locale] = UC.translations[locale] || {};

  for (var translation in translations) {
    var key = translation,
      value = translations[key];
    var registryKey = prefix ? prefix + "." + key : key;
    var valueType = typeof value;
    if (translations.hasOwnProperty(translation)) {
      if (valueType === "string" || valueType === "number") {
        UC.translations[locale][registryKey] = value;
      } else {
        UC.utils.add_translations(value, locale, registryKey);
      }
    }
  }
};

UC.utils.translate = function(key, templateData, options) {
  var options = options || {};
  var locale = options.locale || UC.locale;
  var translation = UC.translations[locale] && UC.translations[locale][key];

  if (typeof translation === "undefined") {
    return UC.utils.translate.whenUndefined(key, locale);
  } else {
    return templateData
      ? translation.replace(/%{(\w+)}/g, function(match, param) {
          return templateData[param] || match;
        })
      : translation;
  }
};

UC.utils.translate.whenUndefined = function(key, locale) {
  return key;
};

UC.utils.recordNesClickEvent = function(element, attributes) {
  $(element).click(function() {
    UC.utils.sendNesEvent('track_click', attributes)
  })
};

UC.utils.sendNesEvent = function (eventName, attributes) {
  if (!UC.constants.captureClientEvents) {
    return
  }

  var defaults = {
    user_agent: navigator.userAgent,
    referrer: window.document.referrer,
    current_url: window.location.href,
    page_tags: UC.constants.pageTags
  };

  if (UC.user) defaults.user_id = UC.user.id;
  if (UC.job) defaults.job_id = UC.job.id;
  if (UC.invoice) defaults.invoice_id = UC.invoice.id;
  if (UC.payment) defaults.payment_id = UC.payment.id;

  attributes = $.extend({}, defaults, attributes);

  var data = {
    eventName: eventName,
    attributes: attributes
  };

  if (!UC.user || !UC.user.impersonate) {
    if (window.Bugsnag) {
      Bugsnag.leaveBreadcrumb('UC.utils.sendNesEvent', {data: JSON.stringify(data)});
    }
    $.post("/events/client_nes_event", data).fail(function(xhr) {
      xhr.handled = true;
    });
  }
};

UC.utils.noBackbone = function() {
  var $body = $("body");

  // Some browsers, such as older versions of IE, do not support data attributes
  // on the body element.  Google translate also seems to fail.  Perhaps we
  // should try a different method?
  return !$body.data('controller') || !$body.data('action') ||
    $body.hasClass('no-backbone');
};

// method for magically pulling in components based
//   on body[data-controller] and body[data-action]
UC.utils.initView = function(module) {
  var $body = $("body");

  if (UC.utils.noBackbone()) return;

  var controller_path = $body.data("controller").split("/");

  var namespace = (module || controller_path[0] || "").classify();
  var controller = (controller_path[1] || "").classify();
  var action = $body.data("action").classify();

  if (namespace === "Public") {
    // we don't scope public scripts
    namespace = "";
  }

  // controller scoped script
  var klass_controller = UC.views["{0}{1}".format(namespace, controller)];
  // specific action scoped script
  var klass_action =
    UC.views["{0}{1}{2}".format(namespace, controller, action)];

  _.each([klass_controller, klass_action], function(klass) {
    if (klass && klass.prototype instanceof Backbone.View) {
      new klass({ el: "body" });
    }
  });
};

// finds {keys} as `id selectors`s and selects elements on the page and set them to {value}.
UC.utils.setSelectors = function(elements) {
  for (var element in elements) {
    if (elements.hasOwnProperty(element)) {
      elements[element] = jQuery("#" + element);
    }
  }
};

UC.utils.findSelectedElement = function(select) {
  return $(select)
    .find("option:selected")
    .last();
};

UC.utils.setSelectedElement = function(select, elementValue, opts) {
  opts = opts || {};
  var itemAttrForQuery = "value";
  var findSelector;

  $(select)
    .find("option")
    .prop("selected", false);

  if (opts.optionAttr) itemAttrForQuery = opts.optionAttr;

  findSelector = "option[" + itemAttrForQuery + '="{0}"]';
  $(select)
    .find(findSelector.format(elementValue))
    .prop("selected", "selected");
};

UC.utils.setSelectedElementByText = function(select, text) {
  $(select)
    .find("option")
    .filter(function() {
      return $(this).html() == text;
    })
    .prop("selected", "selected");
};

UC.utils.isEmptySelectValue = function(select) {
  return (
    UC.utils.findSelectedElement(select).val() == UC.constants.emptySelectValue
  );
};

UC.utils.anyEmptySelectValue = function(selectElements) {
  for (var i = 0, len = selectElements.length; i < len; i++) {
    if (UC.utils.isEmptySelectValue(selectElements[i])) {
      return true;
    }
  }

  return false;
};

UC.utils.showComponent = UC.utils.showPanel = function(panel, options) {
  var pnl = $(panel),
    defaults = {
      transition: "none"
    },
    opt = $.fn.extend({}, defaults, options);

  if (opt.transition != "none" && !$("body").hasClass("notransition")) {
    pnl.addClass(opt.transition);
  }

  pnl.removeClass("hidden hide");
};

UC.utils.showComponents = UC.utils.showPanels = function(panels) {
  for (var i = 0, len = panels.length; i < len; i++) {
    UC.utils.showPanel(panels[i]);
  }
};

UC.utils.toggleComponents = UC.utils.togglePanels = function(panels) {
  if (panels && panels.length > 0) {
    for (var i = 0, len = panels.length; i < len; i++) {
      if (panels[i].hasClass("hidden")) {
        UC.utils.showComponent(panels[i]);
      } else {
        UC.utils.hideComponent(panels[i]);
      }
    }
  }
};

UC.utils.fadeInComponent = function(component, options) {
  component.css({ display: "none" });
  UC.utils.showComponent(component);
  component.fadeIn(options);
};

UC.utils.viewportWidth = function() {
  return document.compatMode == "BackCompat"
    ? document.body.clientWidth
    : document.documentElement.clientWidth;
};

UC.utils.viewportHeight = function() {
  return document.compatMode == "BackCompat"
    ? document.body.clientHeight
    : document.documentElement.clientHeight;
};

UC.utils.isMobileViewPort = function() {
  return UC.utils.viewportWidth() < UC.constants.minSmallScreen;
};

UC.utils.isDesktopViewPort = function() {
  return UC.utils.viewportWidth() >= UC.constants.minMediumScreen;
};

UC.utils.hideComponent = UC.utils.hidePanel = function(panel, options) {
  var pnl = $(panel),
    defaults = {
      transition: "none"
    },
    opt = $.fn.extend({}, defaults, options);

  if (opt.transition != "none" && !$("body").hasClass("notransition")) {
    pnl.addClass(opt.transition);
  }

  pnl.addClass("hidden");
};

UC.utils.hideComponents = UC.utils.hidePanels = function(panels) {
  for (var i = 0, len = panels.length; i < len; i++) {
    UC.utils.hidePanel(panels[i]);
  }
};

UC.utils.showConfirmModal = function(title, text, callback, options) {
  var defaults = {
      title: title,
      text: text,
      callback: callback
    },
    opt = $.fn.extend({}, defaults, options);
  UC.components.confirmModal(opt);
};

UC.utils.getParameterByName = function(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
  return results == null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

UC.utils.scrollToElement = function(element, options) {
  var options = options || {};
  var offset = typeof options.offset !== "undefined" ? options.offset : 50;
  var scroll_to = $(element).offset().top - offset;

  // only scroll if we need to
  if (scroll_to !== $("body").scrollTop()) {
    $("html, body").animate({ scrollTop: scroll_to }, 500);

    if (typeof options.callback === "function") {
      setTimeout(options.callback, 500);
    }
  }
};

UC.utils.scrollToTop = function(options) {
  var offset = options ? options.offset : 50;

  $("html, body").animate(
    {
      scrollTop: 0 - offset
    },
    500
  );
};

UC.utils.ensureUrlProtocol = function(url) {
  return /^(https?|ftp):\/\//i.test(url) ? url : "http://{0}".format(url);
};

UC.utils.camelToUnderscore = function(str) {
  return str.replace(/([A-Z])/g, function($1) {
    return "_" + $1.toLowerCase();
  });
};

UC.utils.isAttorney = function() {
  return UC.user && UC.user.type == "attorney";
};

UC.utils.registerEnterKey = function(selector, fn) {
  function onKeyDown(e) {
    if (e.keyCode == 13) {
      fn();
    }
  }

  selector.bind("keydown", onKeyDown);
};

UC.utils.unRegisterEnterKey = function(selector) {
  selector.unbind("keydown");
};

UC.utils.recordPajEvent = function(options) {
  var kissEventName = "paj-" + options.eventName,
    // kiss events have hyphens, regular nes events have underscores
    nesEventName = kissEventName.replace(/-/g, "_"),
    properties = {},
    nesProperties = {};

  if (options.stageName) properties.stageName = options.stageName;

  if (options.jobPackage) {
    properties.packageName = options.jobPackage.package;
    properties.packageId = options.jobPackage.package_id;
  };

  if (options.jobLead) {
    if (options.jobLead._id) properties.jobLeadId = options.jobLead._id;
    if (options.jobLead.legalPhraseId)
      properties.legalPhraseId = options.jobLead.legalPhraseId;
    if (options.jobLead.legalAreaId)
      properties.legalAreaId = options.jobLead.legalAreaId;
    if (options.jobLead.legalTaskId)
      properties.legalTaskId = options.jobLead.legalTaskId;
    if (options.jobLead.FixedFeePackageId)
      properties.fixedFeePackageId = options.jobLead.fixedFeePackageId;
  } else if (options.jobLeadId) {
    properties.jobLeadId = options.jobLeadId;
  }

  if (options.legalPhraseId) properties.legalPhraseId = options.legalPhraseId;
  if (options.legalAreaId) properties.legalAreaId = options.legalAreaId;
  if (options.legalTaskId) properties.legalTaskId = options.legalTaskId;
  if (options.legalTileId) properties.legalTileId = options.legalTileId;
  if (options.selectionType) properties.selectionType = options.selectionType;
  if (options.questionId) properties.questionId = options.questionId;

  if (UC.constants.legalTilesHash && properties.legalTileId) {
    properties.legalTileName =
      UC.constants.legalTilesHash[properties.legalTileId].Name;
  }

  for (var i in properties) {
    var nesProp = UC.utils.camelToUnderscore(i).toUpperCase();
    nesProperties[nesProp] = properties[i];
  }

  if (UC.user && UC.user.id) properties.userId = UC.user.id;

  nesProperties['event_name'] = nesEventName;

  // TODO: Resolve data discrepancy
  // https://upcounsel.atlassian.net/browse/UC-12107
  UC.utils.sendNesEvent('track_view_paj', nesProperties);
};

UC.utils.recordGoogleAnalytics = function(hitType, opts) {
  if (typeof ga === "function") {
    ga("send", hitType, opts);
  } else {
    console.log("Google analytics hasn't loaded yet...");
  }
};

UC.utils.recordGoogleAnalyticsEvent = function(
  eventCategory,
  eventAction,
  opts
) {
  var opts = typeof opts === "undefined" ? {} : opts;
  opts["eventCategory"] = eventCategory;
  opts["eventAction"] = eventAction;
  UC.utils.recordGoogleAnalytics("event", opts);
};

UC.utils.recordGoogleAnalyticsPageView = function(pageUrl) {
  UC.utils.recordGoogleAnalytics("pageview", pageUrl);
};

UC.utils.recordBookingTransaction = function(
  jobId,
  userId,
  attorneyId,
  attorneyName,
  amount
) {
  if (typeof ga === "function") {
    // Send Google Analytics.js ecommerce event
    ga("require", "ecommerce");
    ga("ecommerce:addTransaction", {
      id: "JobId_" + jobId, // Transaction ID. Required.
      revenue: amount // Grand Total.
    });
    ga("ecommerce:addItem", {
      id: "JobId_" + jobId, // Transaction ID. Required.
      name: attorneyName, // Product name. Required.
      sku: "AttorneyId_" + attorneyId, // SKU/code.
      category: "Booked Job", // Category or variation.
      price: amount, // Unit price.
      quantity: "1" // Quantity.
    });
    ga("ecommerce:send");
  }
};

UC.utils.createTrackingImage = function(url) {
  new Image().src = url;
};

UC.utils.setLinksTarget = function(selector, target) {
  selector.each(function() {
    var $this = $(this);
    $this
      .data("orig-target", $this.attr("target") || "")
      .attr("target", target);
  });
};

UC.utils.resetLinksTarget = function(selector) {
  selector.each(function() {
    var $this = $(this);
    $this.attr("target", $this.data("orig-target"));
  });
};

UC.utils.hideLoaders = function(loaders) {
  if (loaders) {
    for (var i = 0, len = loaders.length; i < len; i++) {
      loaders[i].hideLoader();
    }
  }
};

UC.utils.showLoaders = function(loaders) {
  if (loaders) {
    for (var i = 0, len = loaders.length; i < len; i++) {
      loaders[i].showLoader();
    }
  }
};

//http://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily
UC.utils.preventScrolling = function(e) {
  e = e || window.event;
  if (e.preventDefault) e.preventDefault();
  e.returnValue = false;
};

UC.utils.preventScrollKeys = function(e) {
  var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

  if (keys[e.keyCode] && e) {
    e.preventDefault();
    return false;
  }
};

UC.utils.disableScrolling = function() {
  if (window.addEventListener)
    // older FF
    window.addEventListener("DOMMouseScroll", UC.utils.preventScrolling, false);
  window.onwheel = UC.utils.preventScrolling; // modern standard
  window.onmousewheel = document.onmousewheel = UC.utils.preventScrolling; // older browsers, IE
  window.ontouchmove = UC.utils.preventScrolling; // mobile
  document.onkeydown = UC.utils.preventScrollKeys;
};

UC.utils.enableScrolling = function() {
  if (window.removeEventListener) {
    window.removeEventListener(
      "DOMMouseScroll",
      UC.utils.preventScrolling,
      false
    );
  }

  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
  document.onkeydown = null;
};

UC.utils.handleGenericClick = function($this, eventName) {
  var attributes = {
    "page-path": window.location.pathname,
    "button-value": $this.attr("value") || $this.text(),
    href: $this.attr("href")
  };

  if (!eventName) {
    eventName = $this.data("event-name") || "Generic Button Click";
  }

  attributes = $.extend({}, $this.data(), attributes);
  delete attributes["eventName"];
  delete attributes["loader"];
  UC.utils.recordKissEvent(eventName, attributes);
};

UC.fee = {};
UC.fee.from_percent = function(amount, percent) {
  return amount * percent / 100.0;
};

$(function() {

  if (UC.browser.isMobile()) {
    $("html").addClass("isMobile");
  }

  $("html").addClass(UC.browser.getBrowser());
  $("html").addClass(UC.browser.getEngine());
  $("html").addClass(UC.browser.getOS());

  if ($.prototype.tooltip) {
    $(".iconHelp, .poshyme").tooltip({container: 'body'});
  }

  if ($.prototype.draggable) {
    $(document).on("mouseover", ".jqmWindow legend", function(e) {
      $(".jqmWindow").draggable({
        handle: "legend",
      });
    });

    $(".modal-content").each(function(index, element) {
      var $element = $(element);
      if (!$element.hasClass("no-drag")) {
        $element.draggable({
          handle: ".modal-header",
        });
      }
    });
  }

  $(".f-select").click(function() {
    if (!$(this).hasClass("active")) {
      $(".f-select").removeClass("active");
    }
    $(this).toggleClass("active");
  });

  window.setInterval(function() {
    if (UC.net && typeof UC.net.post === "function") {
      UC.net.post("/account/noop", $.noop);
    }
  }, 600000);

  $(".popdown").popdown();

  $(".h-nav-btn").click(function(e) {
    e.stopPropagation();
    $(".wrap").toggleClass("offset");

    UC.utils.recordKissEvent("Mobile - Click Nav button");
  });

  $(".h-side-nav, .mobile-filter, .jqmWindow.anchor").click(function(e) {
    e.stopPropagation();
  });

  $("body").click(function(e) {
    $(".wrap")
      .removeClass("offset")
      .removeClass("filter-on");
    $(".job-nav").removeClass("selected");
    if ($(".jqmWindow.anchor").is(":visible")) {
      UC.utils.hideModal($(".jqmWindow.anchor"));
    }
  });

  $(".verifyEmailNotificationLink").click(function(e) {
    e.preventDefault();

    UC.net.post($(this).attr("href"), function(result) {
      UC.utils.showNotification(result.message, UC.constants.results.success);
    });
  });

  UC.utils.recordKissClickEvent(".filter-btn", "Mobile - Filter Button Click");

  $("body").on("click propogateTracking", ".track-click-paj", function(e) {
    UC.utils.handleGenericClick($(this), "Post a Job Button Click");
  });

  $("body").on("click propogateTracking", ".track-click", function(e) {
    UC.utils.handleGenericClick($(this));
  });

  $(".profile-image-edit").on("click", function() {
    location.href = $(this).data("url");
  });

  $(document).delegate(".area.disabled *", "click", function(event) {
    event.preventDefault();
    event.stopPropagation();
    $(this).blur();
    return false;
  });

  UC.utils.listenForGoogleData();
});

window.UC = window.UC || {};
UC.Components = {};

$.fn.starate = function(options) {
  var opts = $.extend({}, $.fn.starate.defaults, options);

  this.each(function() {
    var $this = $(this),
      dataRate = $this.attr("data"),
      rate = dataRate ? parseFloat(dataRate) * 20 + "%" : 0;

    $this
      .addClass("starate")
      .html('<div class="back"></div><div class="front">')
      .find(".back")
      .css("width", rate);
  });
};

$.fn.starate.defaults = {
  className: "starate",
  rate: 0
};

$.fn.selectable = function(options) {
  var that = this;
  $("body").click(removeActives);

  function removeActives() {
    that.find("tr").removeClass("active");
  }

  function onSelect(e) {
    that.find("tr.active").removeClass("active");
    $(e.currentTarget).addClass("active");
  }

  return this.find("tr").click(onSelect);
};

$.fn.charCounter = function(options) {
  var that = this,
    defaults = {
      countWordsInstead: false,
      label: "Characters",
      className: "",
      min: null,
      max: null,
      validityThreshold: 25,
      allowInvalid: false
    },
    opt = $.fn.extend({}, defaults, options),
    limitsText = "";

  if (opt.min || opt.max) {
    var minText = opt.min ? "minimum {0}".format(opt.min) : "",
      maxText = opt.max ? "/{0}".format(opt.max) : "";

    limitsText = "{0}{1}".format(
      maxText,
      minText != "" ? " (" + minText + ")" : ""
    );
  }

  function wordCountOrTextLength(that) {
    var text = that.val().trim();
    return opt.countWordsInstead ? text.split(/\s+/).length : text.length;
  }

  function onKeyUp() {
    var $this = $(this),
      $counter = $this.next(".char-counter"),
      textLength = wordCountOrTextLength($this),
      valid =
        textLength >= (opt.min || 0) && textLength <= (opt.max || Infinity),
      nearInvalid =
        textLength <= opt.min || textLength > opt.max - opt.validityThreshold;

    $counter[valid ? "addClass" : "removeClass"]("valid")
      .find(".char-count-number")
      .text(textLength);
    $this[nearInvalid ? "addClass" : "removeClass"]("text-danger");
    $counter[nearInvalid ? "addClass" : "removeClass"]("text-danger");
  }

  that.each(function() {
    var $this = $(this);
    if ($this.data("charCounter")) {
      return wordCountOrTextLength($this);
    }

    $this.valid = function() {
      var textLength = wordCountOrTextLength($this);
      return (
        textLength >= (opt.min || 0) && textLength <= (opt.max || Infinity)
      );
    };

    if ($this.is("textarea")) {
      var textLength = wordCountOrTextLength($this),
        valid =
          textLength >= (opt.min || 0) && textLength <= (opt.max || Infinity);

      $this.on("keyup focus countchars", onKeyUp);

      if (opt.max && !opt.allowInvalid && !opt.countWordsInstead) {
        $this.attr("maxlength", opt.max);
      }

      $(
        '<div class="char-counter {0} {1}"><span class="char-count-label">{2}: </span><span class="char-count-number">{3}</span><span class="char-count-limits">{4}</span></div>'.format(
          opt.className,
          valid ? "valid" : "",
          opt.label,
          textLength,
          limitsText
        )
      ).insertAfter($this);
      $this.data("charCounter", true);
    }
  });
};

$.fn.digits = function() {
  return this.each(function() {
    $(this).text(
      $(this)
        .text()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    );
  });
};

$.fn.paginateList = function() {
  var that = this;

  that.each(function() {
    var $this = $(this),
      container = $this.closest(".list-wrap"),
      count = $this.find("li").length,
      pagination = container.find(".list-paginate");

    $this.find("li:nth-child(-n+5)").addClass("displayed");
    UC.utils.showComponent(pagination);

    if (count <= 5) {
      UC.utils.hideComponent(pagination);
    } else {
      container.find(".list-paginate-prev").addClass("disabled");
    }
  });

  function paginate() {
    var $this = $(this),
      container = $this.closest(".list-wrap"),
      displayed = container.find(".displayed"),
      nextItems = displayed.last().nextAll(),
      prevItems = displayed.first().prevAll(),
      items = $this.hasClass("list-paginate-prev") ? prevItems : nextItems,
      hiddenItems = items.slice(0, 5),
      pagination = container.find(".list-paginate-link");

    if ($this.hasClass("disabled")) {
      return;
    }

    pagination.removeClass("disabled");
    displayed.removeClass("displayed");
    hiddenItems.addClass("displayed");

    if (items.length <= 5) {
      $this.addClass("disabled");
    }
  }

  $(".list-paginate-link").click(paginate);
};

UC.utils.queryStringParams = function () {
  //http://stackoverflow.com/questions/4656843/jquery-get-querystring-from-url
  var params = {};
  var paramStrings = window.location.href
    .slice(window.location.href.indexOf("?") + 1)
    .split("&");

  for (var i = 0; i < paramStrings.length; i++) {
    var hash = paramStrings[i].split("=");
    params[hash[0]] = hash[1];
  }

  return params;
};

UC.utils.updateQueryStringParam = function(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

UC.utils.checklistSuccessCallback = function(itemId) {
  var $item = $("#" + itemId).closest(".js-checklist-item"),
    $icon = $item.find(".js-checklist-item-icon");

  $icon.addClass("text-green");
  $icon.removeClass("text-grey-15");

  var completed = $(".js-checklist-item-icon.text-green").length;
  var total = $(".js-checklist-item-icon").length;
  var percent = Math.trunc(completed*100 / total);
  $(".completed-items-counter").text(completed);
  var $progressBar = $(".completed-items-bar");
  $progressBar.text(`${percent} %`);
  $progressBar.css('width', `${percent}%`);
  if (percent >= 70) {
    $progressBar.addClass('progress-bar-green')
  }
};

UC.utils.initNavBar = function() {
  new UC.partials.LawyersNavDesktop();
  new UC.partials.LawyersNavMobile();

  if($("#aQuoteCalculator").length) {
    var quoteCalculatorModal = new UC.modals.QuoteCalculatorModal({
      el: '#modalQuoteCalculator'
    });

    $("#aQuoteCalculator").on("click", function (e) {
      quoteCalculatorModal.open();
    });
  }
};

UC.utils.sendCopyContextToKissMetrics = function(event) {
  var page_path = window.location.href;
  var selection = window.getSelection().toString().substring(0,50);

  if ( ( event === 'general_context_menu_triggered' && selection.length > 0 ) ||
       ( event === 'general_copy_triggered' && UC.user ) ) {

    var kissData = {
      length: selection.length,
      contents: selection,
      page_path: page_path
    };

    UC.utils.recordKissEvent(event, kissData);
  }
};

UC.utils.trackCopyAndContextMenu = function() {
  document.oncontextmenu = function () {
    UC.utils.sendCopyContextToKissMetrics('general_context_menu_triggered');
  };

  document.addEventListener('copy', function(e){
    UC.utils.sendCopyContextToKissMetrics('general_copy_triggered');
  });
};

UC.utils.listenForGoogleData = function(){
  var goKiss = false;

  if (window.gaData) {
    UC.utils.setGoogleOptimizeCookie();
  } else {
    Object.defineProperty(window, 'gaData', {
      configurable: true,
      enumerable: true,
      writeable: true,
      get: function() {
        return this._gaData;
      },
      set: function(val) {
        // When Google fills out this object, we'll check for it

        this._gaData = val;
        UC.utils.setGoogleOptimizeCookie();
      }
    });
  }
};

UC.utils.setGoogleOptimizeCookie = function(){

  if (typeof gaData === 'undefined' || Object.keys(gaData).length === 0) {
    // Google has set the object, but still waiting the internals
    return;
  }

  var gaProperties = Object.keys(gaData);
  var ucGoCookie = JSON.parse(Cookies.get('uc_go') || "{}");

  $.each(gaProperties, function( i, propertyId ) {

    if (typeof gaData[propertyId].experiments === 'undefined'){
      // experiments haven't loaded in yet
      return true;
    }

    var experiments = Object.keys(gaData[propertyId].experiments);

    $.each(experiments, function (i, experimentId) {
      var variationId = gaData[propertyId].experiments[experimentId];

      if (ucGoCookie[experimentId] != variationId) {
        //experiment variant either unset or changed, let's update

        ucGoCookie[experimentId] = variationId;
        var properties = {experimentId: experimentId, variant: variationId};
        UC.utils.recordKissEvent('optimize experiment stored', properties);
      }
    });
  });

  // Needs to be available to trk.upcounsel.com, WILL be also in play on Staging etc.
  Cookies.set('uc_go', JSON.stringify(ucGoCookie), { domain: '.upcounsel.com' });
};
