UC.views.UsersAtlas =
UC.views.UsersAtlasSubsidiary =
UC.views.UsersAtlasDissolution =
Backbone.View.extend({

  events: {
    'click #btn-get-started': 'showRegisterModal'
  },

  initialize: function() {
    new UC.partials.UsersForm({
      onRegister: this.onRegister.bind(this)
    });
  },

  showRegisterModal: function(e) {
    e.preventDefault();
    this.$('#modal-register').modal();
  },

  onRegister: function() {
    this.$('.modal-title').text('Check your email');
    this.$('#atlasNewUserForm').addClass('hide');
    this.$('#atlasPendingPanel').removeClass('hide');
  }
});
