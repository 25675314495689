UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.CurrentTimesheetLineItemsForClientView = Backbone.View.extend({

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/current_timesheet_line_items_for_client"),

  tagName: 'div',

  id: 'current-timesheet-line-items',

  initialize: function(opts) {
    this.clientId = opts.clientId;
    this.$parentElement = $(opts.parentElement);

    this.collection = new UC.timesheetComponent.TimesheetLineItems(null, {
      clientId: this.clientId,
      filterOptions: {
        current_line_items: true
      }
    });

    this.timesheetTableView = new UC.timesheetComponent.TimesheetTableView({
      collection: this.collection,
      allowNewTimesheetLines: !!opts.allowNewTimesheetLines,
      showDeleteColumn: true,
      showJobColumn: true,
      showTypeSelect: true
    });

    this.timesheetTotalsView = new UC.timesheetComponent.TimesheetTotalsView({
      collection: this.collection
    });

    this.collection.on('reset', this.render.bind(this));

    this.createTimesheetInvoiceButtonView = new UC.timesheetComponent.CreateTimesheetInvoiceButtonView({
      collection: this.collection
    });

  },

  render: function() {
    this.$el.html(this.template(this.collection));

    if (this.collection.showIntro) this.$('#timesheet-intro-container').html(this.introHtml());

    this.timesheetTableView.render();
    this.$('#timesheet-line-item-container').html(this.timesheetTableView.$el);

    this.timesheetTotalsView.render();
    this.$('#timesheet-totals-container').html(this.timesheetTotalsView.$el);

    this.createTimesheetInvoiceButtonView.render();
    this.$('#create-ts-inv-btn-container').html(this.createTimesheetInvoiceButtonView.$el);

    this.$parentElement.html(this.$el);
  },

  introHtml: function() {
    return UC.views.resolveHandlebarsTemplate("components/timesheets/timesheet_intro")();
  }

});
