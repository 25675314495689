UC = UC || {};

UC.attorneyCreatedReviewsModal = {

  init: function(openModalSelector) {
    this.$openModalBtn = $(openModalSelector);
    this.$modalAddReviews = $('#modalAddReviews');
    this.$btnSaveReview = this.$modalAddReviews.find('#btnSaveReview');
    this.$txtTitle = this.$modalAddReviews.find('#txtTitle');
    this.$txtReview = this.$modalAddReviews.find('#txtReview');
    this.$creationSource = this.$modalAddReviews.find('#creation_source');

    this.$btnSaveReview.click(this.saveReview.bind(this));

    this.$modalAddReviews.find('.js-pf-additional-label').on('click', this.triggerExampleBox.bind(this));
    this.btnSaveLoader = $('#btnSaveReview').buttonLoader();

    this.$openModalBtn.on('click', this.openReviewModal.bind(this));
  },

  openReviewModal: function (e) {
    e.preventDefault();

    this.$modalAddReviews.find('.row').removeClass('has-error');

    this.$modalAddReviews.modal({
      backdrop: 'static'
    });
  },

  triggerExampleBox: function(e) {
    e.preventDefault();
    $(e.target).closest('.modal').find('.pf-additional').toggleClass('hide');
  },

  saveReview: function() {
    var errors = [];
    UC.utils.hideValidationErrors('has-error');
    $('.form-group').removeClass('has-error');

    var data = {
      JobTitle: this.$txtTitle.val().trim(),
      DescriptionPublic: this.$txtReview.val().trim(),
      CreationSource: this.$creationSource.val().trim()
    };

    if (data.JobTitle == '') {
      errors.push({element: this.$txtTitle, text: 'Please enter a project description'});
    }

    if (data.DescriptionPublic == '') {
      errors.push({element: this.$txtReview, text: 'Please enter a client review'});
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, {className: 'form-group'});
      return
    } else {
      this.btnSaveLoader.showLoader();
      this.submitReview(data);
    };
  },

  submitReview: function(data) {
    UC.net.post('/attorney/add_review', { review: data }, function(response) {
      UC.utils.showNotification('Review was successfully added.', UC.constants.results.success);
      this.$txtTitle.val('');
      this.$txtReview.val('');

      if (response.numReviews < 2) {
        this.$btnSaveReview.text('Save');
      } else {
        this.$modalAddReviews.modal('hide');
      }

      if (response.numReviews > 1) {
        UC.utils.checklistSuccessCallback('btnAddReview');
      }

      if (UC.controller === 'attorney_profile' && response.numReviews > 1) {
        this.$openModalBtn.remove();
      }
    }.bind(this)).fail(function(response) {
      var showCallback = function() {
        $('#modalAddReviews').addClass('modal-stacked');
      };

      var hideCallback = function() {
        $('#modalAddReviews').removeClass('modal-stacked');
      };

      UC.closeTheLoop.handleFailure(response, false, showCallback, hideCallback);

    }.bind(this)).always(function() {
      this.btnSaveLoader.hideLoader();
    }.bind(this));
  }

};
