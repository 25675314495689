UC.partials.JobInfoPanel = Backbone.View.extend({

  el: '#job-info-panel',

  events: {
    'click #show-job-info' : 'showJobInfoPanelContent',
    'click .hide-job-info' : 'hideJobInfoPanelContent',
    'click .read-more-ji-panel' : 'showFullContent',
    'click #qa-panel-read-more' : 'showFullQAPanel'
  },

  initialize: function() {
    this.$qAPanel = $('#qa-panel');
    this.$qAReadMore = $('#qa-panel-read-more');
    this.initQuesAnsPanel();
  },

  showJobInfoPanelContent: function() {
    $('#job-info-panel').addClass('show-content');
  },

  hideJobInfoPanelContent: function() {
    $('#job-info-panel').removeClass('show-content');
  },

  showFullContent: function(e) {
    e.preventDefault();
    $(e.currentTarget).closest('.ji-panel-section').addClass('show-full');
  },

  showFullQAPanel: function(e) {
    e.preventDefault()
    this.$('#qa-panel').addClass('show-all')
    this.$('#qa-panel-read-more').addClass('hidden');
    this.$('.fadeout-bottom').addClass('hidden');
  },

  initQuesAnsPanel: function() {
    var panelDiv = document.getElementById('qa-panel');
    if (!panelDiv) return;

    var panelDivHeight = panelDiv.clientHeight;
    var panelContentHeight = panelDiv.scrollHeight;

    if (panelContentHeight > panelDivHeight) {
      this.$('#qa-panel-read-more').removeClass('hidden');
      $(panelDiv).find('.fadeout-bottom').removeClass('hidden');
      this.$('.fadeout-bottom').removeClass('hidden');
    }
  }

})
