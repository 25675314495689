!function() {

  UC.views.FreeLegalIndex = Backbone.View.extend({

    events: {
      'submit #legal_search': 'formSubmit',
      'blur #legal_search_input': 'pushState',
      'keydown #legal_search_input': 'searchTimeout',
      'click .horizontal-list-filter-option': 'filterResults',
      'click .free-legal-docs-tile-filter': 'toggleLegalArea',
      'click #search_results_pagination .page-link': 'showPage'
    },

    initialize: function() {
      var search = UC.constants.search || {};

      this.query = search.q;
      this.page = search.page;
      this.filter = search.filter;
      this.legal_areas = search.legal_areas;

      this.$search_input = this.$el.find('#legal_search_input');
      this.$search_input.focus().val(this.query);

      window.onpopstate = this.popState.bind(this);
    },

    searchTimeout: function(e) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(function() {
        var query = this.$search_input.val();

        if (query == this.query) {
          // query hasn't changed
          return;
        }

        this.page = 1;
        this.query = query;
        this.performSearch();
      }.bind(this), 100);
    },

    formSubmit: function(e) {
      clearTimeout(this.timeout);
      e.preventDefault();

      var $search_results = this.$el.find('#search_results_wrap');

      $search_results.css({
        'height': $search_results.height(),
        'visibility': 'hidden'
      })

      this.page = 1;
      this.query = this.$search_input.val();

      this.pushState();
      this.performSearch(function(data) {

        if ($(window).isMobileWidth()) {
          UC.utils.scrollToElement('#legal_search_wrap', {
            offset: 0
          });
        }

        $search_results.css({
          'height': 'auto',
          'visibility': 'visible'
        }).addClass('animated fadeIn');

        setTimeout(function() {
          // reset for next time
          $search_results.removeClass('animated fadeIn');
        }, 500);
      }.bind(this));

      this.recordFilterTracking('free_legal#index form submit');
    },

    showPage: function(e) {
      e.preventDefault();
      UC.utils.scrollToTop();
      this.page = $(e.currentTarget).data('page');
      this.performSearch();
      this.pushState();
    },

    filterResults: function(e) {
      e.preventDefault();

      var $filter = $(e.currentTarget);
      var type = $filter.data('type');

      this.page = 1;
      this.selectFilter(type);

      this.pushState();
      this.performSearch();

      this.recordFilterTracking('free_legal#index horizontal list filter clicked', {
        filter_type: type
      });
    },

    toggleLegalArea: function(e) {
      clearTimeout(this.timeout);

      this.page = 1; // reset back to page one
      this.legal_areas = this.legal_areas || [];

      var $legal_area = $(e.currentTarget);
      var legal_area_id = $legal_area.data('legal-area');

      if ($legal_area.hasClass('active')) {
        var index = this.legal_areas.indexOf(legal_area_id);
        this.legal_areas.splice(index, 1);
        $legal_area.removeClass('active');

        this.recordFilterTracking('free_legal#index legal area filter deactivated', {
          legal_area_id: legal_area_id
        });
      } else {
        this.legal_areas.push(legal_area_id);
        $legal_area.addClass('active');

        this.recordFilterTracking('free_legal#index legal area filter activated', {
          legal_area_id: legal_area_id
        });
      }

      this.pushState();
      this.performSearch();
    },

    performSearch: function(callback) {

      if (this.ajax && typeof this.ajax.abort === 'function') {
        // abort any running requests
        this.ajax.abort();
      }

      var options = {
        q: this.query,
        filter: this.filter,
        legal_areas: this.legal_areas,
        page: this.page
      };

      this.ajax = UC.net.get('/free-legal-documents.json', options, function(data) {
        this.updateFilter(data);
        this.$('#search_results').html(data.html);

        if (typeof callback === 'function') {
          callback(data);
        }

        this.recordFilterTracking('free_legal#index search performed');
      }.bind(this));

      this.ajax.fail(function(xhr, textStatus) {
        if (textStatus === 'abort') {
          // don't show angry message on aborts (from above)
          xhr.handled = true;
        }
      });
    },

    updateFilter: function(data) {
      var $filter = this.$el.find('#search_results_filter');
      var type_facets = data.facets['_type'] || {};
      var document_count = type_facets.Document || 0;
      var article_count = type_facets.Article || 0;
      var filters = {
        'all': document_count + article_count,
        'documents': document_count,
        'articles': article_count
      };

      for (var type in filters) {
        var count = filters[type];
        var $li = $filter.find('a[data-type={0}]'.format(type)).closest('li');
        var $count = $li.find('span.count');

        $count.text(count);

        if (count > 0) {
          $li.removeClass('hide');
        } else if (type !== 'all') {
          $li.addClass('hide');
        }
      }

      if (data.total_entries > 0) {
        $filter.removeClass('hide');
      } else if (this.filter === 'all') {
        $filter.addClass('hide');
      }
    },

    selectFilter: function(filter) {
      this.filter = filter || 'all';

      this.$el.find('#search_results_filter li').removeClass('active');
      this.$el.find('#search_results_filter a[data-type={0}]'.format(this.filter))
        .closest('li').addClass('active');
    },

    selectLegalAreas: function(legal_areas) {
      legal_areas = legal_areas || [];
      var $tiles = this.$('.free-legal-docs-tile-filter');

      $tiles.removeClass('active');

      for (var i=0; i<legal_areas.length; i++) {
        var legal_area = legal_areas[i];
        var $tile = $tiles.filter('[data-legal-area={0}]'.format(legal_area));

        $tile.addClass('active');
      }
    },

    recordFilterTracking: function(event_name, attrs) {
      _.extend(attrs, this.currentFiltersState());
      UC.utils.recordKissEvent(event_name, attrs);
    },

    currentFiltersState: function() {
      return {
        active_horizontal_filter: this.filter,
        active_legal_area_id_filters: this.legal_areas,
        query: this.query,
        page: this.page
      };
    },

    updateQueryStringParam: function(uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf('?') !== -1 ? "&" : "?";

      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
      } else {
        return uri + separator + key + "=" + value;
      }
    },

    pushState: function() {
      var changed = false;
      var old_state = history.state || {};
      var new_state = old_state;

      if (this.query != old_state.q) {
        new_state.q = this.$search_input.val();
        changed = true;
      }

      if (this.page != old_state.page) {
        new_state.page = this.page;
        changed = true;
      }

      if (this.filter != old_state.filter) {
        new_state.filter = this.filter;
        changed = true;
      }

      if (this.legal_areas != old_state.legal_areas) {
        new_state.legal_areas = this.legal_areas;
        changed = true;
      }

      if (changed) {
        url = "{0}?{1}".format(window.location.pathname, $.param(new_state));
        window.history.pushState(new_state, null, url);
      }
    },

    popState: function(e) {

      if (e.state) {
        this.query = e.state.q;
        this.filter = e.state.filter;
        this.page = e.state.page;
        this.legal_areas = e.state.legal_areas;
      } else {
        this.query = this.filter = this.page = this.legal_areas = null;
      }

      this.$search_input.val(this.query);
      this.selectFilter(this.filter);
      this.selectLegalAreas(this.legal_areas);
      this.performSearch();
    }
  });
}();
