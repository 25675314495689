!function() {

  UC.views.StaticPagesSitemap = Backbone.View.extend({
    initialize: function() {
      new UC.components.TableOfContents({
        scope: '#sitemap-content',
        affixTop: 268,
        affixBottom: function() {
          return $('#content').height() - $('#sitemap-content').height() - 170;
        },
        tocEnabledColumnClass: 'col-md-10',
        tocDisabledColumnClass: 'col-md-12'
      })
    },
  })

}();
