UC.partials.OverviewForPaidConsultation = Backbone.View.extend({
  events: {
    'click .js-schedule': 'showPaymentOptionsOrSchedule',
    'click #js-consultation-add-credit-card': 'showCreditCardForm',
    'click #js-cancel-add-credit': 'cancelAddCreditCard',
    'click #js-show-schedule-call': 'showScheduleCall',
    'click #js-exit-schedule-call': 'exitScheduleCall',
    'click #js-schedule-call': 'scheduleCall'
  },

  initialize: function() {
    var data = $('#js-schedule-call-panel').data();
    this.isCallScheduled = data.scheduled;
    this.isCallPending = data.pending;
    this.callTime = moment(data.callTime, 'MM/DD/YY h:mma z');
    this.hasPaymentMethod = data.payment;
    this.attorneyId = data.attorneyId;
    this.bidId = data.bidId;
    this.isHired = data.hired;
    this.$getStartedButtonLoader = $('.js-schedule').buttonLoader();

    UC.utils.initRatingDimensions('.js-attorney-rating');

    new UC.components.PaymentInfo({
      el: $('body'),
      showDefaultButtonLoader: true,
      bankAccountSuccessCallback: function() {
        this.hasPaymentMethod = true;
        this.hireAttorney();
      }.bind(this)
    });

    if(this.$el.find('.js-schedule-call-instant').length > 0) {
      this.scheduleCallForm = new UC.partials.ScheduledCallsFormReply({
        isModal: false,
        instant: true,
        submitButton: $('#js-schedule-call'),
        attorneyId: this.attorneyId,
        jobId: UC.constants.jobId,
        removeAlternativeTimeOptions: true,
        onReply: function (result) {
          // if call scheduled vs call requested
          if (result.data) {
            $('#js-scheduled_call-date-time').text(result.data.scheduled_dt_fancy);

            this.isCallScheduled = true;

            this.showCallConfirmation();
          } else {
            this.showCallRequestedConfirmation();
          }

          this.scrollTo();
        }.bind(this),
      });
    } else {
      new UC.partials.ScheduledCallsFormRequest({
        el: this.$el,
        onRequest: this.onNonInstantCallSchedule.bind(this),
        jobId: UC.constants.jobId
      });
    }

    this.initialView();
  },

  onNonInstantCallSchedule: function(_result) {
    this.$("#js-submit-step").toggleClass("hidden");
    this.$("#js-availability-step").toggleClass("hidden");
    this.showCallRequestedConfirmation();
    this.scrollTo();
  },

  scrollTo: function() {
    var currentScreenPosition = $(document).scrollTop();

    var scrollTo = $('.js-PaidConsultationOverview').offset().top;

    if (currentScreenPosition > scrollTo) {
      $('html, body').animate({ scrollTop: scrollTo }, 500);
    }
  },

  initialView: function() {
    if (this.isCallScheduled) {
      var now = moment();

      if (now.isAfter(this.callTime)) {
        this.showAfterCallStep();
      } else {
        this.showCallConfirmation();
      }
    } else {
      if(this.isCallPending) {
        this.showCallRequestedConfirmation();
      } else {
        this.showIntro();
      }
    }
  },

  showIntro: function() {
    $('.js-schedule-call-flow-container').addClass('hidden');
    $('#js-consultation-intro-container').removeClass('hidden');
  },

  exitScheduleCall: function() {
    this.initialView();
    this.scrollTo();
  },

  showPaymentOptionsOrSchedule: function() {
    if(this.hasPaymentMethod) {
      if (this.isHired) {
        this.showScheduleCall();
      } else {
        this.hireAttorney(this.$getStartedButtonLoader);
      }
    } else {
      this.showPaymentOptions();
    }
  },

  hireAttorney: function(buttonLoader) {
    var options = {};
    if (buttonLoader) {
      buttonLoader.showLoader();
      options = { loaders: [buttonLoader] }
    }

    UC.net.post('/jobs/{0}/proposals/{1}/accept'.format(UC.constants.jobId, this.bidId), { remove_success_flash: true }, null, options)
      .done(function() {
        this.isHired = true;
        this.showScheduleCall();
      }.bind(this));
  },

  showPaymentOptions: function() {
    $('.js-schedule-call-flow-container').addClass('hidden');
    $('#js-consultation-add-payment-container').removeClass('hidden');
    this.scrollTo();

    UC.utils.sendNesEvent('start_opportunity_to_pay', {
      attorney_id: this.attorneyId
    });
  },

  showCreditCardForm: function() {
    $('.js-schedule-call-flow-container').addClass('hidden');
    $('#js-consultation-credit-card-container').removeClass('hidden');
    $('#js-consultation-credit-card-container').find('select').select2({ width: '100%' });
    $('.fancy-check').fancyCheck();

    this.scrollTo();

    new UC.views.AccountNewCreditCard({
      el: '#js-consultation-credit-card-container',
      saveCreditCardSuccessCallback: function() {
        this.hasPaymentMethod = true;
        this.hireAttorney();
      }.bind(this)
    });
  },

  cancelAddCreditCard: function() {
    $('.js-schedule-call-flow-container').addClass('hidden');
    $('#js-consultation-add-payment-container').removeClass('hidden');

    this.scrollTo();
  },

  showScheduleCall: function(e) {
    if (e) e.preventDefault();
    $('.js-schedule-call-flow-container').addClass('hidden');
    $('#js-schedule-call-container').removeClass('hidden');

    this.scrollTo();
  },

  scheduleCall: function(e) {
    this.scheduleCallForm.reply(e);
  },

  showCallConfirmation: function() {
    $('.js-schedule-call-flow-container').addClass('hidden');
    $('#js-call-confirmation-container').removeClass('hidden');
  },

  showCallRequestedConfirmation: function() {
    $('.js-schedule-call-flow-container').addClass('hidden');
    $('#js-call-requested-confirmation-container').removeClass('hidden');
  },

  showAfterCallStep: function () {
    $('.js-schedule-call-flow-container').addClass('hidden');
    $('#js-about-attorney').addClass('hidden');

    $('#js-after-call-container').removeClass('hidden');
    $('#js-after-call-questions').removeClass('hidden');
  }
});
