import Clipboard from 'clipboard' // webpack-only-import

UC.components.InputWithCopyButton = Backbone.View.extend({
  initialize: function(options) {
    const inputId = options.inputId;
    const buttonId = `#${inputId}-copy-button`
    const copySpanId = `#${inputId}-copy-span`
    const copiedSpanId = `#${inputId}-copied-span`
    const selectedSpanId = `#${inputId}-selected-span`

    this.clipboard = new Clipboard(buttonId, {
      target: function() {
        return document.getElementById(inputId);
      }
    });

    this.clipboard.on('success', function() {
      $(copySpanId).addClass('hidden');
      $(copiedSpanId).removeClass('hidden');
      this.resetCopyLink(copySpanId, copiedSpanId, selectedSpanId);
    }.bind(this));

    this.clipboard.on('error', function() {
      $(copySpanId).addClass('hidden');
      $(selectedSpanId).removeClass('hidden');
      this.resetCopyLink(copySpanId, copiedSpanId, selectedSpanId);
    }.bind(this));

    $(buttonId).on('click', function(e) {
      e.preventDefault()
    });
  },

  resetCopyLink: function(copySpanId, copiedSpanId, selectedSpanId) {
    if (this.timeout) {
      return;
    }

    this.timeout = setTimeout(function() {
      $(copySpanId).removeClass('hidden');
      $(copiedSpanId).addClass('hidden');
      $(selectedSpanId).addClass('hidden');
      clearTimeout(this.timeout);
      this.timeout = null;
    }.bind(this), 1000);
  },
});
