var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n\n    <div class=\"modal-header\">\n      <h4 class=\"modal-title\">Request a Proposal</h4>\n    </div>\n\n    <form>\n\n      <div class=\"modal-body\">\n        <div class=\"form-group\">\n\n          <label for=\"invite_message\" class=\"control-label\">\n            Send a message to your attorney to request a proposal.\n          </label>\n\n          <textarea id=\"invite_message\" name=\"message\" class=\"form-control\" rows=\"9\">Hi "
    + alias4(((helper = (helper = lookupProperty(helpers,"attorney_first_name") || (depth0 != null ? lookupProperty(depth0,"attorney_first_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"attorney_first_name","hash":{},"data":data,"loc":{"start":{"line":17,"column":88},"end":{"line":17,"column":111}}}) : helper)))
    + ",\n\nI'd like to request a proposal from you for my project \""
    + alias4(((helper = (helper = lookupProperty(helpers,"job_title") || (depth0 != null ? lookupProperty(depth0,"job_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"job_title","hash":{},"data":data,"loc":{"start":{"line":19,"column":56},"end":{"line":19,"column":69}}}) : helper)))
    + "\".\n\nI look forward to hearing from you.\n\nKind regards,\n"
    + alias4(((helper = (helper = lookupProperty(helpers,"client_full_name") || (depth0 != null ? lookupProperty(depth0,"client_full_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"client_full_name","hash":{},"data":data,"loc":{"start":{"line":24,"column":0},"end":{"line":24,"column":20}}}) : helper)))
    + "</textarea>\n        </div>\n      </div>\n\n      <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-md btn-default\" data-dismiss=\"modal\">Cancel</button>\n        <button type=\"submit\" class=\"btn btn-md btn-primary\" id=\"send_invite\">Send Request</button>\n      </div>\n    </form>\n  </div>\n</div>\n";
},"useData":true});