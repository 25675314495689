import { GeoLocationPajWidjet } from '../../../components/geo_location_paj_widget'

UC.views.StaticPagesHome = Backbone.View.extend({

  initialize: function() {
    UC.utils.initTransparentNav('#nav');

    $(".hp-square-btns .primary-cta").on('click',function(e){
      var cat = $(e.target).closest('.hp-square-btns').attr('data-cat');
      UC.utils.recordKissEvent("Home_Page_Grid_Click", {"category": cat, "call_to_action":"primary"});
      UC.utils.recordKissEvent("Post a Job Button Click", {"location" : "hp-grid", "category": cat, "page-path":"/"});
    });

    $(".hp-square-btns .secondary-cta").on('click',function(e){
      var cat = $(e.target).closest('.hp-square-btns').attr('data-cat');
      UC.utils.recordKissEvent("Home_Page_Grid_Click", {"category": cat, "call_to_action":"secondary"});
    });

    if(this.$('.js-geo-location').length) {
      new GeoLocationPajWidjet({el: this.$('.js-geo-location')});
    }
  },
});
