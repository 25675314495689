UC.partials.modalV9 = Backbone.View.extend({

  tagName: 'div',

  className: 'modal v9',

  events: {
    "hidden.bs.modal" : "deleteElement",
    "shown.bs.modal" : "triggerRenderCallback"
  },

  template: UC.views.resolveHandlebarsTemplate("shared/modal_v9"),

  initialize: function(opts) {
    this.modalContent = opts.modalContent;
    this.modalTitle = opts.modalTitle;
    this.modalSubTitle = opts.modalSubTitle;
    this.modalFooter = opts.modalFooter;
    this.renderCallback = opts.renderCallback;
    this.modalOptions = opts.modalOptions || {};
    this.customHeaderClass = opts.customModal ? 'modal-header--basic' : '';
    this.render();
  },

  render: function() {
    this.$el.html(this.template(this));
    this.$el.modal(this.modalOptions);
  },

  triggerRenderCallback: function() {
    if (this.renderCallback) {
      this.renderCallback();
    }
  },

  deleteElement: function() {
    this.$el.remove();
  }

});
