UC.partials.PackageSelection = Backbone.View.extend({

  events: {
    'click .js-selectOptionBtn' : 'handleSelectOption',
  },

  initialize: function(options) {
    UC.utils.initRatingDimensions('.js-reviews');

    this.nextButtonCallback = options.nextButtonCallback;

    this.stylePanel();
  },

  stylePanel: function() {
    var $panel = this.$el.find('#panelCustomProposalsDescription, .js-panel-description');

    $panel.find('ul').addClass('list');
    $panel.find('li').
      prepend('<i class="fontello icon-ok text-green"></i>').
      not(':last').addClass('marg-b-10');
  },

  validate: function(dfd) {
    var data = this.$el.serializeJSON();

    if (this.selectOptionData) {
      $.extend(data, this.selectOptionData);
    } else if(isDeclinedPackage(data.package_selection)) {
      $.extend(data.package_selection, { decline: true })
    }

    dfd.resolve(data);

    function isDeclinedPackage(package_selection_data) {
      return(
        Object.keys(package_selection_data).some(function(key){
          return package_selection_data[key] === 'decline';
        })
      );
    }
  },

  handleSelectOption: function(e) {
    e.preventDefault();

    var data = $(e.target).data();

    if ($.isEmptyObject(data)) {
      this.selectOptionData = { "package_selection" : "" };
    } else {
      this.selectOptionData = data;
    }

    this.nextButtonCallback.call();
  }
});
