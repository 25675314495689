import { TextareaAutoSize } from 'textarea-autosize'

!function() {

  UC.views.MessagesIndex = Backbone.View.extend({

    events: {
      'click .thread-item': 'clickThreadItem',
      'click .btn-filter': 'filter',
      'click .messages_search_clear' : 'clearSearch',
      'submit #formMessageSearch' : 'search',
      'click #btnLoadMore' : 'loadMore',
      'click .btn-schedule-call-reply': 'scheduleCallReply',
      'click .cancel-sched-call' : 'openCancelSchedCallModal'
    },

    initialize: function() {
      this.activeThreadId;
      this.page = 1;

      this.messagesCreate =
        new UC.partials.MessagesCreate({
          messageSentCallbacks: [this.messageSentCallback.bind(this)]
      });
    },

    messageSentCallback: function() {
      $('.thread-content').scrollTop($('.thread-content')[0].scrollHeight);
      var $thread = $(".thread-list [data-thread-id={0}]".format(this.activeThreadId));
      var time = new Date().getTime() / 1000;
      $thread.parent().prepend($thread);
      $thread.find('.last-message-words').data('time', time).text('Just now');
      $('.thread-list').scrollTop(0);
      $('#txtReplyMessage').height($('#txtReplyMessage').data('original-height'));
    },

    clickThreadItem: function(e) {
      if ($(e.currentTarget).hasClass('active')) {
        return false;
      } else {
        var threadId = $(e.currentTarget).data('thread-id'),
            $thread = $(".thread-item[data-thread-id='{0}']".format(threadId));

        $('.thread-item').removeClass('active');

        var eventProperties = {
          location: 'inbox',
          thread_id: threadId
        };

        if ($thread.hasClass('unread')) {
          if (!UC.user.impersonate) {
            $thread.removeClass('unread');
            $thread.find('.unread-count').remove();
          }
          eventProperties.unread = true;
        } else {
          eventProperties.unread = false;
        }

        $thread.addClass('active');
        UC.utils.sendNesEvent('message_thread_viewed', eventProperties);

        UC.net.get('messages/threads/{0}'.format(threadId), null, function(err, res){
          if (err) {
            UC.utils.showNotification(err, UC.constants.results.failure);
          } else {
            $('.thread-container').html(res.html);

            if ($(window).width() < 992 && !$('.thread-container').is(':visible')){
              $('.messages-left').hide();
              $('.thread-container').show();
              $('.thread-header .glyphicon-menu-left').click(function(){
                $('.messages-left').show();
                $('.thread-container').hide();
                $('.thread-item').removeClass('active');
              })
            }

            this.messagesCreate.initCentralMessages(res.messageConstants);

            $('.thread-content').scrollTop($('.thread-content')[0].scrollHeight);
            if (!$(window).isMobileWidth()){
              $('#txtReplyMessage').focus();
            }
            this.activeThreadId = threadId;

            new TextareaAutoSize(document.getElementById('txtReplyMessage'));

            UC.job = res.job;

            $('.notification-pulldown-wrapper').replaceWith(res.notificationHtml);
            new UC.components.Notifications();

            if (res.unreadCount > 0) {
              $('.chat-filters .unread-count-circle').text(res.unreadCount);
            } else {
              $('.chat-filters .unread-count-circle').remove();
            }
          }
        }.bind(this));
      }
    },

    filter: function(e) {
      if ($(e.currentTarget).hasClass('active')) {
        return false;
      } else {
        var filter = $(e.currentTarget).data('filter');
        $('.btn-filter').removeClass('active');
        $(e.currentTarget).addClass('active');
        this.page = 1;
        this.getData();
      }
    },

    search: function(e) {
      e.preventDefault();
      this.page = 1;

      var q = $('#txtMessageSearch').val().trim();
      if (q && q !== '') {
        this.$el.find('.messages_search_clear').removeClass('hide');
        this.$el.find('.messages_search_icon').addClass('hide');
      }

      this.getData();
    },

    clearSearch: function(e) {
      $('#txtMessageSearch').val('');
      this.$el.find('.messages_search_clear').addClass('hide');
      this.$el.find('.messages_search_icon').removeClass('hide');
      this.search(e);
    },

    loadMore: function(e) {
      e.preventDefault();
      this.page++;
      this.getData();
    },

    getData: function() {
      var data = {
        filter: $('.btn-filter.active').data('filter'),
        q: $('#txtMessageSearch').val().trim(),
        page: this.page
      };

      UC.net.get(location.href, data, function(res) {
        if (this.page == 1) {
          $('.thread-list').replaceWith(res.html);
        } else {
          $('.thread-list').append($(res.html).html());
        }

        if (res.showMore) {
          $('.thread-list-container').addClass('load-more');
        } else {
          $('.thread-list-container').removeClass('load-more');
        }

        if (data.q!= '') {
          var count = res.total_entries,
              suffix = (count == 1 ? '' : 's'),
              infoText= "{0} result{1} for \"{2}\" in your inbox".format(count, suffix, data.q);
          $('.thread-list-container').addClass('search-results');
          $('.search-results-count-container').text(infoText).removeClass('hidden');
        } else {
          $('.thread-list-container').addClass('search-results');
          $('.search-results-count-container').text(infoText).addClass('hidden');
        }

        if ($('.thread-item').length > 0) {
          $('.thread-list-container').removeClass('threads-none');
        } else {
          $('.thread-list-container').addClass('threads-none');
        }
      }.bind(this));

    },

    scheduleCallReply: function(e) {
      e.preventDefault();

      new UC.modals.ScheduledCallsShow({
        scheduled_call_id: $(e.target).data('id')
      }).render();
    },

    openCancelSchedCallModal: function(e) {
      e.preventDefault();
      var scheduledCallId = $(e.currentTarget).data('scheduled-call-id');
      new UC.partials.ScheduledCallsCancel({scheduledCallId: scheduledCallId});
    }

  });
}();
