UC.partials.LawyersNavDesktop = Backbone.View.extend({
  el: '#lawyers-nav-item',

  events: {
    'mouseover  .topic-link-row': 'highlightTopicGroup',
    'mouseout   .topic-link-row': 'removeTopicGroupHighlight',
    'click      #lawyers-nav-link': 'lawyersNavClick',
    'click      .topic-link': 'trackTopicLinkClick'
  },

  initialize: function() {
    this.$lawyersNavItem = $('#lawyers-nav-item');
    this.$lawyersTopicGroup = $('#lawyers-topic-group-cell');
    this.$lawyersNavDropdown = $('#lawyers-nav-dropdown');
    this.$topicGroupLink = $('.topic-link-row');

    // Hover doesn't play nice in events, binded here
    this.$lawyersNavItem.hover(this.initiateHoverDelayToDisplayDropdown.bind(this), this.hidelawyersDropdown.bind(this));
  },

  lawyersNavClick: function (e) {
    e.preventDefault();
    this.displaylawyersDropdown();
  },

  initiateHoverDelayToDisplayDropdown: function () {
    this.timeoutConstant = setTimeout(function () {
      this.displaylawyersDropdown();
    }.bind(this), 300);
  },

  displaylawyersDropdown: function () {
    this.$lawyersNavItem.addClass('lawyers-dropdown-displayed');
    UC.utils.recordKissEvent('lawyers_nav_dropdown_shown', {currentUrl: window.location.href});
  },

  hidelawyersDropdown: function () {
    clearTimeout(this.timeoutConstant);
    this.$lawyersNavItem.removeClass('lawyers-dropdown-displayed');
    this.$topicGroupLink.removeClass('active');
    this.$lawyersTopicGroup.removeClass('active');
    this.$lawyersNavDropdown.removeClass('lawyers-group-displayed');
  },

  highlightTopicGroup: function (e) {
    var $selectedTopicGroupLink = $(e.currentTarget);
    this.$topicGroupLink.removeClass('active');
    $selectedTopicGroupLink.addClass('active');
  },

  removeTopicGroupHighlight: function (e) {
    this.$topicGroupLink.removeClass('active');
  },

  trackTopicLinkClick: function (e) {
    var $linkClicked = $(e.currentTarget);
    var linkPath = $linkClicked.attr('href');
    var linkText = $linkClicked.text();

    UC.utils.recordKissEvent('lawyers_nav_link_clicked', {
      currentUrl: window.location.href,
      linkPath: linkPath,
      linkText: linkText
    });
  }
});

UC.partials.LawyersNavMobile = Backbone.View.extend({
  el: '#lawyers-mobile-nav-parent',

  events: {
    'click .parent-mobile-nav-link': 'toggleNavGroup'
  },

  initialize: function () {
    this.$parentMobileNavGroups = $('.parent-mobile-nav-group');

    this.$parentMobileNavGroups.on('show.bs.collapse', this.markNavLinkSelected.bind(this));
    this.$parentMobileNavGroups.on('hide.bs.collapse', this.markNavLinkUnSelected.bind(this));
  },

  toggleNavGroup: function (e) {
    e.preventDefault();
    var $selectedParentNavLink = $(e.currentTarget);

    if ($selectedParentNavLink.hasClass('indent-1') && !$selectedParentNavLink.hasClass('selected')) {
      _.each(this.$el.find('.parent-mobile-nav-group .sub-mobile-nav-group.collapse.in'), function(element) {
        $(element).collapse('toggle');
      });
    }

    var $selectedParentMobileNavGroup = $selectedParentNavLink.parent('.parent-mobile-nav-group');
    var $subNavs = $selectedParentMobileNavGroup.children('.sub-mobile-nav-group');

    $subNavs.collapse('toggle');

    this.$el[0].scrollIntoView({behavior: 'instant'});
  },

  markNavLinkSelected: function (e) {
    var $parentNav = $(e.currentTarget);
    var $parentNavLink = $parentNav.children('.parent-mobile-nav-link');

    $parentNavLink.addClass('selected');
    e.stopPropagation();
  },

  markNavLinkUnSelected: function (e) {
    var $parentNav = $(e.currentTarget);
    var $parentNavLink = $parentNav.children('.parent-mobile-nav-link');

    $parentNavLink.removeClass('selected');
    e.stopPropagation();
  }
});
