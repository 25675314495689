UC.partials.RegisterUser = Backbone.View.extend({

  events: {
    'click #btnGoogle': 'onGoogleClick',
    'click #btnLinkedIn': 'onLinkedinClick',
  },

  elements: {
    'txtEmail': null,
    'txtFirstName': null,
    'txtLastName': null,
    'btnGoogle': null,
    'btnLinkedIn': null,
    'divLoginOptions': null,
  },

  routes: {
    emailExists: '/account/email_exists'
  },

  initialize: function(options) {
    UC.utils.setSelectors(this.elements);

    this.data = {};

    this.components = {
      authentication: new UC.components.Authentication()
    };

    this.loggedInCallback = options.loggedInCallback;
    this.logonErrorCallback = options.logonErrorCallback;
    this.components.linkedInLoader = this.elements.btnLinkedIn.buttonLoader();
  },

  onLinkedinClick: function(e) {
    e.preventDefault();
    this.components.authentication.logonLinkedIn(
        {
          callback: this.onOauthAuthorize.bind(this),
        }
    );
  },

  onGoogleClick: function(e) {
    e.preventDefault();
    this.components.authentication.logonGoogle(
        {
          context: 'signup',
          backdrop: true,
          callback: this.onOauthAuthorize.bind(this),
        }
    );
  },

  validate: function(dfd) {
    this.$el.find('.has-error').removeClass('has-error');

    var errors = [];
    var data = this.$el.serializeJSON();

    if (!data.user.FirstName) {
      errors.push({element: this.elements.txtFirstName, text: 'Please provide your first name'});
    };

    if (!data.user.LastName) {
      errors.push({element: this.elements.txtLastName, text: 'Please provide your last name'});
    };

    if (!data.user.Email || !UC.utils.validateEmail(data.user.Email)) {
      errors.push({element: this.elements.txtEmail, text: 'Please provide a valid email'});
    };

    if (this.data.omniauth && Object.keys(this.data.omniauth).length > 0) {
        data.omniauth = this.data.omniauth;
    };

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      this.hideLoaders();
      dfd.reject();
    } else {
      this.checkEmailAvailability(data, dfd);
    }
  },

  checkEmailAvailability: function(data, dfd) {
    UC.net.post(this.routes.emailExists, {email: data.user.Email}, function(res) {
      if (res.exists) {

        UC.utils.showValidationErrors([
          {
            element: this.elements.txtEmail,
            text: 'This email address is already in use.'
          }
        ]);

        this.hideLoaders();
        dfd.reject();
      } else {
        dfd.resolve(data);
      }
    }.bind(this)).fail(function() {
      this.hideLoaders();
      dfd.reject();
    }.bind(this));
  },

  onOauthAuthorize: function(data) {
    if (data.status === 'unregistered') {
      this.showLoaders(data.provider);
      this.data.omniauth = data.auth;
      this.elements.txtFirstName.val(this.data.omniauth.info.first_name);
      this.elements.txtLastName.val(this.data.omniauth.info.last_name);
      this.elements.txtEmail.val(this.data.omniauth.info.email);
      this.$el.find('#nextButton').trigger('click');
    } else if (data.status === 'authenticated') {
      this.showLoaders(data.provider);
      this.loggedInCallback(null, data);
    } else if (data.status === 'signup_not_displayed' || data.status === 'signin_not_displayed') {
      this.logonErrorCallback(data.googleErrorMessage || data.status);
    } else if (data.status !== 'signup_skipped') {
      this.logonErrorCallback(data.status);
    }
  },

  showLoaders: function(provider) {
    if (provider === 'linkedin') {
      this.components.linkedInLoader.showLoader();
    }
  },

  hideLoaders: function() {
    this.components.linkedInLoader.hideLoader();
  }

});
