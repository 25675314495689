UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.TimesheetTotalsView = Backbone.View.extend({

  tagName: 'div',
  
  id: 'timesheet-totals',

  className: 'inline-block grey-BG rounded-corners border-grey',

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/timesheet_totals"),

  initialize: function() {
    this.collection.on('add destroy reset change:Hours change:Rate', this.render.bind(this));
  },

  render: function() {
    this.$el.html(this.template(this.templateData()));
  },

  templateData: function() {
    return {
      totalHoursOne: this.collection.totalHoursOne(),
      totalHours: this.collection.totalHours(),
      formattedTotalFees: this.collection.formattedTotalFees()
    }
  }

});
