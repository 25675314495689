UC.views.AccountEditCreditCard = Backbone.View.extend({

  events: {
    'click #btnSubmitCreditCard' : 'submit',
    'change #selectCountry' : 'toggleCountry'
  },

  initialize: function() {
    this.$el.find('select').select2({width: '100%'});
    this.btnLoader = this.$el.find('#btnSubmitCreditCard').buttonLoader();

    this.$month = this.$el.find('#selectMonth');
    this.$year= this.$el.find('#selectYear');
    this.$street = this.$el.find('#txtAddress');
    this.$city = this.$el.find('#txtCity');
    this.$state = this.$el.find('#selectState');
    this.$zip = this.$el.find('#txtZipCode');
    this.$country = this.$el.find('#selectCountry');
    this.$default = this.$el.find('#chkDefaultMethod');
    this.$default.fancyCheck();
  },

  submit: function(e) {
    e.preventDefault();
    if (this.validate()) {
      this.btnLoader.showLoader();
      UC.net.post('/account/save_credit_card/{0}'.format(UC.constants.paymentMethodId), this.data).fail(function() {
        this.btnLoader.hideLoader();
      }.bind(this));
    }
  },

  validate: function () {
    UC.utils.clearValidationErrors('form-group');
    var errors = [];
    var now = new Date();
    var data = {};

    data.exp_month = this.$month.val();
    data.exp_year = this.$year.val();
    data.address_line1 = this.$street.val();
    data.address_city = this.$city.val();
    data.address_state = this.$state.val();
    data.address_zip = this.$zip.val();
    data.address_country = this.$country.val();
    data.IsDefaultCharge = this.$default.hasClass('checked');
    console.log(data)

    if (data.exp_year < now.getFullYear() || (data.exp_year == now.getFullYear() && data.exp_month < now.getMonth() + 1)) {
      errors.push({
        className: 'form-group', 
        element: this.$month, 
        text: 'Invalid expiration date.'
      });
    }

    if (!data.address_line1) {
      errors.push({
        className: 'form-group', 
        element: this.$street, 
        text: 'Please enter your billing address.'
      });
    }

    if (!data.address_city) {
      errors.push({
        className: 'form-group', 
        element: this.$city, 
        text: 'Please enter your billing city.'
      });
    }

    if (data.address_country === 'USA') {
      if (!data.address_state) {
        errors.push({
          className: 'form-group', 
          element: this.$state, 
          text: 'Please select your billing state.'
        });
      }
    } else {
      delete data.address_state;
      delete data.address_zip;
    }

    if (!data.address_country) {
      errors.push({element: this.$country, text: 'Please enter your billing country.'});
    }

    if (!data.address_zip && data.address_country === 'USA' && !UC.utils.validateZipCode(data.zip)) {
      errors.push({element: this.$zip, text: 'Please enter a valid billing zip code.'});
    }

    this.data = data;
    UC.utils.showValidationErrors(errors, {scrollToFirst: true});
    return errors.length === 0;
  },

  toggleCountry: function(e) {
    if(this.$country.val() === 'USA'){
      this.$state.prop('disabled', false).trigger('change');
      $('#state-zip-input-row').removeClass('hidden');
    } else{
      this.$state.prop('disabled', true).trigger('change');
      $('#state-zip-input-row').addClass('hidden');
    }
  }

});
