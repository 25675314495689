UC.views.AccountAvailability = Backbone.View.extend({
	
  events: {
    'change input#js-toggle-availability' : 'toggleAvailability'
  },
	
  initialize: function() {
    this.user = UC.user;
    this.$toggle = this.$('#js-toggle-availability');
    this.$valueToggle = this.$('.js-toggle-value');
  },
	
  toggleAvailability: function() {
    if(this.user && this.user.type === 'attorney') {
      this.$toggle.prop('disabled', true);
      UC.net.invoke(
        'patch',
        '/account/availability',
        { toggle: true },
        this.toggleInfoDisplays.bind(this)
      )
      .success(function() {
        this.$toggle.prop('disabled', false)
      }.bind(this))
    }
  },
	
  toggleInfoDisplays: function() {
    if (this.$toggle.prop('checked')) {
      this.$valueToggle.html('Yes');
    } else {
      this.$valueToggle.html('No');
    }
  }
	
});
