UC.views.AccountConfirmInvite = Backbone.View.extend({

  events: {
    'click #aCancelClientForm' : 'cancel',
    'click #btnSaveClient' : 'submit'
  },

  initialize: function() {
    this.btnLoader = this.$el.find('#btnSaveClient').buttonLoader();
    this.$el.find('select').select2();

    this.$form = this.$el.find('#panelClientForm');
    this.$firstName = this.$el.find('#txtFirstName');
    this.$password = this.$el.find('#txtPassword');
    this.$chkAgreeTerms = this.$el.find('#chkAgreeTerms');
    this.$website = this.$el.find('#txtCompanyWebsite');

    this.addressForm = new UC.partials.AddressForm({
      el: '#panelClientForm'
    });
  },

  cancel: function(e) {
    function onConfirm(response) {
      if (response) {
        location.href = '/';
      }
    }

    e.preventDefault();
    UC.utils.showConfirmModal('Confirm Operation', 'Are you sure you want to cancel?', onConfirm);
  },

  submit: function (e) {
    e.preventDefault();

    var addressDfd = new $.Deferred();
    var formDfd = new $.Deferred();

    $.when.apply(this, [formDfd, addressDfd]).done(function() {

      this.btnLoader.showLoader();
      var address = this.addressForm.getAddress();
      var account = $.extend({}, this.account);
      account.Address = address;
      account.token = UC.constants.token;

      UC.net.post('/account/confirm_invite_ajax', {account: account}).fail(function() {
        this.btnLoader.hideLoader();
      }.bind(this));

    }.bind(this));

    this.validate(formDfd);
    this.addressForm.validate(addressDfd);
  },

  validate: function(dfd) {
    $('.row').removeClass('has-error');
    var errors = [];
    var account = this.$form.serializeJSON();
    account.agreeTerms = this.$chkAgreeTerms.is(':checked');

    var fields = ['firstName', 'lastName', 'company', 'password', 'website'];
    for (var i = 0; i < fields.length; i++) {
      account[fields[i]] = account[fields[i]].trim();
    }

    if (!account.firstName || !account.lastName) {
      errors.push({element: this.$firstName, text: 'Missing first/last name'});
    }

    if (account.password.length < UC.constants.minPasswordLength) {
      errors.push({element: this.$password, text: 'Please provide a valid password with at least {0} characters'.format(UC.constants.minPasswordLength)});
    }

    if (!account.agreeTerms) {
      errors.push({element: this.$chkAgreeTerms, text: 'Please agree to the terms of use'});
    }

    if (account.website) {
      account.website = UC.utils.ensureUrlProtocol(account.website);
      if (!UC.utils.validateUrl(account.website)) {
        errors.push({element: this.$website, text: 'Please provide a valid website url or leave the field empty'});
      }
    }

    UC.utils.showValidationErrors(errors, {scrollToFirst: true});
    if (errors.length > 0) {
      dfd.reject();
    } else {
      this.account = account;
      dfd.resolve();
    }
  }

});
