UC.partials.ScheduledCallsTimesAvailable = Backbone.View.extend({

  events: {
    'click #time-available-add': 'addRow',
    'click .time-available-remove': 'removeRow'
  },

  initialize: function() {
    this.initCalendars();
  },

  initCalendars: function() {
    var options = $.extend({
      minDate: "0"
    }, UC.constants.datepickerOptions);

    this.$el.find('.time-available-date').datepicker(options);

    this.$el.find('.js-calendar-button').click(function(e) {
      $(e.target).closest('.input-with-icon-right').find('input').focus();
    });
  },

  errors: function() {
    var errors = [];
    var data = [];

    this.$el.find('.time-available').each(function() {
      var $el = $(this);
      var $date = $el.find('.time-available-date');
      var $from = $el.find('.time-available-range-from');
      var $to = $el.find('.time-available-range-to');
      var date = moment($date.val(), UC.constants.momentDateFormats, true);

      if (!date.isValid()) {
        errors.push({element: $date, text: 'Please provide a valid date (mm/dd/yyyy)', className: 'form-group'});
      }

      if (date.isBefore(new Date(), 'day')) {
        errors.push({ element: $date, text: 'Date must be in the future', className: 'form-group' });
      }

      var from = parseInt($from.val().replace(/\D/g,''), 10);
      var to = parseInt($to.val().replace(/\D/g,''), 10);

      var overlaps = data.find(function(overlap) {
        return overlap.date == $date.val() && overlap.to_hour >= from && overlap.from_hour <= to;
      });

      if ( from >= to) {
        errors.push({ element: $from, text: 'From time must be before to time', className: 'form-group' });
      } else if(overlaps) {
        errors.push({ element: $from, text: 'Time segments cannot overlap', className: 'form-group' });
      }
      data.push({
        date: $date.val(),
        from_hour: from,
        to_hour: to
      });
    });

    return errors;
  },

  serialize: function() {
    var data = [];

    this.$el.find('.time-available').each(function() {
      var $el = $(this);
      var date = $el.find('.time-available-date').val();
      var from = $el.find('.time-available-range-from').val();
      var to = $el.find('.time-available-range-to').val();

      data.push({
        date: date,
        from_hour: from,
        to_hour: to
      });
    });

    return data;
  },

  addRow: function(e) {
    e.preventDefault();
    var $template = this.$el.find('#time-available-template');
    this.$el.find('#times-available-list').append($template.html());
    this.initCalendars();
    this.toggleAddTime();
  },

  removeRow: function(e) {
    e.preventDefault();
    $(e.target).closest('.time-available').remove();
    this.toggleAddTime();
  },

  toggleAddTime: function() {
    var $add = this.$el.find('#time-available-add');

    if (this.$el.find('.time-available').length >= 3) {
      $add.hide();
    } else {
      $add.show();
    }
  }
});
