UC.modals.AttorneyJobFeedbackModal = Backbone.View.extend({
  className: 'modal',

  el: '#mdlAttorneyFeedback',

  events: {
    "change input[name='feedback_response[AttorneyFeedback]']" : 'onChangeAttorneyFeedback',
    "change input[name='report_attorney_feedback[AttorneyFeedback]']" : 'onChangeReportFeedback',
    'submit #formAttorneyFeedback' : 'onSubmitAttorneyFeedback'
  },

  initialize: function() {
    this.$reportOptionsComments = this.$el.find('#reportOptionsComments');
    this.$feedbackComments = this.$el.find('#feedbackComments');
    this.$reportOptions = this.$el.find('#reportOptions');
    this.$formAttorneyFeedback = this.$el.find('#formAttorneyFeedback');
    this.$reportAttorneyFeedback = this.$el.find("#reportAttorneyFeedback");

    return this;
  },

  render: function() {
    this.$el.modal();

    UC.utils.recordKissEvent('attorney_job_feedback_shown',
      { jobId: UC.job.id, attorneyId: UC.user.id, url: location.href }
    );
  },

  onChangeReportFeedback: function(e) {
    UC.utils.hideValidationErrors('js-report-options');
    if (e.target.value === 'other') {
      this.$reportOptionsComments.removeClass('hidden');
    } else {
      this.$reportOptionsComments.addClass('hidden');
    }
  },

  onChangeAttorneyFeedback: function(e) {
    if (e.target.value === 'report_job') {
      this.$feedbackComments.addClass('hidden');
      this.$reportOptions.removeClass('hidden');
    } else if (e.target.value === 'other') {
      this.$reportOptions.addClass('hidden');
      this.$feedbackComments.removeClass('hidden');
    } else {
      this.$reportOptions.addClass('hidden');
      this.$feedbackComments.addClass('hidden');
    }
  },

  preprocessData: function(data){
    data.referrer = document.referrer;
    if(data.feedback_response){
      if(data.feedback_response.AttorneyFeedback !== 'report_job') {
        delete data.report_attorney_feedback;
      } else if(data.feedback_response.AttorneyFeedback !== 'other'){
        delete data.feedback_response.Comments;

        if(data.feedback_response.AttorneyFeedback === 'report_job' && data.report_attorney_feedback.AttorneyFeedback !== 'other'){
          delete data.report_attorney_feedback.Comments;
        }
      }
    }

    return data;
  },

  validate: function(data){
    var errors = [];

    if(data.feedback_response && data.feedback_response.AttorneyFeedback === 'report_job'){
      if(data.report_attorney_feedback && _.isEmpty(data.report_attorney_feedback.AttorneyFeedback)){
        errors.push({element: this.$reportAttorneyFeedback, text: 'Please select a report reason'});
      }
    }

    if(errors.length > 0) {
      UC.utils.showValidationErrors(errors, {className: 'js-report-options'});
      return false;
    } else {
      return true;
    }
  },

  onSubmitAttorneyFeedback: function(e) {
    e.preventDefault();
    var data = this.$formAttorneyFeedback.serializeJSON();
    data = this.preprocessData(data);

    if(this.validate(data)) {
      UC.net.post(this.$formAttorneyFeedback.attr('action'), data, function () {
        this.$el.modal('hide');
      }.bind(this));
    }
  }
});
