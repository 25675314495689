UC.utils.loadPopover = function(element, options) {

  if (UC.utils.isMobileViewPort()) {
    return;
  }

  var defaults = {
    scrollOver: false,
    scrollGoal: false,
    scrollOffset: 0,
    scrollUp: false,
    timer: false,
    seconds: 10,
    triggered: false,
    sticky: false,
    fireKiss: true
  };
  var opt = $.fn.extend({}, defaults, options);

  function triggerModal(element) {
    element.css('margin-top', -(element.outerHeight() / 2));

    if (!element.hasClass('anchor')) {
      element.css('margin-left', -(element.outerWidth() / 2));
    }

    element.show();

    if (!opt.triggered && opt.fireKiss) {
      UC.utils.recordKissEvent('show popover modal', {'page': UC.constants.pageName, 'type': 'scrollOver'});
    }

    opt.triggered = true;
    opt.sticky = true;
  }

  if (opt.timer) {
    setTimeout(function() {
      triggerModal(element);
    }, opt.seconds * 1000);
  } else if (opt.scrollOver) {
    $(window).scroll(function() {
      if (opt.triggered) {
        return;    
      }
      var scrollTop = $(window).scrollTop(),
      scrollGoal = opt.scrollGoal ? opt.scrollGoal.offset().top : 0;

      if (scrollTop >= scrollGoal + opt.scrollOffset || opt.sticky) {
        triggerModal(element); 
      }
    });
  } else if (opt.scrollUp) {
    var lastPosition = 0;

    $(window).scroll(function() {
      if (opt.triggered) {
        return;
      }

      var scrollTop = $(window).scrollTop();

      if (scrollTop < lastPosition || opt.sticky){
        triggerModal(element);
      }

      lastPosition = scrollTop;
    });
  }
};
