UC.views.RegisterUserForDocumentDownload = Backbone.View.extend({
  el: '#registerUserForDocumentDownload',

  events: {
    'click .js-onRegister': 'onRegisterClick',
  },

  initialize: function(opt) {
    $('#registerUserForDocumentDownload').modal();

    this.signupForm = new UC.partials.UsersForm({
      beforeRegister: this.setDocumentDownloadData.bind(this),
      onRegister: opt.onRegisterSuccess
    });
  },

  // TODO: Implement tracking
  // clickTrackingProps: function (location) {
    // return {
      // 'author_attorney_id': this.$('#AuthorId').val(),
      // 'document_id': this.$('#DocumentId').val(),
      // 'loc': location || 'document_download_modal',
      // 'logged-in': (UC.user ? true : false)
    // };
  // },

  onRegisterClick: function () {
    // TODO: Implement tracking
    // UC.utils.recordKissEvent('click-on-register-user', this.clickTrackingProps());
    this.signupForm.validate();
  },

  setDocumentDownloadData: function (data) {
    data.document_id = this.$('#js-RegisterUserForDocumentDownload').data().documentId;
  }
});
