import '../proposals/accept_proposal'

UC.views.JobsMessageThread = Backbone.View.extend({
  events: {
    'click #js-schedule-call' : 'scheduleCall',
    'click .cancel-sched-call' : 'cancelCall',
    'click .btn-schedule-call-reply' : 'replyCall',
    'click .jp-send-message' : 'sendMessage',
    'click .js-copy-share-btn': 'handleCopyShare'
  },

  initialize: function() {
    new UC.partials.MessagesCreate({
      jobOwnerId: UC.constants.jobOwnerId,
      attorneyId: UC.constants.attorneyId,
      jobId: UC.constants.jobId
    });

    UC.acceptJobProposal.init({
      acceptProposalButtonSelector: '#btnHire',
      jobId: UC.constants.jobId,
      hideHireHelpModal: UC.constants.hideHireHelpModal
    });

    UC.utils.sendNesEvent('message_thread_viewed', {
      location: 'job_portal',
      thread_id: UC.constants.threadId,
      unread: UC.constants.unread
    });

    new UC.components.FeesPopover({ el: this.$el });

    this.$('#btnSendReply').buttonLoader();

    this.queryParamsForScheduledCallReply();

    this.scrollToElement(location.hash);
  },

  sendMessage: function(e) {
    e.preventDefault();

    if (UC.user.status === UC.constants.UserStatus.Pending) {
      new UC.modals.ModalPendingUser().render(
        'Please confirm your email address before sending a message'
      );

      return false;
    }

    var attorneyId = $(e.currentTarget).data('attorney-id');
    this.openMessageModal(attorneyId);
  },

  handleCopyShare: function() {
    document.querySelector('.copy-share-calendar-url').select();
    document.execCommand('copy');

    UC.utils.sendNesEvent('attorney_calendar_copy_url_clicked', { attorney_id: UC.user.id });

    this.$('.js-copy-share-btn').fadeOut(function() {
      $(this).text('Copied').addClass('text-green').fadeIn('slow');
    });
  },

  openMessageModal: function(attorneyId) {
    this.$('#lblAttorneyName').text('attorney');

    UC.utils.showModal(this.$('#modalContactAttorney'));
  },

  scrollToElement: function(scrollLocation) {
    if (scrollLocation === '#reply') {
      UC.utils.scrollToElement(this.$('#panelReply'));

      this.$('#txtReplyMessage').focus();
    }
  },

  queryParamsForScheduledCallReply: function() {
    var action = UC.utils.getParameterByName('a');

    if (UC.utils.getParameterByName('scheduled_call_reply') || action === 'scheduled_call_reply') {
      var id = this.$('.btn-schedule-call-reply:enabled').last().data('id');

      if (id) {
        new UC.modals.ScheduledCallsShow({
          scheduled_call_id: id, callback: this.renderScheduledCallMessage
        }).render();
      }
    }
  },

  replyCall: function(e) {
    e.preventDefault();

    new UC.modals.ScheduledCallsShow({
      scheduled_call_id: $(e.target).data('id'),
      callback: this.renderScheduledCallMessage
    }).render();
  },

  renderScheduledCallMessage: function(result) {
    var summary = "Sent via 'Schedule phone call'";
    var messages = new UC.partials.MessagesCreate();
    var template = messages.addMessageToThread(result.message, null, 'sender');

    template.find('.message-sent-wrapper').html(summary);
  },

  scheduleCall: function (event) {
    event.preventDefault();

    var data = event.target.dataset;

    var attorneyId = data.attorneyId;
    var jobId = data.jobId;
    var bidId = data.bidId;
    var instantAvailable = data.instantAvailable === "true";

    if (instantAvailable) {
      new UC.partials.ScheduledCallsInstant({
        attorneyId: attorneyId,
        jobId: jobId,
        bidId: bidId,
        onSuccessfulSchedule: this.onSuccessfulSchedule
      }).goHam();
    } else {
      new UC.modals.ScheduledCallsNew({
        attorney_id: attorneyId, onRequest: this.onSuccessfulSchedule
      }).render();
    }
  },

  cancelCall: function (event) {
    event.preventDefault();

    var scheduledCallId = event.target.dataset['scheduledCallId'];
    new UC.partials.ScheduledCallsCancel({ scheduledCallId: scheduledCallId });
  },

  onSuccessfulSchedule: function () {
    location.reload();
  }
});
