UC.modals.QuoteCalculatorModal= Backbone.View.extend({

  el: '#modalQuoteCalculator',

  events: {
    'change #js-qcUserId': 'fetchAttorneyClient',
    'change #qcServiceFees': 'validateServiceFees',
    'change #qcFilingFees': 'validateFilingFees',
    'change #qcServiceFeesClient': 'validateServiceFeesClient',
    'change #qcFilingFeesClient': 'validateFilingFeesClient',
    'change [name="proposal[PaymentCount]"]': 'updatePaymentTerms',
    'change .fees': 'calculateProposalFees',
    'change .js-client-fees': 'calculateProposalFeesClient',
    'click #tabHourlyFee': 'showHourlyFee',
    'click #tabFixedFee': 'showFixedFee'
  },

  initialize: function(args) {
    this.$el.modal({show: false});
    this.$template = this.$el.clone();

    this.$form = this.$el.find('#frmAddProject');
    this.initHelp();
    this.$('.fees').currencyInput();
    this.$el.find('#UserId').select2({ width: '100%' });
    this.$el.find('#UserId').trigger('change');

    $('[data-toggle="tooltip"]').tooltip();

    this.client_loaded = false;
    this.$el.find("#proposals-form-quote").css('visibility', 'hidden');
  },

  open: function() {
    this.$el.modal('show');
    this.$el.on('hidden.bs.modal', this.resetModal.bind(this));

    var kissData = {
      userId: UC.user.id
    };

    UC.utils.recordKissEvent('quote_calculator_opened', kissData);
    this.$el.find('#UserId').trigger('change');
    this.$el.find("#proposals-form-quote").css('visibility', 'hidden');
  },

  resetModal: function() {
    this.$el = this.$template.clone();
    this.$el.modal({show: false});
    this.delegateEvents();
  },

  // Form specific methods

  fetchAttorneyClient: function(e) {
    var $selected = $(e.currentTarget).find('option:selected');
    var client_id = $selected.data('attorney-client-id');

    if (client_id) {
      var path = '/clients/{0}.json'.format(client_id);
      this.client_loaded = true;
      this.$el.find("#proposals-form-quote").css('visibility', 'visible');

      UC.net.get(path, function(attorney_client) {
        this.onFetchAttorneyClient(attorney_client);
      }.bind(this));
    } else {
      this.client_loaded = false;
      this.$el.find("#proposals-form-quote").css('visibility', 'hidden');
    }
  },

  onFetchAttorneyClient: function(attorney_client) {
    this.attorney_client = attorney_client;

    this.$('#forUserTitle').text("for " + this.attorney_client.FirstName + " " + this.attorney_client.LastName);
    this.$('#hourlyClientName').text(this.attorney_client.FirstName + " " + this.attorney_client.LastName);
    this.hourly_rate = 0;
    if (this.attorney_client.HourlyRate) {
      this.hourly_rate = this.attorney_client.HourlyRate;
    } else if (this.attorney_client.latest_hourly_rate) {
      this.hourly_rate = this.attorney_client.latest_hourly_rate;
    } else {
      this.hourly_rate = 0;
    }
    this.$('#qcServiceFees').val(UC.utils.formatCurrency(0));
    this.$('#qcFilingFees').val(UC.utils.formatCurrency(0));
    this.$('#qcEstimatedHours').val(0);

    this.payment_profile = this.attorney_client.payment_profile;
    this.transaction_type = this.attorney_client.transaction_type;
    this.client_id = this.attorney_client.UserId;
    this.has_invoices = this.attorney_client.has_invoices;
    this.percentFee = (this.payment_profile.baked_service_fee / 100) + 1;

    this.showFixedFee();

    var kissData = {
      attorneyId: UC.user.id,
      clientId: this.attorney_client.UserId
    };

    UC.utils.recordKissEvent('quote_calculator_attorney_client_fetch', kissData);
  },

  /// Form Quote

  toggleBakedInFee: function() {
    var hasBakedFee = !!this.payment_profile.baked_service_fee;

    // show disclaimer on "Your Fees" only for non-baked attorneys
    this.$('#your-fees-disclaimer').toggleClass('hidden', hasBakedFee);
    this.$('#your-fees-no-disclaimer').toggleClass('hidden', !hasBakedFee);
  },

  initHelp: function() {
    var $serviceFees = $('#qcServiceFees');
    var $filingFees = $('#qcFilingFees');
    var $body = $('body');

    $serviceFees.popover({
      placement: 'top',
      content: 'Not sure how much to bid? Click <a href="/educationcenter/Proposal_Pricing" id="bid-amount-popover-education-center" target="_blank">here</a> for help.',
      trigger: 'manual',
      html: true
    });

    $filingFees.popover({
      placement: 'top',
      content: 'Not sure how much to bid? Click <a href="/educationcenter/Proposal_Pricing" id="bid-amount-popover-education-center" target="_blank">here</a> for help.',
      trigger: 'manual',
      html: true
    });

    // set data attribute on element to close on next click after it has been shown
    $serviceFees.on('shown.bs.popover', function() {
      $serviceFees.data('closePopoverOnClick', true);
    });

    $filingFees.on('shown.bs.popover', function() {
      $filingFees.data('closePopoverOnClick', true);
    });

    // this logic is to close the popover when clicking outside of the popover div. we want to close it on the second 'click'
    // in most cases since it is opened by a click (triggering a change event) which is why we set a data attribute of
    // closePopoverOnClick
    $body.on('click', function(e) {
      var $target = $(e.target);

      if ($target.parents('.popover.in').length === 0 && $serviceFees.data('closePopoverOnClick')) {
        $serviceFees.popover('hide');
      }
    });

    new UC.components.FeesPopover({el: this.$el});
  },

  updatePaymentTerms: function(e) {
    var termValue = $('[name="proposal[PaymentCount]"]:checked').val();

    this.paymentCount = null;
    this.$('#panel-upfront').addClass('hidden');

    if (parseInt(termValue, 10) === 2) {
      this.$('#panel-upfront').removeClass('hidden');
      this.paymentCount = 2;
    } else if (parseInt(termValue, 10) === 1) {
      this.paymentCount = 1;
    }

    this.toggleBakedInFee();
    this.calculateProposalFees();
  },

  setBidType: function(bidType) {
    var $proposalType = this.$('#BidType');
    $proposalType.val(bidType);
  },

  calculateProposalFees: function() {
    var $serviceFees = this.$('#qcServiceFees');
    var $estimatedHours = this.$('#qcEstimatedHours');
    var $filingFees = this.$('#qcFilingFees');
    var $proposalType = this.$('#BidType');

    var serviceFees = UC.utils.currencyToFloat($serviceFees.val());
    var estimatedHours = UC.utils.currencyToFloat($estimatedHours.val());
    var filingFees = UC.utils.currencyToFloat($filingFees.val());
    var bidType = parseInt($proposalType.val(), 10);

    if (serviceFees < 0) $serviceFees.val(0);
    if (estimatedHours < 0) $estimatedHours.val(0);
    if (filingFees <= 0) {
      $filingFees.val(UC.utils.formatCurrency(filingFees));
    }

    // add any client "baked-in" fees to the total and show proper payment terms
    this.handleClientFees(bidType, serviceFees, estimatedHours, filingFees);
  },

  calculateProposalFeesClient: function() {
    var $serviceFeesClient = this.$('#qcServiceFeesClient');
    var $estimatedHoursClient = this.$('#qcEstimatedHoursClient');
    var $filingFeesClient = this.$('#qcFilingFeesClient');
    var $proposalType = this.$('#BidType');

    var serviceFeesClient = UC.utils.currencyToFloat($serviceFeesClient.val());
    var estimatedHoursClient = UC.utils.currencyToFloat($estimatedHoursClient.val());
    var filingFeesClient = UC.utils.currencyToFloat($filingFeesClient.val());
    var bidType = parseInt($proposalType.val(), 10);

    if (serviceFeesClient < 0) $serviceFeesClient.val(0);
    if (estimatedHoursClient < 0) $estimatedHoursClient.val(0);
    if (filingFeesClient <= 0) {
      $filingFeesClient.val(UC.utils.formatCurrency(filingFeesClient));
    }

    // add any client "baked-in" fees to the total and show proper payment terms
    this.handleAttorneyFees({bidType: bidType, serviceFees: serviceFeesClient, estimatedHours: estimatedHoursClient, filingFees: filingFeesClient});
  },

  calculateClientHourly: function() {
    // add the baked in fee to the base service fees
    return this.hourly_rate * this.percentFee;
  },

  handleClientFees: function(bidType, serviceFees, estimatedHours, filingFees) {
    var platformFees = 0.0;
    var totalAttorneyServiceFee = 0.0;

    if (bidType === UC.constants.BidType.HourlyRate) {
      totalAttorneyServiceFee = serviceFees * estimatedHours;
    } else {
      totalAttorneyServiceFee = serviceFees;
    }

    if (this.percentFee > 0) {

      // these are currently always the same as the attorney
      this.$('#qcEstimatedHoursClient').val(UC.utils.formatCurrency(estimatedHours));
      this.$('#qcFilingFeesClient').val(UC.utils.formatCurrency(filingFees));

      // add the baked in fee to the base service fees
      var clientServiceFees = serviceFees * this.percentFee;

      // add the baked in fee to the total attorney service fees
      var totalClientServiceFees = totalAttorneyServiceFee * this.percentFee;

      var amountClientServiceText = UC.utils.formatCurrency(clientServiceFees);
      this.$('#qcServiceFeesClient').val(amountClientServiceText);

      if (this.$('#qcPlatformFee').length) {
        platformFees = this.calculatePlatformFees(totalClientServiceFees, filingFees);
        var amountPlatformText = UC.utils.formatCurrency(platformFees, true);
        this.$('#qcPlatformFee').text(amountPlatformText);
      }

      var amountClientText = UC.utils.formatCurrency(totalClientServiceFees + filingFees + platformFees, true);
      this.$('#quoteTotalFeesClient').text(amountClientText);
    } else {
      this.$('#qcPlatformFee').text(UC.utils.formatCurrency(0, true));
    }

    var totalText = UC.utils.formatCurrency(totalAttorneyServiceFee + filingFees, true);
    this.$('#quoteTotalFees').text(totalText);

    // this should include any extra client fees from above
    if(platformFees > 0) {
      this.updateUpfrontPaymentPlatform(totalClientServiceFees, filingFees, platformFees);
    } else {
      this.updateUpfrontPaymentTerms(totalClientServiceFees, filingFees);
    }
  },

  handleAttorneyFees: function(options) {
    var bidType = options['bidType'];
    var serviceFees = options['serviceFees'];
    var estimatedHours = options['estimatedHours'];
    var filingFees = options['filingFees'];
    var platformFees = 0.0;
    var totalClientServiceFee = 0.0;

    if (bidType === UC.constants.BidType.HourlyRate) {
      totalClientServiceFee = serviceFees * estimatedHours;
    } else {
      totalClientServiceFee = serviceFees;
    }

    if (this.percentFee > 0) {

      // these are currently always the same as the client
      this.$('#qcEstimatedHours').val(UC.utils.formatCurrency(estimatedHours));
      this.$('#qcFilingFees').val(UC.utils.formatCurrency(filingFees));

      // divide the baked in fee to the client's service fees
      var attorneyServiceFees = serviceFees / this.percentFee;

      // divide the baked in fee to the total client service fees
      var totalAttorneyServiceFees = totalClientServiceFee / this.percentFee;

      var amountAttorneyServiceText = UC.utils.formatCurrency(attorneyServiceFees);
      this.$('#qcServiceFees').val(amountAttorneyServiceText);

      if (this.$('#qcPlatformFee').length) {
        platformFees = this.calculatePlatformFees(totalClientServiceFee, filingFees);
        var amountPlatformText = UC.utils.formatCurrency(platformFees, true);
        this.$('#qcPlatformFee').text(amountPlatformText);
      }

      var amountClientText = UC.utils.formatCurrency(totalClientServiceFee + filingFees + platformFees, true);
      this.$('#quoteTotalFeesClient').text(amountClientText);
    } else {
      this.$('#qcPlatformFee').text(UC.utils.formatCurrency(0, true));
    }

    var totalText = UC.utils.formatCurrency(totalAttorneyServiceFees + filingFees, true);
    this.$('#quoteTotalFees').text(totalText);

    // this should include any extra client fees from above
    if(platformFees > 0) {
      this.updateUpfrontPaymentPlatform(totalClientServiceFee, filingFees, platformFees);
    } else {
      this.updateUpfrontPaymentTerms(totalClientServiceFee, filingFees);
    }
  },

  calculatePlatformFees: function(totalClientServiceFees, filingFees) {
    var serviceRake = this.payment_profile.platform_service_fee_percent / 100;
    var filingRake = this.payment_profile.platform_filing_fee_percent / 100;
    var platformServiceFee = totalClientServiceFees * serviceRake;
    var platformFilingFee = filingFees * filingRake;
    return platformFilingFee + platformServiceFee;
  },

  updateUpfrontPaymentTerms: function(totalServiceFees, filingFees) {
    var $optionFiling = this.$('#optionFiling');

    if (filingFees > 0) {
      $optionFiling.removeAttr('disabled');
      $optionFiling.text('All Filing Fees ({0})'.format(UC.utils.formatCurrency(filingFees, true)));
    } else {
      $optionFiling.attr('disabled', 'disabled');
      $optionFiling.text('All Filing Fees');
    }

    this.$('#option75').text('75% of Service Fees ({0})'.format(UC.utils.formatCurrency(totalServiceFees * 0.75, true)));
    this.$('#option50').text('50% of Service Fees ({0})'.format(UC.utils.formatCurrency(totalServiceFees * 0.50, true)));
    this.$('#option25').text('25% of Service Fees ({0})'.format(UC.utils.formatCurrency(totalServiceFees * 0.25, true)));
  },

  updateUpfrontPaymentPlatform: function(totalServiceFees, filingFees, platformFees) {
    var upfrontPercentage = [75, 50, 25, 10];
    var $optionFiling = this.$('#optionFiling');

    if (filingFees > 0) {
      $optionFiling.removeAttr('disabled');
    } else {
      $optionFiling.attr('disabled', 'disabled');
    }

    $optionFiling.text('All Filing Fees');
    upfrontPercentage.map(function(percentage) {
      var totalUpfront = parseFloat((totalServiceFees + platformFees).toFixed(2)) * percentage / 100;
      return (
        this.$('#option' + percentage).text(
          '{0}% of Service + Platform Fees ({1})'.format(
            percentage,
            UC.utils.formatCurrency(totalUpfront , true)))
      )
    }.bind(this))
  },

  validateFees: function($fees, feeAmount, feeValidation) {
    if (feeValidation) {
      $fees.val('0.00');
      $fees.popover('show');
      $fees.data('closePopoverOnClick', false);
    }
  },

  validateServiceFees: function() {
    var minServiceFeeAmount = 50;
    var $serviceFees = this.$('#qcServiceFees');
    var serviceFeeAmount = UC.utils.currencyToFloat($serviceFees.val());
    var feeValidation = serviceFeeAmount < minServiceFeeAmount && serviceFeeAmount !== 0;
    this.validateFees($serviceFees, serviceFeeAmount, feeValidation);
    var $proposalType = this.$('#BidType');
    var bidType = parseInt($proposalType.val(), 10);

    if (bidType && bidType === UC.constants.BidType.HourlyRate
      && serviceFeeAmount != this.hourly_rate
      && this.hourly_rate > 0) {

      var kissData = {
        userId: UC.user.id,
        serviceFeeAmount: serviceFeeAmount,
        oldhourlyRate: this.hourly_rate,
        clientId: this.attorney_client.UserId
      };
      if (UC.job) kissData.jobId = UC.job.id;
      UC.utils.recordKissEvent('quote_calculator_fee_changed_from_hourly_rate', kissData);
    }
  },

  validateServiceFeesClient: function() {
    var minServiceFeeAmount = 50 * this.percentFee;
    var $serviceFees = this.$('#qcServiceFeesClient');
    var serviceFeeAmount = UC.utils.currencyToFloat($serviceFees.val());
    var feeValidation = serviceFeeAmount < minServiceFeeAmount && serviceFeeAmount !== 0;
    this.validateFees($serviceFees, serviceFeeAmount, feeValidation);
  },

  validateFilingFees: function() {
    var $filingFees = this.$('#qcFilingFees');
    var filingFeeAmount = UC.utils.currencyToFloat($filingFees.val());
    var feeValidation = filingFeeAmount < 0;
    this.validateFees($filingFees, filingFeeAmount, feeValidation);
  },

  validateFilingFeesClient: function() {
    var $filingFees = this.$('#qcFilingFeesClient');
    var filingFeeAmount = UC.utils.currencyToFloat($filingFees.val());
    var feeValidation = filingFeeAmount < 0;
    this.validateFees($filingFees, filingFeeAmount, feeValidation);
  },

  showHourlyFee: function(e) {
    if (e) e.preventDefault();
    if (this.client_loaded === false) return;

    var $serviceFees = this.$('#qcServiceFees');
    var serviceFeeAmount = UC.utils.currencyToFloat($serviceFees.val());

    UC.utils.showPanel(this.$('.panelHours'));
    this.$('#tabHourlyFee').addClass('active');
    this.$('#tabFixedFee').removeClass('active');
    this.$('#lblServiceFee').text('Hourly Rate:');
    this.setBidType(UC.constants.BidType.HourlyRate);

    if (this.hourly_rate > 0) {
      // Hourly Rate info
      if (serviceFeeAmount === 0) this.$('#qcServiceFees').val(UC.utils.formatCurrency(this.hourly_rate));
      this.$('#hourlyRate').text(UC.utils.formatCurrency(this.calculateClientHourly(), true) + '/hr');
      this.$('clientTimesheetLink').toggleClass('hidden', !this.has_invoices);
      this.$('#clientTimesheetLink').attr('href', '/invoices?UserId=' + this.client_id);
      UC.utils.showPanel(this.$('#hourlyNoticeRow'));
    } else {
      UC.utils.hidePanel(this.$('#hourlyNoticeRow'));
    }

    this.calculateProposalFees();

    $('.js-hourly-blurb').removeClass('hidden');
  },

  showFixedFee: function(e) {
    if (e) e.preventDefault();
    if (this.client_loaded === false) return;
    UC.utils.hidePanel(this.$('.panelHours'));
    UC.utils.hidePanel(this.$('#hourlyNoticeRow'));
    this.$('#tabFixedFee').addClass('active');
    this.$('#tabHourlyFee').removeClass('active');
    this.$('#lblServiceFee').text('Service Fees:');
    this.setBidType(UC.constants.BidType.FixedBid);
    this.calculateProposalFees();
    $('.js-hourly-blurb').addClass('hidden');
  }

});
