!function() {

  var defaults = {
    attachments: {}
  };

  function SuperFancyCat($el, options) {
    this.$el = $el;
    this.options = $.fn.extend({}, defaults, options);

    this.render();
    this.bindEvents();
  }

  SuperFancyCat.prototype = {

    constructor: SuperFancyCat,

    template: UC.views.resolveHandlebarsTemplate("components/super_fancy_cat"),

    render: function() {
      this.$el.addClass('hidden');
      this.$fancy = $(this.template({
        id: this.$el.attr('id'),
        groups: this.serialize()
      }));
      this.$el.after(this.$fancy);
    },

    serialize: function() {
      var $groups = this.$el.find('optgroup');

      return $groups.map(function() {
        var $group = $(this);
        var count = 0;
        var count_selected = 0;

        var options = $group.find('option').map(function() {
          var $option = $(this);
          var selected = $option.is(':selected'); 

          count++;
          if (selected) count_selected++;

          return {
            name: $option.text(),
            value: $option.val(),
            selected: selected
          };
        }).get();

        return {
          name: $group.attr('label'),
          options: options,
          count: (count == count_selected && count > 0 ? 'All' : count_selected)
        };
      }).get();
    },

    bindEvents: function() {
      this.$fancy.find('.super-fancy-cat-header').click(this.toggleGroup.bind(this));
      this.$fancy.find('.super-fancy-cat-option').click(this.toggleOption.bind(this));
      this.$fancy.find('.super-fancy-cat-all').click(this.toggleGroupAll.bind(this));
      this.$fancy.find('.super-fancy-cat-none').click(this.toggleGroupNone.bind(this));
    },

    toggleAll: function() {
      this.selectOptions(this.$fancy);
    },

    toggleNone: function() {
      this.unselectOptions(this.$fancy);
    },

    toggleGroup: function(e) {
      e.preventDefault();
      $(e.currentTarget).closest('.super-fancy-cat-group').toggleClass('open');
    },

    toggleGroupAll: function(e) {
      e.preventDefault();
      e.stopPropagation();
      var $group = $(e.currentTarget).closest('.super-fancy-cat-group');
      this.selectOptions($group);
    },

    toggleGroupNone: function(e) {
      e.preventDefault();
      e.stopPropagation();
      var $group = $(e.currentTarget).closest('.super-fancy-cat-group');
      this.unselectOptions($group);
    },

    toggleOption: function(e) {
      e.preventDefault();
      var $fancy_option = $(e.currentTarget);

      this.setOption($fancy_option, !$fancy_option.hasClass('selected'));
      this.$el.trigger('change');
      this.updateGroupCounts();
    },

    selectOptions: function($context) {
      var that = this;

      $context.find('.super-fancy-cat-option').each(function() {
        that.setOption(this, true);
      });

      this.$el.trigger('change');
      this.updateGroupCounts();
    },

    selectSpecificOptions: function(options) {
      var $context = this.$fancy;
      var that = this;
      $context.find('.super-fancy-cat-option').each(selectOption(options));
      function selectOption(options){
        var opts = options;

        return function(index, element){
          var id = $(element).data('val');
          var selected = that.$el.find('option[value="{0}"]'.format(id)).prop('selected');
          var hasOption = opts[id];

          if(!selected && hasOption) {
            that.setOption(this, true);
          } else if(selected && !hasOption) {
            that.setOption(this, false);
          }
        }
      }
      this.$el.trigger('change');
      this.updateGroupCounts();
    },

    unselectOptions: function($context) {
      var that = this;

      $context.find('.super-fancy-cat-option').each(function() {
        that.setOption(this, false);
      });

      this.$el.trigger('change');
      this.updateGroupCounts();
    },

    setOption: function(fancy_option, select) {
      var which = select ? 'addClass' : 'removeClass' ;
      var id = $(fancy_option)[which]('selected').data('val');
      this.$el.find('option[value="{0}"]'.format(id)).prop('selected', select);
    },

    updateGroupCounts: function() {

      this.$fancy.find('.super-fancy-cat-group').each(function() {
        var $group = $(this);
        var $count = $group.find('.notification-count');
        var count = $group.find('.super-fancy-cat-option').length;
        var count_selected = $group.find('.super-fancy-cat-option.selected').length;
        var text = (count == count_selected && count > 0 ? 'All' : count_selected);

        $count.text(text);
      });
    }
  };

  $.fn.superFancyCat = function() {
    var args = Array.prototype.slice.apply(arguments);
    var $el = $(this).first();
    var existing = $el.data('superfancycat');

    if (existing && typeof args[0] === 'string') {
      return existing[args[0]].apply(existing, args.slice(1));
    } else {
      var ata = new SuperFancyCat(this, args[0]);
      $el.data('superfancycat', ata);
      return ata;
    }
  };
}();
