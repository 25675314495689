!function() {

  UC.modals.JobContactMessageModal= Backbone.View.extend({
    events: {
      'click #send-job-contact-message' : 'sendMessage'
    },

    initialize: function(args) {
      this.recipientId = args.recipientId;
      this.$el.modal({show: false});
      this.$template = this.$el.clone();
      this.sendMessageBtnLoader = this.$el.find('#send-job-contact-message').buttonLoader();
    },

    open: function() {
      this.$el.modal('show');
      this.$el.on('hidden.bs.modal', this.resetModal.bind(this));
    },

    resetModal: function() {
      this.$el = this.$template.clone();
      this.$el.modal({show: false});
      this.delegateEvents();
    },

    sendMessage: function() {
      this.sendMessageBtnLoader.showLoader();
      this.clearErrors();

      if(this.validate()) {
        UC.net.post('/messages', {
          recipient: this.recipientId,
          messageInput: this.$el.find('#message-text').val(),
          job: this.$el.find('#job-id').val(),
          source: 'attorney_client_profile'
        })
          .done(this.messageSent.bind(this))
          .fail(function() {
            this.sendMessageBtnLoader.hideLoader();
          }.bind);
      } else {
        this.sendMessageBtnLoader.hideLoader();
      }

    },

    validate: function() {
      var valid = true;
      var $jobListGroup;
      var $messageTextGroup;
      var messageText;

      if (!this.getJobId()) {
        valid = false;
        $jobListGroup = this.$el.find('#job-list-group');

        $jobListGroup.find('.error-message').text("A job must be selected");
        $jobListGroup.addClass('has-error');
      }

      $messageTextGroup = this.$el.find('#message-text-group');
      messageText = $messageTextGroup.find('#message-text').val();


      if (!$.trim(messageText)) {
        valid = false;
        $messageTextGroup.find('.error-message').text('The message may not be blank');
        $messageTextGroup.addClass('has-error');
      }

      return valid;
    },

    clearErrors: function() {
      $('.form-group').removeClass('has-error');
    },

    getJobId: function() {
      return this.$el.find('#job-id').val();
    },

    messageSent: function() {
      this.$el.modal('hide');
    }

  });

}();
