UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.CreateTimesheetInvoiceButtonView = Backbone.View.extend({

  tagName: 'span',

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/create_timesheet_invoice_button"),

  events: {
    'click #create-ts-invoice-btn.inactive' : 'preventDefaultClick',
    'click #create-ts-invoice-btn.enabled' : 'createTimesheetInvoiceBtnClick'
  },

  initialize: function() {
    this.collection.on('line-selected line-unselected destroy change:Hours change:Rate change:JobId', this.render.bind(this));
  },

  templateData: function() {
    return {
      anyModelsSelected: this.anyModelsSelected(),
      formattedSelectedModelFees: this.formattedSelectedModelFees(),
      newInvoiceUrlForTsLines: this.newInvoiceUrlForTsLines()
    }
  },

  render: function() {
    this.$el.html(this.template(this.templateData()));
    this.$('[data-toggle="tooltip"]').tooltip();
  },

  preventDefaultClick: function(e) {
    e.preventDefault();
  },

  createTimesheetInvoiceBtnClick: function() {
    UC.utils.recordKissEvent('create_invoice_from_timesheet_entries_click', {
      selected_timesheet_line_ids: this.collection.selectedLineItemModelIds(),
      page_path: window.location.pathname
    });
  },

  newInvoiceUrlForTsLines: function() {
    var url = '/invoices/new';
    var relevantJobId = this.collection.jobId || this.collection.selectedLineItemsJobId;
    var selectedLineItemIds = this.collection.selectedLineItemModelIds();
    var clientId = this.collection.clientId;

    var paramsObj = { timesheet_line_items: selectedLineItemIds };

    if (relevantJobId !== 'direct') {
      url = url + '/' + relevantJobId;
    }

    return url + '?' + $.param(paramsObj);
  },

  anyModelsSelected: function() {
    return this.collection.anyModelsSelected();
  },

  formattedSelectedModelFees: function() {
    return this.collection.formattedSelectedModelFees();
  }

});
