!function() {

  UC.views.ReferralsAttorney = Backbone.View.extend({
    events: {
      'submit #attorney-referral-form' : 'submitReferralForm',
      'click #open-learn-more-modal' : 'openLearnMoreModalClick',
      'click #learn-more' : 'openLearnMoreModalClick'
    },

    initialize: function() {
      _.bindAll(this, 'handleReferralFormSuccess');
      this.$select = this.$el.find('select#attorneyClients');
      if (this.$select.length > 0) {
        this.$select.select2({placeholder: 'Select...'});
      }
      this.$submitLoader = $('input[type="submit"]').buttonLoader();
      new UC.components.InputWithCopyButton({inputId: 'referral-url'});
    },

    submitReferralForm: function(e) {
      e.preventDefault();
      var $form = $(e.currentTarget);

      var formUrl = $form.attr('action');
      var typedAddresses = $form.find('#referral_email_addresses').val().split(',');
      var formData = {
        referral_message: $form.find('#attorney-referral-message').val(),
      };

      if ( this.$select.length > 0) {
        formData.referral_email_addresses = this.$select.val() || [];
      } else {
        formData.referral_email_addresses = [];
      }

      typedAddresses.forEach(function(address){
        var trimmed = address.trim();
        if (trimmed.length > 0 && formData.referral_email_addresses.indexOf(trimmed) === -1) {
          formData.referral_email_addresses.push(trimmed);
        }
      })

      this.$submitLoader.showLoader();

      UC.net.post(formUrl, formData, function (error, result) {
        this.$submitLoader.hideLoader();
        if (error) {
          UC.utils.showNotification(error, UC.constants.results.failure);
        } else {
          this.handleReferralFormSuccess();
        }
      }.bind(this));

      return false;
    },

    handleReferralFormSuccess: function(response) {
      $('#referral-thank-you-modal').modal();
      $('#referral_email_addresses').val('');
      $('#attorney-referral-message').val('');

      if (this.$select.length > 0) {
        this.$select.val('').trigger('change');
      }
    },

    openLearnMoreModalClick: function(e) {
      e.preventDefault();
      $('#referral-learn-more-modal').modal();
    }
  })

}();
