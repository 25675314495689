UC.modals.SubscriptionPromptModal = Backbone.View.extend({
  className: 'modal',

  template: UC.views.resolveHandlebarsTemplate('public/proposals/subscription_prompt_modal'),

  confirmationTemplate: UC.views.resolveHandlebarsTemplate('public/proposals/confirm_subscription_modal'),

  events: {
    'click .js-accept': 'handleAcceptance',
    'click .js-decline': 'handleDecline'
  },

  routes: {
    sendEmail: '/api/v1/request_for_subscription'
  },

  render: function() {
    this.$el.html(this.template()).modal();

    UC.utils.recordKissEvent('View Subscription Prompt');
  },

  handleAcceptance: function(e) {
    UC.utils.recordKissEvent('Click Accept Subscription');

    var data = { job_id: UC.constants.jobId }

    UC.net.post(this.routes.sendEmail, data).done(function() {
      this.$el.html(this.confirmationTemplate()).modal();
    }.bind(this))
  },

  handleDecline: function(e) {
    UC.utils.recordKissEvent('Click Decline Subscription');

    this.$el.html(this.template()).modal('hide');
  }
});
