!function() {

  UC.views.UserDocumentsHistory = Backbone.View.extend({

    events: {
      'submit #formRestoreRevision': 'restoreRevision'
    },

    elements: {
      'formRestoreRevision': null,
      'btnRestoreVersion': null
    },

    initialize: function() {
      UC.utils.setSelectors(this.elements);
      this.components = {};
      this.components.btnRestoreVersionLoader =
        this.elements.btnRestoreVersion.buttonLoader();
    },

    restoreRevision: function(e) {
      e.preventDefault();

      var version = this.elements.formRestoreRevision.find('input[name=version]:checked').val();
      var data = { version: version };

      if (!version || 0 === version.length) {
        UC.utils.showNotification('Please select a version to restore.', UC.constants.results.failure);
        return;
      }

      this.components.btnRestoreVersionLoader.showLoader();

      if (UC.job) {
        data.job_id = UC.job.id;
      }

      UC.net.post($(e.target).attr('action'), data)
        .complete(function() {
          window.location.pathname = "/documents"
        });
    }
  });
}();
