UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.InvoiceFormView = Backbone.View.extend({

  initialize: function(opts) {
    this.jobId = opts.jobId;
    this.directInvoice = !opts.jobId;
    this.clientId = opts.clientId;
    this.invoiceForm = opts.invoiceForm;
    this.$tsServiceFeeLinesBtnContainer = opts.tsServiceFeeLinesBtnContainer;
    this.$tsExpenseLinesBtnContainer = opts.tsExpenseLinesBtnContainer;

    this.collection = new UC.timesheetComponent.TimesheetLineItems(null, {
      jobId: this.jobId,
      clientId: this.clientId,
      direct: this.directInvoice,
      filterOptions: {
        current_line_items: true
      }
    });

    this.addTsModalView = new UC.timesheetComponent.AddTimesheetLinesItemsInvoiceModal({
      collection: this.collection
    });

    this.addTsBtnViewServiceFees = new UC.timesheetComponent.AddTimesheetLinesButtonInvoiceView({
      collection: this.collection,
      addTsModalView: this.addTsModalView
    });

    this.addTsBtnViewExpenses = new UC.timesheetComponent.AddTimesheetLinesButtonInvoiceView({
      collection: this.collection,
      addTsModalView: this.addTsModalView
    });

    this.collection.on('reset', this.markTsLineModelsOnInvoice.bind(this));
    this.collection.on('ts-lines-marked-on-invoice-load', this.render.bind(this));
    this.collection.on('ts-line-added-to-invoice', this.addInvoiceLineBasedOnTsLine.bind(this));

    $('#invoice-items').on('invoiceLineItemRemoved', '.invoice-line-item', this.invoiceLineRemoved.bind(this));

  },

  render: function() {
    this.addTsBtnViewServiceFees.render();
    this.addTsBtnViewExpenses.render();
    this.$tsServiceFeeLinesBtnContainer.html(this.addTsBtnViewServiceFees.$el);
    this.$tsExpenseLinesBtnContainer.html(this.addTsBtnViewExpenses.$el);
  },

  markTsLineModelsOnInvoice: function() {
    $('.invoice-line-item').each(function(i, el) {
      var tsLineId = $(el).data('timesheet-item-id');
      var model;

      if (tsLineId) {
        model = this.collection.get(tsLineId);
        model.addedToInvoice = true;
      }
    }.bind(this));

    this.collection.trigger('ts-lines-marked-on-invoice-load');
  },

  invoiceLineRemoved: function(e) {
    var $invItem = $(e.currentTarget);
    var tsLineId = $invItem.data('timesheet-item-id');

    if (tsLineId) this.collection.get(tsLineId).removeFromInvoice();
  },

  addInvoiceLineBasedOnTsLine: function(tsLineModel) {
    var lineType = tsLineModel.get('Type');
    var itemType;

    if (lineType === 'filing_fees' || lineType === 'expenses') {
      itemType = 'filing';
    } else {
      itemType = 'service';
    }

    this.invoiceForm.addLineItem(itemType, {
      date: tsLineModel.formattedDate(),
      quantity: tsLineModel.get('Hours') || 0,
      unitPrice: tsLineModel.get('Rate') || 0,
      description: tsLineModel.get('Description'),
      type: tsLineModel.get('Type'),
      timesheetLineItemId: tsLineModel.get('_id')
    });
  }
});
