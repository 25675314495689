import { renderTwoFactorRequestModal } from '../multi_factor_auth/functions'

UC.partials.V4PAJ = Backbone.View.extend({

  events: {
    'click #nextButton' : 'nextButtonClicked',
    'click .js-nextButton' : 'nextButtonClicked',
    'click #backButton' : 'backButtonClicked',
    'click .js-lnkNewJobLogin' : 'openNewJobLogin',
    'click #lnkNewJobLogin': 'openNewJobLogin'
  },

  routes: {
    update: '/job_leads/{0}'
  },

  initialize: function(options) {
    try {
      this.removePageLocationQueryParam();
    } catch(e) {
      console.error(
        "Failed to clear query param for job_lead: \"{0}\"".format(UC.constants.jobLeadId)
      );
    }

    this.elements = {
      paj_stage_wrapper: this.$el.find('#paj_stage_wrapper')
    };

    if (UC.user) {
      this.isUserRestricted();
    } else {
      this.elements.modalNewJobLogin = $('#modalNewJobLogin');
      this.elements.loginPartial = $('#loginPartial');

      this.logonForm = new UC.partials.SessionsForm({
        logonCallback: this.logonCallback.bind(this),
        oauthCallback: this.onOauthLogon.bind(this),
        modal: true
      });
    }

    this.jobLeadId = UC.constants.jobLeadId;
    this.currentStage = UC.constants.jobLeadStage;
    this.routes.update = this.routes.update.format(this.jobLeadId);
    this.initializeStageJS();

    this.addTracking();

    // outside $el because header nav is detached
    $('.header-logon').click(this.openNewJobLogin.bind(this));
  },

  removePageLocationQueryParam: function() {
    var queryParam = UC.utils.queryStringParams()

    if (queryParam) {
      if (queryParam.page_location) {
        delete queryParam.page_location;

        if ($.isEmptyObject(queryParam)) {
          window.history.replaceState({},"", window.location.pathname);
        } else {
          window.history.replaceState({},"", window.location.pathname + "?" + $.param(queryParam));
        }
      }
    }
  },

  initializeStageJS: function() {
    this.activeSteps = [];
    var stageFunctionName = this.currentStage + "Stage";
    var eventParams = {
      eventName: '{0}_stage_shown'.format(this.currentStage),
      stageName: this.currentStage,
      jobLeadId: this.jobLeadId
    };

    if (this.$('.package_selection').length > 0) {
      eventParams.jobPackage = this.$('.package_selection').data();
    }

    this.buttonLoader = this.$el.find('#nextButton').buttonLoader();
    if (typeof this[stageFunctionName] === 'function') {
      this[stageFunctionName](eventParams);
    }
    UC.utils.recordPajEvent(eventParams);
  },

  addTracking: function() {
    if (UC.constants.add_tracking != undefined) {
      UC.utils.recordKissEvent(
        UC.constants.event_name, UC.constants.track
      )
    }
  },

  createdStage: function(eventParams) {
    this.activeSteps.push(new UC.partials.Created({
      nextButtonCallback: this.nextButtonClicked.bind(this)
    }));
  },

  questionnaireStage: function(eventParams) {
    this.activeSteps.push(new UC.partials.PAJQuestion({
      el: '.job-question',
      nextButtonCallback: this.nextButtonClicked.bind(this)
    }));

    eventParams.questionId = $('.job-question').data('id');
  },

  user_infoStage: function(eventParams) {
    this.activeSteps.push(new UC.partials.UserInfo({
      el: '#js-userInfoForm',
      btnLoader: this.buttonLoader
    }));

    this.logonForm.logonCallback =
      UC.partials.UserInfo.prototype.logonCallback.bind(this)
  },

  register_userStage: function(eventParams) {
    this.activeSteps.push(new UC.partials.RegisterUser({
      el: '#registerForm',
      loggedInCallback: this.logonCallback.bind(this),
      logonErrorCallback: this.logonError.bind(this)
    }));
  },

  package_selectionStage: function(eventParams) {
    this.activeSteps.push(new UC.partials.PackageSelection({
      el: '.js-package-selection',
      nextButtonCallback: this.nextButtonClicked.bind(this)
    }));
  },

  addressStage: function(eventParams) {
    this.activeSteps.push(new UC.partials.LocationSearch({
      el: '#location_search',
      jobLeadId: this.jobLeadId
    }));
  },

  logonError: function(err) {
    if (err === 'suspended' || err === 'Your account is suspended') {
      UC.utils.showNotification("Your account has been suspended.", UC.constants.results.failure);
    } else if (err === 'pending' || err === 'Your account is pending') {
      UC.utils.showNotification("Your account is pending. Please check your email for activation link.", UC.constants.results.failure);
    } else if (err === 'unregistered' || (err && err.indexOf('LinkedIn account not found') > -1)) {
      UC.utils.showNotification("Your account was not found.", UC.constants.results.failure);
    } else {
      err = err || "An unknown error occurred.";
      UC.utils.showNotification(err, UC.constants.results.failure);
    }
  },

  logonCallback: function(err, result) {
    if (!err && result && result.two_factor_request_modal) {
      renderTwoFactorRequestModal(result.two_factor_request_modal, this.logonCallback.bind(this))
      return;
    }

    if (err) {
      this.logonForm.hideLogonLoaders();
      this.logonError(err);
    } else {
      this.updateAndLoadNextStage({ logon: true, nextStage: true });
    }
  },

  onOauthLogon: function(res) {
    if (res.status === 'pending' || res.status === 'suspended' || res.status === 'unregistered') {
      this.logonCallback(res.status);
    } else {
      this.logonCallback();
    }
  },

  nextButtonClicked: function(e) {
    if (e) e.preventDefault();
    if (this.submitting) return;
    this.submitting = true;

    this.buttonLoader.showLoader();

    UC.utils.recordPajEvent({
      eventName: 'next_button_clicked',
      stageName: this.currentStage,
      jobLeadId: this.jobLeadId
    });

    var dfds = [];
    var data = {};

    for (var i = 0; i < this.activeSteps.length; i++) {
      var dfd = new $.Deferred();
      dfds.push(dfd);
      this.activeSteps[i].validate(dfd);
    }

    this.waitForValidationAndSubmit(dfds, data);
  },

  backButtonClicked: function(e) {
    e.preventDefault();
    if (this.submitting) return;
    this.submitting = true;

    UC.utils.recordPajEvent({
      eventName: 'back_button_clicked',
      stageName: this.currentStage,
      jobLeadId: this.jobLeadId
    });

    this.updateAndLoadNextStage({revert_stage: true});
  },

  waitForValidationAndSubmit: function(dfds, data) {
    $.when.apply(this, dfds).done(function() {
      for (var i = 0; i < this.activeSteps.length; i++) {
        $.extend(data, arguments[i]);
      }

      this.updateAndLoadNextStage(data);

    }.bind(this)).fail(function() {

      this.buttonLoader.hideLoader();
      this.submitting = false;

    }.bind(this));
  },

  updateAndLoadNextStage: function(data) {
    UC.net.put(this.routes.update, data, function(res) {

      if (res.jsUser && !UC.user) {
        UC.user = res.jsUser;
      }

      if (UC.user) {
        var isUserRestricted = this.isUserRestricted();
      }

      if (!isUserRestricted && data.logon && data.nextStage) {
        this.handleLogOnUpdate(res);
        this.handleStageUpdate(res);
      } else if (!isUserRestricted && data.logon) {
        this.handleLogOnUpdate(res);
      } else {
        this.handleStageUpdate(res);
      }

    }.bind(this)).fail(function(res) {

      this.buttonLoader.hideLoader();
      UC.closeTheLoop.handleFailure(res, false);

    }.bind(this)).always(function() {

      this.submitting = false;

    }.bind(this));
  },

  openNewJobLogin: function(e) {
    e.preventDefault();

    this.elements.modalNewJobLogin.modal();
    if ($('body').hasClass('mobile-nav-open')) {
      $('#menu-toggle').trigger('click'); // close mobile menu
    }

    _.each(this.activeSteps, function(step) {
      if (typeof step.stashData === 'function') {
        step.stashData();
      }
    })
  },

  handleLogOnUpdate: function(res) {
    if (this.elements.modalNewJobLogin.is(":visible")) {
      this.elements.modalNewJobLogin.modal('hide');
    };

    this.elements.loginPartial.empty().append(res.loginPartialHtml);
    this.elements.loginPartial.find('.popdown').popdown();

    if (res.trackingUrl) {
      UC.utils.createTrackingImage(res.trackingUrl);
    }

    if ($('.mobile-nav-open #mobile-nav .logon').length > 0) {
      $('#mobile-nav .logon').html('<a href="/account/logout">Logout</a>');
    }

    new UC.components.Notifications(); // init popdown
  },

  handleStageUpdate: function(res) {
    this.elements.paj_stage_wrapper.html(res.html);
    this.currentStage = res.current_stage;
    this.initializeStageJS();
    UC.utils.scrollToTop();
  },

  isUserRestricted: function() {
    return UC.PajHelpers.isUserRestricted();
  }

});
