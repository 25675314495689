UC.partials.JobsTaskSelector = Backbone.View.extend({

  el: '#task-selector',

  events: {
    'click .task-selector-toggle-type': 'toggleManualSelection',
    'click .fancy-tile-container': 'onLegalTileSelect',
    'click .legal-tile-radio': 'onLegalTileSubjectClick',
    'change #task-selector-legal-subject': 'onLegalSubjectSelect'
  },

  routes: {
    legal_tiles: '/legal_tiles/{0}'
  },

  initialize: function(options) {
    this.options = options;
    this.originalType = this.selectionType = this.$el.data('selection-type');
    this.$legal_subject = this.$el.find('#task-selector-legal-subject');
    this.$legal_subject.select2({width: '100%', placeholder: 'Select...'});
  },

  getLegalSubjectId: function() {
    var selection_id;
    var $selected = this.$legal_subject.find('option:selected');

    if ($selected.length > 0) {
      selection_id = $selected.val().trim();
    }

    return selection_id;
  },

  toggleManualSelection: function(e) {
    e.preventDefault();
    this.changeSelectionType('manual');
  },

  changeSelectionType: function(type) {
    var $tiles = this.$el.find('#task-selector-tiles');
    var $manual = this.$el.find('#task-selector-manual');

    this.$legal_subject.val('');

    $tiles.addClass('hidden');
    $manual.addClass('hidden');

    switch(type) {
      case 'manual':
        $manual.removeClass('hidden');
        break;
      case 'tiles':
        // make sure tiles are visible and task list starts as hidden
        this.$el.find('#task-selector-tiles-subjects').empty().addClass('hidden');
        this.$el.find('#task-selector-legal-tiles').removeClass('hidden');
        $tiles.removeClass('hidden');
        break;
      default:
        throw 'The selection type {0} does not exist'.format(type);
        break;
    }

    // keep track of current selection type
    this.selectionType = type;

    if (typeof this.options.onChangeSelectionType === 'function') {
      this.options.onChangeSelectionType(this.selectionType);
    }
  },

  onLegalSubjectSelect: function(e) {
    var selection_id = this.getLegalSubjectId();

    // empty selection
    if (!selection_id) return;

    if (typeof this.options.onLegalSubjectSelect === 'function') {
      this.options.onLegalSubjectSelect(this.selectionType, selection_id);
    }
  },

  onLegalTileSelect: function(e) {
    var dfd = new $.Deferred();

    if (typeof this.options.onBeforeLegalTileSelect === 'function') {
      this.options.onBeforeLegalTileSelect(dfd);
    } else {
      dfd.resolve();
    }

    $.when(dfd).done(function() {
      var $tile = $(e.currentTarget);
      var tile_id = $tile.data('id');

      if ($tile.data('other') === true) {
        this.changeSelectionType('manual');
        UC.utils.scrollToTop();
        return;
      } else {
        this.renderLegalTileSubjects(tile_id);
      }
    }.bind(this));
  },

  renderLegalTileSubjects: function(tile_id) {

    // prevent double click junk
    if (this.loading_tiles) return;

    this.loading_tiles = true;

    UC.net.get(this.routes.legal_tiles.format(tile_id), function(result) {
      this.loading_tiles = false;

      if (result.legal_subjects.length <= 1) {
        // no choice needed since there aren't multiple options
        var selection = result.legal_subjects[0];
        this.setLegalSubjectId(selection._id);
      } else {
        // multiple choices, let's show the radio buttons
        this.$el.find('#task-selector-legal-tiles').addClass('hidden');
        this.$el.find('#task-selector-tiles-subjects').html(result.html).removeClass('hidden');
        UC.utils.scrollToTop();
      }

      if (typeof this.options.onLegalTileSelect === 'function') {
        this.options.onLegalTileSelect(tile_id, result.legal_subjects);
      }
    }.bind(this));
  },

  setLegalSubjectId: function(selection_id) {
    this.$legal_subject.val(selection_id);
    this.$legal_subject.change(); 
  },

  onLegalTileSubjectClick: function(e) {
    var selection_id = $(e.currentTarget).val();
    this.setLegalSubjectId(selection_id);
  },

  clearErrors: function() {
    UC.utils.clearValidationErrors('form-group', this.$el);
    UC.utils.clearValidationErrors('job-question', this.$el);
  },

  errors: function() {
    this.clearErrors();

    var errors = [];
    var legal_subject = this.$legal_subject.val();
    var subject_missing = !legal_subject || legal_subject === '--';

    switch(this.selectionType) {
      case 'manual':

        if (subject_missing) {
          errors.push({ element: this.$legal_subject, text: 'Please select a subcategory', className: 'form-group' });
        }
        break;
      case 'tiles':

        if (subject_missing) {
          var $el = this.$el.find('#task-selector-tiles-subjects.form-options');
          errors.push({ element: $el, text: 'Please select a legal need', className: 'job-question' });
        }
        break;
      default:
        throw 'The selection type {0} does not exist'.format(type);
        break;
    }

    return errors;
  }
});
