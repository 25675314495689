UC.views.JobsIndex = Backbone.View.extend({

  events: {
    'change #type': 'changeFilter',
    'keypress #q': 'searchOnKeyPress',
    'blur #q': 'searchOnFocusChange',
  },

  initialize: function() {
    this.$filter = this.$el.find('form#jobFilter');
    this.$filter.find('#type').select2({width: '100%'});
    this.$filter.submit(this.changeFilter.bind(this));

    this.jobsList = new UC.views.JobsList({
      el: '#job-list-container',
      eventNamePrefix: 'Jobs',
      updateUrl: '/jobs',
      filterParams: this.filterParams.bind(this),
      beforePaginate: this.pushState.bind(this)
    });

    this.$el.find('#learn-about-colors').tooltip({
      html: true,
      title: this.$el.find('#jobs-list-legend').html()
    }).click(function(e) {
      e.preventDefault();
    });

    if (window.history) {
      window.onpopstate = this.popState.bind(this);
    };

    this.jobsList.setPage(UC.utils.queryStringParams().page || 1);
    this.setState()
  },

  searchOnFocusChange: function(e) {
    this.changeFilter(e);
  },

  searchOnKeyPress: function(e) {
    if (e.key === 'Enter') {
      this.changeFilter(e);
    }
  },

  changeFilter: function(e) {
    e.preventDefault();
    this.jobsList.setPage(1);
    this.pushState();
    this.jobsList.render();
  },

  popState: function(e) {
    if (e.state) {
      this.jobsList.setPage(e.state.page);
      this.$filter.find("[name='q']").val(e.state.q);
      this.$filter.find("[name='UserId']").val(e.state.UserId);
      this.$filter.find("[name='type']").val(e.state.type).trigger('change.select2');
    }

    this.jobsList.render();
  },
  
  pushState: function() {
    if (!window.history) return;
    var state = this.createState();
    window.history.pushState(state, '', state.url);
  },

  setState: function() {
    if (!window.history) return;
    var state = this.createState();
    window.history.replaceState(state, '', state.url);
  },

  createState: function() {
    var state = this.$filter.serializeJSON();
    state.page = this.jobsList.getPage();
    state.url = window.location.href;

    var encoded = encodeURIComponent(state.q).replace(/%20/g, '+');
    state.url = UC.utils.updateQueryStringParam(state.url, 'q', encoded);
    state.url = UC.utils.updateQueryStringParam(state.url, 'type', state.type);
    state.url = UC.utils.updateQueryStringParam(state.url, 'UserId', state.UserId);
    state.url = UC.utils.updateQueryStringParam(state.url, 'page', state.page);

    return state;
  },

  filterParams: function() {
    return this.$filter.serializeJSON();              
  }
  
})
