import { default as Cookies } from 'js-cookie';

!function() {

  var COOKIE_NAME = 'nps_score';

  UC.components.NpsBox = Backbone.View.extend({

    events: {
      'click .js-close-nps-box': 'delayNpsScore',
      'click #nps-show-section-like': 'showLikeSection',
      'click #nps-show-section-improve': 'showImproveSection',
      'submit form#new_nps_score': 'submitResults',
      'click .form-radio-btn': 'saveProgress',
      'blur textarea': 'saveProgress'
    },

    initialize: function() {
      var current_section = 'nps-box-form-section-1';
      var cookie;

      if(Cookies.get(COOKIE_NAME)) cookie = JSON.parse(Cookies.get(COOKIE_NAME));

      if (cookie && cookie.CurrentSection && cookie.NpsScoreId) {
        current_section = cookie.CurrentSection;
        this.npsScoreId = cookie.NpsScoreId;
      }

      this.$el.find('#{0}'.format(current_section)).removeClass('hidden');
      this.$el.removeClass('hidden');

      UC.utils.recordKissEvent("NPS Box - show", {
        user_id: UC.user.id
      });
    },

    formData: function() {
      return this.$el.find('#new_nps_score').serializeJSON();
    },

    saveProgress: function() {
      var current_section = this.$el.find('.nps-box-form-section:not(.hidden)').attr('id');
      this.$el.find('#nps_score_CurrentSection').val(current_section);

      Cookies.set(COOKIE_NAME, JSON.stringify({CurrentSection: current_section, NpsScoreId: this.npsScoreId}), { expires: 1 });
    },

    delayNpsScore: function(e) {
      e.preventDefault();
      this.$el.addClass('hidden');
      $.ajax({
        url: '/nps_scores/hide_nps_box',
        method: 'POST'
      });

      Cookies.remove(COOKIE_NAME);

      UC.utils.recordKissEvent("NPS Box - delay clicked", {
        user_id: UC.user.id
      });
    },

    hideAllSections: function() {
      this.$el.find('.nps-box-form-section').addClass('hidden');
    },

    getNpsScore: function() {
      return this.$el.find("input[name='nps_score[Score]']:checked").val();
    },

    getUserLikesMessage: function() {
      return this.$el.find("textarea[name='nps_score[UserLikesMessage]']").val();
    },

    getUserImprovesMessage: function() {
      return this.$el.find("textarea[name='nps_score[UserImprovesMessage]']").val();
    },

    // @return - {Boolean} - success or fail
    completeScoreSection: function() {
      var score = this.getNpsScore();
      var $error = this.$el.find('#nps-box-form-section-1 .js-error-message');
      if (!score) {
        $error.removeClass('hide');
      }

      return score;
    },

    showImproveSection: function() {
      var score = this.completeScoreSection()
      if (!score) { return; };


      UC.net.post('/nps_scores', { nps_score: { Score: score }})
        .done(function(npsScoreObj) {
          this.npsScoreId = npsScoreObj._id;
          this.saveProgress();
        }.bind(this));


      this.hideAllSections();
      this.$el.find('#nps-box-form-section-improve').removeClass('hidden');
      this.saveProgress();

      UC.utils.recordKissEvent("NPS Box - score submit", {
        user_id: UC.user.id,
        score: score
      });
    },

    showLikeSection: function() {
      var userImprovesMessage = this.getUserImprovesMessage();
      var url = "/nps_scores/{0}".format(this.npsScoreId);
      if(this.npsScoreId) {
        UC.net.put(url, { nps_score: { UserImprovesMessage: userImprovesMessage }});
      }

      this.hideAllSections();
      this.$el.find('#nps-box-form-section-like').removeClass('hidden');

      UC.utils.recordKissEvent("NPS Box - improve section complete", {
        user_id: UC.user.id
      });
    },

    submitResults: function(e) {
      e.preventDefault();

      var url = "/nps_scores/{0}".format(this.npsScoreId);

      var userLikesMessage = this.getUserLikesMessage();
      if(this.npsScoreId && userLikesMessage) {
        UC.net.put(url, {
          hide_nps_box: true,
          nps_score: { UserLikesMessage: this.getUserLikesMessage() }
        });
      }

      Cookies.remove(COOKIE_NAME);

      this.hideAllSections();
      this.$el.find('#nps-box-form-section-4').removeClass('hidden');

      UC.utils.recordKissEvent("NPS Box - fully complete", {
        user_id: UC.user.id
      });
    }
  });

  $(function() {
    if (UC.user && UC.user.show_nps_box === true) {
      new UC.components.NpsBox({
        el: '#nps-box'
      });
    }
  });
}();
