UC.partials.ProposalRow = Backbone.View.extend({

  events: {
    'click .cancel-sched-call' : 'openCancelScheduledCallModal',
    'click .jp-instant-schedule-call' : 'instantScheduleCallClick'
  },

  initialize: function(opts) {
    if (opts.showMore) this.setProposalMessageReadMore();

    this.$instantCallBtn = this.$('.jp-instant-schedule-call');
    if (this.$instantCallBtn.length) this.$instantCallBtnLoader = this.$instantCallBtn.buttonLoader();

    new UC.components.FeesPopover({
      el: this.$el
    });

    this.bidId = this.$el.data('bidId');
  },

  setProposalMessageReadMore: function() {
    var messageDiv = this.el.getElementsByClassName('jp-message')[0];
    var messageDivHeight = messageDiv.clientHeight;
    var messageContentHeight = messageDiv.scrollHeight;

    if (messageContentHeight > messageDivHeight) {
      this.$el.addClass('show-read-more');
      $(messageDiv).find('.fadeout-bottom').removeClass('hidden');
    }
  },

  openCancelScheduledCallModal: function(e) {
    e.preventDefault();
    var scheduledCallId = $(e.currentTarget).data('scheduled-call-id');

    new UC.partials.ScheduledCallsCancel({scheduledCallId: scheduledCallId});
  },

  instantScheduleCallClick: function (e) {
    e.preventDefault();

    this.$instantCallBtnLoader.showLoader();

    if (UC.user.status === UC.constants.UserStatus.Pending) {
      new UC.modals.ModalPendingUser().render(
        'Please confirm your email address before scheduling a call'
      );

      return false;
    }

    var $this = $(e.currentTarget),
        attorneyId = $this.data('attorney-id');

    new UC.partials.ScheduledCallsInstant({
      attorneyId: attorneyId,
      jobId: UC.job.id,
      bidId: this.bidId,
      hamCallback: function() {
        this.$instantCallBtnLoader.hideLoader();
      }.bind(this),
      onSuccessfulSchedule: this.onSuccessfulSchedule
    }).goHam();
  },

  onSuccessfulSchedule: function () {
    location.reload();
  }

});
