UC.partials.Created = Backbone.View.extend({
  events: {
    'click .fancy-tile-container' : 'selectionChanged'
  },

  initialize: function(options) {
    this.nextButtonCallback = options.nextButtonCallback;
    this.setElement('#js-createdForm');
  },

  selectionChanged: function(e) {
    var $target = $(e.target);
    this.$el.removeClass('has-error');
    this.$el.find('.fancy-tile-container').removeClass('selected');
    $target.closest('.fancy-tile-container').addClass('selected');

    this.nextButtonCallback.call();
  },

  validate: function(dfd) {
    var data = this.$el.serializeJSON();

    dfd.resolve(data);
  }
});
