!function() {

  UC.views.DashboardTransactions =
  UC.views.DashboardIncomeReport =
  Backbone.View.extend({

    events: {
      'click #js-apply-filters': 'applyFilters'
    },

    initialize: function() {
      $("[data-toggle='tooltip']").tooltip();
      $("select").select2({width: "100%"});
      $('.datepicker').datepicker(this.datepickerOptions);
      this.$applyFiltersBtnLoader = $('#apply-trans-report-filters').buttonLoader();
      this.trackFiltersUsed();
    },

    applyFilters: function(e) {
      e.preventDefault();
      var filters = this.getFilterStates();

      if (this.filtersValid(filters)) {
        this.$applyFiltersBtnLoader.showLoader();
        filters['filters_applied_by_user'] = true;
        this.requestPageWithFilters(filters);
      }
    },

    filtersValid: function(filters) {
      var valid = true;

      var startDate = filters.start_date ? moment(filters.start_date, UC.constants.momentDateFormats, true) : null;
      var endDate = filters.start_date ? moment(filters.end_date, UC.constants.momentDateFormats, true) : null;

      if (startDate && endDate && (startDate > endDate)) {
        UC.utils.showNotification("Start Date Must be Before End Date", UC.constants.results.failure);
        valid = false;
      }

      return valid;
    },

    trackFiltersUsed: function() {
      var filters = this.getFilterStates();
      UC.utils.recordKissEvent('transactions_report_shown', filters);
    },

    getFilterStates: function() {
      var filters = {},
        $filterDivs = $(".js-filter");

      $filterDivs.each(function (index, element) {
        var $element = $(element),
          filterValue = $element.val(),
          filterName = $element.data('filterName');

        if (filterValue) filters[filterName] = filterValue;
      });

      return filters
    },

    requestPageWithFilters: function(filters) {
      var location_path = location.href.split("?")[0]

      location.href = location_path + '?' + $.param(filters);
    },

    datepickerOptions: {
      dateFormat: 'mm/dd/yy',
      changeMonth: true,
      changeYear: true,
      yearRange: "1940:+nn",
      maxDate: "0"
    }

  })

}();
