!function() {

  UC.components.CustomMessage = Backbone.View.extend({

    events: {
      'click #custom-message-view-more': 'view',
      'click .custom-message-dismiss': 'dismiss'
    },

    view: function() {
      UC.utils.recordKissEvent('custom_message_view_more_click', {
        user_id: (UC.user ? UC.user.id : ''),
        custom_message_id: this.$el.data('id')
      });
    },

    dismiss: function(e) {
      e.preventDefault();
      $.post(this.$el.data('mark-read-path'));
      this.$el.remove();

      UC.utils.recordKissEvent('custom_message_dismiss_click', {
        user_id: (UC.user ? UC.user.id : ''),
        custom_message_id: this.$el.data('id')
      });
    }
  });
}();

$(function() {
  var $el = $('#custom-message');

  if ($el.length > 0) {
    new UC.components.CustomMessage({
      el: $el
    });
  }
});
