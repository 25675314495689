UC.partials.ProposalsForm = Backbone.View.extend({

  el: '#proposal-form',

  events: {
    'submit' : 'proposalSubmitInitated',
    'input #txtProposalMessage, #proposal-background, #proposal-experience' : 'manualPropMsgUpdate',
    'click #prop-preview-btn' : 'showProposalPreview'
  },

  routes: {
    previewUrl: '/jobs/{0}/proposals/preview'
  },

  initialize: function(opts) {
    this.$submitBtn = this.$(opts.submitSelector);
    this.submitLoader = this.$submitBtn.buttonLoader();
    this.attorneyHasPayoutAcct = opts.attorneyHasPayoutAcct;
    this.attorneyAccountUnverified = opts.attorneyAccountUnverified;
    this.propMsgUpdatedManually = false;
    this.deluxeBid = this.$('[name="proposal[DeluxeBid]"]').val() === 'true';
    this.validatePropMessagesUpdated = opts.validatePropMessagesUpdated;
    this.jobId = opts.jobId;
    this.$previewBtn = this.$('#prop-preview-btn');

    if (this.$previewBtn.length > 0) {
      this.$previewBtnLoader = this.$previewBtn.buttonLoader();
    }

    this.quoteForm = new UC.partials.ProposalsFormQuote();

    quoteFormOptions = {payment_profile: opts.paymentProfile};

    if (opts.attorneyClient) {
      quoteFormOptions['client_id'] = opts.attorneyClient.UserId;

      if (opts.attorneyClient.HourlyRate) {
        quoteFormOptions['hourly_rate'] = opts.attorneyClient.HourlyRate;
      } else if (opts.attorneyClient.latest_hourly_rate) {
        quoteFormOptions['hourly_rate'] = opts.attorneyClient.latest_hourly_rate;
      } else {
        quoteFormOptions['hourly_rate'] = 0;
      }
    }

    this.quoteForm.render(quoteFormOptions);

    if(opts.toggleToHourly) {
      this.quoteForm.$el.find('#tabHourlyFee').trigger('click');
    }

    this.initProposalTemplates();
  },

  validate: function() {
    var errors = [];

    this.$('.form-group').removeClass('has-error').find('.error-message').text('');

    if (this.deluxeBid) {
      var $relevantExperience = this.$("[name='proposal[RelevantExperience]']");

      if ($relevantExperience.val().isBlank()) {
        errors.push({element: $relevantExperience, text: 'Please enter your relevant experience'});
      }

      var $background = this.$("[name='proposal[Background]']");

      if ($background.val().isBlank()) {
        errors.push({element: $background, text: 'Please provide a description of your background and work history'});
      }
    }

    errors = errors.concat(this.quoteForm.errors());

    var $proposalDescription= this.$("[name='proposal[Description]']");

    if ($proposalDescription.val().isBlank()) {
      errors.push({element: $proposalDescription, text: 'Please provide a proposal message'});
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, { scrollToFirst: true, className: 'form-group' });
      return false;
    } else {
      return true;
    }
  },

  proposalSubmitInitated: function(e) {
    e.preventDefault();
    if (!this.validate()) return;

    if (!this.attorneyHasPayoutAcct) {
      $('#modalNoBankAccount').modal();
    } else if (this.attorneyAccountUnverified) {
      $('#modalUnverifiedAccount').modal();
    } else {
      this.submitLoader.showLoader();

      if(this.validatePropMessagesUpdated && !this.propMsgUpdatedManually) {
        UC.utils.showConfirmModal(
          'Confirm Submit Proposal',
          'Are you sure your want to submit this proposal without editing your template?',
          function(response){
            if (response) {
              this.submitProposalForm();
            } else {
              this.submitLoader.hideLoader();
            }
          }.bind(this)
        );
      } else {
        this.submitProposalForm();
      }
    }
  },

  submitProposalForm: function() {
    var formSubmitUrl = this.$el.attr('action');
    var formData = this.serializeForm();

    UC.net.post(formSubmitUrl, formData)
      .fail(function(res) {
        this.submitLoader.hideLoader();
        UC.closeTheLoop.handleFailure(res, false);
      }.bind(this));
  },

  showProposalPreview: function(e) {
    e.preventDefault();
    if (!this.validate()) return;

    this.$previewBtnLoader.showLoader();
    var previewUrl = this.routes.previewUrl.format(this.jobId);
    var formData = this.serializeForm();

    UC.net.post(previewUrl, formData)
      .done(function(res) {
        this.renderPreviewModal(res.proposal_html);
      }.bind(this))
      .fail(function(res) {
        this.submitLoader.hideLoader();
        UC.closeTheLoop.handleFailure(res, false);
      }.bind(this))
      .always(function() {
        this.$previewBtnLoader.hideLoader();
      }.bind(this));
  },

  renderPreviewModal: function(proposalHtml) {
    new UC.partials.modalV9({
      modalTitle: "Proposal Preview",
      modalContent: proposalHtml,
      id: 'prop-preview-modal',
      renderCallback: function() {
        UC.utils.initRatingDimensions('#prop-preview-modal .jp-reviews');

        // Don't love calling this function from here but it's used a bunch of places.
        if ($('#prop-preview-modal .jp-fee-details-btn').length) {
          $('#prop-preview-modal .jp-fee-details-btn')
            .click(UC.partials.ProposalsForm.prototype.proposalOnShowFeesClick);
        }

        $('#prop-preview-modal .jp-fee-details').find('ul').click(function(e) {
          e.preventDefault();
          e.stopPropagation();
        });

        $('.js-estimated-tooltip').on('click', function() {
          $('.tooltip-blurb').toggleClass('hidden');
        });

        new UC.components.FeesPopover({
          el: $('#prop-preview-modal'),
          container: 'body'
        });
      }
    })
  },

  proposalOnShowFeesClick: function (e) {
    e.preventDefault();
    e.stopPropagation();

    var $this = $(e.target);
    var $container = $this.parentsUntil('.jp-bid', '.jp-fee-details');
    var isActive = $container.hasClass('active');

    $('.jp-fee-details').removeClass('active');

    if (!isActive) {
      var $proposalDiv = $this.parents('.jp-single');
      var proposalId;

      if ($proposalDiv.length > 0) proposalId = $proposalDiv.data('bidId');

      var kissData = {
        pageName: UC.constants.pageName,
        userId: UC.user.id,
        proposalId: proposalId,
        location: location.href
      };

      UC.utils.recordKissEvent('fee_detail_dropdown_shown', kissData);
      $container.addClass('active');
    }
  },

  serializeForm: function() {
    var formData = this.$el.serializeJSON();
    if (formData.FilingFees) formData.FilingFees = UC.utils.currencyToFloat(formData.FilingFees);
    if (formData.FilingFees) formData.ServiceFees = UC.utils.currencyToFloat(formData.ServiceFees);
    return formData;
  },

  initProposalTemplates: function() {
    var modalOptions = {
      el: '.js-modalChooseProposalTemplates',
      select: true,
      selectCallback: this.chooseTemplateModalCallback.bind(this)
    };

    this.templateList = new UC.views.ProposalTemplatesIndex(modalOptions);
    $('.insert-template').click(this.onInsertTemplateClick.bind(this));
    $('.insert-template').tooltip();
  },

  manualPropMsgUpdate: function() {
    this.propMsgUpdatedManually = true;
  },

  onInsertTemplateClick: function(e) {
    e.preventDefault();
    $('.insert-template').tooltip('hide');
    $('.js-modalChooseProposalTemplates').modal();
    this.templateTargetSelector = $(e.currentTarget).data('templateTarget');
  },

  chooseTemplateModalCallback: function(templateId) {
    $('.js-modalChooseProposalTemplates').modal('hide');

    if (!templateId) return;

    var clientId;
    if (UC.constants.jobOwnerId) {
      clientId = UC.constants.jobOwnerId;
    }

    UC.net.get('/proposal_templates/{0}'.format(templateId), {client_id: clientId}, function(proposalTemplate) {
      var $templateTarget = $(this.templateTargetSelector);

      if ($templateTarget.val().trim() !== '') {
        $templateTarget.val( $templateTarget.val() + "\n\n");
      }

      $templateTarget.val( $templateTarget.val() + proposalTemplate.Text );

      UC.utils.sendNesEvent('proposal_template_inserted', { template_id: templateId });
      UC.utils.recordKissEvent('proposal_template_inserted', { template_id: templateId });

    }.bind(this));
  }

});
