!function() {

  var Search = UC.components.Search = function() {
    this.open = false;
    this.$body = $('body');
    this.$bar = $('#global_search');
    this.$nav = $('#nav');

    $('#search_link').click(this.toggleSearch.bind(this));

    this.toggle_nav_transparency = this.$nav && this.$nav.hasClass('navbar-transparent');
  };

  Search.prototype = {

    constructor: Search,

    toggleSearch: function(e) {
      e.preventDefault();

      if (this.open) {
        // close
        this.$body.removeClass('searching');

        if (this.toggle_nav_transparency && this.$nav.hasClass('transparent-scroll-zone')) {
          this.$nav.addClass('navbar-transparent');
        }
      } else {
        // open
        this.$body.addClass('searching');

        if (this.toggle_nav_transparency) {
          this.$nav.removeClass('navbar-transparent');
        }

        if (this.$nav && this.$nav.hasClass('navbar-fixed-top')) {
          this.$bar.css('position', 'fixed');
        } else {
          this.$bar.css('position', 'aboslute');
        }

        setTimeout(function() {
          this.$bar.find('input.search_input').focus();
        }.bind(this), 201);
      }

      this.open = !this.open;
    }
  };

  $(function() {
    if (!$('body').hasClass('search')) {
      new Search();
    }
  });
}();
