UC.views.MultiFactorAuthPrintRecoveryCodes = Backbone.View.extend({
  events: {
    'click #print': 'printCodes',
  },

  initialize: function() {
    const sudo = new UC.partials.SessionSudoForm({el: this.$el})

    if (!sudo.isActive()) {
        window.onafterprint = function () {
            window.close()
        };
        this.printCodes();
    }
  },

  printCodes: function() {
    window.print();
  },
});
