!function() {

  UC.views.StaticPagesAttorneys = Backbone.View.extend({

    initialize: function() {
      this.initAttyCarousel();
    },

    initAttyCarousel: function() {
      if (typeof $.fn.slick !== 'function') {
        console.log('slick is not initialized yet.');
        return;
      }
      $("#attorney-carousel-container").slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        nextArrow: "<i class='fontello icon-angle-right atty-carousel-next'></i>",
        prevArrow: "<i class='fontello icon-angle-left atty-carousel-prev'></i>",
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    }
  })

}();
