!function() {

  UC.views.AttorneyClientsEdit = Backbone.View.extend({

    initialize: function() {
      new UC.views.AttorneyClientsForm();
    }

  })

}();
