!function() {

  var defaults = {
    checked: false,
    label: null,
    cssClass: ''
  };

  function FancyCheck(el, options) {
    this.$el = $(el);
    this.options = $.fn.extend({}, defaults, options);
    this.checked = this.options.checked;
    this.manualChecking = this.options.manualChecking;

    if (this.checked) {
      this.$el.addClass('checked');
    }

    if (!this.options.label && this.$el.text()) {
      this.options.label = this.$el.text();
    }

    this.$el.addClass('fancy-check noselect {0}'.format(this.options.cssClass));

    this.render();
    
    if (!this.manualChecking) this.bindEvents();
    this.bindCheck();
  }

  FancyCheck.prototype = {

    constructor: FancyCheck,

    render: function() {
      var html = '<span class="checkbox"></span>';

      if (this.options.label) {
        html += '<label for="{0}">{1}</label>'.format(this.$el.attr('id'), this.options.label);
      }

      this.$el.html(html);
    },

    bindEvents: function() {

      this.$el.on('click', function() {
        if (!this.$el.hasClass('disabled')) {
          this.checked = !this.checked;
          if (this.checked) {
            this.$el.addClass('checked');
          } else {
            this.$el.removeClass('checked');
          }
          this.$el.trigger('change', this.checked);
        }
      }.bind(this));

      this.$el.on('keydown', function(e) {
        if (!this.$el.hasClass('disabled')) {
          if(e.keyCode == 32){
            e.preventDefault();
            this.$el.trigger('click');
          }
        }
      }.bind(this));
    },

    check: function() {

      if (!this.$el.hasClass('disabled')) {
        if (arguments.length == 0) { // get
          return this.checked;
        } else if (arguments[0] === true) {
          this.checked = true;
          this.$el.addClass('checked');
          this.$el.trigger('change');
        }
        else if (arguments[0] === false) {
          this.checked = false;
          this.$el.removeClass('checked');
          this.$el.trigger('change');
        }
      }

      return this.$el;
    },

    bindCheck: function() {
      this.$el.check = this.check.bind(this);
    }
  };

  $.fn.fancyCheck = function() {
    var args = Array.prototype.slice.apply(arguments);
    var existing = $(this).data('fancycheck');

    if (existing && typeof args[0] === 'string') {
      return existing[args[0]].apply(existing, args.slice(1));
    } else {
      var fancy = new FancyCheck(this, args[0]);
      $(this).data('fancycheck', fancy);
      return fancy.$el;
    }
  };
}();
