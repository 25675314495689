var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"attorney-card\">\n  <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attorney") : depth0)) != null ? lookupProperty(stack1,"ProfileUrl") : stack1), depth0))
    + "\" target=\"_blank\">\n    <img src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attorney") : depth0)) != null ? lookupProperty(stack1,"PictureUrl") : stack1), depth0))
    + "\" alt=\"\" onerror=\"this.onerror=null;this.src='"
    + alias2(((helper = (helper = lookupProperty(helpers,"default_profile_img_url") || (depth0 != null ? lookupProperty(depth0,"default_profile_img_url") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"default_profile_img_url","hash":{},"data":data,"loc":{"start":{"line":3,"column":83},"end":{"line":3,"column":110}}}) : helper)))
    + "';\">\n  </a>\n  <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attorney") : depth0)) != null ? lookupProperty(stack1,"ProfileUrl") : stack1), depth0))
    + "\" target=\"_blank\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attorney") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "</a><br>\n  "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attorney") : depth0)) != null ? lookupProperty(stack1,"Status") : stack1), depth0))
    + "\n</div>\n";
},"useData":true});