UC.partials.ScheduledCallsCancel = Backbone.View.extend({

    tagName: 'div',

    className: 'modal fade',

    id: 'cancel-sched-call',

    template: UC.views.resolveHandlebarsTemplate("public/calls/cancel"),

    events: {
      'hidden.bs.modal': 'remove',
      'submit' : 'formSubmit'
    },

    routes: {
      cancel_scheduled_call: '/scheduled_calls/{0}/cancel'
    },

    initialize: function(opts) {
      this.scheduledCallId = opts.scheduledCallId;
      this.render();
    },

    render: function() {
      this.$el.html(this.template({formPath: this.formPath()}));
      this.$el.modal();
      this.$form = this.$('form');
      this.$submitBtnLoader = this.$('#submit-sc-cancel').buttonLoader();

      UC.utils.recordKissEvent('cancel_sched_call_modal_opened', {
        loc: window.location.href
      });

    },

    formPath: function() {
      return this.routes.cancel_scheduled_call.format(this.scheduledCallId);
    },

    formSubmit: function(e) {
      e.preventDefault();
      this.$submitBtnLoader.showLoader();

      var data = this.$form.serializeJSON();

      UC.net.post(this.$form.attr('action'), data)
        .done(function() {
          location.reload();
        });
    }

});
