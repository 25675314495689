!function() {

  UC.modals.ScheduledCallsShow = Backbone.View.extend({

    className: 'modal fade',

    events: {
      'hidden.bs.modal': 'remove'
    },

    routes: {
      scheduled_calls_show: '/scheduled_calls/{0}'
    },

    initialize: function(options) {
      this.options = options || {};
      this.scheduled_call_id = this.options.scheduled_call_id;
      return this;
    },

    render: function() {

      var path = this.routes.scheduled_calls_show.format(this.scheduled_call_id);

      UC.net.get(path, function(res) {
        this.$el.html(res.html);
        this.$el.modal();

        new UC.partials.ScheduledCallsChooseAvailable({
          el: this.$el,
          isModal: true,
          onReply: function(result) {
            this.$el.modal('hide');
            $('body').find('.btn-schedule-call-reply').remove();

            if (typeof this.options.callback === 'function') {
              this.options.callback(result);
            }
          }.bind(this)
        });

        var data = {
          json_attributes: {
            time_to_first_availability: this.$('.js-select-time-step').data('firstAvailability'),
            client_id: this.$('#scheduled_calls_form_reply').data('clientId'),
            bid_id: this.$('#scheduled_calls_form_reply').data('bidId')
          },
          attorney_id: this.$('#scheduled_calls_form_reply').data('attorneyId'),
        };
        UC.utils.sendNesEvent('schedule_call_reply_click', data);

      }.bind(this));
    },
  });
}();
