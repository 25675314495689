!function() {

  UC.views.JobsProposals = Backbone.View.extend({

    events: {
      'click #no-props-close-job' : 'onClickedNoPropsCloseJob',
      'click #request-more-bids' : 'onRequestMoreBidsClick',
      'click #proposals-tabs .btn': 'toggleProposalsTab',
      'click .jp-invite-btn': 'openInviteModal'
    },

    initialize: function() {
      this.requestMoreBidsButtonLoader = $('#request-more-bids').buttonLoader();
      $('body').on('proposalDeclined', this.onProposalDeclined.bind(this));
      if (UC.constants.showImmediateBiddingRetractNotification) this.showImmediateBiddingRetractNotification();
      UC.utils.initRatingDimensions('.jp-reviews');

      $('.jp-invite-btn').tooltip({
        trigger: 'hover',
        title: 'Ask this lawyer to submit a proposal for your project. You may request proposals from more than one lawyer.'
      });

      $('#proposals-tabs a[href="#suggested"]').tooltip({
        trigger: 'hover',
        container: 'body',
        placement: 'bottom',
        title: 'Lawyers who have completed projects like yours.'
      });

      $('#proposals-tabs a[href="#proposals"]').tooltip({
        trigger: 'hover',
        container: 'body',
        placement: 'bottom',
        title: 'Proposals from lawyers who want to work with you.'
      });

      this.initProposalRows();
    },

    toggleProposalsTab: function(e) {
      e.preventDefault();
      var $btn = $(e.currentTarget);
      $btn.parent().find('.active').removeClass('active');
      $btn.addClass('active');

      UC.utils.recordKissEvent('suggested_attorneys_tab_changed', {
        tab: $btn.text().trim()
      });
    },

    openInviteModal: function(e) {
      e.preventDefault();

      if (UC.user.status === UC.constants.UserStatus.Pending) {
        var $modal = $('#modalPendingUser');
        var message = 'Please confirm your email address before requesting a proposal';
        $modal.find('h4.modal-title').text(message);
        $modal.modal();
        return false;
      }

      var $btn = $(e.currentTarget);
      var $proposal = $btn.closest('.jp-single');
      var attorney_id = $btn.data('attorney-id');

      new UC.partials.JobsModalRequestInvite({
        attorney_id: attorney_id,
        attorney_first_name: $btn.data('attorney-first-name'),
        callback: function() {
          $btn.parent().addClass('hide');
          $proposal.find('.jp-invited-btn').parent().removeClass('hide');
        }
      }).render();

      UC.utils.recordKissEvent('suggested_attorneys_request_clicked', {
        attorney_id: attorney_id
      });
    },

    onClickedNoPropsCloseJob: function(e) {
      e.preventDefault();
      $(e.currentTarget).trigger('userCancelJobClick');
    },

    onRequestMoreBidsClick: function(e) {
      e.preventDefault();
      this.requestMoreBidsButtonLoader.showLoader();

      UC.net.post('/job/{0}/immediate_bidding_request_proposals'.format(UC.job.id), {})
        .done(function() {
          // A redirect should be triggered by a global handler
        })
        .fail(function() {
          this.requestMoreBidsButtonLoader.hideLoader();
        }.bind(this));
    },

    onProposalDeclined: function(e) {
      if (!$('.jp-single').hasClass('submitted')) {
        $('#request-addl-proposals-container').removeClass('hidden');
      }
    },

    showImmediateBiddingRetractNotification: function() {
      UC.utils.showNotification("Some of the suggested lawyers are unable to work on your project. We have invited additional attorneys to submit proposals, and you will be able to review these proposals soon.", null, 0);
      UC.utils.recordKissEvent('immediate_bidding_retract_notification_shown', {
        loc: 'job_proposals_page'
      });
    },

    initProposalRows: function() {
      this.$('.jp-single').each(function(i, elem) {
        new UC.partials.ProposalRow({
          el: elem,
          showMore: true
        })
      });
    }
  })

}();
