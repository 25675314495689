UC.components.TableOfContents = Backbone.View.extend({
  initialize: function(options) {
    options = options || {}
    const $affixPanel = $('.toc-column .affix-panel');
    const affixAvailable = typeof $affixPanel.affix === 'function';
    const mobileHeaderHeight = 48 + 41; // $header-height-mobile + $toc-height-mobile
    const tocNavSelector = '#toc';

    $affixPanel.css('width', $('.toc-column').width());

    $(window).resize(function() {
      $affixPanel.css('width', $('.toc-column').width());

      if (affixAvailable) {
        $affixPanel.affix('checkPosition');
      }
    });

    if (typeof Toc === 'object') {
      Toc.init({
        $nav: $(tocNavSelector),
        $scope: $(options.scope),
        startWithHeadingsLevel: 2
      });
    } else {
      $('.toc-column').hide();
      $('.content-column').toggleClass(`${options.tocEnabledColumnClass || 'col-md-7'} ${options.tocDisabledColumnClass || 'col-md-9'}`);
    }

    const $stickyHeader = $('.sticky-cta-header');
    const isMobile = UC && UC.browser && typeof UC.browser.isMobile === 'function' && UC.browser.isMobile();
    const stickyHeaderHeight = Math.max($stickyHeader.length && !isMobile ? $stickyHeader.height() : 0, 0);
    const scrollspyOffset = $stickyHeader.length && !isMobile ? $stickyHeader.height() : mobileHeaderHeight + 20;
    const $body = $('body')
    if (typeof $body.scrollspy === 'function') {
      $body.scrollspy({target: tocNavSelector, offset: scrollspyOffset + 20});
      if (isMobile) {
        $body.on('activate.bs.scrollspy', function (e) {
          if ($(e.target).find('li.active').length > 0) {
            return
          }
          const list = $('#toc').find('li');
          const index = list.index(e.target) + 1;
          const progress = Math.min(100, (index / list.length) * 100);
          $('.mobile-toc .progress-bar').css('width', `${progress}%`);
        })
      }
    }

    $affixPanel.on('affixed.bs.affix', function() {
      $affixPanel.css('position', 'fixed');
    }).on('affixed-top.bs.affix', function() {
      $affixPanel.css('position', 'relative');
    });

    if (affixAvailable) {
      $affixPanel.affix({
        offset: {
          top: isMobile ? mobileHeaderHeight : options.affixTop,
          bottom: options.affixBottom,
        }
      });
    }

    var $stickySection = $('.js-sticky-section');
    if($stickySection.length > 0) {
      this.stickyStart = 5;
      if (!$stickySection.hasClass('js-sticky-nav')) {
        var desc = $('.doc-description, #articleTitle');
        var offset = desc.offset();

        this.stickyStart = offset.top + desc.height();
      }
      $(window).scroll({start: this.stickyStart, view: this}, this.testScroll);
    }

    // override TOC link clicks to offset the sticky header height
    $('#toc a').on('click', function (e) {
      const href = e.target.getAttribute('href');
      if (!href) {
        return;
      }
      try {
        const element = document.getElementById(href.substring(1));
        const stickySectionsExist = $('.sticky-section, .js-sticky-section').length > 0;
        let headerOffset = stickyHeaderHeight;
        if (isMobile && stickySectionsExist) {
          headerOffset = 48 + 41 /* $header-height-mobile + $toc-height-mobile */;
        }
        window.scrollBy({
          top: element.getBoundingClientRect().top - headerOffset - 20 + 1,
          behavior: "smooth"
        });
        e.preventDefault();
      } catch {
        console.warn('failed to scroll to Toc heading')
      }
    });
  },

  testScroll: function(event) {
    if ($(window).scrollTop() >= event.data.start) {
      if (!event.data.view.showStickySection) {
        event.data.view.showStickySection = true;
        $('.js-sticky-section').first().css({display:'block'});
      }
    } else {
      if (event.data.view.showStickySection) {
        event.data.view.showStickySection = false;
        $('.js-sticky-section').first().css({display:'none'});
      }
    }
  },
});
