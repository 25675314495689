UC.views.JobsAttorneyProposal = Backbone.View.extend({
  events: {
    'click #aRetractProposal': 'retractProposalLinkHandler',
    'click #btnRetractProposal': 'retractProposalBtnHandler'
  },

  initialize: function() {
    new UC.partials.FeesDetails();

    UC.utils.initRatingDimensions('.jp-reviews');

    new UC.components.FeesPopover({ el: '.jp-single' });
  },

  onProposalUnDecline: function(err, message) {
    if (err) {
      this.showNotification(err, false);
    }
    else {
        this.showNotification(message, true);
        UC.utils.recordKissEvent('Job Page: Bid Actions - Show Bid Click');
    }
  },

  showNotification: function(message, success) {
    this.$('#panelNotification').removeClass('red green').addClass(success ? 'green' : 'red').find('div').html(message);
    UC.utils.showPanel(this.$('#panelNotification'));
    UC.utils.scrollToElement(this.$('#panelNotification'));
  },

  retractProposalLinkHandler: function(e) {
    e.preventDefault();

    this.$('#modalRetractProposal').modal();
  },

  retractProposalBtnHandler: function(e) {
    var $reasonField = this.$('#txtRetractReason');
    var reason = $reasonField.val();
    this.btnRetractProposalLoader = this.$('#btnRetractProposal').buttonLoader();

    $reasonField.closest('.row').removeClass('has-error');

    if (reason === '') {
      $reasonField.closest('.row').addClass('has-error');
    }
    else {
      this.btnRetractProposalLoader.hideLoader();
      this.retractProposal(reason, this.onRetractProposal.bind(this));
    }
  },

  retractProposal: function(reason, callback) {
    UC.net.post('/job/retract_proposal/{0}'.format(UC.constants.jobId), {reason: reason}, callback);
  },

  onRetractProposal: function (err, message) {
    this.$('#btnSubmitProposalLoader').buttonLoader().hideLoader();
    this.$('#modalRetractProposal').modal('hide');

    if (err) {
      UC.utils.showNotification(err, UC.constants.results.failure);
    }
    else {
      UC.utils.showNotification(message, UC.constants.results.success);
      $('.jp-single').addClass('retracted').removeClass('submitted');
      this.$('#txtRetractReason').val('');
    }
  }
});
