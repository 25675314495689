UC.partials.FeesDetails = Backbone.View.extend({
  el: 'body',

  events: {
    'click .jp-fee-details-btn': 'feeDetailsClickHandler',
    'click .jp-fee-details ul': 'noEventAction',
    'click .js-estimated-tooltip': 'toggleEstimatedToolTip',
    'click' : 'hideFeeDetails'
  },

  feeDetailsClickHandler: function(e) {
    e.preventDefault();
    e.stopPropagation();

    var $container = $(e.target).parentsUntil('.jp-bid', '.jp-fee-details');
    var isActive = $container.hasClass('active');
    var $proposalDiv;
    var proposalId;

    $('.jp-fee-details').removeClass('active');

    if (!isActive) {
        $proposalDiv = $(e.target).parents('.jp-single');
        if ($proposalDiv.length > 0) proposalId = $proposalDiv.data('bidId');

        var kissData = {
          pageName: UC.constants.pageName,
          userId: UC.user.id,
          proposalId: proposalId,
          location: location.href
        };
        UC.utils.recordKissEvent('fee_detail_dropdown_shown', kissData);
        $container.addClass('active');
    }
  },

  toggleEstimatedToolTip: function(e) {
    $('.tooltip-blurb').toggleClass('hidden');
  },

  noEventAction: function(e) {
    e.preventDefault();
    e.stopPropagation();
  },

  hideFeeDetails: function(e) {
    $('.jp-fee-details').removeClass('active');
  }
});
