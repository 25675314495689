UC.components.contentPage = {};
UC.components.contentPage.data = {};
UC.components.contentPage.components = {};

UC.components.contentPage.onClickPostJobBtn = function(e) {
  var loc = $(e.target).attr("btn-loc");
  if (loc){
    var p = window.location.pathname;
    UC.utils.recordKissEvent('Post a Job Button Click', {"location" : loc, "page-path": p, 'page' : $contentPage.data.page});
  }
};

UC.components.contentPage.removeCtaStickyFooter = function() {
  $('.sticky-cta-footer').removeClass('active');
  $('#footer').removeClass('sticky-footer-active');
};

UC.components.contentPage.setCtaBannerSize = function(header) {
  var scrollTop = $(document).scrollTop(),
      $stickyElement,
      isMobile,
      headerTop = header.offset().top;

  if (!UC.utils.isMobileViewPort()) {
    $stickyElement = $('.sticky-cta-header');
    isMobile = false;
  } else {
    $stickyElement = $('.sticky-cta-footer');
    isMobile = true;
  }

  if($stickyElement.length > 0) {
    if (scrollTop >= headerTop) {
      $stickyElement.addClass('active');
      if (isMobile) {
        $('#footer').addClass('sticky-footer-active');
      }
    } else {
      $stickyElement.removeClass('active');
      if (isMobile) {
        $('#footer').removeClass('sticky-footer-active');
      }
    }
  }
};

UC.components.contentPage.setHeaderSize = function() {
  var scrollTop = $(document).scrollTop(),
      $header = $('.fixed'),
      $footer = $('.sticky-footer');

  if (scrollTop >= $contentPage.data.ctaBtn) {
    $header.addClass('small');
    $footer.addClass('active');
  } else {
    $header.removeClass('small');
    $footer.removeClass('active');
  }
};

UC.components.contentPage.init = function () {
  window.$contentPage = UC.components.contentPage;

  $contentPage.data.ctaBtn = $(".main-cta-btn").length > 0 ? $(".main-cta-btn").offset().top : 150;
  $contentPage.data.page = window.location.pathname.replace("/","");

  $(".btn[data-loc]").click($contentPage.onClickPostJobBtn);
  $contentPage.setHeaderSize();
  $(document).scroll($contentPage.setHeaderSize);

  UC.utils.initRatingDimensions();

  /* Seo Mother Pages */
  UC.utils.recordKissClickEvent('.mother-document-cross-link', 'Seo Cross Link Click', {"category": "document", "page-path": location.href, "page-tag" : "seo mother"});
  UC.utils.recordKissClickEvent('.mother-article-cross-link', 'Seo Cross Link Click', {"category": "article", "page-path": location.href, "page-tag" : "seo mother"});
  UC.utils.recordKissClickEvent('.mother-topic-cross-link', 'Seo Cross Link Click', {"category": "topic", "page-path": location.href, "page-tag" : "seo mother"});

  /* Seo Child Pages */
  UC.utils.recordKissClickEvent('.child-document-cross-link', 'Seo Cross Link Click', {"category": "document", "page-path": location.href, "page-tag" : "seo page"});
  UC.utils.recordKissClickEvent('.child-article-cross-link', 'Seo Cross Link Click', {"category": "article", "page-path": location.href, "page-tag" : "seo page"});
  UC.utils.recordKissClickEvent('.child-topic-cross-link', 'Seo Cross Link Click', {"category": "topic", "page-path": location.href, "page-tag" : "seo page"});
  UC.utils.recordKissClickEvent('.child-nearby-cross-link', 'Seo Cross Link Click', {"category": "nearby", "page-path": location.href, "page-tag" : "seo page"});


};

$(UC.components.contentPage.init);
