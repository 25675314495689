UC.views.StaticPages = Backbone.View.extend({

  events: {
    'click .section-nav-tab' : 'navTabClicked'
  },

  initialize: function() {
    $(window).on('scroll', this.onWindowScroll.bind(this));
    this.cta_btn = $(".main-cta-btn").length > 0 ? $(".main-cta-btn").offset().top : 150;

    /* For Attorneys */
    UC.utils.recordKissClickEvent('#forattorneys-apply-now', 'For Attorneys Apply Now Click', {
      "location": "hero",
      "page-path": "/home/forattorneys"
    });

    UC.utils.recordKissClickEvent('#forattorneys-sign-up', 'For Attorneys Sign Up Click', {
      "location": "footer",
      "page-path": "/home/forattorneys"
    });
  },

  navTabClicked: function(e) {
    var $this = $(e.currentTarget);
    var id = $this.attr('href') || $this.find('a').attr('href');
    var hasHash = (id && id.substr(0,1) == '#');
    var nav = $this.closest('.section-nav');
    var options = { offset: 100 };

    if (UC.utils.isMobileViewPort()) {
      e.stopPropagation();

      if(nav.hasClass('selected')) {
        $('.section-nav-tab').removeClass('active');
        $this.addClass('active');
        nav.removeClass('selected');
      } else {
        e.preventDefault();
        nav.addClass('selected');
        if (hasHash) {
          return;
        }
      }
    }       

    if (hasHash) {
      e.preventDefault();
      UC.utils.scrollToElement($(id), options);
    } 
  },

  onWindowScroll: function(e) {
    var scrollTop = $(document).scrollTop();
    var navbar = $('#nav.navbar-fixed-top');
    var nav = $('.section-nav');
    var nav_wrap = nav.closest('.section-nav-wrap');
    var sticky = nav.hasClass('sticky');
    var footer = $('.sticky-footer');

    if (scrollTop >= this.cta_btn) {
      $('body').addClass('navbar-small');
      navbar.addClass('navbar-small');
      footer.addClass('active');
    } else {
      $('body').removeClass('navbar-small');
      navbar.removeClass('navbar-small');
      footer.removeClass('active');
    }

    if (!sticky) {
      return;
    }

    if (!UC.utils.isMobileViewPort() && scrollTop >= nav_wrap.offset().top) {
      nav.addClass('fixed');
      $('.nav-section').each(function(i) {           
        if ($(e.currentTarget).position().top <= scrollTop + 100) {
          $('.section-nav-tab.active').removeClass('active');
          $('.section-nav-tab').eq(i).addClass('active');
        }
      });
      footer.addClass('active');

    } else {
      nav.removeClass('fixed');
      $('.section-nav-tab.active').removeClass('active');
      $('.section-nav-tab:first').addClass('active');
    }
  }

});
