!function() {

  UC.partials.ScheduledCallsFormReply = Backbone.View.extend({

    el: '#scheduled_calls_form_reply',

    events: {
      'submit': 'reply',
      'change #TimeZone': 'changeTimeZone',
      'click #not-available': 'notAvailable',
      'shown.bs.tab a[data-toggle=tab]': 'setCurrentMode'
    },

    routes: {
      instant: '/users/{0}/scheduled_calls/instant',
      scheduled_calls_provide_alternatives: '/scheduled_calls/{0}/provide_alternatives',
      create_from_instant_reply_alternatives: '/users/{0}/scheduled_calls/create_from_instant_reply_alternatives'
    },

    initialize: function(options) {
      this.options = options || {};
      this.time_zone_selected = false;
      this.scheduled_call_id = this.$('#scheduled_call_id').val();
      this.loader = (options.submitButton || this.$('.btnScheduleCallReply')).buttonLoader();
      this.jobId = options.jobId;
      this.instant = options.instant;
      this.attorneyId = options.attorneyId;
      this.removeAlternativeTimeOptions = options.removeAlternativeTimeOptions;
      this.defaultMessage = options.defaultMessage;
      this.isModal = !!options.isModal;

      this.setCurrentMode();

      this.selectTimeZone();

      this.$('[data-toggle="tooltip"]').tooltip();

      this.times_available_view = new UC.partials.ScheduledCallsTimesAvailable({
        el: this.$el.find('#times-available')
      });

      if (this.removeAlternativeTimeOptions) {
        this.$el.find('.js-alternative-options').remove();
      }
    },

    selectTimeZone: function() {
      if (this.time_zone_selected === false) {
        // only try selecting the user's time zone once
        this.$el.find('#TimeZone').selectTimeZone();
        this.time_zone_selected = true;
      }
    },

    saveState: function() {
      this.state = {
        message: this.$el.find('#message').val(),
        phone: this.$el.find('#phone').val()
      };
    },

    loadState: function() {
      if (this.state) {
        this.$el.find('#message').val(this.state.message);
        this.$el.find('#phone').val(this.state.phone);
      }
    },

    changeTimeZone: function(e) {
      e.preventDefault();

      this.saveState();

      var data = {
        time_zone: this.$('#TimeZone').val(),
        job_id: this.jobId,
        is_modal: this.isModal,
        paid_consultation_package: true
      };

      UC.net.get(this.routes.instant.format(this.attorneyId), data, function(res) {
        // not my finest hour
        var $newForm = $(res.html);

        if (this.removeAlternativeTimeOptions) {
          $newForm.find('.js-alternative-options').remove();
        }

        if (this.defaultMessage) {
          $newForm.find('.js-add-default-msg').append(this.defaultMessage)
        }

        this.$el[0].innerHTML = $($newForm[0].outerHTML).find('#scheduled_calls_form_reply').html();

        this.loadState();
        this.setCurrentMode();
        this.$('[data-toggle="tooltip"]').tooltip();

        // reconnect event listen
        this.times_available_view = new UC.partials.ScheduledCallsTimesAvailable({
          el: this.$el.find('#times-available')
        });
      }.bind(this));
    },

    validate: function() {
      var errors = [];
      var $time_zone = this.$el.find('#TimeZone');
      var $scheduled_time = this.$el.find('#ScheduledDateTime');
      var $message = this.$el.find('#message');
      var $phone = this.$el.find('#phone');

      UC.utils.clearValidationErrors('form-group');

      if (!$time_zone.val()) {
        errors.push({
          element: $time_zone,
          text: 'Please select your time zone',
          className: 'form-group'
        });
      }

      if($phone.length && $phone.val().isBlank()) {
        errors.push({
          element: $phone,
          text: 'Please enter your preferred phone number',
          className: 'form-group'
        });
      }

      switch(this.mode) {
        case 'schedule':
          if (!$scheduled_time.val()) {
            errors.push({
              element: $scheduled_time,
              text: 'Select a date and time you are available',
              className: 'form-group'
            });
          }
          break;
        case 'provide_alternatives':
          errors = errors.concat(this.times_available_view.errors());
          break;
      }

      if (!$message.val()) {
        errors.push({
          element: $message,
          text: 'Please include a message',
          className: 'form-group'
        });
      }

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors);
        return false;
      } else {
        return true;
      }
    },

    serializeForm: function() {
      var data = {
        time_zone: this.$el.find('#TimeZone').val(),
        message: this.$el.find('#message').val(),
        phone: this.$el.find('#phone').val()
      };

      switch(this.mode) {
        case 'schedule':
          data.ScheduledDateTime = this.$el.find('#ScheduledDateTime').val();
          break;
        case 'provide_alternatives':
          data.availability = this.times_available_view.serialize();
          break;
      }

      return data;
    },

    reply: function(e) {
      e.preventDefault();
      if (!this.validate()) return;

      this.loader.showLoader();
      var data = this.serializeForm();

      if (this.jobId) data.job_id = this.jobId;

      UC.net.post(this.replyPath(), data)
      .done(function(result) {
        UC.utils.showNotification(result.message, UC.constants.results.success);

        if (typeof this.options.onReply === 'function') {
          this.options.onReply(result);
        }

        this.loader.hideLoader();
      }.bind(this))
      .fail(function() {
        this.loader.hideLoader();
      }.bind(this));

    },

    replyPath: function() {
      // If we're not providing alternatives, we should use the form's path
      if (this.mode !== 'provide_alternatives') return this.$el.attr('action');

      if (this.instant) {
        return this.routes.create_from_instant_reply_alternatives.format(this.attorneyId);
      } else {
        return this.routes.scheduled_calls_provide_alternatives.format(this.scheduled_call_id);
      }
    },

    setCurrentMode: function(e) {
      this.mode = this.currentMode();
      this.$('#mode').val(this.mode);

      if (this.mode === 'provide_alternatives') {
        this.$('.btnScheduleCallReply').text('Provide Alternative Times');
      } else {
        this.$('.btnScheduleCallReply').text('Schedule The Call');
      }
    },

    notAvailable: function(e) {
      e.preventDefault();
      this.$el.find('.nav-tabs li.active').next('li').find('a').click();
    },

    currentMode: function() {
      return this.$('#schedule-call-reply-tabs li.active a[data-toggle="tab"]').data('mode');
    }

  });
}();
