UC.partials.ContentPagesShowContentHub = Backbone.View.extend({
  initialize: function () {
    this.$tiles = this.$el.find('.ch-tile');
    this.$attorneyCarouselContainer = this.$el.find("#attorney-carousel-container");

    this.resizeTiles();

    if (this.$attorneyCarouselContainer.length > 0) {
      this.initAttorneyCarousel();
      this.standardizeAttorneyCardHeight();
    }
  },

  resizeTiles: function () {
    var maxHeight = 0;
    this.$tiles.each(function(){
      if ($(this).height() > maxHeight) maxHeight = $(this).height();
    });
    this.$tiles.height(maxHeight);
  },

  initAttorneyCarousel: function () {
    if (typeof $.fn.slick !== 'function') {
      console.log('slick is not initialized yet.');
      return;
    }
    this.$attorneyCarouselContainer.slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      // autoplay: true,
      nextArrow: "<i class='fontello icon-angle-right atty-carousel-next'></i>",
      prevArrow: "<i class='fontello icon-angle-left atty-carousel-prev'></i>",
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: UC.constants.minSmallScreen,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  },

  standardizeAttorneyCardHeight: function () {
    var cards = this.$el.find(".attorney-carousel-card");
    var heights = cards.map(function () {
      return $(this).height()
    });
    var maxHeight = Math.max.apply(null, heights);

    cards.each(function () { $(this).height(maxHeight) });
  }
});
