!function() {

  var defaults = {
    allowOverlayClose: true,
    allowEscClose: true,
    showLoader: false
  };
  var froogaloop_loaded = false;

  function Modal($el, options) {
    this.$el = $el;
    this.options = $.extend({}, defaults, options);
    this._open = false;
    this.$body = $('body');
    this.$wrapper = $('<div class="jqmWrapper">');
    this.$overlay = $('<div class="jqmOverlay">');
  }

  Modal.prototype = {

    constructor: Modal,

    show: function() {

      if (this._open == true) {
        // if the modal is already open don't do anything
        return false;
      } else {
        this._open = true;
      }

      if (this.options.showLoader) {
        this.$overlay.addClass('.jqmLoading');
      }

      this.$body.append(this.$overlay);

      // move the modal inside the wrapper
      this.$el.appendTo(this.$wrapper);

      this.checkScrollbar();
      this.setScrollbar();

      // add the wrapper, prevent scrolling
      this.$body
        .addClass('modal-open')
        .append(this.$wrapper);

      this.bindCloseEvents();

      // jquery draggable
      this.$el.on('drag', this.adjust.bind(this));

      $(window).resize(function() {
        this.adjust();
      }.bind(this)).resize();
    },

    hide: function() {
      // move modal back to body and hide
      this.$el.appendTo('body');

      // remove wrapper
      this.$wrapper.remove();

      // remove overlay
      this.$overlay.remove();

      // finally, let us scroll again
      this.$body.removeClass('modal-open');

      this.resetScrollbar();

      this.$el.trigger('jqmHidden');

      $(document).off('keydown.modal');

      this._open = false;
    },

    adjust: function() {
      this.$overlay
        .css('height', 0)
        .css('height', this.$wrapper[0].scrollHeight)
    },

    bindCloseEvents: function() {

      this.$el.find('.jqmClose').click(function(e) {
        e.preventDefault();
        this.hide();
      }.bind(this));

      if (this.options.allowOverlayClose) {
        this.$wrapper.click(function(e) {
          if (e.target.className === 'jqmWrapper') { this.hide(); }
        }.bind(this));
      }

      if (this.options.allowOverlayClose && this.options.allowEscClose) {
        $(document).on('keydown.modal', function(e) {
          if (e.which === 27) { this.hide(); }
        }.bind(this));
      }
    },

    bindVideoEvents: function($autoplay_video) {
      var that = this;
      var player = $f($autoplay_video[0]);

      this.$el.on('jqmHidden', function() {
        $autoplay_video.load(function() {
          player.api('pause').api("seekTo", "0");
        });
      });

      $autoplay_video.load(function() {
        if (that.froogalooping && that.froogalooping.state() !== 'resolved') {
          $.when(that.froogalooping).done(function() {
            player.api('play');
          });
        } else {
          player.api('play');
          $autoplay_video.load(function() {
            player.api('play');
          });
        }
      });
    },

    checkScrollbar: function() {
      var fullWindowWidth = window.innerWidth;
      if (!fullWindowWidth) { // workaround for missing window.innerWidth in IE8
        var documentElementRect = document.documentElement.getBoundingClientRect();
        fullWindowWidth = documentElementRect.right - Math.abs(documentElementRect.left);
      }
      this.bodyIsOverflowing = document.body.clientWidth < fullWindowWidth;
      this.scrollbarWidth = this.measureScrollbar();
    },

    measureScrollbar: function() {
      var scrollDiv = document.createElement('div');
      scrollDiv.className = 'modal-scrollbar-measure';
      this.$body.append(scrollDiv);
      var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      this.$body[0].removeChild(scrollDiv);
      return scrollbarWidth;
    },

    setScrollbar: function() {
      var bodyPad = parseInt((this.$body.css('padding-right') || 0), 10);
      this.originalBodyPad = document.body.style.paddingRight || '';
      if (this.bodyIsOverflowing) this.$body.css('padding-right', bodyPad + this.scrollbarWidth);
    },

    resetScrollbar: function() {
      this.$body.css('padding-right', this.originalBodyPad);
    }
  };

  function bindModal($el, options) {
    var modal = new Modal($el, options);
    $el.data('modal', modal);
    return modal;
  }

  $.fn.ucModal = function(options) {
    var args = Array.prototype.slice.apply(arguments);
    // can only find to a single element
    var $el = $(this).first();

    // method calls
    if (typeof args[0] === 'string') {

      var modal = $el.data('modal');

      if (!modal) {
        modal = bindModal($el);
      }

      if (typeof modal[args[0]] === 'function') {
        modal[args[0]].apply(modal, args.slice(1, args.length-1));
      }
    } else {
      if (!$el.data('modal')) {
        bindModal($el, args[0]);
      }
    }

    return this;
  };

  UC.utils.showModal = function(element, options) {
    if (element && element.length > 0) {
      $(element).ucModal(options).ucModal('show');
    }
  };

  UC.utils.hideModal = function(element){
    if (element && element.length > 0) {
      $(element).ucModal('hide');
    }
  };

  // deprecated
  UC.utils.positionModal = function() {};
}();
