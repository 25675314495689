!function() {

  UC.partials.JobsModalJobIntro = Backbone.View.extend({

    events: {
      'click #job-intro-done': 'done',
      'hidden.bs.modal': 'hidden'
    },

    initialize: function() {
      UC.utils.recordKissEvent('Show Modal: #modalJobIntro', {
        title: this.$('.modal-title').text()
      });

      return this;
    },

    render: function() {
      this.$el.modal();
    },

    done: function() {
      UC.utils.recordKissEvent('Done Clicked: #modalJobIntro', {
        title: this.$('.modal-title').text()
      });
    },

    hidden: function() {
      UC.utils.recordKissEvent('Hide Modal: #modalJobIntro', {
        title: this.$('.modal-title').text()
      });
    }
  });

}();
