UC.partials.UserInfo = Backbone.View.extend({
  routes: {
    emailExists: '/account/email_exists'
  },

  initialize: function(options) {
    this.$el.find('select').select2({ placeholder: 'Select...' });
    this.btnLoader = options.btnLoader;
  },

  validate: function(dfd) {
    this.$el.find('.has-error').removeClass('has-error');

    var data = this.$el.serializeJSON();
    var errors = [];

    if (!data.user_info_stage.FirstName) {
      var txtFirstName = this.$el.find('#user_info_stage_FirstName');

      errors.push({element: txtFirstName, text: 'Please provide your first name'});
    };

    if (!data.user_info_stage.LastName) {
      var txtLastName = this.$el.find('#user_info_stage_LastName');

      errors.push({element: txtLastName, text: 'Please provide your last name'});
    };

    if (!data.user_info_stage.CompanyName) {
      var txtCompanyName = this.$el.find('#user_info_stage_CompanyName');

      errors.push({element: txtCompanyName, text: 'Please provide your company name'});
    };

    if (!data.user_info_stage.NumberOfEmployees) {
      var txtNumberOfEmployees = this.$el.find('#user_info_stage_NumberOfEmployees');

      errors.push({element: txtNumberOfEmployees, text: 'Please provide number of employees'});
    };

    var txtEmail = this.$el.find('#user_info_stage_Email');

    if (!data.user_info_stage.Email || !UC.utils.validateEmail(data.user_info_stage.Email)) {
      errors.push({element: txtEmail, text: 'Please provide a valid email'});
    };

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, { className: 'js-inputContainer' });
      dfd.reject();
    } else {
      this.checkEmailAvailability(data, dfd, txtEmail);
    }
  },

  checkEmailAvailability: function(data, dfd, element, callback) {
    UC.net.post(this.routes.emailExists, {email: data.user_info_stage.Email}, function(res) {
      if (res.exists) {

        this.renderLoginAlert(element);
        this.btnLoader.hideLoader();
        dfd.reject();
      } else {
        dfd.resolve(data);
      }
    }.bind(this)).fail(function() {
      this.btnLoader.hideLoader();
      dfd.reject();
    });
  },

  renderLoginAlert: function(element) {
    this.$el.find('.js-login-link').remove();

    if (!element.parent().find('.alert').length) {
      element.parent().append(
          '<div class="alert alert-info alert-dismissible marg-t-10" role="alert">' +
            '<i class="fontello icon-info-blurb" />' +
            'An account already exists for this email, ' +
            '<a href="/account/logon" class="js-lnkNewJobLogin">' +
            'please log in</a>' +
          '</div>'
      )
    }
  },

  logonCallback: function(err) {
    if (err) {
      this.logonForm.hideLogonLoaders();
      this.logonError(err);
    } else {
      this.updateAndLoadNextStage({logon: true, nextStage: true, user_info_stage: {} });
    }
  }
});
