UC.modals.ProposalTemplatesFormModal = Backbone.View.extend({
  id: 'modalFormProposalTemplates',

  className: 'new-template modal fade',

  events: {
    'click #btnSaveProposalTemplate' : 'save',
    'click .js-placeholder-link' : 'insertNamePlaceholder'
  },

  elements: {
    'txtTemplateName': null,
    'selectLegalGroup': null,
    'txtTemplateText': null,
    'btnSaveProposalTemplate': null
  },

  initialize: function(options) {
    this.components = {};
    this.parent_view = options.parent_view;
    this.base_path = '/proposal_templates'
    this.onSave = options.onSave;
    this.select = options.select;
  },

  render: function(options) {
    if (options && options.templateId) {
      this.templateId = options.templateId;
    } else {
      delete this.templateId;
    }

    var path = this.base_path;
    path += this.templateId ? '/{0}/edit'.format(this.templateId) : '/new';

    UC.net.get(path, {}, function(modal_html) {
      this.$el.html(modal_html);
      this.$el.modal();
      UC.utils.setSelectors(this.elements);
      this.components.btnSaveProposalTemplate =
        this.elements.btnSaveProposalTemplate.buttonLoader();
      var selectLegalGroup = this.$('#selectLegalGroup')
      selectLegalGroup.select2({width: '100%'});
    }.bind(this));
  },

  save: function(e) {
    if (!this.validateTemplate()) return false;

    this.components.btnSaveProposalTemplate.showLoader();
    var path = this.base_path,
        data = {
          template: this.templateData,
          select: this.select
        };

    if (this.templateId) {
      path += '/{0}'.format(this.templateId);
      UC.net.put(path, data, function(res) {
        this.onSave(this.templateId);
        this.$el.modal('hide');
      }.bind(this)).complete(function(res){
        this.components.btnSaveProposalTemplate.hideLoader();
      }.bind(this)).fail(function(res){
        UC.closeTheLoop.handleFailure(res, true);
      });

    } else {
      UC.net.post(path, data, function(res) {
        this.onSave();
        this.$el.modal('hide');
      }.bind(this)).complete(function(res){
        this.components.btnSaveProposalTemplate.hideLoader();
      }.bind(this)).fail(function(res){
        UC.closeTheLoop.handleFailure(res, true);
      });

    }
  },

  validateTemplate: function() {
    // NOTE: needed to resolve race condition when the page is loaded, selectors are not yet set.
    UC.utils.setSelectors(this.elements);

    UC.utils.clearValidationErrors('form-group')
    var errors = [];

    this.templateData = {
      Name: this.elements.txtTemplateName.val().trim(),
      LegalGroupId: this.elements.selectLegalGroup.val(),
      Text: this.elements.txtTemplateText.val().trim()
    };

    if (this.templateData.Name === '') {
      errors.push({element: this.elements.txtTemplateName, text: 'Template name is required'})
    }

    if (!this.templateData.LegalGroupId || this.templateData.LegalGroupId === '##') {
      errors.push({element: this.elements.selectLegalGroup, text: 'Template category is required'})
    }

    if (this.templateData.Text === '') {
      errors.push({element: this.elements.txtTemplateText, text: 'Template text is required'})
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, { className: 'form-group'});
      return false
    } else {
      return true;
    }
  },

  insertNamePlaceholder: function(e) {
    e.preventDefault();
    var txtTemplateText = $('#txtTemplateText');
    var newText = "{client_first_name}" + txtTemplateText.val();
    txtTemplateText.val(newText);
  }
});
