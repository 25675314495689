!function() {

  UC.views.JobsMatchAttorneys = Backbone.View.extend({

    initialize: function() {
      this.$title = this.$('#match-attorneys-title');
      this.$slides = this.$('.match-attorneys-slide');

      this.prepareAnimation();
    },

    prepareAnimation: function() {

      // turn into real array
      var slides = $.map(this.$slides.toArray(), function(slide) {
        // turn raw dom element back into jQuery object
        return $(slide);
      });

      var first_slide = slides[0];

      if (first_slide.get(0).complete) {
        this.startAnimation(slides);
      } else {
        first_slide.on('load', function() {
          this.startAnimation(slides);
        }.bind(this));
      }
    },

    startAnimation: function(slides) {

      // show the first slide
      slides[0].removeClass('hide');

      // need a zero timeout here to function properly
      setTimeout(function() {
        this.animateSlides(slides, function() {
          // finished
          var route = '/job/find_intro/{0}'.format(UC.constants.jobId);

          UC.net.get(route, function(data) { window.location.href = data.nextUrl })
        });
      }.bind(this), 0);
    },

    animateSlides: function(slides, callback) {
      var $slide = slides.shift();

      if ($slide && $slide.length > 0) {
        var title = $slide.attr('alt');

        // update page title
        if (title) {
          this.$title.text($slide.attr('alt'));
        }

        // hide all slides
        this.$slides.addClass('hide');

        // show next slide
        $slide.removeClass('hide');

        setTimeout(function() {
          this.animateSlides(slides, callback);
        }.bind(this), $slide.data('timeout'));
      } else {
        callback();
      }
    }
  });

}();
