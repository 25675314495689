import { renderTwoFactorRequestModal } from '../multi_factor_auth/functions'

UC.partials.ContentPagesShowDocument = Backbone.View.extend({

  accountTypeLabels: {
    0: 'attorney',
    1: 'client'
  },

  components: {
    sendMsgLoader: $('#sendMsg').buttonLoader()
  },

  data: {
    account: {},
    loggedIn: UC.constants.loggedIn || false
  },

  events: {
    'click #btnRegister': 'onRegisterClick',
    'click #close-share-doc-modal': 'closeShareDocModal',
    'click .doc-download__back-btn': 'onBackBtnClick',
    'click .doc-download__next-btn': 'onNextBtnClick',
    'click .downloadDoc': 'onDownloadClick',
    'click #sendMsg': 'onShareClick',
    'click .shareDoc': 'showShareModal'
  },

  initialize: function () {
    if (!UC.constants.internalDoc) {
      this.$('#docPreview').click(this.downloadBtnAnimate.bind(this));
    }

    this.downloadDocIfHashPresent();
    this.registerScrollEvent();
    this.renderSelectFields();
    this.startKissTracking();
    this.signupForm = new UC.partials.UsersForm({
      beforeRegister: this.setDocumentDownloadData.bind(this),
      oauthLoginCallback: this.respondToUpdatedUser.bind(this),
      onRegister: this.respondToCreateUser.bind(this),
      onRegistrationFailure: this.onBackBtnClick.bind(this)
    });

    if ($('.js-downloadDocument').length) {
      new UC.partials.PayAndDownloadDocument({
        el: this.$el,
        clickTrackingProps: this.clickTrackingProps
      })
    }
  },

  accountTypeLabel: function () {
    return this.accountTypeLabels[this.data.account.Type];
  },

  attorneyValid: function () {
    this.$(this.registrationModalId() + ' div').removeClass('has-error');
    var barStatesInvalid = !this.$('#selectBarStates').val(),
        legalGroupsInvalid = !this.$('#selectLegalGroups').val(),
        errors = [];

    if (barStatesInvalid) {
      errors.push({
        element: this.$('#selectBarStates'),
        text: 'Please specify a state in which you are certified'
      });
    }
    if (legalGroupsInvalid) {
      errors.push({
        element: this.$('#selectLegalGroups'),
        text: 'Please specify a legal practice area'
      });
    }

    UC.utils.showValidationErrors(errors, { className: 'input-container' });
    return !barStatesInvalid && !legalGroupsInvalid;
  },

  clickTrackingProps: function (location) {
    return {
      'author_attorney_id': this.$('#AuthorId').val(),
      'document_id': this.$('#DocumentId').val(),
      'loc': location || 'document_download_modal',
      'logged-in': (UC.user ? true : false)
    };
  },

  clientValid: function () {
    this.$(this.registrationModalId() + ' div').removeClass('has-error');
    var companyNameInvalid = !this.$('#txtCompanyName').val(),
        companySizeRangeInvalid = !this.$('#selectCompanySizeRange').val(),
        industryInvalid = !this.$('#selectIndustry').val(),
        errors = [];

    if (companyNameInvalid) {
      errors.push({
        element: this.$('#txtCompanyName'), text: 'Please specify a company name'
      });
    }
    if (companySizeRangeInvalid) {
      errors.push({
        element: this.$('#selectCompanySizeRange'), text: 'Please specify an employee count'
      });
    }
    if (industryInvalid) {
      errors.push({
        element: this.$('#selectIndustry'), text: 'Please specify a company industry'
      });
    }

    UC.utils.showValidationErrors(errors, { className: 'input-container' });
    return !companyNameInvalid && !companySizeRangeInvalid && !industryInvalid;
  },

  closeShareDocModal: function (e) {
    e.preventDefault();
    this.$('#shareModal').modal('hide');
  },

  downloadBtnAnimate: function (e) {
    e.preventDefault();
    this.$('.downloadDoc').addClass('wiggle');

    setTimeout(function (){
      this.$('.downloadDoc').removeClass('wiggle');
    }.bind(this), 600);
  },

  downloadDocIfHashPresent: function () {
    if (window.location.hash) {
      // Puts hash in variable, and removes the # character
      var hash = window.location.hash.substring(1);
      if (hash === 'download') this.onDownloadClick();
    }
  },

  downloadDocument: function() {
    var iframeString =
      '<iframe src="/free-legal-documents/document/{0}/download" ' +
      'class="component hidden"></iframe>';
    $('body').append(iframeString.format($('#DocumentId').val()));
  },

  hideAllDownloadPanels: function () {
    this.$('.doc-modal').addClass('hidden');
  },

  hideError: function () {
    this.$('#downloadErrorMessage').text('');
    UC.utils.hidePanel(this.$('#downloadError'));
  },

  hideShareValidationErrors: function () {
    this.$('#SenderName').closest('.row').removeClass('has-error');
    this.$('#SenderEmail').closest('.row').removeClass('has-error');
    this.$('#Emails').closest('.row').removeClass('has-error');
    this.$('#EmailMessage').closest('.row').removeClass('has-error');
  },

  onBackBtnClick: function () {
    $(this.registrationModalId()).addClass('hidden');
    this.$('#registrationStepOne').removeClass('hidden');
    UC.utils.recordKissEvent('click_back-btn', this.clickTrackingProps());
  },

  onDownloadClick: function (e) {
    var eventProps = this.clickTrackingProps('document_download_page');
    UC.utils.recordKissEvent('click_download-for-free', eventProps);
    this.showDownloadModal();
  },

  onNextBtnClick: function () {
    UC.utils.clearValidationErrors('input-container');
    var onValidationSuccess = this.showSecondRegistrationStep.bind(this);
    this.signupForm.validate(null, true, onValidationSuccess, true);
    UC.utils.recordKissEvent('click_next-btn', this.clickTrackingProps());
  },

  onRegisterClick: function () {
    UC.utils.recordKissEvent('click_email-me-btn', this.clickTrackingProps());
    var validator = '{0}Valid'.format(this.accountTypeLabel());
    if (typeof this[validator] === 'function') {
      var isValid = this[validator]();
      if (isValid) this.signupForm.validate();
    }
  },

  onShareClick: function (e) {
    e.preventDefault();

    if (this.validateShareForm()) {
      var message = {
        SenderEmail: this.$('#SenderEmail').val(),
        SenderName: this.$('#SenderName').val(),
        Emails: this.$('#Emails').val(),
        EmailMessage: this.$('#EmailMessage').val(),
        CopyMyself: this.$('#CopyMyself').prop('checked')
      };
      this.shareDocument(message);
    }
  },

  recordKissRegister: function () {
    var properties = {
      source: 'form',
      document_id: this.$('#DocumentId').val(),
      author_attorney_id: this.$('#AuthorId').val(),
    };

    if (this.signupForm.omniauth) {
      properties.source = 'google';
    }

    UC.utils.recordKissEvent('doc-modal - register', properties);
  },

  registerScrollEvent: function () {
    var cta_btn =
      $('#docButtons').length > 0 ? $('#docButtons').offset().top : 0;

    $(window).scroll(function () {
      var scrollTop = $(document).scrollTop();

      if (scrollTop >= cta_btn) {
        $('#docButtons').addClass('active');
      } else {
        $('#docButtons').removeClass('active');
      }
    });
  },

  registrationModalId: function () {
    if (!this.data.account.Type) return;
    return '#{0}RegistrationStepTwo'.format(this.accountTypeLabel());
  },

  handleEventsForSelectUserType: function(selectUserType) {
    $(selectUserType).on('select2:select', function(e) {
      var properties = { clicked: e.params.data.element.dataset.eventName }

      UC.utils.recordKissEvent('document-user-registration', properties);
    })
  },

  renderSelectFields: function () {
    if (!$.fn.select2) {
      return;
    }
    var selectOptions = { placeholder: 'Select...', width: '100%' };
    this.$('#selectBarStates').select2(selectOptions);
    this.$('#selectCompanySizeRange').select2(selectOptions);
    this.$('#selectIndustry').select2(selectOptions);
    this.$('#selectLegalGroups').select2(selectOptions);

    var selectUserType = this.$('#selectUserType').select2(selectOptions);
    this.handleEventsForSelectUserType(selectUserType)
  },

  respondToCreateUser: function (result) {
    if (result.errors) {
      this.showError(result.errors.join("<br>"));
    } else {
      this.hideError();
      this.hideAllDownloadPanels();
      this.$('#download-doc-modal').removeClass('small');

      if (result.message === 'download') {
        this.data.loggedIn = true;
        this.showDownloadPanel();
      } else {
        UC.utils.showPanel(this.$('#modalPanelPostReg'));
        if (this.data.account.Type === 0) {
          UC.utils.showPanel(this.$('#panelAttorneyPostReg'));
        } else {
          UC.utils.showPanel(this.$('#panelClientPostReg'));
        }
      }

      this.recordKissRegister();
    }
  },

  respondToShare: function (status_result, result) {
    this.components.sendMsgLoader.hideLoader();

    if (status_result) {
      UC.utils.showNotification(status_result, UC.constants.results.failure);
    } else {
      this.$('#shareModal').modal('hide');
      this.$('#SenderEmail').val('');
      this.$('#SenderName').val('');
      this.$('#Emails').val('');
      this.$('#EmailMessage').val('');
      this.$('#CopyMyself').prop('checked', false);
      UC.utils.showNotification(result, UC.constants.results.success);
    }
  },

  respondToUpdatedUser: function(data) {
    if (data && data.two_factor_request_modal) {
      renderTwoFactorRequestModal(data.two_factor_request_modal, this.respondToUpdatedUser.bind(this))
      return;
    }
    this.data.loggedIn = true;
    this.hideAllDownloadPanels();
    this.showDownloadPanel();
    this.recordKissRegister();
  },

  setDocumentDownloadData: function (data) {
    data.user.CompanyIndustry = this.$('#selectIndustry').val();
    data.user.CompanyName = this.$('#txtCompanyName').val();
    data.user.CompanySizeRange = this.$('#selectCompanySizeRange').val();
    data.document_id = this.$('#DocumentId').val();
    data.user.StatedBarCertifiedStates = this.$('#selectBarStates').val();
    data.user.TopLegalGroupIds = this.$('#selectLegalGroups').val();
    var $selectUserType = this.$('#selectUserType');
    data.user.Type = $selectUserType.val();
    data.user.InHouseCounsel = $selectUserType.find('option:selected').data('event-name') === 'in-house-counsel';
    data.user.WantsUpCounselClients =
      this.$('.attorney-source__radio.yes').is(':checked') && data.user.Type == 0;
  },

  shareDocument: function (message) {
    message.DocumentId = this.$('#DocumentId').val();
    this.components.sendMsgLoader.showLoader();
    UC.net.post(
      '/free-legal-documents/document/share',
      { data: message },
      this.respondToShare.bind(this)
    );
  },

  showDownloadModal: function () {
    var downloadSection = this.$('#modalPanelDownload');
    if (!downloadSection.hasClass('hidden')) {
      this.$('#downloadModal').removeClass('small');
      this.downloadDocument();
    }

    this.$('#download-doc-modal').modal();
  },

  showDownloadPanel: function () {
    var docId = this.$('#DocumentId').val();
    UC.utils.showPanel($('#modalPanelDownload'));
    if ((UC.user && UC.user.type === 'attorney') ||
          (this.data.account.Type && this.data.account.Type === 0)) {
      UC.utils.showPanel($('#panelAttorneyDownload'));
    } else {
      UC.utils.showPanel($('#panelClientDownload'));
    }
    this.downloadDocument();
  },

  showSecondRegistrationStep: function () {
    this.signupForm.loader.hideLoader();
    var accountType = this.data.account.Type = this.$('#selectUserType').val();
    var errors = [];
    if (!accountType) {
      errors.push({
        element: this.$('#selectUserType'),
        text: 'Please specify whether or not you are a lawyer.'
      });
      UC.utils.showValidationErrors(errors, { className: 'input-container' });
    } else {
      UC.utils.clearValidationErrors('input-container');
      this.$('#registrationStepOne').addClass('hidden');
      $(this.registrationModalId()).removeClass('hidden');
    }
  },

  showError: function (message) {
    $('#downloadErrorMessage').html(message);
    UC.utils.showPanel($('#downloadError'));
  },

  showShareModal: function (e) {
    e.preventDefault();
    var position = $(e.target).hasClass('top') ? 'top' : 'bottom';
    var properties = {
      'logged-in': (UC.user ? true : false),
      'position': position,
      'document_id' : $('#DocumentId').val(),
      'author_attorney_id': $('#AuthorId').val(),
    };
    UC.utils.recordKissEvent('doc - share click', properties);
    this.$('#shareModal').modal();
  },

  showValidationError: function (element) {
    var id = element.attr('id');
    element.closest('.row').addClass('has-error');
  },

  startKissTracking: function () {
    var properties = {
      document_id : $('#DocumentId').val(),
      author_attorney_id: $('#AuthorId').val(),
    };
    UC.utils.recordKissEvent('viewed a document', properties);
    UC.utils.recordKissClickEvent(
      '.reg-cta-paj',
      'clicked paj cta button in document registration modal'
    );
    UC.utils.recordKissClickEvent(
      '.reg-cta-learn-more',
      'clicked learn more cta button in document registration modal'
    );
    UC.utils.recordKissClickEvent(
      '.download-cta-paj',
      'clicked paj cta button in document download modal'
    );
    UC.utils.recordKissClickEvent(
      '.download-cta-learn-more',
      'clicked learn more cta button in document download modal'
    );
  },

  validateEmails: function (emails) {
    var all_valid = true;
    var string_array = emails.split(',').map(function (email) {
      return email.trim();
    });

    if (string_array.length > 10) {
      all_valid = false;
    } else {
      string_array.forEach( function (email) {
        if (!UC.utils.validateEmail(email)) {
          all_valid = false;
        }
      });
    }
    return all_valid;
  },

  validateShareForm: function () {
    this.hideShareValidationErrors();
    var emailsInvalid  = this.$('#Emails').val() == '' ||
          !this.validateEmails(this.$('#Emails').val()),
        messageInvalid = this.$('#EmailMessage').val() == '',
        nameInvalid = this.$('#SenderName').val() == '',
        senderInvalid = this.$('#SenderEmail').val() == '' ||
          !UC.utils.validateEmail(this.$('#SenderEmail').val());

    if (nameInvalid) this.showValidationError(this.$('#SenderName'));
    if (senderInvalid) this.showValidationError(this.$('#SenderEmail'));
    if (emailsInvalid) this.showValidationError(this.$('#Emails'));
    if (messageInvalid) this.showValidationError(this.$('#EmailMessage'));

    return !emailsInvalid && !messageInvalid && !nameInvalid && !senderInvalid;
  }

});
