UC.views.DocumentDownloadOffer = Backbone.View.extend({
  className: 'modal',

  offerTemplate: UC.views.resolveHandlebarsTemplate("public/document_download_offer"),

  events: {
    'click .js-acceptOffer': 'onAcceptOffer'
  },

  initialize: function(opt) {
    this.onAccept = opt.onAccept

    this.$el.html(this.offerTemplate()).modal();
  },

  onAcceptOffer: function(e) {
    this.onAccept()
  }
});
