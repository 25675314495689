var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n    <div class=\"modal-header\">\n      <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>\n      <h4 class=\"modal-title\">Add Note</h4>\n    </div>\n\n    <div class=\"modal-body\">\n      <form action=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"createUrl") || (depth0 != null ? lookupProperty(depth0,"createUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"createUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":33}}}) : helper)))
    + "\" method=\"post\" id=\"admin-log-form\">\n        <div class=\"form-group\">\n          <textarea name=\"Entry\" class=\"form-control\" style=\"width:100%;\"></textarea>\n          <div class=\"error-message\"></div>\n        </div>\n\n        <div class=\"text-right\">\n          <button type=\"submit\" class=\"btn btn-primary btn-md marg-b-15\" id=\"submit-admin-log-entry\">\n            Add\n          </button>\n        </div>\n      </form>\n    </div>\n  </div>\n</div>\n";
},"useData":true});