var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <a href=\"#\" class=\"show-flipped delete-ts-line\">\n    <i class=\"fontello icon-cancel\"></i>\n  </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"selected") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":12,"column":9}}})) != null ? stack1 : "")
    + "    <i class=\"fontello icon-ok\"></i>\n  </a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "    <a href=\"#\" class=\"show-flipped select-ts-line checked\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <a href=\"#\" class=\"show-flipped select-ts-line\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"invoiceUrl") || (depth0 != null ? lookupProperty(depth0,"invoiceUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"invoiceUrl","hash":{},"data":data,"loc":{"start":{"line":19,"column":13},"end":{"line":19,"column":27}}}) : helper)))
    + "\">\n      <i class=\"fontello icon-exported text-grey-45\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"Invoice #"
    + alias4(((helper = (helper = lookupProperty(helpers,"invoiceNumber") || (depth0 != null ? lookupProperty(depth0,"invoiceNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"invoiceNumber","hash":{},"data":data,"loc":{"start":{"line":20,"column":112},"end":{"line":20,"column":129}}}) : helper)))
    + "\"></i>\n    </a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"allowedToSelectLine") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":35,"column":11}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"selected") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":24,"column":6},"end":{"line":28,"column":13}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"fancy-check select-ts-line checked\"></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"fancy-check select-ts-line\"></div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"notInvoiceable") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":35,"column":4}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "      <i class=\"fontello icon-info-blurb text-grey-30\" data-toggle=\"tooltip\" data-placement=\"top\"\n         title=\"Please select a different job. New invoices can only be created for jobs in progress.\"></i>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"previouslySelectedLineJobConflict") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":35,"column":4}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    return "      <i class=\"fontello icon-info-blurb text-grey-30\" data-toggle=\"tooltip\" data-placement=\"top\"\n         title=\"You may only invoice multiple entries that are all for the same job or a direct invoice\"></i>\n    ";
},"22":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tc ts-date-col read-only\">\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tc ts-date-col\">\n";
},"26":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"formattedDate") || (depth0 != null ? lookupProperty(depth0,"formattedDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"formattedDate","hash":{},"data":data,"loc":{"start":{"line":49,"column":6},"end":{"line":49,"column":23}}}) : helper)))
    + "\n";
},"28":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <input class=\"ts-line-item-field datepicker\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"formattedDate") || (depth0 != null ? lookupProperty(depth0,"formattedDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"formattedDate","hash":{},"data":data,"loc":{"start":{"line":51,"column":58},"end":{"line":51,"column":75}}}) : helper)))
    + "\" data-field-name=\"Date\" data-field-type=\"date\">\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":58,"column":2},"end":{"line":62,"column":9}}})) != null ? stack1 : "")
    + "\n    <div class=\"attribute-title\">Job</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":66,"column":4},"end":{"line":70,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"tc ts-job-col read-only\">\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"tc pad-0\">\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"attribute-detail ts-line-job-dropdown-container\"></div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"attribute-detail pad-0 ts-line-job-dropdown-container\"></div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tc ts-type-col read-only\">\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tc ts-type-col\">\n";
},"43":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"attribute-detail ts-line-type-dropdown-container\"></div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"attribute-detail\">\n      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"displayType") || (depth0 != null ? lookupProperty(depth0,"displayType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"displayType","hash":{},"data":data,"loc":{"start":{"line":86,"column":6},"end":{"line":86,"column":21}}}) : helper)))
    + "\n    </div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tc ts-description-col read-only\">\n";
},"49":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tc ts-description-col\">\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"Description") : stack1), depth0))
    + "\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <input class=\"ts-line-item-field\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"Description") : stack1), depth0))
    + "\" data-field-name=\"Description\" data-field-type=\"string\">\n";
},"55":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tc ts-hours-col read-only\">\n";
},"57":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tc ts-hours-col\">\n";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"Hours") : stack1), depth0))
    + "\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <input class=\"ts-line-item-field ts-float-field text-right\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"Hours") : stack1), depth0))
    + "\" data-field-name=\"Hours\" data-field-type=\"float\">\n";
},"63":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tc ts-rate-col read-only\">\n";
},"65":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tc ts-rate-col\">\n";
},"67":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"formattedRate") || (depth0 != null ? lookupProperty(depth0,"formattedRate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"formattedRate","hash":{},"data":data,"loc":{"start":{"line":136,"column":6},"end":{"line":136,"column":23}}}) : helper)))
    + "\n";
},"69":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <input class=\"ts-line-item-field ts-line-item-rate text-right\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"formattedRate") || (depth0 != null ? lookupProperty(depth0,"formattedRate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"formattedRate","hash":{},"data":data,"loc":{"start":{"line":138,"column":76},"end":{"line":138,"column":93}}}) : helper)))
    + "\" data-field-name=\"Rate\" data-field-type=\"currency\">\n";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"tc text-center ts-delete-col hide-flipped\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showDeleteButton") : depth0),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":4},"end":{"line":157,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"72":function(container,depth0,helpers,partials,data) {
    return "      <a href=\"#\" class=\"text-grey-15 fs-15 delete-ts-line\" tabindex=\"-1\">\n        <i class=\"fontello icon-cancel-circled\"></i>\n      </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDeleteButton") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowedToSelectLine") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":15,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"tc text-center hide-flipped ts-select-inv-lnk-col\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInvoicedIcon") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":36,"column":9}}})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":39,"column":0},"end":{"line":43,"column":7}}})) != null ? stack1 : "")
    + "\n  <div class=\"attribute-title\">Date</div>\n\n  <div class=\"attribute-detail\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":48,"column":4},"end":{"line":52,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showJobColumn") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":0},"end":{"line":72,"column":7}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":75,"column":0},"end":{"line":79,"column":7}}})) != null ? stack1 : "")
    + "  <div class=\"attribute-title\">Type</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTypeSelect") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(45, data, 0),"data":data,"loc":{"start":{"line":82,"column":2},"end":{"line":88,"column":9}}})) != null ? stack1 : "")
    + "\n</div>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.program(49, data, 0),"data":data,"loc":{"start":{"line":93,"column":0},"end":{"line":97,"column":7}}})) != null ? stack1 : "")
    + "  <div class=\"attribute-title\">Description</div>\n\n  <div class=\"attribute-detail\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":101,"column":4},"end":{"line":105,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.program(57, data, 0),"data":data,"loc":{"start":{"line":110,"column":0},"end":{"line":114,"column":7}}})) != null ? stack1 : "")
    + "  <div class=\"attribute-title\">Hours</div>\n\n  <div class=\"attribute-detail text-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.program(61, data, 0),"data":data,"loc":{"start":{"line":118,"column":4},"end":{"line":122,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.program(65, data, 0),"data":data,"loc":{"start":{"line":126,"column":0},"end":{"line":130,"column":7}}})) != null ? stack1 : "")
    + "\n  <div class=\"attribute-title\">Rate</div>\n\n  <div class=\"attribute-detail text-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(67, data, 0),"inverse":container.program(69, data, 0),"data":data,"loc":{"start":{"line":135,"column":4},"end":{"line":139,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n\n<div class=\"tc ts-line-item-subtotal read-only\">\n  <div class=\"attribute-title\">Subtotal</div>\n\n  <div class=\"attribute-detail text-right\">\n    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"formattedSubtotal") || (depth0 != null ? lookupProperty(depth0,"formattedSubtotal") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"formattedSubtotal","hash":{},"data":data,"loc":{"start":{"line":147,"column":4},"end":{"line":147,"column":25}}}) : helper)))
    + "\n  </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDeleteColumn") : depth0),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":0},"end":{"line":159,"column":7}}})) != null ? stack1 : "");
},"useData":true});