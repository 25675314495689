UC.partials.SessionSudoForm = Backbone.View.extend({
  events: {
    'submit #session-sudo-form': 'onSubmit',
  },

  initialize: function() {
    setTimeout(function () {
      $('input#verification-password').focus();
    }, 500)
  },

  isActive: function() {
    return this.$el.find('#session-sudo-form').length > 0;
  },

  validatePassword: function(input) {
    UC.utils.clearValidationErrors('form-group');

    const errors = [];
    const passwordValue = input.val();

    if (!passwordValue) {
      errors.push({
        className: 'form-group',
        element: input,
        text: 'Password is required'
      });
    } else if (passwordValue.length < 3) {
      errors.push({
        className: 'form-group',
        element: input,
        text: 'Invalid password'
      });
    }

    UC.utils.showValidationErrors(errors);
    return errors.length === 0;
  },

  onSubmit: function(e) {
    e.preventDefault();
    const btnLoader = this.$el.find('#verify-password-button').buttonLoader();
    const $password = this.$el.find('input#verification-password');

    if (this.validatePassword($password)) {
      btnLoader.showLoader();

      UC.net.post(
          '/sessions/sudo',
          {
            password: $password.val()
          },
          function () {
            btnLoader.hideLoader();
            window.location.reload();
          }
      ).fail(function () {
        btnLoader.hideLoader();
        $password.val('');
        $password.focus();
      });
    } else {
      $password.focus();
    }
  },
});
