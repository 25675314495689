UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.ClientInvoicedLineItemsView = Backbone.View.extend({

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/client_invoiced_line_items"),

  tagName: 'div',

  id: 'invoiced-timesheet-line-items',

  initialize: function(opts) {
    this.clientId = opts.clientId;
    this.$parentElement = $(opts.parentElement);

    this.collection = new UC.timesheetComponent.TimesheetLineItems(null, {
      clientId: this.clientId,
      invoiced: true
    });

    this.timesheetTableView = new UC.timesheetComponent.TimesheetTableView({
      collection: this.collection,
      showJobColumn: true,
    });

    this.timesheetTotalsView = new UC.timesheetComponent.TimesheetTotalsView({
      collection: this.collection
    });

    this.timesheetDateFiltersView = new UC.timesheetComponent.TimesheetDateFiltersView({
      collection: this.collection
    });

    this.render();
  },

  render: function() {
    this.$el.html(this.template(this.collection));

    this.timesheetDateFiltersView.render();
    this.$('#timesheet-date-filters-container').html(this.timesheetDateFiltersView.$el);

    this.$('#timesheet-line-item-container').html(this.timesheetTableView.$el);

    this.timesheetTotalsView.render();
    this.$('#timesheet-totals-container').html(this.timesheetTotalsView.$el);

    this.$parentElement.html(this.$el);
  }

});
