UC.partials.BankAccountForm = Backbone.View.extend({

  events: {
    'click #aSecurityInfo' : 'showSecurityInfo'
  },

  initialize: function(options) {
    this.payout = !!options.payout;
    this.btnLoader = this.$el.find('#btnSaveBankAccount').buttonLoader();
    this.$el.find('select').select2();
    this.$el.submit(this.submit.bind(this));

    this.$firstName = this.$el.find('#txtFirstName');
    this.$lastName = this.$el.find('#txtLastName');
    this.$accountNumber = this.$el.find('#txtAccountNumber');
    this.$country = this.$el.find('#selectAccountCountry');
    this.$routingNumber = this.$el.find('#txtRoutingNumber');
    this.$accountType = this.$el.find('#selectBankAccountType');
    this.$agreeToTermsWidgets = this.$el.find('.js-agreeToTermsWidget');
    this.$agreeToTermsCheckboxes = this.$el.find('.js-agreeToTermsCheckbox');
    this.$agreeToTermsDates = this.$el.find('.js-agreeToTermsDate');
  },

  isAgreeToTermsChecked: function() {
    var isApplicable = this.$agreeToTermsWidgets.length;
    var isChecked = this.$agreeToTermsCheckboxes.filter(":checked").length;
    var isAgreedToPreviously = this.$agreeToTermsDates.length;

    if(isApplicable) {
      return isChecked || isAgreedToPreviously;
    } else {
      return true;
    }
  },

  submit: function(e) {
    e.preventDefault();
    UC.utils.clearValidationErrors('form-group');

    var errors = [];
    var firstName = this.$firstName.val();
    var lastName = this.$lastName.val();
    var accountNumber = this.$accountNumber.val();
    var country = this.$country.val();
    var routingNumber = UC.payments.formatRoutingNumber(this.$routingNumber.val(), country);
    var accountType = this.$accountType.val();
    var isAgreeToTermsChecked = !!this.isAgreeToTermsChecked();

    if (firstName == '') {
      errors.push({
        className: 'form-group', 
        element: this.$firstName, 
        text: 'Please provide first name'
      });
    }

    if (lastName == '') {
      errors.push({
        className: 'form-group', 
        element: this.$lastName, 
        text: 'Please provide last name'
      });
    }

    if (!UC.payments.validateRoutingNumber(routingNumber, country)) {
      errors.push({
        className: 'form-group', 
        element: this.$routingNumber, 
        text: 'Invalid routing number'
      });
    }

    if (!UC.payments.validateBankAccountNumber(accountNumber, country)) {
      errors.push({
        className: 'form-group', 
        element: this.$accountNumber, 
        text: 'Invalid account number'
      });
    }

    if (!isAgreeToTermsChecked) {
      errors.push({ element: this.$agreeToTermsCheckboxes });
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, {className: "form-group"});
      return;
    }

    this.btnLoader.showLoader();

    UC.payments.tokenizeBankAccount({
      account_holder_name: '{0} {1}'.format(firstName, lastName),
      account_number: accountNumber,
      routing_number: routingNumber,
      country: country,
      account_holder_type: accountType
    }, this.saveBankAccount.bind(this));
  },

  saveBankAccount: function(err, token) {
    if (err) {
      this.btnLoader.hideLoader();
      UC.utils.showNotification(err, UC.constants.results.failure);
    } else {
      var data = {
        token: token,
        type: 'bank_account',
        payout: UC.utils.isAttorney() && this.payout,
        isAgreeToTermsChecked: !!this.isAgreeToTermsChecked(),
        nextUrl: UC.constants.nextUrl,
        redirectSuccess: true
      };

      UC.net.post('/account/save_payment_method', data).fail(function() {
        this.btnLoader.hideLoader();
      }.bind(this));
    }
  },

  showSecurityInfo: function (e) {
    e.preventDefault();
    $('#modal-account-security').modal('show');
  }
  
});
