!function() {
  var changed = false;

  $.fn.confirmUnload = function() {
    $(this).change(function() {
      changed = true;
    });

    function onUnload(e) {
      if (changed) {
        var confirmationMessage = 'Leaving this page will discard your changes';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    this.resetChanged = function() {
      changed = false;
    };

    $(window).bind('beforeunload', onUnload);
    return this;
  };

}();
