!function() {
  UC.modals.EditBio = Backbone.View.extend({

    el: '#mdlEditBiography',

    elements: {
      bioCounter: null,
      btnLoader: null,
      btnSaveBiography: null,
      biographyContent: null
    },

    events: {
      'click #btnSaveBiography': 'saveBiography',
      'click .js-pf-additional-label': 'showExample'
    },

    openBioModal: function (e) {
      if (e) e.preventDefault();
      var bioText = this.$el.find('.modal-body').data('originalBio');
      this.$('#txtBiography').val(bioText);
      this.$('#errEditBiography').hide();
      this.$el.modal({ backdrop: 'static' });
    },

    saveBiography: function() {
      var data = { biography : this.$('#txtBiography').val() };

      if (data.biography.length < 200) {
        this.$('#errEditBiography').html('Biography must be at least 200 characters in length.<br/>Current length: ' + data.biography.length);
        this.$('#errEditBiography').show();
        return;
      }

      if (data.biography.length > 1000) {
        this.$('#errEditBiography').html('Biography must be at less 1000 characters in length.<br/>Current length: ' + data.biography.length);
        this.$('#errEditBiography').show();
        return;
      }

      this.$('#errEditBiography').hide();
      this.elements.btnLoader.showLoader();

      UC.net.post(UC.constants.update_biography_path, data, function(response) {
        UC.utils.checklistSuccessCallback('btnEditBiography');
        this.elements.biographyContent.html(response.html);
        this.$el.find('.modal-body').data('originalBio', response.biography_text);
        this.$el.modal('hide');
      }.bind(this)).fail(function(res) {
        UC.closeTheLoop.handleFailure(res, true);
      }).always(function(res) {
        this.elements.btnLoader.hideLoader();
      }.bind(this));
    },

    initialize: function(opts) {
      this.elements.biographyContent = opts.biographyContent;
      this.elements.bioCounter = this.$('#txtBiography').charCounter({ min: 200, max: 1000, allowInvalid: true, validityThreshold: 0 });
      this.elements.btnLoader = this.$('#btnSaveBiography').buttonLoader();
    },

    showExample: function(e) {
      e.preventDefault();
      $(e.target).closest('.modal').find('.pf-additional').toggleClass('hide');
    }
  });
}();
