UC.views.ContentPagesSeoMotherPage = Backbone.View.extend({
  events: {
    'click #btnSelectState': 'onBtnSelectState',
    'click .state': 'clickStateMap'
  },
  
  initialize: function() {
    $('path.state').each(function(index, el){
      var abbr = $(el).attr('id');
      var state = UC.constants.statesHash[abbr];
      $(el).prop('title', state);
    });
  
    $('.state').tooltip({ container:'body' });
    
    this.onBtnSelectState = this.onBtnSelectState.bind(this);
    this.clickStateMap = this.clickStateMap.bind(this);

    UC.utils.initRatingDimensions('.review-dimensions');
  },
  
  onBtnSelectState: function () {
    var slug = this.$('#selectState').val();
    this.setSlug(slug);
  },
  
  clickStateMap: function (e) {
    var state = e.target.title;
    var slug = this.$('#selectState option').filter(function (i, option) {
      return $(option).html() === state;
    }).val();
    
    this.setSlug(slug);
  },
  
  setSlug: function(slug) {
    if (slug && slug !== '' && slug !== '##') return location.href = slug;
  }
});
