!function () {

  var defaults = {
    checkboxSelector: '.modal-checkbox-required',
    confirmBtnSelector: '.modal-confirm-btn',
    modalSelector: '#modal'
  };

  var CheckboxModal = function (options) {
    options = $.extend({}, defaults, options || {});
    this.checkboxSelector = options.checkboxSelector;
    this.confirmBtnSelector = options.confirmBtnSelector;
    this.modalSelector = options.modalSelector;
    this.initialize();
  };

  CheckboxModal.prototype = {
    initialize: function () {
      var checkboxes = $(this.checkboxSelector);
      var modal = $(this.modalSelector);

      // Ensure confirm button is disabled when modal is first shown
      modal.on('shown.bs.modal', this.setConfirmBtnDisability.bind(this));

      // Track each time modal opens in KISS
      modal.on('shown.bs.modal', this.registerViewEvent.bind(this));

      // Toggle confirm button disability based on all checkboxes being checked
      checkboxes.click(this.setConfirmBtnDisability.bind(this));

      // Track confirm button clicks only if checkboxes are all checked
      checkboxes.click(this.configureClickTracking.bind(this));
    },

    areAllCheckboxesChecked: function () {
      var selector = this.modalSelector + ' ' + this.checkboxSelector;
      return $(selector + ':checked').length === $(selector).length;
    },

    configureClickTracking: function () {
      var confirmBtn = $(this.confirmBtnSelector);
      var allChecked = this.areAllCheckboxesChecked();

      if (allChecked && !confirmBtn.hasClass('track-click')) {
        confirmBtn.addClass('track-click');
      } else if (!allChecked) {
        confirmBtn.removeClass('track-click');
      }
    },

    registerViewEvent: function () {
      var eventName = $(this.modalSelector).data('view-event-name');
      if (eventName) UC.utils.recordKissEvent(eventName);
    },

    setConfirmBtnDisability: function () {
      var allChecked = this.areAllCheckboxesChecked();
      $(this.confirmBtnSelector).prop('disabled', !allChecked);
    }
  };

  UC.components.checkboxModal = function (options) {
    new CheckboxModal(options);
  };

}();
