UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.TimesheetJobsDropdownView = Backbone.View.extend({
  tagName: 'span',

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/timesheet_jobs_dropdown"),

  render: function() {
    var options = { dropdownAutoWidth: true };

    this.$el.html(this.template(this.templateData()));
    if (this.newLineItem()) options.placeholder = 'Add Job';

    this.$('select').select2(options);
  },

  templateData: function() {
    return {
      readOnly: this.readOnly(),
      selectedJobTitle: this.selectedJobTitle(),
      newLineItem: this.newLineItem(),
      collection: this.collection,
      model: this.model,
      selectNotRelatedtoUcJob: this.selectNotRelatedtoUcJob()
    }
  },

  newLineItem: function() {
    return !this.model.get('_id');
  },

  selectNotRelatedtoUcJob: function() {
    if(!this.model.get('_id')) return false;
    if(this.model.get('JobId')) return false;
    return true;
  },

  readOnly: function() {
    return this.model.readOnly();
  },

  selectedJob: function() {
    return this.model.getAssociatedJobModel();
  },

  selectedJobTitle: function() {
    var jobModel = this.selectedJob();

    if (jobModel) return jobModel.get('JobTitle');
  }
});
