UC.components.AvailabilityDayGroupView = Backbone.View.extend({

  tagName: 'div',

  className: 'availability-day-group marg-b-15',

  events: {
    'click .add-time-range' : 'addNewTimeRange'
  },

  template: UC.views.resolveHandlebarsTemplate("components/calendar_availability/availability_day_group"),

  initialize: function(args) {
    this.day = args.day;
    this.collection.on('add', this.appendNewModelView.bind(this));
  },

  render: function() {
    this.$el.html(this.template({dayDisplay: this.dayDisplay()}));

    this.collection.forEach(function(availabilityTimeRangeModel) {
      this.appendNewModelView(availabilityTimeRangeModel)
    }, this);

    this.initSelect2();
  },

  dayDisplay: function() {
    return this.day.capitalize();
  },

  initSelect2: function () {
    $('select').select2({width: "100%"});
  },

  addNewTimeRange: function(e) {
    e.preventDefault();
    var newModel = new UC.components.AvailabilityTimeRange({
      FromTime: '9:00',
      ToTime: '17:00',
      Day: this.day
    })
    this.collection.add(newModel);
    this.initSelect2();
  },

  appendNewModelView: function(availabilityTimeRangeModel) {
    // We only will append the new range row if it's for this day group
    if (availabilityTimeRangeModel.get('Day') !== this.day) return;

    availabilityView = new UC.components.AvailabilityTimeRangeView({
      model: availabilityTimeRangeModel
    });

    availabilityView.render();
    this.$('.time-ranges').append(availabilityView.$el);
  }


})
