import './shared'
import 'bootstrap-rating-input'
import './timesheets/timesheet'
import './views/public'
import './views/enterprise'
import './views/shared'
import './modals/public'
import './extentions/jquery'

!function() {

  function initSubNav() {

    if ($('.sticky-sub-nav').length != 1) {
      return;
    }

    $(window).scroll(function() {
      var scrollTop = $(document).scrollTop(),
        nav = $('.section-nav'),
        nav_wrap = nav.closest('.section-nav-wrap'),
        footer = $('.sticky-footer');

      if (!UC.utils.isMobileViewPort() && scrollTop >= nav_wrap.offset().top) {
        nav.addClass('fixed');
        footer.addClass('active');
      } else {
        nav.removeClass('fixed');
      }
    }).scroll();

    $('.section-nav-wrap .section-nav-tab').on('click', function(e) {
      var $this = $(this),
          nav_wrap = $this.closest('.section-nav-wrap'),
          scrollTop = $(document).scrollTop(),
          id = $this.attr('href') || $this.find('a').attr('href'),
          hasHash = (id && id.substr(0,1) == '#'),
          pastHeader = scrollTop >= nav_wrap.offset().top;

      if (UC.utils.isMobileViewPort()) {
        e.preventDefault();
        if(nav_wrap.hasClass('selected')) {
          $('.section-nav-tab.active').removeClass('active');
          $this.addClass('active');
          nav_wrap.removeClass('selected');
        } else {
          nav_wrap.addClass('selected');
        }
      } else {
        e.preventDefault();
        $('.section-nav-tab.active').removeClass('active');
        $this.addClass('active');
      }

      $('section.tab.active').removeClass('active');
      $(id).addClass('active');
      if (hasHash && pastHeader) {
        UC.utils.scrollToElement($(id), { offset: 100 });
      }

      $(id).trigger('nav-tab-clicked');
    });

    $('.section-nav-wrap .nav-link').on('click', function(e) {
      var $this = $(this),
          nav_wrap = $this.closest('.section-nav-wrap'),
          scrollTop = $(document).scrollTop(),
          href = $this.attr('href') || $this.find('a').attr('href'),
          pastHeader = scrollTop >= nav_wrap.offset().top;

      if (UC.utils.isMobileViewPort()) {
        e.preventDefault();
        if(nav_wrap.hasClass('selected')) {
          if ($this.hasClass('active')) {
            nav_wrap.removeClass('selected');
          } else {
            location.href = href;
          }
        } else {
          nav_wrap.addClass('selected');
        }
      }
    });
  }

  $(function() {

    initSubNav();

    UC.utils.initView();

    UC.utils.initNavBar();
    UC.utils.trackCopyAndContextMenu();
  });
}();
