UC.partials.takeATour = Backbone.View.extend({
  initialize: function(_) {
    if ($('#jobNav').hasClass('selected')) $('.jn-toggle').click();

    var $fake_step = $('<div class="hidden">visible</div>');
    $('body').append($fake_step);

    window.intro = UC.utils.initIntroJs(this.steps($fake_step));

    intro
      .onchange(this.onChangeHandler)
      .onbeforechange(this.onBeforeChangeHandler)
      .onafterchange(this.onAfterChangeHandler)
      .onexit(this.onExitHandler.bind(this))
      .oncomplete(this.onCompleteHandler.bind(this));

    UC.utils.recordKissEvent('Product tour started', {
      'page-path': window.location.pathname
    });
  },

  onCompleteHandler: function() {
    this.reset();

    UC.utils.recordKissEvent('Product tour completed', {
      'page-path': window.location.pathname
    });
  },

  onExitHandler: function() {
    this.reset();

    UC.utils.recordKissEvent('Product tour exited', {
      'page-path': window.location.pathname
    });
  },

  onAfterChangeHandler: function(el) {
    var $el = $(el);
    var step = $el.data('step');

    switch(step) {
      case 4:
        intro.exit();
        $('body').append('<div class="introjs-overlay" style="position:fixed;z-index: 9999999;opacity: 0.8">');
        break;
    }
  },

  onBeforeChangeHandler: function(el) {
    var step =  $(el).data('step');

    if (step === 2) {
      $('.job-nav').removeClass('selected');
    } else if (step === 3) {
      // timeout allows click event to occur
      // https://stackoverflow.com/a/25732108
      setTimeout(function () {
        $('.jn-toggle').click();
      });
    }
  },

  onChangeHandler: function(el) {
    var $el = $(el);
    var step = $el.data('step');

    switch (step) {
      case 3:
        $el.attr('href', '{0}#intro'.format($el.attr('href')));
        break;
      case 4:
        // fake keeping the overlay open
        $('.introjs-overlay').addClass('no-transition');
        window.location = '/dashboard#intro';
        break;
    }

    UC.utils.recordKissEvent('Product tour next step', {
      'step': step,
      'page-path': window.location.pathname
    });
  },

  reset: function() {
    var $dl = $('.jn-dashboard');
    if ($dl.length > 0) {
      $dl.attr('href', $dl.attr('href').split('#')[0]);
    }
  },

  steps: function($fake_step) {
    return ([
      {
        element: $('#jobNav'),
        text: 'Use the tabs on the navigation panel to view proposals, send messages to attorneys, and upload relevant documents.',
        position: 'bottom-middle-aligned'
      },
      {
        element: $('#what-happens-next'),
        text: 'The Job Overview tab will update as you receive proposals and will provide instructions on how to proceed.',
        position: 'top'
      },
      {
        element: UC.utils.isMobileViewPort() ? $('.jn-dashboard-mobile') : $('.jn-dashboard'),
        text: 'Click this button to visit your Dashboard and continue the tour.',
        position: 'bottom'
      },
      {
        element: $fake_step,
        text: 'Redirecting to Dashboard...'
      }
    ]);
  }
});
