UC.partials.JobNavMenu = Backbone.View.extend({
  el: '#jobNav',

  events: {
    'click': 'jobNavClickHandler',
    'click #navMenuToggleCaret': 'navMenuToggleCaretClickHandler'
  },

  jobNavClickHandler: function(e) {
    if (UC.utils.isMobileViewPort()) {
      e.stopPropagation();

      var nav = this.$el.closest('.job-nav');

      if (nav.hasClass('selected')) {
        $('.jn-tab').removeClass('active');
        this.$el.addClass('active');
        nav.removeClass('selected');
      } else {
        e.preventDefault();

        nav.addClass('selected');
        UC.utils.recordKissEvent('Mobile - Click job nav menu');
      }
    }
  },

  navMenuToggleCaretClickHandler: function(e) {
    e.stopPropagation();

    this.$el.closest('.job-nav').toggleClass('selected');
  }
});
