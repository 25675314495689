import { GeoLocationPajWidjet } from '../../../components/geo_location_paj_widget'

!function() {

  UC.views.ContentPagesShow = Backbone.View.extend({

    partials: {
      article: UC.partials.ContentPagesShowArticle,
      content_hub: UC.partials.ContentPagesShowContentHub,
      content_sub_hub: UC.partials.ContentPagesShowContentSubHub,
      document: UC.partials.ContentPagesShowDocument
    },

    views: {
      seoMotherPage: UC.views.ContentPagesSeoMotherPage,
      seoPage: UC.views.ContentPagesSeoPages
    },

    initialize: function() {
      switch(UC.constants.contentPageType) {
        case 'seo_enterprise_page':
          UC.utils.initTransparentNav('#nav');
          break;
        case 'seo_page':
          new this.views['seoPage']({
            el: '.content_pages.show'
          });
          break;
        case 'seo_mother_page':
         new this.views['seoMotherPage']({
           el: '.content_pages.show'
         });
         break;
        case 'article':
        case 'content_hub':
        case 'content_sub_hub':
        case 'document':
          new this.partials[UC.constants.contentPageType]({
            el: '.content_pages.show'
          });
          break;
      }

      $("[data-toggle='truncate']").truncateToggle();

      if(this.$('.js-geo-location').length) {
        new GeoLocationPajWidjet({el: this.$('.js-geo-location')});
      }
    }
  });

}();
