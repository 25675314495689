UC.partials.V5PAJ = UC.partials.V4PAJ.extend({
  summary_resolutionStage: function(_eventParams) {
    this.activeSteps.push(new UC.partials.SummaryResolution({
      resolvedCallback: function() {
        this.updateAndLoadNextStage({ summary_resolution: true, nextStage: true});
      }.bind(this)
    }));
  },

  summary_confirmationStage: function(_eventParams) {
    this.activeSteps.push(new UC.partials.SummaryConfirmation({
      el: '#summaryConfirmationForm',
      nextButtonCallback: this.nextButtonClicked.bind(this)
    }));
  },
});

