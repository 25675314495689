!function() {

  UC.views.CallsModalInitiate = Backbone.View.extend({

    className: 'modal fade',

    template: UC.views.resolveHandlebarsTemplate('public/calls/modal_initiate'),

    events: {
      'click #call': 'call',
      'hidden.bs.modal': 'removeModalDiv',
      'click #call-modal-cancel' : 'closeModal'
    },

    initialize: function(options) {
      this.options = options || {};
      return this;
    },

    render: function() {
      this.$el.html(this.template());
      $('body').append(this.$el);
      this.loader = this.$el.find('#call').buttonLoader();
      this.$el.modal();
    },

    call: function(e) {

      var data = {
        to: this.options.to,
        job_id: this.options.job_id
      };

      this.loader.showLoader();

      UC.net.post('/calls', data, function(err, data) {
        this.$el.modal('hide');
        if(err){
          UC.utils.showNotification(err, UC.constants.results.failure);
        } else {
          UC.utils.showNotification("Call is being initiated. Please wait while we connect you.", UC.constants.results.success);
        }
        //this.loader.hideLoader();
        //this.pollForStatus(data.id);
      }.bind(this));
    },

    // TODO: make this work
    pollForStatus: function(call_id) {
      this.poll = setInterval(function() {
        var path = '/calls/{0}'.format(call_id);

        UC.net.get(path, function(err, data) {
          var status = data.Status;

          if (status == 'queued' || status == 'ringing' || status == 'in-progress') {
            this.$el.find('#call').html(data.Status + '...');
          } else {
            clearInterval(this.poll);
            UC.utils.hideModal(this.$el);
          }
        }.bind(this));
      }.bind(this), 2000);
    },

    closeModal: function() {
      this.$el.modal('hide');
    },

    removeModalDiv: function() {
      this.$el.remove();
    }

  });
}();
