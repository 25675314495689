UC.closeTheLoop = {};

UC.closeTheLoop.handleFailure = function (xhr, addToExistingModal, showCallback, hideCallback) {
  if (!xhr || xhr.status !== 422 || !xhr.responseJSON) return;

  if (window.Bugsnag) {
    Bugsnag.leaveBreadcrumb('closeTheLoop failure', {responseJSON: xhr.responseJSON});
  }

  xhr.handled = true;

  if (typeof xhr.responseJSON.message === 'string') {
    UC.utils.showNotification(xhr.responseJSON.message, UC.constants.results.failure);
    return;
  }

  var options = {
        show_client: UC.user && UC.user.type === 'client',
      },
      eventProperties = $.extend({}, options);

  for (var type in xhr.responseJSON) {
    var failures = xhr.responseJSON[type].join(', ');
    options[type] = failures;
    eventProperties[type] = xhr.responseJSON[type];
  }

  if (addToExistingModal) {

    // new modals
    var $openModalBody = $('.modal.fade.in .modal-body'),
        template = UC.views.resolveHandlebarsTemplate('shared/panel_close_the_loop'),
        html = template(options);

    // no new modal found, grab old modal
    if ($openModalBody.length === 0) {
      $openModalBody = $('.jqmWrapper .jqmWindow .modalWrapper');
    }

    // only show close the loop once, add class to prevent re-showing
    if (!$openModalBody.hasClass('ctl-shown')) {
      $openModalBody.append(html);
      $openModalBody.addClass('ctl-shown');
    }

  } else {

    var $existingModal = $('#modalCloseTheLoop');
    if ($existingModal.length > 0) {
      $existingModal.modal('hide');
      $existingModal.remove();
    }

    var template = UC.views.resolveHandlebarsTemplate('shared/modal_close_the_loop'),
        mdl = template(options);

    $('body').append(mdl);
    if(typeof showCallback === 'function') {
      showCallback();
    }
    var $mdl = $('#modalCloseTheLoop');
    $mdl.modal({backdrop: 'static'});

    if(typeof hideCallback === 'function') {
      $mdl.on('hide.bs.modal', function() {
        hideCallback();
      });
    }

    //TODO not sure why the built-in BS modal dismissal and callback and are not being used
    $mdl.find('#btnCloseClosTheLoop').click(function() {
      $mdl.modal('hide');
      UC.utils.sendNesEvent('close_the_loop_modal_closed');
    })
  }

  eventProperties.append_to_modal = addToExistingModal;
  UC.utils.sendNesEvent('close_the_loop_message_shown', eventProperties);
};
