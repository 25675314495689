UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.TimesheetLineItemRowView = Backbone.View.extend({
  tagName: 'div',

  className: 'tr flipped-outer-border',

  events: {
    'change .ts-line-item-field' : 'updateField',
    'click .select-ts-line' : 'selectLineClicked',
    'click .delete-ts-line' : 'deleteTsLineLineClick',
    'focus input' : 'inputFocused',
    'focusout input' : 'inputUnFocused',
    'keydown .ts-line-item-rate' : 'createNewTsLineOnTabIfLastLine'
  },

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/timesheet_line_item_row"),

  initialize: function(opts) {
    this.model.on('change:Hours change:Rate', this.updateSubtotal.bind(this));
    this.model.on('change:JobId', this.render.bind(this));
    this.model.on('change:Rate', this.formatRateField.bind(this));
    this.model.on('change:Hours', this.setHoursInputValue.bind(this));
    this.model.on('destroy', this.removeElement.bind(this));
    this.model.on('line-selected', this.selectLineItem.bind(this));
    this.model.on('line-unselected', this.unselectLineItem.bind(this));
    this.tableView = opts.tableView;
    this.readOnlyOverride = opts.readOnlyOverride;
  },

  templateData: function() {
    return {
      showDeleteButton: this.showDeleteButton(),
      allowedToSelectLine: this.allowedToSelectLine(),
      showInvoicedIcon: this.showInvoicedIcon(),
      invoiceUrl: this.invoiceUrl(),
      notInvoiceable: this.notInvoiceable(),
      invoiceNumber: this.invoiceNumber(),
      previouslySelectedLineJobConflict: this.previouslySelectedLineJobConflict(),
      readOnly: this.readOnly(),
      formattedDate: this.formattedDate(),
      showJobColumn: this.showJobColumn(),
      showTypeSelect: this.showTypeSelect(),
      displayType: this.displayType(),
      formattedRate: this.formattedRate(),
      formattedSubtotal: this.formattedSubtotal(),
      showDeleteColumn: this.showDeleteColumn(),
      model: this.model
    }
  },

  render: function() {
    this.$el.html(this.template(this.templateData()));
    this.$('[data-toggle="tooltip"]').tooltip();
    this.$('.datepicker').datepicker(UC.constants.datepickerOptions);

    if (this.tableView.showJobColumn) {
      this.jobsDropdownView = new UC.timesheetComponent.TimesheetJobsDropdownView({
        model: this.model,
        collection: this.jobsCollection()
      });

      this.jobsDropdownView.render();
      this.$('.ts-line-job-dropdown-container').html(this.jobsDropdownView.$el);
    }

    if (this.showTypeSelect()) {
      this.typesDropdownView = new UC.timesheetComponent.TimesheetTypesDropdownView({
        model: this.model,
        showSelect: this.showJobColumn()
      });

      this.typesDropdownView.render();
      this.$('.ts-line-type-dropdown-container').html(this.typesDropdownView.$el);
    }

    this.$('.fancy-check').fancyCheck({manualChecking: true});
    if (this.model.selected) this.$el.addClass('selected');

    this.delegateEvents();
  },

  updateField: function(e) {
    var $updatedInput = $(e.currentTarget);
    var fieldName = $updatedInput.data('field-name');
    var fieldType = $updatedInput.data('field-type');
    var value = $updatedInput.val();


    if (fieldType === 'currency' || fieldType === 'float') {
      var convertedVal = UC.utils.stripCurrency(value);

      if (isNaN(convertedVal)) {
        value = null;
      } else {
        value = (Math.round(convertedVal * 100) / 100.0).toFixed(2);
      }

      // Removes trailing zeros from hours field as they are not necessary
      if (fieldType === 'float' && value) value = parseFloat(value);
    }

    // We cannot set a null value for rate as it will not reflect properly on invoice
    if (fieldName === 'Rate' && !value) value = '0.00';

    if (fieldType === 'date') {
      var date = moment(value, UC.constants.momentDateFormats, true);

      if (date.isValid()) {
        value = date.format("MM/DD/YYYY");
      } else {
        value = '';
        this.clearDateInputValue();
      }
    }

    var updates = {};

    updates[fieldName] = value;

    this.model.save(updates, {patch: true});
  },

  updateSubtotal: function() {
    this.$('.ts-line-item-subtotal .attribute-detail').text(this.formattedSubtotal());
  },

  formatRateField: function() {
    this.$('.ts-line-item-rate').val(this.formattedRate());
  },

  setHoursInputValue: function() {
    this.$('input[data-field-name="Hours"]').val(this.model.get('Hours'));
  },

  clearDateInputValue: function() {
    this.$('input[data-field-name="Date"]').val('');
  },

  selectLineClicked: function(e) {
    e.preventDefault();

    if (this.model.selected) {
      this.model.markUnselected();
    } else if (this.model.allowedToSelectLine()) {
      this.model.markSelected();
    } else {
      // This case should be prevented by re-render
      new UC.components.alertModal({
        title: 'Select line item from same job',
        message: 'You can only create an invoice from line items that are associated with a single job'
      });
    }
  },

  unselectLineItem: function() {
    this.$('.select-ts-line').removeClass('checked');
    this.$el.removeClass('selected');
  },

  selectLineItem: function() {
    this.$('.select-ts-line').addClass('checked');
    this.$el.addClass('selected');
  },

  inputFocused: function(e) {
    $parentCell = $(e.currentTarget).closest('.tc');
    $parentCell.addClass('focused');
  },

  inputUnFocused: function(e) {
    $parentCell = $(e.currentTarget).closest('.tc');
    $parentCell.removeClass('focused');
  },

  deleteTsLineLineClick: function(e) {
    e.preventDefault();

    UC.utils.recordKissEvent('timesheet_entry_deleted', {
      timesheet_line_id: this.model.get('_id'),
      page_path: window.location.pathname
    });

    this.model.destroy();
  },

  removeElement: function() {
    this.$el.remove();
  },

  jobsCollection: function() {
    return this.model.jobsCollection();
  },

  showDeleteColumn: function() {
    return this.tableView.showDeleteColumn;
  },

  isInvoiceable: function() {
    return this.model.isInvoiceable();
  },

  formattedDate: function() {
    return this.model.formattedDate();
  },

  formattedSubtotal: function() {
    return this.model.formattedSubtotal();
  },

  formattedRate: function() {
    return this.model.formattedRate();
  },

  showInvoicedIcon: function() {
    return this.model.invoiced();
  },

  showDeleteButton: function() {
    return (this.showDeleteColumn() && !this.model.invoiced());
  },

  allowedToSelectLine: function() {
    return this.model.allowedToSelectLine();
  },

  notInvoiceable: function() {
    return !this.model.isInvoiceable();
  },

  readOnly: function() {
    return this.readOnlyOverride || this.model.readOnly();
  },

  showJobColumn: function() {
    return this.tableView.showJobColumn;
  },

  invoiceNumber: function() {
    return this.model.invoiceNumber();
  },

  previouslySelectedLineJobConflict: function() {
    return !this.model.matchesPreviouslySelectedLineJob();
  },

  invoiceUrl: function() {
    var invoiceId = this.model.invoiceId();

    if (invoiceId) return '/invoices/{0}'.format(invoiceId);
  },

  createNewTsLineOnTabIfLastLine: function(e) {
    var keyCode = e.which;

    if (keyCode === 9 && this.lastLineInTable()) {
      this.model.collection.createNewModel();
    }
  },

  lastLineInTable: function() {
    var tsTableLineViews = this.tableView.timesheetLineViews;

    return tsTableLineViews[tsTableLineViews.length-1] === this;
  },

  setFocusOnDateCell: function() {
    this.$('.ts-date-col input:text:visible:first').focus();
  },

  showTypeSelect: function() {
    return !this.model.readOnly() && this.tableView.showTypeSelect ;
  },

  displayType: function() {
    return this.model.displayType();
  }

});
