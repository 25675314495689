import './accept_proposal'

UC.views.ProposalsIndex = Backbone.View.extend({

  events: {
    'click #no-props-close-job' : 'onClickedNoPropsCloseJob',
    'click #request-more-bids' : 'onRequestMoreBidsClick',
    'click .jp-send-message' : 'openContactModal',
    'click .jp-invite-btn' : 'openInviteModal',
    'click #js-suggested-attorneys-view-all': 'onClickViewAllAttorneys'
  },

  initialize: function() {
    UC.utils.initRatingDimensions('.jp-reviews');
    UC.utils.initRatingDimensions('.suggested-attorney-reviews')

    $('body').on('proposalDeclined', this.onProposalDeclined.bind(this));

    var queryParams = UC.utils.queryStringParams();

    if (UC.constants.showImmediateBiddingRetractNotification) {
      this.showImmediateBiddingRetractNotification();
    } else if (queryParams['prompt_for_subscription'] === 'true') {
      var promptCookieValue = 'viewed_prompt_for_subscription';

      if(document.cookie.indexOf(promptCookieValue) !== -1) return;

      document.cookie = promptCookieValue + "=true; max-age=60*60"
      new UC.modals.SubscriptionPromptModal().render();
    }

    this.$el.find('.invite-tooltip').tooltip({
      trigger: 'hover',
      title: 'Ask this lawyer to submit a proposal for your project. You may request proposals from more than one lawyer.'
    });

    this.initProposalRows();

    this.modalContactAttorney = new UC.partials.ModalContactAttorney({
      el: '#modal-contact-attorney'
    });

    new UC.partials.FeesDetails(); 

    UC.acceptJobProposal.init({
      acceptProposalButtonSelector: '.jp-accept-btn',
      jobId: UC.constants.jobId,
      hideHireHelpModal: UC.constants.hideHireHelpModal
    });

    this.setBtnLoaders();
  },

  setBtnLoaders: function() {
    $('.jp-accept-btn').each(function() {
      var $this = $(this);
      $this.data('loader', $this.buttonLoader());
    });

    this.btnContinuePayLoader = $('#btnContinuePay').buttonLoader();
    this.requestMoreBidsButtonLoader = this.$el.find('#request-more-bids').buttonLoader();
  },

  openContactModal: function (event) {
    if (UC.user.status === UC.constants.UserStatus.Pending) {
      this.showPendingModal('Please confirm your email address before sending a message');
      return false;
    }
    var targetData = event.target.dataset;
    this.modalContactAttorney.render({
      attorneyId: targetData.attorneyId || targetData.aid,
      attorneyName: targetData.attorneyName
    });
  },

  openInviteModal: function (event) {
    event.preventDefault();

    if (UC.user.status === UC.constants.UserStatus.Pending) {
      this.showPendingModal('Please confirm your email address before requesting a proposal');
      return false;
    }

    var $btn = $(event.currentTarget);
    var $row = $btn.closest('.suggested-attorney-row');
    var attorney_id = $btn.data('attorney-id');
    var attorney_first_name = $btn.data('attorney-first-name');

    new UC.partials.JobsModalRequestInvite({
      attorney_id: attorney_id,
      attorney_first_name: attorney_first_name,
      callback: function() {
        $('.js-jp-invite-container-'+this.attorney_id).addClass('hide');
        $('.js-jp-invited-container-'+this.attorney_id).removeClass('hide');
      }
    }).render();

    UC.utils.recordKissEvent('invite_proposal_click', {
      attorney_id: attorney_id,
      job_id: UC.job.id,
      referrer: document.referrer
    });
  },

  showPendingModal: function (message) {
    new UC.modals.ModalPendingUser().render(message);
  },

  onClickedNoPropsCloseJob: function(e) {
    e.preventDefault();
    $(e.currentTarget).trigger('userCancelJobClick');
  },

  onRequestMoreBidsClick: function(e) {
    e.preventDefault();
    this.requestMoreBidsButtonLoader.showLoader();

    UC.net.post(
      '/job/{0}/immediate_bidding_request_proposals'.format(UC.job.id),
      {}
    ).fail(function() {
      this.requestMoreBidsButtonLoader.hideLoader();
    }.bind(this));
  },

  onProposalDeclined: function(e) {
    if (!this.$el.find('.jp-single').hasClass('submitted')) {
      this.$el.find('#request-addl-proposals-container').removeClass('hidden');
    }
  },

  showImmediateBiddingRetractNotification: function() {
    var message = 'Some of the suggested lawyers are unable to work on your project. We have invited additional attorneys to submit proposals, and you will be able to review these proposals soon.'

    UC.utils.showNotification(message, null, 0);
    UC.utils.recordKissEvent('immediate_bidding_retract_notification_shown', {
      loc: 'job_proposals_page'
    });
  },

  onClickViewAllAttorneys: function() {
    this.$('.js-suggested-attorney-row').removeClass('hidden');
    this.$('#js-suggested-attorneys-view-all').remove();

    UC.utils.recordKissEvent('Clicked View All', { job_id: UC.job.id });
  },

  initProposalRows: function() {
    this.$el.find('.jp-single').each(function(i, elem) {
      new UC.partials.ProposalRow({
        el: elem,
        showMore: true
      })
    })
  }
});
