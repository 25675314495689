$.fn.detachableHead = function (options) {
  var that = this,
    defaults = {
      backgroundColor: '#fff'
    },
    opt = $.fn.extend({}, defaults, options);

  function detach() {
    var $this = $(this),
      $head = $this.find('thead'),
      $tr = $head.find('tr');

    if ($tr && $tr.length > 0) {
      var $dummyTr = $tr.clone();
      $dummyTr.addClass('detach-dummy');

      $tr = $($tr[0]);
      $tr.addClass('detach');
      $this._backgroundColor = $tr.css('backgroundColor');
      $this._height = $tr.height();

      var $th = $tr.find('th'),
          $dTh = $dummyTr.find('th');

      $dTh.each(function (i) {
        var $currentTh = $th.eq(i),
            $currentDTh = $(this);
        $currentDTh.css({width: '{0}px'.format($currentTh.outerWidth())});
      });

      $dummyTr.css({
        'position': 'fixed',
        'z-index': 100,
        'top': 0,
        'backgroundColor': opt.backgroundColor,
        'width': '{0}px'.format($this.width())
      });

      $head.append($dummyTr);
      that.detached = true;
    }
  }

  function attach() {
    var $this = $(this),
      $head = $this.find('thead'),
      $tr = $head.find('tr.detach');

    if ($tr) {
      $tr.removeClass('detach');
      $tr.css({backgroundColor: $this._backgroundColor, position: '', left: ''});
      $head.find('.detach-dummy').remove();
      that.detached = false;
    }
  }

  function onScroll() {
    var scrollTop = $(window).scrollTop(),
      scrollLeft = $(window).scrollLeft(),
      offsetTop = that.offset().top,
      offsetLeft = that.offset().left;

    if (scrollTop >= offsetTop) {
      if (!that.detached) {
        that.detach();
      }
    } else {
      if (that.detached) {
        that.attach();
      }
    }

    if (that.detached) {
      var left = scrollLeft == 0 ? '' : '{0}px'.format(offsetLeft - scrollLeft);
      that.find('tr.detach').css({left: left});
    }

  }

  $(window).scroll(onScroll);

  that.detach = detach;
  that.attach = attach;
  return that;
};
