!function () {

  var defaults = {
      buttonCssClass: 'btn btn-multi-select'
    },
    ulFormat = '<ul class="multi-button-list">{0}</ul>',
    liFormat = '<li class="{0}" data-value="{1}">{2}</li>';

  function MultiSelectButtons(el, options) {
    this.$el = $(el);
    this.options = $.fn.extend({}, defaults, options);
    this.buttonCssClass = this.options.buttonCssClass;
    this.listValues = {};

    if(this.options.listValues){
      if($.isArray(this.options.listValues)){
        for(var i = 0, len = this.options.listValues.length; i < len; i++){
          this.listValues[this.options.listValues[i]] = this.options.listValues[i];
        }
      }
      else if($.isPlainObject(this.options.listValues)){
        this.values = this.options.listValues;
      }
    }

    this.render();
    this.bindEvents();
    this.$el.selected = this.selected.bind(this);
  }

  MultiSelectButtons.prototype = {

    constructor: MultiSelectButtons,

    render: function () {
      var items = '';

      for(var key in this.listValues){
        if(this.listValues.hasOwnProperty(key)){
          items += liFormat.format(this.buttonCssClass, this.listValues[key], key);
        }
      }
      this.$el.html(ulFormat.format(items));
    },

    bindEvents: function () {
      this.$el.on('click', 'li', function() {
        var $li = $(this);
        $li.toggleClass('selected');
      });
    },

    selected: function(){
      return this.$el.find('li.selected').map(function(){
        return $(this).data('value');
      }).get();
    }
  };

  $.fn.multiSelectButtons = function () {
    var args = Array.prototype.slice.apply(arguments),
      existing = $(this).data('msb');

    if (existing && typeof args[0] === 'string') {
      return existing[args[0]].apply(existing, args.slice(1));
    } else {
      var msb = new MultiSelectButtons(this, args[0]);
      $(this).data('msb', msb);
      return msb.$el;
    }
  };
}();
