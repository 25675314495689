UC.views.AccountForgotPassword = Backbone.View.extend({

  events: {
    'click #btnResetPassword' : 'submit'
  },

  initialize: function() {
    this.btnLoader = this.$el.find('#btnResetPassword').buttonLoader();
    this.$email = this.$el.find('#txtEmail');
    this.$password = this.$el.find('#txtPassword');
    this.$verify = this.$el.find('#txtPasswordVerify');
    this.$token = this.$el.find('#txtToken');
    this.$messagePanel = this.$el.find('#panelForgotPasswordMessage');
    this.$passwordPanel = this.$el.find('#panelForgotPassword');
    UC.utils.registerEnterKey(this.$passwordPanel, this.submit.bind(this))
  },

  submit: function(e) {
    if (this.validate()) {
      this.btnLoader.showLoader();
      UC.net.post('/account/reset_password', this.data, function() {
        UC.utils.hidePanel(this.$passwordPanel);
        UC.utils.showPanel(this.$messagePanel);
      }.bind(this)).always(function() {
        this.btnLoader.hideLoader();
      }.bind(this));
    }
  },

  validate: function() {
    var errors = [];
    UC.utils.clearValidationErrors('form-group');

    this.data = {
      email: this.$email.val(),
    };

    if (!UC.utils.validateEmail(this.data.email)) {
      errors.push({element: this.$email, text: 'Please provide a valid email address'});
    }

    if (UC.constants.pageName === 'setPassword') {
      this.data.password = this.$password.val();
      this.data.passwordVerify = this.$verify.val();
      this.data.token = this.$token.val();

      if (this.data.password.length < UC.constants.minPasswordLength) {
        errors.push({element: this.$password, text: 'Please provide a valid password with at least {0} characters'.format(UC.constants.minPasswordLength)});
      }
      else if (this.data.password != this.data.passwordVerify) {
        errors.push({element: this.$verify, text: 'Passwords do not match'});
      }
    }

    UC.utils.showValidationErrors(errors, {className: 'form-group'});
    return errors.length === 0;
  }

});
