UC.views.InvoicesShow = Backbone.View.extend({

  events: {
    'click #invoice-additional-info' : 'showAdminInfoForm',
    'click #cancel-admin-info-form' : 'cancelAddAdminInfo',
    'click #btnSaveInvoiceReason' : 'submitUpcounselPaymentsReason',
    'click #btnInvoiceDescription' : 'openInvoiceDescription',
    'click #openOfflinePaymentModal' : 'openOfflinePaymentModal',
    'click #btnBadExperience' : 'showExperienceQuestion',
    'click #btnGoodExperience' : 'showExperienceQuestion',
    'submit #formAttorneyQuestionnaire' : 'submitAttorneyQuestionnaire',
    'click #btnDeleteInvoice' : 'destroy',
    'click #btnChangeDueDate' : 'onBtnChangeDueDateClick',
    'click #btnConfirmDueDateChange' : 'onDueDateChange',
    'click #aNonPaymentInvoice': 'openNoPaymentRequiredModal'
  },

  initialize: function () {
    this.today = new Date();
    var dueDateRange = this.dueDateRange();
    var dueDateDatepicker = this.$el.find('#txtDueDate').datepicker(UC.constants.datepickerOptions);
    var $mdlAttorneyQuestionnaire = this.$el.find('#mdlAttorneyQuestionnaire');

    if (!dueDateRange || dueDateRange.endDate < this.today) {
      // user is client, or is an attorney and the deadline to change due date has passed
      dueDateDatepicker.datepicker('option', {disabled: true});
    } else {
      dueDateDatepicker.datepicker('option', {minDate: dueDateRange.startDate, maxDate: dueDateRange.endDate});
    }

    if ($mdlAttorneyQuestionnaire.length > 0) {
      $mdlAttorneyQuestionnaire.modal();
      UC.utils.recordKissEvent('attorney_questionnaire_shown',{invoiceId: UC.invoice.id, attorneyId: UC.user.id});
    }

    new UC.components.FeesPopover({ el: this.$el });

    $('.jn-invoices').addClass('active');

    new UC.views.JobManageCog({ el: '.manage-job-cog' });
  },

  openInvoiceDescription: function (e) {
    e.preventDefault();
    this.$el.find('#invoice-description-modal').modal();
  },

  showAdminInfoForm: function (e) {
    e.preventDefault();
    this.$('form#add-admin-info').removeClass('hidden');
    this.$('#invoice-additional-info').addClass('hidden');
  },

  cancelAddAdminInfo: function (e) {
    e.preventDefault();
    var $adminInfoTextarea = this.$el.find('#admin-info-textarea');
    var originalValue = $adminInfoTextarea.data('originalValue');
    $adminInfoTextarea.val(originalValue);
    this.$('form#add-admin-info').addClass('hidden');
    this.$('#invoice-additional-info').removeClass('hidden');
  },

  submitUpcounselPaymentsReason: function (e) {
    e.preventDefault();

    var data = { invoice: {} };

    data.invoice.UpcounselInvoiceReason = this.$el.find("[name='UpcounselInvoiceReason']:checked").val();
    data.invoice.RelatedInvoiceId = this.$el.find('#txtRelatedInvoiceId').val();
    data.adminNote = this.$el.find('#txtAdminNote').val().trim();

    if (this.validateUpcounselInvoiceReason(data)) {
      UC.net.post('/invoices/{0}/add_upcounsel_payments_reason'.format(UC.invoice.id), data, function (response) {
        UC.utils.showNotification(response.message, UC.constants.results.success);
        $('#mdlUpcounselInvoiceReason').modal('hide');
      })
    }
  },

  dueDateRange: function () {
    if (!UC.constants.invoiceSentDate) return;

    var invoiceSentDate = new Date(UC.constants.invoiceSentDate);

    // Starting date bound is 24 hours from date the invoice was sent by attorney
    var startingDateBound = new Date(invoiceSentDate.getTime());
    startingDateBound.setDate(invoiceSentDate.getDate() + 1);

    // Ending date bound is 30 days from date the invoice was sent by attorney
    var endingDateBound = new Date(invoiceSentDate.getTime());
    endingDateBound.setDate(invoiceSentDate.getDate() + 30);

    if (UC.user && UC.user.is_admin) {
      // Admin can set due date to any date in the future
      return {startDate: this.today, endDate: null};
    } else if (UC.user && UC.user.type === 'attorney') {
      if (startingDateBound > this.today) {
        return {startDate: startingDateBound, endDate: endingDateBound};
      } else if (this.today < endingDateBound) {
        return {startDate: this.today, endDate: endingDateBound};
      } else {
        // past ending date bound, should not allow attorney to update
        return {startDate: startingDateBound, endDate: endingDateBound};
      }
    }
  },

  dueDateOutOfRange: function (momentDueDate) {
    var dateRange = this.dueDateRange();
    var startingDateBound = moment(dateRange.startDate, UC.constants.momentDateFormats, true);
    var endingDateBound = moment(dateRange.endDate, UC.constants.momentDateFormats, true);

    return !UC.user.is_admin && ((momentDueDate.isBefore(startingDateBound, 'day')) || (momentDueDate.isAfter(endingDateBound, 'day')))
  },

  onBtnChangeDueDateClick: function (e) {
    e.preventDefault();
    this.$('#dueDateModal').modal();
  },

  onDueDateChange: function () {
    var dueDate = this.$el.find('#txtDueDate').val();
    var formattedDate = moment(dueDate, UC.constants.momentDateFormats, true);

    var onChange = function (err, message) {
      this.$('#dueDateModal').modal('hide');

      if (err) {
        UC.utils.showNotification(err, UC.constants.results.failure);
      } else {
        UC.utils.showNotification(message, UC.constants.results.success);
        this.$('#lblDueDate').text(dueDate);
        this.$('#lblAutoProcessDate').text(dueDate);
      }
    }.bind(this);

    if (!formattedDate.isValid()) {
      UC.utils.showNotification('Invalid date {0}'.format(dueDate));
    } else if (this.dueDateOutOfRange(formattedDate)) {
      UC.utils.showNotification('Due date selected is outside of allowable range.');
    } else {
      var dateToSend = formattedDate.format('MM/DD/YYYY');
      UC.net.post('/invoices/{0}/update_due_date'.format(UC.invoice.id), {date: dateToSend}, onChange);
    }
  },

  validateUpcounselInvoiceReason: function (data) {
    var errors = [];
    var relatedInvoiceId = data.invoice.RelatedInvoiceId;
    var upcounselInvoiceReason = data.invoice.UpcounselInvoiceReason;
    var adminNote = data.adminNote;

    this.$el.find('.row.has-error').removeClass('has-error');

    if (relatedInvoiceId === '' || (relatedInvoiceId != '' && !UC.utils.validateObjectId(relatedInvoiceId))) {
      errors.push({element: $('#txtRelatedInvoiceId'), text: 'Please enter a valid object id'});
    }

    if (!upcounselInvoiceReason) {
      errors.push({element: $('#upcounselInvoiceReason'), text: 'Invoice reason is required'});
    }

    if (adminNote === '') {
      errors.push({element: $('#txtAdminNote'), text: 'Notes are required'});
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      return false;
    }

    return true;
  },

  openOfflinePaymentModal: function (e) {
    e.preventDefault();

    var $modal = this.$el.find('#offline-payment-modal').modal({});
    var $dateField = $modal.find('#txtProcessDate');
    var $totalFees = $modal.find('#txtPaidAmount');
    var $filingFees = $modal.find('#txtFilingFees');
    var $serviceFees = $modal.find('#txtServiceFees');
    var $closeJob = $modal.find('#chkCloseJob');

    $dateField.datepicker().datepicker(UC.constants.datepickerOptions).datepicker('setDate', new Date());

    $modal.on('change', '#txtServiceFees, #txtFilingFees', function (e) {
      var serviceFees = UC.utils.currencyToFloat($serviceFees.val());
      var filingFees = UC.utils.currencyToFloat($filingFees.val());

      $totalFees.val("${0}".format(UC.utils.formatCurrency(serviceFees + filingFees)));
    });

    $modal.on('click', '#btnCreateOfflinePayment', function (e) {
      UC.utils.clearValidationErrors('form-group');

      var errors = [];
      var data = {
        DateProcessed: $dateField.datepicker().datepicker('getDate'),
        PaidAmount: UC.utils.currencyToFloat($totalFees.val()),
        ServiceFees: UC.utils.currencyToFloat($serviceFees.val()),
        FilingFees: UC.utils.currencyToFloat($filingFees.val())
      };

      if ($closeJob.length > 0 && $closeJob.is(':checked')) {
        data.CloseJob = true;
      } else {
        data.CloseJob = false;
      }

      if (data.PaidAmount <= 0) {
        errors.push({element: $totalFees, text: 'Paid Amount must be greater than $0.00'})
      }

      if (data.ServiceFees < 0) {
        errors.push({element: $serviceFees, text: 'Service Fees must be greater than or equal to $0.00'})
      } else {
        data.ServiceFees *= 100;
      }

      if (data.FilingFees < 0) {
        errors.push({element: $filingFees, text: 'Filing Fees must be greater than or equal to $0.00'})
      } else {
        data.FilingFees *= 100;
      }

      if (data.DateProcessed === '') {
        errors.push({element: $dateField, text: 'Check Date is required'})
      }

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors, { className: 'form-group'});
      } else {
        UC.net.post('/invoices/{0}/payment/create_offline'.format(UC.invoice.id), {payment: data}, function (){
          location.reload(true);
        });
      }
    }.bind($modal));
  },

  showExperienceQuestion: function (e) {
    var target = $(e.currentTarget).data('target');
    this.$el.find(target).removeClass('hidden');
    this.$el.find('#rateExperienceQuestion').addClass('hidden');
    this.$el.find('#actionButtons').removeClass('hidden');
  },

  submitAttorneyQuestionnaire: function (e) {
    e.preventDefault();

    var data = this.$el.find('#formAttorneyQuestionnaire').serializeJSON();
    var $mdlAttorneyQuestionnaire = this.$el.find('#mdlAttorneyQuestionnaire');

    UC.net.post('{0}/submit_invoice_questionnaire'.format(UC.invoice.id), data, function (res) {
      $mdlAttorneyQuestionnaire.modal('hide');
      UC.utils.showNotification(res.message, UC.constants.results.success)
    }.bind(this))
  },

  destroy: function (e) {
    e.preventDefault();

    var message = 'Are you sure you want to delete this invoice?';

    function onConfirm(response) {
      if (response) {
        UC.net.destroy('/invoices/{0}'.format(UC.invoice.id));
      }
    }

    UC.utils.showConfirmModal('Confirm', message, onConfirm);
  },

  openNoPaymentRequiredModal: function(e) {
    e.preventDefault();

    new UC.partials.InvoicesModalNoPaymentRequired({
      el: '#modal-no-payment-required'
    }).render();
  }

})
