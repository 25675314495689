UC.components.FeesPopover = Backbone.View.extend({

  events: {
    'shown.bs.popover .proc-fee-popover' : 'procFeePopoverShown'
  },

  initialize: function(options) {
    this.initPopover(options);
    this.closePopoverOnClickOutsideOfPopover();
  },

  closePopoverOnClickOutsideOfPopover: function() {
    // hide popover when clicking outside of popover
    $('body').on('click', function (e) {

      $('.proc-fee-popover').each(function () {
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
          $(this).popover('hide');
        }
      });

    });

    // when a popover is hidden via 'hide' we need to reset its state so it will open when clicked again
    $('body').on('hidden.bs.popover', function (e) {
      $(e.target).data("bs.popover").inState = { click: false, hover: false, focus: false }
    });
  },

  initPopover: function(options) {
    options = options || {};

    this.$('.proc-fee-popover').each(function() {

      $(this).popover({
        container: options.container,
        placement: function() {
          if ($(window).isMobileWidth()) {
            return 'bottom';
          } else {
            return this.$element.data('placement');
          }
        }
      }).data('bs.popover')
        .tip()
        .addClass('fs-12');
    });
  },

  procFeePopoverShown: function(e) {
    var kissData = {
      userId: UC.user.id,
      page: "{0}_{1}".format(UC.controller, UC.action),
      type: $(e.target).data('type')
    };

    if (UC.invoice) kissData.invoiceId = UC.invoice.id;
    if (UC.job) kissData.jobId = UC.job.id;

    UC.utils.recordKissEvent('fee_popover_shown', kissData);
  }
});
