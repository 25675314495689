UC.views.JobsOverview = Backbone.View.extend({
  events: {
    'click #reveal-email-address' : 'showEmailAddress',
    'click #open-job-to-pub-mktplce-btn': 'openJobToPubMktplceBtnClick',
    'click #close-job': 'closeJobClick',
    'click #btnContactAttorney': 'contactAttorneyClickHandler',
    'click #btnContactAttorneyCancel': 'contactAttorneyCancelClickHandler',
    'click #btnContactAttorneySend': 'contactAttorneySendClickHandler',
    'click .take-a-tour': 'takeATourClickHandler',
    'click .reopen-job-button': 'reopenJobClickHandler'
  },

  initialize: function() {
    if(this.$el.find('.js-PaidConsultationOverview').length) {
      new UC.partials.OverviewForPaidConsultation({ el: '.js-PaidConsultationOverview' });
    } else {
      this.openJobToPubMktplceBtnLoader = $('#open-job-to-pub-mktplce-btn').buttonLoader();
      $('[data-toggle="popover"]').popover();
    }

    $(".jo-box:nth-child(odd)").addClass('jo-box-odd');

    var JobOverviewScheduledCallsElement = this.$('.js-overview-calls')
    if (JobOverviewScheduledCallsElement.length) {
      new UC.views.JobOverviewScheduledCalls({
        el: JobOverviewScheduledCallsElement
      })
    }

    this.initializeRatingDimensions();
  },

  reopenJobClickHandler: function(e) {
    e.preventDefault();

    var $reopenButton = $(e.currentTarget);
    var url = $reopenButton.attr('href');
    var requestAdditionalProposals = $reopenButton.data('requestAdditionalProposals');
    var $buttonLoader = $reopenButton.buttonLoader();

    $buttonLoader.showLoader();

    UC.net.post(
      url,
      { currentPage: UC.constants.pageName, requestAdditionalProposals: requestAdditionalProposals },
      function(err, res) {
        if (err) {
          $buttonLoader.hideLoader();
          UC.utils.showNotification(err, UC.constants.results.failure);
        }
      }
    );
  },

  initializeRatingDimensions: function() {
    if (this.$('#ratingDimensions').length > 0) {
      new UC.partials.ReviewsForm({ el: '#jobBoxReview' });
    } else {
      UC.utils.initRatingDimensions();
      $('.rate-star').starate();
    }
  },

  takeATourClickHandler: function(e) {
    e.preventDefault();

    this.$('#modalJobIntro').modal('hide');

    new UC.partials.takeATour();
  },

  contactAttorneyClickHandler: function(e) {
    e.preventDefault();

    this.$('#jobBoxAttorneyInfo').addClass('active');
  },

  contactAttorneyCancelClickHandler: function(e) {
    e.preventDefault();

    this.hideContactAttorneyNotificationPanel();
    this.$('#jobBoxAttorneyInfo').removeClass('active');
  },

  contactAttorneySendClickHandler: function(e) {
    e.preventDefault();

    this.hideContactAttorneyNotificationPanel();
    var message = this.$('#txtContactAttorney').val();

    if (message === '') {
      this.showContactAttorneyNotificationPanel('Please provide a message', false);
    } else {
      sendMessage(message, function(data) {
        this.showContactAttorneyNotificationPanel(data.message, true);
        this.$('#jobBoxAttorneyInfo').removeClass('active');
      }.bind(this));
    }

    function sendMessage(message, callback) {
      var attorneyId = this.$('.jo-contact-form').attr('id');
      var data = { message: { JobId: UC.constants.jobId, Message: message } };

      UC.net.post('/attorney/contact/{0}'.format(attorneyId), data, callback);
    }
  },

  showContactAttorneyNotificationPanel: function(message, isSuccess) {
    var $notificationPanel = this.$('#panelContactAttorneyNotification');

    $notificationPanel
      .removeClass('red green')
      .addClass(isSuccess ? 'green' : 'red')
      .find('p')
      .html(message);

    UC.utils.showPanel($notificationPanel);
  },

  hideContactAttorneyNotificationPanel: function() {
    var $notificationPanel = this.$('#panelContactAttorneyNotification');
    $notificationPanel.removeClass('red green').find('p').html('');
    UC.utils.hidePanel($notificationPanel);
  },

  showEmailAddress: function(e) {
    e.preventDefault();
    $('#attorney-email-address').removeClass('hidden');
    $('#reveal-email-address').addClass('hidden');
  },

  openJobToPubMktplceBtnClick: function(e) {
    e.preventDefault();
    this.convertJobToPublicMarketplace();
  },

  convertJobToPublicMarketplace: function() {
    this.openJobToPubMktplceBtnLoader.showLoader();
    UC.net.post('/job/convert_to_public_marketplace/{0}'.format(UC.job.id))
    .done(function() {
      location.reload();
    })
    .fail(function() {
      this.openJobToPubMktplceBtnLoader.hideLoader();
    });
  },

  closeJobClick: function(e) {
    e.preventDefault();
    $(e.currentTarget).trigger('userCancelJobClick');
  }
});
