UC.partials.PayAndDownloadDocument = Backbone.View.extend({

  events: {
    'click .js-downloadDocument': 'onDownloadClick',
  },

  initialize: function(opt) {
    this.clickTrackingProps = opt.clickTrackingProps
  },

  onDownloadClick: function(e) {
    var eventProps = this.clickTrackingProps('document_download_page');
    UC.utils.recordKissEvent('click_download-for-free', eventProps);
    this.showDownloadModal();
  },

  showDownloadModal: function() {
    new UC.views.DocumentDownloadOffer({
      onAccept: this.handleAcceptDocumentOffer.bind(this)
    });
  },

  handleAcceptDocumentOffer: function() {
    if (UC.constants.loggedIn) {
      console.log('Need to implement payment modal');
    } else {
      new UC.views.RegisterUserForDocumentDownload({
        onRegisterSuccess: this.handleRegisterSuccess
      })
    }
  },

  handleRegisterSuccess: function () {
    console.log('Need to implement payment modal');
  },

  downloadDocument: function() {
    var iframeString =
      '<iframe src="/free-legal-documents/document/{0}/download" ' +
      'class="component hidden"></iframe>';
    $('body').append(iframeString.format($('#DocumentId').val()));
  },
});
