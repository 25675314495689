!function() {

  UC.components.GoogleApi = function(options) {
    this.options = options || {};

    this.isChrome = navigator.userAgentData &&
        navigator.userAgentData.brands &&
        _.filter(navigator.userAgentData.brands, function(row) {
          return row.brand === "Google Chrome";
        }).length > 0;
    this.fedCMEnabled = this.isChrome && typeof window.IdentityCredential !== "undefined";
  };

  UC.components.GoogleApi.prototype = {

    authorize: function(callback) {
      const self = this;
      this.callback = callback;

      if (!window.google || !window.google.accounts || !window.google.accounts.id) {
        console.warn('google identity service is not initialized yet.');
        return;
      }

      if (this.options.backdrop) {
        this.$backdrop = $('<div class="modal-backdrop in" style="z-index: 9998"></div>');
      }

      if (!window.google_id_initialized) {
        console.info('google.accounts.id.initialize');

        google.accounts.id.initialize({
          client_id: UC.constants.google_client_id,
          auto_select: false,
          ux_mode: 'popup',
          cancel_on_tap_outside: true,
          use_fedcm_for_prompt: this.fedCMEnabled,
          context: this.options.context,
          callback: function (resp) {
            const instance = window.google_api_instance;
            if (instance) {
              instance.removeBackdrop();
              instance.handleAuthResult(resp, instance.callback);
              window.google_api_instance = null;
            }
          }
        });

        window.google_id_initialized = true; // 'initialize' must be called once on a page according to Google
      }

      window.google_api_instance = self;

      if (self.$backdrop) {
        $('body').append(self.$backdrop);
      }

      google.accounts.id.prompt(
        function (notification) {
          if (window.Bugsnag) {
            Bugsnag.leaveBreadcrumb('google.accounts.id.prompt', {notificationJSON: JSON.stringify(notification)});
          }
          const context = this.options.context || 'signin';
          if (this.fedCMEnabled) {
            this.fedCmPromptCallback(notification, context, callback)
          } else {
            this.noFedCmPromptCallback(notification, context, callback)
          }
        }.bind(this)
      );
    },

    removeBackdrop: function() {
      if (this.$backdrop) {
        this.$backdrop.remove();
      }
    },

    fedCmPromptCallback: function(notification, context, callback) {
      if (notification.isSkippedMoment()) {
        this.removeBackdrop();

        const skipReason = typeof notification.getSkippedReason === 'function' && notification.getSkippedReason();
        console.log('skipReason', skipReason)

        if (skipReason && (skipReason === 'tap_outside' || skipReason === 'user_cancel')) {
          callback({
            status: `${context}_skipped`,
            provider: 'google_oauth2'
          })
        } else {
          const contextAction = context === 'signup' ? 'up' : 'in';
          const skipMessage =
            `<span>Sign ${contextAction} with Google was canceled, please sign ${contextAction} \
            with your email ${context !== 'signup' ? 'and password ' : ' '}instead.</span> \
            <div class="marg-l-35 marg-t-10">
              In some cases, Google sign-${contextAction} might not work due to extensions like 1Password or AdBlocker, \
              try temporarily disabling them to resolve the issue.\
            </div> \
            <div class="marg-l-35 marg-t-10"> \
              Also, please make sure that Chrome sync with your Google account is turned on and \
              Third-party sign-in is not blocked by clicking the page info icon in the address bar and \
              then clicking the Reset Permission button accordingly. \
            </div>`

          callback({
            status: `${context}_not_displayed`,
            provider: 'google_oauth2',
            googleErrorMessage: skipMessage,
          })
        }
      } else if (notification.isDismissedMoment()) {
        this.removeBackdrop();

        callback({
          status: `${context}_skipped`,
          provider: 'google_oauth2'
        })
      }
    },

    noFedCmPromptCallback: function(notification, context, callback) {
      const isNotDisplayed = typeof notification.isNotDisplayed === 'function' && notification.isNotDisplayed();
      if (notification.isSkippedMoment() || isNotDisplayed) {
        this.removeBackdrop();
        document.cookie = 'g_state={}; max-age=0; path=/';
        if (isNotDisplayed) {
          console.warn(notification);
          const getNotDisplayedReason = typeof notification.getNotDisplayedReason === 'function' && notification.getNotDisplayedReason();
          if (window.Bugsnag && getNotDisplayedReason !== 'opt_out_or_no_session') {
            Bugsnag.notify(new Error(`google.accounts.id.prompt was not displayed ${getNotDisplayedReason}`), function (event) {
              event.addMetadata('notification', {NotDisplayedReason: getNotDisplayedReason})
            });
          }
        }
        callback({
          status: `${context}_${notification.isSkippedMoment() ? 'skipped' : 'not_displayed'}`,
          provider: 'google_oauth2',
          googleErrorMessage: this.buildErrorMessage(notification)
        })
      }
    },

    buildErrorMessage: function(notification) {
      const getNotDisplayedReason = notification.getNotDisplayedReason()
      const context = this.options.context || 'signin';
      const contextWords = context === 'signup' ? 'sign up' : 'sign in';
      const message = `You can not ${contextWords} with Google at the moment, please ${contextWords} using your email and password instead.`;
      if (notification.isNotDisplayed() && getNotDisplayedReason === 'opt_out_or_no_session') {
        if (this.isChrome) {
          return `In order to ${contextWords} with Google in Chrome you need to turn on Chrome sync with your Google account first. Alternatively, you can ${contextWords} with Google using different browser.`;
        } else {
          return context === 'signup' ? `${message} You will be able to link your Google account later.` : `${message} Alternatively, you can ${contextWords} with Google using different browser.`;
        }
      } else if (notification.isNotDisplayed() && getNotDisplayedReason === 'unknown_reason') {
        return `Google ${contextWords} isn't working right now, possibly due to extensions like 1Password or AdBlocker. Try temporarily disabling them to resolve the issue.`;
      }
      return message;
    },

    handleAuthResult: function(resp, callback) {
      if (window.Bugsnag) {
        Bugsnag.leaveBreadcrumb('google auth response', {responseJSON: JSON.stringify(resp)});
      }

      if (resp && !resp.error) {
        if (resp.credential) {
          if (this.options.next_url) {
            resp.next_url = this.options.next_url;
          } else if (UC.constants.returnUrl) {
            resp.returnUrl = UC.constants.returnUrl;
          }

          $.post('/auth/google_identity/callback', resp, function (data) {
            callback(data);
          });
        }
        else {
          if (window.Bugsnag) {
            Bugsnag.notify(new Error('google.accounts.id.callback is missing credential'));
          }

          UC.utils.showNotification("There was an error retrieving authentication response from Google.", UC.constants.results.failure);
        }
      } else {
        UC.utils.showNotification("There was an error connecting to Google for authentication.", UC.constants.results.failure);
      }
    }
  };
}();
