UC.partials.SelectCalendar = Backbone.View.extend({

  initialize: function(opts) {
    this.$btn = $(opts.btnSelector);
    this.$btnLoader = this.$btn.buttonLoader();
    this.callsPage = opts.callsPage;

    this.$btn.on('click', this.openCalSelectModal.bind(this));

    if (opts.openImmediately) this.openCalSelectModal();
  },

  routes: {
    select_calendar: '/calendars/select',
    calendars: '/calendars',
    calls: '/calls'
  },

  openCalSelectModal: function(e) {
    if (e) e.preventDefault();

    this.$btnLoader.showLoader();

    UC.net.get(this.routes.select_calendar)
      .done(function(res) {
        new UC.partials.modalV9({
          modalTitle: 'Select Your Calendar',
          modalContent: res.html,
          renderCallback: this.initForm.bind(this)
        });
      }.bind(this))
      .always(function(){
        this.$btnLoader.hideLoader();
      }.bind(this));
  },

  initForm: function() {
    this.$submitBtnLoader = $('#submit-cal').buttonLoader();

    this.$selectCalForm = $('#select-cal-form');

    this.$selectCalForm.find('select').select2({width: '100%'});

    this.$selectCalForm.on('submit', this.onFormSubmit.bind(this));
  },

  onFormSubmit: function(e) {
    e.preventDefault();
    this.$submitBtnLoader.showLoader();

    var formData = this.$selectCalForm.serializeJSON();
    var selectedOpt = this.$selectCalForm.find('#cronofy-cal-select option:selected');

    // Enrich the response with additional values returned from the API which will be used
    // for display purposes
    formData.cronofy_calendar.Name = selectedOpt.data('name');
    formData.cronofy_calendar.ProfileName = selectedOpt.data('profile-name');

    var formPath = this.$selectCalForm.attr('action');

    UC.net.post(formPath, formData)
      .done(function() {
        if(this.callsPage) {
          location.href = this.routes.calls;
        } else {
          location.href = this.routes.calendars;
        }
      }.bind(this))
      .fail(function() {
        this.$submitBtnLoader.hideLoader();
      }.bind(this));

  }

});
