export const GeoLocation = Backbone.View.extend({
  userChoiceLocalKey: 'UC.user.zip_code',

  initialize: function() {
    this.validateUserChoiceDebounced = _.debounce(this.validateUserChoice, 500);
  },

  findZip: function(succesfullCallback) {
    UC.net.get('/api/v1/find_zip_by_ip', succesfullCallback)
  },

  storeUserChoice: function(value) {
    try {
      if(/^\d+$/.test(value) && value.toString().length >= 5) {
        localStorage.setItem(this.userChoiceLocalKey, JSON.stringify({zip: value, timestamp: new Date().getTime()}));
        this.validateUserChoiceDebounced(value);
      } else {
        localStorage.removeItem(this.userChoiceLocalKey)
      }
    } catch(e) {
      console.warn('Failed to update data in localStorage', e);
    }
  },

  fetchUserChoice: function() {
    try {
      const localData = JSON.parse(localStorage.getItem(this.userChoiceLocalKey) || '{}');
      if (localData) {
        const diffHours = Math.abs( new Date() - localData.timestamp) / (1000 * 60 * 60); // 1 hour
        if (diffHours < 1) {
          return localData.zip;
        }
      }
    } catch(e) {
      console.warn('Failed to read data from localStorage', e);
    }
    return null;
  },

  validateUserChoice: function(value) {
    UC.net.get('/account/valid_zip_code/{0}'.format(value), {}, null).fail(function(res) {
      res.handled = true;
      if (this.fetchUserChoice() === value) {
        this.storeUserChoice(NaN);
      }
    }.bind(this));
  }
});
