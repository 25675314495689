/**
 * @description - an abstract template list that provides logic fo handling proposal_template lists.
 * @requires {template}
 */
UC.components.ProposalTemplateListAbstract = Backbone.View.extend({
  events: {
    'click .edit-template': 'handleEdit',
    'click .delete-template': 'handleDelete'
  },

  handleDelete: function(e) {
    this.onDelete(e)
  },

  handleEdit: function(e) {
    this.onEdit(e)
  },

  initialize: function(opts) {
    this.$el = opts.el;
    this.onEdit = opts.onEdit;
    this.onDelete = opts.onDelete;
    this.refreshTemplateList();
  },

  refreshTemplateList: function() {
    // NOTE: this header is added intentionally to differentiate browser caching
    //  between a matching hit between a html/json response.
    return UC.net.get('/proposal_templates', {
      'Content-Type': 'application/json'
    })
      .done(function(res) {
        this.proposalTemplates = res;
        this.render();
      }.bind(this))
      .fail(function() {
        this.render();
      }.bind(this));
  },

  render: function() {
    this.$el.html(this.template(this));
    return this;
  }
});
