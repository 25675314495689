import TextLengthCounter from '../../../components/text_length_counter'

UC.views.DirectHireJobLeadsNew = Backbone.View.extend({
  events: {
    'click #nextButton' : 'nextButtonClicked',
    'click .js-nextButton' : 'nextButtonClicked',
    'click #backButton' : 'backButtonClicked',
    'click .js-lnkNewJobLogin' : 'openNewJobLogin',
    'change .expedited_legal_subject' : 'updateExpeditedTitle'
  },

  el: '#content',

  routes: {
    update: '/direct_hire_job_leads/{0}/answer_question',
    revert: '/direct_hire_job_leads/{0}/revert_question'
  },

  initialize: function() {
    this.buttonLoader = this.$('#nextButton').buttonLoader();
    this.$paj_stage_wrapper = this.$('#paj_stage_wrapper');
    this.jobLeadId = UC.constants.jobLeadId;
    this.routes.update = this.routes.update.format(this.jobLeadId);
    this.routes.revert = this.routes.revert.format(this.jobLeadId);
    this.show_question();

    if (UC.user) {
      UC.PajHelpers.isUserRestricted();
    }

    this.descriptionLengthCounter = new TextLengthCounter({
      el: this.$el,
      errorContainer: '.form-group',
      textarea: 'textarea.js-description-textarea',
      counter: '.js-description-length'
    })
  },

  backButtonClicked: function(){
    UC.utils.recordPajEvent({
      eventName: 'back_button_clicked',
      stageName: 'questionnaire',
      jobLeadId: this.jobLeadId
    });
    this.revert_question();
  },

  nextButtonClicked: function(){
    UC.utils.recordPajEvent({
      eventName: 'next_button_clicked',
      stageName: 'questionnaire',
      jobLeadId: this.jobLeadId
    });
    var form = this.$('.job-question');
    if(!this.is_valid(form)) return;
    this.answer_question({
      question: form.data('id'),
      responses: this.responses_from_form(form)
    });
  },

  answer_question: function(data){
    this.buttonLoader.showLoader();
    UC.net.post(this.routes.update, data, function(res) {
      try{
        if(typeof res == 'string') res = JSON.parse(res);
        if(res.next_question) this.show_question(res.next_question.html);
        if(res.redirect) location.href = res.redirect.url;
      }
      catch(error){
        console.log(error);
      }
    }.bind(this)).always(function() {
      this.buttonLoader.hideLoader();
    }.bind(this));
  },

  revert_question: function(){
    UC.net.post(this.routes.revert, {}, function(res) {
      if(typeof res == 'string') res = JSON.parse(res);
      if(res.last_question) this.show_question(res.last_question.html);
    }.bind(this));
  },

  show_question: function(question_html){
    if(question_html) this.$paj_stage_wrapper.html(question_html);
    UC.utils.scrollToTop();
    this.$('.select2').select2();
  },

  responses_from_form: function(form){
    var inputs = form.find(':input');
    var responses = {};
    _.each(inputs, function(input) {
      if (input.type === "radio"){
        if(input.checked) responses[input.id.toString()] = input.value;
      } else {
        responses[input.id.toString()] = input.value;
      }
    });
    return responses;
  },

  is_valid: function(form){
    if(!form.data('required')) {
      return true;
    }
    UC.utils.clearValidationErrors('form-group');

    let errors = []
    _.each(form.find(':input'), function(input) {
      if(input.value.length < 1){
        errors.push({element: $(input), text: 'required'});
      }
    });

    if (!this.descriptionLengthCounter.hasValidTextLength()) {
      errors.push(this.descriptionLengthCounter.getError());
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, {className: 'form-group'});
    }

    return errors.length === 0;
  },

  updateExpeditedTitle: function(){
    this.$('.expedited_title').val(this.$('.expedited_legal_subject option:selected').text());
  }
});
