UC.partials.NewAdminLogEntryModal = Backbone.View.extend({

  template: UC.views.resolveHandlebarsTemplate("admin/notable/new_admin_log_entry_modal"),

  tagName: 'div',

  className: 'modal fade',

  events: {
    "hidden.bs.modal" : "deleteElement",
    "submit #admin-log-form" : "submitForm"
  },

  initialize: function(opts) {
    var $btn = $(opts.btnSelector);
    $btn.on('click', this.showModal.bind(this));
    this.formSubmitCallback = opts.formSubmitCallback || function() { location.reload() };
    this.createUrl = opts.createUrl;
  },

  showModal: function(e) {
    if(e) e.preventDefault();
    this.render();
  },
  
  render: function() {
    this.$el.html(this.template(this));
    this.$el.modal();
    this.delegateEvents();
    this.$submitBtnLoader = this.$('#submit-admin-log-entry').buttonLoader();
  },

  deleteElement: function() {
    this.$el.remove();
  },

  validate: function() {
    var errors = [],
        $noteField = this.$('textarea[name="Entry"]'),
        noteText = $noteField.val();

    UC.utils.clearValidationErrors('form-group');

    if (noteText.isBlank()) {
      errors.push({element: $noteField, text: 'Note may not be blank'});
    }

    if (errors.length === 0) {
      return true;
    } else {
      UC.utils.showValidationErrors(errors, {className: 'form-group'});
    }
  },

  disableSubmitBtn: function() {
    this.$submitBtnLoader.showLoader();
  },

  enableSubmitBtn: function() {
    this.$submitBtnLoader.hideLoader();
  },

  submitForm: function(e) {
    e.preventDefault();

    if (this.validate()) {
      var $form = this.$('#admin-log-form');
      this.disableSubmitBtn();

      UC.net.post($form.attr('action'), $form.serializeJSON())
        .done(this.formSubmitCallback.bind(this))
        .fail(this.enableSubmitBtn.bind(this));
    }
  }

});
