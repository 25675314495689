!function() {

  UC.partials.ProposalsFormQuote = Backbone.View.extend({

    el: '#proposals-form-quote',

    events: {
      'change #txtServiceFees': 'validateServiceFees',
      'change #txtFilingFees': 'validateFilingFees',
      'change #txtServiceFeesClient': 'validateServiceFeesClient',
      'change #txtFilingFeesClient': 'validateFilingFeesClient',
      'change #chkFreeCall': 'setHiddenCallInput',
      'change [name="proposal[PaymentCount]"]': 'updatePaymentTerms',
      'change .fees': 'calculateProposalFees',
      'change .js-client-fees': 'calculateProposalFeesClient',
      'click #tabHourlyFee': 'showHourlyFee',
      'click #tabFixedFee': 'showFixedFee'
    },

    initialize: function() {
      this.initHelp();
      this.$('.fees').currencyInput();
    },

    render: function(options) {
      this.paymentProfile = options.payment_profile;
      this.$platformFee = this.$('.js-platform-fee');
      this.percentFee = (this.paymentProfile.baked_service_fee / 100) + 1;
      this.updatePaymentTerms();
      this.hourlyRate = options.hourly_rate;
      this.has_invoices = options.has_invoices;
      this.client_id = options.client_id;

      if (options.transaction_type === 'USC') {
        this.$('.hide-for-usc-client').hide();
      } else {
        this.$('.hide-for-usc-client').show();
      }
    },

    toggleBakedInFee: function() {
      var hasBakedFee = !!this.paymentProfile.baked_service_fee;

      // show disclaimer on "Your Fees" only for non-baked attorneys
      this.$('#your-fees-disclaimer').toggleClass('hidden', hasBakedFee);
      this.$('#your-fees-no-disclaimer').toggleClass('hidden', !hasBakedFee);
    },

    initHelp: function() {
      var $serviceFees = $('#txtServiceFees');
      var $filingFees = $('#txtFilingFees');
      var $body = $('body');

      $serviceFees.popover({
        placement: 'top',
        content: 'Not sure how much to bid? Click <a href="/educationcenter/Proposal_Pricing" id="bid-amount-popover-education-center" target="_blank">here</a> for help.',
        trigger: 'manual',
        html: true
      });

      $filingFees.popover({
        placement: 'top',
        content: 'Not sure how much to bid? Click <a href="/educationcenter/Proposal_Pricing" id="bid-amount-popover-education-center" target="_blank">here</a> for help.',
        trigger: 'manual',
        html: true
      });

      // can't use typical kissClickEvent since popover initialized after page load
      $body.on('click', '#bid-amount-popover-education-center', function() {
        var kissData = {
          userId: UC.user.id
        };
        if (UC.job) kissData.jobId = UC.job.id;

        UC.utils.recordKissEvent('bid_amount_popover_education_center_link_click', kissData);
      });

      // set data attribute on element to close on next click after it has been shown
      $serviceFees.on('shown.bs.popover', function() {
        $serviceFees.data('closePopoverOnClick', true);
      });

      $filingFees.on('shown.bs.popover', function() {
        $filingFees.data('closePopoverOnClick', true);
      });

      // this logic is to close the popover when clicking outside of the popover div. we want to close it on the second 'click'
      // in most cases since it is opened by a click (triggering a change event) which is why we set a data attribute of
      // closePopoverOnClick
      $body.on('click', function(e) {
        var $target = $(e.target);

        if ($target.parents('.popover.in').length === 0 && $serviceFees.data('closePopoverOnClick')) {
          $serviceFees.popover('hide');
        }
      });

      new UC.components.FeesPopover({el: this.$el});
    },

    updatePaymentTerms: function(e) {
      var termValue = $('[name="proposal[PaymentCount]"]:checked').val();

      this.paymentCount = null;
      this.$('#panel-upfront').addClass('hidden');

      if (parseInt(termValue, 10) === 2) {
        this.$('#panel-upfront').removeClass('hidden');
        this.paymentCount = 2;
      } else if (parseInt(termValue, 10) === 1) {
        this.paymentCount = 1;
      }

      this.toggleBakedInFee();
      this.calculateProposalFees();
    },

    setBidType: function(bidType) {
      var $proposalType = this.$('#BidType');
      $proposalType.val(bidType);
    },

    calculateProposalFees: function() {
      var $serviceFees = this.$('#txtServiceFees');
      var $estimatedHours = this.$('#txtEstimatedHours');
      var $filingFees = this.$('#txtFilingFees');
      var $proposalType = this.$('#BidType');

      var serviceFees = UC.utils.currencyToFloat($serviceFees.val());
      var estimatedHours = UC.utils.currencyToFloat($estimatedHours.val());
      var filingFees = UC.utils.currencyToFloat($filingFees.val());
      var bidType = parseInt($proposalType.val(), 10);

      if (serviceFees < 0) $serviceFees.val(0);
      if (estimatedHours < 0) $estimatedHours.val(0);
      if (filingFees <= 0) {
        UC.utils.setSelectedElement(this.$('#selectUpfrontPayment'), '75');
        $filingFees.val(UC.utils.formatCurrency(filingFees));
      }

      // add any client "baked-in" fees to the total and show proper payment terms
      this.handleClientFees(bidType, serviceFees, estimatedHours, filingFees);
    },

    calculateProposalFeesClient: function() {
      var $serviceFeesClient = this.$('#txtServiceFeesClient');
      var $estimatedHoursClient = this.$('#txtEstimatedHoursClient');
      var $filingFeesClient = this.$('#txtFilingFeesClient');
      var $proposalType = this.$('#BidType');

      var serviceFeesClient = UC.utils.currencyToFloat($serviceFeesClient.val());
      var estimatedHoursClient = UC.utils.currencyToFloat($estimatedHoursClient.val());
      var filingFeesClient = UC.utils.currencyToFloat($filingFeesClient.val());
      var bidType = parseInt($proposalType.val(), 10);

      if (serviceFeesClient < 0) $serviceFeesClient.val(0);
      if (estimatedHoursClient < 0) $estimatedHoursClient.val(0);
      if (filingFeesClient <= 0) {
        $filingFeesClient.val(UC.utils.formatCurrency(filingFeesClient));
      }

      // add any client "baked-in" fees to the total and show proper payment terms
      this.handleAttorneyFees({bidType: bidType, serviceFees: serviceFeesClient, estimatedHours: estimatedHoursClient, filingFees: filingFeesClient});
    },

    calculateClientHourly: function() {
      // add the baked in fee to the base service fees
      return this.hourlyRate * this.percentFee;
    },

    handleClientFees: function(bidType, serviceFees, estimatedHours, filingFees) {
      var platformFees = 0.0;
      var totalAttorneyServiceFee = 0.0;

      if (bidType === UC.constants.BidType.HourlyRate) {
        totalAttorneyServiceFee = serviceFees * estimatedHours;
      } else {
        totalAttorneyServiceFee = serviceFees;
      }

      if (this.percentFee > 0) {

        // these are currently always the same as the attorney
        this.$('#txtEstimatedHoursClient').val(UC.utils.formatCurrency(estimatedHours));
        this.$('#txtFilingFeesClient').val(UC.utils.formatCurrency(filingFees));

        // add the baked in fee to the base service fees
        var clientServiceFees = serviceFees * this.percentFee;

        // add the baked in fee to the total attorney service fees
        var totalClientServiceFees = totalAttorneyServiceFee * this.percentFee;

        var amountClientServiceText = UC.utils.formatCurrency(clientServiceFees);
        this.$('#txtServiceFeesClient').val(amountClientServiceText);

        if (this.$platformFee.length) {
          platformFees = this.calculatePlatformFees(totalClientServiceFees, filingFees);
          var amountPlatformText = UC.utils.formatCurrency(platformFees, true);
          this.$platformFee.text(amountPlatformText);
        }

        var amountClientText = UC.utils.formatCurrency(totalClientServiceFees + filingFees + platformFees, true);
        this.$('#lblTotalFeesClient').text(amountClientText);
      }

      var totalText = UC.utils.formatCurrency(totalAttorneyServiceFee + filingFees, true);
      this.$('#lblTotalFees').text(totalText);

      // this should include any extra client fees from above
      if(platformFees > 0) {
        this.updateUpfrontPaymentPlatform(totalClientServiceFees, filingFees, platformFees);
      } else {
        this.updateUpfrontPaymentTerms(totalClientServiceFees, filingFees);
      }
    },

    handleAttorneyFees: function(options) {
      var bidType = options['bidType'];
      var serviceFees = options['serviceFees'];
      var estimatedHours = options['estimatedHours'];
      var filingFees = options['filingFees'];
      var platformFees = 0.0;
      var totalClientServiceFee = 0.0;

      if (bidType === UC.constants.BidType.HourlyRate) {
        totalClientServiceFee = serviceFees * estimatedHours;
      } else {
        totalClientServiceFee = serviceFees;
      }

      if (this.percentFee > 0) {

        // these are currently always the same as the client
        this.$('#txtEstimatedHours').val(UC.utils.formatCurrency(estimatedHours));
        this.$('#txtFilingFees').val(UC.utils.formatCurrency(filingFees));

        // divide the baked in fee to the client's service fees
        var attorneyServiceFees = serviceFees / this.percentFee;

        // divide the baked in fee to the total client service fees
        var totalAttorneyServiceFees = totalClientServiceFee / this.percentFee;

        var amountAttorneyServiceText = UC.utils.formatCurrency(attorneyServiceFees);
        this.$('#txtServiceFees').val(amountAttorneyServiceText);

        if (this.$platformFee.length) {
          platformFees = this.calculatePlatformFees(totalClientServiceFee, filingFees);
          var amountPlatformText = UC.utils.formatCurrency(platformFees, true);
          this.$platformFee.text(amountPlatformText);
        }

        var amountClientText = UC.utils.formatCurrency(totalClientServiceFee + filingFees + platformFees, true);
        this.$('#lblTotalFeesClient').text(amountClientText);
      }

      var totalText = UC.utils.formatCurrency(totalAttorneyServiceFees + filingFees, true);
      this.$('#lblTotalFees').text(totalText);

      // this should include any extra client fees from above
      if(platformFees > 0) {
        this.updateUpfrontPaymentPlatform(totalClientServiceFee, filingFees, platformFees);
      } else {
        this.updateUpfrontPaymentTerms(totalClientServiceFee, filingFees);
      }
    },

    calculatePlatformFees: function(totalClientServiceFees, filingFees) {
      var serviceRake = this.paymentProfile.platform_service_fee_percent / 100;
      var filingRake = this.paymentProfile.platform_filing_fee_percent / 100;
      var platformServiceFee = totalClientServiceFees * serviceRake;
      var platformFilingFee = filingFees * filingRake;
      return platformFilingFee + platformServiceFee;
    },

    updateUpfrontPaymentTerms: function(totalServiceFees, filingFees) {
      var $optionFiling = this.$('#optionFiling');

      if (filingFees > 0) {
        $optionFiling.removeAttr('disabled');
        $optionFiling.text('All Filing Fees ({0})'.format(UC.utils.formatCurrency(filingFees, true)));
      } else {
        $optionFiling.attr('disabled', 'disabled');
        $optionFiling.text('All Filing Fees');
      }

      this.$('#option75').text('75% of Service Fees ({0})'.format(UC.utils.formatCurrency(totalServiceFees * 0.75, true)));
      this.$('#option50').text('50% of Service Fees ({0})'.format(UC.utils.formatCurrency(totalServiceFees * 0.50, true)));
      this.$('#option25').text('25% of Service Fees ({0})'.format(UC.utils.formatCurrency(totalServiceFees * 0.25, true)));
    },

    updateUpfrontPaymentPlatform: function(totalServiceFees, filingFees, platformFees) {
      var upfrontPercentage = [75, 50, 25, 10];
      var $optionFiling = this.$('#optionFiling');

      if (filingFees > 0) {
        $optionFiling.removeAttr('disabled');
      } else {
        $optionFiling.attr('disabled', 'disabled');
      }

      $optionFiling.text('All Filing Fees');
      upfrontPercentage.map(function(percentage) {
        var totalUpfront = parseFloat((totalServiceFees + platformFees).toFixed(2)) * percentage / 100;
        return (
          this.$('#option' + percentage).text(
            '{0}% of Service + Platform Fees ({1})'.format(
              percentage,
              UC.utils.formatCurrency(totalUpfront , true)))
        )
      }.bind(this))
    },

    validateFees: function($fees, feeAmount, feeValidation) {
      if (feeValidation) {
        $fees.val('0.00');
        $fees.popover('show');
        $fees.data('closePopoverOnClick', false);

        var kissData = {
          userId: UC.user.id,
          serviceFeeAmountEntered: feeAmount
        };
        if (UC.job) kissData.jobId = UC.job.id;

        UC.utils.recordKissEvent('bid_service_fee_amount_popover_shown', kissData);
      }
    },

    validateServiceFees: function() {
      var minServiceFeeAmount = 50;
      var $serviceFees = this.$('#txtServiceFees');
      var serviceFeeAmount = UC.utils.currencyToFloat($serviceFees.val());
      var feeValidation = serviceFeeAmount < minServiceFeeAmount && serviceFeeAmount !== 0;
      this.validateFees($serviceFees, serviceFeeAmount, feeValidation);
      var $proposalType = this.$('#BidType');
      var bidType = parseInt($proposalType.val(), 10);

      if (bidType && bidType === UC.constants.BidType.HourlyRate
        && serviceFeeAmount != this.hourlyRate
        && this.hourlyRate > 0) {

        var kissData = {
          userId: UC.user.id,
          serviceFeeAmount: serviceFeeAmount,
          oldhourlyRate: this.hourlyRate
        };
        if (UC.job) kissData.jobId = UC.job.id;
        UC.utils.recordKissEvent('bid_calculator_fee_changed_from_hourly_rate', kissData);
      }
    },

    validateServiceFeesClient: function() {
      var minServiceFeeAmount = 50 * this.percentFee;
      var $serviceFees = this.$('#txtServiceFeesClient');
      var serviceFeeAmount = UC.utils.currencyToFloat($serviceFees.val());
      var feeValidation = serviceFeeAmount < minServiceFeeAmount && serviceFeeAmount !== 0;
      this.validateFees($serviceFees, serviceFeeAmount, feeValidation);
    },

    validateFilingFees: function() {
      var $filingFees = this.$('#txtFilingFees');
      var filingFeeAmount = UC.utils.currencyToFloat($filingFees.val());
      var feeValidation = filingFeeAmount < 0;
      this.validateFees($filingFees, filingFeeAmount, feeValidation);
    },

    validateFilingFeesClient: function() {
      var $filingFees = this.$('#txtFilingFeesClient');
      var filingFeeAmount = UC.utils.currencyToFloat($filingFees.val());
      var feeValidation = filingFeeAmount < 0;
      this.validateFees($filingFees, filingFeeAmount, feeValidation);
    },

    showHourlyFee: function(e) {
      if (e) e.preventDefault();

      var $serviceFees = this.$('#txtServiceFees');
      var serviceFeeAmount = UC.utils.currencyToFloat($serviceFees.val());

      UC.utils.showPanel(this.$('.panelHours'));
      this.$('#tabHourlyFee').addClass('active');
      this.$('#tabFixedFee').removeClass('active');
      this.$('#lblServiceFee').text('Hourly Rate:');
      this.setBidType(UC.constants.BidType.HourlyRate);

      if (this.hourlyRate > 0) {
        // Hourly Rate info
        if (serviceFeeAmount === 0) this.$('#txtServiceFees').val(UC.utils.formatCurrency(this.hourlyRate));
        this.$('#hourlyRate').text(UC.utils.formatCurrency(this.calculateClientHourly(), true) + '/hr');
        this.$('clientTimesheetLink').toggleClass('hidden', !this.has_invoices);
        this.$('#clientTimesheetLink').attr('href', '/invoices?UserId=' + this.client_id);
        UC.utils.showPanel(this.$('#hourlyNoticeRow'));
      } else {
        UC.utils.hidePanel(this.$('#hourlyNoticeRow'));
      }

      this.calculateProposalFees();

      $('.js-hourly-blurb').removeClass('hidden');
    },

    showFixedFee: function(e) {
      if (e) e.preventDefault();
      UC.utils.hidePanel(this.$('.panelHours'));
      UC.utils.hidePanel(this.$('#hourlyNoticeRow'));
      this.$('#tabFixedFee').addClass('active');
      this.$('#tabHourlyFee').removeClass('active');
      this.$('#lblServiceFee').text('Service Fees:');
      this.setBidType(UC.constants.BidType.FixedBid);
      this.calculateProposalFees();
      $('.js-hourly-blurb').addClass('hidden');
    },

    errors: function() {
      var errors = [];

      var $serviceFees = this.$('#txtServiceFees');
      var $filingFees = this.$('#txtFilingFees');
      var $estimatedHours = this.$('#txtEstimatedHours');
      var $proposalType = this.$('#BidType');

      var serviceFees = UC.utils.currencyToFloat($serviceFees.val());
      var filingFees = UC.utils.currencyToFloat($filingFees.val());
      var estimatedHours = UC.utils.currencyToFloat($estimatedHours.val());

      if (!this.paymentCount) {
        errors.push({element: this.$('#pymtTermsBox'), text: 'Please select payment terms'});
      }

      if (serviceFees <= 0) {
        errors.push({element: $serviceFees, text: 'Must be $50 or more'});
        var kissData = {
          userId: UC.user.id
        };
        if (UC.job) kissData.jobId = UC.job.id;
        UC.utils.recordKissEvent('bid_submitted_with_zero_service_fees', kissData);
      }
  
      if (filingFees < 0) {
        errors.push({element: $filingFees, text: 'Must be greater than zero'});
      }

      if (parseInt($proposalType.val(), 10) === UC.constants.BidType.HourlyRate && estimatedHours <= 0) {
        errors.push({element: $estimatedHours, text: 'Must be greater than zero'});
      }

      return errors;
    }
  });
}();
