UC.partials.InvoicesModalNoPaymentRequired = Backbone.View.extend({

  events: {
    'click #confirm-no-payment': 'submitNoPayment'
  },

  render: function() {
    this.$el.modal({
      backdrop: 'static'
    });
  },

  submitNoPayment: function(e) {
    e.preventDefault();

    var $noPaymentReasonTextarea = this.$('#no-payment-reason');
    var isValid = UC.utils.validateFields({
      element: $noPaymentReasonTextarea,
      errorMessage: 'Please provide a reason for why payment is not required'
    });

    if (isValid) {
      var data = {
        no_payment_reason: $noPaymentReasonTextarea.val()
      };

      if (UC.invoice && UC.invoice.isPersisted) {
        data.invoice_id = UC.invoice.id;
      }

      $(window).off('beforeunload'); // disable 'Are you sure you want to leave this page?' browser modal

      UC.net.post('/invoices/no_payment/{0}'.format(UC.job.id), data);
    }
  }
});
