UC.partials.AddressForm = Backbone.View.extend({

  events: {
    'click #toggleInternationalPanel' : 'toggleInternationalPanel',
    'click #toggleZipCodePanel' : 'toggleZipCodePanel'
  },

  routes: {
    validZipCode: '/account/valid_zip_code/'
  },

  initialize: function(options) {
    this.$zip = this.$el.find('#txtZipCode');
    this.$country = this.$el.find('#selectCountry');
    this.$city = this.$el.find('#txtCity');
    this.$panelInternationalAddress = this.$el.find('#panelInternationalAddress');
    this.$panelZipCodeAddress = this.$el.find('#panelZipCodeAddress');

    if (this.$zip.val() || !this.$city.val()) {
      this.toggleZipCodePanel();
      this.onTxtZipCodeBlur();
    } else {
      this.toggleInternationalPanel();
    }
  },

  toggleZipCodePanel: function (e) {
    if (e) e.preventDefault();
    UC.utils.hidePanel(this.$panelInternationalAddress);
    UC.utils.showPanel(this.$panelZipCodeAddress);
  },

  toggleInternationalPanel: function (e) {
    if (e) e.preventDefault();
    UC.utils.hidePanel(this.$panelZipCodeAddress);
    UC.utils.showPanel(this.$panelInternationalAddress);
  },

  onTxtZipCodeBlur: function (e, dfd) {
    if (this.$panelZipCodeAddress.is(':visible')) {
      var zipCode = this.$zip.val();
      var error = {
        className: 'form-group',
        element: this.$zip,
        text: 'Please enter a valid zip code'
      };

      this.validZipCode = false;

      if (UC.utils.validateZipCode(zipCode)) {
        this.checkZipCodeValid(zipCode, dfd).fail(function() {
          UC.utils.showValidationErrors([error], {scrollToFirst: false});
          if (dfd) dfd.resolve();
        });
      } else {
        UC.utils.showValidationErrors([error], {scrollToFirst: false});
        if (dfd) dfd.resolve();
      }
    } else {
      this.validZipCode = true;
      if (dfd) dfd.resolve();
    }
  },

  checkZipCodeValid: function (zipCode, dfd) {
    return UC.net.get(this.routes.validZipCode + zipCode, function (data) {
      this.onCheckZipCodeValid(data, dfd);
    }.bind(this));
  },

  onCheckZipCodeValid: function (data, dfd) {
    UC.utils.clearValidationErrors('row-zipcode');
    this.validZipCode = true;

    this.address = {
      ZipCode: data.zip.ZipCode,
      City: data.zip.CityName,
      State: data.zip.StateAbbr,
      CountryCode: 'USA'
    }

    if (dfd) dfd.resolve();
  },

  getAddress: function() {
    return this.address;
  },

  validate: function(dfd) {
    this.address = {};

    if (this.$panelZipCodeAddress.is(':visible')) {
      this.onTxtZipCodeBlur(null, dfd);
    } else {
      this.address.CountryCode = this.$country.val();
      this.address.City = this.$city.val().trim();
      var errors = [];

      if (!this.address.City) {
        errors.push({
          className: 'form-group', 
          element: this.$city, 
          text: 'Please enter a city'
        });
      }

      if (!this.address.CountryCode || this.address.CountryCode === '##') {
        errors.push({
          className: 'form-group', 
          element: this.$country, 
          text: 'Please enter a country'
        });
      }

      UC.utils.showValidationErrors(errors);
      if (errors.length > 0) {
        dfd.reject();
      } else {
        dfd.resolve();
      }
    }
  }

});
