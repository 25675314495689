!function() {

  UC.utils.initTransparentNav = function(el, options) {
    options = options || {};

    var $body = $('body');
    var $nav = $(el);
    var $paj = $nav.find('#main_header_link');
    var nav_height = $nav.outerHeight();

    var h1_position = 100;
    var $hero_h1 = $('#hero h1');

    if ($hero_h1.length > 0) {
      h1_position = $hero_h1.offset().top;
    }

    var cta_position = 100;
    var $paj_hero = $('#post-job-hero');

    if ($paj_hero.length > 0) {
      cta_position = $paj_hero.offset().top;
    }

    $(window).scroll(function() {
      var scroll_top = $(this).scrollTop();

      if (scroll_top > h1_position - nav_height) {
        $nav.removeClass('navbar-transparent transparent-scroll-zone');
      } else {
        $nav.addClass('transparent-scroll-zone');

        if (!$body.hasClass('searching')) {
          $nav.addClass('navbar-transparent');
        }
      }

      if (scroll_top > cta_position) {
        $paj.addClass('active marg-x-10');
      } else {
        $paj.removeClass('active marg-x-10');
      }
    }).scroll();
  };
}();
