!function() {

  UC.views.UserDocumentsRow = Backbone.View.extend({

    events: {
      'click': 'triggerFancyCheck',
      'click .document-rename': 'showRename',
      'blur .document-rename-text': 'hideRename',
      'keydown .document-rename-text': 'hideRename',
      'change .document-rename-text': 'saveRename',
      'change .bulk-check': 'toggleRowHighlight'
    },

    initialize: function() {
      this.$check = this.$el.find('.bulk-check').fancyCheck();
      this.$el.find('.popdown').popdown();
      this.documentId = this.$el.data('id');
    },

    triggerFancyCheck: function(e) {
      if (e.target.tagName == 'TD') {
        if (this.$check.hasClass('checked')) {
          // uncheck
          this.$check.check(false);
        } else {
          // check
          this.$check.check(true);
        }
        this.toggleRowHighlight();
      }
    },

    toggleRowHighlight: function() {
      if (this.$el.find('.bulk-check').hasClass('checked')) {
        this.$el.addClass('active');
      } else {
        this.$el.removeClass('active');
      }
    },

    isChecked: function() {
      return this.$el.find('.bulk-check').hasClass('checked');
    },

    showRename: function(e) {
      e.preventDefault();
      this.$link = this.$el.find('.document-view:visible');
      this.$text = this.$link.siblings('.document-rename-text');
      var period = this.$text.val().lastIndexOf('.');
      
      this.$link.hide();
      this.$text.width(this.$link.outerWidth()).show().select();
      if (period != -1) {
        // highlight filename sans extension
        this.$text[0].setSelectionRange(0, period);
      }
    },

    hideRename: function(e) {
      var esc   = e.type === 'keydown' && e.keyCode == 27;
      var enter = e.type === 'keydown' && e.keyCode == 13;

      if (esc || enter || e.type !== 'keydown') {
        e.preventDefault();
        if (esc) {
          // revert back to original
          this.$text.val(this.$link.text());
        } else {
          this.$link.text(this.$text.val());
        }
        this.$link.show();
        this.$text.hide();
      }
    },

    saveRename: function() {
      // we want to update the current document only, not the master (child)
      var path = '{0}/{1}'.format(UC.constants.user_documents_path, this.$el.data('child'));

      UC.net.put(path, {
        FileName: this.$text.val()
      }, function(err, message) {
        if (err) {
          UC.utils.showNotification(err, UC.constants.results.failure);
        }
      })
      .success(function() {
        this.$link = null;
        this.$text = null;
      });
    },

    updateVisibilityText: function(open) {
      this.$el.find('.doc-visibility').html(open === 'true' ? 'Open' : 'Request Only');
    }
  });

}();
