var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n\n    <div class=\"modal-header\">\n      <h4 class=\"modal-title\">Cancel Your Call</h4>\n    </div>\n\n    <div class=\"modal-body pad-b-15\">\n      <p>\n        Are you sure you want to cancel this call? This will send an email notification to the other party that the call\n        has been canceled.\n      </p>\n\n      <form action=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"formPath") || (depth0 != null ? lookupProperty(depth0,"formPath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"formPath","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":32}}}) : helper)))
    + "\" method=\"POST\">\n        <label for=\"message\">Write a message (optional)</label>\n        <textarea name=\"message\" class=\"form-control\" rows=\"4\"></textarea>\n\n        <div class=\"text-right marg-t-15\">\n          <button id=\"cancel-sc-cancel-btn\" class=\"btn btn-grey-10 marg-r-10\" data-dismiss=\"modal\">\n            No, keep it scheduled\n          </button>\n\n          <button type=\"submit\" class=\"btn btn-primary\" id=\"submit-sc-cancel\">\n            Yes, cancel the call\n          </button>\n        </div>\n      </form>\n    </div>\n  </div>\n</div>\n";
},"useData":true});