import { loadFileStackScript, pickFileAndStore } from '../utils/filestack'

!function() {

  var defaults = {
    maxFiles: 5,
    maxSize: 10000000,
    onInit: function() {},
    onUploadComplete: function() {},
    createPath: '/documents',
    skipPathPrefix: false,
    upload_path: null,
    userId: null,
    jobId: null
  };

  var filepicker_loading = false;
  var filepicker_dfd = $.Deferred();

  var Upload = UC.components.Upload = function(options) {
    this.options = $.fn.extend({}, defaults, options);
    this.uploading = null;
    this.upload_path = this.options.upload_path || '/';
    this.source_type = this.options.source_type;

    // default to UC objects, but allow overriding
    this.user_id = (UC.user && !options.userId) ? UC.user.id : options.userId ;
    this.job_id = (UC.job && !options.jobId) ? UC.job.id : options.jobId ;
    this.attorney_id = options.attorneyId;

    if (!this.options.skipPathPrefix) {

      if (this.user_id) {
        this.upload_path += 'user_{0}/'.format(this.user_id);
      }

      if (this.job_id) {
        this.upload_path += 'job_{0}/'.format(this.job_id);
      }
    }

    this.fileStackApiKey = options.fileStackApiKey || UC.constants.fileStackApiKey;

    if (!filepicker_loading) {
      filepicker_loading = true;
      // load in the filepicker JS!!
      loadFileStackScript(function () {
        // resolve our stuff please
        filepicker_dfd.resolve();
      });
    }

    if (window.filestack) {
      this.options.onInit(this);
    } else {
      $.when(filepicker_dfd).done(function() {
        this.options.onInit(this);
      }.bind(this));
    }
  };

  Upload.prototype = {

    constructor: Upload,

    makeDropPane: function($el, options) {
      if (!window.filestack) {
        console.log('filestack was not initialized yet.')
        return;
      }

      var last_enter;
      this.$el = $el;
      options = options || {};

      $('body').on('drop dragover dragleave', function(e){
        e.preventDefault();
      });

      $el.on('dragenter', function(e) {
        if (this.uploading && this.uploading.state() == 'pending') {
          return false;
        }
        last_enter = e.target;
        $el.addClass('dragging');
      }.bind(this));

      $el.on('dragleave', function(e) {
        if (last_enter === e.target) {
          $el.removeClass('dragging');
        }
      }.bind(this));

      filestack
          .init(this.fileStackApiKey)
          .on('upload.error', function (filestackError) {
            if (typeof options.onError === 'function') {
              options.onError(filestackError.type, filestackError.message);
            } else {
              this.error(filestackError.type, filestackError.message);
            }
          })
          .picker({
            maxSize: this.options.maxSize,
            maxFiles: this.options.maxFiles,
            storeTo: {
              location: 'S3',
              path: this.upload_path,
            },
            container: $el[0],
            displayMode: 'dropPane',
            dropPane: {
              overlay: false,
              showProgress: false,
              customText: 'Drag and Drop Files',
              onSuccess: function (files) {
                if (files && files.length > 0) {
                  this.process(files);
                }
              }.bind(this),
              onError: function (_files) {
                $el.removeClass('dragging');
              },
              onDrop: function (files) {
                $el.removeClass('dragging');
                $el.addClass('uploading');
                this.uploading = $.Deferred();
                if (typeof options.onStart === 'function') {
                  options.onStart(files);
                }
              }.bind(this),
              onProgress: options.onProgress,
            },
          }).open()
    },

    openFilePicker: function(options) {
      pickFileAndStore({
        fileStackApiKey: this.fileStackApiKey,
        pickerOptions: $.extend({
          fromSources: ['local_file_system', 'box', 'dropbox', 'googledrive', 'onedrive'],
          maxSize: this.options.maxSize,
          maxFiles: this.options.maxFiles,
          accept: this.options.accept,
          onUploadDone: function (res) {
            if (res.filesUploaded && res.filesUploaded.length > 0) {
              this.uploading = $.Deferred();
              this.process(res.filesUploaded);
            }
          }.bind(this)
        }, options),
        storeOptions: {
          location: 's3',
          path: this.upload_path
        }
      })
    },

    process: function(files) {

      if (typeof this.options.beforeProcess === 'function') {
        this.options.beforeProcess();
      }

      var req = UC.net.post(this.options.createPath, {
        files: files,
        job_id: this.job_id,
        attorney_id: this.attorney_id,
        source_type: this.source_type
      }, function(response) {
        this.options.onUploadComplete(null, response.documents);

        if (this.uploading) {
          this.uploading.resolve();
        }
      }.bind(this)).fail(function(xhr) {
        if (xhr.responseJSON) {
          this.options.onUploadComplete(xhr.responseJSON.message);
        } else {
          this.options.onUploadComplete('There was an error during the upload. Please retry a little later');
        }

        if (this.uploading) {
          this.uploading.resolve();
        }
      }.bind(this));

      if (typeof this.options.processFailureCallback === 'function') {
        req.fail( function(res, err, message) {
          if (res.responseJSON && typeof res.responseJSON.message === 'string') {
            message = res.responseJSON.message;
          }
          this.options.processFailureCallback(message);
        }.bind(this));
      }
    },

    error: function(type, message) {

      if (type === 'WrongSize') {
        var mb = (this.options.maxSize / 1024 / 1024).toFixed(0);
        message = 'File too large. Must be less than {0} Mb'.format(mb);
      }

      UC.utils.showNotification(message, UC.constants.results.failure);
    },

    destroy: function(id, callback) {
      UC.net.destroy('/documents/{0}'.format(id), null, function(err, message) {
        if (typeof callback === 'function') callback(err, message);
      }.bind(this));
    }
  };
}();
