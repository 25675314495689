$.fn.truncateToggle = function(options) {
  var defaults = {
    truncateLength: 200,
    moreText: 'Read more',
    lessText: 'Show less'
  };

  return this.each(function() {
    var opt = $.fn.extend({}, defaults, options);
    var $this = $(this);
    var text = $this.text();
    if ($this.attr('data-truncate-length')) {
      opt.truncateLength = $this.attr('data-truncate-length');
    }

    if (text.length <= opt.truncateLength) return;

    var html = text.slice(0, opt.truncateLength) + "<span class='shown-min'>... </span>" +
      "<a href='#' class='shown-min'>" + opt.moreText + "</a><span class='shown-max' style='display:none;'>" +
      text.slice(opt.truncateLength, text.length) + "<a href='#' class='shown-max'>" +
      opt.lessText + "</a></span>";

    $this.html(html);

    $this.find('a.shown-min').click(function(e) {
      e.preventDefault();
      $this.find('.shown-min').hide();
      $this.find('.shown-max').show();
    });

    $this.find('a.shown-max').click(function(e) {
      e.preventDefault();
      $this.find('.shown-min').show();
      $this.find('.shown-max').hide();
    });

  });
};
