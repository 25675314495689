UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.AddTimesheetLinesItemsInvoiceModal = Backbone.View.extend({

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/add_timesheet_line_items_invoice_modal"),

  tagName: 'div',

  className: 'modal fade',

  events: {
    "hidden.bs.modal" : "deleteElement",
    "click #add-ts-line-to-inv" : "addTsLineToInv"
  },

  initialize: function (opts) {

    this.timesheetTableView = new UC.timesheetComponent.TimesheetTableView({
      collection: this.collection,
      readOnlyOverride: true,
      modelFilters: {
        notAddedToInvoice: true
      }
    });

  },

  render: function() {
    this.$el.html(this.template(this));
    this.timesheetTableView.render();
    this.timesheetTableView.delegateEvents();
    this.$('#timesheet-line-item-container').html(this.timesheetTableView.$el);
    this.delegateEvents();
    this.$el.modal();
  },

  timesheetsAvailableToAdd: function() {
    return this.collection.modelsNotAddedToInvoice().length > 0;
  },

  deleteElement: function() {
    this.$el.remove();
  },

  addTsLineToInv: function() {
    this.$el.modal('hide');
    var addedModels = this.collection.selectedLineItemModels();
    var modelIds = [];

    for (var i = 0, len = addedModels.length; i < len; i++) {
      var model = addedModels[i];
      model.addToInvoice();
      modelIds.push(model.get('_id'));
    }

    UC.utils.recordKissEvent('timesheet_line_items_imported_to_invoice', {
      timesheet_lines: modelIds
    });
  }


});
