$.fn.superSmartSearch = function(options) {
  var defaults = {
    delay: 250,
    cache: true,
    minimumInputLength: 1,
    minimumResultsForSearch: 20,
    params: {},
    width: 'resolve',
    allowClear: false,
    placeholder: '', // select2 actually sucks
    tags: false, // enables user to enter custom option
    modal: false
  };

  if (!options.url) {
    throw 'You must provide a request url';
  }

  return this.each(function() {
    var opt = $.fn.extend({}, defaults, options);

    $(this).select2({
      dropdownParent: opt.modal,
      width: opt.width,
      allowClear: opt.allowClear,
      minimumInputLength: opt.minimumInputLength,
      placeholder: opt.placeholder,
      tags: opt.tags,
      minimumResultsForSearch: opt.minimumResultsForSearch,
      createTag: function(params) {
        return {
          id: params.term,
          text: params.term,
          newOption: true
        }
      },
      templateResult: function (data) {
        var $result = $("<span></span>");

        $result.text(data.text);

        if (data.newOption) {
          $result.append(" <em>(new)</em>");
        }

        return $result;
      },
      ajax: {
        url: opt.url,
        delay: opt.delay,
        cache: opt.cache,
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: function (params) {
          data = opt.params || {};
          data.q = params.term;

          return JSON.stringify(data);
        },
        processResults: function (result, params) {
          var results;

          if (result.grouped_results) {
            results = _.map(result.data, function(group) {
              return {
                text: group.text,
                children: _.map(group.children, function(item) {
                  return {
                    id: item.id,
                    text: item.label
                  }
                })
              }
            })
          } else {
            results = _.map(result.data, function(item) {
              return {
                id: item.id,
                text: item.label
              }
            });
          }

          return {
            results: results,
            pagination: {
              more: false
            }
          };
        }
      }
    });
  });
};
