import { default as MultiFactorRequestFrom } from './_request_form'

export function validateOTPCodeFormat(input) {
  UC.utils.clearValidationErrors('form-group');

  const errors = [];
  const verificationCode = input.val();

  if (!verificationCode) {
    errors.push({
      className: 'form-group',
      element: input,
      text: 'Verification code is required'
    });
    console.warn('Verification code is required')
  } else if (verificationCode.length !== 6) {
    errors.push({
      className: 'form-group',
      element: input,
      text: 'Must be a six-digits code'
    });
    console.warn('Must be a six-digits code')
  }

  UC.utils.showValidationErrors(errors);
  return errors.length === 0;
}

export function validateRecoveryCodeFormat(input) {
  UC.utils.clearValidationErrors('form-group');

  const errors = [];
  const verificationCode = input.val();

  if (!verificationCode) {
    errors.push({
      className: 'form-group',
      element: input,
      text: 'Recovery code is required'
    });
    console.warn('Recovery code is required')
  } else if (!verificationCode.match(/[a-z0-9]{5}-[a-z0-9]{5}/i)) {
    errors.push({
      className: 'form-group',
      element: input,
      text: 'Invalid recovery code'
    });
    console.warn('Invalid recovery code')
  }

  UC.utils.showValidationErrors(errors);
  return errors.length === 0;
}

export function renderTwoFactorRequestModal(modalHtml, successCallback) {
  if (!modalHtml) {
    return;
  }

  const $twoFactorRequestModal = $(modalHtml);
  $('body').append($twoFactorRequestModal);

  const toggleAuthForms = function(e) {
    e.preventDefault();

    $twoFactorRequestModal.find('.otp-code-form').toggleClass('hidden');
    $twoFactorRequestModal.find('.recovery-code-form').toggleClass('hidden');
  }

  const onSuccessVerification = function() {
    $twoFactorRequestModal.modal('hide');
    if (successCallback) {
      successCallback();
    }
  }

  new MultiFactorRequestFrom({
    el: $twoFactorRequestModal.find('.otp-code-form')[0],
    formId: 'two-factor-request-modal-form',
    formUrl: '/sessions/two-factor',
    successCallback: onSuccessVerification.bind(this)
  });

  new MultiFactorRequestFrom({
    el: $twoFactorRequestModal.find('.recovery-code-form')[0],
    formId: 'two-factor-recovery-modal-form',
    codeInputSelector: 'input#recovery-code',
    submitButtonSelector: '#recover-mfa-button',
    recovery: true,
    requestParams: {
      recover: true
    },
    successCallback: onSuccessVerification.bind(this)
  });

  $('.modal.in').modal('hide'); // hide any existing modal shown

  $twoFactorRequestModal.find('#use-recovery-code-link').on('click', toggleAuthForms);
  $twoFactorRequestModal.find('#use-otp-code-link').on('click', toggleAuthForms);
  $twoFactorRequestModal.modal('show');
}