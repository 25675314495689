// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or vendor/assets/javascripts of plugins, if any, can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
import 'jquery-ujs'
import 'backbone'
import 'bootstrap'
import 'moment'
import 'jquery-serializejson'
import 'js-cookie'
import 'handlebars/dist/handlebars.runtime'
import 'bootstrap-multiselect'

import './polyfills'
import './upcounsel'
import './utils'
import './components'

import './views/public/jobs/manage_cog'
import './views/public/jobs/modal_change_status'

// Fixes bootstrap vs. jquery-ui button conflict
// https://github.com/twbs/bootstrap/issues/6094
$.fn.button.noConflict();
