!function() {
  UC.modals.UserDocumentsModalShare = Backbone.View.extend({

      events: {
          'submit #formDocumentPermissions': 'save',
          'change .user-permissions': 'toggleMessageRow'
      },

      elements: {
          'formDocumentPermissions': null,
          'btnSavePermissions': null,
          'rowShareMessage': null,
          'spanShareMessageRecipients': null
      },

      initialize: function(options) {
        this.parent_view = (options || {}).parent_view;
        this.components = {};
      },

      render: function(options) {
          this.perms = {};

          if (options && options.document_ids) {
            this.document_ids = options.document_ids;
            this.base_path = '{0}/{1}'.format(UC.constants.user_documents_path,
                this.document_ids.join());
          }

          this.renderModal(options);
      },

      renderModal: function(options) {
          var options = options || {};

          UC.net.get(this.base_path + '/share', {
            job_id: UC.constants.jobId,
            open: options.open // forces visibility after update
          }, $.proxy(function(modal_html) {
            this.this_sucker_is_dirty = false;
            UC.utils.setSelectors(this.elements);
            this.components.btnSavePermissionsLoader =
              this.elements.btnSavePermissions.buttonLoader();
            this.$el.find('.user-permissions').change();
            this.onPermissionsUpdated = options.onPermissionsUpdated;

            this.shareModal = new UC.partials.modalV9 ({
              modalTitle: 'Share Settings',
              modalSubTitle: {
                text: 'Learn More',
                className: 'restart-share-tutorial link'
              },
              modalContent: modal_html,
              id: 'modalDocumentShare',
              renderCallback: this.shareModalCallback.bind(this)
            })
          }.bind(this), this));
      },

      shareModalCallback: function() {
        $('#formDocumentPermissions').on('submit', this.save.bind(this));
        $('#formDocumentVisibility').on('change', this.updateVisibility.bind(this));
        $('#aCancelLink').on('click', this.close.bind(this));
        $('.user-permissions').on('change', this.toggleMessageRow.bind(this));
        $('.restart-share-tutorial').on('click', this.runTutorial.bind(this));
        $("[data-toggle='tooltip']").tooltip();
        if ($('.user-permissions').length === 0) {
          $('.restart-share-tutorial').addClass('hidden');
        }
      },

      runTutorial: function() {
          var container = this.shareModal.$el.find('#js-vis-share'),
              stepCount = 1,
              steps = '';

          if (UC.user.type === "client") {
              steps = [
                  {
                      element: container.find('.share-visibility').eq(0),
                      intro: "Change your Document Visibility Settings here to choose how to manage access to your documents."
                  },
                  {
                      element: container.find('.share-user').eq(0),
                      intro: "Attorneys who request access or submit proposals will appear here."
                  },
                  {
                      element: container.find('.share-access').eq(0),
                      intro: "Choose what level of access you want to give people. You can choose 'View', 'View &amp; Download' and 'Full Control.'"
                  },
                  {
                      element: container.find('.share-message:visible'),
                      intro: "Include an optional message when you give access to other parties."
                  }
              ];
          } else if (UC.user.type === "attorney") {
              steps = [
                  {
                      element: container.find('.share-visibility').eq(0),
                      intro: "Change your Document Visibility Settings here to choose how to manage access to your documents."
                  },
                  {
                      element: container.find('.share-user').eq(0),
                      intro: "Users who request access will appear here."
                  },
                  {
                      element: container.find('.share-access').eq(0),
                      intro: "Choose what level of access you want to give people. You can choose 'View', 'View &amp; Download' and 'Full Control.'"
                  },
                  {
                      element: container.find('.share-message:visible'),
                      intro: "Include an optional message when you give access to other parties."
                  }
              ];
          }

          for (var i = 0, len = steps.length; i < len; i++) {
              if (steps[i].element.length > 0) {
                  steps[i].element.attr({
                      'data-step': stepCount,
                      'data-intro': steps[i].intro,
                      'data-position': steps[i].position ? steps[i].position : ''
                  });
                  steps[i].element.addClass('tutorial');
                  stepCount++;
              }
          }

          introJs().start().onexit(function() {
              $('.tutorial').removeAttr('data-step').removeAttr('data-intro').removeClass('tutorial');
          });
      },

      getOpenChecked: function() {
          return this.shareModal.$el.find('input[name=Open]:checked').val();
      },

      buildPermissionsData: function() {
          var permissions = { Open: this.getOpenChecked(), Users: [] };

          permissions.Message = $('#txtShareMessage').val();

          $('.row-permissions.form-group').each(function() {
              var $el = $(this);
              var p = parseInt($el.find('.user-permissions').val(), 10);

              if (p >= 0) {
                permissions.Users.push({
                    UserId: $el.data('user-id'),
                    View: (p > 0),
                    Download: (p > 1),
                    Replace: (p > 2)
                });
              }
          });

          return permissions;
      },

      save: function(e) {
          e.preventDefault();

          if (!this.this_sucker_is_dirty) {
              this.shareModal.$el.modal('hide');
              return false;
          }

          var data = { permissions: this.buildPermissionsData() };

          this.components.btnSavePermissionsLoader.showLoader();

          if (this.document_ids.length === 1) {
            UC.utils.recordKissEvent('User Document Share', {
              'user_type' : UC.user.type ,'document_id' : this.document_ids[0]
            });
          }

          if (UC.job) {
            data['job_id'] = UC.job.id;
          }

          UC.net.put(this.base_path + '/permissions', data, function(err, message) {
            var $notification = $('.js-success-notification');
            $notification.find('.flash-message').text(message);
            $notification.removeClass('hidden');
            this.shareModal.$el.modal('hide');
            if (typeof this.onPermissionsUpdated === 'function') {
                this.onPermissionsUpdated(this.document_ids, this.getOpenChecked());
            }
          }.bind(this)).fail(function(res) {

            UC.closeTheLoop.handleFailure(res, true);

          }.bind(this)).always(function(res) {

              this.components.btnSavePermissionsLoader.hideLoader();

          }.bind(this));
      },

      updateVisibility: function(e) {
        e.preventDefault();

        var open = this.getOpenChecked();
        var $notification = $('.js-save-notification');
        if (this.document_ids.length === 1) {
          UC.utils.recordKissEvent('User Document  - Update Permissions', {
            'document_id' : this.document_ids[0], 'permissions' : open
          });
        }
        UC.net.put(this.base_path + '/permissions', {
          permissions: {
            Open: open
          }
        })
        .done(function(data, textStatus, jqXHR) {
          $notification.replaceWith('<span class="text-success">{0}</span>'.format(data.message));
            if (typeof this.onPermissionsUpdated === 'function') {
                this.onPermissionsUpdated(this.document_ids, open);
            }
        }.bind(this))
        .fail(function(jqXHR, textStatus, errorThrown) {
          $notification.replaceWith('<span class="text-danger">{0}</span>'.format(errorThrown));
        });
      },

      dirty: function() {
          this.this_sucker_is_dirty = true;
          $('#formDocumentPermissions').find('#aCancelLink').removeClass('hidden');
      },

      toggleMessageRow: function(e) {
          var $el = $(e.target);
          var $row = $el.closest('.row-permissions');
          var user_name = $row.find('.user-name').text();
          var user_id = $row.data('user-id');
          var $requestOnlyRadio = $('#formDocumentVisibility').find('#Open_false');
          if (!$requestOnlyRadio.prop('checked')) {
              $requestOnlyRadio.prop("checked", true);
          }

          var original = parseInt($el.data('original'), 10);
          var selected = parseInt($el.val(), 10);

          if (original != selected) {
              this.dirty();
          }

          if ((!original || original == 0) && selected >= 1) {
              this.perms[user_id] = user_name;
          } else {
              delete this.perms[user_id];
          }

          var recipients = [];
          for (var user_id in this.perms) {
              recipients.push(this.perms[user_id].trim());
          }

          if (recipients.length > 0) {
              var string = "";

              if (recipients.length <= 2) {
                  string = recipients.join(' & ');
              } else {
                  var beginning = recipients.slice(0, recipients.length - 1);
                  string = beginning.join(', ');
                  string += " & " + recipients[recipients.length - 1];
              }
              this.elements.spanShareMessageRecipients.text(string);
              UC.utils.showComponent(this.elements.rowShareMessage);
          } else {
              UC.utils.hideComponent(this.elements.rowShareMessage);
          }

          this.$el.ucModal('adjust');
      },

      close: function() {
          this.shareModal.$el.modal('hide');
      }
  });
}();
