UC.views.MultiFactorAuthRecoveryCodes = Backbone.View.extend({
  events: {
    'click #generate-new-recovery-codes': 'generateNewRecoveryCodes',
  },

  initialize: function() {
    new UC.partials.SessionSudoForm({el: this.$el})
  },

  generateNewRecoveryCodes: function(e) {
    e.preventDefault();

    UC.components.confirmModal({
      title: 'Confirm',
      text: 'When you generate new recovery codes, <b>your old codes won\'t work anymore.</b><br> Are you sure?',
      callback: function (confirmed) {
        if (confirmed) {
          UC.net.put('/account/mfa/recovery-codes', null, function (response) {
            location.href = response.url;
          });
        }
      },
      yesText: 'Yes, generate new codes',
      noText: 'Cancel'
    });
  },
});
