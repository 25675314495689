!function() {

  UC.views.LegalAreaPages = Backbone.View.extend({

    initialize: function() {
      this.initNavScrollSpyStuff();
      this.initTabScrolling();
    },

    initNavScrollSpyStuff: function() {
      var $body = $('body');
      var cta_btn = $(".main-cta-btn").length > 0 ? $(".main-cta-btn").offset().top : 150;
      var nav = $('.section-nav');
      var nav_wrap = nav.closest('.section-nav-wrap');
      var sticky = nav.hasClass('sticky');
      var footer = $('.sticky-footer');

      $(window).scroll(function() {
        var scrollTop = $(document).scrollTop();

        if (scrollTop >= cta_btn) {
          footer.addClass('active');
        } else {
          footer.removeClass('active');
        }

        if (!UC.utils.isMobileViewPort() && scrollTop >= nav_wrap.offset().top) {
          nav.addClass('fixed');

          $('.nav-section').each(function(i) {

            if ($(this).position().top <= scrollTop + 100) {
              $('.section-nav-tab.active').removeClass('active');
              $('.section-nav-tab').eq(i).addClass('active');
            }
          });
        } else {
          nav.removeClass('fixed');
          $('.section-nav-tab.active').removeClass('active');
          $('.section-nav-tab:first').addClass('active');
        }
      }).scroll();
    },

    initTabScrolling: function() {

      $('.section-nav-tab').click(function(e) {
        var $this = $(this),
          id = $this.attr('href') || $this.find('a').attr('href'),
          hasHash = (id && id.substr(0,1) == '#');

        if (hasHash) {
          e.preventDefault();
          UC.utils.scrollToElement($(id), { offset: 100 });
        }
      });
    }
  });
}();
