UC.components.CitySmartSearch = Backbone.View.extend({
  events: {
    'change #seo_legal_subject_city_page_State' : 'clearCity'
  },

  initialize: function(config) {
    this.config = config;

    this.$('#citySmartSearch').smartSearch({
      url: ' /radmin/admin/seo_mother_pages/city_smart_search',
      data: this.currentState.bind(this),
      select: this.successCallback.bind(this)
    });
  },

  clearCity: function() {
    this.$('#citySmartSearch').val('');
    this.$(this.config.cityIdSelector).val('');
  },

  currentState: function() {
    { State: this.$(this.config.stateSelector).val() }
  },

  successCallback: function(_, data) {
    this.$(this.config.cityIdSelector).val(data.item.id);
  }
});
