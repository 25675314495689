UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.TimesheetTypesDropdownView = Backbone.View.extend({
  tagName: 'span',

  template: UC.views.resolveHandlebarsTemplate('components/timesheets/timesheet_types_dropdown'),

  render: function () {
    var options = { dropdownAutoWidth: true, width: '100%' };

    this.$el.html(this.template({typeSelectOptions: this.typeSelectOptions()}));
    if (this.newLineItem()) {
      options.placeholder = 'Select Type';
    }

    this.$('select').select2(options);
  },

  newLineItem: function () {
    return !this.model.get('_id');
  },

  typeSelectOptions: function () {
    types = this.model.types;
    var output = '';

    for(var i = 0; i < types.length; i++) {
      if (this.model.attributes.Type === types[i].value) {
        output += '<option value={0} selected>{1}</option>'.format(types[i].value, types[i].label)
      } else {
        output += '<option value={0}>{1}</option>'.format(types[i].value, types[i].label)
      }
    }

    return output;
  }
});
