var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <a class=\"new-style btn btn-md btn-primary-outline text-bold enabled\" id=\"create-ts-invoice-btn\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"newInvoiceUrlForTsLines") || (depth0 != null ? lookupProperty(depth0,"newInvoiceUrlForTsLines") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"newInvoiceUrlForTsLines","hash":{},"data":data,"loc":{"start":{"line":2,"column":105},"end":{"line":2,"column":132}}}) : helper)))
    + "\">\n    Create an invoice for "
    + alias4(((helper = (helper = lookupProperty(helpers,"formattedSelectedModelFees") || (depth0 != null ? lookupProperty(depth0,"formattedSelectedModelFees") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formattedSelectedModelFees","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":56}}}) : helper)))
    + "\n  </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <a class=\"new-style btn btn-md btn-grey-BG text-bold inactive\" id=\"create-ts-invoice-btn\" href=\"#\"\n     data-toggle=\"tooltip\" data-placement=\"top\" title=\"You must select timesheet entries to invoice\">\n    Create an invoice\n  </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"anyModelsSelected") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":10,"column":7}}})) != null ? stack1 : "");
},"useData":true});