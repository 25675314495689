$.fn.popdown = function (options) {
  var that = this,
      opt = $.fn.extend({}, options);

  $('body, .notification-icon-header').click(function (e) {
    var element = $(e.currentTarget);
    if (!element.hasClass('popdown-holder')) {
      hideAll(e);
    }
  });

  function hideAll(e) {
    that.addClass('hidden');
  }

  function hidePopdown(element) {
    return element.addClass('hidden');
  }

  function showPopdown(element) {
    return element.removeClass('hidden');
  }

  function toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    var currentElement = $(e.currentTarget).parent(),
        action = currentElement.hasClass('hidden') ? showPopdown : hidePopdown;

    hideAll(e);
    action(currentElement);

    if (opt.onToggleCallback) {
      opt.onToggleCallback(e);
    }

    return that;
  }

  $.fn.togglePopdown = function (element) {
    var action = element.hasClass('hidden') ? showPopdown : hidePopdown;

    hideAll();
    action(element);
  };

  // only init popdown once per element
  if (!that.data('popdown')) {
    that.data('popdown', this);
    return that.find('.popdown-btn').click(toggle);
  }
};
