export function injectEzoicAdPlaceholders(contentSelector, maxPlaceholders, firstPlaceholderId) {
    const $content = $(contentSelector);
    const contentHeight = $content.height();
    const screenHeight = Math.max($(window).height(), 1200);
    const maxAds = Math.min(maxPlaceholders, Math.trunc(contentHeight / screenHeight));
    if (maxAds > 0) {
        const contentTop = $content.offset().top;
        const offset = contentHeight / (maxAds + 1);
        let currentOffset = offset;
        let adBlockId = firstPlaceholderId;
        let putBlocks = 0;

        $(`${contentSelector} p`).each(function () {
            if (putBlocks >= maxAds) {
                return;
            }
            const paragraph = $(this);
            if (paragraph.closest('table').length) {
                // skip the ad - do not inject into tables
                currentOffset = currentOffset + offset;
                putBlocks = putBlocks + 1;
                return
            }

            if (paragraph.offset().top - contentTop >= currentOffset) {
                paragraph.prepend(`<div id="ezoic-pub-ad-placeholder-${adBlockId}" data-ezoic-ad-placeholder-id="${adBlockId}"></div>`);
                currentOffset = paragraph.offset().top + offset;
                adBlockId = adBlockId + 1;
                putBlocks = putBlocks + 1;
            }
        });
    }
}

export function findAndActivateEzoicAdPlaceholders() {
    const ezoicPlaceholderIds = _.map($('div[data-ezoic-ad-placeholder-id]'), function (div) {
        return $(div).data('ezoic-ad-placeholder-id')
    })
    if (ezoicPlaceholderIds.length) {
        window.ezstandalone = window.ezstandalone || {};
        ezstandalone.cmd = ezstandalone.cmd || [];
        ezstandalone.cmd.push(function () {
            ezstandalone.enableConsent();
            ezstandalone.define(ezoicPlaceholderIds);
            ezstandalone.enable();
            ezstandalone.display();
        })
    }
}