!function() {

  UC.views.SearchResult = Backbone.View.extend({

    template: UC.views.resolveHandlebarsTemplate('public/search/result'),

    initialize: function(options) {
      this.result = options.result;
    },

    render: function() {
      this.setElement(this.template(this.result));
      return this;
    }
  });

}();
