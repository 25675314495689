!function() {

  UC.partials.JobsModalRequestInvite = Backbone.View.extend({

    className: 'modal',

    template: UC.views.resolveHandlebarsTemplate('public/jobs/modal_request_invite'),

    events: {
      'submit form': 'inviteAttorney',
      'hidden.bs.modal': 'remove'
    },

    routes: {
      invite_job_bids: '/jobs/{0}/proposals/invite'
    },

    initialize: function(options) {
      options = options || {};

      this.attorney_id = options.attorney_id;
      this.attorney_first_name = options.attorney_first_name;
      this.callback = options.callback;
    },

    render: function() {

      this.$el.html(this.template({
        attorney_first_name: this.attorney_first_name,
        client_full_name: UC.user.name,
        job_title: UC.job.jobTitle
      })).modal();

      this.loader = this.$('#send_invite').buttonLoader();

      return this;
    },

    inviteAttorney: function(e) {
      e.preventDefault();

      var data = {
        attorney_id: this.attorney_id,
        message: this.$('#invite_message').val()
      };

      this.loader.showLoader();

      UC.net.post(this.routes.invite_job_bids.format(UC.job.id), data).done(function() {
        this.$el.modal('hide');

        if (typeof this.callback === 'function') {
          this.callback(data);
        }
      }.bind(this)).fail(function() {
        this.loader.hideLoader();
      }.bind(this));
    }
  });
}();
