var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalHours") || (depth0 != null ? lookupProperty(depth0,"totalHours") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalHours","hash":{},"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":11,"column":20}}}) : helper)))
    + " hour\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalHours") || (depth0 != null ? lookupProperty(depth0,"totalHours") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalHours","hash":{},"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":13,"column":20}}}) : helper)))
    + " hours\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"text-right text-bold text-uppercase\">\n  <div id=\"timesheet-total-header\" class=\"pad-b-5\">\n    Total\n  </div>\n</div>\n\n<div>\n  <div class=\"total-stat text-bold pad-t-5\">\n    <div class=\"fontello icon-hourly-rate text-green\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalHoursOne") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n\n  <div class=\"total-stat text-bold pad-t-5\">\n    <div class=\"fontello icon-invoice text-green\"></div>\n    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"formattedTotalFees") || (depth0 != null ? lookupProperty(depth0,"formattedTotalFees") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"formattedTotalFees","hash":{},"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":19,"column":26}}}) : helper)))
    + "\n  </div>\n</div>\n";
},"useData":true});