!function() {

  UC.modals.ScheduledCallsNew = Backbone.View.extend({

    className: 'modal fade',

    events: {
      'hidden.bs.modal': 'remove'
    },

    routes: {
      scheduled_calls_new: '/users/{0}/scheduled_calls/new'
    },

    initialize: function(options) {
      this.attorney_id = options.attorney_id;
      this.noInstantAvailability = options.noInstantAvailability;
      this.isPaidConsultationPacakge = options.isPaidConsultationPacakge;
      this.onRequest = options.onRequest || this.setButtonAsRequested.bind(this)
      this.jobId = options.jobId;

      return this;
    },

    render: function() {
      var path = this.routes.scheduled_calls_new.format(this.attorney_id);

      var data = {
        no_instant_availability: this.noInstantAvailability,
        is_paid_consultation_package: this.isPaidConsultationPacakge
      }

      UC.net.get(path, data)
        .done(function(res) {
          var scheduleCallModal = new UC.partials.modalV9({
            className: 'modal v9 modal-backport',
            modalContent: res.html,
            customModal: true,
          });

          new UC.partials.ScheduledCallsFormRequest({
            el: scheduleCallModal.el,
            onRequest: this.onRequest,
            removeAlternativeTimeOptions: this.removeAlternativeTimeOptions,
            jobId: this.jobId
          });
      }.bind(this));

      var event_data = {
        attorney_id: this.attorney_id,
        job_id: (this.jobId || UC.constants.jobId)
      };

      UC.utils.sendNesEvent('proposals_schedule_call_click', event_data);
    },

    setButtonAsRequested: function() {
      var $bid = $('#{0}'.format(this.attorney_id));

      this.$el.modal('hide');
      $bid.find('.jp-schedule-phone-call').addClass('hide');
      $bid.find('.jp-phone-call-scheduled').removeClass('hide');
    }
  });
}();
