import {findAndActivateEzoicAdPlaceholders, injectEzoicAdPlaceholders} from '../../../utils/ezoic_ads'
import '../../../components/table_of_contents'

UC.partials.ContentPagesShowArticle = Backbone.View.extend({

  stickyStart: 0,
  showStickySection: false,

  initialize: function () {
    UC.utils.recordKissEvent('viewed an article', {
      'article_id' : this.$el.find('#ArticleId').val(),
      'author_attorney_id': this.$el.find('#AuthorId').val(),
    });

    $('.article-body a').on('click propogateTracking', function(e) {
      UC.utils.handleGenericClick($(this), 'article-internal-link-clicked');
    });

    // TODO: Remove when Samsung fixes this issue - https://github.com/SamsungInternet/support/issues/56
    Function.prototype.ToString = function () { return this.toString(); }

    var that = this;
    var header = $('.document');

    this.initTocComponents();

    if($('.js-sticky-cta-test').length > 0) {
      this.showStickyFooter = true;
      this.optOutStickyFooter = false;
      if(UC.utils.isMobileViewPort()) {
        $('.js-cancel-sticky-footer').click(function() {
          this.hideStickyCtaFooter();
          this.optOutStickyFooter = true;
        }.bind(this));
      }
      UC.components.contentPage.setCtaBannerSize(header);
      $(document).scroll(function () {
        if(!that.optOutStickyFooter) {
          UC.components.contentPage.setCtaBannerSize(header);
        }
      });
    }

    var $stickySection = $('.js-sticky-section');

    if (this.showStickyFooter) {
      $stickySection.on('show.bs.dropdown', function () {
        that.hideStickyCtaFooter();
      }).on('hide.bs.dropdown', function () {
        if(!that.optOutStickyFooter) {
          UC.components.contentPage.setCtaBannerSize(header);
        }
      })

      $('#mobile-nav').on('mobile-nav-closed', function() {
        if(!that.optOutStickyFooter) {
          UC.components.contentPage.setCtaBannerSize(header);
        }
      })
    }
    const isMobile = UC && UC.browser && typeof UC.browser.isMobile === 'function' && UC.browser.isMobile();
    const $stickyHeader = $('.sticky-cta-header');
    const stickyHeaderHeight = Math.max($stickyHeader.length && !isMobile ? $stickyHeader.height() : 0, 0);

    if (stickyHeaderHeight) {
      $('.sticky-section').css('top', stickyHeaderHeight);
    }

    if (UC.constants.ezoicEnabled) {
      injectEzoicAdPlaceholders('.article-body', 5, 105);

      findAndActivateEzoicAdPlaceholders();
    }
  },

  hideStickyCtaFooter: function() {
    this.showStickyFooter = false;
    UC.components.contentPage.removeCtaStickyFooter();
  },

  initTocComponents: function() {
    if (!UC.constants.displayToc) {
      return;
    }

    new UC.components.TableOfContents({
      scope: '.article-body',
      affixTop: 65 /* $header-height-desktop */,
      affixBottom: function() {
        return document.getElementById("content").getBoundingClientRect().height - $('#articlePreview').height() - 270;
      },
      tocEnabledColumnClass: 'col-md-7',
      tocDisabledColumnClass: 'col-md-9'
    })
  }
});
