!function() {
  UC.utils.initIntroJs = function(steps) {

    for (var i = 0, len = steps.length; i < len; i++) {
      var step = steps[i];

      if (step.element.length > 0) {
        step.element.attr({
          'data-step': i + 1,
          'data-intro': steps[i].text,
          'data-position': steps[i].position
        });
        step.element.addClass('introjs');
      }
    }

    return introJs().start();
  };
}();

