UC.partials.JobsAttorneyNavigation = Backbone.View.extend({

  events: {
    'click .js-bookmark': 'toggleJobBookmark',
    'click .js-hide': 'toggleJobVisibility'
  },

  toggleJobBookmark: function(e) {
    var $target = $(e.currentTarget);
    var jobId = UC.job.id;
    var isBookmarked  = $target.hasClass('bookmarked');

    UC.utils.sendNesEvent('bookmark_job_clicked', {job_id: jobId, bookmark: !isBookmarked});
    UC.net.post('/job/bookmark/{0}'.format(jobId), { bookmark: !isBookmarked }, function() {
      if (isBookmarked) {
        $target.removeClass('bookmarked');
      } else {
        $target.addClass('bookmarked');
      }
    });
  },

  toggleJobVisibility: function(e) {
    var $target = $(e.currentTarget);
    var jobId = UC.job.id;
    var isHidden = $target.hasClass('js-hidden');
    var $tooltip = $target.find('.js-hide-title');
    var $icon = $target.find('.glyphicon');
    $tooltip.tooltip('hide');

    UC.net.post('/job/marketplace_visibility/{0}'.format(jobId), { visible: isHidden }, function() {
      if (isHidden) {
        $tooltip.attr('data-original-title', $tooltip.attr('data-tooltip-hide'));
        $icon.addClass('glyphicon-eye-close').removeClass('glyphicon-eye-open');
        $target.removeClass('js-hidden');
      } else {
        $tooltip.attr('data-original-title', $tooltip.attr('data-tooltip-show'));
        $icon.addClass('glyphicon-eye-open').removeClass('glyphicon-eye-close');
        $target.addClass('js-hidden');
        history.back();
      }
    });
  }
});
