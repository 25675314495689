UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.TimesheetLineItems = Backbone.Collection.extend({

  model: UC.timesheetComponent.TimesheetLineItem,

  url: '/timesheet_line_items',

  initialize: function(modelsArray, opts) {
    opts = opts || {};

    // if any of these attributes are set, it implies that these are default values for the collection that will be used
    // when fetching relevant line items and creating new ones
    this.clientId = opts.clientId;
    this.invoiceId = opts.invoiceId;
    this.jobId = opts.jobId;
    this.direct = opts.direct;
    this.invoiced = opts.invoiced;
    this.startDateFilter = null;
    this.endDateFilter = null;

    this.selectedLineItemsJobId = null;

    this.on('line-selected', this.lineItemSelected.bind(this));
    this.on('line-unselected', this.removeSelectedJobIdIfAppropriate.bind(this));
    this.on('remove', this.removeSelectedJobIdIfAppropriate.bind(this));
    this.on('dateFilterChange', this.fetchModels.bind(this));
    this.on('destroy', this.triggerEventForModelDestroySync.bind(this));

    // Typically we should fetch models based on filter options. Should make this flexible to initiate anywhere
    if (!modelsArray) this.fetchModels(opts.filterOptions);
  },

  fetchModels: function(filterOptions) {
    var filter = {};
    if (this.clientId) filter.client = this.clientId;
    if (this.invoiceId) filter.invoice = this.invoiceId;
    if (this.jobId) filter.job = this.jobId;
    if (this.direct) filter.direct = this.direct;
    if (this.invoiced) filter.invoiced = true;

    if (this.startDateFilter) filter.start_date = this.startDateFilter.format(UC.constants.momentDateFormats[0]);
    if (this.endDateFilter) filter.end_date = this.endDateFilter.format(UC.constants.momentDateFormats[0]);

    _.extend(filter, filterOptions);

    _.extend(filter, {include_job_details: this.needJobDropdownOptions()});

    UC.net.get(this.url, filter, null, {cache: false})
      .done(this.handleModelFetchResponse.bind(this));
  },

  needJobDropdownOptions: function() {
    // If there is a client id present for the collection and not an invoice or job id (or direct), it indicates the user
    // should be on the client timesheet page, where they can select jobs they've worked on with the client for ts lines
    return !!(this.clientId && !(this.invoiced || this.invoiceId || this.jobId || this.direct));
  },

  handleModelFetchResponse: function(response) {
    this.setShowIntroVisibility(response.line_items_created_by_user_count);

    if (response.invoices) this.invoicesCollection = new UC.timesheetComponent.TimesheetInvoices(response.invoices);
    if (response.jobs) this.jobsCollection = new UC.timesheetComponent.TimesheetJobs(response.jobs);

    this.reset(response.timesheet_line_items);
  },

  setShowIntroVisibility: function(userLineItemCount) {
    this.showIntro = userLineItemCount === 0;
  },

  sumOfModelHours: function(models) {
    var sum = _.reduce(models, function(total, m) {
      return total + (parseFloat(m.get('Hours')) || 0);
    }, 0);

    return UC.utils.handleFloatPrecision(sum, 2);
  },

  totalHours: function() {
    return this.sumOfModelHours(this.models);
  },

  totalHoursOne: function() {
    return this.totalHours() === 1;
  },

  sumOfModelFees: function(models) {
    var sum = _.reduce(models, function(total, m) {
      return total + (m.calculateSubtotal() || 0);
    }, 0)

    return UC.utils.handleFloatPrecision(sum, 2);
  },

  totalFees: function() {
    return this.sumOfModelFees(this.models);
  },

  formattedTotalFees: function() {
    return UC.utils.formatCurrency(this.totalFees(), true);
  },

  selectedLineItemModels: function() {
    return this.models.filter(function(m) {
      return m.selected;
    })
  },

  modelsNotAddedToInvoice: function() {
    return this.models.filter(function(m) {
      return !m.addedToInvoice;
    })
  },

  selectedLineItemModelIds: function() {
    return _.map(this.selectedLineItemModels(), function(m) {
      return m.get('_id');
    });
  },

  sumOfSelectedModelFees: function() {
    return this.sumOfModelFees(this.selectedLineItemModels());
  },

  formattedSelectedModelFees: function() {
    return UC.utils.formatCurrency(this.sumOfSelectedModelFees(), true);
  },

  selectedModelsCount: function() {
    return this.selectedLineItemModels().length;
  },

  anyModelsSelected: function() {
    return this.selectedModelsCount() > 0;
  },

  lineItemSelected: function(model) {
    // The collection can only have one job selected at a given time as this is how invoices are generated and invoices
    // are associated with jobs
    this.selectedLineItemsJobId = model.get('JobId') || 'direct';
  },

  removeSelectedJobIdIfAppropriate: function() {
    if (!this.anyModelsSelected()) this.selectedLineItemsJobId = null;
  },

  triggerEventForModelDestroySync: function (model) {
    // The destroy event removes a model from the collection before sync. As a result, a sync event is not fired on the
    // collection. This function triggers the expected sync event to show to the user a delete was successful.
    model.on('sync', function() {
      this.trigger('model-delete-sync');
    }.bind(this));
  },

  createNewModel: function() {
    var newModel = new UC.timesheetComponent.TimesheetLineItem({
      ClientId: this.clientId,
      JobId: this.jobId,
      InvoiceId: this.invoiceId,
      Type: 'time_entry'
    }, {
      collection: this
    });

    this.create(newModel, {wait: true});

    UC.utils.recordKissEvent('timesheet_entry_created', {
      page_path: window.location.pathname
    });
  }

});
