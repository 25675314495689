UC.modals.UserJobReportModal = Backbone.View.extend({
  className: 'modal',

  el: '#mdlUserReportJob',

  events: {
    "change input[name='feedback_response[AttorneyFeedback]']" : 'onChangeAttorneyFeedback',
    'submit #formUserReportJob' : 'onSubmitUserReportJob'
  },

  initialize: function() {
    this.$feedbackComments = this.$el.find('#feedbackComments');
    this.$formUserReportJob = this.$el.find('#formUserReportJob');
    this.$reportAttorneyFeedback = this.$el.find("#reportAttorneyFeedback");

    return this;
  },

  render: function() {
    this.$el.modal();

    UC.utils.recordKissEvent('user_job_report_shown',
      { jobId: UC.job.id, attorneyId: UC.user.id, url: location.href }
    );
  },

  onChangeAttorneyFeedback: function(e) {
    UC.utils.hideValidationErrors('js-report-options');
    if (e.target.value === 'other') {
      this.$feedbackComments.removeClass('hidden');
    } else {
      this.$feedbackComments.addClass('hidden');
    }
  },

  preprocessData: function(data){
    data.referrer = document.referrer;

    if(data.feedback_response && data.feedback_response.AttorneyFeedback !== 'other') {
      delete data.feedback_response.Comments;
    }

    return data;
  },

  validate: function(data){
    var errors = [];

    if(data.feedback_response && _.isEmpty(data.feedback_response.AttorneyFeedback)){
      errors.push({element: this.$reportAttorneyFeedback, text: 'Please select a report reason'});
    }

    if(errors.length > 0) {
      UC.utils.showValidationErrors(errors, {className: 'js-report-options'});
      return false;
    } else {
      return true;
    }
  },

  onSubmitUserReportJob: function(e) {
    e.preventDefault();

    var data = this.$formUserReportJob.serializeJSON();
    data = this.preprocessData(data);

    if(this.validate(data)) {
      UC.net.post(this.$formUserReportJob.attr('action'), data, function () {
        this.$el.modal('hide');
      }.bind(this));
    }
  }
});
