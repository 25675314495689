UC = UC || {};

UC.acceptJobProposal = {
  init: function(options) {
    this.$acceptProposalButton = $(options.acceptProposalButtonSelector);
    this.jobId = options.jobId;
    this.bidId = options.bidId;
    this.hideHireHelpModal = options.hideHireHelpModal;
    this.setListeners();
  },

  setListeners: function() {
    this.$acceptProposalButton.on('click', this.onAcceptProposalClick.bind(this));
    $('#chkHideHireHelpModal').on('click', this.onChkHideHireHelpModalClick.bind(this));
    $('#btnCloseHiringInfoModal').on('click', this.hideModal.bind(this));
  },

  onAcceptProposalClick: function(e) {
    e.preventDefault();

    if (UC.user.status === UC.constants.UserStatus.Pending) {
      new UC.modals.ModalPendingUser().render(
        'Please confirm your email address before hiring an attorney'
      );
    } else {
      this.acceptProposal(e);
    }
  },

  acceptProposal: function(e) {
    if (UC.user && UC.user.impersonate) {
      this.showHireConfirmModalToImpersonator(e);
    } else {
      this.hireAttorney(e);
    }
  },

  showHireConfirmModalToImpersonator: function(e) {
    var attorney_name = $(e.target).closest('[data-attorney-name]').data().attorneyName,
      job_owner_name = UC.user.name,
      message = "Are you sure you want to hire {0} on behalf of {1}?".format(attorney_name, job_owner_name);
    new UC.components.confirmModal({
      title: 'Hiring Confirmation',
      text: message,
      callback: function(yes) {
        if (yes) {
          this.hireAttorney(e);
        }
      }.bind(this)
    });
  },

  hireAttorney: function(e) {
    var bidId = this.bidId || $(e.target).closest('[data-bid-id]').data().bidId;
    UC.net.post('/jobs/{0}/proposals/{1}/accept'.format(this.jobId, bidId), {})
      .done(this.handleAcceptProposalResponse.bind(this));
  },

  hideModal: function(e) {
    e.preventDefault();
    $('#modalHireInfo').modal('hide');
  },

  handleAcceptProposalResponse: function(data) {
    if (this.hideHireHelpModal || UC.constants.hideHireHelpModal) {
      window.location = data.next_url;
    } else {
      if (data.type === 'awarded') $('#btnCloseHiringInfoModal').addClass('hidden');
      $('#modalHireInfo').modal({
        backdrop: 'static',
        keyboard: false
      });

      $('#btnContinuePay').click(function (e) {
        this.onBtnContinuePay(e, data.next_url);
      }.bind(this));
    }
  },

  onBtnContinuePay: function(e, url) {
    e.preventDefault();
    var $modalPhoneNumber = $('#modalPhoneNumber');
    var $btnContinuePay = $('#btnContinuePay');
    var $modalHireInfo = $('#modalHireInfo');

    $modalPhoneNumber.closest('.row').removeClass('has-error');
    var valid = true;
    var updatePhone = false;
    if ($modalPhoneNumber && $modalPhoneNumber.length > 0) {
      updatePhone = true;
      var phone = $modalPhoneNumber.val().trim();
      if (phone == '') {
        UC.utils.showValidationErrors([
          {element: $modalPhoneNumber, text: 'Please enter a phone number'}
        ]);
        valid = false;
      } else {
        if (!/^[0-9\.)(+\s-]{7,15}$/.test(phone)) {
          UC.utils.showValidationErrors([
            {element: $modalPhoneNumber, text: 'Invalid phone number'}
          ]);
          valid = false;
        } else {
          phone = phone.replace(/[\.)(+\s-]+/g, '');
          if (!/^\d{7,15}$/.test(phone)) {
            UC.utils.showValidationErrors([
              {element: $modalPhoneNumber, text: 'Invalid phone number'}
            ]);
            valid = false;
          }
        }
      }
    }

    if (valid && updatePhone) {
      var data = { info: {Phone: phone} };
      UC.net.post('/account/update_org_info', data, function (res) {
        if ($btnContinuePay.hasClass('jqmClose')) {
          $modalHireInfo.modal('hide');
        } else if (url) {
          location.href = url;
        } else {
          location.reload(true);
        }
      });
    } else if (valid) {
      if ($btnContinuePay.hasClass('jqmClose')) {
        $modalHireInfo.modal('hide');
      } else if (url) {
        location.href = url;
      } else {
        location.reload(true);
      }
    }
  },

  onChkHideHireHelpModalClick: function () {
    var $chkHideHireHelpModal = $('#chkHideHireHelpModal');
    UC.net.post('/account/update_help_display_option', {
      option: 'hire',
      optionValue: $chkHideHireHelpModal.is(':checked')
    });
  }
};
