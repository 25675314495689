!function() {

  UC.views.AttorneyClientsShow = Backbone.View.extend({
    events: {
      'click .schedule-call-btn' : 'openScheduledCallModal',
      'click #send-message-btn' : 'openSendMessageModal',
      'show.bs.tab #timesheets-tab' : 'renderCurrentTimesheetTabSetHistory',
      'show.bs.tab #profile-tab' : 'setProfileHistory',
      'click #current-timesheets-tab' : 'renderCurrentTimesheetTabSetHistory',
      'click #invoiced-timesheets-tab' : 'renderInvoicedTimesheetTabSetHistory',
      'click .cancel-sched-call' : 'openCancelSchedCallModal'
    },

    initialize: function() {
      this.messageModal = new UC.modals.JobContactMessageModal({
        el: '#send-message-modal',
        recipientId: UC.constants.clientId
      });

      this.$timesheetsDiv = $('#timesheets');

      this.renderTimesheetContentForParams();

      $('[data-toggle="tooltip"]').tooltip();
    },

    openScheduledCallModal: function(e) {
      e.preventDefault();
      var $el = $(e.currentTarget);
      var jobId = $el.data('jobId');
      var scheduledCallId = $el.data('scheduledCallId');

      new UC.modals.ScheduledCallsShow({
        job_id: jobId,
        scheduled_call_id: scheduledCallId,
        callback: this.handleCallScheduled.bind(this)
      }).render();

      UC.utils.recordKissEvent('call_reply_click_client_profile', {
        type: UC.user.type,
        scheduled_call_id: scheduledCallId,
        loc: window.location.href
      });
    },

    openSendMessageModal: function(e) {
      e.preventDefault();

      this.messageModal.open();
    },

    handleCallScheduled: function() {
      window.location.reload();
    },

    setProfileHistory: function() {
      history.pushState({}, 'profile', '?profile=true');
    },

    renderCurrentTimesheetTabSetHistory: function() {
      history.pushState({}, 'current_ts_entries', '?current_ts_entries=true');
      this.showCurrentTimesheetsView();
    },

    renderInvoicedTimesheetTabSetHistory: function() {
      history.pushState({}, 'invoiced_ts_entries', '?invoiced_ts_entries=true');
      this.showInvoicedTimesheetsView();
    },

    showCurrentTimesheetsView: function() {
      new UC.timesheetComponent.CurrentTimesheetLineItemsForClientView({
        parentElement: this.$timesheetsDiv,
        clientId: UC.constants.clientId,
        allowNewTimesheetLines: true
      });
    },

    showInvoicedTimesheetsView: function() {
      new UC.timesheetComponent.ClientInvoicedLineItemsView({
        parentElement: this.$timesheetsDiv,
        clientId: UC.constants.clientId
      });
    },

    renderTimesheetContentForParams: function() {
      if (UC.utils.getParameterByName('current_ts_entries') === 'true') {
        this.showCurrentTimesheetsView();
        return;
      }

      if (UC.utils.getParameterByName('invoiced_ts_entries') === 'true') {
        this.showInvoicedTimesheetsView();
        return;
      }
    },

    openCancelSchedCallModal: function(e) {
      e.preventDefault();
      var scheduledCallId = $(e.currentTarget).data('scheduled-call-id');
      new UC.partials.ScheduledCallsCancel({scheduledCallId: scheduledCallId});
    }

  })

}();
