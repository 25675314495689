UC.views.ProposalsEdit = Backbone.View.extend({

  initialize: function() {
    new UC.partials.ProposalsForm({
      paymentProfile: UC.constants.payment_profile,
      attorneyClient: UC.constants.attorney_client,
      toggleToHourly: UC.constants.jobBidType && UC.constants.jobBidType === UC.constants.BidType.HourlyRate,
      attorneyHasPayoutAcct: UC.constants.hasPayout,
      attorneyAccountUnverified: UC.constants.unverifiedAccount,
      submitSelector: '#btnSubmitProposal',
      validatePropMessagesUpdated: false,
      jobId: UC.job.id
    });

    new UC.partials.JobInfoPanel();
  }

});
