UC.views.AccountNewPayoutAccount = Backbone.View.extend({

  initialize: function() {
    this.bankAccountForm = new UC.partials.BankAccountForm({
      el: '#formBankAccount',
      payout: true
    });
  }

});
