!function() {

  UC.views.EmailsRow = Backbone.View.extend({

    attributes: {
      id: 'emails',
      class: 'email__container'
    },

    tagName: 'li',

    template: UC.views.resolveHandlebarsTemplate('public/emails/email'),

    events: {
      'click .set-primary': 'setPrimary',
      'click .resend-verification': 'resendVerification',
      'click .remove': 'destroy'
    },

    initialize: function(options) {
      this.email = options.email;
      this.email.primary = this.email.Email === UC.user.email;
      this.base_path = '{0}/{1}'.format(window.location, this.email._id);
    },

    render: function() {
      this.$el.html(this.template(this.email));
      return this;
    },

    resendVerification: function(e) {
      e.preventDefault();

      var path = '{0}/resend_verification'.format(this.base_path);

      UC.net.post(path, null, function(result) {
        UC.utils.showNotification(result.message, UC.constants.results.success);
      });
    },

    destroy: function(e) {
      e.preventDefault();
      UC.net.destroy(this.base_path, null, this.remove.bind(this));
    },

    setPrimary: function(e) {
      e.preventDefault();

      const $loader = $(e.currentTarget).buttonLoader();
      $loader.showLoader();

      UC.net.post('{0}/set_primary'.format(this.base_path), null).fail(function() {
        $loader.hideLoader();
      });
    }
  });
}();
