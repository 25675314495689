!function() {

  UC.components.FollowUpOffers = Backbone.View.extend({

    events: {
      'click .follow-up-offer-hire-btn': 'acceptProposal'
    },

    initialize: function() {
      this.$el.find('.follow-up-offer-hire-btn').each(function() {
        $(this).buttonLoader();
      });
    },

    acceptProposal: function(e) {
      e.preventDefault();

      var $el = $(e.currentTarget);
      var url = $el.attr('href');

      $el.data('button-loader').showLoader();

      UC.net.post(url, function(res) {
        window.location = res.next_url;
      });
    }

  });

}();
