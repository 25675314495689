!function() {
  UC.modals.EditLanguages = Backbone.View.extend({

    el: '#mdlAddLanguages',

    events: {
      'click #btnSaveLanguages': 'saveLanguages',
      'click .add-language-proficiency': 'addLanguage',
      'click .remove-language-proficiency': 'removeLanguage'
    },

    initialize: function(opts) {
      this.$('.language-row').find('.controls.col-sm-8').removeClass('col-sm-8').addClass('col-sm-12');
      // TODO: find a way to add error message div by passing flag into simple form
      this.$('.language-row').find('.form-group .controls').append("<div class='error-message help-block'></div>")

      this.data = {};

      if (opts) this.saveCallback = opts.saveCallback;
    },

    openModal: function() {
      this.$el.modal({ backdrop: 'static' });
    },

    saveLanguages: function() {
      if (this.validateLanguages()) {
        UC.net.post(UC.constants.update_languages_path, this.data, function(response) {
          UC.utils.hideNotifications();
          if (UC.controller === 'dashboard' && UC.action === 'index'){
            UC.utils.checklistSuccessCallback('btnOpenLanguagesModal');
          } else {
            this.saveCallback(response);
          };
          this.$el.modal('hide');
        }.bind(this));

      }
    },

    addLanguage: function(e) {
      e.preventDefault();

      var html = this.$('#language-proficiency-template').html();
      var new_id = new Date().getTime();
      var regexp = new RegExp('new_Language', 'g');
      var $row = $(html.replace(regexp, new_id));

      $row.find('.controls.col-sm-8').removeClass('col-sm-8').addClass('col-sm-12');
      // TODO: find a way to add error message div by passing flag into simple form
      $row.find('.form-group .controls').append("<div class='error-message help-block'></div>")
      this.$('.language-proficiencies').append($row);
    },

    removeLanguage: function(e) {
      e.preventDefault();
      $(e.target).closest('.language-row').remove();
    },

    validateLanguages: function() {
      this.$('.has-error').removeClass('has-error');
      this.data = { LanguageProficiencies: [] };

      var errors = [];
      var $rows = this.$('.language-row');

      if ($rows.length === 0) {
        UC.utils.showNotification('Must add at least one language', UC.constants.results.failure);
        return false
      } else {
        var that = this;
        $rows.each(function() {
          var $this = $(this);
          var $language = $this.find('.attorney_user_LanguageProficiencies_Language select.select');
          var language = $language.val();
          var $level = $this.find('.attorney_user_LanguageProficiencies_Level select.select');
          var level = $level.val();

          if (language === '') {
            errors.push({ element: $language, text: 'Language is required' });
          } else {
            for (var i = 0; i < that.data.LanguageProficiencies.length; i++) {
              var current = that.data.LanguageProficiencies[i];
              if (current.Language === language) {
                errors.push({ element: $language, text: 'Duplicate language' });
              }
            }
          }

          if (level === '') {
            errors.push({element: $level, text: 'Level is required'});
          }

          var source = UC.controller === 'dashboard' ? 'checklist' : 'attorney_profile';
          that.data.LanguageProficiencies.push({ Language: language, Level: level, Source: source, Added: new Date() });
        });
      }

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors, { className: "form-group" });
        return false
      } else {
        return true
      }
    }
  });
}();
