UC.components.AvailabilityTimeRangeView = Backbone.View.extend({

  tagName: 'div',

  className: 'marg-t-10',

  template: UC.views.resolveHandlebarsTemplate("components/calendar_availability/availability_time_range"),

  events: {
    'click .remove-range' : 'removeTimeRangeModel',
    'change select' : 'updateModelFromRangeChange'
  },

  initialize: function() {
    this.model.on('remove', this.removeEl.bind(this));
  },

  timeOptions: function() {
    var selectOpts = [];

    for (var i=0; i < 24; i++) {

      var newSelectOpts = [
        { value: i + ':00', display: this.hourDisplay(i) + ':00 ' + this.amPmForHour(i)},
        { value: i + ':30', display: this.hourDisplay(i) + ':30 ' + this.amPmForHour(i)},
      ]

      selectOpts = selectOpts.concat(newSelectOpts);
    }

    return selectOpts;
  },

  hourDisplay: function(hour) {
    if (hour === 0) return 12;
    if (hour > 12) return hour - 12;
    return hour;
  },

  amPmForHour: function(hour) {
    if (hour >= 12) {
      return 'PM';
    } else {
      return 'AM';
    }
  },

  render: function() {
    this.$el.html(this.template({timeOptions: this.timeOptions(), model: this.model}));
  },

  removeTimeRangeModel: function(e) {
    e.preventDefault();
    this.model.collection.remove(this.model);
  },

  removeEl: function() {
    this.$el.remove();
  },

  updateModelFromRangeChange: function(e) {
    var updateArgs = {},
        $updateSelect = $(e.currentTarget);

    updateArgs[$updateSelect.attr('name')] = $updateSelect.val();
    this.model.set(updateArgs);
  }

});
