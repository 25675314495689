!function() {

  UC.views.ReferralsAttorneyReferralLanding = Backbone.View.extend({
    events: {
      'click #bioMoreLink' : 'showFullBio',
      'click .get-started-btn' : 'onClickGetStartedBtn'
    },

    initialize: function() {
      UC.utils.initRatingDimensions('.review-dimensions');
      this.setTrackingCode();
    },

    setTrackingCode: function() {
      UC.net.put(UC.constants.referral_path);
    },

    showFullBio: function(e) {
      e.preventDefault();
      $('.bio-full').show();
      $('#bioMoreLink').hide();
    },

    onClickGetStartedBtn: function(e) {
      e.preventDefault();
      $('#atty-ref-get-started-modal').modal();
    }
  })

}();
