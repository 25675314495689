UC.views.PaymentsNew = Backbone.View.extend({

  initialize: function() {

    this.components = {
      payment_info: new UC.components.PaymentInfo({el: $('body')}),
      billingSummary: new UC.components.BillingSummary({el: $('#billing-summary')})
    };

    UC.utils.sendNesEvent('start_opportunity_to_pay', {
      attorney_id: UC.invoice.attorneyId
    });
  }
});
