!function() {

  UC.views.StaticPagesHowItWorks = Backbone.View.extend({
    events: {
      'click .how-it-works-summary-image-container, .how-it-works-panel-label' : 'panelHeaderClicked',
      'click button.next-how-it-works-panel' : 'nextPanelButtonClicked'
    },

    initialize: function () {
      var vimeoScriptUrl = 'https://player.vimeo.com/api/player.js';
      $.getScript(vimeoScriptUrl, this.bindVimeoEventCallbacks.bind(this));
    },

    bindVimeoEventCallbacks: function () {
      var fireKissEvent = this.fireKissEvent;
      var video = document.querySelector('#vimeo');
      var player = new Vimeo.Player(video);

      player.on('play', function(attrs) { fireKissEvent('play', attrs); });
      player.on('pause', function(attrs) { fireKissEvent('pause', attrs); });
      player.on('ended', function(attrs) { fireKissEvent('ended', attrs); });

      $('body').unload(function() {
        player.getCurrentTime().then(function(seconds) {
          fireKissEvent('leave', { seconds: seconds });
        });
      });
    },

    fireKissEvent: function (name, attrs) {
      attrs.url = window.location.href;
      UC.utils.recordKissEvent("vimeo_video_" + name, attrs);
    },

    nextPanelButtonClicked: function(e) {
      e.preventDefault();
      var nextPanelName = $(e.currentTarget).data('nextPanelName');
      var $nextPanel = $('#'+nextPanelName+'-panel');
      this.setActivePanel($nextPanel);
    },

    panelHeaderClicked: function(e) {
      e.preventDefault();
      var $panelSelected = $(e.currentTarget).parents('.how-it-works-panel');
      this.setActivePanel($panelSelected);
    },

    setActivePanel: function($panelSelected) {
      var $howItWorksPanels = $('.how-it-works-panel');
      var $panelContentDivs = $('.how-it-works-panel-content');
      var panelSelectedName = $panelSelected.data('panelName');

      $howItWorksPanels.removeClass('active');
      $panelSelected.addClass('active');
      $panelContentDivs.addClass('hidden');
      $('#'+panelSelectedName+'-panel-content').removeClass('hidden');
    }
  })

}();
