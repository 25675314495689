!function() {

  UC.views.SearchIndex = Backbone.View.extend({

    paginationTemplate: UC.views.resolveHandlebarsTemplate('shared/pagination'),

    events: {
      'blur #global_search .search_input': 'pushState',
      'keydown #global_search .search_input': 'searchTimeout',
      'submit #global_search .search_form': 'formSubmit',
      'click .search-result-filters a': 'filterResults',
      'click #search_link': 'preventDefault',
      'click a.page-link': 'showPage',
      'click a.search_clear_button': 'clearSearchTerm',
      'click #global_search .search_button': 'trackSearchClick',
      'click #global_search .search_result a': 'trackResultClick'
    },

    initialize: function() {
      var search = UC.constants.search || {};

      this.results = null;
      this.$search_input = this.$el.find('#global_search .search_input');
      this.filterSearch = this.$el.find('#search_results .dropdown-toggle');
      this.clearSearch = this.$el.find('#global_search .search_clear_button');
      if (UC.browser.isMobile()) {
        this.filterSearchLoader = this.clearSearch.buttonLoader();
      } else {
        this.filterSearchLoader = this.$el.find('#global_search .search_button').buttonLoader();
      }

      this.page = search.page;
      this.query = search.q;
      this.filter = search.filter;
      this.exp = search.exp;

      if (this.page || this.query || this.filter) {
        this.trackEvent('global_search_results_shown', {
          counts: search.counts
        });
      }

      if (this.$search_input.val() === '') {
        this.$search_input.focus();
      } else {
        this.toggleClearSearchIcon(true);
      }

      window.onpopstate = this.popState.bind(this);
    },

    toggleClearSearchIcon: function(visible) {
      var $icon = this.clearSearch.find('.icon-delete');
      if (visible) {
        $icon.removeClass('hide');
      } else {
        $icon.addClass('hide');
      }
    },

    clearSearchTerm: function(e) {
      e.preventDefault();

      this.$search_input.val('');
      this.pushState();
      this.searchTimeout();
      this.toggleClearSearchIcon(false);
    },

    formSubmit: function(e) {
      e.preventDefault();

      this.pushState();

      var $search_results = this.$el.find('#search_results');

      $search_results.css({
        'height': $search_results.height(),
        'visibility': 'hidden'
      })

      this.page = 1;
      this.query = this.$search_input.val();
      this.performSearch(function(data) {

        $search_results.css({
          'height': 'auto',
          'visibility': 'visible'
        }).addClass('animated fadeIn');

        setTimeout(function() {
          // reset for next time
          $search_results.removeClass('animated fadeIn');
        }, 500);
      }.bind(this));

      this.trackEvent('global_search_form_submit');
    },

    searchTimeout: function(e) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(function() {
        var query = this.$search_input.val();

        if (query == this.query) {
          // query hasn't changed
          return;
        }

        this.page = 1;
        this.query = query;
        this.performSearch();
      }.bind(this), 100);
    },

    performSearch: function(callback) {

      if (this.ajax && typeof this.ajax.abort === 'function') {
        // abort any running requests
        this.ajax.abort();
      }

      var options = {
        q: this.query,
        filter: this.filter,
        page: this.page
      };
      if (this.exp) {
        options['exp'] = this.exp;
      }

      this.filterSearchLoader.showLoader();

      this.ajax = $.get('/search.json', options, function(data) {
        this.renderStats(data);
        this.renderFilter(data);
        this.renderBestResult(data.best_result);
        this.renderResults(data.hits);
        this.renderPagination(data);
        this.setPageTitle();

        this.trackEvent('global_search_results_shown', {
          counts: data.counts
        });

        if (options.q !== '') {
          this.toggleClearSearchIcon(true);
        }

        if (typeof callback === 'function') {
          callback(data);
        }
      }.bind(this));

      this.ajax.fail(function(xhr, textStatus) {
        if (textStatus === 'abort') {
          // don't show angry message on aborts (from above)
          xhr.handled = true;
        }
        this.hideSearchLoader();
      }.bind(this));
    },

    renderStats: function(data) {
      var $stats = this.$el.find('#search_results_stats');

      $stats.text('{0} result{1}'.format(data.total_entries,
            (data.total_entries === 1 ? '' : 's')));
    },

    renderFilter: function(data) {
      _.each($('.search-result-filters'), function(element) {
        var $filter = $(element);
        for (var type in data.counts) {
          var count = parseInt(data.counts[type], 10);
          var $li = $filter.find('a[data-type={0}]'.format(type)).closest('li');
          var $count = $li.find('span.count');

          if (count > 0) {
            $li.removeClass('hide');
            $count.text(count);
          } else {
            $li.addClass('hide');
          }
        }

        if (data.hits.length > 0) {
          $filter.removeClass('hide');
        } else if (!this.filter) {
          $filter.addClass('hide');
        }
      });
    },

    renderBestResult: function(best_result) {
      var $best_result_header = this.$el.find('#best_result_title');
      var $best_result = this.$el.find('#best_result');

      if (best_result) {
        var result = new UC.views.SearchResult({ result: best_result });
        $best_result_header.removeClass('hide');
        $best_result.html(result.render().el).removeClass('hide');
      } else {
        $best_result_header.addClass('hide');
        $best_result.addClass('hide');
      }
    },

    hideSearchLoader: function() {
      this.filterSearchLoader.hideLoader();
      this.filterSearch.css('min-width', '');
    },

    renderResults: function(results) {
      var $result_list = this.$el.find('#search_results_list');

      $result_list.empty();

      _.each(results, function(result) {
        var result = new UC.views.SearchResult({ result: result });
        $result_list.append(result.render().el);
      }.bind(this));

      this.hideSearchLoader();
    },

    selectFilter: function(filter) {
      filter = filter || 'all'
      this.$el.find('#search_results_filter li').removeClass('active');
      this.$el.find('#search_results_filter a[data-type={0}]'.format(filter))
        .closest('li').addClass('active');
    },

    filterResults: function(e) {
      e.preventDefault();

      this.filterSearchLoader.showLoader();

      var $filter = $(e.currentTarget);
      var $dropdown = $filter.closest('.dropdown');
      var $dropdownToggle = $dropdown.find('.dropdown-toggle');
      $dropdown.find('li.active').removeClass('active');
      $filter.closest('li').addClass('active');
      $dropdownToggle.find('.filter-title').text($filter.find('.title').text())

      this.page = 1;
      this.filter = $filter.data('type');
      this.selectFilter(this.filter);
      this.performSearch();
      this.pushState();

      this.trackEvent('global_search_filter_click');
    },

    preventDefault: function(e) {
      e.preventDefault();
    },

    pushState: function() {
      var changed = false;
      var title = null;
      var url = window.location.href;

      var query = this.$search_input.val();
      var filter = this.filter;
      var page = this.page;
      var state = { query: query, filter: filter, page: page };

      if (query != this.pushState_query) {
        var encoded = encodeURIComponent(query).replace(/%20/g, '+');
        url = UC.utils.updateQueryStringParam(url, 'q', encoded);
        changed = true;
      }

      if (filter != this.pushState_filter) {
        url = UC.utils.updateQueryStringParam(url, 'filter', filter);
        changed = true;
      }

      if (page != this.pushState_page) {
        url = UC.utils.updateQueryStringParam(url, 'page', page);
        changed = true;
      }

      if (changed) {
        this.pushState_query = query;
        this.pushState_filter = filter;
        this.pushState_page = page;
        window.history.pushState(state, title, url);
      }
    },

    popState: function(e) {

      if (e.state) {
        this.pushState_query = this.query = e.state.query;
        this.pushState_filter = this.filter = e.state.filter;
        this.pushState_page = this.page = e.state.page;
      }

      this.selectFilter(this.filter);
      this.$search_input.val(this.query);
      this.performSearch();
    },

    renderPagination: function(result) {

      var $pagination = this.$el.find('#search_results_pagination');

      if (result.total_pages > 1) {
        var html = this.paginationTemplate(result.search_pagination);
        $pagination.html(html).removeClass('hide');
      } else {
        $pagination.addClass('hide');
      }
    },

    showPage: function(e) {
      e.preventDefault();
      UC.utils.scrollToTop();
      this.page = $(e.currentTarget).data('page');
      this.performSearch();
      this.pushState();
    },

    setPageTitle: function() {
      if (typeof this.query === 'string' && this.query.length > 0) {
        document.title = "{0} - Search Results on UpCounsel".format(this.query.trim());
      } else {
        document.title = "Search on UpCounsel";
      }
    },

    trackEvent: function(name, options) {
      var attrs = options || {};

      attrs.query = this.query;
      attrs.filter = this.filter;
      attrs.page = this.page;

      UC.utils.sendNesEvent(name, attrs);
      UC.utils.recordKissEvent(name, attrs);
    },

    // purely for tracking
    trackSearchClick: function() {
      this.trackEvent('global_search_button_click');
    },

    // purely for tracking
    trackResultClick: function(e) {
      var $el = $(e.currentTarget);

      this.trackEvent('global_search_result_click', {
        href: $el.attr('href'),
        name: $el.data('name'),
        index: $el.closest('.search_result').data('index')
      });
    }
  });
}();
