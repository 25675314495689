import { default as MultiFactorRequestFrom } from '../multi_factor_auth/_request_form'

UC.views.SessionsMultiFactorRecover = Backbone.View.extend({
    initialize: function() {
        new MultiFactorRequestFrom({
            el: this.$el,
            formId: 'two-factor-recovery-form',
            recovery: true,
            requestParams: {
              recover: true
            },
            successCallback: function(response) {
                window.location = response.next_url || '/dashboard';
            },
            failureCallback: function() {
                window.location = '/account/logon';
            }
        });
    }
});
