UC.partials.NewFindWork = Backbone.View.extend({
  events: {
    'click #js-legal-group-selection': 'openEditPracticeAreasModal',
    'click a.js-find-work-all-option': 'changeShowAllOption',
    'change input.js-find-work-job-type[name=type]': 'changeJobTypeOption',
    'change input.js-find-work-list-view[name=ListView]': 'changeListViewOption'
  },

  initialize: function(opts) {
    this.trackFilterClick = opts.trackFilterClick;
    this.disabledFilterArray = ["recommended", "invited"];
    this.$findWorkList = this.$el.find('#find-work-list-new');
    this.currentType = this.$findWorkList.data('type');
    this.$filter = this.$el.find('#jobFilters');
    this.$disabledFilter = this.$el.find('.disabled-filter');
    this.$legalGroupSelectionBtn = this.$el.find('#js-legal-group-selection');
    this.$stateSelection = this.$el.find('#js-select-filter-location-dropdown');
    this.$stateLabel = this.$el.find('.js-state-label');
    this.$legalAreaLabel = this.$el.find('.js-legal-area-label');
    this.legalSubjectIds = UC.constants.legalSubjectIds;
    this.$openPracticeAreasModal = new UC.modals.EditPracticeAreas({
      savePracticeAreas: this.submitPracticeAreas.bind(this),
      cancelPracticeAreas: this.cancelPracticeAreas.bind(this)
    });

    this.initSetup();
    this.jobsList = new UC.views.JobsList({
      el: '#find-work-list-new',
      eventNamePrefix: 'Find Work',
      updateUrl: '/work',
      filterParams: this.filterParams.bind(this),
      updateMethod: 'post',
      renderCallback: this.renderCallback.bind(this),
      beforePaginate: this.pushState.bind(this),
      currentType: this.currentType
    });

    if (window.history) {
      window.onpopstate = this.popState.bind(this);
    };

    this.jobsList.setPage(UC.utils.queryStringParams().page || 1);
    this.setState();
  },

  initSetup: function(){
    this.stateSelectionSetup();
    if (UC.utils.isMobileViewPort()) {
      this.mobileSetup();
    }
    this.disabledFilterSetup();
  },

  disabledFilterSetup: function(){
    if (!_.contains(this.disabledFilterArray, this.currentType)) {
      if (this.$legalGroupSelectionBtn.is(':disabled')) {
        this.$legalGroupSelectionBtn.prop('disabled', false).removeClass('disabled');
      }
    } else {
      this.$disabledFilter.removeClass('hidden');
      this.$stateSelection.multiselect('disable');
    }
  },

  mobileSetup: function(){
    this.$findWorkFilters = this.$el.find(".js-find-work-filters");
    this.$el.find('#js-find-work-toggle').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if($(".js-find-work-filters:visible").length > 0) {
        this.$findWorkFilters.addClass('hidden-xs');
      } else {
        this.$findWorkFilters.removeClass('hidden-xs');
      }
    }.bind(this));
  },

  stateSelectionSetup: function(){
    var self = this;
    var span = document.createElement("span");

    this.$stateSelection.multiselect({
      enableHTML: true,
      enableClickableOptGroups: true,
      selectedClass: 'multiselect-selected',
      buttonClass: 'btn find-work-filter-btn find-work-state-btn btn-block',
      buttonContainer: '<div class="btn-group btn-block" />',
      maxHeight: 500,
      onChange: function(_option, _checked) {
        self.changeFilter();
      },
      onInitialized: function(_select, container) {
        self.$el.find("#grpFilterStates").removeClass('hidden');
        self.$el.find(".multiselect-container input").after(span);
        container.find("button.dropdown-toggle").append("<b class='caret'></b>");
      },
      optionClass: function(_element) {
        return 'super-fancy-check';
      },
      buttonText: function(options, _select) {
        if (options.length === 0) {
          return 'No states selected';
        } else if (options.length === 53) {
          return 'All Selected';
        } else if (options.length > 3) {
          return '{0} Selected'.format(options.length);
        } else {
          return self.parseStatesFilterOptions(options);
        }
      },
    });
  },

  parseStatesFilterOptions: function(options){
    var labels = [];
    options.each(function() {
      var value = $(this).val();
      if(value == "int"){ // handle international to be more consistent with the other state names
        value = "INTL";
      }
      labels.push(value);
    });

    return labels.join(', ');
  },

  changeFilter: function(e) {
    if (e) e.preventDefault();
    this.jobsList.setPage(1);
    this.jobsList.render();
  },

  popState: function (e) {
    if (e.state) {
      this.jobsList.setPage(e.state.page);
    }

    this.jobsList.render();
  },

  pushState: function () {
    if (!window.history) return;
    var state = this.createState();
    window.history.pushState(state, '', state.url);
  },

  setState: function () {
    if (!window.history) return;
    var state = this.createState();
    window.history.replaceState(state, '', state.url);
  },

  createState: function () {
    var state = {};
    state.page = this.jobsList.getPage();
    state.url = window.location.href;

    state.url = UC.utils.updateQueryStringParam(state.url, 'page', state.page);

    return state;
  },

  trackListViewClick: function($el) {
    UC.utils.recordKissEvent('Find Work List View Toggle', {
      job_type_filter: this.jobsList.getCurrentType(),
      view:  $el.val()
    });
  },

  renderCallback: function() {
    var type = this.jobsList.getCurrentType();
    this.$findWorkList.attr('data-type', type);
    this.$filter.attr('data-type', type);
    try {
      document.querySelector("#job-list").scrollIntoView({behavior: 'smooth'});
    } catch {
      console.warn('Failed to scroll to the top of the job list')
    }
  },

  filterParams: function() {
    var filterParams = this.$filter.serializeJSON();
    if(!filterParams.States){ // add states back in if disabled/form won't send them
      var states = this.$stateSelection.val();
      if(states && states.length > 0){
        filterParams.States = states;
      }
    }
    if(this.legalSubjectIds){
      filterParams.LegalSubjectIds = this.legalSubjectIds;
    }

    return filterParams;
  },

  cancelPracticeAreas: function(fancyCat) {
    var options = {};
    if(this.legalSubjectIds) {
      options = this.legalSubjectIds.reduce(function (obj, v) {
        obj[v] = true;
        return obj;
      }, {});
    }
    fancyCat.selectSpecificOptions(options);
  },

  submitPracticeAreas: function() {
    this.legalSubjectIds = $("[name='LegalSubjectIds[]']").val();
    this.changeFilter();
    var btnCopy = 'None Selected';
    if(this.legalSubjectIds){
      if(this.legalSubjectIds.length === UC.constants.legalSubjectsCount){
        btnCopy = "All Selected";
      } else {
        btnCopy = "{0} Selected".format(this.legalSubjectIds.length);
      }
    }
    btnCopy = "{0} <span class='caret'></span>".format(btnCopy);
    this.$legalGroupSelectionBtn.html(btnCopy);
    $('#mdlEditPracticeAreas').modal('hide');
  },

  openEditPracticeAreasModal: function(e) {
    if (e) e.preventDefault();
    this.$openPracticeAreasModal.openModal();
  },

  changeJobTypeOption: function(e) {
    this.disableJobTypeBtns(e);
    this.changeFilter();
  },

  changeListViewOption: function(e) {
    this.changeFilter();
    this.trackListViewClick($(e.currentTarget));
  },

  changeShowAllOption: function(e) {
    if (e) e.preventDefault();
    var $selected = $(e.currentTarget);
    this.$el.find('#showAllOption').val($selected.data('option'));
    $selected.closest('.dropdown').find('.js-find-work-all-option-text').text($selected.text())
    $selected.closest('label').find('input[type=radio]').prop("checked", true);
    this.changeFilter();
  },

  disableJobTypeBtns: function(e){
    var type = $(e.currentTarget).val();
    if(this.$legalGroupSelectionBtn.is(':disabled')) {
      if (!_.contains(this.disabledFilterArray, type)) {
        this.$stateSelection.multiselect('enable');
        this.$legalGroupSelectionBtn.prop('disabled', false).removeClass('disabled');
        this.$stateLabel.removeClass('disabled');
        this.$legalAreaLabel.removeClass('disabled');
        this.$disabledFilter.addClass('hidden');
      }
    } else {
      if (_.contains(this.disabledFilterArray, type)) {
        this.$stateSelection.multiselect('disable');
        this.$legalGroupSelectionBtn.prop('disabled', true).addClass('disabled');
        this.$stateLabel.addClass('disabled');
        this.$legalAreaLabel.addClass('disabled');
        this.$disabledFilter.removeClass('hidden');
      }
    }
  }
});
