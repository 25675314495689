UC.views.AttorneyClientsForm = Backbone.View.extend({
  
  el: '#attorney-client-form',
  
  events: {
    "click #not-located-in-usa" : "showInternationalAddressPanel",
    "click #located-in-usa" : "showDomesticAddressPanel",
    "ajax:success" : "formSaved",
    "click #submit-form" : "submitForm"
  },

  initialize: function() {
    this.$zipPanel = this.$el.find('#atty-client-zip');
    this.$internationalPanel = this.$el.find('#atty-client-intl-address');

    this.$firstName = this.$el.find('#first-name');
    this.$lastName = this.$el.find('#last-name');
    this.$email = this.$el.find('#email');
    this.$zipCode = this.$el.find('#zip-code');
    this.$city = this.$el.find('#city');
    this.$country = this.$el.find('#country');
    this.$websiteUrl = this.$el.find('#website-url');

    this.$el.find('select').select2();

    this.$('#attorney_client_HourlyRate').currencyInput();
  },

  showInternationalAddressPanel: function() {
    this.$zipPanel.addClass('hidden');
    this.$zipCode.val('');
    this.$internationalPanel.removeClass('hidden');
  },

  showDomesticAddressPanel: function() {
    this.$zipPanel.removeClass('hidden');
    this.$internationalPanel.addClass('hidden');
  },

  formSaved: function(e, data) {
    window.location.replace(data.url);
  },

  validateForm: function() {
    var errors = [];

    var firstName = this.$firstName.val();
    var lastName = this.$lastName.val();
    var email = this.$email.val();
    var zipCode = this.$zipCode.val();
    var city = this.$city.val();
    var country = this.$country.val();
    var websiteUrl = this.$websiteUrl.val();

    UC.utils.clearValidationErrors('form-group');

    if (this.$email.length > 0 && !UC.utils.validateEmail(email)) {
      errors.push({element: this.$email, text: "Please provide a valid email address"});
    }

    if (firstName.isBlank()) {
      errors.push({element: this.$firstName, text: "Please provide a valid first name"});
    }

    if (lastName.isBlank()) {
      errors.push({element: this.$lastName, text: "Please provide a valid last name"});
    }

    if (this.$zipCode.is(':visible') && zipCode.isBlank()) {
      errors.push({element: this.$zipCode, text: "Please provide a valid zip code"});
    }

    if (!this.$zipCode.is(':visible') && city.isBlank()) {
      errors.push({element: this.$city, text: "Please provide a valid city"});
    }

    if (!this.$zipCode.is(':visible') && !country) {
      errors.push({element: this.$country, text: "Please select a country"});
    }

    if (websiteUrl !== '' && !UC.utils.validateUrl(websiteUrl)) {
      errors.push({element: this.$websiteUrl, text: 'Invalid website url'});
    }

    if (errors.length === 0) {
      return true;
    } else {
      UC.utils.showValidationErrors(errors, {className: 'form-group'});
    }
  },

  submitForm: function(e) {
    e.preventDefault();
    
    if (this.validateForm()) {
      this.$el.submit();
      UC.utils.recordKissEvent('submit_attorney_client_form', { loc: window.location.href });
    }
  }
})
