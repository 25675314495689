!function() {

  UC.utils.showNotificationSuccess = function(message, timeout) {
    UC.utils.showNotification(message, UC.constants.results.success, timeout);
  };

  UC.utils.showNotification = function(message, result, timeout){
    var color = null;
    var template = UC.views.resolveHandlebarsTemplate('shared/notification');
    var allow_close = (timeout == 0);
    var timeout = typeof timeout === 'undefined' ? 7000 : timeout;

    switch(result){
      case UC.constants.results.success:
        color = 'success';
        break;
      case UC.constants.results.failure:
        color = 'danger';
        break;
      case UC.constants.results.warning:
        color = 'warning';
        break;
      default:
        color = 'info';
        break;
    }

    var $notification = $(template({
      color: color,
      message: message,
      allow_close: allow_close
    }));

    var $oldNotifs = $('.notify-msg-container');
    if ($oldNotifs.length > 0) {
      $oldNotifs.fadeOut(100, function() {
        $(this).remove();
        $notification.hide().appendTo('body').fadeIn(100);
      });
    } else {
      $notification.hide().appendTo('body').fadeIn(100);
    }

    if(timeout > 0){
      setTimeout(function() {
        $notification.remove();
      }, timeout);
    }
  };

  UC.utils.hideNotifications = function(){
    var $notification = $('.notify-msg-container');
    $notification.remove();
  };

  $(function() {

    // init close button and set close timeout on
    // notifications that rendered as flash messages
    $('.notify-msg-container').each(function() {
      var $this = $(this);
      var $close = $this.find('.notify-close');
      var notifyTime = parseInt($(this).data('time'), 10);

      if ($close.length > 0) {
        $close.click(function() {
          $this.remove();
        });
      }

      if(!isNaN(notifyTime) && notifyTime > 0){
          setTimeout(function() { $this.remove(); }, notifyTime * 1000);
      }
    });
  });
}();
