UC.views.EnterpriseInvoicesShow = Backbone.View.extend({
  initialize: function() {
    this.$('.price-range').each(function() {
      $(this).tooltip({
        title: 'See details below',
        container: 'body',
        placement: 'right auto'
      });
    });
  }
});
