UC.partials.MessagesCreate = Backbone.View.extend({
  el: 'body',

  initialize: function(options) {
    if (!UC.user) return;

    this.options = options || {};

    var namespaces = {
            notify: 'notify',
            error: 'error',
            redirect: 'redirect'
        }

    this.window_focus = true
    this.registerSelectors();
    this.registerEvents();
    this.processingMessage = false;
    this.sentTimeInterval;

    var sourceType = this.options.pageName === 'proposal' ?
      UC.constants.UserDocumentSourceType.ProposalMessageAttachment :
      UC.constants.UserDocumentSourceType.ChatAttachment;

    if (this.$txtReplyMessage.length > 0) {
      attachments = this.$txtReplyMessage.attachmentTextarea({
        jobId: this.options.jobId,
        attorneyId: this.options.attorneyId,
        userId: this.options.userId,
        source_type: sourceType
      });
    }
  },

  log: function() {
    if (this.options.debug) console.log(arguments);
  },

  registerSelectors: function() {
      this.$txtReplyMessage = this.$el.find('#txtReplyMessage');
      this.$chkEnterToSend = this.$el.find('#chkEnterToSend');
      this.$btnSendReply = this.$el.find('#btnSendReply');
      this.$messagesRow = this.$el.find('#messagesRow');
      this.$sentTimeAgo = this.$el.find('.sent-time-ago');
      this.$panelProposalContact = this.$el.find('#panelProposalContact');
      this.$panelExchangedMessages = this.$el.find('#panelExchangedMessages');
      this.$proposalContactButton = this.$el.find('.jp-contact-btn');
      this.attorneyCalendarUrl = this.$('.copy-share-calendar-url').val();

      this.$btnSendReplyLoader = this.$btnSendReply.buttonLoader();
  },

  registerEvents: function() {
    $(window).focus(function () {
        this.window_focus = true;
    }).blur(function () {
        this.window_focus = false;
    });
    this.$chkEnterToSend.change(this.onChkEnterToSendChange);
    this.$btnSendReply.click(this.onSendMessageClick.bind(this));
  },

  addMessageToThread: function(message, channel, by, documents) {
    var templateClass = '.template-{0}'.format(by),
        template = $(templateClass).clone(),
        time = new Date().getTime() / 1000;

    template.removeClass('hidden template-sender template-recipient').removeAttr('id');
    template.find('.chat-item-content-message').html(message);
    template.find('.chat-item-time').data('time', time).text('Just now');

    if (channel) {
      template.addClass('channel-{0}'.format(channel));
      template.find('.sent-channel').text(channel);
    }

    if (documents) {
      var $documents = $('<div class="jm-doc-list">');

      _.each(documents, function(doc) {
        var link_url = '/job/{0}/documents/{1}'.format(UC.job.id, doc._id);
        var link = '<a href="{0}" target="_blank"><i class="glyphicon glyphicon-file"></i> {1}</a>'.format(
          link_url, doc.FileName
        );
        $documents.append('<span class="jm-doc-document">{0}</span>'.format(link));
      });

      template.find('.chat-item-content-message').after($documents);
    }

    var oldTop = $(window).scrollTop(),
        oldReplyY = this.$btnSendReply.offset().top,
        newReplyY,
        diff;

    this.$messagesRow.append(template);
    newReplyY = this.$btnSendReply.offset().top;
    diff = newReplyY - oldReplyY;
    $(window).scrollTop(oldTop + diff);

    var messageSentCallbacks = this.options.messageSentCallbacks || []

    if (messageSentCallbacks && messageSentCallbacks.length > 0) {
      for (var i = 0; i < messageSentCallbacks.length; i++) {
        messageSentCallbacks[i]();
      }
    }

    this.startUpdateTimeAgo();

    return template;
  },

  showNotification: function(message) {
    UC.utils.showNotification(message, UC.constants.results.info, 0);
  },

  updateSentTimeAgo: function() {
      var $sentTimeAgo = $('.sent-time-ago');

      $sentTimeAgo.each(function() {
          var $el = $(this);
          var time = $el.data('time');

          if (time) {
            var timeObj = moment(time, 'X');
            var timeAgo = timeObj.fromNow(true);

            $el.text('{0} ago'.format(timeAgo));
          }
      });
  },

  startUpdateTimeAgo: function() {
    if (this.sentTimeInterval) {
      clearInterval(this.sentTimeInterval);
    }

    this.sentTimeInterval = setInterval(this.updateSentTimeAgo, 60000);
  },

  sendMessage: function(recipient, message, job, callback) {
      var data = {
          recipient: recipient,
          messageInput: message,
          job: job,
          AttachmentIds: attachments.getDocumentIds()
      };

      if (recipient && message) {
        UC.net.post('/messages', data, callback).fail(function() {
          this.$btnSendReplyLoader.showLoader();
        }.bind(this)).fail(function() {
          this.processingMessage = false;
          this.$btnSendReplyLoader.hideLoader();
        }.bind(this));
      }
  },

  onChkEnterToSendChange: function() {
      if (this.$chkEnterToSend.is(':checked') || this.options.sendMessageOnEnter) {
          UC.utils.registerEnterKey(this.$txtReplyMessage, this.onSendMessageClick);
      }
      else {
          UC.utils.unRegisterEnterKey(this.$txtReplyMessage);
      }
  },

  onMessageSend: function(err, data) {
    attachments.reset();
    this.$btnSendReplyLoader.hideLoader();
    this.processingMessage = false;
    if (err) {
        UC.utils.showNotification(err, UC.constants.results.failure);
    }
    else {
        if(this.$txtReplyMessage.val().indexOf(this.attorneyCalendarUrl) !== -1) {
          UC.utils.sendNesEvent('attorney_calendar_copy_url_clicked', { attorney_id: UC.user.id });
        }
        
        this.$txtReplyMessage.val('');
        if (data['channel'] == 'email') {
            UC.utils.showNotification('Message sent via email', UC.constants.results.success);
        }
        if(this.options.pageName == 'proposal'){
          UC.utils.hideComponent(this.$panelProposalContact);
          UC.utils.showComponent(this.$panelExchangedMessages);
          this.$proposalContactButton.attr('href', this.$proposalContactButton.data('url'));
        }
        else{
          this.addMessageToThread(data.message, data.channel, 'sender', data.documents);
        }
    }
  },

  onSendMessageClick: function(e) {
    if (e) {
        e.preventDefault();
    }

    if(!this.processingMessage){
        this.processingMessage = true;
        var message = this.$txtReplyMessage.val(),
          messageWrapper = this.$txtReplyMessage.parent();

        messageWrapper.removeClass('has-error');

        if (!/.+/.test(message)) {
            messageWrapper.addClass('has-error');
            this.processingMessage = false;
        } else {
            this.$btnSendReplyLoader.showLoader();
            var recipient = UC.user.id == this.options.jobOwnerId ? this.options.attorneyId : this.options.jobOwnerId;

            if (attachments.uploader.uploading) {
              $.when(attachments.uploader.uploading).done(function() {
                this.sendMessage(recipient, message, this.options.jobId, this.onMessageSend.bind(this));
              }.bind(this));
            } else {
              this.sendMessage(recipient, message, this.options.jobId, this.onMessageSend.bind(this));
            }
        }
    }
  },

  onNotify: function(data) {
    if (typeof  data == 'string') {
        showNotification(data);
    }
    else if (typeof data == 'object' && data.message) {
        showNotification(data.message);
    }
    else {
        showNotification(JSON.stringify(data));
    }
  },

  onError: function(data) {
    log(data);
  },

  onMessage: function(message) {
    if (message && message.ns) {
        switch (message.ns) {
            case this.namespaces.notify:
                onNotify(message.data);
                break;
            case this.namespaces.redirect:
                location.href = message.data;
                break;
            case this.namespaces.error:
                log(message);
                onError(message.data);
                break;
            default:
                log('Unknown namespace {0}'.format(message.ns));
                break;
        }
    }
  },

  initCentralMessages: function(newOptions) {
    $.extend(this.options, newOptions);

    this.registerSelectors();
    this.registerEvents();
    this.startUpdateTimeAgo();

    this.$txtReplyMessage.focus();

    attachments = this.$txtReplyMessage.attachmentTextarea({
      jobId: this.options.jobId,
      userId: this.options.userId,
      attorneyId: this.options.attorneyId,
      source_type: UC.constants.UserDocumentSourceType.ChatAttachment
    });
  },
});
