UC.views.AccountChangePassword = Backbone.View.extend({
  events: {
    'click #turn-off-mfa-confirm': 'disableMfa',
  },

  initialize: function() {
    this.passwordForm = new UC.components.PasswordForm({
      el: '#frmChangePassword'
    });
    if (location.hash === '#mfa-recovery-codes') {
      $('#mfa-recovery-codes-modal').modal('show');
    } else if (location.hash === '#turn-off-mfa') {
      $('#turn-off-mfa-modal').modal('show');
    }
  },

  disableMfa: function(e) {
    const loader = $(e.target).buttonLoader();

    loader.showLoader();
    UC.net.destroy(
        '/account/mfa/disable',
        {},
        function (response) {
          loader.hideLoader();
          window.location = response.url || window.location.reload();
        }
    ).fail(function() {
      loader.hideLoader();
      $('#turn-off-mfa-modal').modal('hide');
    });
  }

});
