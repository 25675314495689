!function() {

  UC.views.UserDocumentsBulkActions = Backbone.View.extend({

    events: {
      'click .share': 'share',
      'click .delete': 'destroy'
    },

    initialize: function(options) {
      this.index = options.index;

      this.index.$el.on('change', '.bulk-check',
        this.updateActionsRow.bind(this));
    },

    updateActionsRow: function() {
      var selected_count = this.gatherCheckedRows().length;
      var $tableHeaderText = $('.header-text');

      if (selected_count > 0) {
        var word = selected_count == 1 ? 'document' : 'documents';
        this.$el.find('.doc-list-actions-stats')
          .text('{0} {1} selected'.format(selected_count, word));
        this.$el.show();
        $tableHeaderText.addClass('invisible');
      } else {
        this.$el.hide();
        $tableHeaderText.removeClass('invisible');
      }
    },

    gatherCheckedRows: function() {
      return _.filter(this.index.rows, function(row) {
        return row.isChecked();
      });
    },

    gatherCheckedIds: function() {
      return _.map(this.gatherCheckedRows(), function(row) {
        return row.$el.data('id');
      });
    },

    share: function(e) {
      e.preventDefault();
      this.index.modal_share.render({
        document_ids: this.gatherCheckedIds(),
        onPermissionsUpdated: function(_documentIds, open) {
          _.each(this.gatherCheckedRows(), function(row) {
            row.updateVisibilityText(open);
          });
        }.bind(this)
      });
    },

    gatherCheckedChildren: function() {
      return _.map(this.gatherCheckedRows(), function(row) {
        return row.$el.data('child');
      });
    },

    destroy: function(e) {
      e.preventDefault();

      var that = this;
      var ids = this.gatherCheckedChildren();
      var title = 'Delete Document';
      var body = 'Are you sure you want to delete';

      if (ids.length > 1) {
        title += ' ({0} items)'.format(ids.length);
        body += ' those documents?'
      } else {
        body += ' 1 document?'
      }
  
      new UC.partials.modalV9 ({
        modalTitle: title,
        modalContent: body,
        modalFooter: {
          yesTextClass: 'btn-lg btn-primary js-confirm-delete',
          yesText: 'Confirm',
          noTextClass: 'btn-lg btn-grey-BG',
          noText: 'Cancel'
        },
        renderCallback: function() {
          $('.js-confirm-delete').on('click', function() { that.index.destroy(ids); });
        }
      });
    }
  });

}();
