UC.views.AccountNewCreditCard = Backbone.View.extend({

  events: {
    'click #btnSubmitCreditCard' : 'submit',
    'change #selectCountry' : 'toggleCountry'
  },

  initialize: function(args) {
    this.$el.find('#cvc-info').tooltip();
    this.$el.find('select').select2({ width: '100%' });
    this.btnLoader = this.$el.find('#btnSubmitCreditCard').buttonLoader();

    this.$month = this.$el.find('#selectMonth');
    this.$year= this.$el.find('#selectYear');
    this.$street = this.$el.find('#txtAddress');
    this.$city = this.$el.find('#txtCity');
    this.$state = this.$el.find('#selectState');
    this.$zip = this.$el.find('#txtZipCode');
    this.$country = this.$el.find('#selectCountry');
    this.$agreeToTermsCheckboxes = this.$el.find('.js-agreeToTermsCheckbox');
    this.$agreeToTermsDates = this.$el.find('.js-agreeToTermsDate');
    this.$default = this.$el.find('#chkDefaultMethod');
    this.$default.fancyCheck();
    this.$isExistChkDefaultMethod = this.$default.length != 0

    this.$name = this.$el.find('#txtNameOnCard');
    this.$number = this.$el.find('#txtCardNumber');
    this.$cvc = this.$el.find('#txtCvc');
    this.saveCreditCardSuccessCallback = args.saveCreditCardSuccessCallback;

    this.data = {};
    this.token = null;
    this.isDefaultCreditCard = false;
    this.savePaymentURL = args.savePaymentURL;
    this.scrollToFirst = args.scrollToFirst !== undefined ? args.scrollToFirst : true;
  },

  isAgreeToTermsChecked: function() {
    var isChecked = this.$agreeToTermsCheckboxes.filter(":checked").length;
    var isAgreedToPreviously = this.$agreeToTermsDates.length;

    return isChecked || isAgreedToPreviously;
  },

  submit: function(e) {
    e.preventDefault();
    if (this.validate()) {
      this.btnLoader.showLoader();
      UC.payments.tokenizeCard(UC.payments.createCreditCardData(this.data), this.onCreateCreditCard.bind(this));
    }
  },

  onCreateCreditCard: function (err, token, card) {
    if (err) {
      this.btnLoader.hideLoader();
      UC.utils.showNotification(err, UC.constants.results.failure, 10000);
    }
    else {
      this.token = token;
      if (UC.user.type === 'attorney') {
        if (!this.$authorizationModal) {
          this.$authorizationModal = new UC.partials.CreditCardAuthorizationModal(
            {
              agreedCallback: function() {
                if (!this.$isExistChkDefaultMethod || (this.$isExistChkDefaultMethod && this.$default.hasClass('checked'))) {
                  this.createCreditCard();
                  return;
                }

                this.makeCreditCardDefault()
              }.bind(this),
              dismissedCallback: function() {
                this.btnLoader.hideLoader();
              }.bind(this)
            }
          )
        }
        this.$authorizationModal.show(
          {
            funding: card.funding,
            brand: card.brand,
            number: this.$number.val(),
            cvc: this.$cvc.val(),
            exp_month: this.$month.val(),
            exp_year: this.$year.val(),
            name: this.$name.val(),
            address: {
              street: this.$street.val(),
              city: this.$city.val(),
              state: this.$state.val(),
              zip: this.$zip.val(),
            }
          }
        )
      } else {
        if (!this.$isExistChkDefaultMethod || (this.$isExistChkDefaultMethod && this.$default.hasClass('checked'))) {
          this.createCreditCard();
          return;
        }

        this.makeCreditCardDefault()
      }
    }
  },

  createCreditCard: function() {
    var data = {
      token: this.token,
      type: 'credit_card',
      nextUrl: UC.constants.nextUrl,
      makeTokenDefault: this.$default.hasClass('checked') || this.isDefaultCreditCard,
      isAgreeToTermsChecked: !!this.isAgreeToTermsChecked(),
      redirectSuccess: (typeof this.saveCreditCardSuccessCallback !== 'function')
    };
    var self = this;

    UC.net.post(this.savePaymentURL ? this.savePaymentURL : '/account/save_payment_method', data)
      .fail(function () {
        self.btnLoader.hideLoader();
      }).success(function () {
      if (self.saveCreditCardSuccessCallback) {
        self.saveCreditCardSuccessCallback();
      }
      if (location.hash === '#checklist') {
        location.href = '/dashboard';
      }
    });
  },

  makeCreditCardDefault: function() {
    function onConfirm(confirm) {
      this.isDefaultCreditCard = confirm
      this.createCreditCard()
    }

    UC.utils.showConfirmModal('', 'Would you like to make this your default payment method?', onConfirm.bind(this));
  },

  validate: function () {
    $('.form-group').removeClass('has-error');
    var errors = [];
    var now = new Date();

    this.data.nameOnCard = this.$name.val();
    this.data.cardNumber = this.$number.val();
    this.data.expireMonth = this.$month.val();
    this.data.expireYear = this.$year.val();
    this.data.address = this.$street.val();
    this.data.city = this.$city.val();
    this.data.state = this.$state.val();
    this.data.zip = this.$zip.val();
    this.data.country = this.$country.val();
    this.data.cvc = this.$cvc.val();
    this.data.isAgreeToTermsChecked = !!this.isAgreeToTermsChecked();

    if (!this.data.nameOnCard) {
      errors.push({element: this.$name, text: 'Please enter the name on your card.'});
    }

    if (!UC.payments.validateCardNumber(this.data.cardNumber)) {
      errors.push({element: this.$number, text: 'Invalid credit card number.'});
    }

    if (this.data.expireYear < now.getFullYear() || (this.data.expireYear == now.getFullYear() && this.data.expireMonth < now.getMonth() + 1)) {
      errors.push({element: this.$month, text: 'Invalid expiration date.'});
    }

    if (!UC.payments.validateSecurityCode(this.data.cvc)) {
      errors.push({element: this.$cvc, text: 'Invalid CVC number.'});
    }

    if (!this.data.address) {
      errors.push({element: this.$street, text: 'Please enter your billing address.'});
    }

    if (!this.data.city) {
      errors.push({element: this.$city, text: 'Please enter your billing city.'});
    }

    if (!this.data.country) {
      errors.push({element: this.$country, text: 'Please enter your billing country.'});
    } else if (this.data.country === 'USA') {
      if (!this.data.zip || !UC.utils.validateZipCode(this.data.zip)) {
        errors.push({element: this.$zip, text: 'Please enter a valid billing zip code.'});
      }

      if (!this.data.state) {
        errors.push({element: this.$state, text: 'Please select your billing state.'});
      }
    } else {
      delete this.data.zip;
      delete this.data.state;
    }

    if (!this.data.isAgreeToTermsChecked) {
      errors.push({ element: this.$agreeToTermsCheckboxes });
    }

    UC.utils.showValidationErrors(errors, {scrollToFirst: this.scrollToFirst, className: 'form-group'});
    return errors.length === 0;
  },

  toggleCountry: function(e) {
    if(this.$country.val() === 'USA'){
      this.$state.prop('disabled', false).trigger('change');
      $('#state-zip-input-row').removeClass('hidden');
    } else{
      this.$state.prop('disabled', true).trigger('change');
      $('#state-zip-input-row').addClass('hidden');
    }
  },

  clearForm: function() {
    $('.form-group').removeClass('has-error');
    this.$name.val('');
    this.$number.val('');
    this.$month.val();
    this.$year.val();
    this.$street.val('');
    this.$city.val('');
    this.$state.val('');
    this.$zip.val('');
    this.$country.val('');
    this.$cvc.val('');
  }
});
