var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <li class=\"active\">\n          <a class=\"text-uppercase\">Current</a>\n        </li>\n        <li>\n          <a id=\"js-invoiced-timesheets-tab\" class=\"text-uppercase track-click\" data-event-name=\"attorney_invoiced_timesheet_entries_click\">\n            Invoiced\n          </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <li>\n          <a id=\"js-current-timesheets-tab\" class=\"text-uppercase track-click\" data-event-name=\"attorney_current_timesheet_entries_click\">\n            Current\n          </a>\n        </li>\n        <li class=\"active\">\n          <a class=\"text-uppercase\">Invoiced</a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <ul class=\"nav nav-tabs alt-tabs no-bottom-border marg-b-20 inline-block\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isShowingCurrentTimesheetsTab") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "  </ul>\n</div>";
},"useData":true});