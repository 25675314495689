!function() {

  UC.views.AttorneyClientsIndex = Backbone.View.extend({

    events: {
      'change #selectAttorneyClient' : 'selectAttorneyClient'
    },

    initialize: function() {
      $('#attorney-client-list').stickyCssTableHeader();

      this.$el.find('#selectAttorneyClient').superSmartSearch({
        url: UC.constants.smartSearchPath,
        width: '100%',
        placeholder: 'Search for a client'
      });
    },

    selectAttorneyClient: function(e) {
      var clientId = $(e.currentTarget).val();
      if (clientId) {
        location.href = "{0}/{1}?current_ts_entries=true".format(UC.constants.attorneyClientsPath, clientId);
      }
    }

  });
}();
