!function() {

  UC.views.UsersNew = Backbone.View.extend({

    initialize: function() {

      this.signupForm = new UC.partials.UsersForm({
        onRegister: this.showPendingPanel.bind(this)
      });

      var titleTextOptionMapping = {
        user_Type_0: 'Sign Up as a Lawyer',
        user_Type_1: 'Sign Up as a Client'
      };

      this.$('#user_Type_0, #user_Type_1').change(function(e) {
        var $selectedRadio = $(e.currentTarget),
        selectedRadioId = $selectedRadio.attr('id');

        var titleText = titleTextOptionMapping[selectedRadioId];

        if (titleText) {
          this.$('#regTitle').text(titleText);
        }

        this.signupForm.togglePhonePanel(selectedRadioId);

      }.bind(this));

      if (location.hash.toLowerCase() == '#attorney') {
        this.$('#user_Type_0').prop('checked', true).trigger('change');
      }
    },

    showPendingPanel: function(registerResult, userData) {
      UC.utils.scrollToTop();
      this.$('#rowRegistration').addClass('hide');
      this.$('#panelPending').removeClass('hide');

      if (userData && userData.Type) {
        // Track conversions from though Paid Google Ads, LinkedIn Ads, etc
        // https://upcounsel.atlassian.net/browse/UPC-628
        location.hash = userData.Type === '0' ? '#pending-attorney' : '#pending-client';
      }
    }
  });
}();
