!function() {

  UC.views.StaticPagesStyleguide = Backbone.View.extend({
    events: {
      'click .modal-confirm-btn': 'hideCheckboxModal'
    },

    initialize: function () {
      UC.components.checkboxModal({
        modalSelector: '#styleguide-checkbox-modal-id'
      });
    },

    hideCheckboxModal: function () {
      $('.modal').modal('hide');
    }
  });

}();
