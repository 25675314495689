UC.CallHelpers = {
  rescheduleCall: function(scheduledCallId, jobId, bidId, attorneyId, instantAvailable, onSuccessfulSchedule){
    if (instantAvailable) {
      new UC.partials.ScheduledCallsInstant({
        attorneyId: attorneyId,
        jobId: jobId,
        bidId: bidId,
        onSuccessfulSchedule: onSuccessfulSchedule
      }).goHam();
    } else {
      new UC.modals.ScheduledCallsNew({
        attorney_id: attorneyId,
        jobId: jobId,
        onRequest: onSuccessfulSchedule
      }).render();
    }
  }
};