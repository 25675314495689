$.fn.smartSearch = function(options) {
  var that = this,
      options = options || {},
      defaults = {
        minLength: 2,
        delay: 50,
        autoFocus: true,
        select: defaultSelect,
        data: function() { return {}; }
      };

  options = $.extend({}, defaults, options);

  if (!options.url) {
    throw 'You must provide a request url';
  }
  else {
    options.source = function(request, callback) {
      var data = $.extend(options.data(), { term: request.term });
      var url = options.url + '?' + $.param(data);

      UC.net.get(url, function(err, data) {
        if (err) {
          UC.utils.showNotification(err, UC.constants.results.failure);
        } else {
          callback(data);
        }
      });
    };
  };

  var opt = $.fn.extend({}, defaults, options);

  function defaultSelect(e, data) {
    e.preventDefault();
    $(e.target).val(data.item.label);
    $(e.target).attr('smartSearchSelectedId', data.item.id);
  };

  return that.each(function() {

    var autocomplete = $(this).autocomplete(opt).focus(function(e) {
      $(this).autocomplete('search');
    }).data('ui-autocomplete');

    if (typeof opt.render === 'function') {
      autocomplete._renderItem = opt.render;
    }
  });
};
