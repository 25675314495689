$.fn.truncateToggleMultiple = function(options) {
  // For when you want to enable many elements with their own toggle

  var defaults = {
    truncateLength: 200,
    moreText: 'Read more',
    lessText: 'Show less',
    toggleClass: 'truncated'
  };

  return this.each(function() {
    var opt = $.fn.extend({}, defaults, options);
    var $this = $(this);
    var text = $this.text();

    if (text.length <= opt.truncateLength) return;

    var html = text.slice(0, opt.truncateLength) + "<span class='shown-min'>... </span>" +
      "<a href='#' class='shown-min'>" + opt.moreText + "</a><span class='shown-max' style='display:none;'>" +
      text.slice(opt.truncateLength, text.length) + "<a href='#' class='shown-max'>" +
      opt.lessText + "</a></span>";

    $this.html(html);
    $this.addClass(opt.toggleClass);

    $this.find('a.shown-min').click(function(e) {
      e.preventDefault();
      var $el = $(e.currentTarget)[0].parentElement;
      $($el).find('.shown-min').hide();
      $($el).find('.shown-max').show();
      $($el).removeClass(opt.toggleClass);
    });

    $this.find('a.shown-max').click(function(e) {
      e.preventDefault();
      var $el = $(e.currentTarget)[0].parentElement.parentElement;
      $($el).find('.shown-min').show();
      $($el).find('.shown-max').hide();
      $($el).addClass(opt.toggleClass);
    });

  });
};
