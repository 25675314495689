!function() {

  UC.views.JobsAddProject = Backbone.View.extend({

    events: {
      'submit #frmAddProject': 'create',
      'change #UserId': 'fetchAttorneyClient',
      'change #task-selector-legal-subject': 'populateProjectTitle',
      'change #ProjectTitle': 'onChangeProjectTitle',
      'change #OngoingProject': 'changeBidType',
      'click .add-project-back': 'prevStep',
      'click .add-project-next': 'nextStep'
    },

    initialize: function() {
      this.current_step = this.$el.find('.add-project-step:visible').data('step');
      this.total_steps = this.$el.find('.add-project-step').length;
      this.$form = this.$el.find('#frmAddProject');
      this.$attorneyFee = this.$('#js-attorney-fee-notice');

      this.projectTitleAutoPopulated = false;

      this.task_selector = new UC.partials.JobsTaskSelector({
        onChangeSelectionType: this.onChangeSelectionType.bind(this)
      });
      this.quote_form = new UC.partials.ProposalsFormQuote();

      this.$el.find('#UserId').select2();
      this.$el.find('.datepicker').datepicker(UC.constants.datepickerOptions);
      this.$el.find('.icon-calendar').click(function(e) {
        $(e.target).closest('.input-with-icon-right').find('input').focus();
      });

      this.$el.find('.add-project-submit').each(function() {
        $(this).buttonLoader();
      });

      // this needs to come before confirmUnload
      this.$el.find('#UserId').trigger('change');
      this.confirm_unload = this.$el.find('#content-body').confirmUnload();

      if (!UC.constants.hasPayout && !UC.constants.adminProposal) {
        this.$el.find('#modalNoBankAccount').modal({ keyboard: false, backdrop: 'static' });
      } else if(UC.constants.unverifiedAccount && !UC.constants.adminProposal){
        this.$el.find('#modalUnverifiedAccount').modal({ keyboard: false, backdrop: 'static' });
      }

      $('[data-toggle="tooltip"]').tooltip();
    },

    changeBidType: function (event) {
      event.preventDefault();

      if (event.target.checked) {
        this.quote_form.showHourlyFee();

        if (this.attorney_client && this.attorney_client.HourlyRate) {
          this.$el.find('#txtServiceFees').val(UC.utils.formatCurrency(this.attorney_client.latest_hourly_rate || 0));
        } else if (this.attorney_client && this.attorney_client.HourlyRate) {
          this.$el.find('#txtServiceFees').val(UC.utils.formatCurrency(this.attorney_client.latest_hourly_rate || 0));
        }

        UC.utils.recordKissEvent('selected_ongoing_project', {attorneyClientId: this.attorney_client.id, attorneyId: UC.user.id});
      } else {
        this.quote_form.showFixedFee();
      }
    },

    onChangeSelectionType: function(type) {
      UC.utils.recordKissEvent('add project - selection type changed', {
        selectionType: type,
        followUpInvoiceId: UC.constants.followUpInvoiceId
      });
    },

    // hack because fancy check currently doesn't use a "shadow input"
    setHiddenCallInput: function(e, checked) {
      this.$offer_call.val(checked);
    },

    fetchAttorneyClient: function(e) {
      var $selected = $(e.currentTarget).find('option:selected');
      var client_id = $selected.data('attorney-client-id');

      if (client_id) {
        var path = '/clients/{0}.json'.format(client_id);

        UC.net.get(path, function(attorney_client) {
          this.onFetchAttorneyClient(attorney_client);
        }.bind(this));
      }
    },

    onFetchAttorneyClient: function(attorney_client) {
      this.attorney_client = attorney_client;

      this.$('#forUserTitle').text("for " + this.attorney_client.FirstName + " " + this.attorney_client.LastName);
      this.$('#hourlyClientName').text(this.attorney_client.FirstName + " " + this.attorney_client.LastName);
      var hourly_rate = 0;
      if (this.attorney_client.HourlyRate) {
        hourly_rate = this.attorney_client.HourlyRate;
      } else if (this.attorney_client.latest_hourly_rate) {
        hourly_rate = this.attorney_client.latest_hourly_rate;
      } else {
        hourly_rate = 0;
      }
      this.$('#txtServiceFees').val(UC.utils.formatCurrency(0));
      this.$('#txtFilingFees').val(UC.utils.formatCurrency(0));
      this.$('#txtEstimatedHours').val(UC.utils.formatCurrency(0));

      if (this.attorney_client.User.Collections === true) {
        this.showClientInCollections();
      }

      if (this.attorney_client.transaction_type === "USC") {
        this.$el.find('.repeat-usc-client-only').removeClass('hidden');
      }

      this.quote_form.render({
        payment_profile: this.attorney_client.payment_profile,
        transaction_type: this.attorney_client.transaction_type,
        hourly_rate: hourly_rate,
        client_id: this.attorney_client.UserId,
        has_invoices: this.attorney_client.has_invoices
      });

      this.$el.find('#OngoingProject').trigger('change');
    },

    populateProjectTitle: function(event) {
      var $projectTitleField = this.$el.find('#ProjectTitle');
      var projectTitle = $projectTitleField.val();
      var legalSubjectName = this.$el.find('#task-selector-legal-subject option:selected').text();

      if ((!projectTitle || this.projectTitleAutoPopulated) && legalSubjectName) {
        $projectTitleField.val(legalSubjectName);
        this.projectTitleAutoPopulated = true;
      }
    },

    onChangeProjectTitle: function(event) {
      this.projectTitleAutoPopulated = false;
    },

    showClientInCollections: function(e) {
      var text = "This client's account is not currently in good standing with UpCounsel. While you are certainly welcome to continue work with the client, we cannot guarantee any ensuing payments until the account is settled. If you do wish to proceed, we recommend that you request a significant portion of the payment upfront.";

      function onConfirm(response) {
        if (response) {
          UC.utils.recordKissEvent('collections_client_notification_clicked_project_page', {
            attorneyId: UC.user.id,
            followUpInvoiceId: UC.constants.followUpInvoiceId,
            clientId: this.attorney_client.UserId
          });
        }
      }

      UC.utils.recordKissEvent('collections_client_notification_shown_project_page', {
        attorneyId: UC.user.id,
        followUpInvoiceId: UC.constants.followUpInvoiceId,
        clientId: this.attorney_client.UserId
      });

      UC.utils.showConfirmModal('Are You Sure?', text, onConfirm.bind(this), {
        yesText: 'Ok',
        noText: 'Cancel'
      });
    },

    prevStep: function(e) {
      e.preventDefault();
      this.showStep(this.current_step - 1);
    },

    nextStep: function(e) {
      if (e) e.preventDefault();

      if (this.validate()) {
        this.resetValidations();
        this.showStep(this.current_step + 1);
      }
    },

    toggleAttorneyFeeNotice: function () {
      var paymentProfile = this.attorney_client.payment_profile;

      if (paymentProfile.attorney_fee_disclaimer && this.current_step == 2) {
        this.$attorneyFee.text("** " + paymentProfile.attorney_fee_disclaimer);
        this.$attorneyFee.removeClass('hide')
      } else {
        this.$attorneyFee.addClass('hide')
      }
    },

    showStep: function(step) {
      this.current_step = step;
      this.$current_step = this.$el.find('.add-project-step[data-step={0}]'.format(this.current_step));

      this.$el.find('.add-project-step').removeClass('active');
      this.$current_step.addClass('active');

      UC.utils.scrollToTop();

      UC.utils.recordKissEvent('Create project - Step shown', {
        step: this.current_step,
        title: this.$current_step.find('h3').text(),
        followUpInvoiceId: UC.constants.followUpInvoiceId
      });

      this.toggleAttorneyFeeNotice()
    },

    resetValidations: function() {
      UC.utils.clearValidationErrors('form-group', this.$el);
    },

    validate: function(all_steps) {
      this.resetValidations();

      var errors = [];

      // add any errors from the task selector
      errors = errors.concat(this.task_selector.errors());

      var $client_id = this.$el.find('#UserId');
      if (!$client_id.val()) {
        errors.push({ element: $client_id, text: 'Please select a client' });
      }

      var $project_title = this.$el.find('#ProjectTitle');
      if (!$project_title.val().trim()) {
        errors.push({ element: $project_title, text: 'Please provide a project title' });
      }

      var $project_description = this.$el.find('#ProjectDescription');
      if (!$project_description.val().trim()) {
        errors.push({ element: $project_description, text: 'Please provide a project description' });
      }

      // add any errors from the quote form
      errors = errors.concat(this.quote_form.errors());

      UC.utils.showValidationErrors(errors, { className: 'form-group' });

      if (errors.length > 0 && (all_steps || this.errorsVisible())) {
        UC.utils.scrollToElement(errors[0].element);
        return false;
      } else {
        return true;
      }
    },

    errorsVisible: function() {
      return this.$form.find('.has-error:visible').length > 0;
    },

    create: function(e) {
      e.preventDefault();

      if (this.current_step < this.total_steps) {
        this.nextStep();
        return;
      }

      if (this.validate(true)) {

        this.confirm_unload.resetChanged();
        this.showLoaders();

        UC.net.post('/job/submit_project', this.$form.serializeJSON()).fail(function() {
          this.hideLoaders();
        }.bind(this));
      }
    },

    showLoaders: function() {
      this.$el.find('.add-project-submit').each(function() {
        $(this).data('button-loader').showLoader();
      });
    },

    hideLoaders: function() {
      this.$el.find('.add-project-submit').each(function() {
        $(this).data('button-loader').hideLoader();
      });
    }
  });
}();
