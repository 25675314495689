UC.views.JobsNew = Backbone.View.extend({

  initialize: function() {
    const viewOptions = {el: '#content'};
    if (UC.constants.pajVersion === 'v7') {
      new UC.partials.V7PAJ(viewOptions);
    } else if (UC.constants.pajVersion === 'v6') {
      new UC.partials.V6PAJ(viewOptions);
    } else if (UC.constants.pajVersion === 'v5') {
      new UC.partials.V5PAJ(viewOptions);
    } else {
      new UC.partials.V4PAJ(viewOptions);
    }
  }

});
