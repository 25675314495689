UC.partials.JobHeader = Backbone.View.extend({
  el: 'body',

  events: {
    'click .decline-proposal-request' : 'onDeclineProposalReqClick',
    'click .user-job-report' : 'onUserJobReportClick',
    'click #jobActionsList a' : 'onJobActionListClick'
  },

  initialize: function() {
    new UC.partials.JobNavMenu();
    new UC.views.JobManageCog({ el: '.manage-job-cog' });
    if (this.$el.find('.decline-proposal-request').length > 0) {
      this.attorneyJobFeedbackModal = new UC.modals.AttorneyJobFeedbackModal();
    } else if (this.$el.find('.user-job-report').length > 0) {
      this.userJobReportModal = new UC.modals.UserJobReportModal();
    }

    this.setActiveTab();

    UC.utils.recordKissClickEvent('.ji-bid-btn', 'Attorney job portal - submit proposal / cta');
    UC.utils.recordKissClickEvent('.btnJobActions', 'Job Page: Manage Job Click');
  },

  onUserJobReportClick: function(e) {
    e.preventDefault();

    if(this.userJobReportModal) {
      this.userJobReportModal.render();
    }
  },

  onDeclineProposalReqClick: function(e) {
    e.preventDefault();

    if(this.attorneyJobFeedbackModal) {
      this.attorneyJobFeedbackModal.render();
    }
  },

  onJobActionListClick: function(e) {
    UC.utils.handleGenericClick(this.$el, 'Job Page: Mangage Item Click');
  },

  setActiveTab: function() {
    if (UC.constants.pageName) {
      $('.jn-{0}'.format(UC.constants.pageName)).addClass('active');
      $('.h-nav-{0}'.format(UC.constants.pageName)).addClass('active');
    }
  }
});
