!function() {

  UC.views.EducationCenterIndex = Backbone.View.extend({

    events: {
      'click #video-modals a': 'onClickVideoModalLink'
    },

    initialize: function() {
      $(window).resize(this.updateVideoModalHeights);
      this.updateVideoModalHeights();
      this.showVideoModalByHash();
    },

    updateVideoModalHeights: function() {
      $('.video-modal-iframe').css('height', $('body').height() * 0.6);
    },

    showVideoModalByHash: function() {
      var elementId = window.location.hash
      var element = $(elementId)

      if (elementId && element.length === 0) {
        UC.utils.showNotification('Video was not found', UC.constants.results.failure)
      } else {
        this.showVideoModal(window.location.hash);
      }
    },

    onClickVideoModalLink: function(e) {
      e.preventDefault();
      var id = $(e.currentTarget).data('id');
      var elementId = '#{0}'.format(id);
      window.location.hash = elementId;
      this.showVideoModal(elementId)
    },

    showVideoModal: function(elementId) {
      this.elementId = elementId
      $(elementId).on('hidden.bs.modal', this.onHideVideoModal.bind(this));
      $(elementId).modal('show');
    },

    onHideVideoModal: function() {
      var iframeElement = this.elementId + ' iframe';
      $(iframeElement).attr('src', $(iframeElement).attr('src'));
      history.pushState('', document.title, window.location.pathname);
    }
  });
}();
