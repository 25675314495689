UC.views.SourcingQuestionnairesShow = Backbone.View.extend({
  events: {
    'submit' : 'submit',
    'click #best_practices_info_link' : 'update_best_practice_labels',
    'click #tos_info_link' : 'update_tos_labels',
  },

  initialize: function () {
    this.$form = this.$el.find('#questionnaire-response-form');

    if (!UC.user.is_admin) {
      this.submitButtonLoader = this.$el.find('#submit-response-button').buttonLoader();
    }

    this.$el.find('select[name="user[Address][State]"]').select2({ width: '100%' });
    this.$el.find('#select-bar-states').select2({ width: '100%' });

    this.$best_practices_info = this.$el.find('.best_practices_info');
    this.$best_practices_info_link = this.$el.find('#best_practices_info_link');
    this.$tos_info = this.$el.find('.tos_info');
    this.$tos_info_link = this.$el.find('#tos_info_link');
  },

  submit: function (event) {
    event.preventDefault();
    this.submitButtonLoader.showLoader();

    var fields = _.map(this.$el.find('.question-container'), function (container) {
      var selector = container.dataset['fieldSelector'];
      return { element: $(container).find(selector) };
    })

    if (UC.utils.validateFields(fields, { scrollToFirst: true })) {
      var $selectBarStates = this.$el.find('#select-bar-states')
      var data = this.$form.serializeJSON();

      if ($selectBarStates.length > 0) {
        data["user"]["StatedBarCertifiedStates"] = $selectBarStates.val();
      }

      UC.net.post(UC.constants.questionnaireSubmitPath, data, function(response) {
        window.location.href = '/dashboard'
      }.bind(this)).fail(function() {
        this.submitButtonLoader.hideLoader();
      }.bind(this));;
    } else {
      this.submitButtonLoader.hideLoader();
    }
  },

  update_best_practice_labels: function() {
    this.$best_practices_info_link.text(this.$best_practices_info.hasClass('in') ? 'Show' : 'Hide');
  },

  update_tos_labels: function() {
    this.$tos_info_link.text(this.$tos_info.hasClass('in') ? 'View requirements' : 'Hide requirements');
  },
});

// disables editing on all inputs if questionnaire already exists.
UC.constants.questionnaireReadonly &&
  $(
    [
      ".sourcing_questionnaires.show input",
      ".sourcing_questionnaires.show textarea"
    ].join()
  ).attr("disabled", true);
