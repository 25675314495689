export default Backbone.View.extend({

  initialize: function(options) {
    this.errorContainer = options.errorContainer || '.form-group';
    this.minLength = options.minLength || 100;
    this.maxLength = options.maxLength || 10000;
    this.updateDescriptionLengthCounterDebounced = _.debounce(this.updateDescriptionLengthCounter, 500);
    this.textarea = $(this.$el.find(options.textarea));
    this.textarea.on('keyup', function(e){
      this.updateDescriptionLengthCounterDebounced(e.target.value || '');
    }.bind(this));
    this.counter = $(this.$el.find(options.counter));
    this.update();
  },

  update: function() {
    this.updateDescriptionLengthCounter(this.textarea.val());
  },

  updateDescriptionLengthCounter: function(text) {
    this.counter.text(`${text.length}/${this.maxLength}`)
    if (text.length >= this.minLength) {
      this.textarea.closest(this.errorContainer).removeClass('has-error');
    }
  },

  hasValidTextLength: function() {
    const length = this.textarea.val().length;
    return length >= this.minLength && length <= this.maxLength;
  },

  getError: function() {
    if (!this.hasValidTextLength()) {
      const length = this.textarea.val().length;

      if (length < this.minLength) {
        return {element: this.textarea, text: `Use at least ${this.minLength} characters`};
      } else if (length > this.maxLength) {
        return {element: this.textarea, text: `Use less than ${this.maxLength} characters`};
      }
    }

    return undefined;
  },
});
