import TextLengthCounter from '../../../components/text_length_counter'
import { GeoLocation } from '../../../components/geo_location'

UC.partials.AiForm = Backbone.View.extend({

  events: {
    'change #job_lead_ZipCode': 'onZipCodeChanged',
  },

  routes: {
    validZipCode: '/account/valid_zip_code/'
  },

  initialize: function(options) {
    this.descriptionLengthCounter = new TextLengthCounter({
      el: this.$el,
      errorContainer: '.form-group',
      textarea: '#job_lead_Description',
      counter: '.js-description-length'
    })

    this.$zipCode = $('#job_lead_ZipCode');

    if (this.$zipCode.val().length > 0) {
      this.onZipCodeChanged();
    }
  },

  validate: function(dfd) {
    this.$el.find('.has-error').removeClass('has-error');

    let errors = [];
    const job_lead = this.$el.serializeJSON().job_lead;

    const data = {
      ai_form: {
        description: job_lead.Description,
        email: job_lead.Email,
        phone: job_lead.Phone,
        timeline: job_lead.Timeline,
        zip_code: job_lead.ZipCode
      }
    }

    UC.constants.aiForm = data.ai_form

    if (!this.descriptionLengthCounter.hasValidTextLength()) {
      errors.push(this.descriptionLengthCounter.getError());
    }

    if (data.ai_form.phone && data.ai_form.phone.length > 0 &&
        !UC.utils.validatePhone(data.ai_form.phone)) {
      errors.push({element: $('#job_lead_Phone'), text: 'Please enter a valid phone number'});
    }

    if (data.ai_form.email && data.ai_form.email.length > 0 &&
        !UC.utils.validateEmail(data.ai_form.email)) {
      errors.push({element: $('#job_lead_Email'), text: 'Please enter a valid email'});
    }

    if (errors.length === 0) {
      if (!data.ai_form.zip_code) {
        errors.push({element: this.$zipCode, text: 'Please enter a valid zip code.'});
      } else if (UC.utils.validateZipCode(data.ai_form.zip_code)) {
        this.checkZipCodeValid(data, dfd);
        return; // return here so that lower dfd.resolve() is not called
      } else {
        errors.push({element: this.$zipCode, text: 'Please enter a valid zip code.'});
      }
    }

    console.log('errors: ' + errors);
    console.log('data: ' + data);


    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, { className: "form-group" });
      dfd.reject();
    } else {
      dfd.resolve(data);
    }
  },

  onZipCodeChanged: function(e) {
    this.$zipCode.closest('.has-error').removeClass('has-error');

    $('.new-job-location').addClass('hidden');

    if (UC.utils.validateZipCode(this.$zipCode.val())) {
      this.checkZipCodeValid({ ai_form: { zip_code: this.$zipCode.val() } }, new $.Deferred());
    } else {
      errors.push({element: this.$zipCode, text: 'Please enter a valid zip code.'});
    }
  },

  checkZipCodeValid: function (data, dfd) {
    const path = '{0}{1}'.format(this.routes.validZipCode, data.ai_form.zip_code);

    UC.net.get(path, {job_lead_id: UC.constants.jobLeadId}, function(res) {
      $('.new-job-location').text(`${res.zip.CityName}, ${res.zip.StateAbbr}`).removeClass('hidden');
      if (res.suggested_attorneys) {
        $('.row.face-piles').replaceWith(res.suggested_attorneys);
      }
      new GeoLocation().storeUserChoice(data.ai_form.zip_code);
      dfd.resolve(data);
    }).fail(function(res) {
      res.handled = true;
      UC.utils.showValidationErrors(
        [
          {
            element: this.$zipCode,
            text: res.responseJSON ? res.responseJSON.message : 'Failed to validate the Zip Code'
          }
        ], { className: "form-group" }
      );
      dfd.reject();
    }.bind(this));
  }

});

