import './accept_proposal'

!function() {

  UC.views.ProposalsShow = Backbone.View.extend({

    events: {
      'click .jp-contact-btn': 'focusReply'
    },

    initialize: function() {
      this.processAction();

      if (UC.constants.showProposalTour === true) {
        this.showTour();
        UC.net.post('/account/update_help_display_option', {
          option: 'single_proposal_tour', optionValue: true
        });
      }

      UC.utils.initRatingDimensions('.jp-reviews');

      new UC.partials.ProposalRow({ el: '.jp-single' });

      new UC.partials.MessagesCreate({
        jobOwnerId: UC.constants.jobOwnerId,
        attorneyId: UC.constants.attorneyId,
        jobId: UC.constants.jobId
      });

      new UC.partials.FeesDetails();

      UC.acceptJobProposal.init({
        acceptProposalButtonSelector: '.jp-accept-btn',
        jobId: UC.constants.jobId,
        bidId: UC.constants.bidId,
        hideHireHelpModal: UC.constants.hideHireHelpModal
      });

      this.setBtnLoaders();
    },

    setBtnLoaders: function() {
      $('.jp-accept-btn').each(function() {
        var $this = $(this);
        $this.data('loader', $this.buttonLoader());
      });

      this.btnSendReply = $('#btnSendReply').buttonLoader();
      this.btnContinuePayLoader = $('#btnContinuePay').buttonLoader();
    },

    processAction: function() {
      var action = UC.utils.getParameterByName('action');

      if (!UC.constants.isBidSubmitted || UC.user.id != UC.job.userId) {
        // perform no action since this bid isn't in submitted
        // status anymore or the current user isn't the owner
        return;
      }

      switch(action) {
        case 'positive':
          UC.utils.showNotification('Thanks for your feedback! You can follow up or hire your attorney below.', UC.constants.results.success);
          break;
      }
    },

    focusReply: function(e) {
      var $message = $('#txtReplyMessage');

      // There are two states for this page: one in which there is a message box,
      // where clicking jp-contact-btn will scroll to the message box, and one
      // in which there is no message box, where clicking jp-contact-btn should
      // link to the job messages page.  Only in the first case do we wish to
      // proceed.
      if ($message.length === 0) {
        return;
      }

      e.preventDefault();

      if (UC.user.status === UC.constants.UserStatus.Pending) {
        var message = 'Please confirm your email address before sending a message';

        new UC.modals.ModalPendingUser().render(message);

        return false;
      }

      if ($message.length > 0) {
        $message.focus();
        UC.utils.scrollToElement($message);
      }
    },

    showTour: function() {
      this.$tourModal = this.$el.find('#modalProposalTour');
      this.$tourModal.find('.take-proposal-tour').click(this.startTour.bind(this));
      this.$tourModal.modal();
    },

    startTour: function(e) {
      e.preventDefault();
      this.$tourModal.modal('hide');

      var steps = [
        {
          element: $('.jp-attorney-name'),
          text: "You can view an attorney's profile to see additional information about their background, specific areas of expertise, prior work history, etc. You can also read reviews to see what other clients have said about working with this attorney.",
          position: 'bottom-middle-aligned'
        },
        {
          element: $('#panelProposalContact'),
          text: 'Reply to an attorney if you have questions about their specific proposal or about their background. Messages are free to send and we encourage you to clarify any questions before hiring an attorney.',
          position: 'top'
        },
        {
          element: $('.jp-main-actions'),
          text: "Once you've decided to accept a proposal, you can click the Hire button to get started. If you do not wish to hire this attorney, you can decline the proposal and tell us why that attorney wasn't a good fit. We'll use your feedback to help send you more relevant proposals.",
          position: 'top'
        }
      ];

      window.intro = UC.utils.initIntroJs(steps);

      intro.onchange(function(el) {
        var $el = $(el);
        var step = $el.data('step');

        UC.utils.recordKissEvent('Proposal tour next step', {
          'step': step,
          'page-path': window.location.pathname
        });
      }).onexit(function() {
        UC.utils.recordKissEvent('Proposal tour exited', {
          'page-path': window.location.pathname
        });
      }).oncomplete(function() {
        UC.utils.recordKissEvent('Proposal tour completed', {
          'page-path': window.location.pathname
        });
      });

      intro.onbeforechange(function(el) {
        if ($(el).data('step') === 2) {
          $('#panelProposalContact').addClass('pad-10');
        } else {
          $('#panelProposalContact').removeClass('pad-10');
        }
      })

      UC.utils.recordKissEvent('Product tour started', {
        'page-path': window.location.pathname
      });
    }
  });
}();
