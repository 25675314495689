import TextLengthCounter from '../../../components/text_length_counter'
import { GeoLocation } from '../../../components/geo_location'

UC.partials.SummaryConfirmation = Backbone.View.extend({

  events: {
    'input #budgetSlider': 'onBudgetValueChanged',
    'input #timelineSlider': 'onTimelineValueChanged',
    'change #job_lead_ZipCode': 'onZipCodeChanged',
  },

  routes: {
    validZipCode: '/account/valid_zip_code/'
  },

  initialize: function(options) {
    this.descriptionLengthCounter = new TextLengthCounter({
      el: this.$el,
      errorContainer: '.form-group',
      textarea: '#job_lead_Description',
      counter: '.js-description-length'
    })

    this.$zipCode = $('#job_lead_ZipCode');
  },

  onBudgetValueChanged: function(e) {
    $('#budgetValue').text('$' + $(e.currentTarget).val());
  },

  onTimelineValueChanged: function(e) {
    $('#timelineValue').text($(e.currentTarget).val() + ' days');
  },

  validate: function(dfd) {
    this.$el.find('.has-error').removeClass('has-error');

    let errors = [];
    const job_lead = this.$el.serializeJSON().job_lead;
    const data = {
      summary_confirmation: {
        title: job_lead.Title,
        description: job_lead.Description,
        phone: job_lead.Phone,
        timeline: job_lead.Timeline,
        budget: job_lead.Budget,
        zip_code: job_lead.ZipCode
      }
    }

    // if (!data.summary_confirmation.title || data.summary_confirmation.title.length === 0) {
    //   errors.push({element: $('#job_lead_Title'), text: 'Please enter a title'});
    // }

    if (!this.descriptionLengthCounter.hasValidTextLength()) {
      errors.push(this.descriptionLengthCounter.getError());
    }

    if (data.summary_confirmation.phone && data.summary_confirmation.phone.length > 0 &&
        !UC.utils.validatePhone(data.summary_confirmation.phone)) {
      errors.push({element: $('#job_lead_Phone'), text: 'Please enter a valid phone number'});
    }

    if (errors.length === 0) {
      if (!data.summary_confirmation.zip_code) {
        errors.push({element: this.$zipCode, text: 'Please enter a valid zip code.'});
      } else if (UC.utils.validateZipCode(data.summary_confirmation.zip_code)) {
        this.checkZipCodeValid(data, dfd);
        return; // return here so that lower dfd.resolve() is not called
      } else {
        errors.push({element: this.$zipCode, text: 'Please enter a valid zip code.'});
      }
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, { className: "form-group" });
      dfd.reject();
    } else {
      dfd.resolve(data);
    }
  },

  onZipCodeChanged: function(e) {
    this.$zipCode.closest('.has-error').removeClass('has-error');

    if (UC.utils.validateZipCode(this.$zipCode.val())) {
      this.checkZipCodeValid({ summary_confirmation: { zip_code: this.$zipCode.val() } }, new $.Deferred());
    } else {
      errors.push({element: this.$zipCode, text: 'Please enter a valid zip code.'});
    }
  },

  checkZipCodeValid: function (data, dfd) {
    const path = '{0}{1}'.format(this.routes.validZipCode, data.summary_confirmation.zip_code);

    UC.net.get(path, {job_lead_id: UC.constants.jobLeadId}, function(res) {
      $('.new-job-location').text(`${res.zip.CityName}, ${res.zip.StateAbbr}`);
      if (res.suggested_attorneys) {
        $('.row.face-piles').replaceWith(res.suggested_attorneys);
      }
      new GeoLocation().storeUserChoice(data.summary_confirmation.zip_code);
      dfd.resolve(data);
    }).fail(function(res) {
      res.handled = true;
      UC.utils.showValidationErrors(
        [
          {
            element: this.$zipCode,
            text: res.responseJSON ? res.responseJSON.message : 'Failed to validate the Zip Code'
          }
        ], { className: "form-group" }
      );
      dfd.reject();
    }.bind(this));
  }

});

