!function() {
  UC.modals.EditIndustries = Backbone.View.extend({

    el: '#mdlEditIndustries',

    events: {
      'click #btnSaveIndustries': 'saveIndustries'
    },

    initialize: function(opts) {
      this.$btnLoader = this.$('#btnSaveIndustries').buttonLoader();

      if (opts) this.saveIndustriesCallback = opts.saveIndustriesCallback;
    },

    openModal: function() {
      this.$el.modal({ backdrop: 'static' });
    },

    saveIndustries: function() {
      var data = this.$('#frmEditIndustries').serializeJSON();

      this.$btnLoader.showLoader();
      UC.net.post(UC.constants.update_industries_path, data, function(response) {
        if (UC.action === 'index' && UC.controller === 'dashboard') {
          UC.utils.checklistSuccessCallback('btnEditIndustries');
        } else {
          this.saveIndustriesCallback(response);
          $('.list.paginate').paginateList();
        }

        this.$el.modal('hide');
      }.bind(this)).always(function() {
        this.$btnLoader.hideLoader();
      }.bind(this));
   }
  });
}();
