UC.partials.DayTimeSelector = Backbone.View.extend({

  className: 'modal-content',

  events: {
    'click #js-open-time-zone-change': 'openTimezoneChange',
    'click #js-close-time-zone-change': 'closeTimezoneChange',
    'click .js-day': 'daySelected',
    'click .js-time': 'timeSelected',
    'click #js-previous-available-days-link': 'previousAvailableDays',
    'click #js-next-available-days-link': 'nextAvailableDays',
    'click .js-previous-available-times-link': 'previousAvailableTimes',
    'click .js-next-available-times-link': 'nextAvailableTimes'
  },

  setSelectTimeHeader: function(day) {
    var text = (day) ? 'Select a time on ' + day : 'Select a time';
    this.$('#js-select-a-time-header').text('2. ' + text);
    this.$('#js-select-a-day-text').toggleClass('hidden', !!day);
  },

  nextAvailableDays: function(e) {
    e.preventDefault();

    UC.utils.recordKissEvent('schedule instant call - clicked next available days', this.standardKissData());

    var lastVisibleIndex = this.$('.js-day:not(.btn-hidden):last').data('index');
    var nextVisible = this.$('.js-day').slice(lastVisibleIndex + 1, lastVisibleIndex + 4);
    $('.js-day').addClass('btn-hidden');
    $(nextVisible).removeClass('btn-hidden');

    this.toggleDayPaginationLinks();
  },

  previousAvailableDays: function(e) {
    e.preventDefault();

    UC.utils.recordKissEvent('schedule instant call - clicked previous available days', this.standardKissData());

    var firstVisibleIndex = this.$('.js-day:not(.btn-hidden):first').data('index');
    var nextVisible =this. $('.js-day').slice(firstVisibleIndex - 3, firstVisibleIndex);
    $('.js-day').addClass('btn-hidden');
    $(nextVisible).removeClass('btn-hidden');

    this.toggleDayPaginationLinks();
  },

  toggleDayPaginationLinks: function() {
    var showingFirstDays = this.$('.js-day:not(.btn-hidden):first').data('index') == 0;
    $('#js-previous-available-days-text').toggleClass('hidden', !showingFirstDays);
    $('#js-previous-available-days-link').toggleClass('hidden', showingFirstDays);

    var showingLastDays = this.$('.js-day:not(.btn-hidden):last').data('index') + 1 >= $('.js-day').length;

    $('#js-next-available-days-text').toggleClass('hidden', !showingLastDays);
    $('#js-next-available-days-link').toggleClass('hidden', showingLastDays);
  },

  previousAvailableTimes: function(e) {
    e.preventDefault();

    UC.utils.recordKissEvent('schedule instant call - clicked previous available times', this.standardKissData());

    var $currentTimesContainer = this.$('.js-times:not(.hidden)');
    var $timeElements = $currentTimesContainer.find('.js-time');
    var firstVisibleIndex = $timeElements.filter(':not(.btn-hidden):first').data('index');
    var nextVisible = $timeElements.slice(firstVisibleIndex - 6, firstVisibleIndex);

    $timeElements.addClass('btn-hidden');
    $(nextVisible).removeClass('btn-hidden');

    this.toggleTimePaginationLinks($currentTimesContainer);
  },

  nextAvailableTimes: function(e) {
    e.preventDefault();

    UC.utils.recordKissEvent('schedule instant call - clicked next available times', this.standardKissData());

    var $currentTimesContainer = this.$('.js-times:not(.hidden)');

    var $timeElements = $currentTimesContainer.find('.js-time');
    var lastVisibleIndex = $timeElements.filter(':not(.btn-hidden):last').data('index');
    var nextVisible = $timeElements.slice(lastVisibleIndex + 1, lastVisibleIndex + 7);
    $timeElements.addClass('btn-hidden');
    $(nextVisible).removeClass('btn-hidden');

    this.toggleTimePaginationLinks($currentTimesContainer);
  },

  toggleTimePaginationLinks: function($container) {
    var $timeElements = $container.find('.js-time');

    var showingFirstTimes = $timeElements.filter(':not(.btn-hidden):first').data('index') == 0;

    $container.find('.js-previous-available-times-text').toggleClass('hidden', !showingFirstTimes);
    $container.find('.js-previous-available-times-link').toggleClass('hidden', showingFirstTimes);

    var showingLastTimes = $timeElements.filter(':not(.btn-hidden):last').data('index') + 1 >= $timeElements.length;

    this.$('.js-times').find('.js-next-available-times-text').toggleClass('hidden', !showingLastTimes);
    this.$('.js-times').find('.js-next-available-times-link').toggleClass('hidden', showingLastTimes);
  },

  resetTimes: function(index) {
    var $container = this.$('.js-times[data-index="' + index + '"]');
    $container.find('.js-time').addClass('btn-hidden');
    $container.find('.js-time').slice(0, 6).removeClass('btn-hidden');

    this.toggleTimePaginationLinks($container);

    this.$('.js-next').addClass('hidden');

    this.$('.js-time').removeClass('btn-primary').removeClass('js-time-selected').addClass('btn-default-outline');
  },


  daySelected: function(e) {
    var $button = $(e.currentTarget);
    var index = $button.data('index');

    this.resetTimes(index);

    this.$('.js-times').addClass('hidden');

    if ($button.hasClass('js-day-selected')) {
      $button.removeClass('js-day-selected');

      this.setSelectTimeHeader();

    } else {
      this.$('.js-day').removeClass('js-day-selected');
      $button.addClass('js-day-selected');

      this.setSelectTimeHeader($button.data('dateShort'));

      this.$('.js-times').addClass('hidden');
      this.$('.js-times[data-index="'+index+'"]').removeClass('hidden');
    }

    this.$('.js-day').removeClass('btn-primary').addClass('btn-default-outline');
    this.$('.js-day-selected').addClass('btn-primary').removeClass('btn-default-outline');
  },

  timeSelected: function(e) {
    $button = $(e.currentTarget);

    if ($button.hasClass('js-time-selected')) {
      $button.removeClass('js-time-selected');
      this.$('.js-next').addClass('hidden');
    } else {
      this.$('.js-time').removeClass('js-time-selected');
      $button.addClass('js-time-selected');

      this.$('.js-next').removeClass('hidden');

      this.$('#js-scheduled-date-time-epoch').val($button.data('datetimeEpoch'));
      this.$('#js-scheduled-date-time').val($button.data('datetimeRaw'));

      this.$('.js-selected-day-time').text($button.data('datetimeLong'));
    }

    this.$('.js-time').removeClass('btn-primary').addClass('btn-default-outline');
    this.$('.js-time-selected').addClass('btn-primary').removeClass('btn-default-outline');
  },

  serializeForm: function() {
    var data = {
      time_zone: this.$('#js-time-zone').val(),
      message: this.$('#js-message').val(),
      phone: this.$('#js-phone-number').val(),
      ScheduledDateTime: this.$('#js-scheduled-date-time').val()
    };

    return data;
  },

  validate: function() {
    var errors = [];

    var $phone = this.$('#js-phone-number');

    if($phone.val().isBlank()) {
      errors.push({
        element: $phone,
        text: 'Please enter your preferred phone number',
        className: 'form-group'
      });
    }

    var $message = this.$('#js-message');
    if($message.val().isBlank()) {
      errors.push({
        element: $message,
        text: 'Please include a message',
        className: 'form-group'
      });
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      return false;
    } else {
      return true;
    }
  },

  openTimezoneChange: function(e) {
    e.preventDefault();

    this.$('#js-change-time-zone').removeClass('hidden');
  },

  closeTimezoneChange: function(e) {
    e.preventDefault();

    this.$('#js-change-time-zone').addClass('hidden');
  },

  standardKissData: function() {
    return {
      bid_id: this.bidId,
      job_id: this.jobId
    };
  }
});
