!function() {

  UC.views.AttorneyClients = Backbone.View.extend({

    initialize: function() {
      $('.dashboard').on('click', '.initiate-call', function(e) {
        e.preventDefault();
        new UC.views.CallsModalInitiate({
          to: $(e.currentTarget).data('phone')
        }).render();
      });

      $('.dashboard').on('click', '.reveal-contact-info', this.revealContactInfo);
    },

    revealContactInfo: function(e) {
      e.preventDefault();
      var $link = $(e.currentTarget),
      $info = $link.parent().find('.hidden-contact-info');

        $link.addClass('hidden');
      $info.removeClass('hidden');
    }

  });
}();
