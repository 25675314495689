UC.views.PaymentsShow = Backbone.View.extend({

  initialize: function() {
    UC.utils.recordKissEvent('billed', {'Billing Amount': UC.constants.totalPaid});
    UC.utils.recordKissClickEvent('#social-facebook', 'Payment Confrimation Referral Box - Facebook Share Click');
    UC.utils.recordKissClickEvent('#social-twitter', 'Payment Confrimation Referral Box - Twitter Share Click');
    UC.utils.recordKissClickEvent('#social-gplus', 'Payment Confrimation Referral Box - Google Plus Share Click');
    UC.utils.recordKissClickEvent('#social-linkedin', 'Payment Confrimation Referral Box - LinkedIn Share Click');

    this.components = {
      FollowUpOffers: new UC.components.FollowUpOffers({
        el: '#follow-up-offers-container'
      })
    };

    new UC.components.FeesPopover({ el: this.$el });
  }
});
