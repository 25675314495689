var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"class") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"gap") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":15,"column":13}}})) != null ? stack1 : "")
    + "    </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <a href=\"#\">...</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\"#\" class=\"page-link\" data-page=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"page") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"page") : depth0), depth0))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"pagination\">\n\n  <li class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"prev_class") || (depth0 != null ? lookupProperty(depth0,"prev_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"prev_class","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":27}}}) : helper)))
    + "\">\n    <a href=\"#\" class=\"page-link\" data-page=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"prev_page") || (depth0 != null ? lookupProperty(depth0,"prev_page") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"prev_page","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":58}}}) : helper)))
    + "\" aria-label=\"Previous\">\n      <span aria-hidden=\"true\">&laquo;</span>\n    </a>\n  </li>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pages") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + "\n  <li class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"next_class") || (depth0 != null ? lookupProperty(depth0,"next_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"next_class","hash":{},"data":data,"loc":{"start":{"line":19,"column":13},"end":{"line":19,"column":27}}}) : helper)))
    + "\">\n    <a href=\"#\" class=\"page-link\" data-page=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"next_page") || (depth0 != null ? lookupProperty(depth0,"next_page") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"next_page","hash":{},"data":data,"loc":{"start":{"line":20,"column":45},"end":{"line":20,"column":58}}}) : helper)))
    + "\" aria-label=\"Next\">\n      <span aria-hidden=\"true\">&raquo;</span>\n    </a>\n  </li>\n</ul>\n";
},"useData":true});