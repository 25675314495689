!function() {
  if ($('.js-attorney-badge').length) {
    $('.js-attorney-badge').on('shown.bs.tooltip', function () {
      UC.utils.recordKissEvent('attorney badge tooltip shown');
    });

    $('.js-attorney-image-wrapper').hover(
      function() {
        $(this).find('.js-attorney-badge').tooltip('show');
      }, function() {
        $(this).find('.js-attorney-badge').tooltip('hide');
      }
    );
  }
}();
