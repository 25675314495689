var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<select class=\"ts-line-item-field\" data-field-name=\"Type\" data-field-type=\"string\">\n  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"typeSelectOptions") || (depth0 != null ? lookupProperty(depth0,"typeSelectOptions") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"typeSelectOptions","hash":{},"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n</select>\n";
},"useData":true});