!function() {

  function mergeDefaultAttributes(attrs) {
    var defaults = {
      tracking_code: UC.tracking_code,
      user_agent: navigator.userAgent,
      referrer: window.document.referrer,
      url: window.location.href,
      page_tags: UC.constants.pageTags,
      session_id: UC.sessionId
    };

    // only add if available
    if (UC.job) defaults.jobId = UC.job.id;
    if (UC.user) defaults.userId = UC.user.id;

    // merge, but allow 'attrs' to override the defaults
    return $.extend(defaults, attrs);
  }

  function removeObjectReference(attrs) {
    Object.keys(attrs).forEach(function(a) {
      if (Object.prototype.toString.call(attrs[a]) === "[object Object]" && attrs[a] !== null) {
        attrs[a] = ''
      }
    });

    return attrs
  }

  UC.utils.recordKissEvent = function (eventName, properties) {
    var nesAttrs = mergeDefaultAttributes(properties)

    /**
    * TODO: Resolve data discrepancy
    * https://upcounsel.atlassian.net/browse/UC-12107
    */
    var parsedAttrs = removeObjectReference(nesAttrs)
    parsedAttrs['event_name'] = eventName
    UC.utils.sendNesEvent('track_event', parsedAttrs)
  }

  /**
  * @param   {String/Element}    element    The id (btnClick) or class (.btnClick) or element
  * @param   {String}    eventName    The name of the event
  * @param   {Object}    properties     Dictionary of additional properties
  */
  UC.utils.recordKissClickEvent = function (element, eventName, properties) {
    var nesAttrs = mergeDefaultAttributes(properties)

    /**
    * TODO: Resolve data discrepancy
    * https://upcounsel.atlassian.net/browse/UC-12107
    */
    var parsedAttrs = removeObjectReference(nesAttrs)
    parsedAttrs['event_name'] = eventName
    UC.utils.recordNesClickEvent(element, parsedAttrs)
  };
}();
