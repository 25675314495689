jQuery.fn.selectLegalTaskWithArea = function() {

  return this.each(function() {

    var $container = $(this);

    $container.find('.select-legal-task').change(function(e) {
      var legalAreaId = $(e.currentTarget).find(':selected').data('legal-area-id');
      $container.find('.input-legal-area').val(legalAreaId);
    });

  });

};

