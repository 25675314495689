!function() {

  UC.modals.UserDocumentsModalChooseSigners = Backbone.View.extend({

    elements: {
      'signersList' : null,
      'signerRowTemplate' : null,
      'btnPrepareForSigning' : null,
      'dropdownBtnAddSigner' : null,
      'soloBtnAddSigner' : null
    },

    initialize: function(options) {
      this.components = {};
      this.parent_view = options.parent_view;
      this.submitCallback = options.submitCallback;
    },

    render: function(options) {
      this.perms = {};
      this.documentId = options.document_id;
      this.basePath = "{0}/{1}".format('/signatures/user_documents', this.documentId);
      this.renderModal();
    },

    renderModal: function() {
      UC.net.get(this.basePath + '/choose_signers', null, $.proxy(function(modalHtml) {
        this.modalChooseSigners = new UC.partials.modalV9({
          modalTitle: 'Enter Your Signers',
          modalContent: modalHtml,
          id: 'modalChooseSigners',
          renderCallback: this.handleRenderCallback.bind(this)
        });
      }, this));
    },
    
    handleRenderCallback: function() {
      UC.utils.setSelectors(this.elements);
      $('#soloBtnAddSigner').on('click', this.onBtnAddSigner.bind(this));
      $('#btnPrepareForSigning').on('click', this.onBtnPrepareForSigning.bind(this));
      $('.jqmClose').on('click', this.close.bind(this));
      $('.remove').on('click', this.onRemoveClick.bind(this));
      $('.suggested-signer').on('click', this.onSuggestedSignerClick.bind(this));
      this.components.prepareForSigningLoader = this.elements.btnPrepareForSigning.buttonLoader();
      $('.suggested-signer-dropdown').popdown();
      if (this.elements.dropdownBtnAddSigner.is(':visible')) {
        this.elements.soloBtnAddSigner.hide();
      }
    },
    
    onBtnPrepareForSigning: function(e) {
      e.preventDefault();
      if (this.validateSigners()) {
        var data = {
          signers: this.signers,
          id: this.documentId
        };
        this.components.prepareForSigningLoader.showLoader();
        this.submitCallback(data);
      };
    },

    close: function() {
      this.modalChooseSigners.$el.modal('hide')
    },

    hideLoader: function() {
      this.components.prepareForSigningLoader.hideLoader();
    },

    onBtnAddSigner: function(e) {
      var $template = this.elements.signerRowTemplate.clone();
      $template.removeAttr('id');
      $template.removeClass('hidden');
      this.elements.signersList.append($template);
      $template.find('.remove').on('click', this.onRemoveClick.bind(this))
    },

    validateSigners: function() {
      var valid = true,
          errors= [],
          signers = [];

      $('.row').removeClass('has-error');
      $('.form-group').removeClass('has-error');

      this.elements.signersList.find('.signer').each(function() {
        var $el = $(this),
          signer = {
          FirstName: $el.find('#txtFirstName').val().trim(),
          LastName: $el.find('#txtLastName').val().trim(),
          Email: $el.find('#txtEmail').val().trim(),
          UserId: $el.data('id')
        };

        if (signer.UserId) {
          signers.push(signer);
        } else if (signer.FirstName != '' || signer.LastName != '' || signer.Email != '') {
          if (!signer.FirstName || signer.FirstName == '') {
            errors.push({element: $el.find('#txtFirstName'), className: 'form-group', text: 'Missing first name'});
          };
          
          if (!signer.LastName || signer.LastName == '') {
            errors.push({element: $el.find('#txtLastName'), className: 'form-group', text: 'Missing last name'});
          };

          if (!signer.Email || signer.Email == '') {
            errors.push({element: $el.find('#txtEmail'), className: 'form-group', text: 'Missing email'});
          } else if (!UC.utils.validateEmail(signer.Email)) {
            errors.push({element: $el.find('#txtEmail'), className: 'form-group', text: 'Invalid email'});
          };

          signers.push(signer);
        };
      });

      if (signers.length == 0 ) {
        errors.push({element: $('.form-buttons.row'), text: 'Please add a signer'});
      };

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors);
        valid = false;
      };
      
      this.signers = signers;
      return valid;
    },

    onRemoveClick: function(e) {
      var $row = $(e.target).closest('.row.signer'),
          id   = $row.data('id');

      if (id) {
        $('.suggested-signer-dropdown li[data-id="'+id+'"]').removeClass('added');
        this.toggleSuggestedDropdown();
      };
      $row.remove();
    },

    onSuggestedSignerClick: function(e) {
      e.preventDefault();
      var $row      = $(e.target).closest('li'),
          suggested = $(e.target).text();

      if (suggested !== 'Other') {
        var id       = $row.data('id'),
            $lastRow = this.elements.signersList.find('.signer:last'),
            replace  = true,
            $template;

        if ($lastRow &&
            $lastRow.length > 0 &&
            $lastRow.find('#txtFirstName').val().trim() ==='' &&
            $lastRow.find('#txtLastName').val().trim() === '' &&
            $lastRow.find('#txtEmail').val().trim() === '') {
          $template = $lastRow;
          replace = false;
        } else {
          $template = this.elements.signerRowTemplate.clone();
          $template.removeAttr('id');
          $template.removeClass('hidden');
        }
  
        $template.data('id', id);
        $template
          .find('#txtFirstName')
          .val($row.data('first-name'))
          .prop('disabled', true);
        $template
          .find('#txtLastName')
          .val($row.data('last-name'))
          .prop('disabled', true);
        $template
          .find('#txtEmail')
          .val($row.data('email'))
          .prop('disabled', true);
        $template
          .find('.remove')
          .on('click', this.onRemoveClick.bind(this))

        if (replace) {
          this.elements.signersList.append($template);
        };

        $row.addClass('added');
        this.toggleSuggestedDropdown();
      } else {
        this.onBtnAddSigner();
      };
    },

    toggleSuggestedDropdown: function() {
      if ($('.suggested-signer-dropdown').length > 0) {
        var visibleSuggestedSigners = this.getQtySuggestedSigners();
        if (visibleSuggestedSigners > 0) {
          $('.suggested-signer-dropdown button').show();
          this.elements.soloBtnAddSigner.hide();
        } else {
          $('.suggested-signer-dropdown button').hide();
          this.elements.soloBtnAddSigner.show();
        };
      };
    },

    getQtySuggestedSigners: function() {
      var signers = 0;
      if ($('.v9 .suggested-signer-dropdown').length > 0) {
        $('.v9 li.suggested-signer').each(function() {
          var $el = $(this);
          if (!$el.hasClass('added') && !$el.hasClass('other')) {
            signers++;
          };
        });
      };
      return signers;
    }

  });
}();
