UC.partials.JobScheduleCall = Backbone.View.extend({
  el: 'body',

  events: {
    'click .jp-schedule-phone-call' : 'proposalScheduleCallClick',
    'click .initiate-call' : 'initiateCallClick'
  },

  proposalScheduleCallClick: function(e) {
    e.preventDefault();

    if (UC.user.status === UC.constants.UserStatus.Pending) {
      new UC.modals.ModalPendingUser().render(
        'Please confirm your email address before scheduling a call'
      );

      return false;
    }

    var $this = $(e.currentTarget);
    var attorneyId = $this.data('attorney-id');

    new UC.modals.ScheduledCallsNew({ attorney_id: attorneyId }).render();
  },

  initiateCallClick: function(e) {
    e.preventDefault();

    new UC.views.CallsModalInitiate({
      to: $(e.currentTarget).data('phone'), job_id: UC.constants.jobId
    }).render();
  }
});
