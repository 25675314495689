UC = UC || {};
UC.modals = UC.modals || {};
UC.modals.AttorneyTopLegalGroups = Backbone.View.extend({

  tagName: 'div',

  className: 'modal',

  id: 'top-legal-groups-modal',

  template: UC.views.resolveHandlebarsTemplate("public/attorney_legal_groups/attorney_top_legal_groups_modal"),

  availableRanking: [1,2,3],
  
  events: {
    "click #submit-top-legal-groups" : "submitTopLegalGroups",
    "hidden.bs.modal" : "deleteElement"
  },

  initialize: function(opts) {
    this.allLegalGroups = opts.allLegalGroups;
    this.attorneyLegalGroups = opts.attorneyLegalGroups || [];
    this.origAttorneyTopLegalGroups = opts.attorneyTopLegalGroups || [];
    this.afterUpdateCallback = opts.afterUpdateCallback;

    this.selectedLegalGroupArr = [];

    this.render();
  },

  render: function() {
    this.$el.html(this.template({availableRanking: this.availableRanking}));
    this.$el.modal({
      backdrop: 'static'
    });
    this.initAllSelects();
  },

  gatherSelectedTopLegalGroups: function() {
    this.selectedLegalGroupArr = [];

    this.availableRanking.forEach(function(rankNum) {
      this.selectedLegalGroupArr.push(this.$topLegalGroupSelect(rankNum).val());
    }, this)
  },

  legalGroupName: function(legalGroupId) {
    return this.allLegalGroups[legalGroupId]['Name'];
  },
  
  legalGroupSelectOptions: function() {
    var legalGroupOpts = [];

    for(var i = 0, len = this.attorneyLegalGroups.length; i < len; i++) {
      var legalGroupId = this.attorneyLegalGroups[i];
      legalGroupOpts.push({id: legalGroupId, text: this.legalGroupName(legalGroupId)});
    }

    return legalGroupOpts;
  },

  sortedLegalGroupOpts: function() {
    return this.legalGroupSelectOptions().sort(function(opt1, opt2) {
      if (opt1 > opt2) return 1;
      if (opt1 < opt2) return -1;
      return 0;
    });
  },

  legalGroupSelectEnabled: function(rankNum) {
    return this.attorneyLegalGroups.length >= rankNum;
  },

  $topLegalGroupSelect: function(rankNum) {
    return this.$('select[name="top-legal-group-' + rankNum +'"]')
  },

  initSelect: function(rankNum) {
    var selectConfig= {
      width: '100%'
    };

    var $select = this.$topLegalGroupSelect(rankNum);

    if (this.legalGroupSelectEnabled(rankNum)) {
      var selectOpts = this.sortedLegalGroupOpts();
      selectOpts.unshift({id: '', text: ''});

      selectConfig['data'] = selectOpts;
      selectConfig['placeholder'] = "Select a practice area";
      selectConfig['allowClear'] = true;
    } else {
      selectConfig['disabled'] = true;
    }

    $select.select2(selectConfig);

    var defaultTopGroup = this.origAttorneyTopLegalGroups[rankNum-1];

    if (defaultTopGroup) {
      $select.val(defaultTopGroup);
      $select.trigger('change');
    }

  },

  initAllSelects: function() {
    this.availableRanking.forEach(function(rankNum) {
      this.initSelect(rankNum);
    }, this);
  },

  submitTopLegalGroups: function() {
    if (this.validateSelections()) {
      UC.net.post(UC.constants.update_practice_areas_path, {TopLegalGroupIds: this.selectedLegalGroupArr})
        .done(this.afterUpdateCallback.bind(this))
        .fail(function() {
          this.$el.modal('hide');
        }.bind(this));
    }
  },

  validateSelections: function() {
    var valid = true;
    var errorMessages = [];

    this.$('#error-container').removeClass('has-error');

    this.gatherSelectedTopLegalGroups();

    if (!this.selectedLegalGroupArr[0]) {
      valid = false;
      errorMessages.push('You must select your top practice area.');
    }

    if (this.duplicateSelections()) {
      valid = false;
      errorMessages.push('You may not have duplicate selections.');
    }

    if (this.gapInRanking()) {
      valid = false;
      errorMessages.push('You may not have a gap in your ranked practice areas.');
    }

    if (!valid) {
      this.$('#error-container').addClass('has-error');
      this.$('.error-message').text(errorMessages.join(' '));
    }

    return valid;
  },

  duplicateSelections: function() {
    var selectedLegalGroupCount = {};
    var duplicatePresent = false;

    this.selectedLegalGroupArr.forEach(function(legalGroup) {
      if(legalGroup) {
        selectedLegalGroupCount[legalGroup] = selectedLegalGroupCount[legalGroup] || 0;
        selectedLegalGroupCount[legalGroup]++;
        if (selectedLegalGroupCount[legalGroup] > 1) duplicatePresent = true;
      }
    }, this);

    return duplicatePresent;
  },

  gapInRanking: function() {
    var lastRankPresent = true;
    var gapPresent = false;

    this.selectedLegalGroupArr.forEach(function(legalGroup) {
      if (legalGroup && !lastRankPresent) gapPresent = true;
      if (!legalGroup) lastRankPresent = false;
    }, this);

    return gapPresent;
  },

  deleteElement: function() {
    this.$el.remove();
  }

});
