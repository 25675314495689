UC.views.DashboardFindWork = Backbone.View.extend({
  initialize: function() {
    this.showAttorneyWelcomeModalIfRendered();
    new UC.partials.NewFindWork({
      el: this.$el,
      trackFilterClick: this.trackFilterClick.bind(this)
    });
  },

  trackFilterClick: function(filter, $el) {
    UC.utils.recordKissEvent('Find Work filter click', {
      filter: filter,
      value: $el.data('val')
    });
  },

  showAttorneyWelcomeModalIfRendered: function() {
    if ($('#modal-welcome-to-upcounsel').length) {
      UC.components.checkboxModal({
        modalSelector: '#modal-welcome-to-upcounsel'
      });

      $('.modal-confirm-btn').click(function() {
        UC.utils.recordKissEvent("Attorney Disintermediation - accepted", {
          user_id: UC.user.id
        });

        $.post('/work/accept_invoice_exclusivity_terms');
        $('#modal-welcome-to-upcounsel').modal('hide');
      });

      UC.utils.recordKissEvent("Attorney Disintermediation - seen", {
        user_id: UC.user.id
      });
      $('#modal-welcome-to-upcounsel').modal({ show: true });
    }
  }
});
