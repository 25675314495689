var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <p>Changing the bidding status to 'closed' will stop new proposals and allow you to focus\n              on communicating with those attorneys that have already responded to your request.</p>\n            <p>If you no longer wish to hire an attorney for this project, please\n              <a href=\"#\" id=\"showMarkCanceled\" data-dismiss=\"modal\" aria-label=\"Close\">click here</a> to cancel the request instead.</p>\n            <p><strong>Are you sure you want to close bidding on your job?</strong></p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <p>By canceling your job, you will no longer be able to hire an attorney using this job request. If you\n            wish to hire an attorney for the same legal work, you will need to post a new job. </p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "            <div class=\"form-group\">\n              <label for=\"selectReason\" class='control-label col-sm-4'>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"reasonsLabel") || (depth0 != null ? lookupProperty(depth0,"reasonsLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reasonsLabel","hash":{},"data":data,"loc":{"start":{"line":33,"column":71},"end":{"line":33,"column":87}}}) : helper)))
    + "</label>\n              <div class=\"col-sm-8\">\n                <select id=\"selectReason\" class='form-control col-sm-8'>\n                  <option value=\"\">Select One</option>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"reasons") || (depth0 != null ? lookupProperty(depth0,"reasons") : depth0)) != null ? helper : alias2),(options={"name":"reasons","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":18},"end":{"line":39,"column":30}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"reasons")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </select>\n                <div id=\"selectReasonErrorMsg\" class=\"error-message\"></div>\n              </div>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option data-reopenable=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"2") || (depth0 != null ? lookupProperty(depth0,"2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"2","hash":{},"data":data,"loc":{"start":{"line":38,"column":45},"end":{"line":38,"column":52}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"1") || (depth0 != null ? lookupProperty(depth0,"1") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"1","hash":{},"data":data,"loc":{"start":{"line":38,"column":61},"end":{"line":38,"column":68}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"0") || (depth0 != null ? lookupProperty(depth0,"0") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"0","hash":{},"data":data,"loc":{"start":{"line":38,"column":70},"end":{"line":38,"column":77}}}) : helper)))
    + "</option>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"remove") || (depth0 != null ? lookupProperty(depth0,"remove") : depth0)) != null ? helper : alias2),(options={"name":"remove","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":12},"end":{"line":54,"column":23}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"remove")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"reopenCheck") || (depth0 != null ? lookupProperty(depth0,"reopenCheck") : depth0)) != null ? helper : alias2),(options={"name":"reopenCheck","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":63,"column":28}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"reopenCheck")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"form-group\">\n                <label for=\"chkSendEmail\" class=\"col-sm-offset-4 col-sm-8\">\n                  <input type=\"checkbox\" name=\"chkSendEmail\" id=\"chkSendEmail\" checked=\"checked\">\n                  Send removed email to client\n                </label>\n              </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"form-group\" id=\"chkReopenableContainer\">\n                <label for=\"chkReopenable\" class=\"col-sm-offset-4 col-sm-8\">\n                  <input type=\"checkbox\" name=\"chkReopenable\" id=\"chkReopenable\" checked=\"checked\">\n                  Allow client to reopen job\n                </label>\n              </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "              <label for=\"txtReasonExplain\" class=\"control-label col-sm-4\">Can you tell us more about why you are cancelling this job?</label>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "              <label for=\"txtReasonExplain\" class=\"control-label col-sm-4\">Care to explain?</label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"modal fade\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":34}}}) : helper)))
    + "\">\n\n  <div class=\"modal-dialog\">\n\n    <div class=\"modal-content\">\n\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>\n        <h4 class=\"modal-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":41}}}) : helper)))
    + "</h4>\n      </div>\n\n      <form class=\"form-horizontal\">\n        <div class=\"modal-body clearfix\">\n\n          <h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":15,"column":14},"end":{"line":15,"column":26}}}) : helper)))
    + "</h4>\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"closeBidding") || (depth0 != null ? lookupProperty(depth0,"closeBidding") : depth0)) != null ? helper : alias2),(options={"name":"closeBidding","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":10},"end":{"line":23,"column":27}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"closeBidding")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"cancel") || (depth0 != null ? lookupProperty(depth0,"cancel") : depth0)) != null ? helper : alias2),(options={"name":"cancel","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":10},"end":{"line":28,"column":21}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"cancel")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"closeBidding") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":10},"end":{"line":44,"column":21}}})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"admin") || (depth0 != null ? lookupProperty(depth0,"admin") : depth0)) != null ? helper : alias2),(options={"name":"admin","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":10},"end":{"line":64,"column":20}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"admin")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n          <div class=\"form-group component hidden\" id=\"rowReasonExplain\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"cancel") || (depth0 != null ? lookupProperty(depth0,"cancel") : depth0)) != null ? helper : alias2),(options={"name":"cancel","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":69,"column":23}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"cancel")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"cancel") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":12},"end":{"line":73,"column":23}}})) != null ? stack1 : "")
    + "\n            <div class=\"col-sm-8\">\n              <textarea id=\"txtReasonExplain\" class=\"form-control\"></textarea>\n            </div>\n          </div>\n        </div>\n\n        <div class=\"modal-footer text-right\">\n          <button type=\"button\" class=\"btn btn-lg btn-success\" id=\"btnChangeJobStatus\" data-status=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":82,"column":100},"end":{"line":82,"column":110}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"btnText") || (depth0 != null ? lookupProperty(depth0,"btnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data,"loc":{"start":{"line":82,"column":112},"end":{"line":82,"column":123}}}) : helper)))
    + "</button>\n        </div>\n\n      </form>\n    </div>\n  </div>\n</div>\n\n";
},"useData":true});