UC.partials.ModalContactAttorney = Backbone.View.extend({

  events: {
    'click #close-modal-contact-attorney' : 'cancel',
    'submit #form-contact-attorney' : 'onSubmit'
   },

  initialize: function () {
    this.contactAttorneyPath = '/attorney/contact/{0}'

    this.$sendButton = this.$el.find('#send-contact-message');
    this.sendButtonLoader = this.$sendButton.buttonLoader();
  },

  render: function (options) {
    this.attorneyId = options.attorneyId;
    this.attorneyName = options.attorneyName;

    this.$el.modal();
  },

  cancel: function (event) {
    event.preventDefault();
    this.close();
  },

  close: function () {
    this.attorneyId = undefined;
    this.attorneyName = undefined;
    this.$el.find('#contact-attorney-message').val('');
    this.$el.modal('hide');
  },

  onSubmit: function (event) {
    event.preventDefault();
    var data = this.$el.find('#form-contact-attorney').serializeJSON();

    if (data.message.Message !== '') {
      this.sendButtonLoader.showLoader();

      UC.net.post(this.contactAttorneyPath.format(this.attorneyId), data, function (response) {
        UC.utils.showNotificationSuccess(response.message);
        this.close();
      }.bind(this)).always(function () {
        this.sendButtonLoader.hideLoader();
      }.bind(this));
    }
  }
});
