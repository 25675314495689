!function() {

  UC.views.ReferralsLanding = Backbone.View.extend({

    events: {
      'click .get-started': 'getStarted'
    },

    initialize: function() {
      this.setTrackingCode();

      UC.utils.recordKissClickEvent('#sign-up', 'Referrals Landing - Sign Up Btn Bottom (in modal)');

      UC.utils.recordKissClickEvent('#get-started-top', 'Referrals Landing - Get Started Btn Top');

      UC.utils.recordKissClickEvent('#get-started-bottom', 'Referrals Landing - Get Started Btn Bottom');
    },

    // updates user's tracking code on referral
    setTrackingCode: function() {
      UC.net.put(UC.constants.referral_path);
    },

    getStarted: function(e) {
      e.preventDefault();
      this.$el.find('#modalGetStarted').modal();
    }
  });

}();
