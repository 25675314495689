!function() {

  UC.views.UsersGetStarted = Backbone.View.extend({

    events: {
      'click #btn-get-started': 'showRegisterModal'
    },

    initialize: function() {
      new UC.partials.UsersForm({
        onRegister: this.onRegister.bind(this)
      });

      this.videoPlayer = this.buildVideoPlayer();
      $('#play-btn').on('click', this.playGetStdVideo.bind(this))
    },

    showRegisterModal: function(e) {
      e.preventDefault();
      this.$('#modal-register').modal();
    },

    onRegister: function() {
      this.$('.modal-title').text('Check your email');
      this.$('#get-started-user-form').addClass('hide');
      this.$('#get-started-pending-panel').removeClass('hide');
    },

    buildVideoPlayer: function() {
      var videoOptions = {
        id: UC.constants.getStdVideoId,
        title: false,
        portrait: false,
        byline: false
      };

      return new Vimeo.Player('get-started-video', videoOptions);
    },

    playGetStdVideo: function() {
      $('#get-started-video-container').addClass('show-video');
      this.videoPlayer.play();
      UC.utils.recordKissEvent('get-started-video-played', {
        url: window.location.href
      });
    }

  });
}();
