!function() {

    UC.components.Authentication = function(options) {
      this.options = options || {};
    };

    UC.components.Authentication.prototype = {

        constructor: UC.components.Authentication,

        logon: function(data, callback) {

          if (this.options.next_url) {
            data.next_url = this.options.next_url;
          }

          return UC.net.post('/sessions', data, function(result) {
            if (typeof callback === 'function') {
              callback(null, result);
            }
          }).fail(function(xhr) {
            if (xhr.responseJSON && typeof callback === 'function') {
              xhr.handled = true;
              callback(xhr.responseJSON);
            }
          });
        },

        logonGoogle: function(options = {}) {
            return new UC.components.GoogleApi({
                next_url: this.options.next_url,
                context: options.context || 'signin',
                backdrop: options.backdrop,
            }).authorize(function (data) {
                if (typeof options.callback === 'function') {
                    options.callback(data);
                }
            }.bind(this));
        },

        logonLinkedIn: function(options = {}) {
            const url = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code' +
                `&client_id=${UC.constants.linkedInClientId}` +
                `&redirect_uri=${window.location.protocol}//${window.location.host}/auth/linkedin/callback` +
                '&scope=r_emailaddress,r_liteprofile' +
                `&state=${UC.constants.omniauthToken}`

            const popupHeight = 700;
            const popupWidth = 600;
            const popupParams = [
                'popup=yes',
                `width=${popupWidth}`,
                `height=${popupHeight}`,
                `left=${(window.screen.width - popupWidth) / 2}`,
                `top=${(window.screen.height - popupHeight) / 2}`
            ]
            window.open(url, '_blank', popupParams.join());

            // https://blog.bitsrc.io/4-ways-to-communicate-across-browser-tabs-in-realtime-e4f5f6cbedca
            const channel = new BroadcastChannel('uc-omniauth-callback');
            channel.addEventListener('message', (event) => {
                channel.close();
                if (typeof options.callback === 'function') {
                    options.callback(event.data);
                }
            });

            return true;
        },

        handleLogonError: function(err) {
            switch (err) {
                case 'suspended':
                    UC.utils.showNotification("Your account has been suspended.", UC.constants.results.failure);
                    break;
                case 'pending':
                    UC.utils.showNotification("Your account is pending. Please check your email for activation link.", UC.constants.results.failure);
                    break;
                default:
                    err = err || "An unknown error occurred.";
                    UC.utils.showNotification(err, UC.constants.results.failure);
                    break;
            }
        }
    };
}();
