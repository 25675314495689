UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.AddTimesheetLinesButtonInvoiceView = Backbone.View.extend({

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/add_timesheet_lines_invoice_button"),

  tagName: 'span',

  events: {
    'click .import-ts-lines' : 'preventDefaultClick',
    'click #add-ts-lines' : 'showAddTsLinesModal'
  },

  initialize: function (opts) {
    this.addTsModalView = opts.addTsModalView;
    this.collection.on('ts-line-added-to-invoice', this.render.bind(this));
    this.collection.on('ts-line-removed-from-invoice', this.render.bind(this));
    this.collection.on('ts-lines-marked-on-invoice-load', this.render.bind(this));
  },

  render: function () {
    this.$el.html(this.template({timesheetsAvailableToAdd: this.timesheetsAvailableToAdd()}));
    this.$('[data-toggle="tooltip"]').tooltip();
  },

  timesheetsAvailableToAdd: function() {
    return this.collection.modelsNotAddedToInvoice().length > 0;
  },

  showAddTsLinesModal: function() {
    UC.utils.recordKissEvent('import_timesheet_entries_to_invoice_click');

    this.addTsModalView.render();
  },

  preventDefaultClick: function(e) {
    e.preventDefault();
  }

});
