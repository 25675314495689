!function() {

  var defaults = {
    addCurrencySymbol: false
  };

  $.fn.currencyInput = function (opts) {
    var options = $.fn.extend({}, defaults, opts);
    var that = this;

    function formatCurrency() {

      that.each(function () {
        var $this = $(this);
        var value = $this.val() || 0;
        var formatted = UC.utils.formatCurrency(value, options.addCurrencySymbol);

        $this.val(formatted);
      });
    }

    formatCurrency();

    return that.blur(formatCurrency);
  };
}();
