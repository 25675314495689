var SUMMARY_RESOLUTION_CHECK_INTERVAL_MS = 2000;

UC.partials.SummaryResolution = Backbone.View.extend({
  routes: {
    checkSummaryResolutionStatus: '/job_leads/{0}/resolve/{1}'
  },

  initialize: function(options) {
    const path = this.routes.checkSummaryResolutionStatus.format(
        UC.constants.jobLeadId, UC.constants.jobLeadSummaryResolutionKey
    );

    const resolutionInterval = setInterval(function () {
      UC.net.get(path, {}, function (res) {
        if (res.completed) {
          clearInterval(resolutionInterval);

          if (res.error) {
            window.location = res.nextUrl;
          } else if (options.resolvedCallback) {
            options.resolvedCallback();
          }
        }
      }).fail(function (res) {
        clearInterval(resolutionInterval);
        UC.closeTheLoop.handleFailure(res, false);
      });
    }, SUMMARY_RESOLUTION_CHECK_INTERVAL_MS);
  },
});

