UC.partials.PageNameProposal = Backbone.View.extend({
  events: {
    'click .reopen-job-button' : 'onBtnReopenClick'
  },

  initialize: function() {
    $('[data-toggle="tooltip"]').tooltip();
    $('.page.content').confirmUnload();

    if ($('#showFaqs').length) {
      UC.utils.recordKissClickEvent('#showFaqs', 'Job page click faqs link');
    }

    this.checkBankAccountStatus();
    UC.utils.initRatingDimensions();
  },

  onBtnReopenClick: function(e) {
    e.preventDefault();

    var $reopenButton = $(e.currentTarget)
    var url = $reopenButton.attr('href')
    var $buttonLoader = $reopenButton.buttonLoader();
    var requestAdditionalProposals = $reopenButton.data('requestAdditionalProposals');

    $buttonLoader.showLoader();

    UC.net.post(
      url,
      { currentPage: UC.constants.pageName, requestAdditionalProposals: requestAdditionalProposals },
      function(err, res) {
        if (err) {
          $buttonLoader.hideLoader();
          UC.utils.showNotification(err, UC.constants.results.failure);
        }
      }
    );
  },

  checkBankAccountStatus: function() {
    if (!UC.user.is_admin) {
      if (!UC.constants.hasPayout) {
        $('#modalNoBankAccount').modal({ keyboard: false, backdrop: 'static' });
      }
      else if (UC.constants.unverifiedAccount){
        $('#modalUnverifiedAccount').modal({ keyboard: false, backdrop: 'static' });
      }
    }
  }
});
