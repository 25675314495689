UC.components.PasswordForm = UC.components.OldPasswordForm = Backbone.View.extend({

  initialize: function() {
    this.btnLoader = this.$el.find('#btnChangePassword').buttonLoader();
    this.$currentPassword = this.$el.find('#txtCurrentPassword');
    this.$newPassword = this.$el.find('#txtNewPassword');
    this.$confirmPassword = this.$el.find('#txtNewPasswordConfirm');
    this.$el.submit(this.submit.bind(this));
  },

  submit: function(e) {
    e.preventDefault();

    if (this.validatePassword()) {
      this.btnLoader.showLoader();

      var data = {
        currentPassword: this.$currentPassword.val(),
        newPassword: this.$newPassword.val()
      };

      UC.net.post('/account/change_password', data).fail(function(err) {
        this.btnLoader.hideLoader();
      }.bind(this));
    }
  },

  validatePassword: function () {
    UC.utils.clearValidationErrors('form-group');
    var errors = [];
    var newPasswordEl = this.$newPassword,
        confirmPasswordEl = this.$confirmPassword,
        currentPasswordEl = this.$currentPassword;
    var currentPassword = this.$currentPassword.val(),
        newPassword = this.$newPassword.val(),
        newPasswordConfirm = this.$confirmPassword.val();

    if (!currentPassword) {
      errors.push({
        className: 'form-group',
        element: this.$currentPassword,
        text: 'Please enter your current password'
      });
    }

    if (newPassword.length < UC.constants.minPasswordLength) {
      errors.push({
        className: 'form-group', 
        element: newPasswordEl,
        text: 'The password must be at least {0} characters long'.format(UC.constants.minPasswordLength)
      });
    }

    if (newPassword != newPasswordConfirm) {
      errors.push({
        className: 'form-group',
        element: this.$confirmPassword,
        text: 'Your passwords do not match'
      });
    }

    UC.utils.showValidationErrors(errors);
    return errors.length === 0;
  }

});
