UC.views.AccountVerifyBankAccount = Backbone.View.extend({

  events: {
    'click #btnVerifyAccount' : 'submit'
  },

  initialize: function() {
    this.btnLoader = this.$el.find('#btnVerifyAccount').buttonLoader();
    this.$amount1 = this.$el.find('#txtAmount1');
    this.$amount2 = this.$el.find('#txtAmount2');
  },

  submit: function(e) {
    if (this.validate()) {
      this.btnLoader.showLoader();
      var data = {
        account: {
          id: UC.constants.accountId,
          amount1: this.amount1,
          amount2: this.amount2
        }
      };

      UC.net.post('/account/confirm_bank_account', data).fail(function(){
        this.btnLoader.hideLoader();
      }.bind(this));
    }
  },

  validate: function() {
    var errors = [];
    UC.utils.clearValidationErrors();
    this.amount1 = parseInt(this.$amount1.val());
    this.amount2 = parseInt(this.$amount2.val());

    if (isNaN(this.amount1) || this.amount1 <= 0 || this.amount1 >= 100 || isNaN(this.amount2) || this.amount2 <= 0 || this.amount2 >= 100) {
      errors.push({element: this.$amount1, text: 'Please enter amount between 1 and 99'});
    }

    UC.utils.showValidationErrors(errors);
    return errors.length === 0;
  }

});
