!function() {

  UC.modals.ContactAttorneyModal= Backbone.View.extend({

    events: {
      'click #sendMsg': 'onSendMessageClick'
    },

    initialize: function () {
      this.$el.modal({show: false});
      this.$template = this.$el.clone();
      this.$sendMsgLoader = this.$el.find('#sendMsg').buttonLoader();
    },

    open: function () {
      this.$el.modal('show');
      this.$el.on('hidden.bs.modal', this.resetModal.bind(this));
    },

    resetModal: function () {
      this.$el = this.$template.clone();
      this.$el.modal({show: false});
      this.delegateEvents();
    },

    onSendMessageClick: function (e) {
      this.$JobId = this.$el.find('#JobId');
      this.$Message = this.$el.find('#Message');

      e.preventDefault();

      var jobId = this.$JobId.val();

      if (this.validate()) {

        var d = {
          message: {
            JobId: jobId,
            Message: this.$Message.val()
          }
        };

        this.$sendMsgLoader.showLoader();

        UC.net.post(UC.constants.contactUrl, d).done(function(result) {
          UC.utils.showNotification("Your message for this attorney was sent successfully.", UC.constants.results.success);

          if (UC.constants.jobId === jobId) {
            UC.utils.recordKissEvent('Attorney Profile Proposal Contact - Message Sent', {
              jobId: UC.constants.jobId,
              attorneyId: UC.constants.attorneyId,
              userId: UC.user.id
            })
          }

          this.$Message.val('');
          this.$el.modal('hide');
        }.bind(this)).always(function() {
          this.$sendMsgLoader.hideLoader();
        }.bind(this));

      }
    },

    validate: function () {
      var errors = [];
      var job_id = this.$JobId.val();
      var message = this.$Message.val();

      UC.utils.clearValidationErrors('form-group');

      if (!job_id || job_id === '') {
        errors.push({element: this.$JobId, text: 'Please select a job'});
      }

      if (!message || message === '') {
        errors.push({element: this.$Message, text: 'Please provide a message to the attorney'});
      }

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors, {className: 'form-group'});
        return false;
      }

      return true;
    }
  });

}();
