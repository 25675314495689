!function () {

  function markAsRead(id, url) {
    function onMarkAsRead(err){
      if (err) {
        UC.utils.showNotification(err, UC.constants.results.failure);
      }
      else {
        $('.notification-item[data-id="{0}"]'.format(id)).removeClass('unread');
      }
    }

    UC.net.post('/notifications/{0}/mark_as_read'.format(id), {url: url}, onMarkAsRead);
  }

  function markAllAsRead() {
    UC.net.post('/notifications/mark_all_as_read', null, onMarkAllAsRead);
  }

  function onMarkAllAsRead(err, response) {
    if (err) {
      UC.utils.showNotification(err, UC.constants.results.failure);
    }
    else {
      $('.notification-item').removeClass('unread');
      UC.utils.hideComponent($('#header #nav .notification-count').text('0'));
    }
  }

  var Notifications = UC.components.Notifications = function () {
    this.open = false;
    this.$icon = $('.notification-icon-header');
    this.$pulldown = $('#notificationPulldown');
    this.$markAllAsRead = $('.button-mark-all-as-read');
    this.$markAsRead = $('.notification-mark-as-read');
    this.$notificationsPage = $('#notifications-page');

    this.$icon.click(this.togglePulldown.bind(this));
    this.$markAllAsRead.click(this.onMarkAllAsReadClick.bind(this));
    this.$markAsRead.click(this.onMarkAsReadClick);
    this.$pulldown.on('click', '.notification-item', this.onItemClick);
    this.$notificationsPage.on('click', '.notification-item', this.onItemClick);

    $('body').click(this.hidePulldown.bind(this));
    $('.popdown-btn').click(this.hidePulldown.bind(this));

    // hides user menu on click
    $('.notification-icon-header').click(function(e) {
      $('#user-menu').removeClass('open');
    });
  };

  // public method for making a notification appear read
  Notifications.mark_read = function(relation_id) {
    var $count = $('#header #nav .notification-count');
    var new_count = parseInt($count.text(), 10) - 1;

    if (new_count <= 0) {
      // dim bell and remove count if no more unread notifications
      $('.icon-notifications').addClass('empty');
      $count.remove();
    } else {
      // update count icon
      $count.text(new_count);
    }

    // mark individual notification read if it exists
    var selector = '.notification-item[data-relation-id="{0}"]'.format(relation_id);
    $(selector).removeClass('unread');
  };

  Notifications.prototype = {

    constructor: Notifications,

    showPulldown: function () {
      UC.utils.fadeInComponent(this.$pulldown);
      this.open = true;
    },
    hidePulldown: function () {
      UC.utils.hideComponent(this.$pulldown);
      this.open = false;
    },
    togglePulldown: function (e) {
      e.preventDefault();
      e.stopPropagation();

      this[this.open ? 'hidePulldown' : 'showPulldown']();
    },
    onItemClick: function (e) {
      e.preventDefault();
      e.stopPropagation();

      var $item = $(this),
        id = $item.data('id'),
        url = $item.find('.notification-link').attr('href');

      if ($item.hasClass('unread')) {
        markAsRead(id, url);
      }
      else if (url) {
        location.href = url;
      }
    },
    onMarkAsReadClick: function (e) {
      e.preventDefault();
      e.stopPropagation();

      var $this = $(this),
        $item = $this.parentsUntil('ul', 'li');

      $item.removeClass('unread');
      markAsRead($item.data('id'));
    },
    onMarkAllAsReadClick: function (e) {
      e.preventDefault();
      e.stopPropagation();

      function onConfirm(response) {
        if (response) {
          markAllAsRead();
        }
      }

      var unreadNotifications = parseInt($('#header #nav .notification-count').text(), 10);
      if(unreadNotifications != 0){
        UC.utils.showConfirmModal('Confirm change', 'Are you sure you want to mark all of the notifications as read?', onConfirm);
      }
    }
  };

  $(function () {
    new Notifications();
  });
}();
