!function() {
  UC.modals.EditWorkHistory = Backbone.View.extend({

    el: '#mdlEditWorkHistory',

    elements: {
      modalWorkHistory: null,
      btnAddNewWorkHistory: null
    },

    initialize: function(opts) {
      this.$el.modal = new UC.modals.AttorneyProfileWorkHistoriesForm({
        updateWorkHistoryPagination: opts.updateWorkHistoryPagination
      });
    },

    openModal: function(e) {
      e.preventDefault();
      this.$el.modal.render();
    }
  });
}();
