UC.partials.ScheduledCallsInstant = Backbone.View.extend({

  className: 'modal fade',

  routes: {
    instant: '/users/{0}/scheduled_calls/instant',
    create_schedule_from_instant_reply: '/users/{0}/scheduled_calls/schedule_from_instant_reply'
  },

  events: {
    'shown.bs.modal': 'modalOpened',
    'hidden.bs.modal': 'modalClosed',
    'change #js-time-zones': 'changeTimeZone',
    'click .js-switch-pages': 'switchPages',
    'click #js-submit': 'submitForm',
  },

  initialize: function(args) {
    this.attorneyId = args.attorneyId;
    this.jobId = args.jobId;
    this.bidId = args.bidId;
    this.hamCallback = args.hamCallback;
    this.onSuccessfulSchedule = args.onSuccessfulSchedule || function(){};
    this.reschedule = args.reschedule;

    this.dayTimeSelector = new UC.partials.DayTimeSelector({
      el: this.$el
    });
  },

  modalOpened: function() {
    $('body').addClass('instant-call-scheduler-modal-open');

    var time_to_first_availability = this.$('.modal-dialog').data('firstAvailability');
    UC.utils.recordKissEvent('schedule instant call - modal opened',
      $.extend(this.standardKissData(), {
        time_to_first_availability: time_to_first_availability
      })
    );
  },

  modalClosed: function() {
    $('body').removeClass('instant-call-scheduler-modal-open');
    this.$el.remove();
  },

  switchPages: function(e) {
    if ($(e.currentTarget).hasClass('disabled')) return;

    UC.utils.recordKissEvent('schedule instant call - clicked ' + $(e.currentTarget).text() + ' btn', this.standardKissData());

    this.$('#js-select-time-page').toggleClass('hidden');
    this.$('#js-confirm-time-page').toggleClass('hidden');
  },

  setSelectTimeHeader: function(day) {
    var text = (day) ? 'Select a time on ' + day : 'Select a time';
    this.$('#js-select-a-time-header').text('2. ' + text);
    this.$('#js-select-a-day-text').toggleClass('hidden', !!day);
  },

  goHam: function() {
    UC.net.get(this.routes.instant.format(this.attorneyId), { job_id: this.jobId, is_modal: true })
      .done(function(res) {
        if (res.availability) {
          this.render(res.html);
        } else {
          // If there's no availability, pop the regular "new" modal
          new UC.modals.ScheduledCallsNew({
            attorney_id: this.attorneyId,
            noInstantAvailability: true,
            onRequest: this.onSuccessfulSchedule
          }).render();
        }
      }.bind(this))
      .always(function() {
        if (this.hamCallback) this.hamCallback();
      }.bind(this));
  },

  serializeForm: function() {
    var data = {
      time_zone: this.$('#js-time-zone').val(),
      message: this.$('#js-message').val(),
      phone: this.$('#js-phone-number').val(),
      ScheduledDateTime: this.$('#js-scheduled-date-time').val()
    };

    return data;
  },

  validate: function() {
    var errors = [];

    var $phone = this.$('#js-phone-number');

    if($phone.val().isBlank()) {
      errors.push({
        element: $phone,
        text: 'Please enter your preferred phone number',
        className: 'form-group'
      });
    }

    var $message = this.$('#js-message');
    if($message.val().isBlank()) {
      errors.push({
        element: $message,
        text: 'Please include a message',
        className: 'form-group'
      });
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      return false;
    } else {
      return true;
    }
  },

  changeTimeZone: function(e) {
    e.preventDefault();

    var data = {
      time_zone: this.$('#js-time-zones').val(),
      job_id: this.jobId,
      is_modal: false
    };

    UC.net.get(this.routes.instant.format(this.attorneyId), data, function(res) {
      this.$('#js-instant-scheduler-container').replaceWith(res.html)
    }.bind(this));

  },

  submitForm: function(e) {
    e.preventDefault();
    if (!this.validate()) return;

    this.loader.showLoader();
    this.$switchPageBtns.addClass('disabled');

    var data = this.serializeForm();
    if (this.jobId) data.job_id = this.jobId;
    if (this.reschedule) data.reschedule = this.reschedule;

    var time_till_call = this.$('#js-scheduled-date-time-epoch').val() - this.$('.modal-dialog').data('modalOpenedAt');
    UC.utils.recordKissEvent('schedule instant call - scheduled ',
      $.extend(this.standardKissData(), { scheduled_time: data.ScheduledDateTime, time_till_call: time_till_call })
    );

    UC.net.post(this.routes.create_schedule_from_instant_reply.format(this.attorneyId), data)
      .done(function(result) {
        this.$el.modal('hide');
        UC.utils.showNotification(result.message, UC.constants.results.success);
        if (typeof this.onSuccessfulSchedule === 'function') {
          this.onSuccessfulSchedule();
        }
      }.bind(this))
      .always(function(xhr) {
        this.loader.hideLoader();
        this.$switchPageBtns.removeClass('disabled');
        if (xhr && xhr.responseJSON && xhr.responseJSON.error_code === 'bid_retracted') {
          this.$el.modal('hide');
          setTimeout(function() { location.reload() }, 3000);
        }
      }.bind(this));
  },

  standardKissData: function() {
    return {
      bid_id: this.bidId,
      job_id: this.jobId
    };
  },

  render: function(html) {
    this.$el.html(html);

    this.setSelectTimeHeader();

    this.loader = this.$('#js-submit').buttonLoader();
    this.$switchPageBtns = this.$('.js-switch-pages');

    this.$el.modal();
  }
});
