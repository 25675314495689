UC.views.JobsDetails = Backbone.View.extend({
  events: {
    'click .ji-nav-tab' : 'handleSidebarTabClick'
  },

  initialize: function() {
    UC.utils.showComponent(this.$('#boxJobDetails'))
    UC.utils.recordKissClickEvent('.ji-msg-link', 'Job Details - Send Message Click');
  },

  handleSidebarTabClick: function(e) {
    e.preventDefault();

    var $target = $(e.target)
    var $boxJobDetails = this.$('#boxJobDetails');
    var $boxClientProfile = this.$('#boxClientProfile');

    UC.utils.hideComponents([$boxClientProfile, $boxJobDetails]);

    this.$('.ji-nav-tab').removeClass('active');
    $target.addClass('active');

    if ($target.data('val') === "ji-client-profile") {
        UC.utils.showComponent($boxClientProfile);
    } else {
        UC.utils.showComponent($boxJobDetails);
    }
  }
});
