import { validateOTPCodeFormat, validateRecoveryCodeFormat } from './functions'

export default Backbone.View.extend({
    initialize: function(options) {
        this.options = options || {};
        this.codeInputSelector = this.options.codeInputSelector || 'input#verification-code';
        this.submitButtonSelector = this.options.submitButtonSelector || '#verify-mfa-button';
        $(`#${options.formId || 'two-factor-request-form'}`).on('submit', this.onSubmit.bind(this))
        setTimeout(function () {
            $(this.codeInputSelector).focus();
        }.bind(this), 500)
    },

    onSubmit: function(e) {
        e.preventDefault();
        const btnLoader = this.$el.find(this.submitButtonSelector).buttonLoader();
        const $verificationCode = this.$el.find(this.codeInputSelector);
        const successCallback = this.options.successCallback;
        const failureCallback = this.options.failureCallback;
        const url = this.options.formUrl || '/sessions/two-factor';
        const params = this.options.requestParams || {};
        params.code = $verificationCode.val();
        const validationFunction = this.options.recovery ? validateRecoveryCodeFormat : validateOTPCodeFormat;

        if (validationFunction($verificationCode)) {
            btnLoader.showLoader();

            UC.net.post(
                url,
                params,
                function (response) {
                    btnLoader.hideLoader();
                    if (successCallback) {
                        successCallback(response);
                    }
                }
            ).fail(function () {
                btnLoader.hideLoader();
                $verificationCode.val('');
                $verificationCode.focus();
                if (failureCallback) {
                    failureCallback();
                }
            });
        } else {
            $verificationCode.focus();
        }
    },
});
