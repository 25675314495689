!function() {

  UC.views.OffersIndex = Backbone.View.extend({

    initialize: function() {
      this.components = {
        FollowUpOffers: new UC.components.FollowUpOffers({
          el: '#follow-up-offers-container'
        })
      };
    }

  });

}();
