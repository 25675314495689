UC.components.AvailabilityTimeRangesView = Backbone.View.extend({

  template: UC.views.resolveHandlebarsTemplate("components/calendar_availability/availability_time_ranges"),

  events: {
    'submit' : 'submitAvailability'
  },

  routes: {
    availability_time_ranges: '/calendars/availability_time_ranges'
  },

  initialize: function(opts) {
    UC.net.get(this.routes.availability_time_ranges)
      .done(function(res) {
        this.collection = new UC.components.AvailabilityTimeRanges(res.availability_time_ranges);
        this.render();
      }.bind(this));
  },

  render: function() {
    this.$el.html(this.template(this.templateData));
    this.$avaliabilityRangeGroups = this.$('#availability-range-groups');

    _.each(this.availabilityDays, function(day) {
      var dayGroupView = new UC.components.AvailabilityDayGroupView({
        day: day,
        collection: this.collection
      });

      dayGroupView.render();

      this.$avaliabilityRangeGroups.append(dayGroupView.$el);
    }, this)

    this.submitBtnLoader = this.$('#submit-time-ranges').buttonLoader();
  },

  templateData: (function() {
    return { advanceNoticeBufferOptions: _advanceNoticeBufferOptions() }

    function _advanceNoticeBufferOptions() {
      var _results = [_formatOption(1)];

      for (var i = 2; i < 9; i++) { _results.push(_formatOption(i)); }
      _results.push(_formatOption(24));

      return _results;

      function _formatOption(option) {
        var _hourString;

        if (option > 1) { _hourString = ' hours' } else { _hourString = ' hour' };

        return {
          value: option,
          display: option + _hourString,
          selected: UC.constants.advance_notice_buffer === option
        };
      }
    }
  })(),

  availabilityDays: [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ],

  submitAvailability: function(e) {
    e.preventDefault();
    this.submitBtnLoader.showLoader();

    UC.net.post(this.routes.availability_time_ranges, {
      availability_time_ranges: this.collection.toJSON(),
      advance_notice_buffer:
        UC.utils.findSelectedElement(this.$el.find('.js-advance-notice-dropdown')).val()
    })
      .done(function(res) {
        UC.utils.showNotification(res.message, UC.constants.results.success);
      })
      .always(function() {
        this.submitBtnLoader.hideLoader();
      }.bind(this));
  }

});
