UC.partials.ScheduledCallsChooseAvailable = Backbone.View.extend({

  el: '#scheduled_calls_form_reply',

  events: {
    'submit': 'reply',
    'change #js-time-zones': 'changeTimeZone',
    'click .js-switch-pages': 'nextButtonClick',
    'click .js-confirm-back': 'nextButtonClick',
    'click .js-not-available-btn': 'unavailableButtonClick',
    'click .js-cancel-back': 'unavailableButtonClick',
    'click .js-cancel-submit': 'sendMessageClick',
    'click .js-cancel': 'cancelClick',
  },

  routes: {
    scheduled_calls_show: '/scheduled_calls/{0}',
    instant: '/users/{0}/scheduled_calls/instant',
    scheduled_calls_provide_alternatives: '/scheduled_calls/{0}/provide_alternatives',
    create_from_instant_reply_alternatives: '/users/{0}/scheduled_calls/create_from_instant_reply_alternatives',
    scheduled_calls_cancel: '/scheduled_calls/{0}/cancel'
  },

  initialize: function(options) {
    this.options = options || {};
    this.time_zone_selected = false;
    this.scheduled_call_id = this.$('#scheduled_call_id').val();
    this.loader = (options.submitButton || this.$('.btnScheduleCallReply')).buttonLoader();
    this.jobId = options.jobId;
    this.instant = options.instant;
    this.attorneyId = options.attorneyId;
    this.defaultMessage = options.defaultMessage;
    this.isModal = !!options.isModal;

    this.selectTimeZone();

    this.$('[data-toggle="tooltip"]').tooltip();

    this.times_available_view = new UC.partials.ScheduledCallsTimesAvailable({
      el: this.$('#times-available')
    });

    this.dayTimeSelector = new UC.partials.DayTimeSelector({
      el: this.$el
    });
  },

  selectTimeZone: function() {
    if (this.time_zone_selected === false) {
      // only try selecting the user's time zone once
      this.$('#TimeZone').selectTimeZone();
      this.time_zone_selected = true;
    }
  },

  changeTimeZone: function(e) {
    e.preventDefault();
    var path;

    if (this.instant) {
      path = '/users/{0}/scheduled_calls/instant'.format(this.attorneyId);
    } else {
      path = this.routes.scheduled_calls_show.format(this.scheduled_call_id);
    }

    var data = {
      time_zone: this.$('#js-time-zones').val(),
      job_id: this.jobId,
      is_modal: this.isModal
    };

    UC.net.get(path, data, function(res) {
      this.$('#js-show-call-modal').replaceWith(res.html);
    }.bind(this));
  },

  validate: function() {
    var errors = [];
    var $scheduled_time = this.$('#js-scheduled-date-time');
    var $message = this.$('#message');

    UC.utils.clearValidationErrors('form-group');

    if (!$scheduled_time.val()) {
      errors.push({
        element: $scheduled_time,
        text: 'Select a date and time you are available',
        className: 'form-group'
      });
    }

    if (!$message.val()) {
      errors.push({
        element: $message,
        text: 'Please include a message',
        className: 'form-group'
      });
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      return false;
    } else {
      return true;
    }
  },

  serializeForm: function() {
    var data = {
      time_zone: this.$('#js-time-zones').val(),
      message: this.$('#message').val(),
      ScheduledDateTime: this.$('#js-scheduled-date-time').val()
    };

    return data;
  },

  reply: function(e) {
    e.preventDefault();
    if (!this.validate()) return;

    this.loader.showLoader();
    var data = this.serializeForm();

    if (this.jobId) data.job_id = this.jobId;

    UC.net.post(this.replyPath(), data)
      .done(function(result) {
        UC.utils.showNotification(result.message, UC.constants.results.success);

        if (typeof this.options.onReply === 'function') {
          this.options.onReply(result);
        }

        UC.utils.sendNesEvent('schedule_call_reply_choose_time', this.eventData());

        this.loader.hideLoader();
      }.bind(this))
      .fail(function() {
        this.loader.hideLoader();
      }.bind(this));

  },

  replyPath: function() {
    return this.$('#scheduled_calls_form_reply').attr('action');
  },

  unavailableButtonClick: function(e) {
    e.preventDefault();
    this.$(".js-select-time-step").toggleClass("hidden");
    this.$(".js-not-available-step").toggleClass("hidden");
  },

  nextButtonClick: function(e) {
    e.preventDefault();
    this.$(".js-select-time-step").toggleClass("hidden");
    this.$(".js-confirm-step").toggleClass("hidden");
  },

  sendMessageClick: function(e) {
    e.preventDefault();
    var errors = [];
    var $message = this.$('#unavailable-message');

    UC.utils.clearValidationErrors('form-group');

    if (!$message.val()) {
      errors.push({
        element: $message,
        text: 'Please include a message',
        className: 'form-group'
      });
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
    } else {
      var data = {
        message: $message.val()
      }

      var path = this.routes.scheduled_calls_cancel.format(this.scheduled_call_id);
      var submitLoader = this.$('.js-not-available-btn').buttonLoader();
      submitLoader.showLoader();

      UC.net.post(path, data)
        .done(function(result) {
          UC.utils.showNotification("Your message has been sent", UC.constants.results.success);

          if (typeof this.options.onReply === 'function') {
            this.options.onReply(result);
          }

          UC.utils.sendNesEvent('schedule_call_reply_cancel_message', this.eventData());

          submitLoader.hideLoader();
        }.bind(this))
        .fail(function() {
          submitLoader.hideLoader();
        }.bind(this));
    }
  },

  cancelClick: function(e) {
    e.preventDefault();
    this.$el.modal('hide');
  },

  eventData: function() {
    var data = {
      json_attributes: {
        client_id: this.$('#scheduled_calls_form_reply').data('clientId'),
        bid_id: this.$('#scheduled_calls_form_reply').data('bidId')
      },
      attorney_id: this.$('#scheduled_calls_form_reply').data('attorneyId')
    };

    return data;
  }
});
