!function() {

  UC.partials.ContentPagesShowContentSubHub = Backbone.View.extend({

    events: {
      'click .csh-see-more-btn' : 'showHiddenXSContent',
      'click .csh-see-less-btn' : 'hideXSContent'
    },

    initialize: function() {
      this.$tiles = this.$el.find('.ch-tile');
      this.resizeTiles();
    },

    resizeTiles: function() {
      var maxHeight = 0;
      this.$tiles.each(function(){
        if ($(this).height() > maxHeight) maxHeight = $(this).height();
      });
      this.$tiles.height(maxHeight);
    },

    showHiddenXSContent: function(e) {
      var $btn = $(e.currentTarget);
      var $section = $btn.closest('.csh-section-container');
      $section.find('.toggle-show').removeClass('hidden-xs');
      $btn.addClass('hidden-xs');
    },

    hideXSContent: function(e) {
      var $btn = $(e.currentTarget);
      var $section = $btn.closest('.csh-section-container');
      $section.find('.toggle-show').addClass('hidden-xs');
      $section.find('.csh-see-more-btn').removeClass('hidden-xs');
    }

  });

}();
