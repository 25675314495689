!function() {

  UC.views.StaticPagesPricingPage = Backbone.View.extend({

    initialize: function() {

      $(".js-pricing-cta-btn").on('click',function(e){
        var cat = $(e.target).closest('.child').first('.pricing-name').text().trim();
        var p = window.location.pathname;
        UC.utils.recordKissEvent("clicked_paj_button", {"location": "body" ,"category": cat, "page-path": p});
      });

    }

  });
}();
