var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "            <div class=\"tr\">\n              <div class=\"tc top-legal-group-rank pad-t-15\" style=\"width: 25px;\">"
    + alias2(alias1(depth0, depth0))
    + "</div>\n              <div class=\"tc pad-t-15\">\n                <select name=\"top-legal-group-"
    + alias2(alias1(depth0, depth0))
    + "\" data-legal-group-rank=\""
    + alias2(alias1(depth0, depth0))
    + "\"></select>\n              </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n\n    <div class=\"modal-header\">\n\n      <h4 class=\"modal-title\">Select your practice areas</h4>\n\n    </div>\n\n    <div class=\"modal-body\">\n      Of the practice areas you have selected, please indicate which are your main areas of focus. You can select up to\n      three that will be highlighted on your profile.\n\n      <form id=\"attorney-top-legal-groups-form\">\n        <div class=\"table\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"availableRanking") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":10},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n      </form>\n\n      <div id=\"error-container\">\n        <p class=\"help-block error-message\"></p>\n      </div>\n    </div>\n\n    <div class=\"modal-footer\">\n      <button type=\"button\" class=\"btn btn-primary\" id=\"submit-top-legal-groups\">Save</button>\n    </div>\n\n  </div>\n</div>\n";
},"useData":true});