!function () {

  UC.modals.UserDocumentsModalChooseDocument = Backbone.View.extend({

    data: {},

    initialize: function (options) {
      options = options || {};
      this.parent_view = options.parent_view;
      this.base_path = UC.constants.user_documents_path;
      this.save_callback = options.save_callback;
      this.components = {}
    },

    render: function (options) {
      this.renderModal(options);
    },

    renderModal: function (options) {
      var data = {
        job_id: options.job_id,
        initial: true
      };

      UC.net.get(this.base_path + '/choose', data, function (modalHtml) {
        this.chooseDocumentModal = new UC.partials.modalV9({
          modalTitle: 'Upload Existing Document',
          modalContent: modalHtml,
          id: 'modalChooseDocument',
          modalFooter: {
            yesTextClass: 'btn-lg btn-primary',
            yesTextId: 'btnSave',
            noTextClass: 'btn-lg btn-grey-BG jqmClose',
            noTextId: 'aCancelLink',
            noText: 'Cancel',
            yesText: 'Confirm'
          },
          renderCallback: function () {
            $('#btnSave').on('click', this.save.bind(this))
            $('.documents_search').on('submit', this.search.bind(this))
            this.initList(modalHtml, true);
          }.bind(this)
        });
      }.bind(this));
    },

    initList: function (html, empty) {
      var that = this;
      this.resetList();
      var $v9Modal = $('#modalChooseDocument.v9');

      if (empty) {
        this.$el.empty();
        this.components.btnSaveLoader = $('#btnSave').buttonLoader();
      } else {
        $v9Modal.find('.doc-list-wrapper').replaceWith(html);
      }

      this.$chkNew = $('#chkCreateNew').fancyCheck({
        label: $('#chkCreateNew').attr('label')
      });

      var $rows = $v9Modal.find('tr.document-row');

      $rows.each(function () {
        var view = new UC.views.UserDocumentsRow({
          el: this
        });
        that.rows.push(view);
      });

      $v9Modal.find('#docListPrev, #docListNext').click(this.renderPage.bind(this));
      $v9Modal.find('.sort-menu li').click(this.changeFilter.bind(this));
      $v9Modal.find('.doc-list .doc-filter-item td').click(this.clickFilterItem.bind(this));
      $v9Modal.find('.documents_search').on('submit', this.search.bind(this))
      this.$check = $('#modalChooseDocument.v9 .check-all').fancyCheck();
      UC.utils.recordKissClickEvent('#chkCreateNew',
        'check_create_new_copy',
        {userId: UC.user.id, jobId: UC.job.id});
    },

    resetList: function () {
      // remove and unbind actions if exists
      if (this.actions) this.actions.remove();
      // remove and unbind any existing rows
      _.each(this.rows, function (row) {
        // remove backbone view & unbind events
        row.remove();
      });
      this.rows = [];
    },

    save: function (e) {
      e.preventDefault();

      var create_new = this.chooseDocumentModal.$el.find('#chkCreateNew').hasClass('checked');
      var document_ids = [];
      var $checks = this.chooseDocumentModal.$el.find('td .bulk-check');
      $checks.each(function () {
        var $this = $(this);
        if ($this.hasClass('checked')) {
          var $row = $this.closest('.document-row')
          document_ids.push($row.data('id'));
        }
      });

      if (document_ids.length > 0) {
        this.components.btnSaveLoader.showLoader();
        UC.utils.recordKissEvent(
          'submit_choose_documents',
          {
            userId: UC.user.id,
            jobId: UC.job.id,
            documentIds: document_ids,
            createNew: create_new
          });

        UC.net.post(this.base_path + '/attach', {
          job_id: UC.constants.jobId,
          document_ids: document_ids,
          create_new: create_new
        }, function (data) {
          if (document_ids.length == $checks.length) {
            $('#btnToggleUpload').addClass('hidden');
          }
          var $notification = $('.js-success-notification');
          $notification.find('.flash-message').text(data.message);
          $notification.removeClass('hidden');
          this.save_callback();
        }.bind(this)).always(function () {
          this.components.btnSaveLoader.hideLoader();
          this.close();
        }.bind(this));

      } else {
        this.close();
      }
    },

    renderPage: function (e) {
      e.preventDefault();
      var path = $(e.target).attr('href'),
        data = {
          job_id: UC.job.id
        };

      if (this.data.filter) data.filter = this.data.filter;
      if (this.data.filter_id) data.id = this.data.filter_id;

      UC.net.get(path, data, function (html) {
        this.initList(html);
      }.bind(this));
    },

    // need this because when the jqm related
    // events aren't re-applied when we re-render
    close: function (e) {
      if (e) e.preventDefault();
      if (e) e.stopPropagation();
      this.resetList();
      this.chooseDocumentModal.$el.modal('hide')
    },

    changeFilter: function (e) {
      if (e) e.preventDefault();

      var query = this.data.query;
      var filter = $(e.target).data('filter');
      var kissData = {
        location: 'job_portal',
        filter: filter,
        userId: UC.user.id,
        query: query
      };

      UC.utils.recordKissEvent('document_filter_changed', kissData);

      var data = {
        filter: filter,
        job_id: UC.job.id,
        q: query
      };

      return $.get('/documents/choose', data, function (html) {
        this.data.filter = filter;
        delete this.data['filter_id'];
        this.initList(html);
      }.bind(this));
    },

    clickFilterItem: function (e) {
      var $row = $(e.target).closest('tr'),
        targetId = $row.data('id'),
        title = $row.data('title'),
        filter = this.data.filter,
        kissData = {
          location: 'job_portal',
          filter: filter,
          item_id: targetId,
          userId: UC.user.id
        };

      UC.utils.recordKissEvent('document_filter_item_clicked', kissData);
      this.data.filter_id = targetId;
      delete this.data['filter'];

      var data = {
        filter: filter,
        id: targetId,
        job_id: UC.job.id
      };

      $.get('/documents/choose', data, function (html) {
        this.initList(html);
      }.bind(this));
    },

    search: function (e) {
      e.preventDefault();

      var $search_input = this.chooseDocumentModal.$('.documents_search_input');

      this.data.query = $search_input.val();

      this.changeFilter(e).done(function () {
        this.data.query = null;

        this.chooseDocumentModal.$('.documents_search_input').focus(function () {
          this.selectionStart = this.selectionEnd = this.value.length;
        }).focus();
      }.bind(this));
    }
  });
}();
