UC = UC || {};
UC.attorneyProfileModals = UC.attorneyProfileModals || {};
UC.attorneyProfileModals.mainModal = {

  init: function() {
    $('#btnEditMain, .btn-edit-main').click(this.openModal.bind(this));
  },

  components: {},

  openModal: function(e) {
    if (e) e.preventDefault();

    $(".row").removeClass("has-error");

    this.$modal = $('#mdlEditMain').clone().modal({
      backdrop: 'static'
    });

    this.components.btnLoader = this.$modal.find('#btnSaveMain').buttonLoader();

    this.rowId = UC.constants.bars_count || 0;

    this.$modal.find('#tblBarCerts .glyphicon.glyphicon-question-sign').tooltip();
    this.$modal.find('#tblBarCerts .datepicker').datepicker(this.datepickerOptions);

    if (this.$modal.find('#tblBarCerts .bar-row').length == 0) {
      this.addBarCert();
    };

    this.showBarTypes();

    this.showBarType($('#barCertTemplate div.bar-row'));

    this.setListeners();
  },

  setListeners: function() {
    this.$modal.on('change', '.barType', this.updateBarType.bind(this));
    this.$modal.on('click', '.js-lnkRemoveBarCert', this.removeBarCert.bind(this));
    this.$modal.on('click', '#lnkAddBarCert', this.addBarCert.bind(this));
    this.$modal.on('click', '#btnSaveMain', this.saveModalForm.bind(this));

    this.$modal.on('hidden.bs.modal', function() {
      this.$modal.remove();
    }.bind(this));

    this.$modal.find("select[name='set_law_school']").superSmartSearch({
      url: '/schools/smart_search',
      width: '100%',
      tags: true
    });
  },

  showBarTypes: function() {
    this.$modal.find('.bar-row').each(function(index, element) {
      this.showBarType($(element));
    }.bind(this));
  },

  datepickerOptions: {
    dateFormat: 'mm/dd/yy',
    changeMonth: true,
    changeYear: true,
    yearRange: "1940:+nn",
    maxDate: "0"
  },

  removeBarCert: function(e) {
    e.preventDefault();
    var $this = $(e.currentTarget),
        barCert = $this.attr('barCert');

    $this.parents('.bar-row').eq(0).remove();
  },

  updateBarType: function(e) {
    var $row = $(e.target).closest('.bar-row');
    this.showBarType($row);
  },

  showBarType: function($row) {
    var barCert = $row.attr('barCert'),
      selBarType = $row.find(".barType[barCert='" + barCert + "']"),
      selBarState = $row.find(".barState[barCert='" + barCert + "']"),
      txtBarCert = $row.find(".barCert[barCert='" + barCert + "']");

      var barType = selBarType.val().toLowerCase();

    if (barType == "state") {
      $row.find('.cert .barState').show();
      $row.find('.cert .barCert').hide();
    }
    else if (barType == "patent") {
      $row.find('.cert .barState').hide();
      $row.find('.cert .barCert').show();

      txtBarCert.val("Patent Bar");
      txtBarCert.prop('disabled', true);
    }
    else if (barType == "other") {
      $row.find('.cert .barState').hide();
      $row.find('.cert .barCert').show();

      txtBarCert.prop('disabled', false);
    }
  },

  addBarCert: function(e) {
    if (e) e.preventDefault();

    var newRow = this.createBarCertRow();

    this.$modal.find('#tblBarCertsFooter').before(newRow);
    this.$modal.find(".barType[barCert='" + this.rowId + "']").change(this.updateBarType.bind(this));
    this.$modal.find(".js-lnkRemoveBarCert[barCert='" + this.rowId + "']").click(this.removeBarCert.bind(this));

    newRow.find('.datepicker').datepicker(this.datepickerOptions);

    this.rowId++;
  },

  createBarCertRow: function() {
    var template = $('#barCertTemplate div.bar-row').clone();

    template.attr('barCert', this.rowId);
    template.find('*').each( function(index, element) {
      if ($(element).attr('barCert') === 'temp') {
        $(element).attr('barCert', this.rowId);
      }
    }.bind(this));

    return template;
  },

  saveModalForm: function(e) {
    var data = {
      Tagline: this.$modal.find('#txtTagline').val().trim(),
      set_law_school: this.$modal.find('#txtLawSchool').val(),
      ServicesRate: this.$modal.find('#txtServicesRate').val(),
      HaveInsurance: this.$modal.find('#selHasInsurance').val(),
      BarCerts: this.collectBarCerts()
    };

    if (this.validateMain(data)) {
      this.components.btnLoader.showLoader();
      UC.net.post(UC.constants.updateMainPath, data, this.onUpdateMain.bind(this)).
        always(function() {
          this.components.btnLoader.hideLoader();
        }.bind(this));
    };
  },

  onUpdateMain: function(response) {

    if (UC.action === 'index' && UC.controller === 'dashboard') {
      UC.utils.checklistSuccessCallback('btnEditMain');
    } else {
      $('#mainContent').html(response.html);
      $('#stats').html(response.stats_html);
    };

    $('#main-modal-template').html(response.main_modal_html);

    this.$modal.modal('hide');
  },

  collectBarCerts: function() {
    var barCerts = [];

    this.$modal.find("div.bar-row").each(function () {
      var barCert = {}, $this = $(this);

      if ($this.attr('barcert') !== 'temp') {
        barCert.bar_type = $this.find(".barType").val().toLowerCase();
        barCert.bar_state = $this.find(".barState").val();
        barCert.bar_cert_name = $this.find(".barCert").val();
        barCert.bar_number = $this.find(".barNumber").val();
        barCert.bar_admitted_date = $this.find(".barAdmittedDate").val();
        barCert.element = $this;
        barCerts.push(barCert);
      }
    });
    return barCerts;
  },

  validateMain: function(data) {
    $(".has-error").removeClass("has-error");
    var errors = [];

    if (!data.set_law_school) {
      errors.push({
        element: this.$modal.find('#txtLawSchool'),
        text: 'Law School is required.',
        className: 'form-group'
      })
    }

    if (!data.ServicesRate || data.ServicesRate <= 0) {
      errors.push({
        element: this.$modal.find('#txtServicesRate'),
        text: "Hourly Rate must be a number greater than 0",
        className: 'form-group'
      });
    }

    if (!data.HaveInsurance) {
      errors.push({
        element: this.$modal.find('#selHasInsurance'),
        text: 'Selection is required',
        className: 'form-group'
      });
    }

    if (data.BarCerts.length == 0) {
      errors.push({
        element: this.$modal.find('#tblBarCertsFooter'),
        text: 'Please provide at least one bar',
        className: 'form-group'
      });
    }

    $(data.BarCerts).each(function(){
      if (!this.bar_number) {
        errors.push({
          text: 'Number required',
          element: this.element.find('.barNumber')
        });
      };

      if (this.bar_type == 'other' && this.bar_cert_name == '') {
        errors.push({
          text: 'Bar required',
          element: this.element.find('.barCert')
        });
      };

      var dateFormatted = moment(this.bar_admitted_date, UC.constants.momentDateFormats, true);
      if (!this.bar_admitted_date) {
        errors.push({
          text: 'Date required',
          element: this.element.find('.barAdmittedDate'),
          className: 'bar-input'
        });
      } else if (!dateFormatted.isValid()){
        errors.push({
          element: this.element.find('.barAdmittedDate'),
          text: "(mm/dd/yyyy)",
          className: 'bar-input'
        });
      } else if (moment().isBefore(dateFormatted)) {
        errors.push({
          element: this.element.find('.barAdmittedDate'),
          text: "Future date",
          className: 'bar-input'
        });
      };

      delete this.element
    });

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      return false;
    }

    return true;
  }

};
