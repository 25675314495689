
// handles floating point arithmetic accuracy for a specified precision
UC.utils.handleFloatPrecision = function(result, precision) {
  var divisor = Math.pow(10, precision);
  return ( Math.round(result * divisor) / divisor ).toFixed(precision);
};

// handles float precision when mutliplying two numbers
UC.utils.multiplyCurrency = function(a, b) {
  return UC.utils.handleFloatPrecision(a * b, 2);
};

// parses a string with thousanths separators into floats
UC.utils.parseCurrency = function(value) {
  var decimal = UC.utils.currencyToFloat(value);
  return UC.utils.handleFloatPrecision(decimal, 2);
};

// formats a float back into a string for display
UC.utils.formatCurrency = function (value, addCurrencySymbol, precision) {

  if (isNaN(precision)) {
    precision = 2;
  }

  var value = UC.utils.currencyToFloat(value);
  var currencyValue = parseFloat(value, 10).toFixed(precision);
  var negative = currencyValue < 0;

  if (negative) {
    currencyValue *= -1;
    currencyValue = currencyValue.toFixed(precision);
  }

  var pieces = currencyValue.split('.');
  var dollars = pieces[0];
  var cents = pieces.length > 1 ? '.' + pieces[1] : '';
  var rgx = /(\d+)(\d{3})/;

  // add thousands separators to dollar value
  while (rgx.test(dollars)) {
    dollars = dollars.replace(rgx, '$1' + ',' + '$2');
  }

  return '{0}{1}{2}{3}'.format(
    negative ? '-' : '',
    addCurrencySymbol ? '$' : '',
    dollars,
    cents
  );
};

UC.utils.stripCurrency = function (value) {
  return value.toString().replace(/[,$]/g, '');
};

UC.utils.currencyToFloat = function(value) {
  return parseFloat(UC.utils.stripCurrency(value)) || 0.0;
};
