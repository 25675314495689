!function() {

  UC.views.ReviewsNew = UC.views.AttorneyReviewPortalNewReview = Backbone.View.extend({

    elements: {},

    initialize: function() {
      this.skipUrl = $('#aSkip').attr('href');

      this.loader = this.$el.find('#btnSubmitReview').buttonLoader();
      this.$el.find('#review_AdditionalWork').select2({width: '100%'});

      this.reviewsForm = new UC.partials.ReviewsForm({
        el: '#attorneyReviewPage'
      });
    }
  })
}();
