!function() {

  UC.components.PaymentInfoV1 = Backbone.View.extend({

    events: {
      'change #selectCountry' : 'onChangeCountry',
      'click #aChangeCard' : 'onChangeCardClick',
      'change #chkDefaultMethod': 'trackDefaultMethod',
      'click .btnPay' : 'onBtnPayClick',
      'click #add-bank-account-panel' : 'openPlaid',
      'click #add-credit-card-panel' : 'showCreditCardForm',
      'click #credit-card-form-back' : 'showAddPaymentMethod',
      'click #choose-existing-pmt-method' : 'showExistingPmtMethods',
      'click #change-pmt-method' : 'showAddPaymentMethod',
      'click #panelCouponFieldsToggle' : 'showCouponFields',
      'click .js-cancel-pay': 'showAddPaymentMethod',
      'click .js-back-to.payment-methods': 'showExistingPmtMethods',
      'click .js-back-to.proposals': 'backToProposalsClick'
    },

    initialize: function(args) {
      this.data = {};

      this.components = {
        loader: this.$el.find('.btnPay').buttonLoader(),
        chkDefaultMethod: this.$el.find('#chkDefaultMethod').fancyCheck(),
        addBankAccountLoader: this.$el.find('.js-add-bank-account').buttonLoader()
      };

      this.bankAccountSuccessCallback = args.bankAccountSuccessCallback;
      this.showDefaultButtonLoader = args.showDefaultButtonLoader;

      this.elements = {
        selectMonth: this.$el.find('#selectMonth'),
        selectYear: this.$el.find('#selectYear'),
        selectState: this.$el.find('#selectState'),
        selectCountry: this.$el.find('#selectCountry'),
        txtZipCode: this.$el.find('#txtZipCode'),
        agreeToTermsCheckboxes: this.$el.find(".js-agreeToTermsCheckbox"),
        agreeToTermsDates: this.$el.find(".js-agreeToTermsDate"),
        formCollectInfo: this.$el.find('#formCollectInfo'),
        selectPaymentMethod: this.$('#selectPaymentMethod')
      };

      this.elements.selectMonth.val(new Date().getMonth() + 1);

      this.initYearSelect();
      this.initSelect2();
      this.initPlaid();

      this.$('#cvc-info').tooltip();
      UC.utils.initRatingDimensions('.js-attorney-rating');
      UC.utils.recordKissEvent('Viewed Request upfront payment');
    },

    backToProposalsClick: function() {
      UC.utils.recordKissEvent('Clicked Back To Proposals');
    },

    initYearSelect: function() {
      var year = new Date().getFullYear();
      var endYear = year + 12;
      var options = '';

      for (var i = year; i < endYear; i++) {
        options += '<option value="{0}">{0}</option>'.format(i);
      }

      this.elements.selectYear.html(options);
    },

    initSelect2: function() {

      if (!this.elements.formCollectInfo.hasClass('hidden')) {
        this.elements.selectMonth.select2();
        this.elements.selectYear.select2();
        this.elements.selectState.select2();
        this.elements.selectCountry.select2();
      }

      if (!this.elements.selectPaymentMethod.hasClass('hidden')) {
        this.$('#selectPaymentMethod').select2();
      }
    },

    onChangeCardClick: function(e) {
      e.preventDefault();
      $('#formCollectInfo').removeClass('hidden');
      $('#payment-methods').addClass('hidden');
      this.initSelect2();
    },

    onChangeCountry: function(e) {
      var country = UC.utils.findSelectedElement(this.elements.selectCountry);
      if(country.val() != 'USA'){
        UC.utils.setSelectedElement(this.elements.selectState, UC.constants.emptySelectValue);
        this.elements.txtZipCode.val('');
        this.elements.selectState.prop('disabled', true);
        $('#state-zip-input-row').addClass('hidden');
      }
      else{
        this.elements.selectState.prop('disabled', false);
        $('#state-zip-input-row').removeClass('hidden');
      }
    },

    onBtnPayClick: function(e) {
      UC.utils.recordKissEvent('Payment Flow - ' + $(e.currentTarget).text().trim(), {
        job_id: UC.job.id
      });

      this.components.loader.showLoader();

      var isFormHidden = this.elements.formCollectInfo.hasClass("hidden");
      var isUserAgreeToTerms = this.validateAgreeToTerms();

      if (isFormHidden && isUserAgreeToTerms) {
        this.charge(null, this.onCharge.bind(this));
      } else if (this.validate()) {
        UC.payments.tokenizeCard(UC.payments.createCreditCardData(this.data), this.onCreateToken.bind(this));
      } else {
        this.components.loader.hideLoader();
      }
    },

    onCreateToken: function (err, token) {
      if(err){
        this.components.loader.hideLoader();
        UC.utils.showNotification(err, UC.constants.results.failure, 10000);
      }
      else{
        this.charge(token, this.onCharge.bind(this));
      }
    },

    submitCharge: function(url, data, callback) {
      UC.net.post(url, data, callback).fail(function() {
        this.components.loader.hideLoader();
      }.bind(this));
    },

    isAgreeToTermsChecked: function() {
      var isChecked = this.elements.agreeToTermsCheckboxes.filter(":checked").length;
      var isAgreedToPreviously = this.elements.agreeToTermsDates.length;

      return isChecked || isAgreedToPreviously;
    },

    validateAgreeToTerms: function () {
      if (!this.isAgreeToTermsChecked()) {
        var error =
          { element: this.elements.agreeToTermsCheckboxes, className: "form-group" }

        UC.utils.showValidationErrors([error]);

        return false;
      } else { return true }
    },

    validate: function () {
      var errors = [];
      var now = new Date();

      this.$('.form-group').removeClass('has-error');

      this.data = this.elements.formCollectInfo.serializeJSON();

      if (this.data.nameOnCard === '') {
        errors.push({element: this.$el.find('#txtNameOnCard'), text: 'Please enter the name on your card.'});
      }

      if (!UC.payments.validateCardNumber(this.data.cardNumber)) {
        errors.push({element: this.$el.find('#txtCardNumber'), text: 'Invalid credit card number.'});
      }

      var yearInPast = this.data.expireYear < now.getFullYear()
      var monthInPast = this.data.expireYear === now.getFullYear() && this.data.expireMonth < now.getMonth() + 1

      if (yearInPast || monthInPast) {
        errors.push({element: this.$el.find('#selectMonth'), text: 'Invalid expiration date.'});
      }

      if (!UC.payments.validateSecurityCode(this.data.cvc)) {
        errors.push({element: this.$el.find('#txtCvc'), text: 'Invalid CVC number.'});
      }

      if (this.data.address === '') {
        errors.push({element: this.$el.find('#txtAddress'), text: 'Please enter your billing address.'});
      }

      if (this.data.city === '') {
        errors.push({element: this.$el.find('#txtCity'), text: 'Please enter your billing city.'});
      }

      if (this.data.state === UC.constants.emptySelectValue && this.data.country === 'USA') {
        errors.push({element: this.$el.find('#selectState'), text: 'Please select your billing state.'});
      }

      if (this.data.country === UC.constants.emptySelectValue) {
        errors.push({element: this.$el.find('#selectCountry'), text: 'Please enter your billing country.'});
      }

      if (this.data.country === 'USA' && !UC.utils.validateZipCode(this.data.zip)) {
        errors.push({element: this.$el.find('#txtZipCode'), text: 'Please enter a valid billing zip code.'});
      }

      var accountInfoPresent = this.$el.find('#accountInformation').length > 0;
      var accountInfoShowing = !this.$el.find('#accountInformation').hasClass('hidden');

      if (accountInfoPresent && accountInfoShowing) {

        if (this.data.firstName === '' || this.data.lastName === '') {
          errors.push({element: this.$el.find('#txtFirstName'), text: 'Please enter your first and last name.'});
        }

        if (this.data.password.length < UC.constants.minPasswordLength) {
          errors.push({
            element: this.$el.find('#txtPassword'),
            text: 'Please enter a password with at least {0} characters.'.format(UC.constants.minPasswordLength)
          });
        }
      }

      if (!this.validateAgreeToTerms()) {
        errors.push({ element: this.elements.agreeToTermsCheckboxes });
      }

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors, {scrollToFirst: true, className: 'form-group' });
        return false;
      }

      return true;
    },

    charge: function (token, callback) {
      var data = {};
      var accountId = this.$el.find('#selectPaymentMethod').val();

      if (token) {
        data.token = token;
        data.makeTokenDefault = this.components.chkDefaultMethod.hasClass('checked');
      }
      else if (accountId){
        data.accountId = accountId;
      }

      data.isAgreeToTermsChecked = !!this.isAgreeToTermsChecked();

      var accountInfoPresent = this.$el.find('#accountInformation').length > 0;
      var accountInfoShowing = !this.$el.find('#accountInformation').hasClass('hidden');

      if (accountInfoPresent && accountInfoShowing) {
        data.accountData = {
          firstName: this.data.firstName,
          lastName: this.data.lastName,
          password: this.data.password
        };
      }

      this.submitCharge(UC.constants.paymentPath, data, callback);
    },

    onCharge: function(res) {
      if (res && res.url) {
        location.href = res.url;
      } else {
        this.components.loader.hideLoader();

        if (res && res.message) {

          UC.utils.showNotification(res.message, UC.constants.results.failure, 10000);

        } else if (res) {

          errMessage = res == 'card-declined' ? 'Sorry, your card was declined.' : res;
          UC.utils.showNotification(errMessage, UC.constants.results.failure, 10000);

        }
      }
    },

    trackDefaultMethod: function(e, checked) {
      UC.utils.recordKissEvent('Checkout default payment check click', { checked: checked });
    },

    showCreditCardForm: function(e) {
      e.preventDefault();
      UC.utils.recordKissEvent('Clicked "Add credit card"');
      $('#formCollectInfo').removeClass('hidden');
      $('#add-payment-method').addClass('hidden');
      $('#cc-hold-info').removeClass('hidden');
      $('.upfront-payment-needed .title').addClass("hidden");
      $('.js-back-to').addClass("hidden");
      this.initSelect2();
      UC.utils.recordKissEvent('Viewed "Credit card screen"');
    },

    showAddPaymentMethod: function(e) {
      e.preventDefault();
      UC.utils.recordKissEvent('Viewed "Add payment method"');
      $('#formCollectInfo').addClass('hidden');
      $('#payment-methods').addClass('hidden');
      $('#add-payment-method').removeClass('hidden');
      $('.js-add-payment-method-container').removeClass('hidden');
      $('#cc-hold-info').addClass('hidden');
      $('.upfront-payment-needed .title').removeClass("hidden");
      $('.js-back-to').removeClass("hidden");
    },

    initPlaid: function() {
      var plaidVerification = new UC.plaidBankVerification({
        openPlaidLinkSelector: '#add-bank-account-panel',
        successRedirectUrl: window.location.href,
        successCallback: this.bankAccountSuccessCallback,
        showDefaultButtonLoader: this.showDefaultButtonLoader
      });

      $('#add-bank-account-panel').on('plaidLinkExit plaidLinkAuthFailure', this.showInstantAchNotAvailable);

      plaidVerification.init();
    },

    showInstantAchNotAvailable: function() {
      $('#instant-ach-not-available-container').removeClass('hidden');
    },

    showExistingPmtMethods: function(e) {
      e.preventDefault();
      $('#payment-methods').removeClass('hidden');
      $('#add-payment-method').addClass('hidden');
      this.initSelect2();
    },

    showCouponFields: function(e) {
      e.preventDefault();
      $(e.currentTarget).remove();
      this.$('#panelCouponFields').removeClass('hide');
      this.$('#txtCoupon').focus();
    }
  });
}();
