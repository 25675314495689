!function() {

  UC.components.PaymentDisputeClientRedirectModal = Backbone.View.extend({
    initialize: function(opts) {
      var opts = opts || {};

      this.redirectUrl = opts.redirectUrl || '/dashboard';
      this.title = opts.title || 'Recent Payment Dispute';
      this.text = opts.text || "Sorry, you cannot publish a new job in the marketplace due to a recent payment dispute." +
                               " Please contact our Marketplace Team at <a href='mailto:marketplace@upcounsel.com'>" +
                               "marketplace@upcounsel.com</a> if you have any questions.";


    },

    onModalConfirm: function(response) {
      if (response) this.redirectUser();
    },

    redirectUser: function() {
      location.href = this.redirectUrl;
    },

    show: function() {
      UC.utils.recordKissEvent('payment_dispute_client_notification_shown', {loc: window.location.href});
      UC.utils.showConfirmModal(this.title, this.text, this.onModalConfirm.bind(this), {yesText: 'OK', noText: ''});
    }

  })

}();
