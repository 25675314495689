UC.views.StaticPagesContact = Backbone.View.extend({
  events: {
    'submit #contactForm': 'onContactFormSubmit'
  },

  initialize: function() {
  },

  onContactFormSubmit: function (event) {
    event.preventDefault();

    var inputData = this.$el.find('#contactForm').serializeJSON().contact;

    var fields = [
      {
        element: this.$el.find('#txtName'),
        errorMessage: 'Please provide your name'
      }, {
        element: this.$el.find('#txtEmail'),
        errorMessage: 'Please provide a valid email address'
      }, {
        element: this.$el.find('#selectRecipient'),
        errorMessage: 'Please select what this is regarding'
      }, {
        element: this.$el.find('#txtSubject'),
        errorMessage: 'Please provide a subject'
      }, {
        element: this.$el.find('#txtMessage'),
        errorMessage: 'Please provide a message'
      }
    ];

    if (UC.utils.validateFields(fields)) {
      UC.net.post(UC.constants.postContactRoute, inputData, this.onSendContactMessageSuccess);
    }
  },

  onSendContactMessageSuccess: function () {
    UC.utils.hidePanel($('#panelContactForm'));
    UC.utils.showPanel($('#panelSuccess'));
  }
})
