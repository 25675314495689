!function() {

  $.fn.buttonLoader = function(options) {
    var that = this,
        $el = $(this),
        defaults = { },
        content = this.html(),
        opt = $.fn.extend({}, defaults, options);

    var html = '<span>{0}</span><i class="loader fontello icon-spin1 animate-spin hide"></i>'.format($el.html());
    $el.html(html);

    // http://stackoverflow.com/q/5985839/629356
    $el.attr('disabled', false);
    $el.data('button-loader', this);

    this.showLoader = function() {
      var $this = $(this);

      if (!$this.hasClass('disabled')) {
        that.disableBtn();
        $this.addClass('loading');
        $this.find('.loader').removeClass('hide')
      }
    };

    this.hideLoader = function() {
      var $this = $(this);

      $this.attr('disabled', false);
      $this.removeClass('loading');
      $this.find('.loader').addClass('hide')
    };

    this.disableBtn = function() {
      var $this = $(this);

      $this
        .css('min-width', $this.outerWidth())
        .attr('disabled', true);

      $this.trigger('propogateTracking');
    };

    return this;
  };
}();
