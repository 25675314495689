import { default as DropboxSign } from 'hellosign-embedded';

function openEmbeddedUrl(url, eventHandler) {
  const client = new DropboxSign({
    clientId: UC.constants.dropboxSignClientId
  });

  if (typeof eventHandler === 'function') {
    client.on(DropboxSign.events.SIGN, (data) => eventHandler(DropboxSign.events.SIGN, data));
    client.on(DropboxSign.events.CANCEL, (data) => eventHandler(DropboxSign.events.CANCEL, data));
    client.on(DropboxSign.events.SEND, (data) => eventHandler(DropboxSign.events.SEND, data));
  }

  return client.open(url, {
    allowCancel: true,
    skipDomainVerification: window.location.hostname === 'local.upcounsel.com',
    debug: window.location.hostname === 'local.upcounsel.com' || window.location.hostname === 'staging.upcounsel.com',
  });
}

function updateSignatureRequestAndReloadPage(documentId, event, data) {
  UC.net.post(
    '/signatures/user_document/{0}/update'.format(documentId),
    {...data, event: `signature_request_${event}`},
    function () {
      let url = window.location.href;
      if (url.indexOf('#upload') !== -1) {
        url = url.replace('#upload', '');
        window.location = url;
      } else {
        window.location.reload();
      }
    }
  );
}

export function openDropboxSignForSign(documentId) {
  UC.net.get(
    '/signatures/user_document/{0}/get_signature_url'.format(documentId),
    function (err, signUrl) {
      if (err) {
        UC.utils.showNotification(err, UC.constants.results.failure);
        return
      }

      UC.utils.showPanel($('#overlayPanel'));

      openEmbeddedUrl(
        signUrl,
        function (event, eventData) {
          if (event === DropboxSign.events.SIGN) {
            updateSignatureRequestAndReloadPage(documentId, event, eventData);
          } else if (event === DropboxSign.events.CANCEL) {
            UC.utils.hidePanel($('#overlayPanel'));
          }
        }
      );

    }
  );
}

export function openDropboxSignForClaim(claimData, modal) {
  const documentId = claimData.id;

  UC.net.post(
    '/signatures/user_documents/{0}/create'.format(documentId),
    claimData,
    function (err, claimUrl) {
      if (modal) {
        modal.hideLoader();
      }
      if (err) {
        UC.utils.showNotification(err, UC.constants.results.failure);
        return
      }

      if (modal) {
        modal.close();
      }
      UC.utils.showPanel($('#overlayPanel'));
      UC.utils.disableScrolling();

      openEmbeddedUrl(
        claimUrl,
        function (event, eventData) {
          if ([DropboxSign.events.SIGN, DropboxSign.events.CANCEL, DropboxSign.events.SEND].includes(event)) {
            updateSignatureRequestAndReloadPage(documentId, event, eventData);
          }
        }
      );
    }
  );
}

export function cancelSignatureRequest(documentId) {
  UC.utils.showPanel($('#overlayPanel'));

  UC.net.post(
    '/signatures/user_document/{0}/cancel'.format(documentId),
    null,
    function () {
      UC.utils.hidePanel($('#overlayPanel'));

      location.reload();
    }
  );
}
