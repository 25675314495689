UC.components.WorkspaceNav = Backbone.View.extend({
  events: {
    'click .js-workspace-user' : 'updateWorkspaceUser'
  },

  updateWorkspaceUser: function (event) {
    var newWorkspaceUserId = event.target.dataset['userId']

    if (UC.workspaceUser && newWorkspaceUserId !== UC.workspaceUser.id) {
      var body = { workspace_user_id: newWorkspaceUserId };

      UC.net.post('/sessions/set_workspace_user', body, function (response) {
        location.href = response.next_url;
      });
    }
  }
})

$(function () {
  $workspaceNavbar = $('#workspace-user-navbar')

  if ($workspaceNavbar.length > 0) {
    $('#header').addClass('header-with-workspace-navbar')
    new UC.components.WorkspaceNav({el: $workspaceNavbar});
  }
});
