UC.views.ProposalsHire = Backbone.View.extend({

  initialize: function() {
    var paymentInfo;
      if($(".proposals .hire.v1").length > 0) {
        paymentInfo = new UC.components.PaymentInfoV1({
          el: $('body.proposals .hire.v1'),
          showDefaultButtonLoader: true
        });
      } else {
        paymentInfo = new UC.components.PaymentInfo({ el: $('body') });
      }
    this.components = {
      payment_info: paymentInfo,
      billingSummary: new UC.components.BillingSummary({ el: $('#billing-summary') })
    };

    UC.utils.sendNesEvent('start_opportunity_to_pay', {
      upfront_payment: !!UC.constants.upfrontPayment,
      attorney_id: UC.bid.userId
    });
  }
});
