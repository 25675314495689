import { GeoLocation } from '../../../components/geo_location'
import { GeoLocationPajWidjet } from '../../../components/geo_location_paj_widget'

UC.partials.LocationSearch = Backbone.View.extend({

  events: {
    'click #toggleZipCodePanel': 'toggleZipCodePanel',
    'click #toggleInternationalPanel': 'toggleInternationalPanel',
  },

  elements: {
    'internationalPanel' : null,
    'zipCodePanel' : null,
    'selectCountry' : null,
    'cityField' : null,
    'zipCodeField' : null,
    'locationType' : null,
    'newJobLocationRow' : null
  },

  routes: {
    validZipCode: '/account/valid_zip_code/'
  },

  initialize: function(options) {
    UC.utils.setSelectors(this.elements);
    this.jobLeadId = options.jobLeadId;

    this.renderPanel();
  },

  renderPanel: function() {
    if (this.elements.zipCodePanel.is(':visible')) {
      this.showZipCodePanel();

      if (this.$('.js-geo-location').length && this.$('#zipCodeField').val() === "") {
        new GeoLocationPajWidjet({
          el: this.$('.js-geo-location'),
          input: this.$('#zipCodeField'),
          triggeredLink: $('#nextButton')
        });
      }
    } else {
      this.showInternationalPanel();
    }
  },

  toggleZipCodePanel: function(e) {
    e.preventDefault();
    this.showZipCodePanel();

    UC.utils.recordPajEvent({
      eventName: 'us-address-shown',
      stageName: 'new_job',
      jobLeadId: this.jobLeadId
    });
  },

  showZipCodePanel: function () {
    UC.utils.hidePanel(this.elements.internationalPanel);
    UC.utils.showPanel(this.elements.zipCodePanel);
    this.elements.internationalPanel.find('input, select').prop('disabled', true);
    this.elements.zipCodePanel.find('input, select').prop('disabled', false);
    this.elements.locationType.val('zip');
  },

  toggleInternationalPanel: function(e) {
    e.preventDefault();
    this.showInternationalPanel();

    UC.utils.recordPajEvent({
      eventName: 'international-address-shown',
      stageName: 'new_job',
      jobLeadId: this.jobLeadId
    });
  },

  showInternationalPanel: function() {
    UC.utils.hidePanel(this.elements.zipCodePanel);
    UC.utils.showPanel(this.elements.internationalPanel);
    this.elements.internationalPanel.find('input, select').prop('disabled', false);
    this.elements.zipCodePanel.find('input, select').prop('disabled', true);
    this.elements.selectCountry.select2({width: '100%'});
    this.elements.locationType.val('international');
  },

  validate: function(dfd) {
    this.$el.find('.has-error').removeClass('has-error');

    var errors = [];
    var data = this.$el.serializeJSON();

    if (this.elements.locationType.val() === 'international') {

      if (!data.location.City) {
        errors.push({element: this.elements.cityField, text: 'Please enter a city.'});
      }

      if (!data.location.CountryCode || data.location.CountryCode === '##') {
        errors.push({element: this.elements.selectCountry, text: 'Please enter a country.'});
      }

    } else {

      if (!data.location.ZipCode) {
        UC.utils.hidePanel(this.elements.newJobLocationRow, {transition: 'fade'});
        errors.push({element: this.elements.zipCodeField, text: 'Please enter a valid zip code.'});
      } else if (UC.utils.validateZipCode(data.location.ZipCode)) {
        this.checkZipCodeValid(data, dfd);
        return; // return here so that lower dfd.resolve() is not called
      } else {
        UC.utils.hidePanel(this.elements.newJobLocationRow, {transition: 'fade'});
        errors.push({element: this.elements.zipCodeField, text: 'Please enter a valid zip code.'});
      }

    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      dfd.reject();
    } else {
      dfd.resolve(data);
    }
  },

  checkZipCodeValid: function (data, dfd) {
    var path = '{0}{1}'.format(this.routes.validZipCode, data.location.ZipCode);

    UC.net.get(path, {}, function() {
      new GeoLocation().storeUserChoice(data.location.ZipCode);
      dfd.resolve(data);
    }).fail(function(res) {
      res.handled = true;
      UC.utils.hidePanel(this.elements.newJobLocationRow, {transition: 'fade'});
      UC.utils.showValidationErrors(
        [
          {
            element: this.elements.zipCodeField,
            text: res.responseJSON ? res.responseJSON.message : 'Failed to validate the Zip Code'
          }
        ]
      );
      dfd.reject();
    }.bind(this));
  }

});
