jQuery.fn.extend({
  insertAtCaret: function(value){

    return this.each(function() {

      if (document.queryCommandSupported('insertText')) {
        this.focus();
        document.execCommand('insertText', false, value);
      } else if (document.selection) {
        //For browsers like Internet Explorer
        this.focus();
        var sel = document.selection.createRange();
        sel.text = value;
        this.focus();
      } else if (this.selectionStart || this.selectionStart == '0') {
        //For browsers like Firefox and Webkit based
        var startPos = this.selectionStart;
        var endPos = this.selectionEnd;
        var scrollTop = this.scrollTop;

        this.value = this.value.substring(0, startPos) + value + this.value.substring(endPos, this.value.length);
        this.focus();
        this.selectionStart = startPos + value.length;
        this.selectionEnd = startPos + value.length;
        this.scrollTop = scrollTop;
      } else {
        this.value += value;
        this.focus();
      }
    });
  }
});
