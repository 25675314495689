var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "              <p id=\"client-copy\">For the safety and privacy of our users, we don't allow the sharing of personal contact information until after an attorney has been hired. If you want to communicate with an attorney before hiring them, you can send them a message or schedule a call directly through UpCounsel.</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "              <p id=\"attorney-copy\">For the safety and privacy of our users, we don't allow the sharing of personal contact information before a client hires an attorney. If you want to contact a potential client before you have been hired, you can send them a message or schedule a call directly through UpCounsel.</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"row\">\n            <div class=\"col-sm-12\">\n              <div class=\"flex marg-b-10\">\n                <i class=\"fontello icon-cancel-circled text-danger-red fs-20\"></i>\n                <span class=\"text-bold pad-l-5\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"emails") || (depth0 != null ? lookupProperty(depth0,"emails") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emails","hash":{},"data":data,"loc":{"start":{"line":31,"column":48},"end":{"line":31,"column":60}}}) : helper)))
    + "</span>\n              </div>\n            </div>\n          </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"row\">\n            <div class=\"col-sm-12\">\n              <div class=\"flex marg-b-10\">\n                <i class=\"fontello icon-cancel-circled text-danger-red fs-20\"></i>\n                <span class=\"text-bold pad-l-5\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"phone_numbers") || (depth0 != null ? lookupProperty(depth0,"phone_numbers") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"phone_numbers","hash":{},"data":data,"loc":{"start":{"line":42,"column":48},"end":{"line":42,"column":67}}}) : helper)))
    + "</span>\n              </div>\n            </div>\n          </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"row\">\n            <div class=\"col-sm-12\">\n              <div class=\"flex marg-b-10\">\n                <i class=\"fontello icon-cancel-circled text-danger-red fs-20\"></i>\n                <span class=\"text-bold pad-l-5\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"linkedins") || (depth0 != null ? lookupProperty(depth0,"linkedins") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"linkedins","hash":{},"data":data,"loc":{"start":{"line":53,"column":48},"end":{"line":53,"column":63}}}) : helper)))
    + "</span>\n              </div>\n            </div>\n          </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"row\">\n            <div class=\"col-sm-12\">\n              <div class=\"flex marg-b-10\">\n                <i class=\"fontello icon-cancel-circled text-danger-red fs-20\"></i>\n                <span class=\"text-bold pad-l-5\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"urls") || (depth0 != null ? lookupProperty(depth0,"urls") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"urls","hash":{},"data":data,"loc":{"start":{"line":64,"column":48},"end":{"line":64,"column":58}}}) : helper)))
    + "</span>\n              </div>\n            </div>\n          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"modal fade\" id=\"modalCloseTheLoop\">\n\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n\n      <div class=\"modal-header\">\n        <h4 class=\"modal-title\">Please do not include personal contact information</h4>\n      </div>\n\n      <div class=\"modal-body pad-b-20 clearfix\">\n\n        <div class=\"row\">\n          <div class=\"col-sm-12\">\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"show_client") || (depth0 != null ? lookupProperty(depth0,"show_client") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"show_client","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":17,"column":28}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"show_client")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_client") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "\n          </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"emails") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"phone_numbers") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkedins") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":57,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"urls") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":68,"column":15}}})) != null ? stack1 : "")
    + "\n      </div>\n\n      <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-primary\" id=\"btnCloseClosTheLoop\">OK, I understand</button>\n      </div>\n\n    </div>\n  </div>\n\n</div>\n";
},"useData":true});