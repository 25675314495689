!function() {

  UC.partials.DashboardIndexForAtlas = Backbone.View.extend({

    events: {
      'slide.bs.carousel #atlas-intro': 'toggleNextVisibility',
      'submit #new_message': 'sendMessage'
    },

    initialize: function() {
      this.$form = this.$('#new_message');
      this.loader = this.$('#btnSendMessage').buttonLoader();
      this.attachments = this.$('#message_Message').attachmentTextarea({
        jobId: this.$('#message_JobId').val()
      });
    },

    toggleNextVisibility: function(e) {
      var $next = $(e.relatedTarget);
      var hide = $next.is(':last-child');

      this.$('#atlas-intro-next').toggleClass('hide', hide);

      UC.utils.recordKissEvent('Atlas Dashboard - Intro Step Shown', {
        step: $next.index() + 1
      });
    },

    validateMessage: function() {
      var message = this.$form.serializeJSON().message;
      var errors = [];

      UC.utils.clearValidationErrors('form-group');

      if (!message.Message) {
        errors.push({
          element: this.$('#message_Message'),
          text: 'Please include a message to your attorney.'
        });
      }

      UC.utils.showValidationErrors(errors, { className: 'form-group' });

      return errors.length <= 0;
    },

    sendMessage: function(e) {
      e.preventDefault();

      if (this.validateMessage()) {

        this.loader.showLoader();

        if (this.attachments.uploader.uploading) {
          $.when(this.attachments.uploader.uploading).
            done(this.doTheActualSendPleaseKThx.bind(this));
        } else {
          this.doTheActualSendPleaseKThx();
        }
      }
    },

    handleSuccessfulMessageSending: function() {
      var $sentMsgPanel = this.$('#atlas-thanks');

      this.$form.remove();
      $sentMsgPanel.removeClass('hide');
    },

    doTheActualSendPleaseKThx: function() {
      var path = this.$form.attr('action');
      var data = this.$form.serializeJSON();

      data.AttachmentIds = this.attachments.getDocumentIds();

      UC.net.post(path, data, function() {
        this.handleSuccessfulMessageSending();
      }.bind(this)).fail(this.loader.hideLoader.bind(this));

      UC.utils.recordKissEvent('Atlas Dashboard - Message Sent');
    }
  });
}();
