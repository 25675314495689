UC.views.InvoicesNewForJob = Backbone.View.extend({

  events: {
    'click #aNonPaymentInvoice': 'openNoPaymentRequiredModal'
  },

  initialize: function() {

    this.form_details = new UC.partials.InvoicesFormDetails({
      el: this.el,
      jobId: UC.job.id,
      showTimesheetEntryImport: true
    });

    this.form_details.render(UC.constants.attorney_client);

    if (!UC.constants.hasPayout) {
      this.$el.find('#modalNoBankAccount').modal({ keyboard: false, backdrop: 'static' });
    } else if (UC.constants.unverifiedAccount) {
      this.$el.find('#modalUnverifiedAccount').modal({ keyboard: false, backdrop: 'static' });
    }

    $('.jn-invoices').addClass('active');

    if ($('.manage-job-cog').length > 0) {
      new UC.views.JobManageCog({
        el: '.manage-job-cog'
      });
    }
  },

  openNoPaymentRequiredModal: function(e) {
    e.preventDefault();

    new UC.partials.InvoicesModalNoPaymentRequired({
      el: '#modal-no-payment-required'
    }).render();
  }
});
