var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h4 class=\"modal-title inline-block\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"modalTitle") || (depth0 != null ? lookupProperty(depth0,"modalTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"modalTitle","hash":{},"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":59}}}) : helper)))
    + "</h4>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"modal-subtitle "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"modalSubTitle") : depth0)) != null ? lookupProperty(stack1,"className") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"modalSubTitle") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"modal-footer white-BG\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"modalFooter") : depth0)) != null ? lookupProperty(stack1,"noText") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":10},"end":{"line":31,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"modalFooter") : depth0)) != null ? lookupProperty(stack1,"yesText") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":10},"end":{"line":41,"column":17}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button\n              class=\"btn "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"modalFooter") : depth0)) != null ? lookupProperty(stack1,"noTextClass") : stack1), depth0))
    + "\"\n              id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"modalFooter") : depth0)) != null ? lookupProperty(stack1,"noTextId") : stack1), depth0))
    + "\"\n              data-dismiss=\"modal\"\n            >\n              "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"modalFooter") : depth0)) != null ? lookupProperty(stack1,"noText") : stack1), depth0))
    + "\n            </button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button\n              class=\"btn "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"modalFooter") : depth0)) != null ? lookupProperty(stack1,"yesTextClass") : stack1), depth0))
    + "\"\n              id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"modalFooter") : depth0)) != null ? lookupProperty(stack1,"yesTextId") : stack1), depth0))
    + "\"\n              data-dismiss=\"modal\"\n            >\n              "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"modalFooter") : depth0)) != null ? lookupProperty(stack1,"yesText") : stack1), depth0))
    + "\n            </button>\n          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n\n    <div class=\"modal-header "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"customHeaderClass") || (depth0 != null ? lookupProperty(depth0,"customHeaderClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"customHeaderClass","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":50}}}) : helper)))
    + "\">\n\n      <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modalTitle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":10,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modalSubTitle") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":14,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n\n      <div class=\"modal-body\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"modalContent") || (depth0 != null ? lookupProperty(depth0,"modalContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modalContent","hash":{},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":26}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modalFooter") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":42,"column":13}}})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"useData":true});