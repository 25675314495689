UC = UC || {};
UC.components = UC.components || {};

UC.components.alertModal = Backbone.View.extend({

  tagName: 'div',

  className: 'modal',

  events: {
    "hidden.bs.modal" : "deleteElement"
  },

  template: UC.views.resolveHandlebarsTemplate("components/alert_modal"),

  initialize: function(opts) {
    opts = opts || {};
    this.title = opts.title;
    this.message = opts.message;
    this.confirmAlertText = opts.confirmAlertText || 'Okay';
    this.render();
  },

  render: function() {
    this.$el.html(this.template(this));
    this.$el.modal();
  },

  deleteElement: function() {
    this.$el.remove();
  }

});
