!function() {

  UC.modals.AttorneyProfileEditPastClients = Backbone.View.extend({

    id: 'modalEditPastClients',

    className: 'modal hide',

    events: {
      'click #btnSaveEditPastClients': 'save',
      'click #showEditPastClients': 'showEditPastClients',
      'click .past-client-remove': 'remove'
    },

    elements: {
      'pastClientsContent' : null
    },

    render: function() {

      UC.net.get(UC.constants.edit_past_clients_path, {}, function(result) {
        this.setElement(result);
        UC.utils.setSelectors(this.elements);
        this.delegateEvents();
        this.$el.find('#formEditPastClients').sortable({
          items: '.pf-past-client',
          axis: 'y',
          stop: this.setOrder.bind(this)
        });
        this.loader = this.$el.find('#btnSaveEditPastClients').buttonLoader();
        this.$el.modal();
      }.bind(this));
    },

    setOrder: function() {
      this.$el.find('.pf-past-client').each(function(index) {
        $(this).find('.order').val(index);
      });
    },

    save: function(e) {
      e.preventDefault();

      var data = this.$el.find('#formEditPastClients').serializeJSON();

      data['id'] = UC.user.id;

      this.loader.showLoader();

      UC.net.put(UC.constants.update_past_clients_path, data, function(html) {
        this.elements.pastClientsContent.html(html);
        this.$el.modal('hide');
        $('.past-client').tooltip({ container: 'body' });
      }.bind(this)).always(function() {
        this.loader.hideLoader();
      }.bind(this));
    },

    remove: function(e) {
      e.preventDefault();
      var clientId = $(e.target).closest('.pf-past-client').data('id'),
          result = confirm("Are you sure you want to delete this Past Client?");

      if (result) {
        var data = { clientId: clientId, id: UC.user.id };

        UC.net.post(UC.constants.delete_past_client_path, data, function(html) {
          this.elements.pastClientsContent.html(html);
          $('.past-client').tooltip({ container: 'body' });
        }.bind(this));

        $(e.target).closest('.pf-past-client').remove();
      }
    },

    showEditPastClients: function(e) {
      e.preventDefault();
      this.$el.modal('hide');
      $('#btnAddPastClients').click();
    }
  });
}();
