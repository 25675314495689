var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"pad-y-15 typing-dots\">\n            <span class=\"typing-dot\"></span>\n            <span class=\"typing-dot\"></span>\n            <span class=\"typing-dot\"></span>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"chat-item-content-message\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"messageText") || (depth0 != null ? lookupProperty(depth0,"messageText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"messageText","hash":{},"data":data,"loc":{"start":{"line":21,"column":47},"end":{"line":21,"column":64}}}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row "
    + alias4(((helper = (helper = lookupProperty(helpers,"messageOriginator") || (depth0 != null ? lookupProperty(depth0,"messageOriginator") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageOriginator","hash":{},"data":data,"loc":{"start":{"line":1,"column":16},"end":{"line":1,"column":37}}}) : helper)))
    + " marg-b-20\">\n  <div class=\"chat-item-profile col-sm-2\">\n    <span class=\"profile-image-container\">\n        <img\n            class=\"user-img attorney-profile-image js-attorney-profile-image\"\n            onerror=\"this.onerror=null;this.src='https://sitecdn.upcounsel.com/images/noProfile.png'\"\n            src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"userImageUrl") || (depth0 != null ? lookupProperty(depth0,"userImageUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userImageUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":17},"end":{"line":7,"column":33}}}) : helper)))
    + "\"\n            >\n        </span>\n    <div class=\"chat-profile-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"senderName") || (depth0 != null ? lookupProperty(depth0,"senderName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"senderName","hash":{},"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":10,"column":49}}}) : helper)))
    + "</div>\n  </div>\n\n  <div class=\"chat-item-content "
    + alias4(((helper = (helper = lookupProperty(helpers,"messageOriginator") || (depth0 != null ? lookupProperty(depth0,"messageOriginator") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageOriginator","hash":{},"data":data,"loc":{"start":{"line":13,"column":32},"end":{"line":13,"column":53}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"typingAnimation") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"useData":true});