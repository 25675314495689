UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.TimesheetDateFiltersView = Backbone.View.extend({

  tagName: 'span',

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/timesheet_date_filters"),

  events: {
    'change input' : 'setDateFilterOnCollection'
  },

  render: function() {
    this.$el.html(this.template(this));
    this.$('.datepicker').datepicker();
  },

  setDateFilterOnCollection: function(e) {
    var $dateInput = $(e.currentTarget);
    var filterType = $dateInput.data('type');
    var dateString = $dateInput.val();
    var momentDate;

    if (dateString) {
      momentDate = moment(dateString, UC.constants.momentDateFormats, true);

      if (!this.dateValid(momentDate, filterType)) return;
    }

    switch (filterType) {
      case 'start-date':
        this.collection.startDateFilter = momentDate;
        break;
      case 'end-date':
        this.collection.endDateFilter = momentDate;
        break;
    }

    this.collection.trigger('dateFilterChange');
  },

  dateValid: function(momentDate, filterType) {

    if (!momentDate.isValid()) {
      UC.utils.showNotification("You've entered an invalid date", UC.constants.results.failure);
      this.clearDateInput(filterType);
      return false;
    }

    if (filterType === 'start-date' && this.collection.endDateFilter && momentDate > this.collection.endDateFilter) {
      UC.utils.showNotification("Your start date filter is set past your end date filter", UC.constants.results.failure);
      this.clearDateInput(filterType);
      return false;
    }

    if (filterType === 'end-date' && this.collection.startDateFilter && momentDate < this.collection.startDateFilter) {
      UC.utils.showNotification("Your end date filter is set before your start date filter", UC.constants.results.failure);
      this.clearDateInput(filterType);
      return false;
    }

    return true;
  },

  clearDateInput: function(filterType) {
    $.datepicker._clearDate(this.$('input[data-type="' + filterType + '"]'));
  }

});
