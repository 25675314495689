UC.partials.V7PAJ = UC.partials.V4PAJ.extend({
  ai_formStage: function(_eventParams) {
    this.activeSteps.push(new UC.partials.AiForm({
      el: '#v7AiForm',
      nextButtonCallback: this.nextButtonClicked.bind(this)
    }));
  },

  ai_live_chatStage: function(_eventParams) {
    this.activeSteps.push(new UC.partials.AiLiveChat({
      el: this.$el,
      nextButtonCallback: this.nextButtonClicked.bind(this)
    }));
  },
});
