import 'jquery-jcrop'

UC.photoUploaderWidget = function(options) {
  this.s3UploadPath = options.s3UploadPath;
  this.$openFilePickerBtn = $(options.openFilePickerBtnSelector);
  this.openFilePickerBtnLoader = this.$openFilePickerBtn.buttonLoader();
  this.ucStoreFileInfoPath = options.ucStoreFileInfoPath;
  this.ucStoreCroppedPhotoUrl = options.ucStoreCroppedPhotoUrl;
  this.$uploadedImagePreviewTag = $(options.uploadedImagePreviewSelector);
  this.$currentPhotoPanel = $(options.currentPhotoPanelSelector);
  this.$newPhotoPanel = $(options.newPhotoPanelSelector);
  this.$savePhotoBtn = $(options.savePhotoBtnSelector);
  this.savePhotoBtnLoader = this.$savePhotoBtn.buttonLoader();
  this.currentFilepickerImageObject = null;
  this.userId = options.userId;
  this.imageCoords = null;
  this.radminUpdate = options.radminUpdate;
  this.maxSize = options.maxSize;
  this.maxFiles = options.maxSize;
  this.fileStackApiKey = options.fileStackApiKey;
};

UC.photoUploaderWidget.prototype = {
  init: function() {
    this.client = filestack.init(this.fileStackApiKey);
    this.setListeners();
    this.minImageDimension = 200;
  },

  setListeners: function() {
    this.$openFilePickerBtn.on('click', this.openFilepicker.bind(this));
    this.$savePhotoBtn.on('click', this.saveCroppedImage.bind(this));
  },

  openFilepicker: function() {
    this.client.picker({
      fromSources: ['local_file_system', 'facebook', 'box', 'dropbox', 'googledrive', 'onedrive', 'gmail', 'instagram'],
      accept: ['image/*'],
      maxSize: parseInt(this.maxSize),
      maxFiles: 1,
      uploadInBackground: false,
      storeTo: {
        location: 's3',
          path: this.s3UploadPath,
          access: 'public'
      },
      displayMode: 'overlay',
      onUploadDone: function (res) {
        this.onFileUploadViaFilepicker(res.filesUploaded);
      }.bind(this)
    }).open();
  },

  getMeta: function(url) {
    return new Promise(function(resolve, reject) {
      var img = new Image();
      img.onload = function(){
          resolve({
            width: this.width,
            height: this.height
          })
      };
      img.onerror = function(){
          resolve(null)
      };
      img.src = url;
    });
  },

  onFileUploadViaFilepicker: function(inkblobs) {
    var messageError = 'Could not get the file info, please try again'

    if (!inkblobs || !inkblobs[0]) {
      UC.utils.showNotification(messageError, UC.constants.results.failure);
      return
    }

    this.getMeta(inkblobs[0].url).then(function(dimensions) {
      if (!dimensions) {
        UC.utils.showNotification(messageError, UC.constants.results.failure);
        return
      }

      if (dimensions.height < this.minImageDimension || dimensions.width < this.minImageDimension) {
        UC.utils.showNotification('The photo dimensions are smaller than ' + this.minImageDimension + 'px by width or height.', UC.constants.results.failure);
        return
      }

      this.openFilePickerBtnLoader.showLoader();

      var filepickerFile = inkblobs[0];

      var originalData = {
        path: filepickerFile.key,
        size: filepickerFile.size,
        type: filepickerFile.mimetype,
        status: 'temp'
      };

      this.saveFileInfoToUC(originalData, $.noop);

      this.resizeFilepickerFile(filepickerFile);
    }.bind(this))
  },

  saveFileInfoToUC: function(data, callback) {
    return UC.net.post(this.ucStoreFileInfoPath, {fileData: data, userId: this.userId, radminUpdate: this.radminUpdate}, callback);
  },

  resizeFilepickerFile: function(filepickerFileObject) {
    const resizedUrl = this.client.transform(
        filepickerFileObject.url,
        {
          resize: {width: 400, fit: 'max'}
        }
    );
    this.client.storeURL(resizedUrl, {
      location: 's3',
      path: this.s3UploadPath,
      access: 'public'
    }).then(function (currentFilepickerImageObject) {
      this.currentFilepickerImageObject = currentFilepickerImageObject;
      this.$uploadedImagePreviewTag.attr('src', currentFilepickerImageObject.url);
      this.saveFileInfoToUC(
          {
            path: currentFilepickerImageObject.key,
            size: currentFilepickerImageObject.size,
            type: currentFilepickerImageObject.mimetype,
            status: 'temp'
          },
          this.showResizedFilepickerImageAndCrop.bind(this)
      ).always(this.openFilePickerBtnLoader.hideLoader);
    }.bind(this));
  },

  showResizedFilepickerImageAndCrop: function() {
    UC.utils.hidePanel(this.$currentPhotoPanel);
    UC.utils.showPanel(this.$newPhotoPanel);

    var imageWidth = this.minImageDimension,
        imageHeight = this.minImageDimension,
        crop = (Math.min(imageWidth, imageHeight)) / 2,
        x = (imageWidth / 2) - (crop / 2),
        y = (imageHeight / 2) - (crop / 2),
        x2 = x + crop,
        y2 = y + crop;

    this.$uploadedImagePreviewTag.Jcrop({
      onChange: this.updateImageCoords.bind(this),
      onSelect: this.updateImageCoords.bind(this),
      aspectRatio: 1,
      setSelect: [ x, y, x2, y2 ],
      minSize: [imageWidth, imageHeight]
    });
  },

  updateImageCoords: function(coords) {
    if (parseInt(coords.w) > 0) {
      this.imageCoords = {
        left: coords.x,
        top: coords.y,
        bottom: coords.y2,
        right: coords.x2
      };
    }
  },

  saveCroppedImage: function() {
    if (this.currentFilepickerImageObject && this.imageCoords) {
      this.savePhotoBtnLoader.showLoader();

      const croppedUrl = this.client.transform(
          this.$uploadedImagePreviewTag.attr('src'),
          {
            crop: {
              dim: [
                this.imageCoords.left,
                this.imageCoords.top,
                this.imageCoords.right - this.imageCoords.left,
                this.imageCoords.bottom - this.imageCoords.top
              ],
            },
          }
      );
      this.client.storeURL(croppedUrl, {
        location: 's3',
        path: this.s3UploadPath + '{0}{1}'.format(
            new Date().getTime(),
            this.currentFilepickerImageObject.filename.replace(/%20/g, '-')
        ),
        access: 'public'
      }).then(function (currentFilepickerImageObject) {
        this.currentFilepickerImageObject = currentFilepickerImageObject;
        this.saveFileInfoToUC(
            {
              path: currentFilepickerImageObject.key,
              size: currentFilepickerImageObject.size,
              type: currentFilepickerImageObject.mimetype,
              status: 'none'
            },
            this.saveCroppedImageUrlOnUCInfoSave.bind(this)
        ).fail(this.savePhotoBtnLoader.hideLoader);
      }.bind(this));
    }
  },

  saveCroppedImageUrlOnUCInfoSave: function() {
    var data = {
      photo: {
        path: this.currentFilepickerImageObject.key
      },
      userId: this.userId,
      radminUpdate: this.radminUpdate
    };

    UC.net.post(this.ucStoreCroppedPhotoUrl, data, function(response) { // TODO: FIGURE OUT DYNAMIC PATH
      if (location.hash === '#profile') {
        location.href = '/attorney/profile/' + this.userId;
      } else if (location.hash === '#checklist') {
        location.href = '/dashboard';
      } else {
        location.href = response.next_url;
      }
    }.bind(this)).fail(function() {
      this.savePhotoBtnLoader.hideLoader();
    }.bind(this));
  }
};
