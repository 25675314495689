import { validateOTPCodeFormat } from './functions'

UC.views.MultiFactorAuthConfigure = Backbone.View.extend({
  events: {
    'click #show-manual-code' : 'showCode',
    'submit #mfa-configuration-form': 'onSubmit',
    'keyup #verification-code': 'onVerificationCodeKeyUp',
  },

  initialize: function() {
    this.$verificationCode = this.$el.find('input#verification-code');
    this.$secretCode = this.$el.find('input#secret-code');
    this.btnLoader = this.$el.find('#enable-mfa').buttonLoader();

    new UC.components.InputWithCopyButton({inputId: 'secret-code'})
  },

  showCode: function(e) {
    e.preventDefault();

    $('#show-manual-code').addClass('hidden');
    $('#manual-code-block').removeClass('hidden');
  },

  onSubmit: function(e) {
    e.preventDefault();

    if (validateOTPCodeFormat(this.$verificationCode)) {
      this.btnLoader.showLoader();
      const self = this;

      UC.net.post(
          '/account/mfa/enable',
          {
            secret: self.$secretCode.val(),
            code: self.$verificationCode.val()
          },
          function (response) {
            self.btnLoader.hideLoader();
            window.location = response.url || '/account/edit';
          }
      ).fail(function () {
        self.btnLoader.hideLoader();
        self.$verificationCode.val('');
      });
    }
  },

  onVerificationCodeKeyUp: function(e) {
    if (e.keyCode === 13) {
      this.onSubmit(e);
    }
  },
});
