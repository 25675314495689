import { createConsumer } from "@rails/actioncable"
import TextLengthCounter from '../../../components/text_length_counter'
import { GeoLocation } from '../../../components/geo_location'

const DURATION_RANGES = {
  ONE_WEEK: 7,
  TWO_WEEKS: 14,
  ONE_MONTH: 30,
  THREE_MONTHS: 90,
  SIX_MONTHS: 180,
  ONE_YEAR: 365,
  MORE_THAN_ONE_YEAR: 999
};

const DURATION_DROP_DOWN_VALUES = {
  ONE_WEEK: 1,
  TWO_WEEKS: 2,
  ONE_MONTH: 3,
  THREE_MONTHS: 4,
  SIX_MONTHS: 5,
  ONE_YEAR: 6,
  MORE_THAN_ONE_YEAR: 7
};

const DURATION_DISPLAY_TEXT = {
  [DURATION_DROP_DOWN_VALUES.ONE_WEEK]: "< 1 week",
  [DURATION_DROP_DOWN_VALUES.TWO_WEEKS]: "< 2 weeks",
  [DURATION_DROP_DOWN_VALUES.ONE_MONTH]: "< 1 month",
  [DURATION_DROP_DOWN_VALUES.THREE_MONTHS]: "< 3 months",
  [DURATION_DROP_DOWN_VALUES.SIX_MONTHS]: "< 6 months",
  [DURATION_DROP_DOWN_VALUES.ONE_YEAR]: "< 1 year",
  [DURATION_DROP_DOWN_VALUES.MORE_THAN_ONE_YEAR]: "> 1 year"
};

UC.partials.LiveChat = Backbone.View.extend({
  liveChatMessageTemplate: UC.views.resolveHandlebarsTemplate('public/jobs/live_chat_message'),

  events: {
    'click #preview-summary-link' : 'previewSummaryLinkClicked',
    'click #hide-summary-link' : 'hideSummaryLinkClicked',
    'click #send-message-button' : 'sendMessageButtonClicked',
    'keypress #chat-message-input': 'handleEnterPress',
    'change #job-duration-select': 'onDurationChange',
    'change #job-zip-code': 'onZipCodeChanged',
    'input #chat-message-input': 'autoResizeInputTextArea',
  },

  routes: {
    validZipCode: '/account/valid_zip_code/'
  },

  initialize: function() {
    const startMessage = "Hi there, I'm Rachel, your legal e-assistant. I will assist you in collecting your job details and getting our most qualified attorneys to help you. Please share a basic description of your legal needs.";

    this.pajContainer = $('#paj-container');
    this.pajContainer.css({
      'transition': 'all 0.1s ease',
      'width': '100%'
    });
    this.pajContainer.addClass('live-chat-container col-lg-12 col-md-12 col-sm-12');

    this.headerRow = $('#header-row');
    this.headerRow.css({
      'position': 'relative'
    });

    const $affixPanels = $('.header-row .items');
    const affixAvailable = typeof $affixPanels.affix === 'function';
    if (affixAvailable) {
      const $chatPanel = $('.chat-bot-panel:visible');
      $affixPanels.css('width', $chatPanel.width());

      $affixPanels.affix({
        offset: {
          top: $chatPanel.offset().top,
        }
      });

      $(window).resize(function() {
        $affixPanels.css('width', $('.chat-bot-panel:visible').width());

        if (affixAvailable) {
          $affixPanels.affix('checkPosition');
        }

        if (!UC.browser.isMobile() && $('#job-summary-panel:visible').length) {
          this.hideSummaryLinkClicked()
        }
      }.bind(this));
    }

    this.appendMessage('typing');
    this.toggleSendMessageControlsAvailability(false);

    this.consumer = createConsumer();
    this.connected = false;
    this.jobSummary = null;
    this.jobLocation = null;
    this.chatBotMessagesQueue = [];
    this.firstMessageData = {
      message: startMessage,
      from: 'AI',
      summary: {},
      display_chat_message: true
    };

    this.durationRanges = DURATION_RANGES;
    this.durationDropDownValues = DURATION_DROP_DOWN_VALUES;
    this.durationDisplayText = DURATION_DISPLAY_TEXT;

    this.chatChannel = this.consumer.subscriptions.create({
      channel: "JobChannel",
      lead_id: UC.constants.jobLeadId,
    }, {
      connected: () => {
        console.log("Connected to the JobChannel");
        if (!this.connected) {
          // to avoid greeting messages when re-connected
          this.connected = true;

          // Send first message from AI manually
          this.chatBotMessagesQueue.push(this.firstMessageData);
          this.popChatBotMessage();
          $('#chat-message-input').focus();
        }
      },
      received: (data) => {
        // Handle received data from AI
        if (data.message && data.display_chat_message) {
          this.chatBotMessagesQueue.push(data);
          this.popChatBotMessage();
        }
        if (data.summary && !data.display_chat_message) {
          this.updateSidebar(data.summary);
        }
      },
      disconnected: () => {
        // Add call to disconnected when save/next button is clicked and job_lead stage is updated to closed
        // Handle disconnection
      },

      sendMessage: function(message) {
        this.perform('receive', { message: message, assistant_type: 'v6' });
      }
    });

    this.updateQualityScore(0);

    this.descriptionLengthCounter = new TextLengthCounter({
      el: this.$el,
      errorContainer: '.form-group',
      textarea: '#job-description',
      counter: '.js-description-length'
    })

    this.$zipCode = $('#job-zip-code');

    // To show the summary from the intiialization point onwards
    this.showSummary();
  },

  onChatBotMessageTyped: function() {
    this.chatBotIsTyping = false;
    if (this.chatBotMessagesQueue.length == 0) {
      this.toggleSendMessageControlsAvailability(true);
    } else {
      this.popChatBotMessage();
    }
  },

  popChatBotMessage: function() {
    if (this.chatBotIsTyping || this.chatBotMessagesQueue.length == 0) {
      return;
    }
    this.chatBotIsTyping = true;
    const messageData = this.chatBotMessagesQueue.shift();
    this.appendMessage(messageData.message, messageData.from, true)
        .then(this.onChatBotMessageTyped.bind(this))
        .catch(this.onChatBotMessageTyped.bind(this));
  },

  appendMessage: function(message, from='AI', streamingEffect=false) {
    const chatConversation = $('.chat-bot-conversation');
    const lastMessage = chatConversation.find('.row:last-child');

    lastMessage.css('padding-bottom', '0');

    if (Array.isArray(message)) {
      message = message.join(); // OpenAI can send more than one message as a reply
    }
    if (message.match(/(<([^>]+)>)/i)) {
      streamingEffect = false;
    }

    const aiMessage = from.toLowerCase() === 'ai';
    const messageOriginator = aiMessage ? 'recipient' : 'sender'
    const senderName = aiMessage ? 'Rachel' : 'You'
    const imageUrl = aiMessage ? UC.constants.chatBotProfileUrl : UC.constants.userProfileUrl

    // remove 'typing' message
    this.$el.find('.typing-dots').closest('.row').remove();

    const $message = $(this.liveChatMessageTemplate({
      messageOriginator: messageOriginator,
      senderName: senderName,
      messageText: streamingEffect ? message.charAt(0) : message,
      userImageUrl: imageUrl,
      typingAnimation: message == 'typing'
    }));

    return new Promise(function(resolve, reject) {
      try {
        $('.chat-bot-conversation').append($message);
        if (streamingEffect) {
          this.typeTextMessage(message, $message.find('.chat-item-content-message')[0], 1, 0, resolve);
          this.autoResizeInputTextArea();
        } else {
          resolve();
          this.scrollChatToEnd();
        }
      } catch(e) {
        console.warn(e);
        reject();
      }
    }.bind(this));
  },

  typeTextMessage: function(message, streamingTextElement, currentIndex, lastHeight, resolve) {
    if (currentIndex >= message.length) {
      resolve(message);
      return;
    }

    let idx = message.indexOf(' ', currentIndex);
    if (idx < 0) {
      idx = message.length - 1;
    }

    streamingTextElement.textContent += message.substring(currentIndex, idx + 1);
    currentIndex = idx;
    const height = streamingTextElement.getBoundingClientRect().height;
    if (lastHeight == 0 || height > lastHeight) {
      this.scrollChatToEnd();
    }
    lastHeight = height;
    setTimeout(function() {
      this.typeTextMessage(message, streamingTextElement, currentIndex + 1, lastHeight, resolve);
    }.bind(this), 50); // Adjust the typing speed by changing the delay (in milliseconds)
  },

  autoResizeInputTextArea: function() {
    const textarea = $('#chat-message-input');
    const chatConversation = $('.chat-bot-conversation');
    const lastMessage = chatConversation.find('.row:last-child');

    // To calculate the actual content height
    textarea.css('height', 'auto');

    // 196px is the maximum height before scrolling starts
    let newHeight = Math.min(textarea.prop('scrollHeight'), 196);

    // Set the new height based on content size or the max height allowed
    textarea.css('height', `${newHeight}px`);

    // Calculate and adjust bottom padding based on the new height
    const minHeight = 28 + 10;  // single line height + 10 to adjust for padding
    const currentHeightIncrease = newHeight - minHeight;
    const maximumHeightIncrease = 196 - minHeight;
    let marginBottom = 0;
    if (currentHeightIncrease > 0) {
        marginBottom = (currentHeightIncrease / maximumHeightIncrease) * 125; // Scale padding up to 125px
    }

    // Apply calculated padding to the last message to ensure spacing
    lastMessage.css('padding-bottom', `${marginBottom}px`);

    // Enable scrolling if textarea is more than one line
    if (newHeight >= minHeight) {
        textarea.css('overflow-y', 'auto');
    }

    if (newHeight === minHeight) {
        textarea.css('overflow-y', 'hidden');
    }

    this.scrollChatToEnd();
  },

  toggleSendMessageControlsAvailability: function(available) {
    $('.chat-bot-text-area').find('button').prop('disabled', available ? null : 'disabled')
  },

  sendUserMessage: function(message) {
    this.appendMessage(message, 'User');
    this.appendMessage('typing')
    this.chatChannel.sendMessage(message);
  },

  sendMessageButtonClicked: function(e) {
    e.preventDefault();
    const $input = $('#chat-message-input');
    if (!$input.val().length) {
      return;
    }
    this.sendUserMessage($input.val().trim());
    $input.val('');
    this.autoResizeInputTextArea();
    this.scrollChatToEnd();
    this.toggleSendMessageControlsAvailability(false);
  },

  handleEnterPress: function(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (!$('#send-message-button').prop('disabled')) {
        this.sendMessageButtonClicked(event);
        this.$('#send-message-button').click();
      }
    }
  },

  valueDefined: function(value) {
    return value &&
        value.toLowerCase() !== "unspecified" &&
        value.toLowerCase() !== "undefined" &&
        value.toLowerCase() !== "unprovided" &&
        value.length > 0;
  },

  daysToDurationDropDownValue: function(days) {
    days = parseInt(days, 10);
    if (days < this.durationRanges.ONE_WEEK) return this.durationDropDownValues.ONE_WEEK;
    if (days < this.durationRanges.TWO_WEEKS) return this.durationDropDownValues.TWO_WEEKS;
    if (days < this.durationRanges.ONE_MONTH) return this.durationDropDownValues.ONE_MONTH;
    if (days < this.durationRanges.THREE_MONTHS) return this.durationDropDownValues.THREE_MONTHS;
    if (days < this.durationRanges.SIX_MONTHS) return this.durationDropDownValues.SIX_MONTHS;
    if (days < this.durationRanges.ONE_YEAR) return this.durationDropDownValues.ONE_YEAR;
    return this.durationDropDownValues.MORE_THAN_ONE_YEAR;
},

  onDurationChange: function(event) {
    var value = $(event.currentTarget).val();
    var displayText = this.durationDisplayText[value];

    if (this.jobSummary) {
        this.jobSummary.timeline_text = displayText;
    }
  },

  updateSidebar: function(summary) {
    this.jobSummary = summary;

    if (summary.score) {
      this.updateQualityScore(summary.score);
    }

    if (summary.progress) {
      $('#job-progress').css('width', summary.progress + '%');
      $('#job-progress-value').html(summary.progress + '%');
    }

    if (summary.progress > 40 && summary.progress < 59) {
      $('#job-progress-label').html('Few more details needed')
    } else if (summary.progress > 59 && summary.progress < 65) {
      $('#job-progress-label').html('Halfway there')
    } else if (summary.progress > 66 && summary.progress < 89) {
      $('#job-progress-label').html('Nearly there')
    } else if (summary.progress > 92) {
      $('#job-progress-label').html('Preview & Post a Job')
    }

    if (this.valueDefined(summary.zip_code) && this.jobLocation != summary.zip_code) {
      this.jobLocation = summary.zip_code;
      const validationPath = '{0}{1}'.format(this.routes.validZipCode, summary.zip_code);
      const validationParams = {job_lead_id: UC.constants.jobLeadId};
      if (this.valueDefined(summary.legal_subject)) {
        validationParams.legal_subject = summary.legal_subject;
      }

      UC.net.get(validationPath, validationParams, function(res) {
        this.$zipCode.val(summary.zip_code).parent().removeClass('hidden');

        $('#job-location').text(`${res.zip.CityName}, ${res.zip.StateAbbr}`).removeClass('hidden');
        this.$zipCode.closest('.has-error').removeClass('has-error');
        if (res.suggested_attorneys) {
          $('.row.face-piles').replaceWith(res.suggested_attorneys);
        }
      }.bind(this)).fail(function(res) {
        res.handled = true;
        this.$zipCode.val(summary.zip_code).parent().removeClass('hidden');
        UC.utils.showValidationErrors(
          [
            {
              element: this.$zipCode,
              text: res.responseJSON ? res.responseJSON.message : 'Failed to validate the Zip Code'
            }
          ], { className: "form-group" }
        );
      }.bind(this));
    }

    if (this.valueDefined(summary.title)) {
      $('#job-title').html(summary.title);
    } else if (this.valueDefined(summary.legal_subject)) {
      $('#job-title').html(summary.legal_subject);
    }
    if (this.valueDefined(summary.description)) {
      $('#job-description').html(summary.description);
      $('#job-description').val(summary.description);
      this.descriptionLengthCounter.update();
      this.activateChatBotSummary();
    }

    if (this.valueDefined(summary.timeline) && parseInt(summary.timeline) > 0) {
      const timelineValue = this.daysToDurationDropDownValue(parseInt(summary.timeline));
      $('#job-duration-select').val(timelineValue).parent().removeClass('hidden');

      this.onDurationChange({currentTarget: $('#job-duration-select')});
    }

    if (this.valueDefined(summary.legal_area)) {
      $('#job-legal-area').html(summary.legal_area).removeClass('hidden');
    }
    if (this.valueDefined(summary.legal_subject)) {
      $('#job-legal-subject').html(summary.legal_subject).removeClass('hidden');
    }
    if (this.jobSummaryAvailable()) {
      this.showSummary();
    }
  },

  jobSummaryAvailable: function() {
    return this.jobSummary != null &&
      this.valueDefined(this.jobSummary.description) &&
        this.valueDefined(this.jobSummary.title) &&
        this.valueDefined(this.jobSummary.zip_code);
  },

  previewSummaryLinkClicked: function(e) {
    e.preventDefault();

    $('#job-summary-panel').removeClass('hidden-xs hidden-sm');
    $('#chat-bot-panel').addClass('hidden');

    this.scrollChatToEnd();
  },

  hideSummaryLinkClicked: function(e) {
    if (e) {
      e.preventDefault();
    }

    $('#job-summary-panel').addClass('hidden-xs hidden-sm');
    $('#chat-bot-panel').removeClass('hidden');

    this.scrollChatToEnd();
    this.autoResizeInputTextArea();
  },

  activateChatBotSummary: function() {
    $('#summary-button-group').addClass('active');
    $('#nextButton').removeClass('btn-default').addClass('btn-success');
  },

  scrollChatToEnd: function() {
    if (UC.utils.isMobileViewPort()) {
      $('#chat-bot-panel')[0].scrollIntoView({block: "end", inline: "nearest"});
    } else {
      const chatContainer = $('.chat-bot-conversation');
      chatContainer.stop().animate({
        scrollTop: chatContainer.prop('scrollHeight')
      }, 500);
    }
  },

  scrollChatToStart: function() {
    if (UC.utils.isMobileViewPort()) {
      $('.chat-bot-summary')[0].scrollIntoView({block: "start", inline: "nearest"});
    }
  },

  showSummary: function() {
    $('#chat-bot-panel').removeClass('col-md-offset-2 col-lg-offset-2', 500, function() {
      $('#job-summary-panel').removeClass('hidden');
      $('#preview-summary-link').parent().removeClass('hidden');
    });
  },

  updateQualityScore: function(score) {
    const $svg = $('.chat-bot-summary svg');
    $svg.find('#star_1').attr('fill', `url(#${this.updateSingleQualityScoreStar(1, score)})`);
    $svg.find('#star_2').attr('fill', `url(#${this.updateSingleQualityScoreStar(2, score)})`);
    $svg.find('#star_3').attr('fill', `url(#${this.updateSingleQualityScoreStar(3, score)})`);
    $svg.find('#star_4').attr('fill', `url(#${this.updateSingleQualityScoreStar(4, score)})`);
    $svg.find('#star_5').attr('fill', `url(#${this.updateSingleQualityScoreStar(5, score)})`);
  },

  updateSingleQualityScoreStar: function(segment, scoreValue) {
    if (scoreValue % 1 >= 0.75) {
      scoreValue = Math.round(scoreValue);
    }
    if (scoreValue >= segment - 0.5) {
      return (scoreValue >= segment) ? 'full' : 'half'
    }
    return 'empty';
  },

  captureThreadConversation: function() {
    var messages = [];

    $('.chat-bot-conversation .row').each(function() {
        var role = $(this).hasClass('recipient') ? 'recipient' : 'sender';
        var message = $(this).find('.chat-item-content-message').text().trim();
        messages.push({ role: role, message: message });
    });

    return messages;
  },

  validate: function(dfd) {
    this.$el.find('.has-error').removeClass('has-error');

    var errors = [];

    const data = {
      live_chat: {
        zip_code: this.$zipCode.val(),
        description: $('#job-description').val(),
        timeline: $('#job-duration-select option:selected').text(),
        thread_conversation: this.captureThreadConversation(),
      }
    }

    if (!this.descriptionLengthCounter.hasValidTextLength()) {
      errors.push(this.descriptionLengthCounter.getError());
    }

    if (errors.length === 0) {
      if (!data.live_chat.zip_code || data.live_chat.zip_code.length === 0) {
        errors.push({element: this.$zipCode, text: 'Please enter a valid zip code.'});
      } else if (UC.utils.validateZipCode(data.live_chat.zip_code)) {
        this.checkZipCodeValid(data, dfd);
        return;
      } else {
        errors.push({element: this.$zipCode, text: 'Please enter a valid zip code.'});
      }
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, { className: "form-group" });
      dfd.reject();
    } else {
      if (this.jobSummary) {
        this.jobSummary.timeline = data.live_chat.timeline;
        this.jobSummary.zip_code = data.live_chat.zip_code;
        this.jobSummary.description = data.live_chat.description;
        this.jobSummary.thread_conversation = data.live_chat.thread_conversation;
      }

      dfd.resolve({ live_chat: this.jobSummary });
    }
  },

  onZipCodeChanged: function(e) {
    let errors = [];

    this.$zipCode.closest('.has-error').removeClass('has-error');

    $('#job-location').addClass('hidden');

    if (UC.utils.validateZipCode(this.$zipCode.val())) {
      this.checkZipCodeValid({ live_chat: { zip_code: this.$zipCode.val() } }, new $.Deferred());
    } else {
      errors.push({element: this.$zipCode, text: 'Please enter a valid zip code.'});
    }
  },

  checkZipCodeValid: function (data, dfd) {
    const path = '{0}{1}'.format(this.routes.validZipCode, data.live_chat.zip_code);

    UC.net.get(path, {job_lead_id: UC.constants.jobLeadId}, function(res) {
      $('#job-location').text(`${res.zip.CityName}, ${res.zip.StateAbbr}`).removeClass('hidden');
      if (res.suggested_attorneys) {
        $('.row.face-piles').replaceWith(res.suggested_attorneys);
      }
      new GeoLocation().storeUserChoice(data.live_chat.zip_code);
      dfd.resolve(data);
    }).fail(function(res) {
      res.handled = true;
      UC.utils.showValidationErrors(
        [
          {
            element: this.$zipCode,
            text: res.responseJSON ? res.responseJSON.message : 'Failed to validate the Zip Code'
          }
        ], { className: "form-group" }
      );
      dfd.reject();
    }.bind(this));
  },
});
