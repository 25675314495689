UC.views.InvoicesEdit = Backbone.View.extend({

  events: {
    'click #btnDeleteInvoice' : 'destroy'
  },

  initialize: function() {

    this.form_details = new UC.partials.InvoicesFormDetails({
      el: this.el,
      jobId: UC.job ? UC.job.id : null,
      showTimesheetEntryImport: false
    });

    this.form_details.render(UC.constants.attorney_client);

    $('.jn-invoices').addClass('active');
  },

  destroy: function (e) {
    e.preventDefault();

    var message = 'Are you sure you want to delete this invoice?';

    function onConfirm(response) {
      if (response) {
        UC.net.destroy('/invoices/{0}'.format(UC.invoice.id));
      }
    }

    UC.utils.showConfirmModal('Confirm', message, onConfirm);
  }
});
