UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.SaveStatusIndicatorView = Backbone.View.extend({

  tagName: 'div',

  id: 'ts-save-status',

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/save_status_indicator"),

  initialize: function () {
    this.saveStatusCounter = 0;
    this.collection.on('add', this.handleTimesheetRequestMade.bind(this));
    this.collection.on('request', this.handleTimesheetRequestMade.bind(this));
    this.collection.on('sync', this.handleTimesheetRequestComplete.bind(this));
    this.collection.on('model-delete-sync', this.handleTimesheetRequestComplete.bind(this));
    this.setTimeoutId = null;
  },

  render: function () {
    this.$el.html(this.template({currentTimesheetStateSaved: this.currentTimesheetStateSaved()}));
  },

  handleTimesheetRequestMade: function () {
    this.saveStatusCounter++;
    this.render();
  },

  handleTimesheetRequestComplete: function () {
    this.saveStatusCounter--;

    if (this.setTimeoutId) clearTimeout(this.setTimeoutId);

    this.setTimeoutId = setTimeout(this.render.bind(this), 1500);
  },

  currentTimesheetStateSaved: function () {
    return this.saveStatusCounter === 0;
  }

});
