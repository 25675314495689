import '../user_documents/index'
import '../user_documents/show'
import '../user_documents/history'

!function() {

  UC.views.JobsDocuments = Backbone.View.extend({
    initialize: function() {
      new UC.views.UserDocumentsIndex({el: this.$el})
    },
  });

  UC.views.JobsDocumentsShow = Backbone.View.extend({
    initialize: function() {
      new UC.views.UserDocumentsShow({el: this.$el})
    },
  });

  UC.views.JobsDocumentsHistory = Backbone.View.extend({
    initialize: function() {
      new UC.views.UserDocumentsHistory({el: this.$el})
    },
  });
}();
