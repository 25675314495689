var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <p id=\"client-copy\" class=\"marg-b-0\">For the safety and privacy of our users, we don't allow the sharing of personal contact information until after an attorney has been hired. If you want to communicate with an attorney before hiring them, you can send them a message or schedule a call directly through UpCounsel.</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <p id=\"attorney-copy\" class=\"marg-b-0\">For the safety and privacy of our users, we don't allow the sharing of personal contact information before a client hires an attorney. If you want to contact a potential client before you have been hired, you can send them a message or schedule a call directly through UpCounsel.</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"row\" id=\"panelCloseTheLoop\">\n  <div class=\"col-sm-12\">\n    <div class=\"pad-15 ctl-container\">\n\n      <h4 class=\"marg-t-0\">Please do not include personal contact information</h4>\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"show_client") || (depth0 != null ? lookupProperty(depth0,"show_client") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"show_client","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":9,"column":22}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"show_client")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_client") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + "\n    </div>\n  </div>\n</div>\n";
},"useData":true});