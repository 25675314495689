UC.components.confirmModal = function (options) {
  var that = this,
    defaults = {
      title: 'Confirm Operation',
      text: '',
      noText: 'No',
      yesText: 'Yes',
      callback: $.noop,
      dismissOnYes: true,
    },
    opt = $.fn.extend({}, defaults, options);

  function onNoClick() {
    opt.callback(false);
  }

  function onYesClick() {
    opt.callback(true);
  }

  var $modal = $('<div class="modal"><div class="modal-dialog"><div class="modal-content"></div></div></div>');
  var $content = $modal.find('.modal-content');
  var $backdrop = $('<div class="modal-backdrop in"></div>');

  // header
  $content.append('<div class="modal-header"><h4 class="modal-title">{0}</h4></div>'.format(opt.title));

  // body
  $content.append('<div class="modal-body">{0}</div>'.format(opt.text));

  // buttons
  var $no = $('<button class="btn default" data-dismiss="modal">{0}</button>'.format(opt.noText));
  var $yes = $('<button class="btn btn-primary">{0}</button>'.format(opt.yesText));
  if (opt.dismissOnYes) $yes.attr('data-dismiss', 'modal');
  var $footer = $('<div class="modal-footer"></div>');

  $no.click(onNoClick);
  $yes.click(onYesClick);

  if (opt.noText === '') {
    $footer.append($yes);
  } else {
    $footer.append($no, $yes);
  }
  $footer.appendTo($content);

  $backdrop.css('z-index', '9998');
  $modal.css('z-index', '9999');

  $modal.modal({ backdrop: false });
  $('body').append($backdrop);

  $modal.on('hidden.bs.modal', function() {
    $backdrop.remove();
    $modal.remove();
  });
};
