!function() {
  UC.views.CallsIndex = Backbone.View.extend({

    events: {
      'change #filterCallHistory': 'filterCallHistory',
      'click .initiate-call': 'call',
      'click .js-copy-share-btn': 'handleCopyShare',
      'click .cancel-sched-call' : 'openCancelSchedCallModal',
      'click .js-connect-cal': 'handleConnectCallClick'
    },

    initialize: function() {
      this.$el.find('#filterCallHistory').select2();
      if ($('#scheduled-calls').length > 0) {
        var dashboard_scheduled_calls = new UC.views.DashboardScheduledCalls({
          el: '#scheduled-calls'
        });
      }

      if (UC.utils.getParameterByName('post_auth') === 'true') {
        if ($('.integrated-calendar').length > 0) {
          new UC.partials.SelectCalendar({
            btnSelector: '#select-cal',
            openImmediately: UC.constants.showSelectCalModal,
            callsPage: true
          });
        }

        window.history.pushState({}, null, '/calls')
      }
    },
  
    openCancelSchedCallModal: function(e) {
      e.preventDefault();
      var scheduledCallId = $(e.currentTarget).data('scheduled-call-id');
      new UC.partials.ScheduledCallsCancel({scheduledCallId: scheduledCallId});
    },
  
    handleConnectCallClick: function() {
      UC.utils.sendNesEvent('connect_your_calendar_clicked', { attorney_id: UC.user.id });
    },

    handleCopyShare: function() {
      document.querySelector('.copy-share-calendar-url').select();
      document.execCommand('copy');
      
      UC.utils.sendNesEvent('attorney_calendar_copy_url_clicked', { attorney_id: UC.user.id });

      this.$('.js-copy-share-btn').fadeOut(function() {
        $(this).text('Copied').addClass('text-green').fadeIn('slow');
      });
    },

    call: function(e) {
      e.preventDefault();
      new UC.views.CallsModalInitiate({
        to: $(e.currentTarget).data('phone'),
        job_id: UC.constants.jobId
      }).render();
    },

    filterCallHistory: function(e) {
      var client_id = $(e.target).val();
      window.location = window.location.pathname + '?client_id={0}'.format(client_id);
    }
  });
}();
