!function() {

  UC.views.SuccessStoriesIndex = Backbone.View.extend({
    events: {
      'click .success-story-filter' : 'setFilter'
    },

    setFilter: function(e) {
      e.preventDefault();
      var $selectedFilterDiv = $(e.currentTarget);
      var selectedFilter = $selectedFilterDiv.data('successStoryFilterTag');
      var $successStoryPanelContainers = $('.success-story-panel-container');

      if (!$selectedFilterDiv.hasClass('selected')) {
        this.clearFilters();
        $selectedFilterDiv.addClass('selected');
        $(".success-filter-description[data-success-story-filter-tag='"+ selectedFilter + "']").removeClass('hidden');

        $successStoryPanelContainers.each(function(index, element) {
          var $successStoryPanelContainer = $(element);
          var successStoryPanelTags = $successStoryPanelContainer.data('successStoryTags');

          if (successStoryPanelTags.indexOf(selectedFilter) === -1) {
            $successStoryPanelContainer.addClass('hidden')
          }
        })
      } else {
        this.clearFilters();
      }
    },

    clearFilters: function() {
      $('.success-story-filter').removeClass('selected');
      $('.success-story-panel-container').removeClass('hidden');
      $('.success-filter-description').addClass('hidden');
    }
  })

}();
