UC.utils.validateObjectId = function (id) {
  var re = /^[a-f0-9]{24}$/i;
  return re.test(id);
};

UC.utils.validateEmail = function (email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test((email || '').trim());
};

UC.utils.validatePassword = function (password) {
  return password.length >= UC.constants.minPasswordLength;
};

UC.utils.validateName = function (name) {
  return /^[A-Za-z'\s-]+$/.test(name);
};

UC.utils.validatePhone = function (phone) {

  if (!/^[0-9\.)(+\s-]{7,15}.*$/.test(phone)) {
    return false;
  } else {
    phone = phone.replace(/[\.)(+\s-]+/g, '');
    // avoid extension for client-side validation
    phone = phone.split(';')[0].split('#')[0].split(['ext'])[0].split('x')[0];

    if (!/^\d{7,15}$/.test(phone)) {
      return false;
    }
  }

  return true;
};

UC.utils.validateUrl = function (url) {
  var re = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
  return re.test(url);
};

UC.utils.urlIncludedInText = function (text) {
  var re = /^.*([a-z])?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)|\/|\?)*)?.*$/ig
  return re.test(text);
};

UC.utils.validatePositiveNumber = function (number) {
  return /^[1-9]([0-9]*)?$/.test(number);
};

UC.utils.validateZipCode = function (zip, countryCode) {
  switch (countryCode) {
    case 'CA':
      return  /(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/.test(zip)
      break;
    default:
      // default regex is for US zip codes
      return  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)
      break;
  }
};

UC.utils.validateDate = function (dateString) {
  var date = moment(dateString, UC.constants.momentDateFormats, true);
  return date.isValid();
};

UC.utils.validateAddressAjax = function (options) {

  if (options.address.ZipCode || options.address.ZipCode == '') {
    if (UC.utils.validateZipCode(options.address.ZipCode)) {
      var path = '/account/validate_address';
      UC.net.post(path, {address: options.address}, function (data) {
        UC.utils.onValidateAddressAjax(data.valid, options);
      });
    } else {
      UC.utils.onValidateAddressAjax(false, options);
    }
  } else {
    if (options.address.City == '' || options.address.CountryCode == '##' || options.address.CountryCode == '') {
      UC.utils.onValidateAddressAjax(false, options);
    } else {
      UC.utils.onValidateAddressAjax(true, options);
    }
  }
};

UC.utils.onValidateAddressAjax = function (valid, options) {

  if (typeof options.callback === 'function') {
    options.callback(response)
  }

  if (typeof options.errors === 'object') {
    if (!valid) {
      var errors = [];
      if (options.address.ZipCode || options.address.ZipCode == '') {
        if (options.errors.ZipCode) {
          errors.push(options.errors.ZipCode);
        }
      } else {
        if (options.address.City == '' && options.errors.City) {
          errors.push(options.errors.City);
        }

        if ((options.address.CountryCode == '##' || options.address.CountryCode == '') && options.errors.CountryCode) {
          errors.push(options.errors.CountryCode);
        }
      }
      if (errors.length > 0 && UC.constants.pageName === 'register') UC.utils.showValidationErrors(errors, {className: 'input-container'});
      if (errors.length > 0 && UC.constants.pageName !== 'register') UC.utils.showValidationErrors(errors);
    }
  }

  if (typeof options.dfd === 'object') {
    if (valid) {
      options.dfd.resolve();
    } else {
      options.dfd.reject();
    }
  }
};

UC.utils.clearValidationErrors = function (className, $scope) {
  $scope = $scope || $('body');
  $scope.find('.{0}'.format(className || 'row')).removeClass('error has-error');
};

UC.utils.resetValidationErrors = function($scope) {
  $scope = $scope || $("body");
  $(".has-error", $scope).removeClass("has-error");
  $(".error-message", $scope).remove();
};

UC.utils.hideValidationErrors = function (className) {
  UC.utils.clearValidationErrors(className);
  $('.{0}'.format(className || 'row')).find('.error-message').text('');
};

// function options:
//   [optional] scrollToFirst: scroll to first field with a validation error
//   [optional] className: the class of the element to show the validation error message in for all elements, defaults to '.row'
//
// individual error options:
//   element: jQuery object for the field in question
//   [optional] text: the error message to display for invalid input
//   [optional] className: the class of the element to show the validation error message in, defaults to '.row'
// }

UC.utils.showValidationErrors = function (errors, options) {
  if (!errors || errors.length === 0) {
    return;
  }

  var options = options || {};
  var scrollToFirst = options.scrollToFirst;
  var defaultClass = options.className ? options.className : 'row';
  var $firstContainer = null;

  for (var i = 0, len = errors.length; i < len; i++) {
    var error = errors[i];
    var className = error.className || defaultClass;
    var container = error.element.closest('.{0}'.format(className));

    if (!$firstContainer) {
      $firstContainer = container;
    }

    if (error.text) {

      var $errorMessage = container.find('.error-message');

      if ($errorMessage.length < 1) {
        $errorMessage = $("<div class='error-message help-block'></div>");
        error.element.parent().append($errorMessage);
      }

      $errorMessage.text(error.text);
    }
    container.addClass('has-error');
  }

  if (scrollToFirst && $firstContainer.is(':visible')) {
    UC.utils.scrollToElement($firstContainer);
  }
};

// function options:
//   [optional] errorClassName: the class of the element to show the validation error message in, defaults to '.form-group'
//              This can be overridden at the field level
//   [optional] scope: scope to clear validation errors on
// field options:
//   element: jQuery object or selector for the field
//   [optional] type: text, select, date, email, etc; can be inferred, defaults to 'text' otherwise
//   [optional] errorMessage: the error message to display for invalid input
//   [optional] errorClassName: the class of the element to show the validation error message in, defaults to '.form-group'
//   [optional] invalidValues: an array of values to raise a validation error for, in addition the the basic presence/validity checks
//   [optional] validator: a custom validation function, must return true for valid or false for invalid
//   [optional] required: defaults to true
//
// If only a jquery Object is provided, the field is assumed to be required, use
// a default validator, be type text, have no invalid values (other than ''),
// and display no error message text.
UC.utils.validateFields = function (fields, options) {
  var defaults = {
    errorClassName: 'form-group'
  };

  var options = $.extend(defaults, options);
  var errors = [];

  if (!$.isArray(fields)) {
    fields = [fields];
  }

  $.each(fields, function (i, field) {

    // ensure we have a jQuery selector
    field.element = $(field.element);

    var errorMessage = field.errorMessage || field.element.data("errorMessage");
    var errorClassName = field.errorClassName || 'form-group';

    if (!UC.utils.validateField(field)) {
      errors.push({
        element: field.element,
        text: errorMessage,
        className: errorClassName
      });
    }
  });

  UC.utils.clearValidationErrors(options.errorClassName, options.scope);

  if (errors.length > 0) {
    UC.utils.showValidationErrors(errors, {
      scrollToFirst: options.scrollToFirst,
      className: options.errorClassName
    });

    return false;
  } else {
    return true;
  }
};

UC.utils.validateField = function (field) {
  var type = field.type || UC.utils.determineFieldType(field);
  var value;
  // If required is not explicitly specified, assume it is true.
  var required = (field.required === true || field.required === undefined)

  if (field.element.length !== 1 && type !== 'radio') {
    throw new Error('Each non-radio field element must resolve to one DOM object, {0} resolved to {1}'.format(field.element.selector, field.element.length));
  }

  if (typeof field.validator === 'function') {
    return field.validator.call(null, field);
  } else {
    if (type === 'select') {
      value = UC.utils.findSelectedElement(field.element).val();
    } else if (type === 'radio') {
      // value = field.element.find(':checked').val();
      // horrible hack
      value = $('{0}:checked'.format(field.element.selector)).val();
    } else if (type === 'checkbox') {
      if (field.element[0].checked) {
        value = true;
      }
    } else {
      value = field.element.val().trim();
    }

    if (value === '' || value === undefined || (type === 'select' && value === UC.constants.emptySelectValue)) {
      return required === false;
    } else if (_.contains(field.invalidValues, value)) {
      return false;
    } else {
      switch (type) {
        case 'date':
          return UC.utils.validateDate(value);
          break;
        case 'email':
          return UC.utils.validateEmail(value);
          break;
        case 'phone':
          return UC.utils.validatePhone(value);
          break;
        case 'url':
          return UC.utils.validateUrl(value);
          break;
        case 'password':
          return UC.utils.validatePassword(value);
          break;
        case 'positive-number':
          return UC.utils.validatePositiveNumber(value);
        default:
          return true;
          break;
      }
    }
  }
};

// Type can be specified either directly in the call to validateFields, specified as
// data-field-type on the html element, or inferred from the html element's type.
// The default assumption is that the element is a plain text field.
UC.utils.determineFieldType = function (field) {
  if (field.element.length < 1) {
    throw new Error('Element type cannot be determined for the empty selector {0}'.format(field.element.selector));
  }

  var rawElement = field.element[0];

  if (rawElement.dataset.fieldType) {
    return rawElement.dataset.fieldType;
  } else {
    switch (rawElement.type) {
      case 'select-one':
        return 'select';
        break;
      case 'select-multiple':
        return 'select';
        break;
      case 'radio':
        return 'radio';
        break;
      case 'checkbox':
        return 'checkbox';
        break;
      default:
        return 'text';
        break;
    }
  }
};
