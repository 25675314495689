UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.TimesheetTabsView = Backbone.View.extend({

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/timesheet_tabs"),

  initialize: function (opts) {
    this.isShowingCurrentTimesheetsTab = opts.tab === opts.currentTab;
  },

  render: function () {
    this.$el.html(this.template(this));
  }
});
