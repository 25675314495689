UC.views.JobsList = Backbone.View.extend({

  events: {
    'click .js-job-item .job-item-bookmark': 'toggleBookmark',
    'click .job-item-show-hide': 'toggleJobVisibility',
    'click .archiveJob': 'archive',
    'click .job-item-link': 'trackJobClick',
    'click .js-job-item-link': 'trackJobClick',
    'click .js-submit-first-proposal-link': 'trackJobClick',
    'click .shown-min': 'trackShowMoreClick',
    'click .editJob': 'visitEditJob',
    'click .nav-tabs li a': 'changeTab',
    'click .pagination a': 'paginate',
    'mouseenter .detailed-job-item': 'showViewJobLink',
    'mouseleave .detailed-job-item': 'hideViewJobLink'
  },

  initialize: function(options) {
    this.updateUrl = options.updateUrl;
    this.filterParams = options.filterParams;
    this.updateMethod = options.updateMethod;
    this.additionalRenderCallback = options.renderCallback;
    this.beforePaginate = options.beforePaginate;

    if (options.eventNamePrefix) {
      this.eventNamePrefix = options.eventNamePrefix
    } else {
      this.eventNamePrefix = UC.controller.charAt(0).toUpperCase() + UC.controller.slice(1);
    }

    this.current_type = options.currentType || this.$el.find('.nav-tabs li.active a').data('type');
    
    this.initThings();

    this.$el.find('#learn-about-colors').tooltip({
      html: true,
      title: this.$el.find('#jobs-list-legend').html()
    }).click(function(e) {
      e.preventDefault();
    });
  },

  initThings: function() {
    this.initJobCogs(this.$el.find('.job-item'));
    $('[data-toggle="tooltip"]').tooltip();

    if (UC.utils.isDesktopViewPort()) {
      this.$('.js-desktop-tooltip').tooltip();
    }
    
    var archiveEventName = this.eventNamePrefix + ' - Archive Job Click';
    var manageCogEventName = this.eventNamePrefix + ' - Mangage Job Click';
    var manageItemEventName = this.eventNamePrefix + ' - Mangage Item Click';

    UC.utils.recordKissClickEvent('.archiveJob', archiveEventName, {type: UC.user.type});
    UC.utils.recordKissClickEvent('.btnJobActions', manageCogEventName, {type: UC.user.type});

    $('#jobActionsList a').click(function(e){
      UC.utils.handleGenericClick($(this), manageItemEventName);
    });

    var truncateLength = 280;
    if (!UC.utils.isDesktopViewPort()) {
      truncateLength = 180;
      $('.view-job-button').removeClass('hidden');
    }

    $(".detailed-job-description").truncateToggleMultiple({
      truncateLength: truncateLength,
      moreText: 'Read More',
      lessText: 'Read Less'
    });
  },

  visitEditJob: function(e) {
    e.preventDefault();
    location.href = $(e.currentTarget).attr('url');
  },

  trackJobClick: function(e) {
    var $jobItem = $(e.currentTarget);
    var jobItemData = $jobItem.data();
    var eventName = this.eventNamePrefix + ' - Job Item Click';

    UC.utils.recordKissEvent(eventName, {
      type: UC.user.type,
      tab: this.current_type,
      jobId: $jobItem.parent().data().jobId,
      topLabel: jobItemData.topLabel,
      invitedLabel: jobItemData.invitedLabel,
      recommendedLabel: jobItemData.recommendedLabel,
      repeatClientTest: jobItemData.repeatClientTest
    });
  },

  trackShowMoreClick: function(e) {
    var $jobItem = $(e.currentTarget);
    var jobItemData = $jobItem.parent().data();
    var eventName = this.eventNamePrefix + ' - Show More Description Click';
    var attrs = {
      type: UC.user.type,
      tab: this.current_type,
      jobId: jobItemData.jobId,
      topLabel: jobItemData.topLabel,
      invitedLabel: jobItemData.invitedLabel,
      recommendedLabel: jobItemData.recommendedLabel,
      repeatClientTest: jobItemData.repeatClientTest
    };

    UC.utils.recordKissEvent(eventName, attrs);
  },

  replaceJobItem: function(data) {
    var $existing = $('.job-item.{0}'.format(data.jobId));
    var $new_item = $(data.html);
    $existing.replaceWith($new_item);
    this.initJobCogs($new_item);
  },

  initJobCogs: function($items) {
    $items.find('.manage-job-cog').each(function(i, cog) {
      var $cog = $(cog);
      var view = new UC.views.JobManageCog({
        el: $cog,
        redirectAfterSave: false,
        statusChangeCallback: this.replaceJobItem.bind(this)
      });
    }.bind(this));
  },

  toggleBookmark: function(e) {
    e.preventDefault();

    var $el = $(e.currentTarget);
    var $item = $el.closest('.js-job-item');
    var job_id = $item.data('job-id');
    var is_bookmarked  = $el.hasClass('icon-bookmark-1');

    $el.tooltip('hide');

    if (is_bookmarked) {
      $el.removeClass('icon-bookmark-1');
      $el.addClass('icon-bookmark-empty');
      $el.attr('data-original-title', 'Bookmark this job');
    } else {
      $el.removeClass('icon-bookmark-empty');
      $el.addClass('icon-bookmark-1');
      $el.attr('data-original-title', 'Unbookmark this job');
    }

    $el.tooltip('fixTitle');

    UC.net.post('job/bookmark/{0}'.format(job_id), { bookmark: !is_bookmarked });
    UC.utils.sendNesEvent('bookmark_job_clicked', {job_id: job_id, bookmark: !is_bookmarked});
  },

  toggleJobVisibility: function(e) {
    e.preventDefault();

    var $item = $(e.currentTarget).closest('.js-job-item');
    var jobId = $item.data('job-id');
    var $icon = $item.find('.job-item-show-hide');
    var isHidden = $icon.hasClass('glyphicon-eye-open');
    var self = this;
    var needsRender = this.filterParams()['open'] !== 'all';
    $icon.tooltip('hide');

    UC.net.post('/job/marketplace_visibility/{0}'.format(jobId), { visible: isHidden }, function() {
      if (isHidden) {
        $icon.attr('data-original-title', $icon.attr('data-tooltip-hide'));
        $icon.addClass('glyphicon-eye-close').removeClass('glyphicon-eye-open');
      } else {
        if (needsRender) {
          $item.remove();
        } else {
          $icon.attr('data-original-title', $icon.attr('data-tooltip-show'));
          $icon.addClass('glyphicon-eye-open').removeClass('glyphicon-eye-close');
        }
      }
      if (needsRender) {
        self.render();
      }
    });
  },

  toggleDescription: function(e) {
    e.preventDefault();

    var $el = $(e.currentTarget);
    var $text = $el.find('.detailed-job-description');

    $text.truncateToggle({
      truncateLength: 280,
      moreText: 'Read More',
      lessText: 'Read Less',
      toggleClass: 'truncated'
    });
  },

  showViewJobLink: function(e) {
    e.preventDefault();

    var $el = $(e.currentTarget);
    var $link = $el.find('.detailed-job-item-link');

    $link.removeClass('hidden');
  },

  hideViewJobLink: function(e) {
    e.preventDefault();

    if (!UC.utils.isDesktopViewPort()) {
      // always show on mobile/iPad
      return;
    }

    var $el = $(e.currentTarget);
    var $link = $el.find('.detailed-job-item-link');

    if ( $el.find('.detailed-job-description').hasClass('truncated') ) {
      $link.addClass('hidden');
    } else {
      // don't hide View Job because the description is expanded
    }

  },

  archive: function(e) {
    e.preventDefault();

    const $jobItem = $(e.currentTarget).closest('.job-item');
    const jobId = $jobItem.data('job-id');
    const jobTitle = $jobItem.find('.job-item-title').text();
    const archiveVal = $(e.currentTarget).data('archive');
    const view = this;

    UC.utils.showConfirmModal(
        'Confirm',
        `Are you sure you want to ${archiveVal ? '' : 'un'}archive ${jobTitle} job?`,
        function (response) {
          if (response) {
            UC.net.post('/job/archive/{0}'.format(jobId), {archive: archiveVal}, function() {
              view.render();
            });
          }
        }
    );
  },

  paginate: function(e) {
    e.preventDefault();
    this.current_page = $(e.currentTarget).data('page');

    if (typeof this.beforePaginate === 'function') {
      this.beforePaginate();
    }

    this.render();
  },

  setJobListMessage: function(dots) {
    var message = "<div class='section-job-list'><div class='job-list-no-results'>Please wait while we search jobs for you" + dots.join('') + "</div></div>"

    this.$el.find('#job-list').html(message);
  },

  showUploadJobListMessage: function() {
    var iteration = 0;
    visibleSpan = "<span>.</span>"
    hiddenSpan = "<span style='visibility: hidden'>.</span>"
    var renderText = function() {
      switch (iteration) {
        case 0:
          this.setJobListMessage([visibleSpan, hiddenSpan, hiddenSpan]);
          iteration = 1;
          break;
        case 1:
          this.setJobListMessage([visibleSpan, visibleSpan, hiddenSpan]);
          iteration = 2;
          break;
        default:
          this.setJobListMessage([visibleSpan, visibleSpan, visibleSpan]);
          iteration = 0;
      }
    }

    this.uploadJobsListMessageTimer = setInterval(renderText.bind(this), 600);
  },

  render: function() {
    if (typeof this.filterParams === 'function') {
      var data = this.filterParams();
    } else {
      var data = {};
    }

    if (!('type' in data)) {
      data.type = this.current_type;
    } else {
      this.current_type = data.type;
    }
    data.page = this.current_page;
    data.v9 = this.$el.find('.js-job-list-v9').length > 0;

    clearInterval(this.uploadJobsListMessageTimer);

    if (this.updateMethod === 'post') {
      UC.net.post(this.updateUrl, data, this.renderCallback.bind(this));
    } else {
      UC.net.get(this.updateUrl, data, this.renderCallback.bind(this));
    }

    this.showUploadJobListMessage()
  },

  renderCallback: function(res) {
    this.$el.find('#job-list').html(res);

    clearInterval(this.uploadJobsListMessageTimer)

    this.initThings();

    this.$el.find('#learn-about-colors').tooltip({
      html: true,
      title: this.$el.find('#jobs-list-legend').html()
    }).click(function(e) {
      e.preventDefault();
    });

    if (typeof this.additionalRenderCallback === 'function') {
      this.additionalRenderCallback();
    }
  },

  setPage: function(pageNumber) {
    this.current_page = pageNumber;
  },

  getPage: function() {
    return this.current_page;
  },

  changeTab: function(e) {
    e.preventDefault();
    var type = $(e.currentTarget).data('type');

    this.current_type = type;
    this.current_page = 1;

    this.render();

    var eventName = this.eventNamePrefix + ' - Job List Tab Click';
    UC.utils.recordKissEvent(eventName, {
      type: UC.user.type, tab: type
    });
  },

  getCurrentType: function() {
    return this.current_type;
  }

});
