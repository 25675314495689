!function() {

  var defaults = {
    attachments: [],
    userId: null,
    jobId: null,
    skipPathPrefix: false,
    inputName: null
  };

  function AttachmentTextarea($textarea, options) {
    options = options || {};

    this.options = $.fn.extend({}, defaults, options);
    this.attachments = this.options.attachments || [];

    this.$textarea = $textarea;
    this.$el = $(this.template());
    this.$list = this.$el.find('.attachments-list');
    this.$progress = this.$el.find('.progress-bar');

    this.render();
    this.bindEvents();

    this.uploader = new UC.components.Upload({
      userId: options.userId,
      jobId: options.jobId,
      attorneyId: options.attorneyId,
      skipPathPrefix: options.skipPathPrefix,
      onInit: this.makeDropPane.bind(this),
      onUploadComplete: this.onUploadComplete.bind(this),
      upload_path: this.options.upload_path,
      source_type: this.options.source_type,
      fileStackApiKey: UC.constants.sharedFileStackApiKey
    });

    // render any existing attachments
    if (this.attachments.length > 0) {
      this.renderAttachmentRows(this.attachments);
    }
  }

  AttachmentTextarea.prototype = {

    constructor: AttachmentTextarea,

    template: UC.views.resolveHandlebarsTemplate('components/attachment_textarea'),

    templateAttachment: UC.views.resolveHandlebarsTemplate('public/invoices/attachment'),

    render: function() {
      // move textarea inside our special container
      this.$textarea.after(this.$el);
      this.$el.prepend(this.$textarea);

      // add error class to container element if present on textarea
      if (this.$textarea.hasClass('has-error')) {
        this.$el.addClass('has-error');
      }
    },

    bindEvents: function() {
      this.$el.find('.choose-attachments').on('click', this.openFilePicker.bind(this));
      this.$el.find('.attachments-list').on('click', '.attachment-remove', this.destroy.bind(this));

      this.$textarea.on('focus blur', function(e) {
        this.$el.toggleClass('focused', e.type === 'focus');
      }.bind(this));
    },

    makeDropPane: function(uploader) {
      uploader.makeDropPane(this.$el, {
        onStart: this.dragDropStart.bind(this),
        onProgress: this.dragDropProgress.bind(this),
        onError: this.dragDropError.bind(this)
      });
    },

    dragDropStart: function() {
      // show some fake progress get the user excited
      this.$progress.css('width', '5%').show();
    },

    dragDropProgress: function(percentage) {
      if (percentage > 5) {
        this.$progress.css('width', '{0}%'.format(percentage));
      }
    },

    dragDropError: function(type, message) {
      this.$el.removeClass('dragging');

      if (type === 'WrongSize') {
        message = 'File too large. Must be less than 5 Mb';
      }

      UC.utils.showNotification(message, UC.constants.results.failure);
    },

    onUploadComplete: function(err, documents) {
      this.$el.removeClass('uploading');

      if (err) return;

      this.attachments = this.attachments.concat(documents);
      this.renderAttachmentRows(documents); // only new ones

      if (this.options.inputName) {
        var name = this.options.inputName;
        this.appendAttachmentInputs(name, documents);
      }
    },

    renderAttachmentRows: function(documents) {
      // append new documents to attachments list
      _.each(documents, function(doc) {

        var $attachment = $(this.templateAttachment({
          id: doc._id,
          name: doc.FileName
        }));

        // make sure names aren't too long
        $attachment.find('.attachment-name').css({
          'max-width': this.$el.innerWidth() - 60
        });

        this.$list.append($attachment);
      }.bind(this));

      this.toggleList();
    },

    appendAttachmentInputs: function(name, documents) {

      _.each(documents, function(doc) {
        var html = '<input type="hidden" name="{0}" value="{1}">'.format(name, doc._id);
        var $input = $(html);
        this.$el.after($input);
      }.bind(this));
    },

    openFilePicker: function(e) {
      if (e) e.preventDefault();
      this.uploader.openFilePicker();
    },

    destroy: function(e) {
      e.preventDefault();

      var $el = $(e.target).closest('.attachment');
      var id = $el.data('id');

      $el.hide();

      // if we're removing the last one, hide the list
      if (this.attachments.length <= 1) {
        this.$list.hide();
      }

      this.uploader.destroy(id, function(err, message) {
        if (err) {
          $el.show();
          this.$list.show();
          UC.utils.showNotification('There was an error removing that attachment.', UC.constants.results.failure);
        } else {
          $el.remove();
          this.attachments = _.reject(this.attachments, function(attachment) {
            return attachment._id === id;
          });
        }
      }.bind(this));
    },

    toggleList: function() {
      if (this.attachments.length > 0) {
        this.$list.show();
      } else {
        this.$list.hide();
      }
    },

    getDocumentIds: function() {
      return _.map(this.attachments, function(attachment) {
        return attachment._id;
      });
    },

    reset: function() {
      this.attachments = [];
      this.$list.hide().empty();
    }
  };

  $.fn.attachmentTextarea = function() {
    var args = Array.prototype.slice.apply(arguments);
    var $el = $(this).first();
    var existing = $el.data('attachmenttextarea');

    if (existing && typeof args[0] === 'string') {
      return existing[args[0]].apply(existing, args.slice(1));
    } else {
      var ata = new AttachmentTextarea(this, args[0]);
      $el.data('attachmenttextarea', ata);
      return ata;
    }
  };
}();
