UC.views.ProposalTemplatesIndex = Backbone.View.extend({
  events: {
    'click .js-btnAddNewTemplate': 'openModal',
    'click .js-btnSelectProposalTemplate': 'handleSelect'
  },

  elements: {
    'noTemplateMsg': null
  },

  initialize: function(options) {
    UC.utils.setSelectors(this.elements);
    options = options || {};
    this.base_path = '/proposal_templates';
    this.onSelect = options.selectCallback;

    this.draftProposalTemplateList = new UC.views.DraftProposalTemplateList({
      el: this.$('.js-proposal-template-list'),
      onEdit: this.handleEdit.bind(this),
      onDelete: this.handleDelete.bind(this)
    })

    this.proposalTemplateIndexList = new UC.views.ProposalTemplateIndexList({
      el: this.$('.js-proposal-template-index-page'),
      onEdit: this.handleEdit.bind(this),
      onDelete: this.handleDelete.bind(this)
    })

    this.modal = new UC.modals.ProposalTemplatesFormModal({
      parent_view: this,
      onSave: this.handleSave.bind(this)
    });
  },

  handleSelect: function() {
    var selectedId = $("input[name=proposalTemplate]:checked").val();
    this.onSelect(selectedId);
  },

  openModal: function(e) {
    this.modal.render();
  },

  reloadProposalTemplates: function() {
    if (this.draftProposalTemplateList.el) {
      this.draftProposalTemplateList.refreshTemplateList()
    }
    if (this.proposalTemplateIndexList.el) {
      this.proposalTemplateIndexList.refreshTemplateList();
    }
  },

  handleSave: function(templateId) {
    var message = templateId ? "Successfully updated template" : "Successfully added template";
    UC.utils.showNotification(message, UC.constants.results.success);
    this.reloadProposalTemplates();

    if (!this.elements.noTemplateMsg.hasClass('hidden')) {
      this.elements.noTemplateMsg.addClass('hidden');
    }
  },

  handleEdit: function(e) {
    var templateId = $(e.currentTarget).closest('[data-id]').data('id');
    this.modal.render({templateId: templateId});
  },

  handleDelete: function(e) {
    var $template   = $(e.currentTarget).closest('.list-item'),
        templateId  = $template.data('id'),
        name        = $template.find('.pt-name').text(),
        path        = this.base_path + '/{0}'.format(templateId);

    function onConfirm(response) {
      if (response) {
        UC.net.destroy(path, {}, function(res) {

          UC.utils.showNotification(res.message, UC.constants.results.success);

          this.reloadProposalTemplates();
        }.bind(this));
      }
    }

    var msg = 'Are you sure you want to delete your proposal template titled \'{0}\'?'.format(name);

    UC.utils.showConfirmModal('Confirm delete', msg, onConfirm.bind(this));
  }

});
