UC.views.CheckoutModalPaymentReason = Backbone.View.extend({
  el: '#modalUpcounselPaymentReason',

  events: {
    'hidden.bs.modal' : 'hideLoader',
    'click #btnPayReasonModal' : 'btnPayClick'
  },

  initialize: function(options) {
    this.callback = options.callback;

    this.$saveButton = this.$el.find('#btnPayReasonModal')
    this.saveLoader = this.$saveButton.buttonLoader();
  },

  render: function() {
    this.$el.modal();
  },

  btnPayClick: function() {
    var reason = this.$el.find('#selectReason').val();
    
    this.saveLoader.showLoader();

    if (reason && reason != '##') {
      this.$el.modal('hide');
      this.callback(reason);
    } else {
      UC.utils.showNotification('UpCounsel Payment Reason is required', UC.constants.results.failure);
    }
  },

  hideLoader: function() {
    this.saveLoader.hideLoader();
  }
});
