UC.modals.ModalNewInvoice = Backbone.View.extend({
  className: 'modal',

  el: 'body',

  events: {
    'click #modalNewInvoice a' : 'clickInvoiceModalAction'
  },

  render: function() {
    this.$('#modalNewInvoice').modal('show');

    this.$chk = this.$('#chkDontShowAgain');
    this.$chk.fancyCheck({label: this.$chk.attr('label')});
  },

  clickInvoiceModalAction: function(e) {
    e.preventDefault();

    var targetUrl = $(e.target).attr('href');

    if (this.$chk.hasClass('checked')) {
      var invoice_id = this.$chk.data('invoice-id');
      var path = '/invoices/{0}/update_new_notification'.format(invoice_id);

      UC.net.post(
        path,
        { HideNewNotification: true },
        function() {
          this.hideModal(targetUrl);
        }.bind(this)
      )
    } else{
      this.hideModal(targetUrl);
    }
  },

  hideModal: function(targetUrl) {
    this.$('#modalNewInvoice').modal('hide');

    if (targetUrl && targetUrl !== '#') {
      location.href = targetUrl;
    }
  }
});
