UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.TimesheetLineItem = Backbone.Model.extend({
  idAttribute: '_id',

  initialize: function() {
    this.on('change:JobId', this.handleSelectednessForJobChange.bind(this));
  },

  formattedDate: function() {
    var dateString = this.get('Date');

    if (dateString) {
      return moment(dateString, UC.constants.momentDateFormats, true).format("MM/DD/YYYY");
    }
  },

  calculateSubtotal: function() {
    var rate = this.get('Rate');
    var hours = this.get('Hours');
    return parseFloat((Math.round(rate * hours * 100)/100).toFixed(2)) || 0;
  },

  formattedSubtotal: function() {
    return UC.utils.formatCurrency(this.calculateSubtotal(), true);
  },

  formattedRate: function() {
    return UC.utils.formatCurrency(this.get('Rate') || 0, true);
  },

  markSelected: function() {
    this.selected = true;
    this.trigger('line-selected', this);
  },

  markUnselected: function() {
    this.selected = false;
    this.trigger('line-unselected', this);
  },

  jobsCollection: function() {
    return this.collection.jobsCollection;
  },

  invoicesCollection: function() {
    return this.collection.invoicesCollection;
  },

  getAssociatedJobModel: function() {
    if (!this.get('JobId')) return;
    return this.jobsCollection().get(this.get('JobId'));
  },

  getAssociatedInvoiceModel: function() {
    if (!this.get('InvoiceId')) return;
    return this.invoicesCollection().get(this.get('InvoiceId'));
  },

  jobStatus: function() {
    var jobModel = this.getAssociatedJobModel();
    if (jobModel) return jobModel.get('Status');
  },

  isInvoiceable: function() {
    if (this.invoiced()) return false;
    if (!this.get('JobId')) return true;
    return _.indexOf([UC.constants.JobStatus.WorkInProgress], this.jobStatus()) !== -1;
  },

  invoiced: function() {
    return !!this.get('InvoiceId');
  },

  invoiceNumber: function() {
    var invoiceModel = this.getAssociatedInvoiceModel();
    if (invoiceModel) return invoiceModel.get('InvoiceNumber');
  },

  invoiceId: function() {
    var invoiceModel = this.getAssociatedInvoiceModel();
    if (invoiceModel) return invoiceModel.get('_id');
  },

  readOnly: function() {
    return this.invoiced();
  },

  handleSelectednessForJobChange: function() {
    if (!this.selected) return;

    if (this.collection.selectedModelsCount() === 1 && this.isInvoiceable()) {
      //  This is the only selected model. We can trigger it as selected again to update the collection selected job id
      this.markSelected();
    } else {
      // Going to unselect this model as there are other selected models which have a different job
      this.markUnselected();
    }
  },

  addToInvoice: function() {
    this.addedToInvoice = true;

    // after the ts line is added to the inovice it should become unselected
    this.selected = false;
    this.trigger('ts-line-added-to-invoice', this);
  },

  removeFromInvoice: function() {
    this.addedToInvoice = false;
    this.trigger('ts-line-removed-from-invoice', this);
  },

  allowedToSelectLine: function() {
    // You cannot select a line if it is not invoiceable
    if (!this.isInvoiceable()) return false;

    // You can select a line if it is invoiceable and another line has not yet been selected
    if (!this.collection.selectedLineItemsJobId) return true;

    // You can select a line if it's invoiceable and its job matches a previously selected line
    return this.matchesPreviouslySelectedLineJob();
  },

  matchesPreviouslySelectedLineJob: function() {
    return (this.get('JobId') || 'direct') === this.collection.selectedLineItemsJobId;
  },

  parse: function(response) {
    return response.timesheet_line_item;
  },

  displayType: function() {
    for(var i = 0; i < this.types.length; i++) {
      if (this.types[i].value === this.attributes.Type) {
        return this.types[i].label;
      }
    }

    return '';
  },

  types: [
    { value: 'time_entry', label: 'Time Entry' },
    { value: 'fixed_fees', label: 'Fixed Fees' },
    { value: 'filing_fees', label: 'Filing Fees' },
    { value: 'expenses', label: 'Expenses' }
  ]
});
