UC.views.JobsJobAdmin = Backbone.View.extend({
  events: {
    'click #btnAddAdminNote' : 'btnAddAdminNoteClick'
  },

  initialize: function() {
    this.btnAddAdminNoteLoader = this.$('#btnAddAdminNote').buttonLoader();
  },

  btnAddAdminNoteClick: function(e) {
    var note = this.$('#txtAdminNotes').val();

    if (note === '') {
      alert('please provide a note');
    } else {
      this.btnAddAdminNoteLoader.showLoader();
      this.lastNote = note;
      this.postAdminNote(note, this.onAddAdminNote.bind(this));
    }
  },

  onAddAdminNote: function (res) {
    if (UC.constants.pageName === 'admin') {
      this.$('#adminNotes').prepend(noteLineItem({ forAdmin: true, note: this.lastNote }));
      this.$('#adminNotes').removeClass('hidden');

      this.$('#jo-messages').find('.jo-empty-msg').addClass('hidden');
      this.$('#jo-messages').scrollTop(0);
    }
    else {
      this.$('#adminNotes').append(noteLineItem({ note: this.lastNote }));
    }

    this.$('#txtAdminNotes').val('');
    this.showNotification(res.message, true);

    this.btnAddAdminNoteLoader.hideLoader();
    this.lastNote = '';

    function noteLineItem(opt) {
      if (opt.forAdmin) {
        var html = '<li><span class="jo-list-title">[{0}] {1}</span><span class="jo-text">{2}</span></li>'
        return html.format(UC.user.name, moment().format('MM/DD/YYYY h:m:ss A'), opt.note);
      } else {
        var html = '<p><b>{0}</b><br>{1}</p>'
        return html.format(moment().format('MM/DD/YYYY h:m:ss A'), opt.note);
      }
    }
  },

  postAdminNote: function (note, callback) {
    UC.net.post('/radmin/admin/jobs/{0}/add_note'.format(UC.constants.jobId), { note: note }, callback);
  },

  showNotification: function (message, success) {
    this.$('#panelNotification')
      .removeClass('red green')
      .addClass(success ? 'green' : 'red')
      .find('div')
      .html(message);

    UC.utils.showPanel(this.$('#panelNotification'));
    UC.utils.scrollToElement(this.$('#panelNotification'));
  }
});
