!function() {
  UC.views.ReferralsIndex = Backbone.View.extend({
    events: {
      'submit #formInviteEmails': 'inviteEmails',
      'focus #ReferralCode': 'selectAll',
      'click #learn-more': 'learnMore',
    },

    initialize: function() {
      UC.utils.recordKissClickEvent('#social-facebook', 'Referrals Index - Facebook Share Click');
      UC.utils.recordKissClickEvent('#social-twitter', 'Referrals Index - Twitter Share Click');
      UC.utils.recordKissClickEvent('#social-gplus', 'Referrals Index - Google Plus Share Click');
      UC.utils.recordKissClickEvent('#social-linkedin', 'Referrals Index - LinkedIn Share Click');
      new UC.components.InputWithCopyButton({inputId: 'referral-url'});
    },

    inviteEmails: function(e) {
      e.preventDefault();

      var $btn = this.$el.find('#btnSubmitInviteEmails');
      var $emails = this.$el.find('#emails');
      var emails = $emails.val();

      if (!emails) {
        $emails.closest('.input-group').addClass('has-error');
        return false;
      } else {
        $emails.closest('.input-group').removeClass('has-error');
      }

      $btn.addClass('.disabled').attr('disabled', true);

      UC.net.post(UC.constants.referrals_path, { emails: emails }, function(result) {
        UC.utils.recordKissEvent('Referrals Index - Emails Invited', {
          emails: emails
        });
        UC.utils.showNotification('Invites were successfully sent.', UC.constants.results.success);
        this.$el.find('#referral-list').replaceWith(result.html);
        $emails.val('');
      }.bind(this)).always(function() {
        $btn.removeClass('.disabled').attr('disabled', false);
      });
    },

    selectAll: function(e) {
      var $el = $(e.currentTarget);

      $el.select();

      // Work around Chrome's little problem
      $el.mouseup(function() {
        // Prevent further mouseup intervention
        $el.unbind("mouseup");
        return false;
      });
    },

    learnMore: function(e) {
      e.preventDefault();
      this.$el.find('#modalLearnMore').modal();
    }
  });

}();
