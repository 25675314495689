UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.AddTimesheetRowButtonView = Backbone.View.extend({

  initialize: function(opts) {
    this.tableView = opts.tableView;
  },

  events: {
    'click #add-ts-row' : 'addTimesheetRowButtonClick'
  },

  tagName: 'div',

  id: 'new-timesheet-row-btn-container',

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/add_timesheet_row_button"),

  render: function() {
    this.$el.html(this.template(this));
  },

  addTimesheetRowButtonClick: function(e) {
    e.preventDefault();

    this.collection.createNewModel();
  }

});
