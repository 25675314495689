import TextLengthCounter from '../../../components/text_length_counter'

UC.partials.PAJQuestion = Backbone.View.extend({

  events: {
    'change input, select' : 'selectionChanged',
    'click .fancy-tile-container' : 'selectionChanged',
    'submit' : 'submitHandler'
  },

  initialize: function(options) {
    this.submitOnChange = this.$el.data('submit-on-change');
    this.nextButtonCallback = options.nextButtonCallback;
    this.required = this.$el.data('required');
    this.type = this.$el.data('type');
    this.questionId = this.$el.data('id');
    this.validationType = this.$el.data('validation-type');
    this.$el.find('select').select2({placeholder: 'Select...'});
    this.questionAnsweredClass = "js-question-answered";

    var baseSelector = ".job-question[data-type='{0}']:not(.vertical):visible";
    var radioSelector = baseSelector.format(UC.constants.QuestionableType.RadioButton);
    var checkboxSelector = baseSelector.format(UC.constants.QuestionableType.CheckBox);
    var $questions = $(radioSelector + ", " + checkboxSelector);
    this.makeShortQuestionsHorizontal($questions);

    this.elements = {
      chkNonDirectHire: $('#chkNonDirectHire'),
      sortableQuestion: this.$el.find("#js-sortable")
    };

    var $sortableQuestion = this.elements.sortableQuestion;
    if ($sortableQuestion.length) {
      this.sortableHandler($sortableQuestion.get(0));
    }

    if (this.elements.chkNonDirectHire.length > 0) {
      this.elements.chkNonDirectHire.fancyCheck({
        label:  this.elements.chkNonDirectHire.attr('label'),
        checked: UC.constants.otherProposals
      });
    }

    if (this.questionId === UC.constants.jobDescriptionQuestionId) {
      this.descriptionLengthCounter = new TextLengthCounter({
        el: this.$el,
        errorContainer: '.row',
        textarea: 'textarea.js-description-textarea',
        counter: '.js-description-length'
      })
    }

    this.applyStashedData();
  },

  isDescriptionQuestion: function() {
    return this.questionId === UC.constants.jobDescriptionQuestionId;
  },

  sortableHandler: function(sortableElement) {
    // Utilizes external Sortable library instead of JQuery UI because it accounts
    // for touch events on mobile and allows for drag and drop.
    Sortable.create(sortableElement, {
      sort: true,
      ghostClass: "sortable__placeholder",
      forceFallback: true,
      fallbackClass: "sortable__fallback",
      onUpdate: this.markQuestionAnswered.bind(this)
    });
  },

  markQuestionAnswered: function() {
    if (!this.$el.hasClass(this.questionAnsweredClass)) {
      this.$el.addClass(this.questionAnsweredClass);
    }
  },

  submitHandler: function(e) {
    e.preventDefault();

    this.nextButtonCallback.call();
  },

  makeShortQuestionsHorizontal: function($questions){
    $questions.each(function(){
      var $question = $(this),
          maxWidth = $question.find('ul').innerWidth(),
          sum = 0.0,
          $answers = $question.find('li');

      $answers.each(function(){
        sum += Math.ceil(this.getBoundingClientRect().width);
      });

      if (sum > maxWidth){
        $question.addClass('vertical');
      } else {
        var balance = maxWidth - sum,
            padding = Math.floor(Math.floor((balance/$answers.length)*10)/10);
        $answers.find('label').css('padding-right', '+={0}'.format(padding));
      }
    });
  },

  selectionChanged: function(e) {
    if (this.type === UC.constants.QuestionableType.Tile) {
      var $target = $(e.target);
      this.$el.removeClass('has-error');
      this.$el.find('.fancy-tile-container').removeClass('selected');
      $target.closest('.fancy-tile-container').addClass('selected');
    }

    if (this.submitOnChange) {
      this.nextButtonCallback.call();
    }
  },

  renderConfirmationModal: function(callback) {
    var title = "Are you sure?";
    var text = "You have not made any changes to these items. Are you sure you want to proceed?";
    var options = { noText: "Cancel", yesText: "Confirm" };

    UC.utils.showConfirmModal(title, text, callback, options);
  },

  sendUnansweredQuestionTrackingEvent: function(action) {
    var trackingEventName = "paj_unanswered_question_prompt";
    var trackingAttributes = { job_lead_id: UC.constants.jobLeadId, action: action };

    UC.utils.recordKissEvent(trackingEventName, trackingAttributes);
  },

  handleUnansweredQuestion: function(question, dfd, data) {
    question.sendUnansweredQuestionTrackingEvent("shown")

    var callback = function(accept) {
      if (accept) {
        question.sendUnansweredQuestionTrackingEvent("confirmed")

        dfd.resolve(data);
      } else {
        question.markQuestionAnswered();
        question.sendUnansweredQuestionTrackingEvent("canceled")

        dfd.reject();
      }
    }

    this.renderConfirmationModal(callback);
  },

  stashData: function() {
    if (!this.isDescriptionQuestion()) {
      return
    }

    this.putLocalStorageValue($('textarea.js-description-textarea').val());
  },

  applyStashedData: function() {
    if (!this.isDescriptionQuestion()) {
      return
    }

    const stashedValue = this.fetchLocalStorageValue();
    if (stashedValue) {
      $('textarea.js-description-textarea').val(stashedValue);
      this.descriptionLengthCounter.update();
    }
  },

  putLocalStorageValue: function(value) {
    try {
      localStorage.setItem(this.questionId, value)
    } catch(e) {
      console.warn('Failed to write data to localStorage', e);
    }
    return undefined;
  },

  fetchLocalStorageValue: function() {
    try {
      const value = localStorage.getItem(this.questionId) || "";
      localStorage.removeItem(this.questionId)
      return value;
    } catch(e) {
      console.warn('Failed to read data from localStorage', e);
    }
    return undefined;
  },

  validate: function(dfd) {
    this.$el.removeClass('has-error');

    var errors = [];
    var data = this.$el.serializeJSON();
    var val = Object.keys(data).length > 0 ? data.questionnaire[this.questionId] : null;
    if ('string' == typeof val) val = val.trim();
    var answered = false;

    if (this.elements.chkNonDirectHire.length > 0) {
      data.OtherAttorneyProposals = this.elements.chkNonDirectHire.hasClass('checked');
    }

    if (val && val.length > 0) { answered = true }

    if (this.required && !answered) {
      errors.push({element: this.$el, text: 'Required Question'});
    } else if (answered) {
      // only support single answer values right now
      var value = Array.isArray(val) ? val[0] : val;

      switch(this.validationType) {
        case 'phone_number':

          if (!UC.utils.validatePhone(value)) {
            errors.push({element: this.$el, text: 'Please enter a valid phone number'});
          }
          break;
      }
    } else {
      // need to send an empty value for skips
      data.questionnaire = {};
      data.questionnaire[this.questionId] = null;
    }

    if (this.questionId === UC.constants.jobDescriptionQuestionId && !this.descriptionLengthCounter.hasValidTextLength()) {
      errors.push(this.descriptionLengthCounter.getError());
    }

    var isSortableQuestion = this.elements.sortableQuestion.length
    var isAnswered = this.$el.hasClass(this.questionAnsweredClass)

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      dfd.reject();
    } else if (isSortableQuestion && !isAnswered) {
      this.handleUnansweredQuestion(this, dfd, data);
    } else {
      dfd.resolve(data);
    }
  }

});
