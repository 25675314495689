UC.PajHelpers = {
  isUserRestricted: function() {
    if (!UC.user) {
      return false;
    }
    if (UC.user.collections === true) {
      this.showInCollections();
      return true;
    } else if (UC.user.payment_dispute) {
      this.showPaymentDisputeClientRedirect();
      return true;
    } else {
      return false;
    }
  },

  showInCollections: function() {
    function onConfirm(response) {
      $('.btn.btn-success').buttonLoader().showLoader();
      if (response) {
        location.href = url;
      }
    };

    var modalOptions = { dismissOnYes: false, yesText: 'OK', noText: '' },
      title = 'Please Pay Your Outstanding Invoices',
      url = '/dashboard#awaiting-payment-invoices',
      text = "Sorry, you cannot publish a new job in the marketplace until you" +
        " have submitted payment for your outstanding invoice(s). You can" +
        " view and pay your outstanding invoices from the <a href='" + url +
        "'>Dashboard</a>.<br><br>If you have any questions, please contact" +
        " our Marketplace Team at <a href='mailto:marketplace@upcounsel.com'>" +
        "marketplace@upcounsel.com</a>.";

    UC.utils.recordKissEvent('collections_client_notification_shown_paj_page', {userId: UC.user.id});
    UC.utils.showConfirmModal(title, text, onConfirm, modalOptions);
  },

  showPaymentDisputeClientRedirect: function() {
    new UC.components.PaymentDisputeClientRedirectModal().show();
  },
};
