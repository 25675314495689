UC.partials.AccountIdentityVerification = Backbone.View.extend({
  el: '#account-verification',

  events: {
    'change input:radio[name=rdEntityType]': 'onChangeEntityType',
    'click #btnSaveIdentitySsn': 'onSubmitSsn',
    'click #btnSaveIdentityAddress': 'onSubmitAddress',
    'click #btnSaveTosAcceptance': 'onSubmitTosAcceptance',
    'click #btnSaveIdentity': 'onSubmit'
  },

  initialize: function () {
    this.$panelBusiness = this.$el.find('#panelBusiness');
    this.$panelIndividual = this.$el.find('#panelIndividual');
    this.$txtBusinessName = this.$el.find('#txtBusinessName');
    this.$txtEin = this.$el.find('#txtEin');
    this.$txtRepFirstName = this.$el.find('#txtRepFirstName');
    this.$txtRepLastName = this.$el.find('#txtRepLastName');
    this.$txtRepDob = this.$el.find('#txtRepDob');
    this.$txtRepSsn = this.$el.find('#txtRepSsn');
    this.$txtFirstName = this.$el.find('#txtFirstName');
    this.$txtLastName = this.$el.find('#txtLastName');
    this.$txtDob = this.$el.find('#txtDob');
    this.$txtSsn = this.$el.find('#txtSsn');
    this.$txtBusinessAddress = this.$el.find('#txtBusinessAddress');
    this.$txtBusinessCity = this.$el.find('#txtBusinessCity');
    this.$selectBusinessState = this.$el.find('#selectBusinessState');
    this.$txtBusinessZip = this.$el.find('#txtBusinessZip');

    this.tosCheckBox = new UC.partials.StripeTosAcceptedCheckbox({
      el: this.$el
    });

    this.$btnSaveIdentityLoader = this.$el.find('#btnSaveIdentity').buttonLoader();
    this.$btnSaveIdentityAddressLoader = this.$el.find('#btnSaveIdentityAddress').buttonLoader();
    this.$btnSaveIdentitySsnLoader = this.$el.find('#btnSaveIdentitySsn').buttonLoader();
    this.$btnSaveTosAcceptanceLoader = this.$el.find('#btnSaveTosAcceptance').buttonLoader();

    this.$rdEntityType = this.$el.find('input:radio[name=rdEntityType]');

    this.$el.find('.datepicker').datepicker(this.datepickerOptions());
    this.$selectBusinessState.select2();

    this.$txtBusinessName.focus();
  },

  datepickerOptions: function () {
    return {
      dateFormat: 'mm/dd/yy',
      changeMonth: true,
      changeYear: true,
      yearRange: "1900:+nn",
      maxDate: "0"
    };
  },

  entityTypeValue: function () {
    return this.$rdEntityType.filter(':checked').val();
  },

  saveDetails: function (data, url) {
    var message = {verification: data};
    if (UC.constants.nextUrl) {
      message.nextUrl = UC.constants.nextUrl;
    }

    url = url || '/account/save_identity_verification';

    UC.net.post(url, message, null, {
      loaders: [
        this.$btnSaveIdentityLoader,
        this.$btnSaveIdentityAddressLoader,
        this.$btnSaveIdentitySsnLoader,
        this.$btnSaveTosAcceptanceLoader
      ]
    });
  },

  validateBusinessSsn: function () {
    var data = {ssn: this.$txtSsn.val()};
    var errors = [];

    // will now handle ssn formatted with hyphens, spaces, or neither
    if (!/^\d{3}(-|\s)?\d{2}(-|\s)?\d{4}$/.test(data.ssn)) {
      errors.push({element: this.$txtSsn, text: 'Invalid social security number'});
      UC.utils.showValidationErrors(errors);
      return false;
    }

    return data;
  },

  validateBusinessAddress: function () {
    var data = {
        address: this.$txtBusinessAddress.val(),
        city: this.$txtBusinessCity.val(),
        state: UC.utils.findSelectedElement(this.$selectBusinessState).val(),
        zip: this.$txtBusinessZip.val()
      },
      errors = [];


    if (data.address == '') {
      errors.push({element: this.$txtBusinessAddress, text: 'Missing address'});
    }

    if (data.city == '') {
      errors.push({element: this.$txtBusinessCity, text: 'Missing city'});
    }

    if (data.state == '' || data.state == UC.constants.emptySelectValue) {
      errors.push({element: this.$selectBusinessState, text: 'Missing state'});
    }

    if (!UC.utils.validateZipCode(data.zip)) {
      errors.push({element: this.$txtBusinessZip, text: 'Invalid zip code'});
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      return false;
    }

    return data;
  },

  validateBusiness: function () {
    var data = {
        businessName: this.$txtBusinessName.val(),
        address: this.$txtBusinessAddress.val(),
        city: this.$txtBusinessCity.val(),
        state: UC.utils.findSelectedElement(this.$selectBusinessState).val(),
        zip: this.$txtBusinessZip.val(),
        firstName: this.$txtRepFirstName.val(),
        lastName: this.$txtRepLastName.val(),
        dateOfBirth: this.$txtRepDob.val()
      },
      errors = [];

    if (UC.constants.countryCode == 'US') {
      data.ein = this.$txtEin.val();
      data.ssn = this.$txtRepSsn.val();
    }

    if (data.businessName == '') {
      errors.push({element: this.$txtBusinessName, text: 'Missing business name'});
    }

    if (UC.constants.countryCode == 'US' && !/^\d\d?-\d{7}$/.test(data.ein)) {
      errors.push({element: this.$txtEin, text: 'Invalid EIN'});
    }

    if (data.address == '') {
      errors.push({element: this.$txtBusinessAddress, text: 'Missing address'});
    }

    if (data.city == '') {
      errors.push({element: this.$txtBusinessCity, text: 'Missing city'});
    }

    if (data.state == '' || data.state == UC.constants.emptySelectValue) {
      errors.push({element: this.$selectBusinessState, text: 'Missing state'});
    }

    if (!UC.utils.validateZipCode(data.zip, UC.constants.countryCode)) {
      errors.push({element: this.$txtBusinessZip, text: 'Invalid zip code'});
    }

    if (data.firstName == '') {
      errors.push({element: this.$txtRepFirstName, text: 'Missing first name'});
    }

    if (data.lastName == '') {
      errors.push({element: this.$txtRepLastName, text: 'Missing last name'});
    }

    if (data.dateOfBirth == '') {
      errors.push({element: this.$txtRepDob, text: 'Missing date of birth'});
    }
    else {
      var momentDob = moment(data.dateOfBirth, UC.constants.momentDateFormats, true);

      if (!momentDob.isValid()) {
        errors.push({element: this.$txtRepDob, text: 'Invalid date of birth'});
      }
      else {
        data.dateOfBirth = momentDob.format();
      }
    }

    if (UC.constants.countryCode == 'US' && !/^\d{4}$/.test(data.ssn)) {
      errors.push({element: this.$txtRepSsn, text: 'Invalid 4 digits for SSN'});
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, {className: 'js-input'});
      return false;
    }

    return data;
  },

  validateIndividual: function () {
    var data = {
          firstName: this.$txtFirstName.val(),
          lastName: this.$txtLastName.val(),
          dateOfBirth: this.$txtDob.val(),
        },
        errors = [];

    if (UC.constants.countryCode == 'US') {
      data.ssn = this.$txtSsn.val();
    }

    if (data.firstName == '') {
      errors.push({element: this.$txtFirstName, text: 'Missing first name'});
    }

    if (data.lastName == '') {
      errors.push({element: this.$txtLastName, text: 'Missing last name'});
    }

    if (data.dateOfBirth == '') {
      errors.push({element: this.$txtDob, text: 'Missing date of birth', className: 'js-input'});
    }
    else {
      var momentDob = moment(data.dateOfBirth, UC.constants.momentDateFormats, true);

      if (!momentDob.isValid()) {
        errors.push({element: this.$txtDob, text: 'Invalid date of birth', className: 'js-input'});
      }
      else {
        data.dateOfBirth = momentDob.format();
      }
    }

    if (UC.constants.countryCode == 'US' && !/^\d{4}$/.test(data.ssn)) {
      errors.push({element: this.$txtSsn, text: 'Invalid 4 digits for SSN', className: 'js-input'});
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, {className: 'js-input'});
      return false;
    }

    return data;
  },

  onChangeEntityType: function () {
    UC.utils.hideValidationErrors('js-input');
    if (this.entityTypeValue() == 'business') {
      UC.utils.hideComponent(this.$panelIndividual);
      UC.utils.showComponent(this.$panelBusiness);
    }
    else {
      UC.utils.hideComponent(this.$panelBusiness);
      UC.utils.showComponent(this.$panelIndividual);
    }
  },


  onSubmit: function () {
    UC.utils.hideValidationErrors('js-input');
    var data;
    var entityType = this.entityTypeValue();

    if (entityType == 'business') {
      data = this.validateBusiness();
    } else {
      data = this.validateIndividual();
    }

    if (data && this.tosCheckBox.isMissingOrChecked()) {
      data.entityType = entityType;
      this.saveDetails(data);
    }
  },

  onSubmitAddress: function () {
    UC.utils.hideValidationErrors();

    var data = this.validateBusinessAddress();

    if (data && this.tosCheckBox.isMissingOrChecked()) {
      data.entityType = 'business';
      this.saveDetails(data, '/account/save_identity_verification_address');
    }
  },

  onSubmitSsn: function () {
    UC.utils.hideValidationErrors();

    var data = this.validateBusinessSsn();

    if (data && this.tosCheckBox.isMissingOrChecked()) {
      data.entityType = 'business';
      this.saveDetails(data, '/account/save_identity_verification_ssn');
    }
  },

  onSubmitTosAcceptance: function () {
    UC.utils.hideValidationErrors();

    if (this.tosCheckBox.isMissingOrChecked()) {
      this.saveDetails({}, '/account/stripe_tos/accept');
    }
  }
});
