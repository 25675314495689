UC.views.AccountPracticeLocation = Backbone.View.extend({

  events: {
    'submit form#practice-location' : 'submit'
  },

  initialize: function () {
    this.$form = this.$el.find('form#practice-location');
    this.$submitBtn = this.$el.find('#savePracticeLocation');
    this.$address = this.$el.find('#txtAddress');
    this.$city = this.$el.find('#txtCity');
    this.$zip = this.$el.find('#txtZipCode');
    this.$state = this.$el.find('#selectState');
    this.$country = this.$el.find('#selectCountry');

    this.$state.select2();
    this.$country.select2();

    this.practiceMarkers = [];
    this.btnLoader = this.$submitBtn.buttonLoader();
    this.bindLocationAutocomplete();

    if (location.hash == '#checklist') {
      this.$submitBtn.text('Save & Continue');
    }
  },

  generateMapData: function () {
    var mapData = {};

    if (UC.constants.practiceLocation && UC.constants.practiceLocation.Loc) {
      mapData['location'] = new google.maps.LatLng(UC.constants.practiceLocation.Loc[1], UC.constants.practiceLocation.Loc[0])
      mapData['mapOptions'] = {
        zoom: 12,
        center: mapData['location']
      }
    } else {
      var defaultCenter = new google.maps.LatLng(39.50, -98.35);
      mapData['mapOptions'] = {
        zoom: 3,
        center: defaultCenter
      }
    }

    return mapData;
  },

  addMarkerToMap: function (loc) {
    this.clearPracticeMarkers();

    var marker = new google.maps.Marker({
      position: loc,
      map: this.$practiceMap
    });

    this.practiceMarkers.push(marker);
  },

  bindLocationAutocomplete: function () {
    this.$autocompleteInput = new google.maps.places.Autocomplete(this.$address[0]);

    google.maps.event.addListener(this.$autocompleteInput, 'place_changed', function () {
      this.autocompleteResult = this.$autocompleteInput.getPlace();
      if (this.autocompleteResult && this.autocompleteResult.address_components) {
        this.fillInAddress();
      }
    }.bind(this));
  },

  fillInAddress: function () {
    var address = this.createAddressObject();
    delete this.address;
    this.$city.val('');
    this.$zip.val('');
    this.$state.val('').trigger('change');

    if (this.validatePracticeLocationAutocomplete(address)) {
      this.$address.val(address['full_street']);

      // UK uses `postal_town` as `city`
      this.$city.val(address['locality'] || address['postal_town']);

      if (address.country === 'United States') {
        this.$state.val(address['administrative_area_level_1']['short_name']).trigger('change');
        this.$zip.val(address['postal_code']);
      }

      var countrySelector = "#selectCountry option:contains('" + address.country + "')";
      var val = this.$el.find(countrySelector).val();
      this.$country.val(val).trigger('change');
      if (address.country === 'United States') {
        this.submit();
      }
    }
  },

  validatePracticeLocationAutocomplete: function (address) {
    var errors = [];
    $('.row').removeClass('has-error');

    if (this.checkIfPOBox(this.autocompleteResult.name)) {
      errors.push({element: this.$address, text: 'Address cannot be a P.O. Box'});
    }

    if (!address['full_street']) {
      errors.push({element: this.$address, text: 'Please enter a valid address'});
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors, {scrollToFirst: true});
      return false
    }

    return true
  },

  validateZipCode: function (geolocation) {
    var zip = this.$zip.val();
    var onComplete = function (res) {
      this.btnLoader.hideLoader();

      if (res.status >= 400) {
        UC.utils.showValidationErrors([{
          className: 'form-group',
          element: this.$zip,
          text: 'Please enter a valid zip code.'
        }]);
      } else {
        UC.utils.clearValidationErrors('form-group');
        this.geocodeLocationReceived(geolocation);
      }
    }.bind(this);

    // This is not guaranteed and we could potentially be getting undefined due to invocations to delete this.address.
    if (!this.address) {
      return this.btnLoader.hideLoader();
    }

    if (this.address.CountryCode === 'USA') {
      UC.net.get('/account/valid_zip_code/' + zip).complete(onComplete);
    } else {
      // Need to call this to proceed with saving the location,
      //  otherwise hanging loader
      this.geocodeLocationReceived(geolocation);
    }
  },

  checkIfPOBox: function (address) {
    address = address.toLowerCase().replace(/\.| /g, '');
    return address.indexOf('pobox') > -1;
  },

  submit: function (e) {
    if (e) e.preventDefault();

    if (this.validatePracticeLocation()) {
      this.btnLoader.showLoader();

      this.geocodeLocation(function (geolocation) {
        this.validateZipCode(geolocation);
      }.bind(this));
    }
  },

  validatePracticeLocation: function () {
    UC.utils.clearValidationErrors('form-group');
    var errors = [];
    this.address = this.$form.serializeJSON();

    if (!this.address.Street) {
      errors.push({
        className: 'form-group',
        element: this.$address,
        text: 'Please enter your practice address.'
      });
    }

    if (!this.address.City) {
      errors.push({
        className: 'form-group',
        element: this.$city,
        text: 'Please enter your practice city.'
      });
    }

    if (!this.address.CountryCode) {
      errors.push({
        className: 'form-group',
        element: this.$country,
        text: 'Please enter your practice country.'
      });
    } else if (this.address.CountryCode === 'USA') {

      if (this.checkIfPOBox(this.address.Street)) {
        errors.push({
          className: 'form-group',
          element: this.$address,
          text: 'Address cannot be a P.O. Box'
        });
      }

      if (!this.address.State) {
        errors.push({
          className: 'form-group',
          element: this.$state,
          text: 'Please select your practice state.'
        });
      }

      if (!this.address.ZipCode) {
        errors.push({
          className: 'form-group',
          element: this.$zip,
          text: 'Please enter your practice zip code.'
        });
      }

    } else {
      delete this.address.ZipCode;
      delete this.address.State;
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      delete this.address;
      return false;
    } else {
      UC.utils.clearValidationErrors('form-group');
      return true;
    }
  },

  geocodeLocation: function (callback) {
    var geocoder = new google.maps.Geocoder();
    var address = this.getFullAddress();
    geocoder.geocode({ 'address': address}, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        callback.call(this, results[0].geometry.location);
      } else {
        this.btnLoader.hideLoader();
      }
    }.bind(this));
  },

  getFullAddress: function () {
    var address = this.address.Street;
    address += ", " + this.address.City;

    if (this.address.CountryCode === 'USA') {
      address += ", " + this.address.State;
      address += ", " + this.address.ZipCode;
    }

    var countryName = this.$country.find('option:selected').text();
    address += ", " + countryName;

    return address
  },

  geocodeLocationReceived: function (geolocation) {
    this.address = this.address || {};
    var lng = geolocation.lng();
    var lat = geolocation.lat();

    if (lng && lat) {
      this.address.Loc = [geolocation.lng(), geolocation.lat()];
    }

    this.savePracticeLocation();
  },

  savePracticeLocation: function () {
    UC.net.post('/account/save_practice_location', this.address, this.onSavePracticeLocation.bind(this)).always(function () {
      this.btnLoader.hideLoader();
    }.bind(this));
  },

  onSavePracticeLocation: function (res) {
    var practiceLocation = new google.maps.LatLng(res.newPracticeLocation.Loc[1], res.newPracticeLocation.Loc[0]);
    this.addMarkerToMap(practiceLocation);
    UC.utils.showNotification(res.message, UC.constants.results.success);

    if (location.hash === '#checklist') {
      location.href = '/dashboard';
    }
  },

  clearPracticeMarkers: function () {
    for (var i = 0; i < this.practiceMarkers.length; i++) {
      this.practiceMarkers[i].setMap(null);
    }
    this.practiceMarkers = []
  },

  createAddressObject: function () {
    var addressArr = this.autocompleteResult.address_components;
    var addressObj = {};

    for (var i = 0; i < addressArr.length; i++) {
      if (addressArr[i].types[0] === 'administrative_area_level_1') {
        addressObj[addressArr[i].types[0]] = addressArr[i]
      }
      else {
        addressObj[addressArr[i].types[0]] = addressArr[i].long_name
      }
    }

    if (addressObj['street_number']) {
      addressObj['full_street'] = addressObj['street_number'] + ' ' + addressObj['route'];
    }
    else {
      addressObj['full_street'] = addressObj['route'];
    }

    return addressObj;
  }

});
