UC.views.DashboardJobsList = Backbone.View.extend({

  initialize: function() {
    this.jobsList = new UC.views.JobsList({
      el: '#recent-jobs',
      updateUrl: '/dashboard/jobs'
    });
  }

});
