!function() {
  UC.views.SystemMessagesIndex = Backbone.View.extend({
    events: {
      'click .system-message': 'show'
    },

    initialize: function() {
      var id = UC.utils.getParameterByName('id');

      if (id) {
        this.popDatWindoe(id);
      }
    },

    show: function(e) {
      e.preventDefault();
      var id = $(e.currentTarget).data('id');
      this.popDatWindoe(id);
    },

    markAsRead: function(id, handleSuccess) {
      var link = this.$el.find('.js-markAsRead[data-id={0}] a'.format(id))
      if (link.hasClass('text-primary')) {
        UC.components.Notifications.mark_read(id);
        link.toggleClass('text-primary')
      }

      UC.net.get('/system_messages/{0}'.format(id), handleSuccess)
    },

    popDatWindoe: function(id) {
      var $el = this.$el.find('.system-message[data-id="{0}"]'.format(id));

      this.markAsRead(id, function(msg) {
        this.$el.find('#modalMessageSubject').text(msg.Subject);
        this.$el.find('#modalMessageDate').text(moment(msg.DateSent).format('L LT'));
        this.$el.find('#modalMessageBody').html(msg.Message);

        this.$el.find('#modalSystemMessage').modal('show');
      }.bind(this));
    }
  });
}();
