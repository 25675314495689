!function() {

  UC.components.BillingSummary = Backbone.View.extend({

    events: {
      'click #btnAddCoupon' : 'addCouponClick',
      'click #aRemoveCoupon' : 'removeCouponClick',
      'click #btnAddUserCoupon' : 'addUserCouponClick'
    },

    initialize: function() {
      this.loader = this.$el.find('#btnAddCoupon').buttonLoader();
      this.referralLoader =  this.$el.find('#btnAddUserCoupon').buttonLoader();
      this.initFeePopovers();
    },

    initFeePopovers: function() {
      new UC.components.FeesPopover({ el: this.$el });
    },

    addCouponClick: function (e) {
      e.preventDefault();

      var code = this.$('#txtCoupon').val().trim();
      var $form_group = this.$('#panelCoupon .form-group');

      $form_group.removeClass('has-error');

      if (code === '') {
        $form_group.addClass('has-error');
      }
      else {
        this.loader.showLoader();
        this.addCoupon(code);
      }
    },

    addCoupon: function (code) {
      var url = '';
      var data = {
        render_billing_summary: true
      };

      if (UC.constants.upfrontPayment) {
        url = '/jobs/{0}/coupon/{1}'.format(UC.job.id, code);
        data.bid_id = UC.bid.id;
      } else {
        url = '/invoices/{0}/coupon/{1}'.format(UC.invoice.id, code);
      }

      UC.net.post(url, data, this.onAddCoupon.bind(this)).always(function(){
        this.loader.hideLoader();
      }.bind(this));
    },

    onAddCoupon: function (res) {
      this.$el.html(res.html);
      this.delegateEvents();
      this.initFeePopovers();
    },

    removeCouponClick: function (e) {
      e.preventDefault();
      this.loader.showLoader();
      var data = {render_billing_summary: true},
          url = '';

      if (UC.constants.upfrontPayment) {
        url = '/jobs/{0}/coupon'.format(UC.job.id, UC.bid.id);
        data.bid_id = UC.bid.id;
      } else {
        url = '/invoices/{0}/coupon'.format(UC.invoice.id);
      }

      UC.net.destroy(url, data, this.onRemoveCoupon.bind(this)).always(function(){
        this.loader.hideLoader()
      }.bind(this));
    },

    onRemoveCoupon: function (res) {
      this.$el.html(res.html);
      this.loader = this.$el.find('#btnAddCoupon').buttonLoader();
      this.referralLoader =  this.$el.find('#btnAddUserCoupon').buttonLoader();
      this.delegateEvents();
    },

    addUserCouponClick: function(e) {
      e.preventDefault();
      this.referralLoader.showLoader();
      this.addCoupon($(e.currentTarget).data('code'));
    }

  });

}();
