!function() {

  UC.views.EmailsIndex = Backbone.View.extend({

    events: {
      'submit form.new_email': 'createEmail'
    },

    initialize: function() {
      this.emails = UC.constants.emails;
      this.render();
    },

    render: function() {
      _.each(this.emails, this.addRow.bind(this));
    },

    createEmail: function(e) {
      e.preventDefault();

      var data = $(e.target).serializeJSON();

      UC.net.post(window.location, data, function(email) {
        var message = 'We sent a verification email to {0}. Please follow the instructions in it.'.format(email.Email);
        this.$el.find('#user_email_Email').val('');
        this.addRow(email);
        UC.utils.showNotification(message, UC.constants.results.success);
      }.bind(this));

      return false
    },

    addRow: function(email) {
      var view = new UC.views.EmailsRow({ email: email });
      this.$el.find('#emails').append(view.render().el);
    }
  });
}();
