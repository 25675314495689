var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":43},"end":{"line":15,"column":79}}})) != null ? stack1 : "")
    + ">\n              "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"display") : depth0), depth0))
    + "\n            </option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form>\n  <div id=\"availability-range-groups\"></div>\n\n  <hr>\n\n  <div>\n    This prevents calls from being scheduled at the last minute.\n\n    <div class='marg-t-15 flex flex-align-center'>\n      <span>Require at least</span>\n\n      <div class='marg-x-10'>\n        <select name=\"AdvanceNoticeBuffer\" class=\"form-control js-advance-notice-dropdown\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"advanceNoticeBufferOptions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":10},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n      </div>\n\n      <span class='hidden-xs'>advance notice.</span>\n    </div>\n  </div>\n\n  <hr>\n\n  <div class=\"row\">\n    <div class=\"col-lg-2 col-lg-offset-10 col-md-3 col-md-offset-9 col-sm-12\">\n      <button type=\"submit\" id=\"submit-time-ranges\" class=\"btn btn-md btn-primary full-width pull-right\">\n        Save\n      </button>\n    </div>\n  </div>\n</form>\n";
},"useData":true});