!function() {

  UC.views.JobsTimesheet = Backbone.View.extend({

    events: {
      'click #js-current-timesheets-tab' : 'renderCurrentTab',
      'click #js-invoiced-timesheets-tab' : 'renderInvoicedTab'
    },

    initialize: function() {
      this.tab = UC.utils.getParameterByName('tab') == UC.views.JobsTimesheet.invoicedTab ? UC.views.JobsTimesheet.invoicedTab : UC.views.JobsTimesheet.currentTab;
      this.retrieveData();
    },

    renderCurrentTab: function () {
      this.tab = UC.views.JobsTimesheet.currentTab;
      this.retrieveData();
    },

    renderInvoicedTab: function () {
      this.tab = UC.views.JobsTimesheet.invoicedTab;
      this.retrieveData();
    },

    isTabCurrentTimesheets: function () {
      return this.tab != UC.views.JobsTimesheet.invoicedTab;
    },

    retrieveData: function() {
      var options = { jobId: UC.job.id };
      if (this.isTabCurrentTimesheets()) {
        options.filterOptions = { current_line_items: true };
      } else {
        options.invoiced = true;
      }

      this.collection = new UC.timesheetComponent.TimesheetLineItems(null, options);

      this.renderTab();
    },

    renderTab: function() {
      history.pushState({}, 'ts_entries', '?tab=' + this.tab);

      this.timesheetTableView = new UC.timesheetComponent.TimesheetTableView({
        collection: this.collection,
        allowNewTimesheetLines: true,
        showDeleteColumn: true,
        showTypeSelect: true,
        allowNewTimesheetLines: this.isTabCurrentTimesheets(),
        showDeleteColumn: this.isTabCurrentTimesheets(),
        tab: this.tab
      });
      $('#timesheet-line-item-container').html(this.timesheetTableView.$el);

      this.timesheetTotalsView = new UC.timesheetComponent.TimesheetTotalsView({ collection: this.collection });
      this.timesheetTotalsView.render();
      $('#timesheet-totals-container').html(this.timesheetTotalsView.$el);

      if (this.isTabCurrentTimesheets()) {
        this.createTimesheetInvoiceButtonView = new UC.timesheetComponent.CreateTimesheetInvoiceButtonView({
          collection: this.collection
        });

        this.createTimesheetInvoiceButtonView.render();
        $('#create-ts-inv-btn-container').html(this.createTimesheetInvoiceButtonView.$el);
      } else {
        $('#create-ts-inv-btn-container').empty();
      }

      this.timesheetTabsView = new UC.timesheetComponent.TimesheetTabsView({ tab: this.tab, currentTab: UC.views.JobsTimesheet.currentTab });
      this.timesheetTabsView.render();
      $('#timesheet-tabs-container').html(this.timesheetTabsView.$el);

      this.collection.on('reset', this.render.bind(this));
    },

    introHtml: function() {
      return UC.views.resolveHandlebarsTemplate("components/timesheets/timesheet_intro")();
    },
    
    render: function() {
      if (this.collection.showIntro) this.$('#timesheet-intro-container').html(this.introHtml());
    }
  },
  {
    currentTab: 'current',
    invoicedTab: 'invoiced'
  });
}();
