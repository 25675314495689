import Snap from 'snapjs'

!function() {

  function initMobileNav() {

    var $doc = $(document);
    var $body = $('body');
    var $nav = $('#nav');
    var $mobile_nav = $('#mobile-nav');
    const $mobile_toc = $body.find('.dropdown.mobile-toc');
    var is_fixed_nav = $nav.css('position') === 'fixed';
    var speed = 0.3;
    let closing = false;

    var snapper = window.snapper = new Snap({
      element: document.getElementById('content'),
      disable: 'left',
      touchToDrag: false,
      transitionSpeed: speed
    });

    $('#menu-toggle').on('click touchstart', function(e) {
      e.preventDefault();
      e.stopPropagation();
      
      if (snapper.state().state ===  'closed') {
        snapper.open('right');
      } else {
        snapper.close();
      }
    });

    $mobile_nav.on('touchmove', function(e) {
      e.stopPropagation();

      if (!$mobile_nav.isScrollable()) {
        e.preventDefault();
      }
    });

    // prevent scrolling when menu is open
    snapper.on('open', function() {

      $body.addClass('mobile-nav-open');

      $doc.on('touchmove', function(e) {
        e.preventDefault();
      });

      if (is_fixed_nav) {
        const scrollTop = $(window).scrollTop();
        $nav.css('top', scrollTop);
        $mobile_toc.removeClass('open');
        $mobile_toc.css('top', scrollTop + $nav.height());
      }
    });

    snapper.on('close', function() {
      closing = true;
    });

    snapper.on('animated', function() {
      // we need to wait until menu is closed and
      // snap.js doesn't have a 'closed' event :'(
      if (closing) {
        closing = false;

        $body.removeClass('mobile-nav-open');

        $doc.off('touchmove');

        if (is_fixed_nav) {
          $nav.css('top', 0);
          $mobile_toc.css('top', $nav.height());
          $mobile_nav.trigger('mobile-nav-closed');
        }
      }
    });

    // hack to fix active color on menu items (iOS)
    $('#menu li > a').on('touchstart', function() {});
  };

  $(function() {
    initMobileNav();
  });
}();

