var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <a href=\"#\" id=\"add-ts-lines\" class=\"new-style import-ts-lines fs-11 text-bold enabled\">Import Timesheet</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <a href=\"#\" class=\"new-style import-ts-lines text-grey-15 fs-11 text-bold inactive\"\n     data-toggle=\"tooltip\" data-placement=\"top\" title=\"No timesheet entries are available for import\">Import Timesheet</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"timesheetsAvailableToAdd") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "");
},"useData":true});