$.fn.stickyCssTableHeader = function() {

  var $cssTable = this;
  // If re-running on same table, need to ensure only one sticky-thead is present
  $cssTable.find('#sticky-thead').remove();

  var $cssTableHeader = $cssTable.find('.thead');
  var $stickyHeader = $cssTableHeader.clone();

  $stickyHeader.attr('id', 'sticky-thead');
  $stickyHeader.addClass('hidden');

  var stickyHeaderHeight;

  var $stickyHeaderColumns = $stickyHeader.find('.th');

  $cssTable.prepend($stickyHeader);

  var $incRepFirstRow = $cssTable.find('.tbody .tr').eq(0);

  var setStickyHeaderColumnWidths = function() {
    var columnWidths = $incRepFirstRow.find('.tc').map(function(index, cell) {
      return $(cell).outerWidth();
    });

    $stickyHeaderColumns.each(function(i, th) {
      $(th).css('width', columnWidths[i]);
    });
  };

  var $window = $(window);

  function resetStickyHeader() {
    if (!$cssTableHeader || $cssTableHeader.length === 0) {
      return // in case when table header is not present
    }
    var headerOffset = $cssTableHeader.offset().top;
    var scrollTop = $window.scrollTop();

    if(scrollTop > headerOffset) {
      if ($stickyHeader.is(':visible')) stickyHeaderHeight = $stickyHeader.outerHeight();
      if (scrollTop > (headerOffset + $cssTable.outerHeight() - stickyHeaderHeight)) {
        $stickyHeader.addClass('hidden');
      } else {
        $stickyHeader.removeClass('hidden');
        setStickyHeaderColumnWidths();
      }
    } else if ($window.scrollTop() < headerOffset) {
      $stickyHeader.addClass('hidden');
    }
  }

  $window.on('scroll resize', resetStickyHeader.bind(this));

  setStickyHeaderColumnWidths();

  if (this.is(':visible')) resetStickyHeader();

};
