UC.views.AccountResendActivation = Backbone.View.extend({

  el: 'form#resend-activation',

  events: {
    'ajax:before': 'resendActivationBefore',
    'ajax:success': 'resendActivationSuccess',
    'ajax:complete': 'hideLoader'
  },

  initialize: function() {
    this.components = {};
    this.$email = this.$el.find('#email');
    this.components.resendLoader = this.$el.find('#btnResendActivation').buttonLoader();
  },

  resendActivationBefore: function(e) {
    var email = this.$email.val();

    UC.utils.clearValidationErrors();

    if (!UC.utils.validateEmail(email)) {
      UC.utils.showValidationErrors([{
        element: this.$email,
        text: 'Please enter a valid email address'
      }]);
      return false;
    }

    this.components.resendLoader.showLoader();
  },

  resendActivationSuccess: function(e, data) {
    var message = "An activation message has been sent to your email address. To activate your account, follow the instructions in the email message.";
    this.$el.find('.floating-panel-body').html("<p>{0}</p>".format(message));
  },

  hideLoader: function() {
    this.components.resendLoader.hideLoader();
  }

});
