!function() {

  UC.partials.ReviewsForm = Backbone.View.extend({

    events: {
      'ajax:before #reviewForm': 'validate',
      'ajax:success': 'success',
      'ajax:error #reviewForm': 'formError',
      'change .rating': 'renderAverageRating'
    },

    initialize: function(options) {
      this.options = options || {};

      this.renderAverageRating();
      this.$form = this.$('#reviewForm');
      this.loader = this.$('#btnSubmitReview').buttonLoader();
    },

    renderAverageRating: function() {
      this.$('#ratingAverage').text(this.getAverageRating());
    },

    getAverageRating: function() {
      var sum = 0;
      var count = 0;

      this.$el.find('#ratingDimensions .rating').each(function() {
        var $el = $(this);
        if($el.is("input")) { // v9
          if($el.val() > 0) {
            sum += parseFloat($el.val(), 10);
            count++;
          }
        } else {
          var $checked = $el.find('input:checked');

          if ($checked.length > 0) {
            sum += parseFloat($checked.val(), 10);
            count++;
          }
        }
      });

      var average = (sum / count) || 0;

      // round to two decimal places
      return Math.round(average * 100) / 100;
    },

    buildRatingObject: function() {
      var ratings = {};
      var ratingsElements = this.$el.find('#ratingDimensions .rating');
      var ratingsLength = ratingsElements.length;

      ratingsElements.each(function() {
        var $el = $(this);
        if($el.is("input")) { // v9
          if ($el.val() > 0) {
            ratings[$el.attr('name')] = $el.val();
          } else {
            return false;
          }
        } else {
          var $checked = $el.find('input:checked');

          if ($checked.length > 0) {
            ratings[$el.data('type')] = $checked.val();
          } else {
            return false;
          }
        }
      });

      if (Object.keys(ratings).length === ratingsLength) {
        return ratings;
      }
    },

    validate: function() {
      return this.validateDefaultReview();
    },

    // default review validation, can be overridden
    validateDefaultReview: function() {
      this.$('.has-error').removeClass('has-error');

      var errors = [],
        ratings = this.buildRatingObject(),
        review = this.$form.serializeJSON().review;

      if (review.hasOwnProperty('NonUpcounselClientName') && review.NonUpcounselClientName.trim() === '') {
        errors.push({element: this.$el.find('#review_NonUpcounselClientName'), text: 'Please introduce yourself.'});
      }

      if (review.hasOwnProperty('JobTitle') && review.JobTitle.trim() === '') {
        errors.push({element: this.$el.find('#review_JobTitle'), text: 'Please describe what was your job about.'});
      }

      if (!ratings) {
        errors.push({element: this.$el.find('#ratingDimensions'), text: 'Please rate this attorney on all 4 dimensions.'});
      }

      if (review.DescriptionPublic.trim() === '') {
        errors.push({element: this.$el.find('#review_DescriptionPublic'), text: 'Please write a review.'});
      }

      if(this.$('.review_RecommendAttorneyScore').length != 0 && !this.$('.review_RecommendAttorneyScore').is(':checked')) {
        errors.push({element: this.$('#review_RecommendAttorneyScore_errorMessage'), text: 'Please indicate how likely you are to recommend the attorney.'});
      }

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors, {scrollToFirst: true});
        return false;
      } else {
        this.loader.showLoader();
        return true;
      }
    },

    formError: function() {
      this.loader.hideLoader();
    },

    success: function(e, data) {
      if (data && data.url) {
        window.location = data.url;
      } else {
        var html = $(data).find('#jobBoxReview').html();

        this.$el.html(html);
        UC.utils.initRatingDimensions();
        this.$el.find('.rate-star').starate();
        UC.utils.showNotification('Review submitted successfully', UC.constants.results.success);
      }
    }
  });
}();
