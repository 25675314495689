!function() {
  UC.modals.AttorneyProfileWorkHistoriesForm = Backbone.View.extend({
    el: '#mdlEditWorkHistory',

    elements: {
      'btnAddNewWorkHistory': null,
      'lblNoWorkHistory': null,
      'lnkAddNew': null,
      'template': null,
      'addNewContainer': null,
      'workHistoryContainer' : null
    },

    events: {
      'click #lnkAddNew' : 'showNewForm',
      'click .js-delete' : 'destroy',
      'click .js-edit' : 'showForm',
      'click .js-save-work-history' : 'save',
      'click .js-cancel-work-history' : 'cancel'
    },

    initialize: function(opts) {
      this.updateWorkHistoryPagination = opts.updateWorkHistoryPagination;
    },

    changedIds: {},

    render: function() {
      UC.utils.setSelectors(this.elements);
      this.$el.modal({ backdrop: 'static' });
    },

    showForm: function(e) {
      e.preventDefault();
      if (!this.editingForm) {
        var $history = $(e.currentTarget).closest('.work-history'),
            id = $history.data('id'),
            $form = $history.find('form');

        this.editingForm = $form.clone();
        this.initForm($form);
        $form.change(function() { this.changedIds[id] = true }.bind(this));
      }
    },

    showNewForm: function(e) {
      e.preventDefault();
      if (this.$el.find('#form_new').length === 0) {
        var $template = this.elements.template,
            $form = $template.clone();

        $form.attr('id', 'form_new');
        this.elements.addNewContainer.append($form);
        this.editingForm = true;
        this.elements.lblNoWorkHistory.addClass('hidden');
        this.initForm($form);
      }
    },

    showFormControls: function() {
      this.$el.find('.btn-modal-control').addClass('hidden');
      this.$el.find('.btn-form-control').removeClass('hidden');
    },

    hideFormControls: function() {
      delete this.editingForm;
      this.activeForm = null;
      this.$el.find('.btn-form-control').addClass('hidden');
      this.$el.find('.btn-modal-control').removeClass('hidden');

      if (!this.elements.workHistoryContainer.find('*').length) {
        this.elements.lblNoWorkHistory.removeClass('hidden');
      }
    },

    initForm: function($form) {
      $form.removeClass('hidden');
      $form.find('.datepicker').datepicker({
        dateFormat: 'mm/dd/yy',
        changeMonth: true,
        changeYear: true,
        yearRange: "1940:+nn",
        maxDate: "0"
      });
      $form.find("textarea[name='Description']").charCounter({max: 400});
      $form.find("textarea[name='Description']").trigger('countchars');

      $form.find('.icon-calendar').click(function(e) {
        $(e.target).closest('.input-with-icon-right').find('input').focus();
      });

      $form.find("select[name='set_company']").superSmartSearch({
        url: '/companies/smart_search',
        tags: true,
        modal: $form
      });

      this.setEndDateEnabled(null, $form);
      $form.find("input[name='PresentlyWorkAtCompany']").click(this.setEndDateEnabled.bind(this));

      this.activeForm = $form;
      this.showFormControls();
    },

    setEndDateEnabled: function(e, $form) {
      if (e) $form = $(e.target).closest('form');
      var checked = $form.find("input[name='PresentlyWorkAtCompany']")[0].checked,
          input = $form.find("input[name='EndDate']");

      if (checked) {
        input.attr('disabled', 'disabled');
        input.val(null);
      } else {
        input.removeAttr('disabled');
      }
    },

    cancel: function(e) {
      e.preventDefault();
      var $form = this.activeForm;

      if ($form.attr('id') === 'form_new') {
        $form.remove();
      } else {
        var $history = $form.closest('.work-history'),
          id = $history.data('id');

        if (this.changedIds[id]) {
          $form.replaceWith(this.editingForm);
        } else {
          $form.addClass('hidden');
        }
      }

      this.hideFormControls();
    },

    destroy: function(e) {
      e.preventDefault();

      if (confirm('Are you sure you want to delete this Work History item permanently?')) {
        var $history = $(e.target).closest('.work-history'),
            id = $history.data('id'),
            data = {
              refresh_profile: UC.controller === 'attorney_profile'
            };

        UC.net.destroy('/work_histories/{0}'.format(id), data, function(res) {
          UC.utils.showNotification(res.message, UC.constants.results.success);
          $history.remove();
          if (this.$el.find('.work-history').length === 0) {
            this.elements.lblNoWorkHistory.removeClass('hidden');

            if (UC.controller === 'dashboard' && UC.action === 'index') {
              var $item = $('#btnAddNewWorkHistory').closest('.js-checklist-item'),
                  $icon = $item.find('.js-checklist-item-icon')

              $icon.removeClass('text-success');
              $icon.addClass('text-grey-15');
            }
          }

          if (data.refresh_profile && $('#workHistoryContent').length > 0) {
            $('#workHistoryContent').html(res.profile);
            this.updateWorkHistoryPagination();
          }
        }.bind(this));
      }
    },

    save: function(e) {
      e.preventDefault();
      var $form = this.activeForm,
          formData = $form.serializeJSON(),
          id = $form.attr('id').replace('form_', '');

      if (id !== 'new' && !this.changedIds[id]) {
        $form.addClass('hidden');
        delete this.editingForm;
        return;
      }

      var data = {
        work_history: formData,
        refresh_profile: UC.controller === 'attorney_profile'
      };

      if (this.validate(data.work_history, $form)) {

        if (id === 'new') {
          UC.net.post('/work_histories', data, function(res){
            this.elements.workHistoryContainer.prepend(res.row);
            this.elements.lblNoWorkHistory.addClass('hidden');
            $form.remove();
            this.orderItems();

            if (data.refresh_profile && $('#workHistoryContent').length > 0) {
              $('#workHistoryContent').html(res.profile);
              this.updateWorkHistoryPagination();
            } else if (UC.controller === 'dashboard' && UC.action === 'index'){
              UC.utils.checklistSuccessCallback('btnAddNewWorkHistory');
            }

            this.hideFormControls();
          }.bind(this));

        } else {

          UC.net.put('/work_histories/{0}'.format(id), data, function(res){
            var $html = $(res.row),
                id = $html.data('id'),
                $existing = this.$el.find("[data-id='" + id + "']");

            $existing.replaceWith($html);

            if (data.refresh_profile && $('#workHistoryContent').length > 0) {
              $('#workHistoryContent').html(res.profile);
             this.updateWorkHistoryPagination();
            } else if (UC.controller === 'dashboard' && UC.action === 'index'){
              UC.utils.checklistSuccessCallback('btnAddNewWorkHistory');
            }

            this.orderItems();
            this.hideFormControls();
          }.bind(this));

        }
      }
    },

    orderItems: function() {
      var $wrapper = this.elements.workHistoryContainer;
      $wrapper.find('.work-history').sort(function(a, b) {
        return b.getAttribute('data-unixtime') - a.getAttribute('data-unixtime');
      }).appendTo($wrapper);
    },

    validate: function(data, $form) {
      $form.find('.has-error').removeClass('has-error');
      var errors = [];

      data.Company = data.set_company.trim();
      data.Position = data.Position.trim();
      data.Description = data.Description.trim();

      if (data.Company === null || data.Company === "") {
        errors.push({
          element: $form.find("input[name='set_company']"),
          text: "Company can't be blank"
        });
      }

      if (data.Position === null || data.Position === "") {
        errors.push({
          element: $form.find("input[name='Position']"),
          text: "Position can't be blank"
        });
      }

      if (data.Description === null || data.Description === "") {
        errors.push({
          element: $form.find("textarea[name='Description']"),
          text: "Description can't be blank"
        });
      }

      if (data.StartDate && data.StartDate != '') {
        var start_date = moment(data.StartDate, UC.constants.momentDateFormats, true);

        if (start_date.isValid()) {
          data.StartDate = start_date.format();
        } else {
          errors.push({
            element: $form.find("input[name='StartDate']"),
            text: "Date is invalid"
          });
        }
      }

      if (data.EndDate && data.EndDate != '') {
        var end_date = moment(data.EndDate, UC.constants.momentDateFormats, true);

        if (end_date.isValid()) {
          data.EndDate = end_date.format();
        } else {
          errors.push({
            element: $form.find("input[name='EndDate']"),
            text: "Date is invalid"
          });
        }
      } else {
        data.EndDate = null;
      }

      if (data.StartDate && !data.EndDate && data.PresentlyWorkAtCompany != 'on') {
        errors.push({
          element: $form.find("input[name='EndDate']"),
          text: "You must choose an end date or select that you currently work at this position"
        });
      }

      if (!data.StartDate && (data.EndDate || data.PresentlyWorkAtCompany)) {
        errors.push({
          element: $form.find("input[name='EndDate']"),
          text: "You must select a start date if an end date is chosen"
        });

      }

      delete data.PresentlyWorkAtCompany;
      UC.utils.showValidationErrors(errors, {
        className: 'form-group'
      });

      return errors.length === 0;
    }
  });
}();
