import { default as MultiFactorRequestFrom } from '../multi_factor_auth/_request_form'

UC.views.SessionsMultiFactorRequest = Backbone.View.extend({
    initialize: function() {
        new MultiFactorRequestFrom({
            el: this.$el,
            formUrl: '/sessions/two-factor',
            successCallback: function(response) {
                window.location = response.next_url || '/dashboard';
            }
        });
    }
});
