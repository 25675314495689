import { GeoLocation } from './geo_location'

export const GeoLocationPajWidjet = Backbone.View.extend({
  initialize: function(options) {
    const geoLocationComponent = new GeoLocation();
    const $input = options.input || this.$el.find('.js-geo-location-zip');

    const userChoice = geoLocationComponent.fetchUserChoice();
    if (userChoice) {
      $input.val(userChoice);
    } else {
      geoLocationComponent.findZip(function (response) {
        $input.val(response.zip);
      });
    }

    const $triggeredLink = options.triggeredLink || this.$el.find('.js-new-job');
    if ($triggeredLink.length) {
      $input.on('keypress', function(e) {
        if (e.which === 13) {
          $triggeredLink.click();
        }
      });
    }

    $input.on('keyup', function(e) {
      if (e.which !== 13) {
        geoLocationComponent.storeUserChoice($input.val());
      }
    })
  },
});
