UC.partials.CreditCardAuthorizationModal = Backbone.View.extend({

  el: '#payment-authorization-modal',

  events: {
    'click #btnPaymentAuthorizationAgreed' : 'onAgreeClicked',
    'input #signatureInput' : 'validateSignature',
    'hidden.bs.modal': 'onDismissed'
  },

  initialize: function(options) {
    this.agreedCallback = options.agreedCallback;
    this.dismissedCallback = options.dismissedCallback;
    this.$button = $('#btnPaymentAuthorizationAgreed');
    this.$signature = $('#signatureInput');
    this.agreed = false;
  },

  show: function(cardDetails) {
    this.agreed = false;

    this.$el.find('.authorization-form-card-type').text(
      cardDetails.funding.charAt(0).toUpperCase() + cardDetails.funding.slice(1) + ' Card'
    )
    this.$el.find('.authorization-form-card-brand').text(cardDetails.brand);
    this.$el.find('.authorization-form-card-number').text(cardDetails.number);
    this.$el.find('.authorization-form-card-cvc').text(cardDetails.cvc);
    this.$el.find('.authorization-form-card-exp-month').text(cardDetails.exp_month);
    this.$el.find('.authorization-form-card-exp-year').text(cardDetails.exp_year);
    this.$el.find('.authorization-form-card-holder').text(cardDetails.name);

    this.$el.find('.authorization-form-card-address-street').text(cardDetails.address.street);
    this.$el.find('.authorization-form-card-address-city').text(cardDetails.address.city);
    this.$el.find('.authorization-form-card-address-state').text(cardDetails.address.state);
    this.$el.find('.authorization-form-card-address-zip').text(cardDetails.address.zip);
    this.$el.find('.authorization-form-signature-hint').hide()

    this.$button.removeClass('btn-primary').addClass('btn-white')
    this.$signature.val('')

    this.$el.modal('show');
    this.$el.find('.terms').scrollTop(0);
  },

  onAgreeClicked: function() {
    var signatureText = this.$signature.val();
    if (signatureText.length === 0) {
      this.focusSignature();
      this.onSignatureError();
    } else if (UC.user.name === signatureText) {
      this.agreed = true;
      if (this.agreedCallback) {
        this.agreedCallback();
      }
      this.$el.modal('hide')
    } else {
      this.focusSignature();
      this.onSignatureError();
      this.$el.find('.authorization-form-signature-hint').show()
    }
  },

  focusSignature: function() {
    var input = document.getElementById("signatureInput");
    input.scrollIntoView();
    input.focus();
  },

  onSignatureError: function() {
    this.$signature.addClass('animated shake');
    setTimeout(function() {
      this.$signature.removeClass('animated shake')
    }.bind(this), 1000)
  },

  validateSignature: function() {
    if (UC.user.name === this.$signature.val()) {
      this.$button.removeClass('btn-white').addClass('btn-primary')
    } else {
      this.$button.removeClass('btn-primary').addClass('btn-white')
    }
  },

  onDismissed: function() {
    if (this.dismissedCallback && !this.agreed) {
      this.dismissedCallback();
    }
  }
});
