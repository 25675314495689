UC.payments = {
  onTokenizeCardCallback: $.noop,
  onTokenizeBankAccountCallback: $.noop
};

UC.payments.createCreditCardData = function (data) {
  return {
    number: data.cardNumber,
    cvc: data.cvc,
    exp_month: data.expireMonth,
    exp_year: data.expireYear,
    name: data.nameOnCard,
    address_line1: data.address,
    address_city: data.city,
    address_state: data.country && data.country == 'USA' ? data.state : null,
    address_zip: data.zip,
    address_country: data.country
  }
};

UC.payments.onTokenizeCard = function (status, response) {
  if (response.error) {
    var errorMessage = response.error.message || "Unknown payment error";
    UC.utils.sendNesEvent('payments_error', {ERROR: errorMessage + ": " + JSON.stringify(response.error)});
    UC.payments.onTokenizeCardCallback(errorMessage);
  }
  else {
    UC.payments.onTokenizeCardCallback(null, response.id, response.card);
  }
};

UC.payments.tokenizeCard = function (creditCardData, callback) {
  UC.payments.onTokenizeCardCallback = callback;
  Stripe.card.createToken(creditCardData, UC.payments.onTokenizeCard);
};

UC.payments.onTokenizeBankAccount = function (status, response) {
  if (response.error) {
    UC.utils.sendNesEvent('payments_error', {ERROR: response.error.message});
    UC.payments.onTokenizeBankAccountCallback(response.error.message);
  }
  else {
    UC.payments.onTokenizeBankAccountCallback(null, response.id);
  }
};

UC.payments.tokenizeBankAccount = function (accountData, callback) {
  UC.payments.onTokenizeBankAccountCallback = callback;
  accountData.currency = accountData.currency || 'USD';
  Stripe.bankAccount.createToken(accountData, UC.payments.onTokenizeBankAccount);
};

UC.payments.validateCardNumber = function (cardNumber) {
  return Stripe.card.validateCardNumber(cardNumber);
};

UC.payments.validateSecurityCode = function (code) {
  return Stripe.card.validateCVC(code);
};

UC.payments.validateRoutingNumber = function (routingNumber, country) {
  return Stripe.bankAccount.validateRoutingNumber(routingNumber, country);
};

UC.payments.validateBankAccountNumber = function (accountNumber, country) {
  return Stripe.bankAccount.validateAccountNumber(accountNumber, country);
};

UC.payments.formatRoutingNumber = function ( routingNumber, country) {
  routingNumber = routingNumber ? routingNumber.trim() : '';
  switch (country) {
    case 'US':
      return routingNumber.replace(/[^\w\s]/gi, '');
    case 'CA':
      routingNumber = routingNumber.replace(/[^\w\s]/gi, '');
      if (routingNumber.length >= 5) {
        return routingNumber.splice(5, '-');
      } else {
        return routingNumber;
      }
    default:
      return routingNumber;
  }
};
