!function() {

  UC.views.DashboardScheduledCalls = Backbone.View.extend({

    events: {
      'click .pagination a': 'paginate',
      'click .scheduled-call-archive': 'archive',
      'click .scheduled-call-job-link': 'trackJobClick',
      'click .scheduled-call-reply': 'reply',
      'click .scheduled-call-initiate': 'call',
      'click .js-scheduled-call-handle-reschedule': 'handleReschedule'
    },

    trackJobClick: function() {
      UC.utils.recordKissEvent('Dashboard - Job Item Click', {
        type: UC.user.type,
        section: 'scheduled_calls'
      });
    },

    paginate: function(e) {
      e.preventDefault();
      this.current_page = $(e.currentTarget).data('page');
      this.render();
    },

    render: function(callback) {
      UC.net.get('/dashboard/scheduled_calls', {
        page: this.current_page
      }, function(html) {
        this.$el.html(html);
        if ($('#scheduled-calls .scheduled-call').length <= 0) {
          $('#scheduled-calls').closest('.scheduled-calls-box').remove();
          this.remove();
        }
      }.bind(this));
    },

    archive: function(e) {
      e.preventDefault();

      var view = this;
      var $el = $(e.currentTarget)
      var path = $el.attr('href');

      function onConfirm(response) {
        if (response) {
          UC.net.post(path, null, function() {
            view.render();
          });
        }
      }

      UC.utils.showConfirmModal('Confirm', 'Are you sure you want to hide this call?', onConfirm);
      UC.utils.recordKissEvent('Dashboard - Scheduled Call Archive Click', {
        type: UC.user.type,
        scheduled_call_id: $el.closest('li.scheduled-call').data('id')
      });
    },

    reply: function(e) {
      e.preventDefault();
      var $el = $(e.currentTarget).closest('li.scheduled-call');
      var job_id = $el.data('job-id');
      var id = $el.data('id');

      new UC.modals.ScheduledCallsShow({
        scheduled_call_id: id,
        callback: this.render.bind(this)
      }).render();

      UC.utils.recordKissEvent('Dashboard - Scheduled Call Reply Click', {
        type: UC.user.type,
        scheduled_call_id: id
      });
    },

    call: function(e) {
      e.preventDefault();
      var $el = $(e.currentTarget).closest('li.scheduled-call');

      if (UC.user.twilio_number) {
        new UC.views.CallsModalInitiate({
          to: $el.data('phone'),
          job_id: $el.data('job-id')
        }).render();

        UC.utils.recordKissEvent('Dashboard - Scheduled Call Initiate Click', {
          type: UC.user.type,
          scheduled_call_id: $el.data('id')
        });
      } else {
        $el.find('.scheduled-call-initiate').addClass('hidden');
        $el.find('.scheduled-call-phone').removeClass('hidden');

        UC.utils.recordKissEvent('Dashboard - Scheduled Call Reveal # Click', {
          type: UC.user.type,
          scheduled_call_id: $el.data('id')
        });
      }
    },

    handleReschedule: function(e) {
      var dataset = e.target.dataset;
      var scheduledCallId = dataset.id;
      var jobId = dataset.jobId;
      var bidId = dataset.bidId;
      var attorneyId = dataset.attorneyId;
      var instantAvailable = dataset.instantAvailable === 'true';
      UC.CallHelpers.rescheduleCall(scheduledCallId, jobId, bidId, attorneyId, instantAvailable, this.handleSuccessfulReschedule.bind(this, scheduledCallId));
    },

    handleSuccessfulReschedule: function(scheduledCallId) {
      UC.net.post('/scheduled_calls/{0}/cancel'.format(scheduledCallId)).then(function(res) {
        location.reload();
      }.bind(this))
    }
  });
}();
