UC.partials.ScheduledCallsFormRequest = Backbone.View.extend({

  events: {
    'submit': 'schedule',
    'click #js-open-time-zone-change': 'openTimezoneChange',
    'click #js-close-time-zone-change': 'closeTimezoneChange',
    'click #attorneyPhone .btn': 'showAttorneyPhone',
    'click .js-availability-next': 'availabilityNextButton',
    'click .js-phone-number-next': 'phoneNumberNextButton',
    'click .js-phone-number-back': 'phoneNumberBackButton',
  },

  routes: {
    scheduled_calls: '/users/{0}/scheduled_calls'
  },

  initialize: function(options) {
    this.options = options || {};
    this.time_zone_selected = false;
    this.times_available_view = null;
    this.loader = this.$('.btnScheduleCallSend').buttonLoader();
    this.jobId = (UC.job && !options.jobId) ? UC.job.id : options.jobId;

    this.initPhoneNumber();
    this.selectTimeZone();
    this.initTimesAvailableView(true);
  },

  initPhoneNumber: function() {
    var $attorney_phone = this.$('#attorneyPhone');
    var $span = $attorney_phone.find('span');

    if (this.attorney_phone != '') {
      var text = 'Click to View';

      $attorney_phone.removeClass('hidden');
      this.$('#copyHasPhone').removeClass('hide');

      if (UC.utils.validatePhone(this.$('#txtPhoneNumber').val())) {
        $span.addClass('disable-click');
        text = this.attorney_phone;
      } else {
        $span.removeClass('disable-click');
      }

      $span.text(text);
    }
  },

  selectTimeZone: function() {
    if (this.time_zone_selected === false) {
      // only try selecting the user's time zone once
      this.$('#js-time-zones').selectTimeZone();
      this.time_zone_selected = true;
    }
  },

  initTimesAvailableView: function(force) {
    if (!this.times_available_view || force) {
      this.times_available_view = new UC.partials.ScheduledCallsTimesAvailable({
        el: this.$('#times-available')
      });
    }
  },

  schedule: function(e) {
    e.preventDefault();

    UC.utils.clearValidationErrors();
    UC.utils.clearValidationErrors('form-group');

    this.initTimesAvailableView();

    var $phone = this.$('#txtPhoneNumber');
    var $message = this.$('#txtScheduleCallMessage');
    var $time_zone = this.$('#js-time-zones');
    var errors = this.times_available_view.errors();

    if (!UC.utils.validatePhone($phone.val())) {
      errors.push({
        element: $phone,
        text: 'Please provide a valid phone number',
        className: 'form-group'
      });
    }

    if ($time_zone.val() === '') {
      errors.push({
        element: $time_zone,
        text: 'Please select your time zone.',
        className: 'form-group'
      });
    }

    if ($message.val() === '') {
      errors.push({
        element: $message,
        text: 'Please include a message.',
        className: 'form-group'
      });
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
    } else {
      this.loader.showLoader();

      var path = this.$('#scheduled_calls_form_request').attr('action');
      var data = {
        phone: $phone.val(),
        message: $message.val(),
        time_zone: $time_zone.val(),
        availability: this.times_available_view.serialize()
      };

      if (this.jobId){
        data.job_id = this.jobId;
      }

      UC.net.post(path, data).done(function(result) {
        var eventData = {
          json_attributes: {
            time_zone: data.time_zone,
            availability: data.availability
          }
        };

        if (this.jobId){
          eventData.job_id = this.jobId;
        }

        UC.utils.sendNesEvent('proposals_schedule_call_sent', eventData);
        UC.utils.hideNotifications();
        UC.utils.showNotification(result.message, UC.constants.results.success);

        if (typeof this.options.onRequest === 'function') {
          this.options.onRequest(result);
        }

        this.$el.modal('hide');
      }.bind(this)).always(function() {
        this.loader.hideLoader();
      }.bind(this));
    }
  },

  showAttorneyPhone: function(e) {
    e.preventDefault();

    var $phone = this.$('#txtPhoneNumber');
    var phone = $phone.val();
    var errors = [];

    if (!UC.utils.validatePhone(phone)) {
      errors.push({ element: $phone, text: 'Please provide a valid phone number', className: 'form-group' });
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
    } else {
      UC.utils.clearValidationErrors();

      // update phone on user
      UC.net.post('/account/update_org_info', { info: { Phone: phone } }, function(result) {

        UC.utils.clearValidationErrors('form-group');

        this.$('#attorneyPhone span')
          .text(this.attorney_phone)
          .addClass('disable-click');

        UC.utils.recordKissEvent('proposals - schedule call twilio reveal');
      }.bind(this));
    }
  },

  availabilityNextButton: function(e) {
    e.preventDefault();

    UC.utils.clearValidationErrors('form-group');
    var errors = this.times_available_view.errors();
    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      return;
    }

    var availabileTimes = this.times_available_view.serialize();
    var timeLis = [];
    availabileTimes.forEach(function (time) {
      timeLis.push(
        "<li>" +
        moment(time.date, UC.constants.momentDateFormats).format("dddd, MMMM D") +
        " from " +
        moment(time.from_hour, "HH:mm").format("hh:mmA") +
        " to " +
        moment(time.to_hour, "HH:mm").format("hh:mmA") +
        "</li>"
      );
    });
    var newTimes = "<ul>" + timeLis.join("") + "</ul>";
    this.$(".js-available-times").html(newTimes);

    this.$("#js-availability-step").toggleClass("hidden");
    this.$("#js-phone-number-step").toggleClass("hidden");
  },

  openTimezoneChange: function (e) {
    e.preventDefault();

    this.$('#js-change-time-zone, #js-open-time-zone-change').toggleClass('hidden');
  },

  closeTimezoneChange: function (e) {
    e.preventDefault();

    this.$('#js-change-time-zone, #js-open-time-zone-change').toggleClass('hidden');
  },

  phoneNumberNextButton: function(e) {
    e.preventDefault();

    UC.utils.clearValidationErrors();
    UC.utils.clearValidationErrors('form-group');

    var $phone = this.$('#txtPhoneNumber');
    var $message = this.$('#txtScheduleCallMessage');
    var errors = this.times_available_view.errors();

    if (!UC.utils.validatePhone($phone.val())) {
      errors.push({
        element: $phone,
        text: 'Please provide a valid phone number',
        className: 'form-group'
      });
    }

    if ($message.val() === '') {
      errors.push({
        element: $message,
        text: 'Please include a message.',
        className: 'form-group'
      });
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
    } else {
      this.$("#js-submit-step").toggleClass("hidden");
      this.$("#js-phone-number-step").toggleClass("hidden");
    }
  },

  phoneNumberBackButton: function(e) {
    e.preventDefault();

    this.$("#js-availability-step").toggleClass("hidden");
    this.$("#js-phone-number-step").toggleClass("hidden");
  },
});
