UC.modals.AttorneyProfileUpdatePhoneNumber = Backbone.View.extend({

  el: '#mdlUpdatePhoneNumber',

  events: { 'submit #js-userPhoneNumberForm' : 'save' },

  elements: {
    'btnSavePhoneNumber' : null,
    'js-userPhoneNumber' : null
  },

  render: function() {
    UC.utils.setSelectors(this.elements);

    this.elements.btnLoader = this.elements.btnSavePhoneNumber.buttonLoader();
    this.$el.modal({ backdrop: 'static' });
  },

  handleSuccessfulUpdate: function(response) {
    UC.utils.clearValidationErrors('form-group');
    UC.utils.checklistSuccessCallback('btnUpdatePhoneNumber');

    this.$el.modal('hide');
  },

  handleFailedUpdate: function(xhr) {
    var error = {
      element: this.elements['js-userPhoneNumber'],
      text: xhr.responseJSON['message']
    }

    xhr.handled = true;

    UC.utils.showValidationErrors([error], { className: "form-group" });
  },

  updatePhoneNumber: function(data) {
    UC.net.post(
      UC.constants.update_org_info_path, data, this.handleSuccessfulUpdate.bind(this)
    ).fail(
      this.handleFailedUpdate.bind(this)
    ).always(
      function() { this.elements.btnLoader.hideLoader(); }.bind(this)
    );
  },

  save: function(e) {
    e.preventDefault();

    this.elements.btnLoader.showLoader();
    var data = { info : { Phone : this.elements['js-userPhoneNumber'].val() } }
    this.updatePhoneNumber(data);
  }
});
