UC = UC || {};
UC.timesheetComponent = UC.timesheetComponent || {};

UC.timesheetComponent.TimesheetTableView = Backbone.View.extend({
  tagName: 'div',

  id: 'timesheet-container',

  className: 'marg-b-20',

  template: UC.views.resolveHandlebarsTemplate("components/timesheets/timesheet_table"),

  headerTemplate: UC.views.resolveHandlebarsTemplate("components/timesheets/timesheet_table_header"),

  events: {
    'click .sortable-table-header' : 'sortTableByHeaderClick',
    'click .select-all': 'selectAllTimesheetItems'
  },

  initialize: function(opts) {
    this.collection.on('reset', this.handleCollectionReset.bind(this));
    this.collection.on('add', this.newTimesheetLineAdded.bind(this));
    this.collection.on('line-selected line-unselected', this.render.bind(this));
    this.collection.on('remove', this.handleTsLineRemoved.bind(this));

    this.showDeleteColumn = !!opts.showDeleteColumn;
    this.allowNewTimesheetLines = !!opts.allowNewTimesheetLines;
    this.readOnlyOverride = opts.readOnlyOverride;
    this.showJobColumn = !!opts.showJobColumn;
    this.showTypeSelect = !!opts.showTypeSelect;
    this.showDeleteColumn = !!opts.showDeleteColumn;

    this.modelFilters = opts.modelFilters;
    this.sortByField = 'Date';
    this.sortByFieldType = 'date';
    this.sortDirection = 'asc';
    this.tab = opts.tab;
    this.selectAll = false;

    this.timesheetLineViews = [];

    this.initStatusIndicatorView();
  },

  initStatusIndicatorView: function() {
    this.saveStatusIndicatorView = new UC.timesheetComponent.SaveStatusIndicatorView({
      collection: this.collection,
      tableView: this
    });

    this.saveStatusIndicatorView.render();
  },

  handleTsLineRemoved: function(model) {
    this.removeTsLineViewForModel(model);
    this.renderTimesheetHeaderAndLines();
  },

  removeTsLineViewForModel: function(model) {
    this.timesheetLineViews = _.reject(this.timesheetLineViews, function(view) {
      return model.get('_id') === view.model.get('_id');
    })
  },

  handleCollectionReset: function() {
    this.timesheetLineViews = [];

    this.collection.models.forEach(function(model) {
      this.addLineItemView(model);
    }.bind(this));

    this.render();
  },

  addLineItemView: function(model) {
    var newTsLineView = new UC.timesheetComponent.TimesheetLineItemRowView({
      model: model,
      tableView: this,
      readOnlyOverride: this.readOnlyOverride,
      showDeleteColumn: this.showDeleteColumn
    });

    this.timesheetLineViews.push(newTsLineView);
    return newTsLineView;
  },

  appendLineItemViewToTable: function(view) {
    view.render();
    this.$timesheetLineItemsTbody.append(view.$el);
  },

  render: function() {
    this.$el.html(this.template(this));

    this.$tsHeader = this.$('#ts-header');

    this.$timesheetLineItemsTbody = this.$('#timesheet-line-items');

    this.renderTimesheetHeaderAndLines();

    if (this.allowNewTimesheetLines) this.appendNewTimesheetRowButton();
    if (this.showSaveStatus()) this.appendSaveStatusIndicator();

  },

  renderTimesheetHeaderAndLines: function() {
    this.$tsHeader.html(this.headerTemplate(this));

    // clear existing html;
    this.$timesheetLineItemsTbody.html('');

    this.timesheetLineViews.forEach(function(view) {
      if (this.tsLineViewRelevantBasedOnFilter(view)) this.appendLineItemViewToTable(view);
    }.bind(this));

    this.$('.table').stickyCssTableHeader();
    this.$('[data-toggle="tooltip"]').tooltip({container: 'body'});
  },

  appendNewTimesheetRowButton: function() {

    var newTimesheetRowButton = new UC.timesheetComponent.AddTimesheetRowButtonView({
      collection: this.collection,
      tableView: this
    });

    newTimesheetRowButton.render();

    this.$('#new-timesheet-row-btn-wrapper').html(newTimesheetRowButton.$el);
  },

  newTimesheetLineAdded: function(model) {
    var view = this.addLineItemView(model);
    this.timesheetLineViews.push(view);
    this.appendLineItemViewToTable(view);
    view.setFocusOnDateCell();
  },

  tsLineViewRelevantBasedOnFilter: function(view) {
    if(!this.modelFilters) return true;

    if(this.modelFilters.notAddedToInvoice) return !view.model.addedToInvoice;
  },

  sortTableByHeaderClick: function(e) {
    var columnFieldName = $(e.currentTarget).data('field-name');
    var columnFieldType = $(e.currentTarget).data('field-type');

    if (this.sortByField === columnFieldName) {
      this.toggleSortDirection()
    } else {
      this.sortByField = columnFieldName;
      this.sortByFieldType = columnFieldType;
      this.sortDirection = 'asc';
    }

    this.timesheetLineViews = this.sortTsLineViews();

    this.renderTimesheetHeaderAndLines();
  },

  sortTsLineViews: function() {
    return this.timesheetLineViews.sort(function(view1, view2) {
      var model1 = view1.model;
      var model2 = view2.model;

      var model1Id = model1.get('_id');
      var model2Id = model2.get('_id');

      var value1 = this.sortableValue(model1, this.sortByField, this.sortByFieldType);
      var value2 = this.sortableValue(model2, this.sortByField, this.sortByFieldType);

      // In order to maintain sort order on re-render, we need to have a secondary sort criteria (_id) when the primary
      // sort values are equal
      if (value1 === value2) {
        if (this.sortDirection === 'asc') {
          return model1Id < model2Id ? -1 : model1Id > model2Id ? 1 : 0;
        } else {
          return model2Id < model1Id ? -1 : model2Id > model1Id ? 1 : 0;
        }
      }

      if (this.sortDirection === 'asc') {
        return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      } else {
        return value2 < value1 ? -1 : value2 > value1 ? 1 : 0;
      }

    }.bind(this));
  },

  sortableValue: function(model, sortFieldName, sortFieldType) {
    var convertedValue;

    switch (sortFieldType) {
      case "date":
        var dateString = model.get(sortFieldName) || 1;
        convertedValue = new Date(dateString);
        break;
      case "jobSelect":
        var jobModel = model.getAssociatedJobModel();

        if (jobModel) {
          convertedValue = jobModel.get('JobTitle').toLowerCase();
        } else {
          convertedValue = "";
        }

        break;
      default:
        convertedValue = model.get(sortFieldName);
    }

    return convertedValue;
  },

  toggleSortDirection: function() {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  },

  showSaveStatus: function() {
    return !!this.allowNewTimesheetLines;
  },

  appendSaveStatusIndicator: function() {
    this.$('#ts-save-status-wrapper').html(this.saveStatusIndicatorView.$el);
  },

  selectAllTimesheetItems: function() {
    this.selectAll = !this.selectAll;
    this.$('.select-all').toggleClass('checked');
    if (this.selectAll) {
      this.collection.models.forEach(function(model) {
        if(model.allowedToSelectLine()) {
          model.markSelected();
        }
      });
    } else {
      this.collection.models.forEach(function(model) {
        model.markUnselected();
      });
    }
  }
});
