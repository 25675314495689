!function() {

  UC.views.ClientComplaintsShow = UC.views.ClientComplaintsUpdate = Backbone.View.extend({

    events: {
      'click #upload-documents': 'openFilePicker'
    },

    initialize: function() {
      this.$description = this.$('#client_complaint_ClientComplaintQuestionnaire_attributes_Description');
      this.$description.attachmentTextarea({
        skipPathPrefix: true,
        source_type: UC.constants.source_type,
        upload_path: UC.constants.upload_path,
        attachments: UC.constants.attachments_questionnaire,
        inputName: 'client_complaint[ClientComplaintQuestionnaire_attributes][UserDocumentIds][]'
      });
    },

    openFilePicker: function(e) {
      e.preventDefault();
      this.$description.attachmentTextarea('openFilePicker');
    }
  });
}();
