!function() {

  UC.modals.AttorneyProfileAddPastClients = Backbone.View.extend({

    el: '#mdlAddPastClients',

    events: {
      'submit #formSearchClients' : 'search',
      'click #btnSavePastClients' : 'save',
      'click #btnPastClientAdd' : 'manuallyAdd',
      'click #lnkManuallyAddClient' : 'showManualAdd'
    },

    elements: {
      'formAddClient' : null,
      'txtPastClientAdd' : null,
      'addedClients' : null,
      'pastClientAdded' : null,
      'pastClientsSearchResults' : null,
      'txtPastClientSearch' : null,
      'pastClientsSearchBox' : null,
      'pastClientAdded' : null,
      'btnPastClientSearch' : null,
      'btnSavePastClients' : null,
      'pastClientsContent' : null
    },

    addedClients: {},

    render: function() {
      UC.utils.setSelectors(this.elements);
      this.elements.pastClientsSearchResults.html('');
      this.elements.txtPastClientSearch.val('');
      this.elements.addedClients.html('');
      this.elements.btnSavePastClients.buttonLoader();
      this.elements.btnPastClientSearch.buttonLoader();
      UC.utils.hideComponent(this.elements.pastClientsSearchBox);
      UC.utils.hideComponent(this.elements.pastClientAdded);
      UC.utils.showComponent(this.elements.btnPastClientSearch);
      this.elements.addedClients.on('click', '.action.remove', this.removePastClient.bind(this));
      this.elements.pastClientsSearchResults.on('click', '.action.add', this.addPastClient.bind(this));
      this.$el.modal({ backdrop: 'static' });
    },

    search: function(e) {
      e.preventDefault();
      this.elements.pastClientsSearchResults.html('');
      $('.row').removeClass('has-error');

      var data = {
        company_name: this.elements.txtPastClientSearch.val().trim()
      };

      if (!data.company_name || data.company_name === ''){
        UC.utils.showValidationErrors([{element: $('#txtPastClientSearch'), text: 'Missing company name'}]);
      } else {
        this.elements.btnPastClientSearch.showLoader();
        UC.net.get(UC.constants.search_companies_path, data, function(response) {
          this.searchResults = response.companies;
          this.elements.pastClientsSearchResults.html(response.html);
          UC.utils.showComponent(this.elements.pastClientsSearchBox);
        }.bind(this)).always(function(){
          this.elements.btnPastClientSearch.hideLoader();
        }.bind(this))
      }
    },

    showManualAdd: function(e) {
      e.preventDefault();
      this.elements.formAddClient.removeClass('hidden');
    },

    manuallyAdd: function(e) {
      e.preventDefault();
      $('.row').removeClass('has-error');

      var client = { name: this.elements.txtPastClientAdd.val().trim(), id: Date.now() };

      if (client.name && client.name !== '') {
        this.elements.formAddClient.addClass('hidden');
        this.elements.txtPastClientAdd.val('');

        if (this.elements.addedClients.find(".client-name:contains('" + client.name + "')").length === 0) {
          this.appendClientToPage(client);
        }
      } else {
        UC.utils.showValidationErrors([{element: this.elements.txtPastClientAdd, text: 'Missing company name'}]);
      }
    },

    newClientHTML: function(client) {
      var newClient =
        this.$el.find('#js-added-client-template').find('.js-added-client').clone();

      newClient.attr('data-id', client.id);
      newClient.find('.js-client-name').text(client.name);

      if (client.logo_url && client.logo_url !== '') {
        newClient.find('.js-client-logo').attr('src', client.logo_url)
      }

      return newClient[0].outerHTML;
    },

    appendClientToPage: function(client) {
      this.addedClients[client.id] = client;
      UC.utils.showComponent(this.elements.pastClientAdded);

      var newClientHTML = this.newClientHTML(client);

      this.elements.addedClients.append(newClientHTML);
      $('#add-client-{0}'.format(client.id)).hide();
      $('#added-client-{0}'.format(client.id)).removeClass('hide');
    },

    save: function(e) {
      e.preventDefault();
      var clients = [];
      for (var i in this.addedClients) {
        clients.push(this.addedClients[i]);
      };

      var data = {
        clients : clients,
        id: UC.user.id
      };

      this.elements.btnSavePastClients.showLoader();

      UC.net.post(UC.constants.add_past_clients_path, data, function(response) {
        if (UC.action === 'index' && UC.controller === 'dashboard') {
          UC.utils.checklistSuccessCallback('btnAddPastClients');
        } else {
          this.elements.pastClientsContent.html(response);
          $(".add-button").show();
          $('.past-client').tooltip({ container: 'body' });
        }
        this.$el.modal('hide');
      }.bind(this)).always(function() {
        this.elements.btnSavePastClients.hideLoader();
      }.bind(this));
    },

    addPastClient: function(e) {
      e.preventDefault();

      var index = $(e.target).closest('.js-search-result').data('index');
          client = this.searchResults[index];

      if (this.addedClients[client.id] === undefined && UC.constants.pastClientIds.indexOf(client.id.toString()) === -1) {
        this.appendClientToPage(client);
      }
    },

    removePastClient: function(e) {
      var clientId = $(e.target).closest('.js-added-client').data('id'),
          client = this.addedClients[clientId];

      if(client !== null) {
        delete this.addedClients[clientId];
        $("[data-id='{0}']".format(clientId)).remove();
        $('#add-client-{0}'.format(clientId)).show();
        $('#added-client-{0}'.format(clientId)).hide();
      }
    }
  });
}();
