UC.partials.InvoicesFormDetails = Backbone.View.extend({
  events: {
    'click #btnSaveInvoiceReason' : 'onSaveInvoiceReasonClick',
    'click .lnkAddLineItem' : 'onLnkAddLineItemClick',
    'click .send-invoice' : 'onSendInvoiceClick',
    'change .changeable': 'onItemChange',
    'click .btnRemoveItem': 'onBtnRemoveItemClick',
    'click .modal-confirm-btn': 'sendInvoiceOrShowInvoiceReasonModal',
    'blur .item-quantity': 'onQuantityChange',
    'input .item-description': 'autoResizeInputTextArea',
  },

  initialize: function (options) {
    this.options = options || {};
    this.items = [];
    this.total = 0;

    this.initFancyCombobox();
    this.initLineItems();
    this.initDraggableLineItems();

    this.confirmUnload = this.$('#invoice-form-details').confirmUnload();
    this.sendInvoiceLoader = this.$('#btnSendInvoice').buttonLoader();
    this.modalSendInvoiceLoader = this.$('.modal-confirm-btn').buttonLoader();
    this.$platformFee = this.$('#js-platform-fee');
    this.$attorneyFee = this.$('#js-attorney-fee-notice');
  },

  render: function (attorney_client) {
    this.attorney_client = attorney_client;

    this.initBillToName();
    this.toggleBakedIndicators();
    this.onItemChange();
    this.toggleAttorneyFeeNotice();

    if (this.options.showTimesheetEntryImport) {
      // Initialize Add Timesheet Entries Button
      new UC.timesheetComponent.InvoiceFormView({
        invoiceForm: this,
        jobId: this.options.jobId,
        clientId: this.attorney_client.UserId,
        tsServiceFeeLinesBtnContainer: this.$('#import-ts-service-fee-entries-btn'),
        tsExpenseLinesBtnContainer: this.$('#import-ts-expense-entries-btn')
      });
    }
  },

  toggleAttorneyFeeNotice: function () {
    var paymentProfile = this.attorney_client.payment_profile;
    if (paymentProfile.attorney_fee_disclaimer) {
      this.$attorneyFee.text("** " + paymentProfile.attorney_fee_disclaimer);
      this.$attorneyFee.removeClass('hide')
    } else {
      this.$attorneyFee.addClass('hide')
    }
  },

  onItemChange: function () {
    this.collectItemsData();
    this.setTotalValues();
  },

  collectItemsData: function () {
    this.items = [];

    this.$('.inv-items tbody .trItem').each(function (_, element) {
      var $element = $(element);
      var item = {
        id: $element.data('id'),
        date: moment($element.find('.item-date').val(), UC.constants.momentDateFormats).format('MM/DD/YYYY'),
        itemType: $element.find('.item-type').val(),
        description: $element.find('.item-description').val(),
        quantity: UC.utils.parseCurrency($element.find('.item-quantity').val()),
        unitPrice: UC.utils.parseCurrency($element.find('.item-unit-price').val()),
        timesheetLineItemId: $element.data('timesheet-item-id')
      };

      var total = UC.utils.multiplyCurrency(item.quantity, item.unitPrice);
      var bakedInFee = this.determineBakedFee();

      if (!this.isExpense(item) && bakedInFee > 0) {
        var percentFee = (bakedInFee / 100) + 1;

        item.amount_client = UC.utils.multiplyCurrency(item.unitPrice, percentFee);
        item.amount = UC.utils.multiplyCurrency(total, percentFee);
      } else {
        item.amount = total;
      }

      // update html with parsed/formatted values
      $element.find('.item-amount').val(UC.utils.formatCurrency(item.amount, true));
      $element.find('.item-amount-client').val(item.amount_client);

      this.items.push(item);
    }.bind(this));
  },

  setTotalValues: function () {
    var platformFeeAmount = 0.0;
    var hours = 0.00;
    var serviceFeesSubtotal = 0.00;
    var expensesSubtotal = 0.00;
    var total = 0.00;

    for (var i = 0; i < this.items.length; i++) {
      var item = this.items[i];
      var quantity = parseFloat(item.quantity);
      var amount = parseFloat(item.amount);

      if (this.isExpense(item)) {
        expensesSubtotal += amount;
      } else {
        serviceFeesSubtotal += amount;

        if (item.itemType === UC.constants.InvoiceItemType.TimeEntry) {
          hours += quantity;
        }
      }
    }

    this.total = total = expensesSubtotal + serviceFeesSubtotal;
    this.$('#lblFilingSubtotal').text(UC.utils.formatCurrency(expensesSubtotal, true));

    if (hours > 0) {
      this.$('#lblTotalHours').text(hours.toFixed(2));
      this.$('#rowTotalHours').removeClass('hidden');
    } else {
      this.$('#rowTotalHours').addClass('hidden');
    }

    if (this.$platformFee.length) {
      platformFeeAmount = this.setPlatformFees(serviceFeesSubtotal, expensesSubtotal);
      this.$platformFee.text(UC.utils.formatCurrency(platformFeeAmount, true));
    }
  
    this.$('#lblTotal').text(UC.utils.formatCurrency(total + platformFeeAmount, true));
    this.$('#lblSubtotal').text(UC.utils.formatCurrency(serviceFeesSubtotal, true));
  },
  
  setPlatformFees: function (serviceFees, filingFees) {
    var paymentProfile = this.attorney_client.payment_profile;
    var servicePlatformFeeAmount = serviceFees * paymentProfile.platform_service_fee_percent / 100;
    var filingPlatformFeeAmount = filingFees * paymentProfile.platform_filing_fee_percent / 100;
    return servicePlatformFeeAmount + filingPlatformFeeAmount;
  },

  determineBakedFee: function () {
    var paymentProfile = this.attorney_client.payment_profile;

    if (paymentProfile.is_shadow_baked) {
      // we aren't making the "bake" visible to the attorney/client
      return 0;
    }

    if (UC.constants.bid) {
      // on job invoices, we use the payment profile from the original bid
      paymentProfile = UC.constants.bid.payment_profile;
    }

    return paymentProfile.baked_service_fee;
  },

  isExpense: function (item) {
    return item.itemType === UC.constants.InvoiceItemType.FilingFees || item.itemType === UC.constants.InvoiceItemType.Expenses;
  },

  onLnkAddLineItemClick: function (e) {
    e.preventDefault();
    var type = this.$(e.currentTarget).data('type');
    this.addLineItem(type);
  },

  // TODO this is an open question. is the modal within the el? should we widen the el scope?
  onSaveInvoiceReasonClick: function (e) {
    e.preventDefault();

    if (this.validateUpcounselInvoice() ) {
      this.$('#mdlUpcounselInvoiceReason').modal('hide');
      this.sendInvoiceLoader.showLoader();
      this.sendInvoice(this.invoice, { collectUpcounselInfo: true });
    }
  },

  initFancyCombobox: function () {
    (function ($) {
      $.widget("custom.combobox", {
        _create: function () {
          this.wrapper = $("<div>")
            .addClass("custom-combobox")
            .insertAfter(this.element);
          this.element.hide();
          this._createAutocomplete();
          this._createShowAllButton();
        },
        _createAutocomplete: function () {
          var selected = this.element.children(":selected");
          var value = selected.val() ? selected.text() : "";
          var input = this.input = $("<input>")
            .appendTo(this.wrapper)
            .val(value)
            .attr('id', '{0}Combobox'.format(this.element.attr('id')))
            .addClass("custom-combobox-input form-control changeable")
            .on('focus', function () {
              input.autocomplete("search", "");
            })
            .autocomplete({
              delay: 0,
              minLength: 0,
              source: function (request, response) {
                response(this.element.children("option").map(function () {
                  return $(this).val();
                }).get());
              }.bind(this)
            });
          input.on('autocompletechange', function () {
            var text = input.val();
            if (this.element.find('option[value="{0}"]'.format(text)).length < 1) {
              this.element.append('<option value="{0}">{0}</option>'.format(text, text));
            }
            this.element.val(input.val());
          }.bind(this));
        },
        _createShowAllButton: function () {
          var input = this.input;
          var wasOpen = false;
          $("<a>")
            .attr("tabIndex", -1)
            .appendTo(this.wrapper)
            .addClass("custom-combobox-toggle")
            .html('<i class="fontello icon-angle-down fs-16"></i>')
            .mousedown(function () {
              wasOpen = input.autocomplete("widget").is(":visible");
            })
            .click(function (e) {
              input.focus();
              // Close if already visible
              if (wasOpen) {
                return;
              }
            });
        },
        _destroy: function () {
          this.wrapper.remove();
          this.element.show();
        }
      });
    })(jQuery);
  },

  initBillToName: function () {

    if (this.$billToName) {
      this.$billToName.combobox('destroy');
    }

    this.$billToName = this.$('#selectBillToName').combobox();
  },

  toggleBakedIndicators: function () {
    var hasBakedFee = this.determineBakedFee() > 0;

    this.$('.unbaked-table-head').toggleClass('hidden', hasBakedFee);

    this.$('.baked-table-head').toggleClass('hidden', !hasBakedFee);
    this.$('.client-rate-col').toggleClass('hidden', !hasBakedFee);
  },

  initDraggableLineItems: function () {
    var serviceColumns = $('#serviceItems thead th').length;
    var filingColumns = $('#filingItems thead th').length;

    this.$('table#serviceItems tbody').sortable({
      axis: 'y',
      tolerance: 'pointer',
      handle: '.drag-line-item',
      items: '.invoice-line-item',
      containment: 'parent',
      placeholder: 'invoice-line-item-placeholder',
      start: function (e, ui) {
        this.hideAllTooltips();
        ui.placeholder.html('<td colspan="{0}"></td>'.format(serviceColumns));
      }.bind(this)
    });

    this.$('table#filingItems tbody').sortable({
      axis: 'y',
      tolerance: 'pointer',
      handle: '.drag-line-item',
      items: '.invoice-line-item',
      containment: 'parent',
      placeholder: 'invoice-line-item-placeholder',
      start: function (e, ui) {
        this.hideAllTooltips();
        ui.placeholder.html('<td colspan="{0}"></td>'.format(filingColumns));
        // We need to remove a td element as one is hidden for filing fees
        ui.placeholder.find('td:first').remove();
      }.bind(this)
    });
  },

  hideAllTooltips: function () {
    this.$('#invoice-items').find('[data-toggle="tooltip"]').each(function () {
      $(this).tooltip('hide');
    });
  },

  initLineItems: function ($lineItems) {
    var $items;

    if ($lineItems && $lineItems.length > 0) {
      $items = $lineItems;
    } else {
      // default to all items on the page
      $items = this.$('.invoice-line-item');
    }

    $items.each(function (i, el) {
      var $item = $(el);

      $item.find('.item-type').select2();
      $item.find('.item-date').datepicker(UC.constants.datepickerOptions);
      $item.find('.currency').currencyInput({ addCurrencySymbol: true });

      if (!$(window).isMobileWidth()) {
        // skip tooltips on mobile to avoid need for double tapping
        $item.find('[data-toggle="tooltip"]').tooltip({
          animation: false
        });
      }
    }.bind(this));
  },

  addLineItem: function (type, opts) {
    var opts = opts || {};
    var $container = this.$('.inv-items[data-type="'+ type +'"]');
    var $item = this.defaultItem(type);
    var $previousItem = $container.find('.trItem:visible').last();
    var previousUnitPrice = $previousItem.find('.item-unit-price').val();
    var $itemQuantity = $item.find('.item-quantity');
    var $itemUnitPrice = $item.find('.item-unit-price');
    var $itemDescription = $item.find('.item-description');
    var $itemType = $item.find('.item-type');
    var previousDate;

    if ($previousItem.length > 0) {
      previousDate = $previousItem.find('.item-date').datepicker('getDate');
    }

    if (opts.quantity || opts.quantity === 0) {
      $itemQuantity.val(opts.quantity);
    }

    if (opts.unitPrice || opts.unitPrice === 0) {
      $itemUnitPrice.val(opts.unitPrice);
    } else if (($itemType.val() !== UC.constants.InvoiceItemType.FilingFees) && previousUnitPrice) {
      $itemUnitPrice.val(previousUnitPrice);
    }

    if (opts.description) {
      $itemDescription.val(opts.description);
    }

    if (opts.type) {
      $itemType.val(opts.type);
    }

    if (opts.timesheetLineItemId) {
      $item.data('timesheet-item-id', opts.timesheetLineItemId);
    }

    $container.find('tbody').append($item);

    this.initLineItems($item);

    if (opts.date) {
      $item.find('.item-date').datepicker('setDate', opts.date);
    } else if (previousDate) {
      $item.find('.item-date').datepicker('setDate', previousDate);
    }

    this.onItemChange();
    this.toggleBakedIndicators();
  },

  defaultItem: function (type) {
    // exists outside the invoiceForm element
    var html = this.$('#template-invoice-{0}-item'.format(type)).html();

    return $(html);
  },

  hideSendInvoiceLoaders: function () {
    this.modalSendInvoiceLoader.hideLoader();
    this.sendInvoiceLoader.hideLoader();
  },

  onQuantityChange: function (e) {
    var $quantity = $(e.target),
      val = parseFloat($quantity.val().replace(/,/g, ''));

    if (isNaN(val)) {
      $quantity.val('0.00');
      $quantity.trigger('change');
    }
  },

  onBtnRemoveItemClick: function (e) {
    e.preventDefault();
    var $invoiceLineTr = $(e.target).closest('tr');
    $invoiceLineTr.trigger('invoiceLineItemRemoved');
    $invoiceLineTr.remove();
    this.onItemChange();
  },

  requireSendInvoiceConfirmation: function () {
    var confirmMessage = 'Are you sure you want to send this invoice?';

    UC.utils.showConfirmModal('Confirm', confirmMessage, function (response) {
      if (response) {
        this.sendInvoiceOrShowInvoiceReasonModal();
      }
    }.bind(this));
  },

  sendInvoice: function (invoice, options) {
    this.collectItemsData();

    if (!UC.constants.hasPayout) {
      this.$('#modalNoBankAccount').modal();
      this.hideSendInvoiceLoaders();
      return;
    }
    else if (UC.constants.unverifiedAccount) {
      this.$('#modalUnverifiedAccount').modal();
      this.hideSendInvoiceLoaders();
      return;
    }

    var data = {
      invoice: invoice,
      items: this.items
    };

    if (options && options.collectUpcounselInfo) {
      data.invoice.UpcounselInvoiceReason = this.upcounselInvoiceReason;
      data.invoice.RelatedInvoiceId = this.relatedInvoiceId;
      data.adminNote = this.adminNote;
    }

    this.confirmUnload.resetChanged();

    var ajax = null;

    if (UC.action === 'edit') {
      ajax = UC.net.put('/invoices/{0}'.format(UC.invoice.id), data);
    } else {
      ajax = UC.net.post('/invoices', data);
    }

    ajax.fail(function () {
      this.hideSendInvoiceLoaders();
    }.bind(this));
  },

  sendInvoiceOrShowInvoiceReasonModal: function (e) {

    if (e) {
      $(e.currentTarget).buttonLoader().showLoader();
    }

    var $mdlUpcounselInvoiceReason = this.$('#mdlUpcounselInvoiceReason');

    if ($mdlUpcounselInvoiceReason.length > 0) {
      $mdlUpcounselInvoiceReason.modal();
    } else {
      this.sendInvoiceLoader.showLoader();
      this.sendInvoice(this.invoice);
    }
  },

  onSendInvoiceClick: function (e) {
    e.preventDefault();

    this.invoice = this.$('#invoiceForm').serializeJSON();

    if (!this.validate(this.invoice)) {
      return false;
    }


    this.requireSendInvoiceConfirmation();
  },

  validate: function (invoice) {
    var fields = [
      {
        element: '#selectClients',
        errorMessage: 'Please select a client'
      },
      {
        element: '#selectBillToName',
        errorMessage: 'Please enter a bill to name'
      }
    ];

    if (invoice.JobId && !invoice.Type) {
      fields.push({
        element: '#invoiceFinalInvoice',
        errorMessage: 'Please indicate if this is the final invoice'
      });
    }

    this.$('tbody .trItem').each(function () {
      fields.push({
        element: $(this).find('.item-date'),
        errorClassName: 'date',
        type: 'date'
      });
    });

    return UC.utils.validateFields(fields, {
      scrollToFirst: true
    });
  },

  validateUpcounselInvoice: function () {
    var errors = [];
    this.$('.row .has-error').removeClass('has-error');

    this.upcounselInvoiceReason = this.$("[name='UpcounselInvoiceReason']:checked").val();
    this.relatedInvoiceId = this.$('#txtRelatedInvoiceId').val();
    this.adminNote = this.$('#txtAdminNote').val().trim();

    if (this.relatedInvoiceId === '' || (this.relatedInvoiceId !== '' && !UC.utils.validateObjectId(this.relatedInvoiceId))) {
      errors.push({element: this.$('#txtRelatedInvoiceId'), text: 'Please enter a valid object id'});
    }

    if (!this.upcounselInvoiceReason) {
      errors.push({element: this.$('#upcounselInvoiceReason'), text: 'Invoice reason is required'});
    }

    if (this.adminNote === '') {
      errors.push({element: this.$('#txtAdminNote'), text: 'Notes are required'});
    }

    if (errors.length > 0) {
      UC.utils.showValidationErrors(errors);
      return false;
    }

    return true;
  },

  autoResizeInputTextArea: function (event) {
    const textarea = $(event.currentTarget);

    textarea.css('height', 'auto'); // To calculate the actual content height

    const minHeight = 28 + 10;  // single line height + 10 to adjust for padding
    const scrollHeight = 300; // maximum height before scrolling starts
    const newHeight = Math.min(textarea.prop('scrollHeight'), scrollHeight);

    // Set the new height based on content size or the max height allowed
    textarea.css('height', `${newHeight}px`);
    // Enable scrolling if textarea is more than one line
    textarea.css('overflow-y', newHeight >= minHeight ? 'auto' : 'hidden');
  },
});
