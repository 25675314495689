!function () {

  UC.views.JobsModalChangeStatus = Backbone.View.extend({

    templateStatusChangeModal: UC.views.resolveHandlebarsTemplate('public/jobs/modal_change_status'),

    initialize: function (options) {
      this.jobId = options.jobId;
      this.jobStatus = options.jobStatus;
      this.redirectAfterSave = options.redirectAfterSave;
      this.statusChangeCallback = options.statusChangeCallback;
    },

    markBiddingClosed: function () {
      this.showStatusChangeModal({
        title: 'Change Bidding Options?',
        subtitle: 'Limit project to current proposals.',
        btnText: 'Okay, Close Bidding',
        status: UC.constants.JobStatus.BiddingClosed
      });

      this.$statusModal.find('#showMarkCanceled').click(function (e) {
        e.preventDefault();
        this.markCanceled();
      }.bind(this));
    },

    markCanceled: function () {
      this.showStatusChangeModal({
        title: 'Cancel Your Job',
        subtitle: 'Cancel your job if you do not plan to hire an attorney.',
        reasonsLabel: 'Why are you cancelling your job?',
        reasons: UC.constants.jobAbandonReasons,
        btnText: 'Okay, Cancel This Job',
        status: UC.constants.JobStatus.Abandoned
      });
    },

    markRemoved: function () {
      this.showStatusChangeModal({
        title: 'Remove Job',
        btnText: 'Okay, Remove This Job',
        reasonsLabel: 'Why are you removing this job?',
        reasons: UC.constants.jobRemoveReasons,
        status: UC.constants.JobStatus.Removed
      });
    },

    showStatusChangeModal: function (options) {
      var that = this;

      options.admin = UC.user.is_admin;
      options.cancel = (options.status == UC.constants.JobStatus.Abandoned);
      options.remove = (options.status == UC.constants.JobStatus.Removed);
      options.closeBidding = (options.status == UC.constants.JobStatus.BiddingClosed);
      options.reopenCheck = (UC.user.is_admin && this.jobStatus != UC.constants.JobStatus.WorkInProgress && this.jobStatus != UC.constants.JobStatus.PaymentRequested);

      this.$statusModal = $(this.templateStatusChangeModal(options));
      this.$statusModal.find('#btnChangeJobStatus').on('click', function (e) {
        that.btnChangeStatusClick(e);
      });
      if (options.cancel) {
        this.$statusModal.find('#selectReason').on('change', function (e) {
          that.toggleReasonExplain(e);
        });
      } else if (options.remove) {
        this.$statusModal.find('#selectReason').on('change', function (e) {
          that.enforceClientReopenability(e);
        });
      }

      this.loader = this.$statusModal.find('#btnChangeJobStatus').buttonLoader();

      // remove the modal completely when we're done
      this.$statusModal.modal();
      this.$statusModal.on('hidden.bs.modal', function () {
        $(this).remove();
      });

      if (options.admin && options.remove) {
        this.$statusModal.find('#rowReasonExplain').removeClass('hidden');
      }
    },

    btnChangeStatusClick: function (e) {
      e.preventDefault();

      var status = this.$statusModal.find('#btnChangeJobStatus').data('status'),
        reason_id = null,
        reason_text = null,
        require_reason = false,
        $selectReason = this.$statusModal.find('#selectReason');

      switch (status) {
        case UC.constants.JobStatus.Abandoned:
          reason_id = $selectReason.val();
          break;
        case UC.constants.JobStatus.Removed: // currently admins only
          reason_id = $selectReason.val();
          require_reason = true;
          break;
      }

      reason_text = this.$statusModal.find('#txtReasonExplain').val();
      var $errorMsg = this.$statusModal.find('#selectReasonErrorMsg');
      if (require_reason && !reason_id) {
        $errorMsg.text('Please select a reason.');
        $errorMsg.closest('.form-group').addClass('has-error');
        return false;
      } else {
        $errorMsg.text('');
        $errorMsg.closest('.form-group').removeClass('has-error');
      }

      this.changeStatus(status, {
        id: reason_id,
        text: reason_text
      });
    },

    changeStatus: function (status, reason_obj) {
      var url = '/job/change_status/{0}'.format(this.jobId),
        $cancel = this.$statusModal.find('.jqmClose'),
        $chkSendEmail = this.$statusModal.find('#chkSendEmail'),
        $chkReopenable = this.$statusModal.find('#chkReopenable');

      var data = {
        status: status,
        reason: reason_obj,
        send_email: false,
        reopenable: true
      };

      if ($chkSendEmail.length > 0) {
        data.send_email = $chkSendEmail.is(':checked');
      }

      if ($chkReopenable.length > 0) {
        data.reopenable = $chkReopenable.is(':checked');
      } else if (this.jobStatus == UC.constants.JobStatus.WorkInProgress || this.jobStatus == UC.constants.JobStatus.PaymentRequested) {
        data.reopenable = false;
      }

      this.loader.showLoader();

      UC.net.post(url, data, $.proxy(function (err, data) {
        if (err) {
          UC.utils.showNotification(err, UC.constants.results.failure);
          $cancel.modal();
          this.loader.hideLoader();
        } else {

          if (this.redirectAfterSave === false) {
            UC.utils.showNotification(data.message, UC.constants.results.success);
            this.$statusModal.modal('hide');
            if (typeof this.statusChangeCallback === 'function') {
              this.statusChangeCallback(data);
            }
          } else {
            window.location.href = data.url;
          }
        }
      }, this));
    },

    enforceClientReopenability: function (e) {
      var $el = $(e.target),
        isReopenable = $el.find('option:selected').data('reopenable'),
        reopenableCheckbox = this.$statusModal.find('#chkReopenable');

      reopenableCheckbox.prop('checked', isReopenable);
      reopenableCheckbox.prop('disabled', !isReopenable);
    },

    toggleReasonExplain: function (e) {
      var $el = $(e.target);

      if ($el.find('option:selected').text() == 'Other') {
        this.$statusModal.find('#rowReasonExplain').removeClass('hidden');
      } else {
        this.$statusModal.find('#rowReasonExplain').addClass('hidden');
      }
    }
  });

}();
