UC.views.CalendarsIndex = Backbone.View.extend({
  events: {
    "click #disconnect-cal" : "disconnectCal",
    'click .js-connect-cal': 'handleConnectCallClick'
  },

  routes: {
    disconnect_calendar: '/calendars/disconnect',
    calendars: '/calendars'
  },

  initialize: function() {
    // This div will only be present if the user's calendar has been connected
    if ($('#availability-time-range-container').length) {
      new UC.components.AvailabilityTimeRangesView({
        el: '#availability-time-range-container'
      });
      new UC.partials.SelectCalendar({
        btnSelector: '#select-cal',
        openImmediately: UC.constants.showSelectCalModal
      });
    }

    if (UC.utils.getParameterByName('post_auth') === 'true') {
      window.history.pushState({}, null, '/calendars')
    }
  },
  
  handleConnectCallClick: function() {
    UC.utils.sendNesEvent('connect_your_calendar_clicked', { attorney_id: UC.user.id });
  },

  disconnectCal: function(e) {
    e.preventDefault();
    var confirmationMsg = 'Are you sure you wish to disconnect your calendars?';

    UC.utils.showConfirmModal('Confirm', confirmationMsg, function (confirmed) {
      if (confirmed) {
        UC.net.destroy(this.routes.disconnect_calendar, null, function (data) {
          location.href = this.routes.calendars;
        }.bind(this));
      }
    }.bind(this));
  }

});
