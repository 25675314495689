!function() {

  UC.views.StaticPagesPress = Backbone.View.extend({
    events: {
      'click #see-more-press' : 'getAdditionalPressClippings'
    },

    initialize: function() {
      _.bindAll(this, 'handlePressClippingsResponse');

      this.paginationNumberOfClippings = UC.constants.pagination_number_of_clippings;
      this.pageNum = UC.constants.starting_press_clipping_page;
      this.pressClippingsAccountedFor = this.paginationNumberOfClippings * this.pageNum;
      this.totalPressClippingsCount = UC.constants.total_press_clipping_count;
      this.seeMoreButtonLoader = $('#see-more-press').buttonLoader();
    },

    getAdditionalPressClippings: function(e) {
      e.preventDefault();
      this.pageNum++;
      this.seeMoreButtonLoader.showLoader();
      $.ajax({
        url: '/press/' + this.pageNum,
        type: 'GET',
        success: this.handlePressClippingsResponse,
        error: function() {
          this.seeMoreButtonLoader.hideLoader();
        }.bind(this)
      })
    },

    handlePressClippingsResponse: function(jsonObject) {
      this.seeMoreButtonLoader.hideLoader();
      this.pressClippingsAccountedFor += jsonObject.press_clippings_rendered_count;
      if (this.pressClippingsAccountedFor >= this.totalPressClippingsCount) {
        $('#show-more-press-button-container').remove();
      }
      this.renderAdditionalPressClippings(jsonObject.press_clippings_html);
    },

    renderAdditionalPressClippings: function(pressClippingsHtml) {
      $('#press-clippings').append(pressClippingsHtml);
    }
  })

}();
