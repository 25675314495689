UC.views.InvoicesIndex = Backbone.View.extend({

  events: {
    'change #selectInvoiceFilter': 'onChangeInvoiceStatusFilter'
  },

  initialize: function() {
    $('[data-toggle="tooltip"]').tooltip();
  },

  onChangeInvoiceStatusFilter: function () {
    var $selectInvoiceFilter = this.$el.find('#selectInvoiceFilter');
    var billedto = UC.utils.getParameterByName('billedto');
    var params = {
      Status: UC.utils.findSelectedElement($selectInvoiceFilter).val()
    }

    if (billedto) {
      params.billedto = billedto;
    }

    location.href = '/invoices?{0}'.format($.param(params));
  }
});
