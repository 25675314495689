var _cancelScheduledCalls = function(id) {
  return UC.net.post('/scheduled_calls/{0}/cancel'.format(id));
};

var _indexScheduledCalls = function() {
  return UC.net.get('/scheduled_calls?job_id={0}'.format(UC.job.id))
}

UC.views.JobOverviewScheduledCalls = Backbone.View.extend({
  template: UC.views.resolveHandlebarsTemplate("components/job_overview_scheduled_calls"),

  events: {
    'click .js-call-cancel': 'handleCancel',
    'click .js-call-reschedule': 'handleReschedule'
  },

  routes: {
    proposalsPageLink: '/jobs/{0}/proposals',
    messagesPageLink: '/job/messagelist/{0}'
  },

  handleCancel: function(e) {
    var id = $(e.target).data('id')
    new UC.partials.ScheduledCallsCancel({
      scheduledCallId: id,
      callback: function(isConfirmed) {
        if (isConfirmed) {
          _cancelScheduledCalls(id).then(function(res) {
            this.refreshScheduledCallsList();
          }.bind(this))
        }
      }.bind(this)
    })
  },

  handleReschedule: function(e) {
    var dataset = e.target.dataset;
    var scheduledCallId = dataset.id;
    var jobId = dataset.jobId;
    var bidId = dataset.bidId;
    var attorneyId = dataset.attorneyId;
    var instantAvailable = dataset.instantAvailable === 'true';
    UC.CallHelpers.rescheduleCall(scheduledCallId, jobId, bidId, attorneyId, instantAvailable, this.handleSuccessfulReschedule.bind(this, scheduledCallId));
  },

  handleSuccessfulReschedule: function(scheduledCallId) {
    _cancelScheduledCalls(scheduledCallId).then(function(res) {
      location.reload();
    }.bind(this))
  },

  initialize: function(opts) {
    this.$el = opts.el;
    this.refreshScheduledCallsList();
    var jobId = UC.job.id;
    this.proposalsPageLink = this.routes.proposalsPageLink.format(jobId)
    this.messagesPageLink = this.routes.messagesPageLink.format(jobId)
  },

  _formatData: function(scheduledCall) {
    scheduledCall.isScheduled = scheduledCall.Status === 'scheduled'
    scheduledCall.isPending = scheduledCall.Status === 'pending'
    return scheduledCall
  },

  refreshScheduledCallsList: function() {
    return _indexScheduledCalls()
      .done(function(res) {
        this.scheduledCalls = res.scheduled_calls.map(this._formatData);
        this.isEmptyState = this.scheduledCalls.length === 0;
        this.render();
      }.bind(this))
      .fail(function() {
        this.render();
      }.bind(this));
  },

  render: function() {
    this.$el.html(this.template(this));
    return this;
  }
});
