!function() {

    UC.views.JobManageCog = Backbone.View.extend({

        templateContactAttorneyModal: UC.views.resolveHandlebarsTemplate('public/jobs/modal_message_attorney'),

        events: {
            'click #btnStopProposals': 'btnStopProposalsClick',
            'click #btnCancelJob': 'btnCancelJobClick',
            'click #btnRemoveJob': 'btnRemoveJobClick',
            'click #btnMessageMyAttorney': 'btnMessageMyAttorneyClick',
            'click #btnEditJobTitle': 'btnEditJobTitle',
            'click #btnInviteNow': 'btnInviteNow',
            'click #btnMoveReady': 'btnMoveReady',
            'click #btnSkip': 'btnSkip'
        },

        initialize: function(options) {
            this.data = {};
            this.jobId = options.jobId || this.$el.data('id');
            this.jobStatus = options.jobStatus || this.$el.data('status');
            this.onBtnEditJobTitle = options.onBtnEditJobTitle;
            this.onBtnInviteNow = options.onBtnInviteNow;
            this.onBtnMoveReady = options.onBtnMoveReady;
            this.onBtnSkip = options.onBtnSkip;

            this.statusChangeView = new UC.views.JobsModalChangeStatus({
                jobId: this.jobId,
                jobStatus: this.jobStatus,
                redirectAfterSave: options.redirectAfterSave,
                statusChangeCallback: options.statusChangeCallback
            });

            if (UC.constants.showCancelJobModalOnPageLoad) {
              this.openCancelJobModal();
            }

            $('body').on('userCancelJobClick', this.openCancelJobModal.bind(this));
        },

        btnEditJobTitle: function(e) {
            this.onBtnEditJobTitle(e);
        },

        btnInviteNow: function(e) {
            this.onBtnInviteNow(e);
        },

        btnMoveReady: function(e) {
            this.onBtnMoveReady(e);
        },

        btnSkip: function(e) {
            this.onBtnSkip(e);
        },

        btnStopProposalsClick: function (e) {
            e.preventDefault();

            if (this.jobStatus != UC.constants.JobStatus.Open) {
                UC.utils.showNotification('Only Open jobs can be changed to the Bidding Closed status. This job is not eligible to be updated as Bidding Closed.', UC.constants.results.failure);
            } else {
                this.statusChangeView.markBiddingClosed();
            }
        },

        btnCancelJobClick: function(e) {
            e.preventDefault();
            this.openCancelJobModal();
        },

        openCancelJobModal: function() {
          if (this.jobStatus == UC.constants.JobStatus.Open || this.jobStatus == UC.constants.JobStatus.BiddingClosed || UC.user.is_admin) {
            this.statusChangeView.markCanceled();
          } else {
            UC.utils.showNotification('Only Open jobs can be changed to the Abandoned status. This job is not eligible to be updated as Abandoned.', false);
          }
        },

        btnRemoveJobClick: function(e) {
            e.preventDefault();

            if (this.jobStatus != UC.constants.JobStatus.Removed && UC.user.is_admin) {
                this.statusChangeView.markRemoved();
            } else {
                UC.utils.showNotification('This job may not be removed.', false);
            }
        },

        btnMessageMyAttorneyClick: function(e) {
            e.preventDefault();
            this.data.jobAttorneyId = $(e.target).data('aid');

            if (this.data.jobAttorneyId) {
                var $modal = $('#modalMessageAttorney');
                if (!$modal.length) {
                    $('body').append(this.templateContactAttorneyModal());
                    $modal = $('#modalMessageAttorney');
                    $modal.find('#btnSendContactMessage').on('click', function(e) {
                        this.btnSendContactMessageClick(e);
                    }.bind(this));
                }

                $modal.modal('show');
            }

            UC.utils.recordKissEvent('Job Page: Bid Actions - Send Message Click');
        },

        btnSendContactMessageClick: function(e) {
            var $modal = $('#modalMessageAttorney');
            var message = $modal.find('#txtContactMessage').val();
            var loader = $modal.find('#btnSendContactMessage').buttonLoader();

            if (message !== '') {
                loader.showLoader();
                this.sendAttorneyMessage(message, function(result) {
                  UC.utils.showNotification(result.message, UC.constants.results.success);
                    $modal.modal('hide');
                    $modal.find('#txtContactMessage').val('');
                    loader.hideLoader();
                }.bind(this)).fail(function() { loader.hideLoader(); });
            }
        },

        sendAttorneyMessage: function(message, callback) {
            var data = {
              message: {
                JobId: this.jobId,
                Message: message
              }
            };

            return UC.net.post('/attorney/contact/{0}'.format(this.data.jobAttorneyId), data, callback);
        }
    });
}();
