var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tc\"><input type=\"text\" class=\"new-logo-name\"/></div>\n\n<div class=\"tc press-logo-image-newly-uploaded\"><img src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"ImageUrl") : stack1), depth0))
    + "\"></div>\n\n<div class=\"tc text-right press-logo-actions-newly-uploaded\">\n  <button class=\"btn btn cancel-new-logo-button\">Cancel</button>\n  <button class=\"btn btn btn-primary save-new-logo-button\">Save</button>\n</div>\n";
},"useData":true});