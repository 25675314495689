UC.net = {};

UC.net.invoke = function (method, path, data, callback, options) {
  options = options || {};
  callback = callback || $.noop;

  var loaders = options.loaders ? options.loaders : null;
  var params = {
    url: path,
    type: method,
    data: data,
    headers: { accept: 'application/json' },
    success: function (response) {

      if (response && response.url) {
        location.href = response.url;
      } else if (response && response.result) {
        // old skool. deprecated. don't use.
        if (response.result == 'success') {
          callback(null, response.message);
        } else {
          callback(response.message, response.result);
        }
      } else {
        callback(response);
      }
    }
  };

  if (loaders) {
    UC.utils.showLoaders(loaders);
    params.complete = function() { UC.utils.hideLoaders(loaders); };
    delete options.loaders;
  }

  if (method.toLowerCase() !== 'get') {
    params.contentType = 'application/json';
    params.data = JSON.stringify(data);
  } else {
    params.cache = false // otherwise you woth get success callback when page is taken from browser cache (http 304)
  }

  params = $.extend(params, options);

  return $.ajax(params);
};

$.each(['get', 'post', 'put', 'destroy'], function(i, type) {
  var method = type;

  if (type === 'destroy') method = 'delete';

  UC.net[type] = function(path, data, callback, options) {

    // Shift arguments if data argument was omitted
    if ($.isFunction(data)) {
      options = callback;
      callback = data;
      data = undefined;
    }

    return UC.net.invoke(method, path, data, callback, options);
  }
});

// default response to display a notification if a message
UC.net.onDefaultResponse = function (err, message) {
  if (err) {
    UC.utils.showNotification(err, UC.constants.results.failure);
  }
  else {
    UC.utils.showNotification(message, UC.constants.results.success);
  }
};

// our magical global ajax error handler
$(document).ajaxError(function (e, xhr, settings, error) {

  if (xhr.status >= 400 && !xhr.handled && error !== 'abort') {
    var data = xhr.responseJSON;
    var message = "An error occurred. Please try again later.";

    if (data && data.message) {
      message = data.message;
    }

    if (data && data.url) {
      location.href = data.url;
      return;
    }

    UC.utils.showNotification(message, UC.constants.results.failure);
    console.warn(message)
  }
});
