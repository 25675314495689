UC.partials.StripeTosAcceptedCheckbox = Backbone.View.extend({
  initialize: function (opts) {
    this.errorMessage = opts.errorMessage || 'Must check to agree';
    this.$chkAccountTos = this.$el.find('#chkAccountTos').fancyCheck({
      label: '<span class="text-bold">I have read and agree to the </span><a class="text-bold" href="https://stripe.com/connect/account-terms" target="_blank">Stripe Connected Account Agreement.</a>'
    });
  },

  isMissingOrChecked: function() {
    if (this.$chkAccountTos.length === 0 || this.$chkAccountTos.check()) {
      return true;
    }

    UC.utils.showValidationErrors([
      {element: this.$chkAccountTos, text: this.errorMessage}
    ]);
    return false;
  }
});
