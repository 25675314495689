UC = UC || {};

UC.plaidBankVerification = function(args) {
  this.openPlaidLinkSelector = args.openPlaidLinkSelector;
  this.successRedirectUrl = args.successRedirectUrl || '/account/billing';
  this.successCallback = args.successCallback;
  this.$openPlaidLinkElement = $(this.openPlaidLinkSelector);
  this.showDefaultButtonLoader = !!args.showDefaultButtonLoader;
  if (this.showDefaultButtonLoader) {
    this.buttonLoader = this.$openPlaidLinkElement.buttonLoader();
  }
};

UC.plaidBankVerification.prototype = {
  init: function() {
    if (window.Plaid) {
      this.initHandler();
    }
  },

  initHandler: function() {
    this.$openPlaidLinkElement.on('click', function (e) {
      e.preventDefault();

      this.showLoader();

      UC.net.get('/account/plaid_auth_link_token',
          function (data) {
            this.plaidLinkHandler = Plaid.create({
              token: data.token,
              env: UC.constants.plaidEnv,
              onLoad: this.plaidLinkLoad.bind(this),
              onSuccess: this.plaidLinkAuthSuccess.bind(this),
              onExit: this.plaidLinkExit.bind(this)
            });

            this.openPlaid();
          }.bind(this)
      ).fail(this.plaidFail.bind(this));
    }.bind(this));
  },

  showLoader: function() {
    if (this.showDefaultButtonLoader) {
      this.buttonLoader.showLoader();
    } else {
      this.$openPlaidLinkElement.addClass('loader-enabled');
    }
  },

  hideLoader: function() {
    if (this.showDefaultButtonLoader) {
      this.buttonLoader.hideLoader();
    } else {
      this.$openPlaidLinkElement.removeClass('loader-enabled');
    }
  },

  openPlaid: function() {
    this.plaidLinkHandler.open();
    this.$openPlaidLinkElement.trigger('plaidLinkOpen');

    UC.utils.recordKissEvent('plaid_link_modal_opened', { location: window.location.href })
  },

  plaidLinkAuthSuccess: function(publicToken, metadata) {
    UC.net.post('/account/plaid_auth', { public_token: publicToken, account_id: metadata.account_id })
      .done(function(data) {
        if (typeof this.successCallback !== 'undefined') {
          this.successCallback();
        } else if (data && data.payment_method_id) {
          var href = this.successRedirectUrl;
          if(this.successRedirectUrl.includes('?')) {
            href += '&'
          } else {
            href += '?'
          }

          window.location.href = href + 'payment_method=' + data.payment_method_id;
        } else {
          window.location.href = this.successRedirectUrl;
        }

        this.$openPlaidLinkElement.trigger('plaidLinkAuthSuccess');
      }.bind(this))
      .fail(this.plaidFail.bind(this));
  },

  plaidFail: function () {
    this.$openPlaidLinkElement.trigger('plaidLinkAuthFailure');

    this.hideLoader();

    UC.utils.recordKissEvent('plaid_bank_auth_failed', { location: window.location.href });
  },

  plaidLinkExit: function(err) {
    if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
      this.plaidLinkHandler.destroy();
      this.initHandler();
      return
    }
    this.$openPlaidLinkElement.trigger('plaidLinkExit');
    if (this.showDefaultButtonLoader) {
      this.buttonLoader.hideLoader();
    } else {
      this.$openPlaidLinkElement.removeClass('loader-enabled');
    }

    UC.utils.recordKissEvent('user_exited_plaid_link_modal', { location: window.location.href })
  },

  plaidLinkLoad: function() {
    this.$openPlaidLinkElement.trigger('plaidLinkModuleLoaded');
  }
};
