import { cancelSignatureRequest, openDropboxSignForClaim, openDropboxSignForSign } from './dropbox_sign'
import { loadFileStackScript, pickFileAndStore } from '../../../utils/filestack'

!function() {

  UC.views.UserDocumentsIndex = Backbone.View.extend({

    events: {
      'click .container .document-choose': 'promptChoose',
      'click .container .document-delete': 'confirmDestroy',
      'click .container .document-share': 'openShareModal',
      'click .container .document-cancel': 'cancelSignatureRequest',
      'click .container .document-replace': 'createRevision',
      'click .container .restart-doc-tutorial': 'runTutorial',
      'click .container .document-request-signature': 'openChooseSignersModal',
      'click .container .document-sign': 'openForSign',
      'click .container #lnkLearnMoreHS': 'openLearnMoreHS',
      'click .container #tblFilterList td': 'clickFilterItem',
      'click .container #docListPrev, .container #docListNext': 'renderPage',
      'click .check-all': 'toggleCheckAll',
      'click .document-row': 'selectBetween',
      'click .container .doc-filter:not(.active)': 'changeFilter',
      'click .container .documents_search_clear': 'changeFilter',
      'click .container .sort-menu li': 'changeFilter',
      'submit .container .documents_search': 'search',
      'click .container .js-document-create': 'promptUpload'
    },

    elements: {
      'docIntro': null,
      'btnFilePicker': null,
      'modalDocumentPermissions': null,
      'modalLearnMoreHS' : null
    },

    initialize: function() {
      this.components = {};
      this.data = {};

      // important. loads filepicker.js
      loadFileStackScript(this.afterInitialize.bind(this));
    },

    afterInitialize: function() {
      UC.utils.setSelectors(this.elements);

      // make upload buttons visible now that filepicker has loaded
      this.$('.js-document-create').removeClass('invisible');
      this.$('#btnToggleUpload').removeClass('invisible');

      this.components.btnFilePickerLoader = this.elements.btnFilePicker.buttonLoader();
      this.modal_share = new UC.modals.UserDocumentsModalShare();
      this.modal_choose_signers = new UC.modals.UserDocumentsModalChooseSigners({
        parent_view: this,
        submitCallback: this.onPrepareForSigning });
      // this.modal_visibility = new UC.modals.UserDocumentsModalVisibility({
      //   onSave: this.toggleRowVisibilityIcon
      // });
      this.modal_choose_document = new UC.modals.UserDocumentsModalChooseDocument({
        parent_view: this,
        $el: this.$el.find('#modalChooseDocument'),
        save_callback: this.refreshList.bind(this)
      });

      this.fixDropDownForMobile();

      if (window.location.hash.indexOf('#upload') != -1) {
        this.promptUpload();
      }

      this.initList();

      if (UC.constants.pageName != 'documents'){
        var html = this.$el.find('.doc-list-wrapper')[0].outerHTML,
            newPath = location.pathname,
            query = UC.utils.getParameterByName('q');

        // set initial state info
        history.replaceState({
          html: html, nav: newPath, query: query
        }, '', location.pathname + location.search);

        window.onpopstate = function(e){
          if (e.state && e.state.html) {
            this.initList(e.state.html);
          }
        }.bind(this);
      }

      UC.utils.recordKissClickEvent('.document-download','User Document - Download (cog)',{'user_type' : UC.user.type ,'document_id' : this.document_id});
    },

    fixDropDownForMobile: function() {
      $('#document-dropdown').click(function (e) {
        // Fix for click not dropping down on iOS
        e.preventDefault();
        setTimeout($.proxy(function () {
          if ('ontouchstart' in document.documentElement || window.navigator.msPointerEnabled) {
            $(this).siblings('.dropdown-backdrop').off().remove();
          }
        }, this), 0);
      });
    },
    
    toggleCheckAll: function(e) {
      if (this.$el.find('.check-all').hasClass('checked')) {
        this.selectAll(e);
      } else {
        this.selectNone(e);
      }
    },

    search: function(e) {
      e.preventDefault();

      var $search_input = this.$el.find('.documents_search_input');

      this.data.query = $search_input.val();

      this.changeFilter(e).done(function() {

        if (this.data.query && this.data.query !== '') {
          this.$el.find('.documents_search_clear').removeClass('hide');
        }

        this.data.query = null;

        this.$el.find('.documents_search_input').focus(function() {
          this.selectionStart = this.selectionEnd = this.value.length;
        }).focus();
      }.bind(this));
    },

    changeFilter: function(e){
      e.preventDefault();

      var query = this.data.query;
      var filter = $(e.target).data('filter') || '';
      var kissData = {
        location: 'central_docs',
        filter: filter,
        userId: UC.user.id,
        query: query
      };

      UC.utils.recordKissEvent('document_filter_changed', kissData);

      return $.get('/documents/{0}'.format(filter), { q: query }, function(html) {
        this.data.filter = filter;
        this.pushHistoryState(filter, location.pathname, html, query);
        this.initList(html);
      }.bind(this));
    },

    pushHistoryState: function(filter, pathname, html, query) {
      var nav;
      var newPath;

      if (pathname.match(/\//g).length == 1) {
        if (filter && filter != '') {
          nav = '/documents/{0}'.format(filter);
        } else {
          nav = '/documents';
        }
      } else if (pathname.match(/\//g).length == 2){
        if (filter && filter != '') {
          nav = '../documents/{0}'.format(filter);
        } else {
          nav = '../documents';
        }
      } else {
        if (filter && filter != '') {
          nav = '../../documents/{0}'.format(filter);
        } else {
          nav = '../../documents';
        }
      }

      newPath = nav;

      if (query && query != '') {
        newPath += '?q={0}'.format(query);
      }

      history.pushState({
        html: html,
        nav: nav,
        query: query
      }, filter, newPath);
    },

    buildNavFromString: function(string, query) {

      if (query && query != '') {
        string += "/Search Results for '{0}'".format(query);
      }

      var crumbs = string.split('/'),
          html = '';

      for (var i = 1; i < crumbs.length; i++) {
        var crumb = crumbs[i].capitalize();

        if (i < crumbs.length - 1) {
          html += "<li class='doc-filter'><a href='#' data-filter=''>{0}</a></li>".format(crumb);
        } else {
          html += "<li class='active doc-filter'>{0}</li>".format(crumb);
        }
      };
      return html;
    },

    clickFilterItem: function(e){
      var $row = $(e.target).closest('tr'),
          targetId = $row.data('id'),
          title = $row.data('title'),
          lastBreadCrumb = $('.wide-nav .breadcrumb li.active').text(),
          filter = this.data.filter,
          kissData = {
            location: 'central_docs',
            filter: filter,
            item_id: targetId,
            userId: UC.user.id
          };

      UC.utils.recordKissEvent('document_filter_item_clicked', kissData);
      UC.net.get('/documents/{0}/{1}'.format(filter, targetId), function(html) {
        $('.wide-nav .breadcrumb li.active').html("<a href='#' data-filter='{0}'>{1}</a>".format(filter, lastBreadCrumb));
        $('.wide-nav .breadcrumb li').removeClass('active');
        if (filter== 'documents') {
          var titlePath = "{0}/{1}".format(lastBreadCrumb, title);
        } else {
          var titlePath = "/documents/{0}/{1}".format(lastBreadCrumb, title);
        }
        history.pushState({html: html, nav: titlePath}, title, "{0}/{1}".format(filter, targetId));
        this.initList(html);
      }.bind(this));
    },

    resetList: function() {
      // remove and unbind actions if exists
      if (this.actions) this.actions.remove();
      // remove and unbind any existing rows
      _.each(this.rows, function(row) {
        // remove backbone view & unbind events
        row.remove();
      });
      this.rows = [];
    },

    initList: function(html) {
      var that = this;
      var $list = this.$el.find('.doc-list-wrapper');

      // reset rows first
      this.resetList();

      if (html) {
        $list.replaceWith(html);
      }

      var $rows = this.$el.find('tr.document-row');
      var $learn_more = this.$el.find('.restart-doc-tutorial');

      if ($rows.filter('.document-owner').length > 0) {
        $learn_more.removeClass('hidden');
      } else {
        $learn_more.addClass('hidden');
      }

      $rows.each(function() {
        var view = new UC.views.UserDocumentsRow({
          el: this
        });
        that.rows.push(view);
      });

      this.actions = new UC.views.UserDocumentsBulkActions({
        el: this.$el.find('.doc-list-actions')[0],
        index: this
      });

      this.$check = this.$el.find('.check-all').fancyCheck();
      this.setNavCount();
    },

    setNavCount: function() {
      var $count = $('a.jn-documents .jn-count');
      var count =
        parseInt(this.$el.find('.doc-list').data('total-entries'), 10);

      if (count > 0) {
        $count.html(count).removeClass('hidden');
      } else {
        $count.addClass('hidden');
      }
    },

    openLearnMoreHS: function(e) {
      e.preventDefault();
      UC.utils.showModal(this.elements.modalLearnMoreHS);
    },

    openFilePicker: function(callback) {
      let path = 'user_{0}/'.format(UC.user.id);

      if (UC.job) {
        path += 'job_{0}/'.format(UC.job.id);
      }

      pickFileAndStore({
        fileStackApiKey: UC.constants.sharedFileStackApiKey,
        pickerOptions: {
          fromSources: ['local_file_system', 'box', 'dropbox', 'googledrive', 'onedrive'],
          maxFiles: 10,
          onUploadDone: function (res) {
            if (res.filesUploaded && res.filesUploaded.length > 0) {
              callback.call(this, res.filesUploaded);
            }
          }.bind(this)
        },
        storeOptions: {
          location: 's3',
          path: path
        }
      })

      UC.utils.recordKissEvent('Open File Picker - User Document', {'user_type': UC.user.type});
    },

    promptUpload: function(e) {
      if (e) e.preventDefault();

      this.openFilePicker($.proxy(function(InkBlobs) {
        this.processUpload(InkBlobs);
      }, this));
    },

    promptChoose: function(e) {
      if (e) e.preventDefault();
      UC.utils.recordKissEvent('choose_document_modal_open', {'userId':UC.user.id});
      this.modal_choose_document.render({job_id: UC.constants.jobId});
    },

    processUpload: function(files) {
      var that = this,
          data = {
            files: files,
            job_id: UC.constants.jobId
          }

      if (UC.constants.pageName == 'index') data.includeSort = true;
      this.components.btnFilePickerLoader.showLoader();

      UC.net.post('{0}.html'.format(UC.constants.user_documents_path), data, function(html) {
        that.initList(html);

        var $new_rows = that.$el.find('tr.document-row.new');

        if ($new_rows.length == 1) {

          var document_id = $new_rows.first().data('id');

          if (UC.constants.pageName != 'index'){
            if (UC.constants.jobHasBids || UC.constants.jobIsHired || UC.user.type === 'attorney') {
              that.modal_share.render({
                document_ids: [document_id],
                onPermissionsUpdated: this.onDocumentPermissionsUpdated.bind(this),
              });
            }
          }
        }
      }).complete(function() {
        that.components.btnFilePickerLoader.hideLoader();
      });
    },

    confirmDestroy: function(e) {
      e.preventDefault();

      var that = this;
      var $row = $(e.target).closest('tr');
      var document_id = $row.data('child');

      function onConfirm(response) {
        that.destroy([document_id]);
      }

      new UC.partials.modalV9({
        modalTitle: 'Delete Document',
        modalContent: 'Are you sure you want to delete this document?',
        modalFooter: {
          yesTextClass: 'btn-lg btn-primary js-confirm-delete',
          yesText: 'Confirm',
          noTextClass: 'btn-lg btn-grey-BG',
          noText: 'Cancel'
        },
        renderCallback: function() {
          $('.js-confirm-delete').on('click', function() { onConfirm(); });
        }
      })
    },

    openChooseSignersModal: function(e) {
      e.preventDefault();
      if (!this.modal_choose_signers) {
        return;
      }

      var $row = $(e.target).closest('tr'),
          document_id = $row.data('child');

      this.modal_choose_signers.render({
        document_id: document_id
      });
    },

    onPrepareForSigning: function(data){
      openDropboxSignForClaim(data, this);
    },

    openForSign: function(e){
      e.preventDefault();

      openDropboxSignForSign($(e.target).closest('tr').data('child'));
    },

    getPostDestroyPage: function() {
      var $list = this.$el.find('.doc-list');
      var page = parseInt($list.data('page'), 10);

      var unchecked = _.filter(this.rows, function(row) {
        return !row.isChecked();
      });

      if (page > 1 && unchecked.length <= 0) {
        // previous page if we're deleting entire page
        return page - 1;
      } else {
        return page;
      }
    },

    destroy: function(document_ids) {
      var list = document_ids.join();
      UC.utils.recordKissEvent('User Document Cog', {'action':'delete','document_ids' : list});

      UC.net.destroy('{0}/{1}.html'.format(UC.constants.user_documents_path, list), {
        page: this.getPostDestroyPage(),
        job_id: UC.constants.jobId
      }, function(html) {
        this.initList(html);
      }.bind(this));
    },

    processRevisions: function(files, document_id) {

      this.components.btnFilePickerLoader.showLoader();

      UC.net.post("{0}/{1}/revision".format(UC.constants.user_documents_path, document_id), {
        files: files,
        job_id: UC.constants.jobId
      }, function(data) {
        location.reload();
      })
      .error($.proxy(function() {
        this.components.btnFilePickerLoader.hideLoader();
      }, this));
    },

    createRevision: function(e) {
      e.preventDefault();

      var $row = $(e.target).closest('tr');
      var document_id = $row.data('id');
      UC.utils.recordKissEvent('User Document Cog', {'action':'create_revision','document_id' : document_id});

      this.openFilePicker($.proxy(function(InkBlobs) {
        this.processRevisions(InkBlobs, document_id);
      }, this))
    },

    openShareModal: function(e) {
      e.preventDefault();
      var document_id = $(e.target).closest('tr').data('id');
      UC.utils.recordKissEvent('User Document Cog', {'action':'share','document_id' : document_id});
      this.modal_share.render({
        document_ids: [document_id],
        onPermissionsUpdated: this.onDocumentPermissionsUpdated.bind(this),
      });
    },

    onDocumentPermissionsUpdated: function(documentIds, isOpen) {
      _.each(this.rows, function(row) {
        if (documentIds.includes(row.documentId)) {
          row.updateVisibilityText(isOpen);
        }
      });
    },

    cancelSignatureRequest: function(e) {
      e.preventDefault();

      var document_id = $(e.target).closest('tr').data('child');
      cancelSignatureRequest(document_id);
    },

    selectAll: function(e) {
      e.preventDefault();
      var $list = $(e.target).closest('.doc-list-wrapper');
      $list.find('.bulk-check').each(function() {
        $(this).fancyCheck().check(true);
      });
    },

    selectNone: function(e) {
      e.preventDefault();
      var $list = $(e.target).closest('.doc-list-wrapper');
      $list.find('.bulk-check').each(function() {
        $(this).fancyCheck().check(false);
      });
    },

    selectBetween: function(e) {
      var $check = $(e.currentTarget).find('.bulk-check');
      var $checks = this.$el.find('.doc-list .bulk-check');

      if (this.$last_checked && e.shiftKey) {
        var checked = this.$last_checked.hasClass('checked');
        var start = $checks.index($check);
        var end = $checks.index(this.$last_checked);

        $checks.slice(Math.min(start,end), Math.max(start,end) + 1).each(function() {
          $(this).fancyCheck('check', checked);
        });
      }

      this.$last_checked = $check;
    },

    runTutorial: function() {
      var list = this.$el.find('.doc-list'),
          initialSteps = [],
          steps;

      function resetTutorial() {
        $('.introjs[data-step="2"]')
          .find('.dropdown-menu')
          .removeClass('open forced')
          .data('aria-open', false)
      }

      var container = list.find('.document-owner').eq(0);
      if (!container) list.find('.document-row');

      if (UC.user.type == "client") {
        //steps = initialSteps.concat([
        steps = [
          {
            element: container.find('.document-view').eq(0),
            text: "Click on the title of your document to view it."
          },
          {
            element: container.find('.popdown'),
            text: "The Actions menu is where you’ll find a number of options for your document.",
            position: "left"
          },
          {
            element: container.find('a.document-share'),
            text: "Use the Share action to change document visibility settings or to grant access to attorneys.",
            position: "left"
          },
          {
            element: container.find('a.document-replace'),
            text: "Use the Upload New Version action to upload a new version of the same document.",
            position: "left"
          },
          {
            element: container.find('a.document-versions'),
            text: "Use the Previous Versions link to view the document’s change history.",
            position: "left"
          },
          {
            element: container.find('a.document-request-signature'),
            text: "Use the Prepare to Sign action to electronically sign the document yourself, as well as send it out to 3rd parties for signature. 100% free!",
            position: "left"
          }
        ]
        //]);
      } else if (UC.user.type == "attorney") {
        //steps = initialSteps.concat([
        steps = [
          {
            element: container.find('.document-view').eq(0),
            text: "Click on the title of your document to view it or request access."
          },
          {
            element: container.find('.popdown'),
            text: "The Actions menu is where you’ll find a number of options for your document.",
            position: "left"
          },
          {
            element: container.find('.document-replace'),
            text: "Use the Upload New Version action to upload a new version of the same document.",
            position: "left"
          },
          {
            element: container.find('.document-versions'),
            text: "Use the Previous Versions link to view the document’s change history.",
            position: "left"
          },
          {
            element: container.find('.document-request-signature'),
            text: "Use the Prepare to Sign action to electronically sign the document yourself, as well as send it out to 3rd parties for signature. 100% free!",
            position: "left"
          }
        ]
        //]);
      }
  
      UC.utils.initIntroJs(steps).onchange(function(e) {
        var step = $(e).data('step');
  
        var $dropdown = $('.introjs[data-step="2"]');
        if(step == '3' || step == '4' || step == '5' || step == '6') {
          $dropdown
            .find('.dropdown-menu')
            .addClass('open forced')
            .data('aria-open', true)
        } else {
          $dropdown
            .find('.dropdown-menu')
            .removeClass('open forced')
            .data('aria-open', false);
        }
      }).onexit(function() {
        resetTutorial();
      }).oncomplete(function() {
        resetTutorial();
      });
    },

    renderPage: function(e, page) {
      if (e) e.preventDefault();

      var path = $(e.target).attr('href');

      UC.net.get(path, function(html) {
        this.initList(html);
      }.bind(this));

      UC.utils.scrollToTop();
    },

    refreshList: function(page){
      var page = page || 1;
      UC.net.get(location.href, {page: page}, function(html) {
        this.initList(html);
      }.bind(this));
    }
  })
}();
