import { createConsumer } from "@rails/actioncable"

UC.partials.AiLiveChat = Backbone.View.extend({
  liveChatMessageTemplate: UC.views.resolveHandlebarsTemplate('public/jobs/live_chat_message'),

  events: {
    'click #send-message-button' : 'sendMessageButtonClicked',
    'keypress #chat-message-input': 'handleEnterPress',
    'input #chat-message-input': 'autoResizeInputTextArea',
  },

  initialize: function() {
    const startMessage = "I will assist you in crafting your job description to keep our attorneys competitive in bidding on your post. I'll ask some follow-up questions and link you with the best attorneys for your needs.";
    const startMessage2 = "Please wait a bit while I process your details and get back to you. Thanks for your patience."

    const conversationConclusionMessage = "Great! I am now posting your job to the marketplace where attorneys will start bidding on your post. Thank you for choosing UpCounsel!"

    this.initiateAiMessage = "Please check the details what I have provided in first_message_job_details hash and ask me follow up questions on description. Also ask me if I have not provided any details and don't ask me for details which I have already provided."

    this.pajContainer = $('#paj-container');
    this.pajContainer.css({
      'transition': 'all 0.1s ease',
      'width': '100%'
    });
    this.pajContainer.addClass('live-chat-container col-lg-12 col-md-12 col-sm-12');

    this.headerRow = $('#header-row');
    this.headerRow.css({
      'position': 'relative'
    });

    const $affixPanels = $('.header-row .items');
    const affixAvailable = typeof $affixPanels.affix === 'function';
    if (affixAvailable) {
      const $chatPanel = $('.chat-bot-panel:visible');
      $affixPanels.css('width', $chatPanel.width());

      $affixPanels.affix({
        offset: {
          top: $chatPanel.offset().top,
        }
      });

      $(window).resize(function() {
        $affixPanels.css('width', $('.chat-bot-panel:visible').width());

        if (affixAvailable) {
          $affixPanels.affix('checkPosition');
        }

        if (!UC.browser.isMobile() && $('#job-summary-panel:visible').length) {
          this.hideSummaryLinkClicked()
        }
      }.bind(this));
    }

    this.appendMessage('typing');
    this.toggleSendMessageControlsAvailability(false);

    this.consumer = createConsumer();
    this.connected = false;
    this.jobSummary = null;
    this.jobLocation = null;
    this.chatBotMessagesQueue = [];
    this.firstMessageData = {
      message: startMessage,
      from: 'AI',
      summary: {},
      display_chat_message: true
    };
    this.firstMessageData2 = {
      message: startMessage2,
      from: 'AI',
      summary: {},
      display_chat_message: true
    };

    this.conversationConclusionMessageData = {
      message: conversationConclusionMessage,
      from: 'AI',
      summary: {},
      display_chat_message: true
    };

    this.chatChannel = this.consumer.subscriptions.create({
      channel: "JobChannel",
      lead_id: UC.constants.jobLeadId,
    }, {
      connected: () => {
        console.log("Connected to the JobChannel");
        if (!this.connected) {
          // to avoid greeting messages when re-connected
          this.connected = true;
          this.toggleSendMessageControlsAvailability(false)

          // Send first message from AI manually
          this.chatBotMessagesQueue.push(this.firstMessageData);
          this.chatBotMessagesQueue.push(this.firstMessageData2);
          this.popChatBotMessage().then(() => {
            this.appendMessage('typing')
            this.chatChannel.sendMessage(this.initiateAiMessage)
          });
          $('#chat-message-input').focus();
        }
      },
      received: (data) => {
        // Handle received data from AI
        if (data.message && data.display_chat_message) {
          this.chatBotMessagesQueue.push(data);
          this.popChatBotMessage().then(() => {
            if (this.resolveSendMessage) {
              this.resolveSendMessage();
              this.resolveSendMessage = null;
            }
          });
        }
        this.summaryData = data.summary;

        if (this.resolveSendMessage) {
          this.resolveSendMessage();
          this.resolveSendMessage = null;
        }
      },
      disconnected: () => {
        // Add call to disconnected when save/next button is clicked and job_lead stage is updated to closed
        // Handle disconnection
      },

      sendMessage: function(message) {
        this.perform('receive', { message: message, assistant_type: 'v7', first_message_job_details: UC.constants.aiForm });
      }
    });
  },

  onChatBotMessageTyped: function() {
    this.chatBotIsTyping = false;
    if (this.chatBotMessagesQueue.length == 0) {
      this.toggleSendMessageControlsAvailability(true);
    } else {
      this.popChatBotMessage();
    }
  },

  popChatBotMessage: function() {
    if (this.chatBotIsTyping || this.chatBotMessagesQueue.length == 0) {
      return Promise.resolve();
    }

    this.chatBotIsTyping = true;
    const messageData = this.chatBotMessagesQueue.shift();

    return this.appendMessage(messageData.message, messageData.from, true)
      .then(this.onChatBotMessageTyped.bind(this))
      .catch(this.onChatBotMessageTyped.bind(this));
  },

  appendMessage: function(message, from='AI', streamingEffect=false) {
    const chatConversation = $('.chat-bot-conversation');
    const lastMessage = chatConversation.find('.row:last-child');

    lastMessage.css('padding-bottom', '0');

    if (Array.isArray(message)) {
      message = message.join(); // OpenAI can send more than one message as a reply
    }
    if (message.match(/(<([^>]+)>)/i)) {
      streamingEffect = false;
    }

    const aiMessage = from.toLowerCase() === 'ai';
    const messageOriginator = aiMessage ? 'recipient' : 'sender'
    const senderName = aiMessage ? 'Rachel' : 'You'
    const imageUrl = aiMessage ? UC.constants.chatBotProfileUrl : UC.constants.userProfileUrl

    // remove 'typing' message
    this.$el.find('.typing-dots').closest('.row').remove();

    const $message = $(this.liveChatMessageTemplate({
      messageOriginator: messageOriginator,
      senderName: senderName,
      messageText: streamingEffect ? message.charAt(0) : message,
      userImageUrl: imageUrl,
      typingAnimation: message == 'typing'
    }));

    return new Promise(function(resolve, reject) {
      try {
        $('.chat-bot-conversation').append($message);
        if (streamingEffect) {
          this.typeTextMessage(message, $message.find('.chat-item-content-message')[0], 1, 0, resolve);
          this.autoResizeInputTextArea();
        } else {
          resolve();
          this.scrollChatToEnd();
        }
      } catch(e) {
        console.warn(e);
        reject();
      }
    }.bind(this));
  },

  typeTextMessage: function(message, streamingTextElement, currentIndex, lastHeight, resolve) {
    if (currentIndex >= message.length) {
      resolve(message);
      return;
    }

    let idx = message.indexOf(' ', currentIndex);
    if (idx < 0) {
      idx = message.length - 1;
    }

    streamingTextElement.textContent += message.substring(currentIndex, idx + 1);
    currentIndex = idx;
    const height = streamingTextElement.getBoundingClientRect().height;
    if (lastHeight == 0 || height > lastHeight) {
      this.scrollChatToEnd();
    }
    lastHeight = height;
    setTimeout(function() {
      this.typeTextMessage(message, streamingTextElement, currentIndex + 1, lastHeight, resolve);
    }.bind(this), 50); // Adjust the typing speed by changing the delay (in milliseconds)
  },

  autoResizeInputTextArea: function() {
    const textarea = $('#chat-message-input');
    const chatConversation = $('.chat-bot-conversation');
    const lastMessage = chatConversation.find('.row:last-child');

    // To calculate the actual content height
    textarea.css('height', 'auto');

    // 196px is the maximum height before scrolling starts
    let newHeight = Math.min(textarea.prop('scrollHeight'), 196);

    // Set the new height based on content size or the max height allowed
    textarea.css('height', `${newHeight}px`);

    // Calculate and adjust bottom padding based on the new height
    const minHeight = 28 + 10;  // single line height + 10 to adjust for padding
    const currentHeightIncrease = newHeight - minHeight;
    const maximumHeightIncrease = 196 - minHeight;
    let marginBottom = 0;
    if (currentHeightIncrease > 0) {
        marginBottom = (currentHeightIncrease / maximumHeightIncrease) * 125; // Scale padding up to 125px
    }

    // Apply calculated padding to the last message to ensure spacing
    lastMessage.css('padding-bottom', `${marginBottom}px`);

    // Enable scrolling if textarea is more than one line
    if (newHeight >= minHeight) {
        textarea.css('overflow-y', 'auto');
    }

    if (newHeight === minHeight) {
        textarea.css('overflow-y', 'hidden');
    }

    this.scrollChatToEnd();
  },

  toggleSendMessageControlsAvailability: function(available) {
    $('.chat-bot-text-area').find('button').prop('disabled', available ? null : 'disabled')
  },

  sendUserMessage: function(message) {
    this.appendMessage(message, 'User');
    this.appendMessage('typing')

    return new Promise((resolve, reject) => {
      this.chatChannel.sendMessage(message);
      this.resolveSendMessage = resolve;
      this.rejectSendMessage = reject;
    });
  },

  sendMessageButtonClicked: function(e) {
    e.preventDefault();
    const $input = $('#chat-message-input');
    if (!$input.val().length) {
      return;
    }
    this.sendUserMessage($input.val().trim());
    $input.val('');
    this.autoResizeInputTextArea();
    this.scrollChatToEnd();
    this.toggleSendMessageControlsAvailability(false);
  },

  handleEnterPress: function(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (!$('#send-message-button').prop('disabled')) {
        this.sendMessageButtonClicked(event);
        this.$('#send-message-button').click();
      }
    }
  },

  scrollChatToEnd: function() {
    if (UC.utils.isMobileViewPort()) {
      $('#chat-bot-panel')[0].scrollIntoView({block: "end", inline: "nearest"});
    } else {
      const chatContainer = $('.chat-bot-conversation');
      chatContainer.stop().animate({
        scrollTop: chatContainer.prop('scrollHeight')
      }, 500);
    }
  },

  scrollChatToStart: function() {
    if (UC.utils.isMobileViewPort()) {
      $('.chat-bot-summary')[0].scrollIntoView({block: "start", inline: "nearest"});
    }
  },

  captureThreadConversation: function() {
    var messages = [];

    $('.chat-bot-conversation .row').each(function() {
        var role = $(this).hasClass('recipient') ? 'recipient' : 'sender';
        var message = $(this).find('.chat-item-content-message').text().trim();
        messages.push({ role: role, message: message });
    });

    return messages;
  },

  validate: function(dfd) {
    this.toggleSendMessageControlsAvailability(false);

    this.chatBotMessagesQueue.push(this.conversationConclusionMessageData)
    this.popChatBotMessage()
      .then(() => {
        // Wait for 5 seconds before resolving the deferred object
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 5000);
        });
      })
      .then(() => {
        this.toggleSendMessageControlsAvailability(false);

        // Resolve the deferred object after capturing the thread conversation
        dfd.resolve({ ai_live_chat: { thread_conversation: this.captureThreadConversation() } });
      })
      .catch(error => {
        this.toggleSendMessageControlsAvailability(true);
        console.error("Error while sending conclusion message:", error);
      });
  },
});
