var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tab") || (depth0 != null ? lookupProperty(depth0,"tab") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tab","hash":{},"data":data,"loc":{"start":{"line":1,"column":51},"end":{"line":1,"column":58}}}) : helper)))
    + "-tab";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table spreadsheet no-margin "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tab") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":40},"end":{"line":1,"column":69}}})) != null ? stack1 : "")
    + "\" id=\"timesheet-table\">\n  <div class=\"thead\" id=\"ts-header\"></div>\n\n  <div class=\"tbody\" id=\"timesheet-line-items\"></div>\n</div>\n\n<div id=\"ts-save-status-wrapper\"></div>\n\n<div id=\"new-timesheet-row-btn-wrapper\"></div>\n";
},"useData":true});