!function () {

  UC.modals.UserDocumentsModalConfirmAccount = Backbone.View.extend({

    elements: {
      'btnConfirmAccount': null,
      'txtFirstName': null,
      'txtLastName': null,
      'txtZipCode': null,
      'txtCity': null,
      'selectCountry': null,
      'txtPassword': null,
      'internationalPanel': null,
      'zipCodePanel': null
    },

    initialize: function(options) {
      UC.utils.setSelectors(this.elements);

      this.components = {};
      this.components.btnConfirmAccountLoader = this.elements.btnConfirmAccount.buttonLoader();
      this.userId = UC.constants.userId;
      this.account = {};
      this.account.email = UC.constants.email;
      this.account.token = UC.constants.token;
      this.account.agreeTerms = true;
      this.path = '/account/confirm_account_ajax';
    },

    toggleZipCodePanel: function(e) {
      UC.utils.hidePanel(this.elements.internationalPanel);
      UC.utils.showPanel(this.elements.zipCodePanel);
    },

    toggleInternationalPanel: function(e) {
      UC.utils.hidePanel(this.elements.zipCodePanel);
      UC.utils.showPanel(this.elements.internationalPanel);
      this.elements.selectCountry.select2();
    },

    render: function(options) {
      $('#btnConfirmAccount').click(this.onBtnConfirmAccount.bind(this));
      $('#toggleInternationalPanel').click(this.toggleInternationalPanel.bind(this));
      $('#toggleZipCodePanel').click(this.toggleZipCodePanel.bind(this));
      this.elements.selectCountry.find("option[value='USA']").remove();
      this.$el.modal({
        backdrop: 'static'
      });
      return this;
    },

    onBtnConfirmAccount: function() {
      var accountDfd = new $.Deferred();
      var addressDfd = new $.Deferred();

      this.components.btnConfirmAccountLoader.showLoader();
      $.when(accountDfd, addressDfd)
      .done(function(){
        this.confirmAccount();
      }.bind(this))
      .fail(function() {
        this.components.btnConfirmAccountLoader.hideLoader();
      }.bind(this));

      this.validateConfirmAccount(accountDfd);
      this.validateAddress(addressDfd);
    },

    validateConfirmAccount: function(dfd) {
      var errors = [];
      $('.row').removeClass('has-error');

      this.account.firstName = this.elements.txtFirstName.val().trim();
      this.account.lastName = this.elements.txtLastName.val().trim();
      this.account.password = this.elements.txtPassword.val();

      if (this.account.firstName == '') {
        errors.push({element: this.elements.txtFirstName, text: 'First name required'});
      };

      if (this.account.lastName == '') {
        errors.push({element: this.elements.txtLastName, text: 'Last name required'});
      };

      if (this.account.password == '') {
        errors.push({element: this.elements.txtPassword, text: 'Please enter a password'});
      } else if (this.account.password.length < UC.constants.minPasswordLength) {
        errors.push({element: this.elements.txtPassword, text: 'Your password must be at least {0} characters long'.format(UC.constants.minPasswordLength)});
      };

      if (errors.length > 0) {
        for (var i = 0, len = errors.length; i < len; i++) {
          errors[i].element.closest('.row').addClass('has-error').find('.error-message').text(errors[i].text);
        }

        errors[0].element[0].scrollIntoView()
        dfd.reject();
      } else {
        dfd.resolve();
      };

    },

    validateAddress: function(dfd) {
      var address = {},
          errors = {};

      if (this.elements.zipCodePanel.is(':visible')) {
        address.ZipCode = this.elements.txtZipCode.val().trim();
        errors.ZipCode = {element: this.elements.txtZipCode, text: 'Please enter a valid zip code'};
      } else {
        address.City = this.elements.txtCity.val().trim();
        address.CountryCode = this.elements.selectCountry.val();
        errors.City = {element: this.elements.txtCity, text: 'Please enter a city'};
        errors.CountryCode = {element: this.elements.selectCountry, text: 'Please select a country'};
      };

      this.account.Address = address;
      var options = {
        address: address,
        callback: null,
        errors: errors,
        dfd: dfd
      };
      UC.utils.validateAddressAjax(options);
    },

    confirmAccount: function() {
      var data = {
        account: this.account
      };

      UC.net.post(this.path, data, function(err, data){
        if (err) {
          UC.utils.showNotification(err, UC.constants.results.failure);
        } else {
          var current = window.location.href.split('?')[0];
          location.href = current + '?show_about=true';
        };
      });
    }

  });

}();
