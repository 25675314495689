UC.views.AccountActivate = Backbone.View.extend({

  events: {
    'submit #formActivateAccount' : 'submit'
  },

  initialize: function() {
    this.btnLoader = this.$el.find('#btnActivatePassword').buttonLoader();
    this.$password = this.$el.find('#txtPassword');
    this.$verify = this.$el.find('#txtPasswordVerify');
    this.$password.focus();
  },

  submit: function(e) {
    e.preventDefault();
    if (this.validate()) {
      this.btnLoader.showLoader();
      var data = {
        password: this.data.password,
        passwordVerify: this.data.passwordVerify,
        token: UC.constants.token,
        userId: UC.constants.userId
      };

      if (UC.constants.nextPage) {
        data.url = UC.constants.nextPage;
      }

      UC.net.post('/account/activate_set_password', data).fail(function () {
        this.btnLoader.hideLoader();
      }.bind(this));
    }
  },

  validate: function() {
    var errors = [];
    UC.utils.clearValidationErrors('form-group');

    var data = {
      password: this.$password.val(),
      passwordVerify: this.$verify.val()
    };

    if (data.password.length < UC.constants.minPasswordLength) {
      errors.push({element: this.$password, text: 'Please provide a valid password with at least {0} characters'.format(UC.constants.minPasswordLength)});
    } else if (data.password != data.passwordVerify) {
      errors.push({element: this.$verify, text: 'Passwords do not match'});
    }

    this.data = data;
    UC.utils.showValidationErrors(errors, {className: 'form-group'});
    return errors.length === 0;
  }

});
