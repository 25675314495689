import { loadFileStackScript } from '../../../utils/filestack'

UC.views.AccountPhoto = Backbone.View.extend({

  initialize: function() {
    this.setPhotoUploadWidget();

    if (location.hash == '#checklist') {
      $('#btnSavePhoto').text('Save & Continue');
    }
    else if (location.hash == '#edit') {
      $('#btnFilepicker').trigger('click');
    }
  },

  setPhotoUploadWidget: function() {
    const photoWidgetOptions = {
      s3UploadPath: '{0}/images/'.format(UC.user.id),
      openFilePickerBtnSelector: '#btnFilepicker',
      ucStoreFileInfoPath: '/account/file_uploaded',
      ucStoreCroppedPhotoUrl: '/account/save_photo',
      uploadedImagePreviewSelector: '#imgNewPhoto',
      currentPhotoPanelSelector: '#panelCurrentPhoto',
      newPhotoPanelSelector: '#panelNewPhoto',
      savePhotoBtnSelector: '#btnSavePhoto',
      userId: UC.user.id,
      maxSize: UC.constants.maxPhotoSize,
      fileStackApiKey: UC.constants.fileStackApiKey,
      radminUpdate: false
    };

    const photoWidget = new UC.photoUploaderWidget(photoWidgetOptions);
    const $filePickerBtn = $(photoWidgetOptions.openFilePickerBtnSelector);

    $filePickerBtn.prop('disabled', true);

    loadFileStackScript(function () {
      photoWidget.init();

      $filePickerBtn.prop('disabled', false);
    });
  }
})
